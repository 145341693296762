/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const TagJsonApiModel = {
  id: "",
  name: "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "tag-type": "",
  color: "",
};
