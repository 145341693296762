import React from "react";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button } from "../../general";
import VeriHeading from "../../VeriHeading";
import MessageBox from "../MessageBox";

const ConfirmModal = ({
  isOpen,
  title,
  message,
  onClose,
  onConfirm,
  confirmProps,
  cancelProps,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
  showModalClose = true,
  error,
  ...rest
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    if (onConfirm) {
      setIsLoading(true);
      await onConfirm();
      setIsLoading(false);
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size={"3xl"} {...rest}>
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalBody>
          {showModalClose && (
            <ModalCloseButton position={"relative"} mb={8} right={2} />
          )}

          {error && <MessageBox type={"error"} text={error} mb={6} />}

          <VeriHeading textStyle={"h4"} color={"text.black"}>
            {title}
          </VeriHeading>
          <Box mt={2} textStyle={"bodyRegular"} color={"text.black"}>
            {message}
          </Box>
          <Flex mt={8}>
            <Button
              label={confirmLabel}
              onClick={handleConfirm}
              isLarge
              isBold
              isLoading={isLoading}
              {...confirmProps}
            />
            <Button
              ml={4}
              label={cancelLabel}
              onClick={onClose}
              isLarge
              isBold
              outline
              isDisabled={isLoading}
              {...cancelProps}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
