/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const EntityAddressJsonApiModel = {
  id: "",
  entity: {
    jsonApi: "hasOne",
    type: "entities",
  },
  "entity-id": "",
  address: {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "address-id": "",
  "filing-state": "",
  "filing-status": "",
  "created-at": "",
  "updated-at": "",
};
