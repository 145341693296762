import React from "react";
import { IconButtonProps } from "./IconButton.types";
import { Box, Spinner } from "@chakra-ui/react";

const IconButton = React.forwardRef<HTMLDivElement, IconButtonProps>(
  (
    {
      onClick,
      children,
      isCircle,
      isGrey,
      isActive,
      isGhost,
      isLoading,
      isDisabled,
      ...rest
    },
    ref,
  ) => {
    return (
      <Box
        as="button"
        p="12px"
        justifyContent="center"
        onClick={!isLoading && !isDisabled ? onClick : undefined}
        border="1px"
        focusBorderColor="brand.main"
        height="none"
        borderRadius={isCircle ? "100%" : 4}
        borderColor={
          isGhost || isDisabled
            ? "transparent"
            : isGrey
            ? "border.grey"
            : isActive
            ? "brand.main"
            : "border.input"
        }
        _hover={
          isGhost || isDisabled ? undefined : { borderColor: "brand.main" }
        }
        cursor={isDisabled ? "default" : "pointer"}
        bg={isGhost ? "transparent" : isGrey ? "border.grey" : "bg.white"}
        ref={ref}
        {...rest}
      >
        {isLoading ? (
          <Spinner color="currentColor" boxSize="1em" data-testid="spinner" />
        ) : (
          children
        )}
      </Box>
    );
  },
);

IconButton.displayName = "IconButton";

export default IconButton;
