const BASE_SPONSOR_URL = "baseSponsorUrl";
const IS_TENANT = "isManager";
const TENANT = "tenant";
const IS_MANAGER_DASHBOARD = "isManagerDashboard";
const IS_ADMIN = "isAdmin";
const IS_MOBILE = "isMobile";
const BASE_URL = "baseURL";
const FEATURE_FLAGS = "featureFlags";

const MANAGER = "manager";
const IS_MANAGER = "isManager";
const DASHBOARD_MANAGER = "managerDashboardTenant";

class DynamicConfig {
  constructor(config) {
    this.config = config || {
      baseSponsorUrl: "/",
    };
  }

  set(k, v) {
    this.config[k] = v;
  }

  get(k) {
    return this.config[k];
  }

  getState() {
    return this.config;
  }
}

export {
  BASE_SPONSOR_URL,
  BASE_URL,
  MANAGER,
  DASHBOARD_MANAGER,
  IS_TENANT,
  IS_MANAGER,
  TENANT,
  IS_MANAGER_DASHBOARD,
  IS_ADMIN,
  IS_MOBILE,
  FEATURE_FLAGS,
};

export default DynamicConfig;
