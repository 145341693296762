/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const PreviewAgreementDocumentInputJsonApiModel = {
  id: "",
  "agreement-type": "",
  "agreement-name": "",
  "fund-builder-id": "",
  "fund-builder": {
    jsonApi: "hasOne",
    type: "fund-builders",
  },
};
