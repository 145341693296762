/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const RequestPrincipalsBackgroundCheckPaymentOutputJsonApiModel = {
  id: "",
  "invoice-id": "",
  "invoice-item-ids": "",
};
