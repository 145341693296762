//

import React from "react";
import { errorMessages } from "../../../defines";
import { buildErrorMessageForName } from "../../../react-hook-form";
import VeriInput from "./VeriInput";

import { HookFormVeriInputProps } from "./VeriInput.types";

export function HookFormVeriInput<T>({
  form,
  name,
  rules,
  ...props
}: HookFormVeriInputProps<T>) {
  return (
    <VeriInput
      {...form.register(name, {
        required: {
          value: !!props.isRequired,
          message: errorMessages.requiredField,
        },
        ...rules,
      })}
      error={buildErrorMessageForName(form, name)}
      {...props}
    />
  );
}
