/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum EntityTypeEnumType {
  CCorp = "C-Corp",
  Llc = "LLC",
  Lp = "LP",
  Other = "Other",
  SCorp = "S-Corp",
}
