import { Box, Flex, Icon, Skeleton, Text } from "@chakra-ui/react";
import { LRPrivText } from "@verivest/logrocket/src";
import {
  buildRouteUrl,
  MANAGER_DASHBOARD_ROOT,
  managerDashboardRoutes,
} from "@verivest/shared-components";
import { VeriSpinner } from "@verivest/shared-components/src/components/common/loaders/VeriSpinner";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import {
  AccountIcon,
  MenuAccountsIcon,
  MenuOfferingsIcon,
  MenuOpportunitiesIcon,
  Search2Icon,
} from "../../general";
import VeriDropDown from "../../general/VeriDropDown";

const MAX_RESULTS = 10;

const SearchTypeToIcon = {
  offerings: MenuOfferingsIcon,
  accounts: MenuAccountsIcon,
  opportunities: MenuOpportunitiesIcon,
};

const SearchOptionReadMore = ({ name, onClick }) => {
  return (
    <Flex
      width="100%"
      height="100%"
      py={0}
      pl={"72px"}
      onClick={onClick}
      position={"relative"}
    >
      <Box position={"absolute"} mt={"-11px"}>
        <Box textStyle={"bodyRegular"} color={"brand.main"}>
          {name}
        </Box>
      </Box>
    </Flex>
  );
};

const SearchOptionGroup = ({ name }) => {
  return (
    <Flex
      width="100%"
      height="100%"
      borderBottom={"1px solid"}
      borderColor={"border.grey"}
      pb={2}
    >
      <Text textStyle={"labelSmall"} fontFamily={"satoshi"} color={"text.grey"}>
        {name}
      </Text>
    </Flex>
  );
};

const SearchOption = ({ name, description, isLoading, type }) => {
  return (
    <Flex py={0.5} px={1} alignItems={"center"}>
      <Box mr={2}>
        <Flex
          w={"48px"}
          height={"48px"}
          backgroundColor={"bg.lighter"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius={4}
          color={"text.grey"}
        >
          {isLoading ? (
            <Skeleton w={"48px"} h={"48px"} />
          ) : (
            <Icon boxSize={"24px"} as={SearchTypeToIcon[type] || AccountIcon} />
          )}
        </Flex>
      </Box>
      <Box flexGrow={1}>
        {isLoading && <Skeleton w={"60%"} h={"16px"} mb={2} />}
        {isLoading && <Skeleton w={"50%"} h={"14px"} />}
        {!isLoading && (
          <>
            <Text
              textAlign={"left"}
              textStyle={"bodyRegular"}
              color={"text.black"}
            >
              {name}
            </Text>
            <Text
              textStyle={"bodySmall"}
              textAlign={"left"}
              color={"text.grey"}
            >
              {description}
            </Text>
          </>
        )}
      </Box>
    </Flex>
  );
};

const HeaderSearch = ({ managerId }) => {
  const api = useApi();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dropDownRef, setDropDownRef] = useState(null);

  const fetchResults = async (searchTem, maxRecordsPerGroup, loadMoreItem) => {
    const { data: searchData } = await api.apiRequest(
      `/manager/dashboard-search?&manager_id=${managerId}&page[size]=${
        maxRecordsPerGroup || MAX_RESULTS
      }&text=` + searchTem,
    );

    const maxRecordsToLoadAccounts =
      loadMoreItem === "accounts" ? maxRecordsPerGroup : MAX_RESULTS;
    const maxRecordsToLoadOpportunities =
      loadMoreItem === "opportunities" ? maxRecordsPerGroup : MAX_RESULTS;
    const maxRecordsToLoadOfferings =
      loadMoreItem === "offerings" ? maxRecordsPerGroup : MAX_RESULTS;

    const maxRecords = Math.max(
      searchData.dashboardSearchTotals.totalOfferings,
      searchData.dashboardSearchTotals.totalOpportunities,
      searchData.dashboardSearchTotals.totalAccounts,
    );

    const searchResults = {
      accounts: (searchData.accounts || []).map((result) => ({
        ...result,
        isGroup: false,
        name: <LRPrivText>{result.nameOfRecord}</LRPrivText>,
        description: `${result.accountType}${
          result.mnumber ? " - " + result.mnumber : ""
        }`,
        value: buildRouteUrl(managerDashboardRoutes.ACCOUNT_DETAILS, {
          idAccount: String(result.id),
        }),
      })),
      opportunities: (searchData.opportunities || []).map((result) => ({
        ...result,
        isGroup: false,
        name: result.name,
        description: "",
        value: buildRouteUrl(managerDashboardRoutes.OPPORTUNITIES_DETAILS, {
          idOpportunity: String(result.id),
        }),
      })),
      offerings: (searchData.offerings || []).map((result) => ({
        ...result,
        isGroup: false,
        name: result.name,
        description: "",
        value: buildRouteUrl(managerDashboardRoutes.OFFERINGS_DETAILS, {
          idOffering: String(result.id),
        }),
      })),
    };

    let results = [];

    if (searchResults.accounts.length) {
      results = [
        ...results,
        {
          isGroup: true,
          disabled: true,
          value: "",
          name: "Investor accounts",
        },
        ...searchResults.accounts.slice(0, maxRecordsToLoadAccounts),
        ...(searchData.dashboardSearchTotals.totalAccounts >
        maxRecordsToLoadAccounts
          ? [
              {
                isLoadMore: true,
                value: "show-more-accounts",
                name: `Show ${
                  searchData.dashboardSearchTotals.totalAccounts -
                  maxRecordsToLoadAccounts
                } more results`,
                max: maxRecords,
              },
            ]
          : []),
      ];
    }

    if (searchResults.opportunities.length) {
      results = [
        ...results,
        {
          isGroup: true,
          disabled: true,
          value: "",
          name: "Opportunities",
        },
        ...searchResults.opportunities.slice(0, maxRecordsToLoadOpportunities),
        ...(searchData.dashboardSearchTotals.totalOpportunities >
        maxRecordsToLoadOpportunities
          ? [
              {
                isLoadMore: true,
                value: "show-more-opportunities",
                name: `Show ${
                  searchData.dashboardSearchTotals.totalOpportunities -
                  maxRecordsToLoadOpportunities
                } more results`,
                max: maxRecords,
              },
            ]
          : []),
      ];
    }
    if (searchResults.offerings.length) {
      results = [
        ...results,
        {
          isGroup: true,
          disabled: true,
          value: "/offerings",
          name: "Offerings",
        },
        ...searchResults.offerings.slice(0, maxRecordsToLoadOfferings),
        ...(searchData.dashboardSearchTotals.totalOfferings >
        maxRecordsToLoadOfferings
          ? [
              {
                isLoadMore: true,
                value: "show-more-offerings",
                name: `Show ${
                  searchData.dashboardSearchTotals.totalOfferings -
                  maxRecordsToLoadOfferings
                } more results`,
                max: maxRecords,
              },
            ]
          : []),
      ];
    }

    return results;
  };

  return (
    <VeriDropDown
      wrapperProps={{
        backgroundColor: "#F0F2F5",
        borderRadius: 4,
      }}
      resultsMaxHeight={"calc(100vh - 80px)"}
      backgroundColor={"transparent"}
      filterDelay={1000}
      w={"100%"}
      // label={"Name of your firm"}
      // placeholder={"e.g. Skyline Properties"}
      // minSearchLength={1}
      onFilter={async (filter) => {
        if (!filter) return [];
        // setContactFilter(filter);
        return await fetchResults(filter);
      }}
      /*onSelectOption={(option, searchText) => {
          history.push(option.value + "?filter[text]=" + searchText);
      }}*/
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSelect={(value, label, searchText, item) => {
        // TODO UNCOMMENT THE FOLLOWING IF YOU WANT TO LOAD MORE RECORDS DIRECTLY IN THE DROPDOWN
        /*if (
          value === "show-more-accounts" ||
          value === "show-more-opportunities" ||
          value === "show-more-offerings"
        ) {
          if (dropDownRef) {
            fetchResults(
              searchText,
              item.max,
              value.replace("show-more-", "")
            ).then((results) => {
              dropDownRef.setAsyncFilteredOptions(results);
            });
            // dropDownRef.refreshFilter(searchText, false);
          }
          return false;
        }*/

        if (
          value === "show-more-accounts" ||
          value === "show-more-opportunities" ||
          value === "show-more-offerings"
        ) {
          history.push(
            `${MANAGER_DASHBOARD_ROOT}/` +
              value.replace("show-more-", "") +
              "?filter[text]=" +
              searchText,
          );
          return;
        } else if (value === "show-more-contacts") {
          history.push(
            `${MANAGER_DASHBOARD_ROOT}/` +
              value.replace("show-more-", "") +
              "?filter[name]=" +
              searchText,
          );
          return;
        }

        history.push(value + "?filter[text]=" + searchText);
      }}
      wrapperOptions={(option) =>
        option.isGroup ? { px: 4, py: 0, pt: 4 } : null
      }
      renderOption={(option) => {
        if (option.isGroup) {
          return <SearchOptionGroup {...option} />;
        }

        if (option.isLoadMore) {
          return <SearchOptionReadMore {...option} />;
        }

        return <SearchOption {...option} />;
      }}
      getNoOptions={(filter) => (
        <Box textAlign={"center"} p={8} textStyle={"bodyRegular"}>
          No results for “{filter}”
        </Box>
      )}
      veriInputProps={{
        inputLeft: (
          <Icon as={Search2Icon} color="text.grey" fill="none" boxSize="24px" />
        ),
        inputLeftProps: { pt: 0, w: "24px", mx: 2.5 },
      }}
      color={"#6A6E74"}
      borderWidth={0}
      borderColor={"transparent"}
      options={[]}
      hideCaret
      clearOnSelect
      minSearchLength={1}
      height={10}
      dropDownRef={setDropDownRef}
      persistOnSearch={true}
      retainFilterValue
      notClearResultsOnEmptyFilter
      loading={
        <Flex alignItems={"center"} justifyContent={"center"} p={6}>
          <VeriSpinner color="#DCE1E7" boxSize="1em" />
        </Flex>
      }
    />
  );
};

export default HeaderSearch;
