import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { FileIcon as EmptyFileIcon } from "./Icons";

const IconContainer = ({ children }) => (
  <Flex
    alignItems={"center"}
    justifyContent={"center"}
    backgroundColor={"#F7F8F9"}
    borderRadius={4}
    w={"32px"}
    h={"32px"}
  >
    {children}
  </Flex>
);

const FileIcon = ({ fileName, extension, ...rest }) => {
  const ext = String(
    extension || String(fileName.split(".").slice(-1)[0]) || "",
  ).toLowerCase();

  let Icon = null;

  switch (ext) {
    case "pdf":
      Icon = (
        <IconContainer>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-testid="pdf-icon"
          >
            <path
              d="M6.93333 0C5.95781 0 5.15556 0.802256 5.15556 1.77778C5.15556 2.99049 5.82936 4.49602 6.53889 5.91111C5.98428 7.64726 5.35492 9.5078 4.55 11.0778C2.90253 11.7242 1.43332 12.2047 0.55 12.9167C0.536226 12.9308 0.523243 12.9456 0.511111 12.9611C0.188322 13.2912 0 13.7406 0 14.2222C0 15.1977 0.802255 16 1.77778 16C2.25348 16 2.71264 15.8225 3.04444 15.4833C3.05593 15.4745 3.06705 15.4653 3.07778 15.4556C3.72799 14.6791 4.49467 13.2707 5.17778 11.9833C6.75213 11.364 8.40221 10.7339 9.99444 10.3556C11.1568 11.2923 12.8384 11.9111 14.2222 11.9111C15.1977 11.9111 16 11.1089 16 10.1333C16 9.15781 15.1977 8.35556 14.2222 8.35556C13.1125 8.35556 11.4997 8.75171 10.2667 9.16667C9.26718 8.22837 8.3489 7.05651 7.68889 5.81111C8.16046 4.3584 8.71111 2.90484 8.71111 1.77778C8.71111 0.802256 7.90886 0 6.93333 0ZM6.93333 1.06667C7.33239 1.06667 7.64444 1.37873 7.64444 1.77778C7.64444 2.31137 7.35871 3.29378 7.02778 4.35C6.58557 3.32249 6.22222 2.33669 6.22222 1.77778C6.22222 1.37873 6.53428 1.06667 6.93333 1.06667ZM7.23889 7.15C7.77358 8.00052 8.39982 8.79194 9.08333 9.49444C8.02906 9.78273 6.99943 10.1521 5.98333 10.5389C6.47409 9.43123 6.86697 8.28183 7.23889 7.15ZM14.2222 9.42222C14.6213 9.42222 14.9333 9.73428 14.9333 10.1333C14.9333 10.5324 14.6213 10.8444 14.2222 10.8444C13.4211 10.8444 12.2825 10.4828 11.3722 9.97778C12.417 9.68235 13.5571 9.42222 14.2222 9.42222ZM3.61111 12.6056C3.11118 13.4979 2.61425 14.3309 2.26667 14.75C2.14793 14.8635 1.98999 14.9333 1.77778 14.9333C1.37872 14.9333 1.06667 14.6213 1.06667 14.2222C1.06667 14.0344 1.14648 13.8479 1.25556 13.7278C1.6726 13.405 2.5799 13.0183 3.61111 12.6056Z"
              fill="#EC6348"
            />
          </svg>
        </IconContainer>
      );
      break;
    default:
      Icon = <EmptyFileIcon boxSize={"30px"} data-testid="default-icon" />;
  }

  return <Box {...rest}>{Icon}</Box>;
};

export default FileIcon;
