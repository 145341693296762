/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UpdateEntityOpportunityOutputJsonApiModel = {
  id: "",
  "entity-opportunity": {
    jsonApi: "hasOne",
    type: "entity-opportunities",
  },
};
