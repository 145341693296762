import humps from "humps";
import { AccreditationOverview } from "../../../../components/common/verifications/AccreditationDesktopDetailsTooltip/useAccreditationDetails";
import { ModelId } from "../../../../defines";
import { ApiModel, ApiModelOptions } from "../../../ApiModel";
import {
  VerificationStatusEnumType,
  VerificationTypeEnumType,
} from "../../../enums";
import { VerificationModel } from "../../../models";
import { JsonApiManyResponse } from "../../../types";

export default function apiVerificationModel(
  opts: ApiModelOptions<VerificationModel>,
) {
  const model = ApiModel<VerificationModel>("verification", opts);

  const getVerificationsByAccountId = async (accountId: ModelId) => {
    return await model.handler.apiRequest<
      JsonApiManyResponse<VerificationModel>
    >(`/verifications/account/${accountId}`, "GET");
  };

  const getVerificationOverview = async (accountId: ModelId) => {
    return await model.handler
      .get<AccreditationOverview>(
        `/verifications/account/${accountId}/overview`,
      )
      .then((res) => humps.camelizeKeys(res));
  };

  const getVerificationsByAccountIdAndType = async (
    accountId: ModelId,
    type: "accreditation" | "identity",
  ) => {
    return await model.handler.apiRequest<
      JsonApiManyResponse<VerificationModel>
    >(`/verifications/account/${accountId}/${type}`, "GET");
  };

  const getPendingVerificationByAccountIdOrCreateNewPending = async (
    accountId: ModelId,
  ) => {
    const verification = await getVerificationsByAccountId(accountId);
    const pendingVerifications =
      verification.data?.filter(
        (verification) =>
          verification.status === VerificationStatusEnumType.Pending ||
          verification.status === VerificationStatusEnumType.Unverified,
      ) || [];

    if (!pendingVerifications?.length) {
      const newVerification = await model.create({
        verificationType: VerificationTypeEnumType.Identity,
        status: VerificationStatusEnumType.Pending,
        account: { id: String(accountId) },
      });

      return [newVerification];
    }

    return pendingVerifications;
  };

  return {
    ...model,
    getVerificationOverview,
    getVerificationsByAccountId,
    getVerificationsByAccountIdAndType,
    getPendingVerificationByAccountIdOrCreateNewPending,
  };
}
