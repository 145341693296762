/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const VerificationJsonApiModel = {
  id: "",
  "verification-type": "",
  "verification-method": "",
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "investor-notes": "",
  "reviewer-notes": "",
  status: "",
  "parallel-markets-accreditation-status": "",
  "verified-by": {
    jsonApi: "hasOne",
    type: "users",
  },
  "verified-at": "",
  "expires-at": "",
  "created-at": "",
  "updated-at": "",
};
