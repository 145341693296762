import { Flex, Text, useToast } from "@chakra-ui/react";
import { Button } from "@verivest/shared-components";
import { logError } from "@verivest/shared-components/src/error-handling/logError";
import React from "react";
import useAuth from "../../hooks/useAuth";
import useDynamicConfig, {
  IS_MANAGER,
  MANAGER,
} from "../../hooks/useDynamicConfig";
import { toastSuccess } from "../../utils/utils";

const HeaderMessage = ({ show }) => {
  const { auth } = useAuth();
  const profile = auth.getProfile();
  const dynamicConfig = useDynamicConfig();
  const toast = useToast();
  if (!show) return <></>;
  let message = false;
  let to, onClick;
  if (profile && !profile.email_verified) {
    onClick = async () => {
      try {
        const managers =
          dynamicConfig.get(IS_MANAGER) && dynamicConfig.get(MANAGER);
        await auth.resendActivationEmail(
          profile.email,
          managers && managers.id,
        );
        toastSuccess(toast, "Verification email sent", {
          description: "Follow the instructions on your email",
        });
      } catch (e) {
        logError("error resending activation", e);
      }
    };
    message = (
      <>
        Your email address needs verification. Please check your inbox and
        confirm. Missing the email? <b>Resend verification</b>
      </>
    );
  } else if (profile && !profile.has_account_ready) {
    to = "/accounts";
    message = (
      <>
        <b>Complete your investment account</b> so you don’t miss out on an
        investment opportunity!
      </>
    );
  }

  return (
    <>
      {message && (
        <Flex justifyContent={"center"} bg="bg.lightAccent" p="14px">
          <Button empty justifyContent={"center"} to={to} onClick={onClick}>
            <Text
              textAlign="center"
              textStyle="bodyRegular"
              lineHeight="20px"
              color="text.darkTeal"
            >
              {message}
            </Text>
          </Button>
        </Flex>
      )}
    </>
  );
};

export default HeaderMessage;
