/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallRuleDistributionHoldingJsonApiModel = {
  id: "",
  "distribution-holding-id": "",
  "waterfall-instance-rule-id": "",
  "calculated-amount-to-target": "",
  "adjusted-amount-to-target": "",
  "adjusted-at": "",
  "adjusted-by-user-id": "",
  "amount-paid": "",
};
