/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateFeeOutputJsonApiModel = {
  id: "",
  fee: {
    jsonApi: "hasOne",
    type: "fees",
  },
};
