/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const AlertTriangleIcon = createIcon({
  displayName: "AlertTriangleIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><g clipPath="url(#clip0_142_53)"><path d="M1.243 18.953L10.152 2.111C10.33 1.77466 10.5962 1.49321 10.9222 1.29689C11.2482 1.10058 11.6215 0.996851 12.002 0.996851C12.3825 0.996851 12.7558 1.10058 13.0818 1.29689C13.4078 1.49321 13.674 1.77466 13.852 2.111L22.761 18.953C22.9282 19.2708 23.0103 19.6266 22.9991 19.9855C22.988 20.3445 22.884 20.6944 22.6974 21.0013C22.5108 21.3081 22.2479 21.5614 21.9343 21.7364C21.6207 21.9115 21.2671 22.0023 20.908 22H3.092C2.73321 22.0016 2.38012 21.9103 2.06707 21.735C1.75402 21.5596 1.49167 21.3063 1.30553 20.9996C1.1194 20.6928 1.01581 20.3431 1.00485 19.9845C0.993896 19.6259 1.07594 19.2705 1.243 18.953V18.953Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 8V14" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 19C12.8284 19 13.5 18.3284 13.5 17.5C13.5 16.6716 12.8284 16 12 16C11.1716 16 10.5 16.6716 10.5 17.5C10.5 18.3284 11.1716 19 12 19Z" fill="currentColor" /></g><defs><clipPath id="clip0_142_53"><rect width={24} height={24} fill="currentColor" /></clipPath></defs></g>)
});
export default AlertTriangleIcon;