import { ApiModel, ApiModelOptions } from "../../../ApiModel";
import InvestorGroupedInvestmentEndpoints from "../../../endpoints/GroupedInvestment/investor";
import ManagerGroupedInvestmentEndpoints from "../../../endpoints/GroupedInvestment/manager";
import { InvestmentsStatusType } from "../../../enums";
import { GroupedInvestmentModel } from "../../../models/GroupedInvestment";
import { ModelScope } from "../../../types";

export function apiGroupedInvestmentModel(
  opts: ApiModelOptions<GroupedInvestmentModel>,
) {
  const model = ApiModel<GroupedInvestmentModel>("grouped-investment", {
    urls: {
      [ModelScope.Manager]: ManagerGroupedInvestmentEndpoints,
      [ModelScope.Investor]: InvestorGroupedInvestmentEndpoints,
    },
    ...opts,
  });

  const getCompleted = async (
    sortBy: string,
    managerId?: string,
    investorId?: string,
  ) => {
    return await model.getMany(undefined, {
      type: "NoOversubscribed",
      "&page[size]": 1000,
      user_id: investorId,
      status: InvestmentsStatusType.Completed,
      sort: sortBy,
      ...(managerId && { manager_id: managerId }),
    });
  };

  return {
    ...model,
    getCompleted,
  };
}

export default apiGroupedInvestmentModel;
