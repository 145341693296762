/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetOfferingsOutputJsonApiModel = {
  id: "",
  offerings: {
    jsonApi: "hasMany",
    type: "offerings",
  },
};
