import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { VeriSpinner } from "@verivest/shared-components/src/components/common/loaders/VeriSpinner";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { CloseIcon, SearchIcon } from "./Icons";

const SearchField = ({
  width,
  value,
  onChange,
  placeholder,
  onCancel,
  isLoading,
  delayedSubmit,
  onDelayedSubmit,
  isSmall,
  inputGroupProps,
  ...rest
}) => {
  const timer = useRef(null);

  const [tempValue, setTempValue] = useState(null);

  const _onChange = (e) => {
    const value = e.target.value;
    if (timer.current) clearTimeout(timer.current);

    if (delayedSubmit) {
      setTempValue(value);
      timer.current = setTimeout(() => {
        if (onDelayedSubmit) {
          setTempValue(null);
          onDelayedSubmit(value);
        }
      }, delayedSubmit);
    }

    if (onChange) onChange(e);
  };

  return (
    <InputGroup size="md" width={width} as="span" {...inputGroupProps}>
      <InputLeftElement
        height="none"
        width="none"
        p={isSmall ? 2 : 3}
        borderWidth="1px"
        borderColor="transparent"
      >
        <Icon as={SearchIcon} color="icons.grey" fill="none" boxSize="24px" />
      </InputLeftElement>
      <Input
        bg="white"
        _focus={{
          borderColor: "brand.main",
        }}
        focusBorderColor="brand.main"
        height="none"
        borderRadius={4}
        borderColor="border.input"
        fontFamily="Nunito Sans"
        pr="48px"
        pl="48px"
        pb={isSmall ? 2 : 3}
        pt={isSmall ? 2 : 3}
        fontSize="md"
        type="text"
        value={tempValue !== null ? tempValue : value}
        onChange={_onChange}
        placeholder={placeholder}
        color="black"
        {...rest}
      />

      {value && value.length > 0 && onCancel && (
        <InputRightElement
          width="none"
          height="none"
          onClick={onCancel}
          cursor="pointer"
          p={isSmall ? 2 : 3}
          borderWidth="1px"
          borderColor="transparent"
        >
          {isLoading ? (
            <VeriSpinner color="currentColor" boxSize="24px" />
          ) : (
            <Icon as={CloseIcon} boxSize="24px" color="brand.main" />
          )}
        </InputRightElement>
      )}
    </InputGroup>
  );
};

SearchField.propTypes = {
  /**
   * Value of the search field
   */
  value: PropTypes.string,
  /**
   * Placeholder text for the search field
   */
  placeholder: PropTypes.string,
  /**
   * callback for onChange
   */
  onChange: PropTypes.func,
  /**
   * callback for onCancel, when clicking on the cross
   */
  onCancel: PropTypes.func,
};
export default SearchField;
