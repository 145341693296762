/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const StatementJsonApiModel = {
  id: "",
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "statement-set": {
    jsonApi: "hasOne",
    type: "statement-sets",
  },
};
