import { Box } from "@chakra-ui/react";
import React from "react";
import { dateToMonYYYYUTC } from "../../../../utils/utils";

function DateRenderer({ value, column }) {
  if (value == null) {
    return <Box>{column.emptyReplace || "-"}</Box>;
  }
  const date = !isNaN(value)
    ? new Date(value * 1000)
    : typeof value === "string"
    ? new Date(value)
    : value;
  const convertedDate = date ? dateToMonYYYYUTC(date, true) : null;

  return (
    <Box>
      {!date
        ? column.emptyReplace || "-"
        : convertedDate
        ? convertedDate
        : value}
    </Box>
  );
}

DateRenderer.prototype.sort = (column, direction, records) => {
  return records.sort((a, b) => {
    const aDate = !isNaN(a[column])
      ? new Date(a[column] * 1000)
      : typeof a[column] === "string"
      ? new Date(a[column])
      : a[column];
    const bDate = !isNaN(b[column])
      ? new Date(b[column] * 1000)
      : typeof b[column] === "string"
      ? new Date(b[column])
      : b[column];

    if (aDate && bDate) {
      if (direction === "ASC") {
        return aDate - bDate;
      } else {
        return bDate - aDate;
      }
    } else {
      return 0;
    }
  });
};

export default DateRenderer;
