import React from "react";
import { TableNoRecords } from "./index";
import { SearchIcon } from "../../general";

const TableWithFilterNoRecords = ({ clearFilters }) => {
  return (
    <TableNoRecords
      title={"No results found"}
      description={
        "No results match the filter criteria. Remove filters or clear all filters to show results."
      }
      icon={SearchIcon}
      primaryActionLabel={"Clear all filters"}
      primaryAction={clearFilters}
      primaryActionProps={{ empty: true }}
    />
  );
};

export default TableWithFilterNoRecords;
