import { As, BoxProps, TextProps } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { ButtonStylingProps } from "./ButtonProps";

export const getBoxAsComponent = (
  to: string | undefined,
  href: string | undefined,
  disabled: boolean | undefined,
) => {
  if (disabled) return "button";
  if (href) return "a";
  if (to) return RouterLink;
  return "button";
};

const getColors = (isWhite?: boolean, isDark?: boolean, isFlat?: boolean) => {
  let colorOverride: string | undefined;
  let colorMain = "brand.main";
  let colorHover = "white";
  let borderColor = colorMain;

  if (isWhite) {
    colorMain = "white";
    colorHover = "text.grey";
  }
  if (isDark) {
    colorMain = "black";
    colorHover = "black";
    colorOverride = "bg.grey";
  }

  if (isFlat) {
    colorMain = "text.black";
    borderColor = "border.grey";
    colorOverride = "border.grey";
    colorHover = "text.black";
  } else {
    borderColor = colorMain;
  }

  return {
    colorOverride,
    colorMain,
    colorHover,
    borderColor,
  };
};

export const getButtonVariationAttributes = (
  props: Partial<ButtonStylingProps>,
  isHovering: boolean,
  color: string | undefined,
) => {
  const { colorOverride, colorMain, colorHover, borderColor } = getColors(
    props.isWhite,
    props.isDark,
    props.isFlat,
  );

  let boxStylingAttributes: Partial<BoxProps> = {
    width: (props.fullWidth ? "100%" : undefined) as string,
    px: props.isLarge ? "24px" : "16px",
    py: props.isLarge ? "12px" : props.isMedium ? "8px" : "4px",
    color: colorMain,
    borderColor: borderColor,
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    _disabled: {
      opacity: "40%",
      cursor: "not-allowed",
      boxShadow: "none",
    },
    outline: "none",
    _focus: { borderColor: "brand.dark" },
  };
  let textStylingAttributes: Partial<TextProps> = {
    fontWeight: 700,
  };

  if (props.outline || props.isFlat) {
    const normalColor = props.isDanger ? "semantic.error" : color || colorMain;
    const normalBgColor = props.isDanger ? "semantic.error" : colorMain;

    boxStylingAttributes = {
      ...boxStylingAttributes,
      borderWidth: 1,
      borderColor: props.isDanger ? "semantic.error" : borderColor,
      _hover: { bg: colorOverride || normalBgColor, color: colorHover },
      bg: "white",
    };
    textStylingAttributes = {
      ...textStylingAttributes,
      color: isHovering ? colorHover : normalColor,
      textStyle: props.isLarge ? "bodyRegular" : "bodySmall",
    };
  } else if (props.empty || props.link) {
    boxStylingAttributes = {
      ...boxStylingAttributes,
      px: "0px",
      border: "0px",
      _hover: { color: "brand.dark" },
      boxShadow: "unset",
      bg: "transparent",
    };
    textStylingAttributes = {
      ...textStylingAttributes,
      color: isHovering ? "brand.dark" : colorMain,
      textStyle: props.isLarge ? "bodyRegular" : "bodySmall",
      textDecoration: props.link ? "underline" : undefined,
    };
  } else if (props.boxLink) {
    boxStylingAttributes = {
      ...boxStylingAttributes,
      border: "0px",
      _hover: { color: "brand.dark" },
      boxShadow: "unset",
      bg: "transparent",
    };
    textStylingAttributes = {
      ...textStylingAttributes,
      color: isHovering ? "brand.dark" : colorMain,
      textStyle: props.isLarge ? "bodyRegular" : "bodySmall",
    };
  } else if (props.isRoundIcon) {
    boxStylingAttributes = {
      ...boxStylingAttributes,
      borderWidth: "0",
      color: "text.grey",
      _hover: {
        bg: props.isDanger ? "semantic.danger" : "brand.main",
        color: "white",
      },
      bg: "bg.white",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
    };
  } else {
    boxStylingAttributes = {
      ...boxStylingAttributes,
      borderWidth: "2px",
      borderColor: props.isDanger ? "semantic.error" : "brand.main",
      color: "#FFF",
      _hover: props.isDanger
        ? {
            bg: "bg.error",
            color: "#FFF",
            borderColor: "semantic.error",
          }
        : { bg: "brand.dark", color: "#FFF", borderColor: "brand.dark" },
      bg: props.isDanger ? "semantic.error" : "brand.main",
    };
    textStylingAttributes = {
      ...textStylingAttributes,
      color: "white",
      textStyle: props.isLarge ? "bodyRegular" : "bodySmall",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      as: "span" as As<any>,
    };
  }

  return {
    boxStylingAttributes,
    textStylingAttributes,
  };
};
