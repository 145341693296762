import * as React from "react";
import { ReactNode } from "react";
import Auth from "../auth/Auth";
import { investorPortalRoutes } from "../defines";
import DynamicConfig from "../dynamic-config/DynamicConfig";
import { getLoginURL, isClient } from "../utils";

interface Ctx {
  dynamicConfig: DynamicConfig;
  auth: Auth;
  req?: Record<string, any>;
  match?: Record<string, string>;
}

type FCWithExtra = React.FC & {
  default?: FCWithExtra;
  getInitialProps: (ctx: Ctx) => void;
};

/**
 * Returns a new React component, ready to be instantiated.
 * Note the closure here protecting Component, and providing a unique
 * instance of Component to the static implementation of `load`.
 */
export function asyncComponentAuthenticated({
  loader,
  Placeholder,
  chunkName,
  callbackUrl,
  appendBackUrl,
}: {
  Placeholder?: ReactNode;
  loader: () => Promise<any>;
  chunkName: string;
  callbackUrl?: string;
  appendBackUrl?: boolean; // TODO: Why wouldn't this be on by default?
}) {
  // keep Component in a closure to avoid doing this stuff more than once
  let Component: FCWithExtra | null = null;

  return class AsyncRouteComponentAuthenticated extends React.Component {
    /**
     * Static so that you can call load against an uninstantiated version of
     * this component. This should only be called one time outside of the
     * normal render path.
     */
    static load() {
      return loader().then((ResolvedComponent) => {
        Component = ResolvedComponent?.default || ResolvedComponent;
      });
    }

    static getChunkName() {
      return chunkName;
    }

    static getInitialProps(ctx: Ctx) {
      const query = (ctx.req?._parsedUrl?.query || "") as string;
      const backToUrl =
        (appendBackUrl && ctx?.req?.originalUrl) || ctx?.match?.url;

      if (
        (!ctx.auth || !ctx.auth.isAuthenticated()) &&
        !query.includes("impersonating") &&
        !query.includes("manager_impersonating")
      ) {
        const loginURL = getLoginURL(
          "",
          callbackUrl,
          backToUrl != investorPortalRoutes.home ? backToUrl : undefined,
          undefined,
          (
            ctx?.match?.params as unknown as {
              [key: string]: string | undefined;
            }
          )?.["manager_id"],
        );

        /*if (manager) {
          loginURL = getManagerLoginURL(
            manager.id,
            ctx.dynamicConfig.get(DynamicConfigFlags.BASE_URL),
          );
        }*/

        if (isClient()) {
          if (ctx.auth)
            ctx.auth.cookies.set(
              "redirect_login",
              window.location.pathname + window.location.search,
            );

          window.location.replace(loginURL);
          return Promise.reject();
        } else {
          if (ctx.auth) {
            ctx.auth.cookies.set("redirect_login", ctx.req?.originalUrl || "");
          }
        }

        return Promise.resolve({
          redirectTo: loginURL,
        });
      }
      // Need to call the wrapped components getInitialProps if it exists
      if (Component !== null) {
        return Component.getInitialProps
          ? Component.getInitialProps(ctx)
          : Promise.resolve(null);
      }
    }

    override state = {
      Component,
    };

    override componentDidMount() {
      AsyncRouteComponentAuthenticated.load().then(this.updateState);
    }

    updateState = () => {
      // Only update state if we don't already have a reference to the
      // component, this prevent unnecessary renders.
      if (this.state.Component !== Component) {
        this.setState({
          Component,
        });
      }
    };

    override render() {
      const { Component: ComponentFromState } = this.state;

      if (ComponentFromState) {
        return <ComponentFromState {...this.props} />;
      }

      if (Placeholder && Placeholder instanceof Function) {
        return <Placeholder {...this.props} />;
      }

      return null;
    }
  };
}

export default asyncComponentAuthenticated;
