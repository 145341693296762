import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import Button from "../../buttons/Button";
import { genericErrorMessage } from "./genericErrorMessage";

export interface GlobalErrorScreenProps {
  backToHref?: string;
}

export const GlobalErrorScreen = ({ backToHref }: GlobalErrorScreenProps) => (
  <Flex
    h="100vh"
    w="100vw"
    maxH="100%"
    maxW="100%"
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
    direction="column"
  >
    <Text m={4} variant="h2" maxW="400px" textAlign="center">
      {genericErrorMessage}
    </Text>
    {!!backToHref && (
      <Button link href={backToHref}>
        Go back
      </Button>
    )}
  </Flex>
);
