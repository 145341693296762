/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ImageIcon = createIcon({
  displayName: "ImageIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M1 20L6 14L10 18L17 10L23 17" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M23 3H1V21H23V3Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default ImageIcon;