import qs from "qs";
import { AccountTypeType } from "../api/enums";
import { ManagerModel } from "../api/models";
import {
  Dict,
  investorPortalRoutes,
  ManagerWhiteLabelSectionQueries,
} from "../defines";
import { AuthTemplates } from "../defines/auth";
import { authRoutes } from "../defines/routes/auth";
import { marketingWebsiteRoutes } from "../defines/routes/investor-portal";
import {
  cleanObjectUndefinedValues,
  fixObjectArray,
  getEnumKeys,
  isClient,
  isServer,
} from "./common";

export function createSafeURL(urlString: string) {
  let safeURL;

  try {
    safeURL = new URL(urlString);
  } catch {
    const correctedURLString = `https://${urlString}`;
    safeURL = new URL(correctedURLString);
  }

  return safeURL;
}

export const getDomainFromUrl = (url: string) => {
  const newUrl = createSafeURL(url);

  return newUrl.hostname;
  /*const match = (url || "").match(/(?<=:\/\/)(?:www\.)?([^:/\n?=]+)/gi);
  if (match && match[0]) return match[0];

  return null;*/
};

export function getManagerLoginURL(id: string, url: string, email = "") {
  return `${buildRouteUrl(
    authRoutes.AUTH,
  )}?manager_id=${id}&email=${encodeURIComponent(email)}&callback_url=${url}`;
}

export function getLoginURL(
  email?: string,
  callbackUrl?: string,
  backTo?: string,
  template?: AuthTemplates | "",
  managerId?: string,
): string {
  return buildRouteUrl(
    authRoutes.AUTH,
    undefined,
    cleanObjectUndefinedValues({
      email: email || undefined,
      callback_url: callbackUrl || undefined,
      back_to: backTo || undefined,
      t: template || undefined,
      manager_id: managerId || undefined,
    }),
  );
}

export const objectToQueryParams = (obj: Dict, prefix?: string): string => {
  const str = [];
  let p;

  const isServer = !isClient();

  for (p in obj) {
    if (Object.keys(p).length > 0) {
      const k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? objectToQueryParams(v, k)
          : (!isServer ? encodeURIComponent(k) : k) +
              "=" +
              (!isServer ? encodeURIComponent(v) : v),
      );
    }
  }
  return str.join("&");
};

export const buildRouteUrl = (
  routeUrl: string,
  params: { [key: string]: string | null | undefined } | undefined = undefined,
  queryParams?: Dict,
  backUrl?: boolean,
): string => {
  const paramKeys = Object.keys(params || {});
  const stringQueryParams = queryParams ? objectToQueryParams(queryParams) : "";
  return (
    "/" +
    routeUrl
      .replace(/\?/g, "")
      .split("/")
      .map((part) =>
        part.substr(0, 1) === ":"
          ? paramKeys.includes(part.substr(1, part.length - 1))
            ? params?.[part.substr(1, part.length - 1)] || ""
            : ""
          : part,
      )
      .filter((part) => part !== "")
      .join("/") +
    (stringQueryParams || (backUrl && isClient())
      ? `${
          queryParams || (backUrl && isClient()) ? "?" : ""
        }${stringQueryParams}${
          backUrl && isClient()
            ? `&bTo=${encodeURIComponent(
                window.location.pathname + window.location.search,
              )}`
            : ""
        }`
      : "")
  );
};

export const getURLParams: <T>(url: string) => T = <T>(
  url: string,
): T & Record<string, unknown> => {
  return qs.parse((url || "").replace(/([\s\S]*?\?)/, "")) as T & qs.ParsedQs;
};

export function getURLParamsQuery(url: string) {
  const params = getURLParams<any>(url);
  return getUrlParamsQueryUsingObject(params);
}

export function getUrlParamsQueryUsingObject(params: Dict) {
  return qs.stringify(fixObjectArray(params || {}), {
    arrayFormat: "comma",
  });
}

export function getURLParamsFilter(url: string): Dict {
  const queryParams = (url || "").replace(/([\s\S]*?\?)/, "");
  const params = qs.parse(queryParams);

  return (params.filter as Dict) || {};
}

export function getURLParamsFilterToQuery(url: string) {
  const params = getURLParamsFilter(url);
  return qs.stringify(fixObjectArray(params), { arrayFormat: "comma" });
}

export function getUrlParamsFilterToQueryObject(url: string) {
  const filterList = fixObjectArray(getURLParamsFilter(url));
  return Object.keys(filterList || {}).reduce(
    (obj, cur) => ({
      ...obj,
      [cur]: Array.isArray(filterList[cur])
        ? filterList[cur].join(",")
        : filterList[cur],
    }),
    {},
  );
}

export function getURLParamsFilterServer(params: Dict) {
  return qs.stringify(fixObjectArray(params.filter || {}), {
    arrayFormat: "comma",
  });
}

export const getBaseUrl = (url: string) => {
  if (!url) return url;

  const pathArray = url.split("/");
  const protocol = pathArray[0];
  const host = pathArray[2];
  return protocol + "//" + host;
};

export const isMarketingSiteRoute = () => {
  return (
    isClient() &&
    window.location.hostname !==
      getDomainFromUrl(
        (isServer() && process.env.RAZZLE_APP_DASHBOARD_URL) || "",
      ) &&
    (window.location.pathname === marketingWebsiteRoutes.homepage ||
      window.location.pathname === marketingWebsiteRoutes.pricing ||
      window.location.pathname === marketingWebsiteRoutes.forManagers ||
      window.location.pathname === marketingWebsiteRoutes.forInvestors ||
      window.location.pathname === marketingWebsiteRoutes.terms ||
      window.location.pathname === marketingWebsiteRoutes.privacy ||
      window.location.pathname === marketingWebsiteRoutes.investorPortal ||
      window.location.pathname === marketingWebsiteRoutes.advisory ||
      window.location.pathname === marketingWebsiteRoutes.managerdash ||
      window.location.pathname === marketingWebsiteRoutes.admin ||
      window.location.pathname === marketingWebsiteRoutes.fundbuilder ||
      window.location.pathname === marketingWebsiteRoutes.fundAdmin)
  );
};

export const getHostNameFromURL = (url: string) => {
  if (!url) return "";
  return new URL(
    `${
      url.indexOf("http://") !== 0 && url.indexOf("https://") !== 0
        ? "https://"
        : ""
    }${url}`,
  ).hostname;
};

export const isManagerWhiteLabelRoute = (route: string) => {
  const firstPiece = route.split("/")[1];
  if (!firstPiece) return false;
  return getEnumKeys(investorPortalRoutes).some((routeKey) => {
    const route = investorPortalRoutes[routeKey];
    const routeFirstPiece = route.split("/")[1];
    if (!routeFirstPiece) return true;
    return routeFirstPiece === firstPiece;
  });
};

export const getAccountFilterFromQuery = (urlParams: URLSearchParams) => {
  const queryAccountId = urlParams.get(
    ManagerWhiteLabelSectionQueries.AccountId,
  );
  const queryAccountName = urlParams.get(
    ManagerWhiteLabelSectionQueries.AccountName,
  );
  const queryAccountMNumber = urlParams.get(
    ManagerWhiteLabelSectionQueries.AccountMNumber,
  );
  const queryAccountType = urlParams.get(
    ManagerWhiteLabelSectionQueries.AccountType,
  );
  return queryAccountId
    ? {
        id: queryAccountId,
        nameOfRecord: queryAccountName,
        mnumber: queryAccountMNumber,
        accountType: queryAccountType as AccountTypeType,
      }
    : undefined;
};

export const getOpportunityFilterQuery = (urlParams: URLSearchParams) => {
  const queryOpportunityId = urlParams.get(
    ManagerWhiteLabelSectionQueries.OpportunityId,
  );
  const queryOpportunityName = urlParams.get(
    ManagerWhiteLabelSectionQueries.OpportunityName,
  );
  return queryOpportunityId
    ? {
        id: queryOpportunityId,
        name: queryOpportunityName || "",
      }
    : undefined;
};

export const getUrlProtocol = () => {
  return (
    new URL(process.env.RAZZLE_APP_WEB_URL as string)?.protocol || "https:"
  );
};

export const getUrlPort = () => {
  const { port } = new URL(process.env.RAZZLE_APP_WEB_URL as string);
  return port ? `:${port}` : "";
};

export const getWhiteLabelSubDomainBaseUrl = (slug: string) => {
  return `${getUrlProtocol()}//${slug}.${
    process.env.RAZZLE_APP_VERIVEST_DOMAIN
  }${getUrlPort()}`;
};

export const getWhiteLabelBaseUrl = (manager: ManagerModel) => {
  const { whiteLabelDomain, slug } = manager;

  if (whiteLabelDomain) {
    return `${getUrlProtocol()}//${whiteLabelDomain}${getUrlPort()}`;
  }

  return getWhiteLabelSubDomainBaseUrl(slug || "");
};
