/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuHomeIcon = createIcon({
  displayName: "MenuHomeIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <g clipPath="url(#clip0_12051_16976)">
        <path
          d="M10 23V17H14V23"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M1 11L12 2L23 11"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M4 8.545V4"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
        />
        <path
          d="M4 13V23H20V13"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path d="M13 11H11V13H13V11Z" fill="currentColor" />
        <path
          d="M13 11H11V13H13V11Z"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_12051_16976">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  ),
});
export default MenuHomeIcon;
