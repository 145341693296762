import { Box, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { Button } from "../../general";
import {
  ExportIcon,
  FilterIconAlt,
  ImportIcon,
  VeriHeading,
} from "../../index";

const SectionHeading = ({
  hasImport,
  hasExport,
  hasDivider,
  filters,
  actions,
  title,
  subTitle,
  preTitle,
  onFilterClick,
  actionsExtra,
  ...rest
}) => {
  const hasFilters = filters !== null && filters !== undefined;

  return (
    <>
      <Flex mb={4} {...rest}>
        <Box flexGrow={1}>
          {preTitle && (
            <Box color={"text.grey"} textStyle={"bodyRegular"}>
              {preTitle}
            </Box>
          )}
          <VeriHeading textStyle={rest?.textStyle || "h3"}>{title}</VeriHeading>
          {subTitle && (
            <Box color={"text.grey"} textStyle={"bodyRegular"}>
              {subTitle}
            </Box>
          )}
        </Box>
        <Flex>
          {hasExport && (
            <Button
              mr={2}
              aria-label="Export"
              borderColor={"border.grey"}
              borderWidth={1}
              alignItems={"center"}
              color={"text.grey"}
              outline
            >
              <ExportIcon boxSize={5} />
            </Button>
          )}
          {hasImport && (
            <Button
              mr={2}
              aria-label="Import"
              borderColor={"border.grey"}
              borderWidth={1}
              alignItems={"center"}
              color={"text.grey"}
              outline
            >
              <ImportIcon boxSize={5} />
            </Button>
          )}
          {hasFilters && (
            <Button
              mr={2}
              aria-label="Import"
              borderColor={"border.grey"}
              borderWidth={1}
              alignItems={"center"}
              onClick={onFilterClick}
              color={"text.grey"}
              outline
            >
              <FilterIconAlt boxSize={5} />
            </Button>
          )}
          {(actions || []).map((action, actionIndex) => {
            const mr = actionIndex === actions.length - 1 ? 0 : 2;

            if (action.customActionElement) {
              return (
                <Box key={actionIndex} height="100%" mr={mr}>
                  {action.customActionElement}
                </Box>
              );
            }

            return <Button key={actionIndex} mr={mr} {...action} />;
          })}
          {actionsExtra && <>{actionsExtra}</>}
        </Flex>
      </Flex>
      {hasDivider && <Divider mb={8} />}
    </>
  );
};

export default SectionHeading;
