/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ArrowLeftIcon = createIcon({
  displayName: "ArrowLeftIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M1.29301 11.293L9.00001 3.586L10.414 5L4.41401 11H22C22.553 11 23 11.448 23 12C23 12.552 22.553 13 22 13H4.41401L10.414 19L9.00001 20.414L1.29301 12.707C0.902006 12.316 0.902006 11.684 1.29301 11.293Z" fill="currentColor" /></g>)
});
export default ArrowLeftIcon;