import { v4 as uuidv4 } from "uuid";

const initialData = {
  notifications: [],
  lastUpdate: null,
  drawerIsOpen: false,
};

export interface NotificationStorageType {
  notifications: never[];
  lastUpdate: string | null;
  drawerIsOpen: boolean | null;
}

export enum NotificationActionTypes {
  ADD = "NOTIFICATIONS_ADD",
  SET = "NOTIFICATIONS_SET",
  SET_DRAWER_IS_OPEN = "DRAWER_IS_OPEN_SET",
}

interface ActionNotificationsAdd {
  type: NotificationActionTypes.ADD;
  notification: never;
}
interface ActionDrawerIsOpenSet {
  type: NotificationActionTypes.SET_DRAWER_IS_OPEN;
  drawerIsOpen: boolean | null;
}

interface ActionNotificationsSet {
  type: NotificationActionTypes.SET;
  notifications: never[];
}

type NotificationActions =
  | ActionNotificationsAdd
  | ActionNotificationsSet
  | ActionDrawerIsOpenSet;

const notifications = (state = initialData, action: NotificationActions) => {
  switch (action.type) {
    case NotificationActionTypes.ADD: {
      return {
        ...state,
        notifications: [action.notification, ...state.notifications],
        lastUpdate: uuidv4(),
      };
    }
    case NotificationActionTypes.SET: {
      return {
        ...state,
        notifications: action.notifications,
        lastUpdate: uuidv4(),
      };
    }
    case NotificationActionTypes.SET_DRAWER_IS_OPEN: {
      return { ...state, drawerIsOpen: action.drawerIsOpen };
    }

    default:
      return { ...state };
  }
};

export default notifications;
