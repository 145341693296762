/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DistributionJsonApiModel = {
  id: "",
  amount: "",
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "gross-distribution": "",
  withholding: "",
  "distribution-preference": {
    jsonApi: "hasOne",
    type: "distribution-preferences",
  },
  "split-value": "",
};
