/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CryptoAccountJsonApiModel = {
  id: "",
  nickname: "",
  address: "",
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
};
