/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const FilterIcon = createIcon({
  displayName: "FilterIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M22 4L14 12V20L10 22V12L2 4V1H22V4Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default FilterIcon;