/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum VerificationTypeEnumType {
  Accreditation = "accreditation",
  Identity = "identity",
}
