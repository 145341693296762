import React from "react";
import { MenuListProps } from "./MenuList.types";
import { Box, ScaleFade } from "@chakra-ui/react";

const MenuList: React.FC<MenuListProps> = ({
  children,
  setIsOpen,
  ...rest
}) => {
  const _children = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setIsOpen });
    }
    return child;
  });

  return (
    <ScaleFade initialScale={0.9} in={true}>
      <Box borderRadius={4} boxShadow={4} background={"#fff"} {...rest}>
        {_children}
      </Box>
    </ScaleFade>
  );
};

export default MenuList;
