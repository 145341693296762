import React from "react";
import usaStates from "../../../../defines/usaStates";
import { VeriSelect } from "../../VeriSelect";
import { VeriStateSelectProps } from "./VeriStateSelect.types";

const VeriStateSelect: React.FC<VeriStateSelectProps> = React.forwardRef<
  HTMLSelectElement,
  VeriStateSelectProps
>(({ ...selectProps }, ref) => {
  return (
    <VeriSelect label="State" {...selectProps} ref={ref}>
      <option value="">Select</option>
      {usaStates.map((state, i) => (
        <option key={i} value={state.name}>
          {state.name}
        </option>
      ))}
    </VeriSelect>
  );
});

VeriStateSelect.displayName = "VeriStateSelect";

export default VeriStateSelect;
