import React from "react";
import { BulkActionWithSubsProps } from "./BulkActionWithSubs.types";
import { Button } from "../../../Button";
import { Box, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { VeriTableAction } from "../../VeriTable.types";

const BulkActionWithSubs: React.FC<BulkActionWithSubsProps> = ({
  actions,
  actionName,
  bulkSelection,
  tableObject,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const action = actions[actionName];
  const browseForFile = action.browseForFile;

  const doSubAction = async (action: VeriTableAction) => {
    const _doAction = async (e?: React.ChangeEvent<HTMLInputElement>) => {
      if (browseForFile && action.fileCallback && e?.target?.files) {
        await action.fileCallback(
          e.target.files,
          Object.keys(bulkSelection),
          tableObject,
        );
      } else if (action.callback) {
        await action.callback(Object.keys(bulkSelection), [], tableObject);
      }
    };

    setIsLoading(true);

    if (action.confirm) {
      tableObject.setConfirmData({
        isOpen: true,
        title: action.confirm.title ? action.confirm.title(null) : "",
        message: action.confirm.message ? action.confirm.message(null) : "",
        onClose: () => {
          tableObject.setConfirmData(null);
          setIsLoading(false);
        },
        onConfirm: async () => {
          await _doAction();
          tableObject.setConfirmData(null);
          setIsLoading(false);
        },
        cancelLabel: action.confirm.cancelLabel
          ? action.confirm.cancelLabel(null)
          : "Cancel",
        confirmLabel: action.confirm.confirmLabel
          ? action.confirm.confirmLabel(null)
          : "Confirm",
        confirmProps: action.confirm.confirmProps
          ? action.confirm.confirmProps(null)
          : {},
        cancelProps: action.confirm.cancelProps
          ? action.confirm.cancelProps(null)
          : {},
        record: null,
        inlineBanner: false,
      });

      return;
    }

    try {
      await _doAction();
    } catch (e) {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <Menu>
      <MenuButton as={Box} _hover={{ cursor: "pointer" }}>
        <Button
          mr={2}
          isLoading={isLoading}
          position={browseForFile ? "relative" : undefined}
          outline
        >
          <strong>{actions[actionName].label}</strong>
        </Button>
      </MenuButton>
      <MenuList overflow={"auto"} maxHeight={"400px"}>
        {actions[actionName].subs
          ? Object.keys(actions[actionName].subs || {}).map((sub, index) => (
              <MenuItem
                key={index}
                onClick={() =>
                  actions?.[actionName]?.subs?.[sub] &&
                  doSubAction(
                    actions[actionName].subs?.[sub] as VeriTableAction,
                  )
                }
              >
                {actions[actionName].subs?.[sub]?.label}
              </MenuItem>
            ))
          : undefined}
      </MenuList>
    </Menu>
  );
};

export default BulkActionWithSubs;
