import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FiltersDrawerObject, FiltersDrawerProps } from "./FiltersDrawer.types";
import { VeriDrawer } from "../VeriDrawer";
import { Button, Chip } from "../../common";
import { Box, Text } from "@chakra-ui/react";
import { Dict } from "../../../defines";
import { FilterValueType } from "../VeriDrawer/VeriDrawer.types";

const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
  filters = {},
  useUrlFilters = false,
  urlFilterKey = "filter",
  historyBehaviour = "replace", // 'replace' | 'push'
  onFiltersChange,
  textFilter,
  getRef,
}) => {
  const location = useLocation();
  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [tempFilters, setTempFilters] = React.useState<Dict>({});
  const [appliedFilters, setAppliedFilters] = React.useState<Dict>({});
  const [filterRefs, setFilterRefs] = React.useState<Dict>({});
  const [filtersRefReady, setFiltersRefReady] = React.useState(false);
  const appliedFiltersKeys = Object.keys(appliedFilters);
  const filterKeys = Object.keys(filters);

  const refTempFilters = React.useRef(tempFilters);
  refTempFilters.current = tempFilters;

  const urlSearch = useUrlFilters && filtersRefReady && location.search;

  const _setAppliedFilters = (filtersToApply: any) => {
    setAppliedFilters(filtersToApply);
    onFiltersChange &&
      onFiltersChange(
        Object.keys(filtersToApply).map((filterName) => ({
          filterName,
          value: filtersToApply[filterName],
          type: filterRefs[filterName].valueType,
        })),
      );
  };

  const _setFilterRef = (filterName: string, ref: any) => {
    filterRefs[filterName] = ref;
    setFilterRefs(filterRefs);

    if (Object.keys(filterRefs).length === filterKeys.length) {
      setFiltersRefReady(true);
    }
  };

  const _clearFilters = () => {
    if (useUrlFilters) {
      const urlParams = new URLSearchParams(location.search);
      filterKeys.forEach((filterKey) => {
        urlParams.delete(`${urlFilterKey}[${filterKey}]`);
      });

      if (textFilter) {
        urlParams.delete(`${urlFilterKey}[text]`);
      }

      history[historyBehaviour] &&
        history[historyBehaviour](
          "?" + decodeURIComponent(urlParams.toString()),
        );
    } else {
      setTempFilters({});
      _setAppliedFilters({});
    }

    setIsDrawerOpen(false);
  };

  const clearTextFilter = () => {
    if (useUrlFilters) {
      const urlParams = new URLSearchParams(location.search);

      if (textFilter) {
        urlParams.delete(`${urlFilterKey}[text]`);
      }

      history[historyBehaviour] &&
        history[historyBehaviour](
          "?" + decodeURIComponent(urlParams.toString()),
        );
    }

    setIsDrawerOpen(false);
  };

  const applyFilters = (filtersToApply: any) => {
    const tempFilters = filtersToApply;

    if (useUrlFilters) {
      const urlParams = new URLSearchParams(location.search);
      filterKeys.forEach((filterKey) => {
        const urlFilterName = `${urlFilterKey}[${filterKey}]`;
        const filterRef = filterRefs[filterKey];

        urlParams.delete(urlFilterName);

        let filterValue = "";

        switch (filterRef.valueType) {
          case FilterValueType.ARRAY:
            if (
              Array.isArray(tempFilters[filterKey]) &&
              tempFilters[filterKey].length > 0
            )
              filterValue = tempFilters[filterKey].join(",");
            break;
          default:
            filterValue = tempFilters[filterKey];
        }

        if (filterValue) urlParams.append(urlFilterName, filterValue);
      });

      history[historyBehaviour] &&
        history[historyBehaviour](
          "?" + decodeURIComponent(urlParams.toString()),
        );
    } else {
      _setAppliedFilters({ ...tempFilters });
    }

    setIsDrawerOpen(false);
    // setTempFilters({});
  };

  const _applyFilters = () => {
    applyFilters(tempFilters);
  };

  const changeTempFilter = (filterName: string, value: any) => {
    const newTempFilters = { ...refTempFilters.current };
    newTempFilters[filterName] = Array.isArray(value) ? [...value] : value;

    if (
      Array.isArray(newTempFilters[filterName]) &&
      newTempFilters[filterName].length === 0
    ) {
      delete newTempFilters[filterName];
    }

    setTempFilters(newTempFilters);

    return newTempFilters;
  };

  const _removeFilter = (filterName: string, filterValue: any) => {
    if (
      filterRefs[filterName].valueType === FilterValueType.ARRAY &&
      tempFilters[filterName]
    ) {
      applyFilters(
        changeTempFilter(
          filterName,
          tempFilters[filterName].filter((f: any) => f !== filterValue),
        ),
      );
    }
  };

  const _setFilter = (
    filterName: string,
    filterValue: any,
    append?: boolean,
  ) => {
    if (filterRefs[filterName].valueType === FilterValueType.ARRAY) {
      const newFilterValues = !append
        ? [filterValue]
        : (refTempFilters.current[filterName] || []).filter(
            (f: any) => f !== filterValue,
          );

      newFilterValues.push(filterValue);

      applyFilters(changeTempFilter(filterName, newFilterValues));
    }
  };

  React.useEffect(() => {
    if (useUrlFilters && urlSearch !== false) {
      const urlActiveFilters: Dict = {};
      const urlParams = new URLSearchParams(urlSearch);
      filterKeys.forEach((filterKey) => {
        const urlFilterName = `${urlFilterKey}[${filterKey}]`;
        const filter = urlParams.get(urlFilterName);
        const filterRef = filterRefs[filterKey];
        let filterValue = null;

        if (filter !== undefined && filter !== null) {
          switch (filterRef.valueType) {
            case FilterValueType.ARRAY:
              // eslint-disable-next-line no-case-declarations
              const valueArray = (filter && filter.split(",")) || [];
              if (Array.isArray(valueArray) && valueArray.length > 0)
                filterValue = valueArray;
              break;
            case FilterValueType.BOOLEAN:
              filterValue = filter === "1";
              break;
            default:
              filterValue = filter;
          }

          if (filterValue) urlActiveFilters[filterKey] = filterValue;
        }
      });

      _setAppliedFilters({ ...urlActiveFilters });
      setTempFilters({ ...urlActiveFilters });
    }
  }, [urlSearch]);

  const selfObject = React.useRef<FiltersDrawerObject>(
    {} as FiltersDrawerObject,
  );
  selfObject.current = {
    openDrawer: () => setIsDrawerOpen(true),
    closeDrawer: () => setIsDrawerOpen(false),
    setFilter: (filterName, filterValue, append) =>
      _setFilter(filterName, filterValue, append),
    clearFilters: _clearFilters,
  };

  React.useEffect(() => {
    if (getRef && typeof getRef === "function") {
      getRef(selfObject.current);
    }
  }, []);

  const FilterList = (
    <>
      {filterKeys.map((filterName) => {
        const filter = filters[filterName];
        return (
          <Box key={"filter-" + filterName} mt={6}>
            <Text textStyle={"bodyRegularBold"} color={"text.black"} mb={2}>
              {filter.label}
            </Text>
            <filter.filter
              key={`filter-picker-${filterName}`}
              options={filter.filterOptions}
              value={tempFilters[filterName]}
              onChange={(selectedFilters: any) =>
                changeTempFilter(filterName, selectedFilters)
              }
              filterRef={(ref: any) => _setFilterRef(filterName, ref)}
            />
          </Box>
        );
      })}
    </>
  );

  return (
    <Box>
      {(textFilter || appliedFiltersKeys.length > 0) && (
        <Box mb={4} key={"filter-text"}>
          {textFilter && (
            <Chip
              label={`Search = ${textFilter}`}
              onClickClose={clearTextFilter}
              mb={1}
              mr={1}
            />
          )}
          {appliedFiltersKeys.map((filterName) => {
            const filter = appliedFilters[filterName];
            if (Array.isArray(filter)) {
              return filter.map((filterValue, i) => {
                return (
                  <Chip
                    key={`filter-${i}`}
                    label={`${
                      filters[filterName].chipLabel || filters[filterName].label
                    } = ${filterRefs[filterName].getValueLabel(filterValue)}`}
                    onClickClose={() => _removeFilter(filterName, filterValue)}
                    mb={1}
                    mr={1}
                  />
                );
              });
            }

            return <></>;
          })}
          <Box display="inline-block">
            <Button
              onClick={_clearFilters}
              textProps={{ p: 1, textDecoration: "underline" }}
              empty
            >
              Clear all
            </Button>
          </Box>
        </Box>
      )}

      <VeriDrawer
        title={"Filters"}
        size={"md"}
        isOpen={isDrawerOpen}
        placement="right"
        onClose={() => setIsDrawerOpen(false)}
        drawerFooterProps={{ boxShadow: "0px -1px 0px #DCE1E7" }}
        secondaryButton={(size) => (
          <>
            {appliedFiltersKeys.length > 0 && (
              <Button
                isLarge
                onClick={_clearFilters}
                empty
                color={size === "full" ? "text.black" : ""}
              >
                {size === "full" ? "Clear" : "Clear all"}
              </Button>
            )}
          </>
        )}
        primaryButton={(size) => (
          <Button w="100%" onClick={_applyFilters} isLarge>
            {size === "full" ? "Show results" : "Apply"}
          </Button>
        )}
      >
        {FilterList}
      </VeriDrawer>
      {!isDrawerOpen && <Box display={"none"}>{FilterList}</Box>}
    </Box>
  );
};

export default FiltersDrawer;
