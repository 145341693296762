/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImportDistributionPreferenceJsonApiModel = {
  id: "",
  import: {
    jsonApi: "hasOne",
    type: "imports",
  },
  "external-id": "",
  row: "",
  status: "",
  errors: "",
  metadata: "",
  "created-at": "",
  "updated-at": "",
  "address-id": "",
  "create-address": "",
  "client-account-id": "",
  "account-mnumber": "",
  "account-name": "",
  "offering-id": "",
  "distribution-preference": "",
  "address-type": "",
  address1: "",
  address2: "",
  city: "",
  region: "",
  postcode: "",
  "postcode-suffix": "",
  country: "",
  "bank-name": "",
  "bank-account-type": "",
  "bank-routing-number": "",
  "bank-account-number": "",
  "split-basis": "",
  "split1-type": "",
  "split1-value": "",
  "split2-type": "",
  "split2-value": "",
};
