/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AddressJsonApiModel = {
  id: "",
  address1: "",
  address2: "",
  city: "",
  region: "",
  country: "",
  "postal-code": "",
  "postal-code-suffix": "",
  location: {
    jsonApi: "hasOne",
    type: "locations",
  },
  "created-at": "",
  "updated-at": "",
};
