import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { VeriHeading } from "../VeriHeading";
import { TitleSubtitleProps } from "./TitleSubtitle.types";

const TitleSubtitle: React.FC<TitleSubtitleProps> = ({
  title,
  subtitle,
  noMarginBottom,
  ...rest
}) => {
  return (
    <Flex flexDirection="column" {...rest}>
      {title && (
        <VeriHeading
          fontSize="28px"
          lineHeight="42px"
          textStyle="h3"
          as="h1"
          mb={subtitle ? 2 : !noMarginBottom ? 8 : undefined}
        >
          {title}
        </VeriHeading>
      )}
      {subtitle && (
        <Text mb={!noMarginBottom ? 8 : undefined} lineHeight={4}>
          {subtitle}
        </Text>
      )}
    </Flex>
  );
};

export default TitleSubtitle;
