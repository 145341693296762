/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum MethodtypeType {
  Depreciation = "Depreciation",
  Fee = "Fee",
  InterestIncome = "Interest Income",
  LossOfCapital = "Loss of Capital",
  "NetIncome (loss)" = "Net Income (Loss)",
  ReturnOfCapital = "Return of Capital",
}
