/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ArrowDownIcon = createIcon({
  displayName: "ArrowDownIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12.707 22.707L20.414 15L19 13.586L13 19.586V2C13 1.447 12.552 1 12 1C11.448 1 11 1.447 11 2V19.586L5 13.586L3.586 15L11.293 22.707C11.684 23.098 12.316 23.098 12.707 22.707Z" fill="currentColor" /></g>)
});
export default ArrowDownIcon;