/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UsersActivityJsonApiModel = {
  id: "",
  "user-id": "",
  "created-by-user": {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-by-user-id": "",
  "activity-type": "",
  "activity-notes": "",
  "activity-utm": "",
  "activity-timestamp": "",
  "users-email-message-id": "",
  "created-at": "",
  "updated-at": "",
};
