import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Fade,
  Flex,
  Text,
} from "@chakra-ui/react";
import { CheckCircleIcon, VeriSelect } from "@verivest/shared-components";
import { useNotificationsModel } from "@verivest/shared-components/src/api/hooks/models/useNotificationsModel/useNotificationsModel";
import Button from "@verivest/shared-components/src/buttons/Button";
import { InfiniteScroll } from "@verivest/shared-components/src/components/common/InfiniteScroll/InfiniteScroll";
import { NotificationType } from "@verivest/shared-components/src/components/notification/notifications.types";
import { useDynamicConfig } from "@verivest/shared-components/src/dynamic-config";
import DynamicConfigFlags from "@verivest/shared-components/src/dynamic-config/configs";
import { useStandardApiHandler } from "@verivest/shared-components/src/error-handling/useStandardApiErrorHandler";
import React from "react";
import { NotificationList } from "./NotificationList";

interface NotificationsDrawerProps extends Omit<DrawerProps, "children"> {
  notifications: NotificationType[] | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  onLoad: () => void;
  moreToLoad: boolean;
  mutate: () => void;
  hasUnviewed: boolean;
  finalFocusRef?: React.RefObject<HTMLElement>;
}
const NotificationsDrawer = ({
  notifications,
  isOpen,
  onClose,
  onLoad,
  moreToLoad,
  mutate,
  hasUnviewed,
  finalFocusRef,
  ...rest
}: NotificationsDrawerProps) => {
  const { wrapApiRequest, isLoading } = useStandardApiHandler();
  const notificationsModel = useNotificationsModel({});

  const dynamicConfig = useDynamicConfig();
  const manager = dynamicConfig.get(DynamicConfigFlags.DASHBOARD_MANAGER);
  const marginRight = manager ? "95px" : "385px";

  const [spaceToRight, setSpaceToRight] = React.useState<string>(marginRight);

  const calculateMargin = () => {
    const iconElement = finalFocusRef?.current;
    if (iconElement) {
      const newSpacing =
        window.innerWidth - iconElement.getBoundingClientRect().right;
      setSpaceToRight(`${newSpacing}px`);
    }
  };

  React.useEffect(() => {
    calculateMargin();
    window.addEventListener("resize", calculateMargin);
    return () => {
      window.removeEventListener("resize", calculateMargin);
    };
  }, []);

  const markAllAsRead = wrapApiRequest(async () => {
    await notificationsModel.markAllAsViewed();
    mutate();
  });

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} {...rest}>
        <DrawerOverlay>
          <DrawerContent
            mr={{ base: 0, lg: spaceToRight }}
            mt={{ base: 0, lg: "45px" }}
          >
            <Flex
              px="24px"
              alignItems="center"
              justifyContent="space-between"
              h="76px"
            >
              <Flex gap={3} alignItems="center">
                <Text as="h5" fontStyle="bodySmallBold">
                  Notifications
                </Text>
                <VeriSelect
                  isSmall
                  value="all"
                  isDisabled
                  selectProps={{
                    border: "none",
                    fontStyle: "bodyXS",
                  }}
                >
                  <option value="all">All</option>
                </VeriSelect>
              </Flex>
              {hasUnviewed ? (
                <Button
                  empty
                  fontStyle="bodySmall"
                  onClick={markAllAsRead}
                  isLoading={isLoading}
                  isDisabled={!hasUnviewed}
                >
                  Mark all as read
                  <CheckCircleIcon mt={"-3px"} ml={1} />
                </Button>
              ) : (
                <Fade in={true}>
                  <Text textStyle="bodySmallBold" color="text.grey">
                    All notifications are read{" "}
                    <CheckCircleIcon mt={"-3px"} ml={1} />
                  </Text>
                </Fade>
              )}
            </Flex>
            <DrawerBody px={0}>
              <InfiniteScroll
                isLoading={isLoading}
                moreToLoad={moreToLoad}
                onLoad={onLoad}
              >
                <NotificationList notifications={notifications} />
              </InfiniteScroll>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export default NotificationsDrawer;
