/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CalculateBillingEventAmountOutputJsonApiModel = {
  id: "",
  "applied-billing-schedule-tier-factor": "",
  amount: "",
};
