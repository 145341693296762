/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const PlaidInstitutionJsonApiModel = {
  id: "",
  "plaid-item": {
    jsonApi: "hasOne",
    type: "plaid-items",
  },
  name: "",
  logo: "",
  url: "",
};
