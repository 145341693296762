import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { HelpIcon } from "../../../icons";
import { VeriTooltip } from "../../popover";

export interface LabelWithTooltipProps {
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
}
export const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({
  label,
  tooltip,
}) => {
  const [helpTooltipRef, setHelpTooltipRef] =
    React.useState<HTMLDivElement | null>(null);

  return (
    <Flex display={"inline-flex"} alignItems={"center"} gap={1}>
      {label}
      <Box ref={setHelpTooltipRef} mt={"-3px"}>
        <HelpIcon boxSize={4} color={"icons.grey"} />
      </Box>
      <VeriTooltip
        py={"32px"}
        offsetTop={-32}
        trigger={helpTooltipRef || undefined}
        placement={"top"}
        zIndex={"overlay"}
      >
        <Box maxW={"300px"} textTransform="none" textStyle={"bodyRegular"}>
          {tooltip}
        </Box>
      </VeriTooltip>
    </Flex>
  );
};
