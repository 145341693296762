const allowedParams = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_name",
  "utm_term",
  "initial_utm_source",
  "initial_utm_medium",
  "initial_utm_campaign",
  "initial_utm_content",
  "initial_utm_name",
  "initial_utm_term",
  "hsa_acc",
  "hsa_cam",
  "hsa_grp",
  "hsa_ad",
  "hsa_net",
  "hsa_src",
  "hsa_ver",
  "hsa_la",
  "hsa_ol",
  "gclid",
];

function checkIfInitialParamsExist(params: Record<any, any>) {
  return Object.keys(params).find((k) => k.includes("initial"));
}

class UTMParams {
  /**
   * Get utm params allowed by GA
   *
   * @return {Object}
   */
  parse() {
    const urlSearch = new URL(window.location as never);
    const urlParams = new URLSearchParams(urlSearch.search);
    const parsedParams: Record<any, any> = {};
    allowedParams.forEach((key) => {
      const paramValue = urlParams.get(key);
      if (paramValue) {
        parsedParams[key] = paramValue;
      }
    });
    return parsedParams;
  }

  /**
   * Save UTM params in localwindow.sessionStorage
   *
   * @param {Object} params
   * @return {Boolean}
   */
  save(params: Record<any, any>) {
    if (!params || !allowedParams.some((key) => !!params[key])) {
      return false;
    }
    try {
      const paramsToSave: Record<any, any> = {};
      const initialParams: Record<any, any> = {};

      Object.assign(paramsToSave, params);

      if (window.sessionStorage.getItem("utmSavedParams")) {
        let existingParams: Record<any, any> = {};

        try {
          existingParams = JSON.parse(
            window.sessionStorage.getItem("utmSavedParams") || "",
          );
        } catch (e) {
          existingParams = {};
        }

        if (checkIfInitialParamsExist(existingParams)) {
          Object.keys(existingParams).forEach((k) => {
            if (k.includes("initial_")) {
              initialParams[k] = existingParams[k];
            }
          });
        }
      } else {
        Object.keys(paramsToSave).forEach((k) => {
          initialParams["initial_" + k] = paramsToSave[k];
        });
      }

      Object.assign(paramsToSave, initialParams);

      window.sessionStorage.setItem(
        "utmSavedParams",
        JSON.stringify(paramsToSave),
      );
      return true;
    } catch (e) {
      throw new Error(e as never);
    }
  }

  /**
   * Reads UTM params from localwindow.sessionStorage
   *
   * @return {Object}
   */
  get() {
    const savedParams = window.sessionStorage.getItem("utmSavedParams");
    if (savedParams) {
      return JSON.parse(savedParams);
    }
    return null;
  }
}

export default UTMParams;
