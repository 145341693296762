/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GroupedInvestmentJsonApiModel = {
  id: "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "inception-date": "",
  "invested-date": "",
  "realized-date": "",
  "has-imports": "",
  "ownership-percentage": "",
  "capital-in": "",
  "contributions-value": "",
  "committed-value": "",
  "capital-balance": "",
  "return-of-capital": "",
  "return-on-capital": "",
  "reinvest-value": "",
  "market-value": "",
  "ncc-value": "",
  status: "",
  distributions: "",
  "withholding-value": "",
  "distribution-preference": {
    jsonApi: "hasOne",
    type: "distribution-preferences",
  },
  documents: {
    jsonApi: "hasMany",
    type: "documents",
  },
  contributions: "",
  "distributions-data": "",
  redemptions: "",
  "net-income": "",
  ownership: "",
};
