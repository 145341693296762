import React from "react";
import { MFAModalView } from "./MFAModalView";
import { useMfaModal } from "../context/MfaModalContext/MfaModalContext";
import { useAuth } from "../../../../auth";

const MfaSuccessStep: React.FC = () => {
  const state = useMfaModal();

  const { auth } = useAuth();
  const turnOff = async () => {
    state.setState({ isLoading: true });
    await auth.disableMfa();
  };

  return (
    <MFAModalView
      title="Turn off two-factor authentication?"
      subTitle="Turning off two-factor authentication will remove the extra security on your account and you’ll use only your password to sign in."
      continueLabel="Turn off"
      backLabel={"Cancel"}
      onBack={state.closeModal}
      onContinue={() =>
        turnOff().finally(() =>
          auth.refreshProfile().finally(() => {
            state.setState({ isLoading: false });
            state.closeModal();
          }),
        )
      }
    />
  );
};

export default MfaSuccessStep;
