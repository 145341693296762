/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetTransfersOutputJsonApiModel = {
  id: "",
  transfers: {
    jsonApi: "hasMany",
    type: "transfers",
  },
};
