import JsonApi from "devour-client";
import { jsonApiModels } from "./models/jsonApiModels";

export const defineJsonApiModels = (jsonApi: JsonApi) => {
  Object.keys(jsonApiModels).forEach((modelName) =>
    jsonApi.define(modelName, (jsonApiModels as DictRef)[modelName]),
  );

  // TODO: These are duplicated models, should check if they are needed
  jsonApi.define("sponsoremployee", jsonApiModels["manager-employee"]); // Duplicate of "manager-employee"
  jsonApi.define("sponsoremployee", jsonApiModels["manager"]); // Duplicate of "manager-employee"
  jsonApi.define("track-records", jsonApiModels["track-record"]); // Duplicate of "track-record"
  jsonApi.define("attribute-item", jsonApiModels["attributeitem"]); // Duplicate of "track-record"
  jsonApi.define("principalhistory", jsonApiModels["principal-history"]); // Duplicate of "principal-history"
  jsonApi.define("role", jsonApiModels["usersrole"]); // Duplicate of "userrole"
  jsonApi.define("verifications", jsonApiModels["verification"]); // Duplicate of "verification"
  jsonApi.define("principal-histories", jsonApiModels["principal-history"]);

  jsonApi.define("flattened-investment", {
    items: "",
    totalAmount: "",
  });
};
