/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from "@chakra-ui/react";
import React from "react";
import { statusToColor } from "../../../../utils/utils";
import ColoredTagComp from "../../ColoredTag";

const ColoredTag = ({
  value,
  record,
  options,
  column,
  tableObject,
  ...rest
}) => {
  let { columnIndex, recordIndex, columnName, ...tagRest } = rest;

  return (
    <>
      {value ? (
        <Box {...tagRest}>
          <ColoredTagComp
            width="fit-content"
            bg={statusToColor(value) + "bg"}
            color={statusToColor(value)}
            whiteSpace="nowrap"
          >
            {value}
          </ColoredTagComp>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default ColoredTag;
