import React from "react";
import { Box } from "@chakra-ui/react";
import { VeriTableRendererProps } from "../VeriTable.types";

const Number: React.FC<VeriTableRendererProps> = ({ value }) => {
  return (
    <Box
      display={{ base: "inline", md: "block" }}
      w={{ base: "auto", md: "100%" }}
      textAlign={"right"}
    >
      {value}
    </Box>
  );
};

export default Number;
