/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ExternalLinkIcon = createIcon({
  displayName: "ExternalLinkIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M11 13L22 2" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M14 2H22V10" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M9 4H2V22H20V15" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default ExternalLinkIcon;