/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WorkflowLogJsonApiModel = {
  id: "",
  "workflow-instance-id": "",
  "workflow-instance": {
    jsonApi: "hasOne",
    type: "workflow-instance",
  },
  session: "",
  status: "",
  description: "",
  metadata: "",
  "created-at": "",
};
