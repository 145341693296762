/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetBillingInvoiceOutputJsonApiModel = {
  id: "",
  "billing-invoice": {
    jsonApi: "hasOne",
    type: "billing-invoices",
  },
};
