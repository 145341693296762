import React from "react";

export interface InvestorPortalState {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
}

export const InvestorPortalContext = React.createContext<
  InvestorPortalState | undefined
>(undefined);

export const InvestorPortalProvider: React.FC = ({ children }) => {
  const [isMobileMenuOpen, _setIsMobileMenuOpen] = React.useState(false);

  const setIsMobileMenuOpen = (isOpen: boolean) => {
    const rootEl = document.body as HTMLElement;
    if (rootEl) {
      if (isOpen) rootEl.classList.add("tw:overflow-hidden", "h-screen");
      else rootEl.classList.remove("tw:overflow-hidden", "h-screen");
    }
    _setIsMobileMenuOpen(isOpen);
  };

  return (
    <InvestorPortalContext.Provider
      value={{ isMobileMenuOpen, setIsMobileMenuOpen }}
    >
      {children}
    </InvestorPortalContext.Provider>
  );
};

export const useInvestorPortal = () => {
  const context = React.useContext(InvestorPortalContext);
  if (context === undefined) {
    throw new Error(
      "useInvestorPortal must be used within a InvestorPortalProvider",
    );
  }
  return context;
};
