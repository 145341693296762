/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetMetropolitanStatisticalAreasOutputJsonApiModel = {
  id: "",
  "metropolitan-statistical-areas": {
    jsonApi: "hasMany",
    type: "metropolitan-statistical-areas",
  },
};
