/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallDefinitionJsonApiModel = {
  id: "",
  "offering-id": "",
  "display-name": "",
  "waterfall-definition-rules": {
    jsonApi: "hasMany",
    type: "waterfall-definition-rules",
  },
  "is-template": "",
  group: "",
  "group-order": "",
};
