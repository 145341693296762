/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AccountJsonApiModel = {
  id: "",
  mnumber: "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  tags: {
    jsonApi: "hasMany",
    type: "tags",
  },
  users: {
    jsonApi: "hasMany",
    type: "users",
  },
  "name-of-record": "",
  "account-type": "",
  "is-erisa": "",
  "primary-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "mailing-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "tax-id-number": "",
  "tax-id-type": "",
  "signer-title": "",
  email: "",
  "first-name": "",
  "middle-name": "",
  "last-name": "",
  suffix: "",
  citizenship: "",
  domicile: "",
  "date-of-birth": "",
  "is-director": "",
  "is-affiliated": "",
  "investing-with-spouse": "",
  name: "",
  "entity-type": "",
  "entity-registered-date": "",
  "entity-registered-type": "",
  "entity-registered-state": "",
  "entity-registered-country": "",
  custodian: {
    jsonApi: "hasOne",
    type: "custodians",
  },
  "custodian-account-number": "",
  "custodied-account-type": "",
  "is-taxable": "",
  "ta-account-id": "",
  "ta-party-link-id": "",
  "ta-entity-link-id": "",
  "ta-party-id": "",
  "ta-entity-id": "",
  "accreditation-verified-at": "",
  "accreditation-status": "",
  "accreditation-expires-on": "",
  "identity-verified-at": "",
  "identity-status": "",
  signers: {
    jsonApi: "hasMany",
    type: "accounts",
  },
  members: {
    jsonApi: "hasMany",
    type: "accounts",
  },
  "custodied-owner": {
    jsonApi: "hasOne",
    type: "accounts",
  },
  individual1: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  individual2: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  owner: {
    jsonApi: "hasOne",
    type: "users",
  },
  "documents-count": "",
  holdings: {
    jsonApi: "hasMany",
    type: "holdings",
  },
  "investments-count": "",
  "contributions-total": "",
  "has-imported-investments": "",
  "signers-to-save": [],
  "members-to-save": [],
  "users-accounts": {
    jsonApi: "hasMany",
    type: "users-accounts",
  },
  "holdings-total-mv": "",
  "holdings-total-ncc": "",
  "partner-name": "",
  "partner-tin": "",
  "tax-classification": "",
  "corporation-type": "",
  "entity-description": "",
  "is-locked": "",
  "created-at": "",
  "updated-at": "",
};
