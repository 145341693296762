/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ManagerContactJsonApiModel = {
  id: "",
  "first-name": "",
  "last-name": "",
  email: "",
  title: "",
  "img-url": "",
  "linkedin-url": "",
  "twitter-url": "",
};
