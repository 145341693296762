import { Box } from "@chakra-ui/react";
import React from "react";
import { formatMoneyWithSymbol } from "../../../../utils/utils";

const Money = ({ value, column }) => {
  return (
    <Box
      display={{ base: "inline", md: "block" }}
      w={{ base: "auto", md: "100%" }}
      textAlign={"right"}
    >
      {column.emptyReplace && !value
        ? column.emptyReplace
        : formatMoneyWithSymbol(value || 0)}
    </Box>
  );
};

export default Money;
