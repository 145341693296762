import DynamicConfigFlags from "../configs";
import useDynamicConfig from "./useDynamicConfig";
import { ManagerModel } from "../../api/models";

export default function useGetDynamicConfigDashboardManager() {
  const dynamicConfig = useDynamicConfig();
  return dynamicConfig.get(DynamicConfigFlags.DASHBOARD_MANAGER) as
    | ManagerModel
    | undefined;
}
