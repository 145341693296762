/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ContributionJsonApiModel = {
  id: "",
  investment: {
    jsonApi: "hasOne",
    type: "investments",
  },
  security: {
    jsonApi: "hasOne",
    type: "securities",
  },
  amount: "",
  "effective-date": "",
  status: "",
  "effective-date-str": "",
  "equity-holding-id": "",
  "new-holding-label": "",
  "create-new-holding": "",
  rate: "",
};
