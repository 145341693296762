/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UsersEmailJsonApiModel = {
  id: "",
  "user-id": "",
  email: "",
  "email-type": "",
  "created-at": "",
  "updated-at": "",
};
