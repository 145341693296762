/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const AlertCircleIcon = createIcon({
  displayName: "AlertCircleIcon",
  viewBox: "0 0 17 17",
  path: (
    <g fill="none">
      <g clipPath="url(#clip0_2642_23786)">
        <path
          d="M8.49967 4.9585C8.89088 4.9585 9.20801 5.27563 9.20801 5.66683V8.50016C9.20801 8.89136 8.89088 9.2085 8.49967 9.2085C8.10847 9.2085 7.79134 8.89136 7.79134 8.50016V5.66683C7.79134 5.27563 8.10847 4.9585 8.49967 4.9585Z"
          fill="currentColor"
        />
        <path
          d="M8.49967 10.6252C8.10847 10.6252 7.79134 10.9423 7.79134 11.3335C7.79134 11.7247 8.10847 12.0418 8.49967 12.0418H8.50676C8.89796 12.0418 9.21509 11.7247 9.21509 11.3335C9.21509 10.9423 8.89796 10.6252 8.50676 10.6252H8.49967Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.708008 8.50016C0.708008 4.19694 4.19646 0.708496 8.49967 0.708496C12.8029 0.708496 16.2913 4.19694 16.2913 8.50016C16.2913 12.8034 12.8029 16.2918 8.49967 16.2918C4.19646 16.2918 0.708008 12.8034 0.708008 8.50016ZM8.49967 2.12516C4.97886 2.12516 2.12467 4.97935 2.12467 8.50016C2.12467 12.021 4.97886 14.8752 8.49967 14.8752C12.0205 14.8752 14.8747 12.021 14.8747 8.50016C14.8747 4.97935 12.0205 2.12516 8.49967 2.12516Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2642_23786">
          <rect width={17} height={17} fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  ),
});
export default AlertCircleIcon;
