/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const PrincipalHistoryJsonApiModel = {
  id: "",
  "history-type": "",
  "organization-name": "",
  title: "",
  "start-date": "",
  "end-date": "",
  "expiration-date": "",
  "issued-on": "",
  principal: {
    jsonApi: "hasOne",
    type: "principals",
  },
};
