import { Box, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import { Button } from "../../../components";
import { DownloadIcon, FilterIcon, UploadIcon } from "../../../icons";
import { VeriHeading } from "../../common";
import { SectionHeadingProps } from "./SectionHeading.types";

const SectionHeading: React.FC<SectionHeadingProps> = ({
  hasImport,
  hasExport,
  hasDivider,
  filters,
  actions,
  title,
  subTitle,
  preTitle,
  onFilterClick,
  actionsExtra,
  ...rest
}) => {
  const hasFilters = filters !== null && filters !== undefined;

  return (
    <>
      <Flex mb={4} {...rest}>
        <Box flexGrow={1}>
          {preTitle && (
            <Box color={"text.grey"} textStyle={"bodyRegular"}>
              {preTitle}
            </Box>
          )}
          <VeriHeading textStyle={rest?.textStyle || "h3"}>{title}</VeriHeading>
          {subTitle && (
            <Box color={"text.grey"} textStyle={"bodyRegular"}>
              {subTitle}
            </Box>
          )}
        </Box>
        <Flex>
          {hasExport && (
            <Button
              mr={2}
              aria-label="Export"
              borderColor={"border.grey"}
              borderWidth={1}
              alignItems={"center"}
              color={"text.grey"}
              outline
            >
              <DownloadIcon boxSize={5} />
            </Button>
          )}
          {hasImport && (
            <Button
              mr={2}
              aria-label="Import"
              borderColor={"border.grey"}
              borderWidth={1}
              alignItems={"center"}
              color={"text.grey"}
              outline
            >
              <UploadIcon boxSize={5} />
            </Button>
          )}
          {hasFilters && (
            <Button
              mr={2}
              aria-label="Import"
              borderColor={"border.grey"}
              borderWidth={1}
              alignItems={"center"}
              onClick={onFilterClick}
              color={"text.grey"}
              outline
            >
              <FilterIcon boxSize={5} />
            </Button>
          )}
          {(actions || []).map(({ customComponent, ...action }, actionIndex) =>
            customComponent ? (
              customComponent
            ) : (
              <Button
                key={actionIndex}
                mr={actionIndex === (actions?.length || 0) - 1 ? 0 : 2}
                {...action}
              />
            ),
          )}
          {actionsExtra && <>{actionsExtra}</>}
        </Flex>
      </Flex>
      {hasDivider && <Divider mb={8} />}
    </>
  );
};

export default SectionHeading;
