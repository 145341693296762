import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { ColoredTag as ColoredTagComp } from "../../ColoredTag";
import { VeriTableRendererProps } from "../VeriTable.types";
import { opportunityStatusToColor } from "../../../../utils/opportunities";

const ColoredTag: React.FC<VeriTableRendererProps & BoxProps> = ({
  value,
  ...rest
}) => {
  const { ...tagRest } = rest;

  return (
    <>
      {value ? (
        <Box {...tagRest}>
          <ColoredTagComp
            width="fit-content"
            bg={opportunityStatusToColor(value) + "bg"}
            color={opportunityStatusToColor(value)}
            whiteSpace="nowrap"
          >
            {value}
          </ColoredTagComp>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default ColoredTag;
