import JsonApi from "devour-client";
import humps from "humps";
import pluralize from "pluralize";
import { Dict } from "../defines";
import { jsonApiModels } from "./models/jsonApiModels";

export interface JsonApiResponse {
  data: DictRef;
  included: DictRef;
}

export const serializeJsonApiObj = (
  jsonApi: JsonApi,
  modelName: string,
  resource: DictRef,
) => {
  return jsonApi.serialize.resource.call(jsonApi, modelName, resource);
};

export const serializeWithIncluded = (
  jsonApi: JsonApi,
  modelName: string,
  resource: DictRef,
) => {
  const serializedModel = serializeJsonApiObj(jsonApi, modelName, resource);
  const jsonApiModel = jsonApiModels[
    modelName as keyof typeof jsonApiModels
  ] as any;

  const included: any[] = Object.keys(jsonApiModel).reduce(
    (acc, attributeName) => {
      const attribute = resource[attributeName];

      if (
        !attribute ||
        jsonApiModel == null ||
        typeof jsonApiModel !== "object"
      ) {
        return acc;
      }

      let attributeModelType = jsonApiModel[attributeName]?.type;

      if (!attributeModelType) {
        return acc;
      }

      attributeModelType = pluralize.singular(attributeModelType);

      if (
        jsonApiModel[attributeName].jsonApi === "hasMany" &&
        attribute.length
      ) {
        const test = attribute.reduce((acc: any, entry: any) => {
          const {
            serializedModel: relationship,
            included: relationshipIncluded,
          } = serializeWithIncluded(jsonApi, attributeModelType, entry);
          return [...acc, relationship, ...(relationshipIncluded || [])];
        }, []);

        return [...acc, ...test];
      }
      const { serializedModel: relationship, included: relationshipIncluded } =
        serializeWithIncluded(jsonApi, attributeModelType, attribute);

      return [...acc, relationship, ...(relationshipIncluded || [])];
    },
    [] as any[],
  );

  return {
    serializedModel,
    included,
  };
};

export const deserializeJsonApiObj = <T>(
  jsonApi: JsonApi,
  response: JsonApiResponse,
) => {
  clearJsonApiClientCache(jsonApi);

  let deserializedData;
  if (!response.data) {
    deserializedData = response;
  } else if (Array.isArray(response.data)) {
    const isJsonApi = response.data.some((item: Dict) => item?.type);
    deserializedData = isJsonApi
      ? jsonApi.deserialize.collection.call(
          jsonApi,
          response.data,
          response.included,
        )
      : response.data;
  } else if (response.data.type) {
    deserializedData = jsonApi.deserialize.resource.call(
      jsonApi,
      response.data,
      response.included,
    );
  } else {
    deserializedData = response.data;
  }

  const deserializedResponse = humps.camelizeKeys(
    deserializedData,
  ) as unknown as T;

  return deserializedResponse;
};

export const clearJsonApiClientCache = (jsonApi: JsonApi) => {
  jsonApi.deserialize.cache.clear?.();
};
