import React from "react";
import { Box } from "@chakra-ui/react";
import { InvestorPortalBodyProps } from "./InvestorPortalBody.types";
import { useGetHeaderHeight } from "../../headers/InvestorPortalHeaderBase";

export const InvestorPortalBody: React.FC<InvestorPortalBodyProps> = ({
  isWhiteLabel,
  ...rest
}) => {
  const headerHeight = useGetHeaderHeight();
  return (
    <Box
      minH={`calc(100vh - ${headerHeight}px)`}
      transition={"background-color .5s"}
      bg={isWhiteLabel ? "white" : "bg.grey"}
      {...rest}
    />
  );
};
