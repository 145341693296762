/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum AccountTypeType {
  Brokerage = "Brokerage",
  Custodied = "Custodied",
  CustodiedAccount = "Custodied Account",
  Entity = "Entity",
  Individual = "Individual",
  Joint = "Joint",
  Retirement = "Retirement",
  Trust = "Trust",
}
