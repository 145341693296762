import { useAuth } from "@verivest/shared-components";
import { useTheme } from "@chakra-ui/react";
import { DEFAULT_IMPERSONATING_HEADER_HEIGHT } from "../ImpersonatingHeader";
import { DEFAULT_HEADER_HEIGHT } from "./InvestorPortalHeaderBase";

export function useGetHeaderHeight() {
  const { auth } = useAuth();
  const theme = useTheme();
  const isImpersonating = auth.isManagerImpersonating();
  const impersonatingHeaderHeight =
    theme.investorPortal.headerHeights.impersonatingHeader ||
    DEFAULT_IMPERSONATING_HEADER_HEIGHT;
  const mainHeaderHeight =
    theme.investorPortal.headerHeights.mainHeader || DEFAULT_HEADER_HEIGHT;
  return mainHeaderHeight + (isImpersonating ? impersonatingHeaderHeight : 0);
}
