import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { usePopper } from "react-popper";
import { HiddenInputToReceiveFocus } from "../../../../react-hook-form";
import { getRandomHexColor } from "../../../../utils";
import { Chip, OutlinedColoredTag } from "../../../common";
import { VeriInput } from "../../VeriInput";
import { TagsInputProps } from "./TagsInput.types";

const AddButtonId = "ttags-input-dropdown-add-button";

const TagsInput = React.forwardRef<HTMLInputElement, TagsInputProps>(
  (props, ref) => {
    const {
      value,
      tags,
      allowTagCreation,
      onTagCreation,
      placeholder,
      onChange,
      label,
      helperText,
      error,
      success,
      tagsInInput = false,
      optional,
      onAdd,
      onRemove,
      maxTags,
      containerProps,
      createLabel = "Create",
      showNoTagLeft = true,
      backspaceToRemove = false,
      autoGenerateColor = false,
      hideUsedTags = false,
      checkSelectedByLabel = false,
      ...rest
    } = props;

    const [rootElementRef, setRootElementRef] =
      React.useState<HTMLDivElement | null>(null);
    const [availableTags, setAvailableTags] = React.useState<any[] | undefined>(
      tags,
    );
    const [listElement, setListItem] = React.useState<HTMLDivElement | null>(
      null,
    );
    const [searchFilter, setSearchFilter] = React.useState("");
    const [isOpen, setIsOpen] = React.useState(false);
    const [triggerElRef, setTriggerElRef] =
      React.useState<HTMLInputElement | null>(null);
    const [selectedElement, setSelectedElement] = React.useState(-1);
    const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    const lowerCaseSearchFilter = searchFilter.toLowerCase();

    const [newTagColor, setNewTagColor] = React.useState<string | undefined>();

    const { styles, attributes, update } = usePopper(
      rootElementRef,
      listElement,
      {
        strategy: "fixed",
        modifiers: [
          {
            name: "offset",
            enabled: true,
            options: {
              offset: [0, 6],
            },
          },
        ],
      },
    );

    const filteredTags = (availableTags || []).filter((tag) => {
      const filterByInputText =
        lowerCaseSearchFilter === "" ||
        tag.label.toLowerCase().includes(lowerCaseSearchFilter);

      const filterBySelectedItems = hideUsedTags
        ? !selectedItems.some((sel) => {
            if (checkSelectedByLabel) {
              return sel === tag.label || sel.label === tag.label;
            }
            return sel === tag.value || sel.value === tag.value;
          })
        : true;
      return filterByInputText && filterBySelectedItems;
    });

    const isTagCreation =
      allowTagCreation &&
      filteredTags.length === 0 &&
      availableTags?.find((tag) => tag.label === searchFilter) === undefined;

    const _applyChange = (newSelection: any) => {
      setSelectedItems(newSelection);
      onChange && onChange(newSelection);
    };

    const _selectNext = React.useCallback(() => {
      if (!filteredTags.length || selectedElement + 1 > filteredTags.length - 1)
        setSelectedElement(0);
      else setSelectedElement(selectedElement + 1);
    }, [selectedElement, filteredTags]);

    const _selectPrev = React.useCallback(() => {
      if (!filteredTags.length) return setSelectedElement(0);

      if (selectedElement - 1 < 0) setSelectedElement(filteredTags.length - 1);
      else setSelectedElement(selectedElement - 1);
    }, [selectedElement, filteredTags]);

    const _selectItem = React.useCallback(
      (value) => {
        const newItems = selectedItems.filter((v) => v !== value);

        if (maxTags && selectedItems.length >= maxTags) {
          _applyChange(newItems);
          setSearchFilter("");
          return;
        }

        newItems.push(value);
        _applyChange(newItems);

        onAdd && onAdd(value);
        setSearchFilter("");
      },
      [selectedItems],
    );

    const _removeItem = React.useCallback(
      (value) => {
        const newItems = selectedItems.filter((v) => v !== value);
        _applyChange(newItems);
        onRemove && onRemove(value);
      },
      [selectedItems],
    );

    const _createAndSelectNewTag = React.useCallback(
      async (value) => {
        if (value === "") return;
        if (onTagCreation) {
          const result = await onTagCreation(value, newTagColor);
          if (typeof result === "object" && result.label && result.value) {
            const newTags = availableTags ? [...availableTags] : [];
            newTags.push(result);
            setAvailableTags(newTags);
            _selectItem(
              autoGenerateColor
                ? {
                    value: result.value,
                    label: result.label,
                    color: result.color || newTagColor,
                  }
                : result.value,
            );
          }
        }
      },
      [
        onTagCreation,
        availableTags,
        _selectItem,
        autoGenerateColor,
        newTagColor,
      ],
    );

    const handleUserKeyPress = React.useCallback(
      (event) => {
        const { keyCode } = event;

        if (keyCode === 38) {
          _selectPrev();
          event.preventDefault();
        } else if (keyCode === 40) {
          _selectNext();
          event.preventDefault();
        } else if (keyCode === 13) {
          if (isTagCreation) {
            _createAndSelectNewTag(searchFilter || "");
          } else {
            filteredTags[selectedElement] &&
              _selectItem(filteredTags[selectedElement].value);
          }

          setSearchFilter("");
          if (!tagsInInput) {
            setIsOpen(false);
          } else {
            update && update();
          }
          //if (tagsInInput && triggerElRef) triggerElRef.blur();

          event.preventDefault();
        } else if (keyCode === 27) {
          setIsOpen(false);
          setSearchFilter("");
          if (tagsInInput && triggerElRef) triggerElRef.blur();
          event.stopPropagation();
          event.preventDefault();
        }
      },
      [_selectNext, update],
    );

    const onOutsideClick = React.useCallback(
      (event) => {
        if (
          rootElementRef &&
          !rootElementRef.contains(event.target) &&
          event.target.id !== AddButtonId
        ) {
          setIsOpen(false);
          if (tagsInInput && triggerElRef) triggerElRef.blur();
        }
      },
      [rootElementRef],
    );

    const onRootElClick = () => {
      if (tagsInInput && triggerElRef) triggerElRef.focus();
    };

    React.useEffect(() => {
      setSelectedElement(0);
    }, [searchFilter]);

    React.useEffect(() => {
      if (!triggerElRef) return;

      triggerElRef.addEventListener("keydown", handleUserKeyPress);

      return () => {
        triggerElRef.removeEventListener("keydown", handleUserKeyPress);
      };
    }, [handleUserKeyPress, triggerElRef]);

    React.useEffect(() => {
      window.document.addEventListener("click", onOutsideClick, true);

      return () => {
        window.document.removeEventListener("click", onOutsideClick, true);
      };
    }, [rootElementRef]);

    React.useEffect(() => {
      setSelectedItems(value && Array.isArray(value) ? value : []);
    }, [value]);

    React.useEffect(() => {
      setAvailableTags(tags || []);
    }, [tags]);

    React.useEffect(() => {
      if (autoGenerateColor && !filteredTags.length) {
        setNewTagColor(getRandomHexColor());
      }
    }, [filteredTags.length, autoGenerateColor]);

    const OptionElement: React.FC<
      PropsWithChildren<{
        value?: any;
        index: number;
        isAddButton?: boolean;
      }>
    > = ({ children, value, index, isAddButton }) => (
      <Box
        id={isAddButton ? AddButtonId : ""}
        py={4}
        px={4}
        backgroundColor={selectedElement === index ? "bg.grey" : "bg.white"}
        _hover={{ cursor: "pointer" }}
        onMouseEnter={() => setSelectedElement(index)}
        onClick={() => {
          if (isTagCreation) {
            _createAndSelectNewTag(searchFilter || "");
            setSearchFilter("");
          } else {
            _selectItem(value);
          }
        }}
      >
        {children}
      </Box>
    );

    const ChipComponent = autoGenerateColor ? OutlinedColoredTag : Chip;

    const chips = (
      <>
        {selectedItems.map((val, selectedIndex) => {
          const tag = availableTags?.find(
            (tag) =>
              String(tag.value) ===
              String(autoGenerateColor ? val.label || val : val),
          );
          return (
            <ChipComponent
              key={selectedIndex}
              isFilled
              isSmall
              label={tag?.label || ""}
              tagColor={tag?.color || ""}
              mr={2}
              mt={tagsInInput ? 3 : 2}
              onClickClose={(e) => {
                if (tagsInInput) {
                  e.stopPropagation();
                  e.preventDefault();
                }
                _removeItem(val);
              }}
            />
          );
        })}
      </>
    );

    const handleDropdownOpen = (event?: React.KeyboardEvent) => {
      if (event && event.key === "Tab") return setIsOpen(false);

      if (isOpen) return;

      if (maxTags && selectedItems.length >= maxTags) {
        return;
      }
      setIsOpen(true);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      setSearchFilter(e.target.value);

    const onInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!backspaceToRemove) return;
      if (
        e.key === "Backspace" &&
        searchFilter === "" &&
        selectedItems.length > 0
      ) {
        _removeItem(selectedItems[selectedItems.length - 1]);
      }
    };

    return (
      <FormControl isInvalid={!!error} {...rest}>
        <Box {...containerProps}>
          {label && (
            <Flex justifyContent="space-between">
              <FormLabel
                mb="8px"
                textStyle={"bodyRegularBold"}
                fontWeight="600"
                color={"ink"}
              >
                {label}
              </FormLabel>
              {optional && (
                <Text color="text.grey" fontWeight="400" fontSize="16px">
                  Optional
                </Text>
              )}
            </Flex>
          )}
          <Flex
            {...(tagsInInput && {
              border: "1px solid",
              borderColor: error ? "semantic.error" : "border.input",
              borderRadius: 4,
            })}
            {...(tagsInInput && {
              px: 4,
              cursor: "text",
            })}
            flexWrap={"wrap"}
            onClick={onRootElClick}
            ref={setRootElementRef}
          >
            <HiddenInputToReceiveFocus ref={ref} />
            {isOpen && (
              <>
                <Box
                  ref={setListItem}
                  style={styles.popper}
                  {...attributes.popper}
                  background={"bg.white"}
                  left={0}
                  right={0}
                  w={rootElementRef?.offsetWidth}
                  boxShadow="0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)"
                  borderWidth={1}
                  borderStyle={"solid"}
                  borderColor={"border.grey"}
                  zIndex={"dropdown"}
                  maxH={"224px"}
                  overflow={"auto"}
                >
                  {filteredTags.length > 0 ? (
                    filteredTags.map((tag, tagIndex) => (
                      <OptionElement
                        value={tag.value}
                        index={tagIndex}
                        key={`${tag.value}-${tagIndex}`}
                      >
                        {tag.label}
                      </OptionElement>
                    ))
                  ) : isTagCreation && searchFilter ? (
                    <OptionElement index={0} isAddButton>
                      <Flex alignItems={"center"} gap={2}>
                        {createLabel || "Create"}{" "}
                        <OutlinedColoredTag
                          label={searchFilter}
                          tagColor={newTagColor}
                        />
                      </Flex>
                    </OptionElement>
                  ) : showNoTagLeft ? (
                    <Box textStyle={"labelSmall"} py={4} px={4}>
                      No tags left
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </>
            )}
            {/*{tagsInInput && selectedItems.length > 0 && (
            <Box px={4} py={3}>
              {chips}
            </Box>
          )}*/}
            {tagsInInput && selectedItems.length > 0 && <>{chips}</>}
            {!tagsInInput && (
              <VeriInput
                propsInput={{
                  placeholder:
                    placeholder ||
                    (allowTagCreation
                      ? "Search or create new tags..."
                      : "Search a tag"),
                  autoComplete: "off",
                  isDisabled: !!(maxTags && selectedItems.length >= maxTags),
                  ...(tagsInInput ? { border: 0 } : undefined),
                  ...(isOpen && { zIndex: "dropdown", position: "relative" }),
                }}
                // onFocus={handleDropdownOpen}
                onClick={handleDropdownOpen.bind(null, undefined)}
                onKeyDown={handleDropdownOpen}
                onChange={onInputChange}
                value={searchFilter}
                data-testid="tags-input"
              />
            )}
            {tagsInInput && (
              <input
                data-testid="tags-input"
                autoComplete={"off"}
                ref={setTriggerElRef}
                placeholder={placeholder}
                disabled={!!(maxTags && selectedItems.length >= maxTags)}
                style={{
                  ...(tagsInInput && { border: 0 }),
                  ...(isOpen && { zIndex: "dropdown", position: "relative" }),
                  padding: "12px 0",
                  margin: 0,
                  width: "auto",
                  fontSize: 16,
                  fontFamily: "roboto, sans-serif",
                  outline: "none",
                  display: "inline-block",
                  maxWidth: "100%",
                }}
                onChange={onInputChange}
                onKeyUp={onInputKeyUp}
                onFocus={handleDropdownOpen.bind(null, undefined)}
                value={searchFilter}
                size={Math.max(
                  (searchFilter || "").length || 1,
                  placeholder?.length || 1,
                )}
              />
            )}
          </Flex>

          {helperText && !error && !success && (
            <FormHelperText id="email-helper-text">{helperText}</FormHelperText>
          )}
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
          {success && (
            <Box mt={2} textStyle={"bodySmall"} color={"semantic.success"}>
              {success}
            </Box>
          )}

          <Box>{!tagsInInput && chips}</Box>
        </Box>
      </FormControl>
    );
  },
);

TagsInput.displayName = "TagsInput";

export default TagsInput;
