import {
  dashboardWizardPrefix,
  investorPortalRoutes,
  isManagerWhiteLabelRoute,
  wizardPrefix,
} from "@verivest/shared-components";
import { UseModelProvider } from "@verivest/shared-components/src/api/hooks/contexts/useModelContext";
import { ModelScope } from "@verivest/shared-components/src/api/types";
import { LayoutProvider } from "@verivest/shared-components/src/hooks/ui/useLayout";
import { WorkerJobsProvider } from "@verivest/shared-components/src/hooks/ui/useWorkerJobs/useWorkerJobs";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import useAuth from "../hooks/useAuth";
import useDynamicConfig, {
  DASHBOARD_MANAGER,
  IS_MANAGER_DASHBOARD,
  MANAGER,
} from "../hooks/useDynamicConfig";
import { SponsorDashboardErrorBoundary } from "../pages/manager-dashboard/SponsorDashboardErrorBoundary";
import { getURLParams } from "../utils/utils";
import { InvestorPortalBody } from "./investor-portal/layouts/InvestorPortalBody";
import { InvestorPortalErrorBoundary } from "./InvestorPortalErrorBoundary";
import Header from "./main/Header";
import HeaderMessage from "./main/HeaderMessage";
import SponsorDashboardContainer from "./main/SponsorDashboardContainer";
import SponsorDashboardHeader from "./main/SponsorDashboardHeader";
import Websocket from "./Websocket";
import WhiteLabelFooter from "./WhiteLabelFooter";
import { InvestorPortalHeader } from "./investor-portal/headers/InvestorPortalHeader";
import { InvestorPortalProvider } from "./investor-portal/InvestorPortalContext";

const mfaSkipUrls = [investorPortalRoutes.selectManagerAndCreateFundBuilder];

function isEmptyHeader(location) {
  return (
    location.startsWith(wizardPrefix) ||
    location.startsWith(dashboardWizardPrefix) ||
    location.startsWith("/docusign") ||
    location.startsWith("/auth") ||
    location.startsWith(investorPortalRoutes.getStarted) ||
    location.startsWith(investorPortalRoutes.selectManagerAndCreateFundBuilder)
  );
}

const App = (props) => {
  const isMarketing = props.isMarketing;

  const { auth } = useAuth();
  const token = auth.getToken();
  const dynamicConfig = useDynamicConfig();
  const { history } = props;
  const pathname = props.location.pathname;
  const queryParams = getURLParams(history.location.search);
  let header = true;
  let footer = false;

  const manager = dynamicConfig.get(IS_MANAGER_DASHBOARD)
    ? dynamicConfig.get(DASHBOARD_MANAGER)
    : dynamicConfig.get(MANAGER);

  useEffect(() => {
    if (
      pathname.startsWith(wizardPrefix) ||
      pathname.startsWith(dashboardWizardPrefix)
    ) {
      if (window.Intercom) {
        window.Intercom("update", { hide_default_launcher: true });
      }
    } else {
      if (window.Intercom) {
        window.Intercom("update", { hide_default_launcher: false });
      }
    }
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    auth.setHistory(history);
  }, [auth, history]); // eslint-disable-line react-hooks/exhaustive-deps

  let dashboardHeader = false;
  const isSponsorDashboard = dynamicConfig.get(IS_MANAGER_DASHBOARD);
  if (isSponsorDashboard) {
    header = false;
    dashboardHeader = true;
  }
  let empty = isEmptyHeader(props.location.pathname);

  const profile = auth.getProfile();

  const modelScope = isSponsorDashboard
    ? ModelScope.Manager
    : profile?.id
    ? ModelScope.Investor
    : ModelScope.Public;

  const _isManagerWhiteLabelRoute = isManagerWhiteLabelRoute(
    props.location.pathname,
  );
  return (
    <LayoutProvider
      location={props.location}
      history={history}
      initialProfile={profile}
      app={"web"}
      mfaSkipUrls={mfaSkipUrls}
    >
      <UseModelProvider
        defaults={{
          scope: modelScope,
          modelScope,
        }}
      >
        <WorkerJobsProvider>
          <InvestorPortalProvider>
            {!isMarketing ? (
              <Helmet>
                <title>{manager?.name}</title>
                <link
                  rel="icon"
                  href={manager?.faviconUrl || "/images/wl-favicon.png"}
                />
                <meta name="description" content={manager?.metaDescription} />
              </Helmet>
            ) : undefined}
            {token && <Websocket token={token} manager={manager} />}
            {!isMarketing ? (
              <>
                {
                  header && !empty && (
                    <InvestorPortalHeader
                      isWhiteLabel={_isManagerWhiteLabelRoute}
                      isMarketing={false}
                    />
                  )
                  /* {header && !isMarketing && !empty && (
                    <InvestorPortalHeader
                      isWhiteLabel={_isManagerWhiteLabelRoute}
                    />
                  )} */
                }
                {dashboardHeader && !empty && profile && profile.roles && (
                  <SponsorDashboardHeader profile={profile} />
                )}
                {!dashboardHeader && <HeaderMessage show={!empty} />}
                {(dashboardHeader && !empty && profile && profile.roles && (
                  <SponsorDashboardContainer
                    hideSidebar={queryParams.hideSidebar}
                  >
                    <SponsorDashboardErrorBoundary>
                      {props.children}
                    </SponsorDashboardErrorBoundary>
                  </SponsorDashboardContainer>
                )) || (
                  <InvestorPortalErrorBoundary>
                    <InvestorPortalBody
                      isWhiteLabel={_isManagerWhiteLabelRoute}
                    >
                      {props.children}
                    </InvestorPortalBody>
                  </InvestorPortalErrorBoundary>
                )}
              </>
            ) : undefined}
            {isMarketing ? (
              <Header
                empty={empty}
                history={history}
                isMarketing={isMarketing}
              />
            ) : undefined}
            {footer && !isMarketing && <WhiteLabelFooter />}
          </InvestorPortalProvider>
        </WorkerJobsProvider>
      </UseModelProvider>
    </LayoutProvider>
  );
};

export default withRouter(App);
