/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const HoldingRateUpdateJsonApiModel = {
  id: "",
  "created-at": "",
  holding: {
    jsonApi: "hasOne",
    type: "holdings",
  },
  "market-value": "",
  rate: "",
  "as-of-date": "",
  "holding-calc-id": "",
  "is-security-rate-update": "",
};
