/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const InvestmentJsonApiModel = {
  id: "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "distribution-preference": {
    jsonApi: "hasOne",
    type: "distribution-preferences",
  },
  amount: "",
  "equity-amount": "",
  "payment-method": "",
  "accept-terms": "",
  "passed-investment-questionnaire": "",
  "ds-envelope-id": "",
  "ds-investor1-signed": "",
  "ds-investor2-signed": "",
  "ds-manager-signed": "",
  "documents-uploaded-at": "",
  "funds-in-escrow": "",
  "funds-received": "",
  "bd-approved": "",
  "bd-trade": "",
  "ta-trade-id": "",
  "overrided-accreditation-at": "",
  "manager-verified-at": "",
  status: "",
  progress: "",
  "effective-date": "",
  "completed-at": "",
  name: "",
  "investment-type": "",
  "created-by-user-id": "",
  "created-by-user": {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-by-role": "",
  "bill-as-network": "",
  "accredited-confirmed-at": "",
  "accredited-confirmed-by": "",
  "from-marketplace": "",
  "date-created": "",
  "created-at": "",
  "updated-at": "",
};
