import NotificationItem from "@verivest/shared-components/src/components/notification/NotificationItem";
import { NotificationActionTypes } from "@verivest/shared-components/src/configure-store/reducers/notifications";
import { useToastNotification } from "@verivest/shared-components/src/hooks/ui/toast/useToastNotification";
import humps from "humps";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";

const Websocket = ({ token, manager }) => {
  const toast = useToastNotification();
  const dispatch = useDispatch();
  const drawerIsOpen = useSelector((state) => state.notification.drawerIsOpen);
  const { sendMessage } = useWebSocket(
    process.env.RAZZLE_APP_WS + "?token=" + token,
    {
      shouldReconnect: () => true,
      onOpen: () => {
        setInterval(() => {
          sendMessage("ping");
        }, 10000);
      },
      share: true,
      onMessage: (message) => {
        const data = JSON.parse(message.data);
        const formattedData = humps.camelizeKeys(data);
        if (!drawerIsOpen) {
          toast(
            <NotificationItem
              manager={manager}
              type={formattedData.notificationType}
              content={formattedData.content}
              createdAt={formattedData.createdAt}
              viewed={false}
              p={2}
            />,
          );
        }
        dispatch({
          type: NotificationActionTypes.ADD,
          notification: formattedData.content,
        });
      },
    },
  );

  return <></>;
};

export default Websocket;
