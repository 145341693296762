/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetBillingProductsOutputJsonApiModel = {
  id: "",
  "billing-products": {
    jsonApi: "hasMany",
    type: "billing-products",
  },
};
