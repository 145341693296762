import { useRef } from "react";
import useSWR, { Fetcher, Key, SWRConfiguration, SWRResponse } from "swr";

type SWRArgs<Data, Error = any, SWRKey extends Key = Key> = [
  SWRKey,
  Fetcher<Data, SWRKey>,
  SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>>,
];

export function useStickySWR<Data, Error = any, SWRKey extends Key = Key>(
  ...args: SWRArgs<Data, Error, SWRKey>
): SWRResponse<Data, Error> & { data: Data | undefined } {
  const val = useRef<Data | undefined>(undefined);

  const { data, ...rest } = useSWR<Data, Error, SWRKey>(...args);

  // we use this for  sticky list, in order to prevent the list from jumping
  // we only want ot update the data if the data is not undefined
  if (data !== undefined) {
    val.current = data;
  }

  return {
    ...rest,
    data: val.current,
  };
}
