export enum MfaModalStep {
  start = "start",
  sendVerificationCodeToPhone = "sendVerificationCodeToPhone",
  verifyPhoneVerificationCode = "verifyPhoneVerificationCode",
  configureMobileApp = "configureMobileApp",
  success = "success",
  disable = "disable",
}

export interface MfaModalState {
  authByPhone: boolean;
  phone: string;
  country: string | null;
  step: MfaModalStep;
  verifyCode: string;
  isLoading: boolean;
  error: string;
  codeSent: boolean;
}

export interface MfaModalContextProps extends MfaModalState {
  setState: (newState: Partial<MfaModalState>) => void;
  setStep: (step: MfaModalStep) => void;
  closeModal: () => void;
}
export interface MfaModalContextProviderProps {
  closeModal: () => void;
  initialStep: MfaModalStep;
}
