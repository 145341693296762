/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DocumentsTagJsonApiModel = {
  id: "",
  document: {
    jsonApi: "hasOne",
    type: "documents",
  },
  tag: {
    jsonApi: "hasOne",
    type: "tags",
  },
};
