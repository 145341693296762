/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CalculateBillingScheduleChargeInputJsonApiModel = {
  id: "",
  "billing-schedule-id": "",
  "opportunity-id": "",
  "raw-value": "",
};
