import { Box, Flex, Icon } from "@chakra-ui/react";
import { useLayout } from "@verivest/shared-components/src/hooks/ui/useLayout";
import React from "react";
import useAuth from "../../../hooks/useAuth";
import { CloseIcon, MenuIcon } from "../../general";
import NotificationBell from "../../general/NotificationBell";
import ProfileButton from "./ProfileButton";

const MobileHeaderActions = ({ show, manager, setShow }) => {
  const { auth } = useAuth();
  const { layout } = useLayout();
  const profile = layout.profile;
  const handleToggle = () => setShow(true);
  const logout = () => auth.logout();
  const isMallcop = auth?.isMallcop() || false;
  const isManager = manager && manager.id && auth.isManager(manager.id);

  const isMobile = layout.breakpoint === "base";

  if (!isMobile) return <></>;

  return (
    <Box display={isMobile ? "flex" : "node"} alignItems="center">
      <Icon
        cursor="pointer"
        onClick={handleToggle}
        as={show ? CloseIcon : MenuIcon}
        color="black"
        boxSize="24px"
        mr="16px"
      />

      {profile && (
        <Flex>
          <Box
            mr="18px"
            display={show ? "none" : "block"}
            alignSelf="center"
            whiteSpace="nowrap"
          >
            <NotificationBell />
          </Box>

          <Box>
            <ProfileButton
              auth={auth}
              profile={profile}
              logout={logout}
              isManager={isManager}
              manager={manager}
              isMallcop={isMallcop}
            />
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default MobileHeaderActions;
