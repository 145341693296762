import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { MFAModalViewProps } from "./MFAModalView.types";
import Button from "../../../../../buttons/Button";
import { VeriHeading } from "../../../../common";
import { useMfaModal } from "../../context/MfaModalContext/MfaModalContext";

const MFAModalView: React.FC<MFAModalViewProps> = ({
  title,
  subTitle,
  continueLabel = "Continue",
  onContinue,
  backLabel = "Go back",
  onBack,
  children,
  continueDisabled,
  hideGoBack,
}) => {
  const state = useMfaModal();

  return (
    <>
      <VeriHeading textStyle="h4" mt="24px" mb="16px">
        {title}
      </VeriHeading>
      <Text textStyle="bodyRegular">{subTitle}</Text>
      <Box my="24px">{children}</Box>
      {state.error && (
        <Box mb="24px" color="text.error">
          {state.error}
        </Box>
      )}
      <Flex>
        <Button
          isLarge
          isBold
          onClick={onContinue}
          isLoading={state.isLoading}
          isDisabled={continueDisabled}
        >
          {continueLabel}
        </Button>
        {!hideGoBack && (
          <Button
            isLarge
            isBold
            outline
            onClick={onBack}
            ml="18px"
            isDisabled={state.isLoading}
          >
            {backLabel}
          </Button>
        )}
      </Flex>
    </>
  );
};

export default MFAModalView;
