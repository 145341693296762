import { Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ProfileButtons from "./ProfileButtons";

const ProfileButton = ({
  auth,
  manager,
  profile,
  logout,
  isMallcop,
  isMarketing,
}) => {
  const isImpersonating = auth.isImpersonating();
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={ProfileButtons}
        isImpersonating={isImpersonating}
        profile={profile}
      >
        MenuItem
      </MenuButton>
      <MenuList zIndex={3}>
        {isImpersonating && (
          <MenuItem onClick={() => auth.revokeImpersonation()}>
            Leave impersonation ({isImpersonating.name})
          </MenuItem>
        )}
        <MenuItem
          as={isMarketing ? Link : RouterLink}
          href={isMarketing ? "/user/settings" : undefined}
          to={!isMarketing ? "/user/settings" : undefined}
        >
          Settings
        </MenuItem>
        {isMallcop && (
          <MenuItem
            onClick={() =>
              (window.location =
                process.env.RAZZLE_APP_ADMIN_URL +
                "/login?token=" +
                auth.getToken())
            }
          >
            Switch to Admin Portal
          </MenuItem>
        )}
        {(auth.isMallcop() || auth.isManagerOfAny()) && !manager && (
          <MenuItem onClick={() => auth.switchToDashboard()}>
            <span>Switch to Manager Dashboard</span>
          </MenuItem>
        )}
        {manager && (auth.isMallcop() || auth.isManager(manager.id)) && (
          <MenuItem onClick={() => auth.switchToDashboard(manager.id)}>
            <span>Switch to Manager Dashboard</span>
          </MenuItem>
        )}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ProfileButton;
