/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetOfferingExemptionOutputJsonApiModel = {
  id: "",
  "offering-exemption": {
    jsonApi: "hasOne",
    type: "offering-exemptions",
  },
};
