import { AllHtmlEntities } from "html-entities";
import React from "react";
import { logError } from "../error-handling/logError";

const allHtmlEntities = new AllHtmlEntities();

const thousand = 1.0e3;
const million = 1.0e6;
const billion = 1.0e9;

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const numberFormatterWithDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export function stringToPlural(word: string, total: number, suffix = "s") {
  if (total > 1) return word + suffix;

  return word;
}

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeWords = (s: string) => {
  return s.split(" ").map(capitalize).join(" ");
};

export const getFlatImageUrlUsingIsoCode = (iso: string) => {
  return `https://flag.pk/flags/4x3/${iso.toLowerCase()}.svg`;
};

export const camelCaseToSpaces = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

export const spacesToCamelCase = (str: string) => {
  return str.replace(/ /g, "").replace(/^./, (str) => str.toLocaleLowerCase());
};

export function formatMoney(
  amount_: number | string,
  decimalCount = 2,
  decimal = ".",
  thousands = ",",
) {
  let amount = Number(amount_);
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const i = parseInt(
      String(
        (amount = Number(Math.abs(Number(amount) || 0).toFixed(decimalCount))),
      ),
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    logError(e);
  }

  return "";
}

export function formatMoneyWithSymbol(
  amount_: number | string,
  decimalCount = 2,
  decimal = ".",
  thousands = ",",
) {
  const amount = Number(amount_);
  if (amount < 0) {
    return `-$${formatMoney(
      Math.abs(amount),
      decimalCount,
      decimal,
      thousands,
    )}`;
  }

  return "$" + formatMoney(amount, decimalCount, decimal, thousands);
}

export const formatToShortMoney = (value: number | string) => {
  let amount = Math.abs(Number(value));
  let abbr = "";

  if (amount >= billion) {
    amount = amount / billion;
    abbr = "B";
  }

  if (amount >= million) {
    amount = amount / million;
    abbr = "M";
  }

  if (amount >= thousand) {
    amount = amount / thousand;
    abbr = "K";
  }

  return numberFormatterWithDecimals.format(amount).replace(/\.00$/, "") + abbr;
};

export function makeId(length = 20) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const htmlToText = (html: string) => {
  const strippedText = html.replace(/(<([^>]+)>)/gi, "");
  return allHtmlEntities.decode(strippedText);
};

export const nl2br = (str: string) => {
  const newlineRegex = /(\r\n|\r|\n)/g;

  if (typeof str !== "string") {
    return str;
  }

  return str.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement("br", { key: index });
    }
    return line;
  });
};

export function truncateWithEllipses(text: string, max: number) {
  return text.substr(0, max) + (text.length > max ? "..." : "");
}

export function joinStringArray(
  string: string[],
  separator = ", ",
  lastSeparator = " and ",
) {
  let result = string.join(separator);

  if (lastSeparator) {
    const lastSeparatorIndex = result.lastIndexOf(separator);
    result =
      result.substring(0, lastSeparatorIndex) +
      lastSeparator +
      result.substring(lastSeparatorIndex + separator.length);
  }

  return result;
}
