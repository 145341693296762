/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UpdateBillingScheduleInputJsonApiModel = {
  id: "",
  "billing-schedule": {
    jsonApi: "hasOne",
    type: "billing-schedules",
  },
  options: {
    jsonApi: "hasOne",
    type: "update-options",
  },
};
