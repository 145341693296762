import React, { Component } from "react";
import { PaginationProps } from "./Pagination.types";
import { Box, Link } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../icons";
import { PageButton } from "./components";

//TODO THIS SHOULD BE REWRITTEN TO USE FUNCTION COMPONENTS

export default class PaginationBoxView extends Component<
  PaginationProps,
  never
> {
  static defaultProps = {
    pageCount: 10,
    pageRangeDisplayed: 2,
    marginPagesDisplayed: 3,

    breakLabel: "...",
    disableInitialCallback: false,
  };

  handlePreviousPage = (evt: React.KeyboardEvent<HTMLDivElement>) => {
    const { selected } = this.props;
    evt.preventDefault();
    if (selected > 0) {
      this.handlePageSelected(selected - 1, evt);
    }
  };

  handleNextPage = (evt: React.KeyboardEvent<HTMLDivElement>) => {
    const { selected, pageCount } = this.props;

    evt.preventDefault();
    if (selected < pageCount - 1) {
      this.handlePageSelected(selected + 1, evt);
    }
  };

  handlePageSelected = (
    selected: number,
    evt: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    evt.preventDefault();

    if (this.props.selected === selected) return;

    // Call the callback with the new selected item:
    this.callCallback(selected);
  };

  getForwardJump() {
    const { selected, pageCount, pageRangeDisplayed } = this.props;

    const forwardJump = selected + pageRangeDisplayed;
    return forwardJump >= pageCount ? pageCount - 1 : forwardJump;
  }

  getBackwardJump() {
    const { selected, pageRangeDisplayed } = this.props;

    const backwardJump = selected - pageRangeDisplayed;
    return backwardJump < 0 ? 0 : backwardJump;
  }

  hrefBuilder(pageIndex: number) {
    const { hrefBuilder, pageCount } = this.props;
    if (
      hrefBuilder &&
      pageIndex !== this.props.selected &&
      pageIndex >= 0 &&
      pageIndex < pageCount
    ) {
      return hrefBuilder(pageIndex + 1);
    }

    return "";
  }

  onClickBuilder(pageIndex: number) {
    const { onClickBuilder, pageCount } = this.props;
    if (
      onClickBuilder &&
      pageIndex !== this.props.selected &&
      pageIndex >= 0 &&
      pageIndex < pageCount
    ) {
      return onClickBuilder(pageIndex + 1);
    }
  }

  callCallback = (selectedItem: number) => {
    if (
      typeof this.props.onPageChange !== "undefined" &&
      typeof this.props.onPageChange === "function"
    ) {
      this.props.onPageChange({ selected: selectedItem });
    }
  };

  getPageElement(index: number) {
    const { selected } = this.props;
    return (
      <PageButton
        key={index}
        href={this.hrefBuilder(index)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.onClickBuilder(index);
        }}
        selected={selected === index}
        page={index + 1}
      />
    );
  }

  pagination = () => {
    const items = [];
    const { selected, pageRangeDisplayed, pageCount, marginPagesDisplayed } =
      this.props;

    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        items.push(this.getPageElement(index));
      }
    } else {
      let leftSide = pageRangeDisplayed / 2;
      let rightSide = pageRangeDisplayed - leftSide;

      // If the selected page index is on the default right side of the pagination,
      // we consider that the new right side is made up of it (= only one break element).
      // If the selected page index is on the default left side of the pagination,
      // we consider that the new left side is made up of it (= only one break element).
      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected;
        leftSide = pageRangeDisplayed - rightSide;
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected;
        rightSide = pageRangeDisplayed - leftSide;
      }

      let index;
      let page;
      let breakView: React.ReactNode | null = null;
      const createPageView = (index: number) => this.getPageElement(index);

      for (index = 0; index < pageCount; index++) {
        page = index + 1;

        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > pageCount - marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (items[items.length - 1] !== breakView) {
          breakView = (
            <Box key={index + "break"} as="li" display="inline-block" mr="16px">
              <Box
                key={index}
                h="32px"
                w="32px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                ...
              </Box>
            </Box>
          );
          items.push(breakView);
        }
      }
    }

    return items;
  };

  override render() {
    const { pageCount, nextRel, selected } = this.props;

    const previousDisabled = selected === 0;
    const nextDisabled = selected === pageCount - 1;

    return (
      <Box
        as="ul"
        pl="15px"
        pr="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...this.props.boxProps}
      >
        <Box as="li" display="inline-block" mr="16px">
          {!previousDisabled && (
            <Box
              as={Link}
              to={this.hrefBuilder(selected - 1)}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.onClickBuilder(selected - 1);
              }}
              h="32px"
              w="32px"
              borderRadius={"50%"}
              _hover={{ color: "brand.main" }}
              color={"text.grey"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              onKeyPress={this.handlePreviousPage}
              rel={nextRel}
            >
              <Icon as={ChevronLeftIcon} boxSize="24px" />
            </Box>
          )}
        </Box>

        {this.pagination()}

        <Box as="li" display="inline-block">
          {!nextDisabled && (
            <Box
              as={Link}
              to={this.hrefBuilder(selected + 1)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.onClickBuilder(selected + 1);
              }}
              h="32px"
              w="32px"
              borderRadius={"50%"}
              _hover={{ color: "brand.main" }}
              color={"text.grey"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              onKeyPress={this.handleNextPage}
              rel={nextRel}
            >
              <Icon as={ChevronRightIcon} boxSize="24px" />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}
