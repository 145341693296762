/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const PortfolioManagerJsonApiModel = {
  id: "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "manager-id": "",
  "investments-count": "",
};
