/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const StarFilledIcon = createIcon({
  displayName: "StarFilledIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12.746 1.464L15.856 7.764L22.81 8.776C22.9636 8.79815 23.1079 8.86286 23.2266 8.96282C23.3454 9.06278 23.4337 9.19398 23.4817 9.34157C23.5297 9.48915 23.5354 9.64723 23.4981 9.79788C23.4609 9.94854 23.3822 10.0858 23.271 10.194L18.238 15.094L19.426 22.02C19.4522 22.173 19.4351 22.3302 19.3766 22.474C19.3181 22.6178 19.2206 22.7423 19.095 22.8335C18.9694 22.9248 18.8209 22.9791 18.6661 22.9903C18.5113 23.0015 18.3564 22.9692 18.219 22.897L12 19.632L5.78 22.9C5.64248 22.9719 5.48762 23.0039 5.33288 22.9925C5.17813 22.981 5.02966 22.9266 4.90418 22.8354C4.7787 22.7441 4.68121 22.6196 4.6227 22.4759C4.56418 22.3322 4.54697 22.175 4.573 22.022L5.761 15.1L0.727996 10.2C0.616784 10.0918 0.538105 9.95454 0.500868 9.80388C0.463632 9.65323 0.469327 9.49515 0.517308 9.34757C0.56529 9.19998 0.65364 9.06878 0.772352 8.96882C0.891064 8.86886 1.03539 8.80415 1.189 8.782L8.143 7.765L11.253 1.465C11.3218 1.32583 11.4281 1.20865 11.56 1.12668C11.6918 1.04472 11.8439 1.00123 11.9992 1.00113C12.1544 1.00103 12.3066 1.04431 12.4386 1.1261C12.5705 1.20788 12.677 1.32492 12.746 1.464Z" fill="currentColor" /></g>)
});
export default StarFilledIcon;