import { AxiosResponse } from "axios";

export function getMimeTypesByType(type: string) {
  switch (type) {
    case "image":
    case "images":
      return ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    case "csv":
      return ["text/csv"];
    case "excel":
      return [
        "application/vnd.ms-excel",
        "application/vnd.ms-excel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
        "application/vnd.ms-excel.template.macroEnabled.12",
        "application/vnd.ms-excel.addin.macroEnabled.12",
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
        "text/csv",
      ];
    default:
      return [
        // Word
        "application/msword",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
        "application/vnd.ms-word.document.macroEnabled.12",
        "application/vnd.ms-word.template.macroEnabled.12",

        // Excel
        "application/vnd.ms-excel",
        "application/vnd.ms-excel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
        "application/vnd.ms-excel.template.macroEnabled.12",
        "application/vnd.ms-excel.addin.macroEnabled.12",
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12",

        // Powerpoint
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.presentationml.template",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
        "application/vnd.ms-powerpoint.addin.macroEnabled.12",
        "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
        "application/vnd.ms-powerpoint.template.macroEnabled.12",
        "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",

        // CSV
        "text/csv",

        // PDF
        "application/pdf",

        // IMages
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
      ];
  }
}

export const getFilenameFromResponseHeaders = (
  response: AxiosResponse | undefined,
  defaultName: string,
) => {
  if (!response) {
    return defaultName;
  }

  const disposition = response.headers["content-disposition"];

  let filename;
  if (disposition?.startsWith("attachment")) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);

    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");
    }
  }

  return filename;
};
