import { Icon } from "@chakra-ui/icons";
import { Tag, TagLabel } from "@chakra-ui/react";
import React from "react";
import { CloseIcon } from "../../../icons";
import { ChipProps } from "./Chip.types";
import { chipTestIds } from "./ChipTestIds";

const Chip: React.FC<ChipProps> = ({
  label,
  onClickClose,
  onClick,
  hasCancel,
  isFilled,
  isSmall,
  isBold,
  ...rest
}) => {
  return (
    <Tag
      py={isSmall ? 0.5 : undefined}
      px={isSmall ? 2 : 3}
      height="32px"
      border="1px solid"
      borderColor="border.input"
      rounded="full"
      variant="outline"
      boxShadow="none"
      onClick={onClick}
      backgroundColor={isFilled ? "border.grey" : "bg.white"}
      fontSize={14}
      fontFamily="roboto"
      fontWeight={isBold ? "bold" : 400}
      color="#000"
      h={isSmall ? "24px" : undefined}
      {...rest}
    >
      <TagLabel>{label}</TagLabel>
      {(onClickClose || hasCancel) && (
        <Icon
          data-testid={chipTestIds.closeIcon}
          cursor="pointer"
          as={CloseIcon}
          pl="8px"
          boxSize="24px"
          color="icons.grey"
          onClick={onClickClose}
        />
      )}
    </Tag>
  );
};

export default Chip;
