import { DateOrDateString } from "../defines";

export const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const monthFullNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const shortDayNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

export function dateToDate(date: string) {
  return new Date(date);
}

export function dateToMonDDYYYY(date: string | null | undefined) {
  if (!date) {
    return "";
  }

  let day: number;
  let month: number;
  let year: number;

  let dateString = date;
  if (/T/.test(dateString)) {
    dateString = dateString.split("T")[0];
  }

  if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) {
    const parts = dateString.split("-");
    year = parseInt(parts[0], 10);
    month = parseInt(parts[1], 10);
    day = parseInt(parts[2], 10);
  } else if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    const parts = dateString.split("/");
    month = parseInt(parts[0], 10);
    day = parseInt(parts[1], 10);
    year = parseInt(parts[2], 10);
  } else {
    return "";
  }

  return `${monthShortNames[month - 1]} ${day}, ${year}`;
}

export function dateToMonYYYYUTC(
  _date: DateOrDateString,
  useDay = false,
  useFullMonth = false,
) {
  const date = typeof _date === "object" ? _date : dateToDate(_date);

  const monthIndex = date.getUTCMonth();
  const monthName = useFullMonth
    ? monthFullNames[monthIndex]
    : monthShortNames[monthIndex];
  const day = useDay ? ` ${date.getUTCDate()},` : "";

  const year = date.getUTCFullYear();

  if (!monthName) return null;

  return `${monthName}${day} ${year}`;
}

export function dateToMonYYYY(
  _date: DateOrDateString,
  useDay = false,
  useFullMonth = false,
) {
  const date = typeof _date === "object" ? _date : dateToDate(_date);

  const monthIndex = date.getMonth();
  const monthName = useFullMonth
    ? monthFullNames[monthIndex]
    : monthShortNames[monthIndex];
  const day = useDay ? ` ${date.getDate()},` : "";

  const year = date.getFullYear();

  if (!monthName) return null;

  return `${monthName}${day} ${year}`;
}

export function dateToYYYYMMDDNoIso(date: string | number | Date) {
  if (!date) return "";
  const d = new Date(date);
  return `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${(
    "0" + d.getDate()
  ).slice(-2)}`;
}

export function dateToISOString(date: string | number | Date) {
  if (!date) return "";

  if (
    typeof date !== "string" &&
    typeof date !== "number" &&
    date.toISOString
  ) {
    return date.toISOString();
  }

  const nDate = new Date(date);

  return dateToFixedIsoString(nDate);
}

export function dateToFixedIsoString(nDate: DateOrDateString) {
  if (typeof nDate === "string") {
    return `${nDate}T01:00:00Z`;
  }

  return `${nDate.getFullYear()}-${String(nDate.getMonth() + 1).padStart(
    2,
    "0",
  )}-${String(nDate.getDate()).padStart(2, "0")}T${String(
    nDate.getHours(),
  ).padStart(2, "0")}:${String(nDate.getMinutes()).padStart(2, "0")}:${String(
    nDate.getSeconds(),
  ).padStart(2, "0")}.${String(nDate.getMilliseconds()).padStart(3, "0")}Z`;
}

export function dateToYYYYMMDD(date: string | number | Date) {
  if (!date) return "";

  return dateToISOString(date).split("T")[0];
}

export function prepareDateOnlyForBE(date: string | number | Date) {
  return `${dateToYYYYMMDDNoIso(date)}T00:00:00.000Z`;
}

export function isValidIsoDate(dateStr: string | null | undefined) {
  if (!dateStr) {
    return false;
  }

  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?Z/.test(dateStr)) {
    return false;
  }

  const d = new Date(dateStr);
  return (
    d.toISOString() === dateStr ||
    d.toISOString().replace(/((.\d{3})Z)$/, "Z") === dateStr
  ); // valid date
}

export function isValidInputDateDob(dateString: string) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;
  // Parse the date parts to integers
  const parts = dateString.split("/");
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export function isValidInputDate(dateString: string) {
  // First check for the pattern
  if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) return false;
  // Parse the date parts to integers
  const parts = dateString.split("-");
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export function isInTheFuture(date: Date) {
  const today = new Date();
  today.setHours(23, 59, 59, 998);
  return date > today;
}

export function formatStringDateToDDMMYYYY(date: string | undefined | null) {
  if (!date) return "";
  return formatDateDDMMYYYY(new Date(date));
}

export function formatDateDDMMYYYY(date: Date) {
  const d = date;
  const year = d.getUTCFullYear();
  let month = "" + (d.getUTCMonth() + 1),
    day = "" + d.getUTCDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
}

export function formatDateMMDDYYYY(date: DateOrDateString | null | undefined) {
  if (!date) return;
  return formatDateDDMMYYYY(typeof date === "string" ? dateToDate(date) : date);
}

export function getHoursDiffInDates(
  startDate: Date,
  endDate: Date | undefined = undefined,
) {
  if (!endDate) endDate = new Date();

  return Math.round(
    Math.abs(endDate.getTime() - startDate.getTime()) / 3600000,
  );
}

export function dateToTimeMonYYYYUTC(_date: DateOrDateString, useDay = false) {
  const date = typeof _date === "object" ? _date : dateToDate(_date);

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  const _hours = hours < 10 ? "0" + hours : hours;
  const _minutes = minutes < 10 ? "0" + minutes : minutes;

  return (
    _hours +
    ":" +
    _minutes +
    " " +
    ampm +
    " on " +
    dateToMonYYYYUTC(date, useDay)
  );
}

export function dateToHumanDate(date: Date, now?: Date) {
  const _now = now || new Date();

  const daysDiff = Math.round(
    (_now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24),
  );

  if (daysDiff === 0) {
    const minutes = Math.abs(_now.getTime() - date.getTime()) / 6e4;

    if (minutes <= 1) {
      return "Moments ago";
    } else if (minutes <= 10) {
      return `${Math.round(minutes)} minutes ago`;
    } else {
      const hours = Math.abs(_now.getTime() - date.getTime()) / 36e5;
      if (hours <= 12) {
        if (hours < 1) return "Less than an hour ago";

        return `${Math.round(hours)} hours ago`;
      }
    }

    return "Today";
  } else if (daysDiff === 1) {
    return "Yesterday";
  } else if (daysDiff < 5) {
    return `${String(daysDiff)} days ago`;
  }

  return dateToMonYYYYUTC(date, true);
}

export function isValidYYYYMMDDDate(dateStr: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(dateStr);
}

export function sqlDateToFixedIsoString(nDate: DateOrDateString) {
  let date: Date;
  if (typeof nDate === "string") {
    date = new Date(nDate);
  } else {
    date = nDate as Date;
  }

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0",
  )}-${String(date.getDate()).padStart(2, "0")}T${String(
    date.getHours(),
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds(),
  ).padStart(2, "0")}.${String(date.getMilliseconds()).padStart(3, "0")}Z`;
}

export function sqlDateToIsoDate(date: string) {
  return new Date(date).toISOString();
}

export function sqlDateToFullYear(date: string) {
  return new Date(date).getUTCFullYear();
}

export const yearsAndMonthsLabel = (years: number, months: number) => {
  const yearsLabel = years === 1 ? "1 year" : `${years} years`;
  const monthsLabel = months === 1 ? "1 month" : `${months} months`;

  return `${yearsLabel}${months ? `, ${monthsLabel}` : ""}`;
};

export const monthsToYearsAndMonthsLabel = (monthsAmount: number) => {
  const years = Math.floor(monthsAmount / 12);
  const months = monthsAmount % 12;

  return yearsAndMonthsLabel(years, months);
};

export const fixDateToUTCTime = (date: DateOrDateString, hour = 12) => {
  const newDate = new Date(new Date(date).toUTCString());
  newDate.setUTCHours(hour, 0, 0, 0);

  return newDate;
};

export function getDateFromDateOrString(date: Date | string) {
  return typeof date === "string" ? dateToDate(date) : date;
}

export function dateToString(date: Date, days?: boolean) {
  if (days) {
    date.setDate(date.getDate() + 1);
  }

  // return formatDateDDMMYYYY(date);
  const splitted = date
    .toISOString()
    .replace("T", " ")
    .replace(/-/g, ".")
    .substr(0, 10)
    .split(".");

  return [splitted[1], splitted[2], splitted[0]].join("/");
}

export const calculateAccreditationExpirationDate = (
  dateStr: string,
): string => {
  const accreditationExpiryYears = 5;
  const utcDateStr = new Date(dateStr).toISOString();
  const dateObj = new Date(utcDateStr);

  const newYear = dateObj.getUTCFullYear() + accreditationExpiryYears;
  dateObj.setUTCFullYear(newYear);

  if (dateObj.getUTCFullYear() !== newYear) {
    dateObj.setUTCDate(dateObj.getUTCDate() - 1);
  }

  return dateObj.toISOString();
};
