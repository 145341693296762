import React from "react";
import { countries } from "../../../../defines";
import { VeriSelect } from "../../VeriSelect";
import { VeriCountrySelectProps } from "./VeriCountrySelect.types";

const VeriCountrySelect = React.forwardRef<
  HTMLSelectElement,
  VeriCountrySelectProps
>(({ addEmptyOptionCountry = false, ...selectProps }, ref) => {
  return (
    <VeriSelect label="Country" {...selectProps} ref={ref}>
      {addEmptyOptionCountry && <option value="">Select a country</option>}
      {countries.map((country) => (
        <option key={country.iso2} value={country.iso2}>
          {country.name}
        </option>
      ))}
    </VeriSelect>
  );
});

VeriCountrySelect.displayName = "VeriCountrySelect";

export default VeriCountrySelect;
