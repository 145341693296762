import { As, BoxProps, CheckboxProps, LayoutProps } from "@chakra-ui/react";
import { Token } from "@chakra-ui/styled-system/src/utils/types";
import { Property } from "csstype";
import React from "react";
import { ApiClient } from "../../../api";
import { useVeriModel } from "../../../api/hooks/useVeriModel";
import { JsonApiMeta } from "../../../api/types";
import { ButtonProps } from "../../../buttons/Button";
import { Dict } from "../../../defines";
import { BreakPointPriority } from "../../../defines/ui";
import { UseFormReturn } from "../../../react-hook-form";
import { ConfirmModalProps } from "../../modals";
import { VeriTableStyles } from "./VeriTable.styles";

export enum VeriTableSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export interface VeriTableColumn {
  sortable?: boolean;
  columnAlign?: Token<Property.TextAlign>;
  rowAlign?: Token<Property.TextAlign>;
  label?: React.ReactNode;
  sortName?: string;
  // render?: (props: { value: any }) => React.ReactNode;
  rendererOptions?: Dict;
  processRow?: (
    column: VeriTableColumn,
    value: any,
    record: any,
    tableObject: VeriTableObject<any>,
  ) => React.ReactNode;
  inlineEdit?: {
    checkActionAllowed?: (record: any) => boolean;
    onSubmit?: (values: any, record: any, table: VeriTableObject<any>) => void;
    component?: (form: UseFormReturn<any>) => React.ReactNode;
  };
  renderer?: React.JSXElementConstructor<any>;
  props?: BoxProps;
  emptyReplace?: string;
  hideOnBreakpoint?: BreakPointPriority;
}

export interface VeriTableAction {
  label?: React.ReactNode;
  isInlineAction?: boolean;
  allowBulk?: boolean;
  isMoreAction?: boolean;
  checkActionAllowed?:
    | boolean
    | ((record: any, table: VeriTableObject<any>) => boolean);
  getTooltipText?: (record: any) => string;
  browseForFile?: boolean;
  fileCallback?: (
    file: FileList,
    record: any,
    table: VeriTableObject<any>,
  ) => void;
  isDiver?: boolean;
  subs?: { [key: string]: VeriTableAction };
  icon?: As;
}

type RowAction<T> = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  record: T,
) => void;

export interface VeriTableProps<T> {
  defaultSortingDirection?: VeriTableSortDirection;
  actions?: { [P in string]: VeriTableAction };
  variant?: keyof typeof VeriTableStyles;
  columns: Dict<VeriTableColumn>;
  records?: T[];
  onRowAction?: RowAction<T>;
  getActionForRow?: (record: T) => RowAction<T> | undefined;
  allowBulkSelection?: boolean;
  indexIdentifier?: keyof T;
  tableProps?: BoxProps & { "data-private"?: any };
  hideLastDivider?: boolean;
  onBulkSelectionChange?: (
    selection: string[],
    meta: JsonApiMeta | null,
  ) => void;
  defaultBulkSelection?: string[];
  footerRecord?: T;
  recordsPerPage?: number;
  apiUrl?: string;
  apiPagination?: boolean;
  apiUrlParams?: Dict;
  mapApiRecord?: (record: T) => T;
  apiFallbackData?: any;
  scrollOnTopOnPageChange?: boolean;
  onPageChange?: (page: number) => void;
  hideHeader?: boolean;
  minHeight?: LayoutProps["minHeight"];
  rowAllowMoreActions?:
    | boolean
    | ((record: T, table: VeriTableObject<T>) => boolean);
  actionRowExtra?: (record: T, table: VeriTableObject<T>) => React.ReactNode;
  noRecordsMessage?: React.ReactNode;
  tHeadProps?: BoxProps;
  sortable?: boolean;
  apiSortable?: boolean;
  sortRecords?: (
    sortingColumn: string,
    sortingDirection: VeriTableSortDirection,
    records: T[],
    table: VeriTableObject<T>,
  ) => Promise<T[]>;
  defaultSorting?: string;
  noRecordsContent?: React.ReactNode;
  onRecordsChanged?: (records: T[], table: VeriTableObject<T>) => void;
  selectAllSelectAllPages?: boolean;
  onSelectionAllToggle?: (
    isSelected: boolean,
    totalAmountOfRecords: number,
  ) => void;
  footer?: React.ReactNode;
  showTotalRecords?: boolean;
  tableHeadLabel?: React.ReactNode;
  filterRecords?: (records: T[], meta: Dict) => T[];
  refTableObject?: any;
  api?: ApiClient;
  model?: ReturnType<typeof useVeriModel>;
  checkboxProps?: CheckboxProps;
  isJsonApi?: boolean;
  actionRecordWrapperProps?: BoxProps;
}

export interface VeriTableConfirmData<T> extends ConfirmModalProps {
  inlineBanner?: boolean;
  record?: T;
}

export interface VeriTableAction {
  callback?: (ids: string[], records: any, table: VeriTableObject<any>) => void;
  confirm?: {
    title?: (records: any) => React.ReactNode;
    message?: (records: any) => React.ReactNode;
    cancelLabel?: (record: any) => React.ReactNode;
    confirmLabel?: (record: any) => React.ReactNode;
    confirmProps?: (record: any) => ButtonProps;
    cancelProps?: (record: any) => ButtonProps;
    inlineBanner?: boolean;
  };
}

export interface VeriTableObject<T> {
  setCurrentPage: (page: number) => void;
  isAllRecordsSelected: boolean;
  isIndeterminateSelection: boolean;
  sortingColumn?: string;
  sortingDirection: VeriTableSortDirection;
  setSortingDirection: (direction: VeriTableSortDirection) => void;
  setSortingColumn: (column: string) => void;
  setConfirmData: (confirmData: VeriTableConfirmData<T> | null) => void;
  refresh: (customUrlParams?: Dict) => void;
  toggleAllSelection: () => void;
  selectAllRecords: () => void;
  clearSelection: () => void;
  toggleRecordSelection: (recordId: string) => void;
  totalRecordsCount: number;
  recordsRef: React.MutableRefObject<T[]>;
}

export interface VeriTableRendererProps {
  value: any;
  column: VeriTableColumn;
  tableObject: VeriTableObject<any>;
  record: any;
  recordIndex: number;
  columnIndex: number;
  columnName: string;
  options: Dict<any>;
}
