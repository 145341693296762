/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BillingPriceJsonApiModel = {
  id: "",
  "product-id": "",
  "billing-product": {
    jsonApi: "hasOne",
    type: "billing-products",
  },
  description: "",
  price: "",
  "active-begin-date": "",
  "active-end-date": "",
  "is-active": "",
  "is-subscription": "",
  "subscription-term": "",
  "is-discounted": "",
  "discount-percent": "",
  "stripe-price-id": "",
  "created-at": "",
  "updated-at": "",
};
