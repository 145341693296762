import React, { useState } from "react";
import { useVeriToast } from "../../hooks/ui/toast/useVeriToast";
import { logError } from "../logError";
import { getApiErrorMessage } from "./getApiErrorMessage";

export const useStandardApiHandler = (initialIsLoading = false) => {
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const { toastError, toastSuccess } = useVeriToast();

  function wrapApiRequest<TParams extends unknown[], TResponse>(
    request: (...params: TParams) => Promise<TResponse>,
    successToastMessage?: React.ReactNode,
    onError?: (error: unknown) => void,
  ) {
    return async (...params: TParams): Promise<TResponse | void> => {
      try {
        setIsLoading(true);
        const response = await request(...params);
        setIsLoading(false);

        if (successToastMessage) {
          toastSuccess(successToastMessage);
        }

        return response;
      } catch (error) {
        setIsLoading(false);

        logError(error);
        const errorMessage = getApiErrorMessage(error);
        toastError(errorMessage);
        onError?.(error);
      }
    };
  }

  return {
    isLoading,
    wrapApiRequest,
  };
};
