/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UpdateBillingProductOutputJsonApiModel = {
  id: "",
  "billing-product": {
    jsonApi: "hasOne",
    type: "billing-products",
  },
};
