import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ServerError } from "../../../defines";
import { useBooleanState } from "../../../hooks";
import { getServerError } from "../../../utils";
import { Button, MessageBox, VeriHeading } from "../../common";
import { ConfirmModalProps } from "./ConfirmModal.types";

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  title,
  message,
  onClose,
  onConfirm,
  confirmProps,
  cancelProps,
  buttonWrapperProps,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
  showModalClose = true,
  error: userDefinedError,
  children,
  isContentCentered,
  titleProps,
  ...rest
}) => {
  const isLoading = useBooleanState(false);
  const [asyncError, setAsyncError] = React.useState("");

  const error = userDefinedError || asyncError;

  const handleConfirm = async () => {
    setAsyncError("");

    if (onConfirm) {
      isLoading.true();
      const results = await onConfirm();
      if (results) {
        const error = getServerError(results as ServerError);
        setAsyncError(error.message);
      }
      isLoading.false();
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size={"3xl"} {...rest}>
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalBody>
          {showModalClose && (
            <ModalCloseButton position={"relative"} mb={8} right={2} />
          )}

          {error && <MessageBox type={"error"} text={error} mb={6} />}

          <VeriHeading
            textAlign={isContentCentered ? "center" : undefined}
            textStyle={"h4"}
            color={"text.black"}
            {...titleProps}
          >
            {title}
          </VeriHeading>
          <Text
            textAlign={isContentCentered ? "center" : undefined}
            as={"div"}
            mt={2}
            textStyle={"bodyRegular"}
            color={"text.black"}
          >
            {message}
          </Text>
          {!!children && <Box>{children}</Box>}
          <Flex
            justifyContent={isContentCentered ? "center" : undefined}
            mt={8}
            gap={4}
            {...buttonWrapperProps}
          >
            <Button
              label={cancelLabel}
              onClick={onClose}
              isLarge
              isBold
              outline
              isDisabled={isLoading.state}
              {...cancelProps}
            />
            <Button
              label={confirmLabel}
              onClick={handleConfirm}
              isLarge
              isBold
              isLoading={isLoading.state}
              {...confirmProps}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
