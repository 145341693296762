/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BillingInvoiceJsonApiModel = {
  id: "",
  "stripe-invoice-id": "",
  "stripe-invoice-status": "",
  "stripe-invoice-url": "",
  "billing-entity-id": "",
  "billing-entity": {
    jsonApi: "hasOne",
    type: "billing-entities",
  },
  "charged-billing-entity-id": "",
  "charged-billing-entity": {
    jsonApi: "hasOne",
    type: "billing-entities",
  },
  "due-date": "",
  amount: "",
  "created-at": "",
  "updated-at": "",
};
