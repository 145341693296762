import { Box } from "@chakra-ui/react";
import React from "react";

interface PaddingDividerProps {
  spacing?: string | number;
  pt?: string | number;
  dividerHeight?: number | string;
}

export const PaddingDivider = ({
  spacing = 12,
  pt,
  dividerHeight = "1px",
}: PaddingDividerProps) => (
  <Box py={spacing} pt={pt}>
    <Box height={dividerHeight} backgroundColor="border.grey" />
  </Box>
);
