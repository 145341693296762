import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Box, Icon, Text } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "./Icons";

export default class PaginationBoxView extends Component {
  static propTypes = {
    pageCount: PropTypes.number.isRequired,
    pageRangeDisplayed: PropTypes.number.isRequired,
    previousLabel: PropTypes.node,
    previousAriaLabel: PropTypes.string,
    prevRel: PropTypes.string,
    nextLabel: PropTypes.node,
    nextAriaLabel: PropTypes.string,
    nextRel: PropTypes.string,
    breakLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hrefBuilder: PropTypes.func,
    onPageChange: PropTypes.func,
    initialPage: PropTypes.number,
    forcePage: PropTypes.number,
    disableInitialCallback: PropTypes.bool,
    containerClassName: PropTypes.string,
    pageClassName: PropTypes.string,
    pageLinkClassName: PropTypes.string,
    activeClassName: PropTypes.string,
    activeLinkClassName: PropTypes.string,
    previousClassName: PropTypes.string,
    nextClassName: PropTypes.string,
    previousLinkClassName: PropTypes.string,
    nextLinkClassName: PropTypes.string,
    disabledClassName: PropTypes.string,
    breakClassName: PropTypes.string,
    breakLinkClassName: PropTypes.string,
    extraAriaContext: PropTypes.string,
    ariaLabelBuilder: PropTypes.func,
    eventListener: PropTypes.string,
  };

  static defaultProps = {
    pageCount: 10,
    pageRangeDisplayed: 2,
    marginPagesDisplayed: 3,

    breakLabel: "...",
    disableInitialCallback: false,
  };

  handlePreviousPage = (evt) => {
    const { selected } = this.props;
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (selected > 0) {
      this.handlePageSelected(selected - 1, evt);
    }
  };

  handleNextPage = (evt) => {
    const { selected, pageCount } = this.props;

    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (selected < pageCount - 1) {
      this.handlePageSelected(selected + 1, evt);
    }
  };

  handlePageSelected = (selected, evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);

    if (this.props.selected === selected) return;

    // Call the callback with the new selected item:
    this.callCallback(selected);
  };

  getEventListener = (handlerFunction) => {
    const { eventListener } = this.props;
    return {
      [eventListener]: handlerFunction,
    };
  };

  getForwardJump() {
    const { selected, pageCount, pageRangeDisplayed } = this.props;

    const forwardJump = selected + pageRangeDisplayed;
    return forwardJump >= pageCount ? pageCount - 1 : forwardJump;
  }

  getBackwardJump() {
    const { selected, pageRangeDisplayed } = this.props;

    const backwardJump = selected - pageRangeDisplayed;
    return backwardJump < 0 ? 0 : backwardJump;
  }

  handleBreakClick = (index, evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);

    const { selected } = this.props;

    this.handlePageSelected(
      selected < index ? this.getForwardJump() : this.getBackwardJump(),
      evt,
    );
  };

  hrefBuilder(pageIndex) {
    const { hrefBuilder, pageCount } = this.props;
    if (
      hrefBuilder &&
      pageIndex !== this.props.selected &&
      pageIndex >= 0 &&
      pageIndex < pageCount
    ) {
      return hrefBuilder(pageIndex + 1);
    }
  }

  onClickBuilder(pageIndex) {
    const { onClickBuilder, pageCount } = this.props;
    if (
      onClickBuilder &&
      pageIndex !== this.props.selected &&
      pageIndex >= 0 &&
      pageIndex < pageCount
    ) {
      return onClickBuilder(pageIndex + 1);
    }
  }

  callCallback = (selectedItem) => {
    if (
      typeof this.props.onPageChange !== "undefined" &&
      typeof this.props.onPageChange === "function"
    ) {
      this.props.onPageChange({ selected: selectedItem });
    }
  };

  getPageElement(index) {
    const { selected } = this.props;
    return (
      <PageButton
        key={index}
        href={this.hrefBuilder(index)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.onClickBuilder(index);
        }}
        selected={selected === index}
        page={index + 1}
      />
    );
  }

  pagination = () => {
    const items = [];
    const { selected, pageRangeDisplayed, pageCount, marginPagesDisplayed } =
      this.props;

    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        items.push(this.getPageElement(index));
      }
    } else {
      let leftSide = pageRangeDisplayed / 2;
      let rightSide = pageRangeDisplayed - leftSide;

      // If the selected page index is on the default right side of the pagination,
      // we consider that the new right side is made up of it (= only one break element).
      // If the selected page index is on the default left side of the pagination,
      // we consider that the new left side is made up of it (= only one break element).
      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected;
        leftSide = pageRangeDisplayed - rightSide;
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected;
        rightSide = pageRangeDisplayed - leftSide;
      }

      let index;
      let page;
      let breakView;
      let createPageView = (index) => this.getPageElement(index);

      for (index = 0; index < pageCount; index++) {
        page = index + 1;

        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > pageCount - marginPagesDisplayed) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(createPageView(index));
          continue;
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (items[items.length - 1] !== breakView) {
          breakView = (
            <Box key={index + "break"} as="li" display="inline-block" mr="16px">
              <Box
                key={index}
                h="32px"
                w="32px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                ...
              </Box>
            </Box>
          );
          items.push(breakView);
        }
      }
    }

    return items;
  };

  render() {
    const { pageCount, nextRel, selected } = this.props;

    const previousDisabled = selected === 0;
    const nextDisabled = selected === pageCount - 1;

    return (
      <Box
        as="ul"
        pl="15px"
        pr="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box as="li" display="inline-block" mr="16px">
          {!previousDisabled && (
            <Box
              as={Link}
              to={this.hrefBuilder(selected - 1) || "#"}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.onClickBuilder(selected - 1);
              }}
              h="32px"
              w="32px"
              borderRadius={"50%"}
              _hover={{ color: "brand.main" }}
              color={"text.grey"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              onKeyPress={this.handlePreviousPage}
              rel={nextRel}
            >
              <Icon as={ChevronLeftIcon} boxSize="24px" />
            </Box>
          )}
        </Box>

        {this.pagination()}

        <Box as="li" display="inline-block">
          {!nextDisabled && (
            <Box
              as={Link}
              to={this.hrefBuilder(selected + 1) || "#"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.onClickBuilder(selected + 1);
              }}
              h="32px"
              w="32px"
              borderRadius={"50%"}
              _hover={{ color: "brand.main" }}
              color={"text.grey"}
              display="flex"
              alignItems="center"
              justifyContent="center"
              onKeyPress={this.handleNextPage}
              rel={nextRel}
            >
              <Icon as={ChevronRightIcon} boxSize="24px" />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
}

const PageButton = ({ selected, page, href, onClick }) => {
  return (
    <Box as="li" display="inline-block" mr="16px">
      <Box
        as={(!selected && Link) || "span"}
        to={href || "#"}
        onClick={onClick}
        h="32px"
        w="32px"
        borderRadius={"50%"}
        bg={selected && "brand.main"}
        _hover={{ color: selected ? "white" : "brand.main" }}
        color={selected ? "white" : "text.grey"}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text as="span" fontWeight="700">
          {page}
        </Text>
      </Box>
    </Box>
  );
};
