/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const TransactionSetJsonApiModel = {
  id: "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  investment: {
    jsonApi: "hasOne",
    type: "investments",
  },
  "transaction-set-type": "",
  exchange: {
    jsonApi: "hasOne",
    type: "exchanges",
  },
  imported: "",
  redemption: {
    jsonApi: "hasOne",
    type: "redemptions",
  },
  transfer: {
    jsonApi: "hasOne",
    type: "transfers",
  },
};
