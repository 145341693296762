/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetChartOutputJsonApiModel = {
  id: "",
  chart: {
    jsonApi: "hasOne",
    type: "charts",
  },
  nodes: {
    jsonApi: "hasMany",
    type: "charts",
  },
  graph: "",
};
