import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";

const handleAdjustSizeToContent = (e) => {
  e.target.style.height = "";
  e.target.style.height = e.target.scrollHeight + 5 + "px";
};
function VeriTextarea({
  type = "text",
  label,
  value,
  onChange,
  error,
  helperText,
  height,
  propsTextArea = {},
  textAreaRef,
  maxCharsLength,
  // isHTML,
  adjustSizeToContent,
  ...rest
}) {
  const handleChange = (event) => {
    if (adjustSizeToContent) {
      handleAdjustSizeToContent(event);
    }
    if (onChange) {
      onChange(event);
    }

    if (propsTextArea.onChange) {
      propsTextArea.onChange(event);
    }
  };

  return (
    <FormControl isInvalid={!!error} {...rest}>
      {label && (
        <FormLabel
          lineHeight="24px"
          mb="8px"
          fontWeight="500"
          htmlFor="login_email"
          data-testid="textarea-label"
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        data-testid="textarea"
        value={value}
        _focus={{
          borderColor: "brand.main",
          color: "black",
        }}
        focusBorderColor="brand.main"
        borderRadius={4}
        borderColor="border.input"
        fontFamily="Nunito Sans"
        fontSize="md"
        color="black"
        type={type}
        height={height || "40px"}
        ref={textAreaRef}
        {...propsTextArea}
        onChange={handleChange}
        maxLength={maxCharsLength}
      />
      {maxCharsLength && (
        <Box
          mt={2}
          color={"text.grey"}
          textAlign={"right"}
          data-testid="textarea-max-characters"
        >
          {(value || "").length}/{maxCharsLength} characters
        </Box>
      )}
      {helperText && !error && (
        <FormHelperText id="email-helper-text" data-testid="textarea-helper">
          {helperText}
        </FormHelperText>
      )}
      {error && (
        <FormErrorMessage data-testid="textarea-error">
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  );
}

VeriTextarea.propTypes = {
  /**
   * label of the field
   */
  label: PropTypes.string,
  /**
   * Value of the field
   */
  value: PropTypes.string,
  /**
   * Type of rht einput field
   */
  type: PropTypes.string,
  /**
   * props passed to the Input(chakra-ui) field
   */
  propsInput: PropTypes.object,
  /**
   * callback for onChange
   */
  onChange: PropTypes.func,
};

export default VeriTextarea;
