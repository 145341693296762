/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
import { AccountJsonApiModel } from "./Account";
import { AddressJsonApiModel } from "./Address";
import { AdminDashboardSearchJsonApiModel } from "./AdminDashboardSearch";
import { AgreementJsonApiModel } from "./Agreement";
import { AllocationJsonApiModel } from "./Allocation";
import { AllocationGroupJsonApiModel } from "./AllocationGroup";
import { AllocationMethodJsonApiModel } from "./AllocationMethod";
import { AssetJsonApiModel } from "./Asset";
import { AssetKeyTenantJsonApiModel } from "./AssetKeyTenant";
import { AssetPurchaseJsonApiModel } from "./AssetPurchase";
import { AssetPurchaseCostJsonApiModel } from "./AssetPurchaseCost";
import { AssetPurchaseDebtJsonApiModel } from "./AssetPurchaseDebt";
import { AssetTypeJsonApiModel } from "./AssetType";
import { AttributeJsonApiModel } from "./Attribute";
import { AttributeItemJsonApiModel } from "./AttributeItem";
import { BackgroundCheckJsonApiModel } from "./BackgroundCheck";
import { BackgroundCheckItemJsonApiModel } from "./BackgroundCheckItem";
import { BankAccountJsonApiModel } from "./BankAccount";
import { BannerImageJsonApiModel } from "./BannerImage";
import { BillingEntityJsonApiModel } from "./BillingEntity";
import { BillingEventJsonApiModel } from "./BillingEvent";
import { BillingInvoiceJsonApiModel } from "./BillingInvoice";
import { BillingPriceJsonApiModel } from "./BillingPrice";
import { BillingProductJsonApiModel } from "./BillingProduct";
import { BillingScheduleJsonApiModel } from "./BillingSchedule";
import { BillingScheduleTierJsonApiModel } from "./BillingScheduleTier";
import { BlueSkyFilingJsonApiModel } from "./BlueSkyFiling";
import { CalculateBillingEventAmountOutputJsonApiModel } from "./CalculateBillingEventAmountOutput";
import { CalculateBillingEventDefaultsOutputJsonApiModel } from "./CalculateBillingEventDefaultsOutput";
import { CalculateBillingScheduleChargeInputJsonApiModel } from "./CalculateBillingScheduleChargeInput";
import { CalculateBillingScheduleChargeOutputJsonApiModel } from "./CalculateBillingScheduleChargeOutput";
import { CalculateTransferInputJsonApiModel } from "./CalculateTransferInput";
import { CalculateTransferOutputJsonApiModel } from "./CalculateTransferOutput";
import { ChartJsonApiModel } from "./Chart";
import { ChartNodeJsonApiModel } from "./ChartNode";
import { ContributionJsonApiModel } from "./Contribution";
import { CreateBillingEntityOutputJsonApiModel } from "./CreateBillingEntityOutput";
import { CreateBillingPriceOutputJsonApiModel } from "./CreateBillingPriceOutput";
import { CreateBillingProductOutputJsonApiModel } from "./CreateBillingProductOutput";
import { CreateBillingScheduleInputJsonApiModel } from "./CreateBillingScheduleInput";
import { CreateBillingScheduleOutputJsonApiModel } from "./CreateBillingScheduleOutput";
import { CreateBillingScheduleTierInputJsonApiModel } from "./CreateBillingScheduleTierInput";
import { CreateBillingScheduleTierOutputJsonApiModel } from "./CreateBillingScheduleTierOutput";
import { CreateBlueSkyFilingInputJsonApiModel } from "./CreateBlueSkyFilingInput";
import { CreateBlueSkyFilingOutputJsonApiModel } from "./CreateBlueSkyFilingOutput";
import { CreateChartInputJsonApiModel } from "./CreateChartInput";
import { CreateChartNodeInputJsonApiModel } from "./CreateChartNodeInput";
import { CreateChartNodeOutputJsonApiModel } from "./CreateChartNodeOutput";
import { CreateChartOutputJsonApiModel } from "./CreateChartOutput";
import { CreateDocumentsZipJobOutputJsonApiModel } from "./CreateDocumentsZipJobOutput";
import { CreateEntityAddressInputJsonApiModel } from "./CreateEntityAddressInput";
import { CreateEntityAddressOutputJsonApiModel } from "./CreateEntityAddressOutput";
import { CreateEntityInputJsonApiModel } from "./CreateEntityInput";
import { CreateEntityKeyPersonnelInputJsonApiModel } from "./CreateEntityKeyPersonnelInput";
import { CreateEntityKeyPersonnelOutputJsonApiModel } from "./CreateEntityKeyPersonnelOutput";
import { CreateEntityOpportunityInputJsonApiModel } from "./CreateEntityOpportunityInput";
import { CreateEntityOpportunityOutputJsonApiModel } from "./CreateEntityOpportunityOutput";
import { CreateEntityOutputJsonApiModel } from "./CreateEntityOutput";
import { CreateFeeInputJsonApiModel } from "./CreateFeeInput";
import { CreateFeeInstanceInputJsonApiModel } from "./CreateFeeInstanceInput";
import { CreateFeeInstanceOutputJsonApiModel } from "./CreateFeeInstanceOutput";
import { CreateFeeOutputJsonApiModel } from "./CreateFeeOutput";
import { CreateFundBuilderOrganizationalChartInputJsonApiModel } from "./CreateFundBuilderOrganizationalChartInput";
import { CreateFundBuilderOrganizationalChartOutputJsonApiModel } from "./CreateFundBuilderOrganizationalChartOutput";
import { CreateInvestmentInputJsonApiModel } from "./CreateInvestmentInput";
import { CreateManagerIndividualInputJsonApiModel } from "./CreateManagerIndividualInput";
import { CreateManagerIndividualOutputJsonApiModel } from "./CreateManagerIndividualOutput";
import { CreateManualBillingEventInputJsonApiModel } from "./CreateManualBillingEventInput";
import { CreateManualBillingEventOutputJsonApiModel } from "./CreateManualBillingEventOutput";
import { CreateOpportunityAffiliateInputJsonApiModel } from "./CreateOpportunityAffiliateInput";
import { CreateOpportunityAffiliateOutputJsonApiModel } from "./CreateOpportunityAffiliateOutput";
import { CreateOpportunityTimelineItemInputJsonApiModel } from "./CreateOpportunityTimelineItemInput";
import { CreateOpportunityTimelineItemOutputJsonApiModel } from "./CreateOpportunityTimelineItemOutput";
import { CreateOpportunityTimelineSectionInputJsonApiModel } from "./CreateOpportunityTimelineSectionInput";
import { CreateOpportunityTimelineSectionOutputJsonApiModel } from "./CreateOpportunityTimelineSectionOutput";
import { CreateOrganizationalChartInputJsonApiModel } from "./CreateOrganizationalChartInput";
import { CreateOrganizationalChartOutputJsonApiModel } from "./CreateOrganizationalChartOutput";
import { CreateSecurityInputJsonApiModel } from "./CreateSecurityInput";
import { CreateSecurityOutputJsonApiModel } from "./CreateSecurityOutput";
import { CreateTransferInputJsonApiModel } from "./CreateTransferInput";
import { CreateTransferOutputJsonApiModel } from "./CreateTransferOutput";
import { CustodianJsonApiModel } from "./Custodian";
import { CustomDomainJsonApiModel } from "./CustomDomain";
import { DashboardSearchJsonApiModel } from "./DashboardSearch";
import { DeleteBillingEntityOutputJsonApiModel } from "./DeleteBillingEntityOutput";
import { DeleteBillingProductOutputJsonApiModel } from "./DeleteBillingProductOutput";
import { DeleteBillingScheduleInputJsonApiModel } from "./DeleteBillingScheduleInput";
import { DeleteBillingScheduleOutputJsonApiModel } from "./DeleteBillingScheduleOutput";
import { DeleteEntityInputJsonApiModel } from "./DeleteEntityInput";
import { DeleteEntityOpportunityInputJsonApiModel } from "./DeleteEntityOpportunityInput";
import { DeleteEntityOpportunityOutputJsonApiModel } from "./DeleteEntityOpportunityOutput";
import { DeleteEntityOutputJsonApiModel } from "./DeleteEntityOutput";
import { DeleteFeeInputJsonApiModel } from "./DeleteFeeInput";
import { DeleteFeeInstanceInputJsonApiModel } from "./DeleteFeeInstanceInput";
import { DeleteFeeInstanceOutputJsonApiModel } from "./DeleteFeeInstanceOutput";
import { DeleteFeeOutputJsonApiModel } from "./DeleteFeeOutput";
import { DeleteFundBuilderOutputJsonApiModel } from "./DeleteFundBuilderOutput";
import { DistributionJsonApiModel } from "./Distribution";
import { DistributionDetailJsonApiModel } from "./DistributionDetail";
import { DistributionHoldingJsonApiModel } from "./DistributionHolding";
import { DistributionPreferenceJsonApiModel } from "./DistributionPreference";
import { DistributionSetJsonApiModel } from "./DistributionSet";
import { DocumentsTagJsonApiModel } from "./DocumentsTag";
import { DocusignSettingJsonApiModel } from "./DocusignSetting";
import { EmailJsonApiModel } from "./Email";
import { EntityJsonApiModel } from "./Entity";
import { EntityAddressJsonApiModel } from "./EntityAddress";
import { EntityKeyPersonnelJsonApiModel } from "./EntityKeyPersonnel";
import { EntityOpportunityJsonApiModel } from "./EntityOpportunity";
import { ExchangeJsonApiModel } from "./Exchange";
import { FeeJsonApiModel } from "./Fee";
import { FeeInstanceJsonApiModel } from "./FeeInstance";
import { FlattenedInvestmentJsonApiModel } from "./FlattenedInvestment";
import { FlattenedInvestmentItemJsonApiModel } from "./FlattenedInvestmentItem";
import { FundBuilderJsonApiModel } from "./FundBuilder";
import { FundBuilderChargesJsonApiModel } from "./FundBuilderCharges";
import { FundBuilderFormJsonApiModel } from "./FundBuilderForm";
import { GIContributionJsonApiModel } from "./GIContribution";
import { GIGenericJsonApiModel } from "./GIGeneric";
import { GIOwnershipJsonApiModel } from "./GIOwnership";
import { GenerateChartGraphInputJsonApiModel } from "./GenerateChartGraphInput";
import { GenerateChartGraphOutputJsonApiModel } from "./GenerateChartGraphOutput";
import { GetAssetTypeInputJsonApiModel } from "./GetAssetTypeInput";
import { GetAssetTypeOutputJsonApiModel } from "./GetAssetTypeOutput";
import { GetAssetTypesInputJsonApiModel } from "./GetAssetTypesInput";
import { GetAssetTypesOutputJsonApiModel } from "./GetAssetTypesOutput";
import { GetBillingEntitiesOutputJsonApiModel } from "./GetBillingEntitiesOutput";
import { GetBillingEntityOutputJsonApiModel } from "./GetBillingEntityOutput";
import { GetBillingInvoiceOutputJsonApiModel } from "./GetBillingInvoiceOutput";
import { GetBillingInvoicesOutputJsonApiModel } from "./GetBillingInvoicesOutput";
import { GetBillingPriceOutputJsonApiModel } from "./GetBillingPriceOutput";
import { GetBillingPricesOutputJsonApiModel } from "./GetBillingPricesOutput";
import { GetBillingProductOutputJsonApiModel } from "./GetBillingProductOutput";
import { GetBillingProductsOutputJsonApiModel } from "./GetBillingProductsOutput";
import { GetBillingScheduleOutputJsonApiModel } from "./GetBillingScheduleOutput";
import { GetBillingScheduleTiersOutputJsonApiModel } from "./GetBillingScheduleTiersOutput";
import { GetChartInputJsonApiModel } from "./GetChartInput";
import { GetChartNodesInputJsonApiModel } from "./GetChartNodesInput";
import { GetChartNodesOutputJsonApiModel } from "./GetChartNodesOutput";
import { GetChartOutputJsonApiModel } from "./GetChartOutput";
import { GetEntitiesInputJsonApiModel } from "./GetEntitiesInput";
import { GetEntitiesOutputJsonApiModel } from "./GetEntitiesOutput";
import { GetEntityInputJsonApiModel } from "./GetEntityInput";
import { GetEntityOpportunitiesInputJsonApiModel } from "./GetEntityOpportunitiesInput";
import { GetEntityOpportunitiesOutputJsonApiModel } from "./GetEntityOpportunitiesOutput";
import { GetEntityOpportunityInputJsonApiModel } from "./GetEntityOpportunityInput";
import { GetEntityOpportunityOutputJsonApiModel } from "./GetEntityOpportunityOutput";
import { GetEntityOutputJsonApiModel } from "./GetEntityOutput";
import { GetFeeInputJsonApiModel } from "./GetFeeInput";
import { GetFeeInstanceInputJsonApiModel } from "./GetFeeInstanceInput";
import { GetFeeInstanceOutputJsonApiModel } from "./GetFeeInstanceOutput";
import { GetFeeInstancesInputJsonApiModel } from "./GetFeeInstancesInput";
import { GetFeeInstancesOutputJsonApiModel } from "./GetFeeInstancesOutput";
import { GetFeeOutputJsonApiModel } from "./GetFeeOutput";
import { GetFeesInputJsonApiModel } from "./GetFeesInput";
import { GetFeesOutputJsonApiModel } from "./GetFeesOutput";
import { GetHoldingInputJsonApiModel } from "./GetHoldingInput";
import { GetHoldingOutputJsonApiModel } from "./GetHoldingOutput";
import { GetMetropolitanStatisticalAreasInputJsonApiModel } from "./GetMetropolitanStatisticalAreasInput";
import { GetMetropolitanStatisticalAreasOutputJsonApiModel } from "./GetMetropolitanStatisticalAreasOutput";
import { GetNotificationsOutputJsonApiModel } from "./GetNotificationsOutput";
import { GetOfferingExemptionInputJsonApiModel } from "./GetOfferingExemptionInput";
import { GetOfferingExemptionOutputJsonApiModel } from "./GetOfferingExemptionOutput";
import { GetOfferingExemptionsInputJsonApiModel } from "./GetOfferingExemptionsInput";
import { GetOfferingExemptionsOutputJsonApiModel } from "./GetOfferingExemptionsOutput";
import { GetOfferingsInputJsonApiModel } from "./GetOfferingsInput";
import { GetOfferingsOutputJsonApiModel } from "./GetOfferingsOutput";
import { GetSecuritiesInputJsonApiModel } from "./GetSecuritiesInput";
import { GetSecuritiesOutputJsonApiModel } from "./GetSecuritiesOutput";
import { GetTransferInputJsonApiModel } from "./GetTransferInput";
import { GetTransferOutputJsonApiModel } from "./GetTransferOutput";
import { GetTransfersInputJsonApiModel } from "./GetTransfersInput";
import { GetTransfersOutputJsonApiModel } from "./GetTransfersOutput";
import { GroupedInvestmentJsonApiModel } from "./GroupedInvestment";
import { HoldingJsonApiModel } from "./Holding";
import { HoldingRateUpdateJsonApiModel } from "./HoldingRateUpdate";
import { HoldingsActivityJsonApiModel } from "./HoldingsActivity";
import { ImageJsonApiModel } from "./Image";
import { ImportJsonApiModel } from "./Import";
import { ImportAccountJsonApiModel } from "./ImportAccount";
import { ImportAccountMatchDataJsonApiModel } from "./ImportAccountMatchData";
import { ImportBalanceJsonApiModel } from "./ImportBalance";
import { ImportDistributionPreferenceJsonApiModel } from "./ImportDistributionPreference";
import { ImportLogJsonApiModel } from "./ImportLog";
import { ImportPositionJsonApiModel } from "./ImportPosition";
import { ImportTransactionJsonApiModel } from "./ImportTransaction";
import { ImportUserJsonApiModel } from "./ImportUser";
import { ImporterJsonApiModel } from "./Importer";
import { InvestmentJsonApiModel } from "./Investment";
import { InvestorAssistJsonApiModel } from "./InvestorAssist";
import { LocationJsonApiModel } from "./Location";
import { LoginJsonApiModel } from "./Login";
import { ManagerJsonApiModel } from "./Manager";
import { ManagerCardJsonApiModel } from "./ManagerCard";
import { ManagerContactJsonApiModel } from "./ManagerContact";
import { ManagerContactMessageJsonApiModel } from "./ManagerContactMessage";
import { ManagerEmployeeJsonApiModel } from "./ManagerEmployee";
import { ManagerIndividualJsonApiModel } from "./ManagerIndividual";
import { ManagerReviewJsonApiModel } from "./ManagerReview";
import { ManagerWhiteLabelSubscriptionJsonApiModel } from "./ManagerWhiteLabelSubscription";
import { MediaItemsJsonApiModel } from "./MediaItems";
import { MetropolitanStatisticalAreaJsonApiModel } from "./MetropolitanStatisticalArea";
import { MinimumInvestmentManagerJsonApiModel } from "./MinimumInvestmentManager";
import { MonitoringReportJsonApiModel } from "./MonitoringReport";
import { MonitoringReportItemJsonApiModel } from "./MonitoringReportItem";
import { ObjectSnapshotJsonApiModel } from "./ObjectSnapshot";
import { OfferingJsonApiModel } from "./Offering";
import { OfferingExemptionJsonApiModel } from "./OfferingExemption";
import { OpportunityJsonApiModel } from "./Opportunity";
import { OpportunityAffiliateJsonApiModel } from "./OpportunityAffiliate";
import { OpportunityMetricJsonApiModel } from "./OpportunityMetric";
import { OpportunityPointJsonApiModel } from "./OpportunityPoint";
import { OpportunityTimelineItemJsonApiModel } from "./OpportunityTimelineItem";
import { OpportunityTimelineSectionJsonApiModel } from "./OpportunityTimelineSection";
import { PortfolioJsonApiModel } from "./Portfolio";
import { PortfolioManagerJsonApiModel } from "./PortfolioManager";
import { PreviewAgreementDocumentInputJsonApiModel } from "./PreviewAgreementDocumentInput";
import { PreviewAgreementDocumentOutputJsonApiModel } from "./PreviewAgreementDocumentOutput";
import { PrincipalJsonApiModel } from "./Principal";
import { PrincipalHistoryJsonApiModel } from "./PrincipalHistory";
import { RedemptionJsonApiModel } from "./Redemption";
import { ReportJsonApiModel } from "./Report";
import { RequestPrincipalsBackgroundCheckPaymentOutputJsonApiModel } from "./RequestPrincipalsBackgroundCheckPaymentOutput";
import { ReverseTransferInputJsonApiModel } from "./ReverseTransferInput";
import { ReverseTransferOutputJsonApiModel } from "./ReverseTransferOutput";
import { ReviewUserJsonApiModel } from "./ReviewUser";
import { RuleInputJsonApiModel } from "./RuleInput";
import { SecurityJsonApiModel } from "./Security";
import { SecurityRateUpdateJsonApiModel } from "./SecurityRateUpdate";
import { SendManagerAgreementDocumentInputJsonApiModel } from "./SendManagerAgreementDocumentInput";
import { SendManagerAgreementDocumentOutputJsonApiModel } from "./SendManagerAgreementDocumentOutput";
import { SetupFundBuilderChargesOutputJsonApiModel } from "./SetupFundBuilderChargesOutput";
import { StatementJsonApiModel } from "./Statement";
import { StatementSetJsonApiModel } from "./StatementSet";
import { StripeCustomerJsonApiModel } from "./StripeCustomer";
import { TagJsonApiModel } from "./Tag";
import { TokenJsonApiModel } from "./Token";
import { TrackRecordJsonApiModel } from "./TrackRecord";
import { TrackRecordSummaryJsonApiModel } from "./TrackRecordSummary";
import { TransactionJsonApiModel } from "./Transaction";
import { TransactionSetJsonApiModel } from "./TransactionSet";
import { TransferJsonApiModel } from "./Transfer";
import { UnitPriceUpdateJsonApiModel } from "./UnitPriceUpdate";
import { UpdateBillingEntityOutputJsonApiModel } from "./UpdateBillingEntityOutput";
import { UpdateBillingInvoiceInputJsonApiModel } from "./UpdateBillingInvoiceInput";
import { UpdateBillingInvoiceOutputJsonApiModel } from "./UpdateBillingInvoiceOutput";
import { UpdateBillingPriceOutputJsonApiModel } from "./UpdateBillingPriceOutput";
import { UpdateBillingProductOutputJsonApiModel } from "./UpdateBillingProductOutput";
import { UpdateBillingScheduleInputJsonApiModel } from "./UpdateBillingScheduleInput";
import { UpdateBillingScheduleOutputJsonApiModel } from "./UpdateBillingScheduleOutput";
import { UpdateBillingScheduleTierInputJsonApiModel } from "./UpdateBillingScheduleTierInput";
import { UpdateBillingScheduleTierOutputJsonApiModel } from "./UpdateBillingScheduleTierOutput";
import { UpdateEntityInputJsonApiModel } from "./UpdateEntityInput";
import { UpdateEntityOpportunityInputJsonApiModel } from "./UpdateEntityOpportunityInput";
import { UpdateEntityOpportunityOutputJsonApiModel } from "./UpdateEntityOpportunityOutput";
import { UpdateEntityOutputJsonApiModel } from "./UpdateEntityOutput";
import { UpdateFeeInputJsonApiModel } from "./UpdateFeeInput";
import { UpdateFeeInstanceInputJsonApiModel } from "./UpdateFeeInstanceInput";
import { UpdateFeeInstanceOutputJsonApiModel } from "./UpdateFeeInstanceOutput";
import { UpdateFeeOutputJsonApiModel } from "./UpdateFeeOutput";
import { UpdateOptionsJsonApiModel } from "./UpdateOptions";
import { UpdateTrackRecordOutputJsonApiModel } from "./UpdateTrackRecordOutput";
import { UpdateTrackRecordsOutputJsonApiModel } from "./UpdateTrackRecordsOutput";
import { UpdateVerificationOutputJsonApiModel } from "./UpdateVerificationOutput";
import { UpsertBillingScheduleTierInputJsonApiModel } from "./UpsertBillingScheduleTierInput";
import { UpsertBillingScheduleTierOutputJsonApiModel } from "./UpsertBillingScheduleTierOutput";
import { UserJsonApiModel } from "./User";
import { UserActivitiesByDateJsonApiModel } from "./UserActivitiesByDate";
import { UserSelectionsJsonApiModel } from "./UserSelections";
import { UsersAccountJsonApiModel } from "./UsersAccount";
import { UsersActivityJsonApiModel } from "./UsersActivity";
import { UsersEmailJsonApiModel } from "./UsersEmail";
import { UsersPhoneJsonApiModel } from "./UsersPhone";
import { UsersRoleJsonApiModel } from "./UsersRole";
import { UsersTagJsonApiModel } from "./UsersTag";
import { VerificationJsonApiModel } from "./Verification";
import { VoidBillingInvoiceInputJsonApiModel } from "./VoidBillingInvoiceInput";
import { WaterfallDefinitionJsonApiModel } from "./WaterfallDefinition";
import { WaterfallDefinitionRuleJsonApiModel } from "./WaterfallDefinitionRule";
import { WaterfallDefinitionRuleClassJsonApiModel } from "./WaterfallDefinitionRuleClass";
import { WaterfallDefinitionTemplateParamJsonApiModel } from "./WaterfallDefinitionTemplateParam";
import { WaterfallInstanceJsonApiModel } from "./WaterfallInstance";
import { WaterfallInstanceRuleJsonApiModel } from "./WaterfallInstanceRule";
import { WaterfallInstanceRulesSummaryJsonApiModel } from "./WaterfallInstanceRulesSummary";
import { WaterfallRuleDistributionHoldingJsonApiModel } from "./WaterfallRuleDistributionHolding";
import { WaterfallSideLetterJsonApiModel } from "./WaterfallSideLetter";
import { WorkflowJsonApiModel } from "./Workflow";
import { WorkflowInstanceJsonApiModel } from "./WorkflowInstance";
import { WorkflowJobJsonApiModel } from "./WorkflowJob";
import { WorkflowJobExecutionSummaryJsonApiModel } from "./WorkflowJobExecutionSummary";
import { WorkflowJobInstanceJsonApiModel } from "./WorkflowJobInstance";
import { WorkflowJobQueueJsonApiModel } from "./WorkflowJobQueue";
import { WorkflowJobWorkerJsonApiModel } from "./WorkflowJobWorker";
import { WorkflowLogJsonApiModel } from "./WorkflowLog";
import { CryptoAccountJsonApiModel } from "./CryptoAccount";
import { DocumentJsonApiModel } from "./Document";
import { DistributionHoldingsJsonApiModel } from "./DistributionHoldings";
import { HoldingCalcJsonApiModel } from "./HoldingCalc";
import { PlaidItemJsonApiModel } from "./PlaidItem";
import { PlaidInstitutionJsonApiModel } from "./PlaidInstitution";
import { PlaidAccountJsonApiModel } from "./PlaidAccount";
import { MediaItemJsonApiModel } from "./MediaItem";
import { ImportErrorsJsonApiModel } from "./ImportErrors";

export const jsonApiModels = {
  account: AccountJsonApiModel,
  address: AddressJsonApiModel,
  "admin-dashboard-search": AdminDashboardSearchJsonApiModel,
  agreement: AgreementJsonApiModel,
  allocation: AllocationJsonApiModel,
  "allocation-group": AllocationGroupJsonApiModel,
  "allocation-method": AllocationMethodJsonApiModel,
  asset: AssetJsonApiModel,
  "asset-key-tenant": AssetKeyTenantJsonApiModel,
  "asset-purchase": AssetPurchaseJsonApiModel,
  "asset-purchase-cost": AssetPurchaseCostJsonApiModel,
  "asset-purchase-debt": AssetPurchaseDebtJsonApiModel,
  "asset-type": AssetTypeJsonApiModel,
  attribute: AttributeJsonApiModel,
  attributeitem: AttributeItemJsonApiModel,
  "background-check": BackgroundCheckJsonApiModel,
  "background-check-item": BackgroundCheckItemJsonApiModel,
  "bank-account": BankAccountJsonApiModel,
  "banner-image": BannerImageJsonApiModel,
  "billing-entity": BillingEntityJsonApiModel,
  "billing-event": BillingEventJsonApiModel,
  "billing-invoice": BillingInvoiceJsonApiModel,
  "billing-price": BillingPriceJsonApiModel,
  "billing-product": BillingProductJsonApiModel,
  "billing-schedule": BillingScheduleJsonApiModel,
  "billing-schedule-tier": BillingScheduleTierJsonApiModel,
  "blue-sky-filing": BlueSkyFilingJsonApiModel,
  "calculate-billing-event-amount-output":
    CalculateBillingEventAmountOutputJsonApiModel,
  "calculate-billing-event-defaults-output":
    CalculateBillingEventDefaultsOutputJsonApiModel,
  "calculate-billing-schedule-charge-input":
    CalculateBillingScheduleChargeInputJsonApiModel,
  "calculate-billing-schedule-charge-output":
    CalculateBillingScheduleChargeOutputJsonApiModel,
  "calculate-transfer-input": CalculateTransferInputJsonApiModel,
  "calculate-transfer-output": CalculateTransferOutputJsonApiModel,
  chart: ChartJsonApiModel,
  chart: ChartNodeJsonApiModel,
  contribution: ContributionJsonApiModel,
  "create-billing-entity-output": CreateBillingEntityOutputJsonApiModel,
  "create-billing-price-output": CreateBillingPriceOutputJsonApiModel,
  "create-billing-product-output": CreateBillingProductOutputJsonApiModel,
  "create-billing-schedule-input": CreateBillingScheduleInputJsonApiModel,
  "create-billing-schedule-output": CreateBillingScheduleOutputJsonApiModel,
  "create-billing-schedule-tier-input":
    CreateBillingScheduleTierInputJsonApiModel,
  "create-billing-schedule-tier-output":
    CreateBillingScheduleTierOutputJsonApiModel,
  "create-blue-sky-filing-input": CreateBlueSkyFilingInputJsonApiModel,
  "create-blue-sky-filing-output": CreateBlueSkyFilingOutputJsonApiModel,
  "create-chart-input": CreateChartInputJsonApiModel,
  "create-chart-node-input": CreateChartNodeInputJsonApiModel,
  "create-chart-node-output": CreateChartNodeOutputJsonApiModel,
  "create-chart-output": CreateChartOutputJsonApiModel,
  "create-document-zip-job-output": CreateDocumentsZipJobOutputJsonApiModel,
  "create-entity-address-input": CreateEntityAddressInputJsonApiModel,
  "create-entity-adderess-output": CreateEntityAddressOutputJsonApiModel,
  "create-entity-input": CreateEntityInputJsonApiModel,
  "create-entity-key-personnel-input":
    CreateEntityKeyPersonnelInputJsonApiModel,
  "create-entity-key-personnel-output":
    CreateEntityKeyPersonnelOutputJsonApiModel,
  "create-entity-opportunity-input": CreateEntityOpportunityInputJsonApiModel,
  "create-entity-opportunity-output": CreateEntityOpportunityOutputJsonApiModel,
  "create-entity-output": CreateEntityOutputJsonApiModel,
  "create-fee-input": CreateFeeInputJsonApiModel,
  "create-fee-instance-input": CreateFeeInstanceInputJsonApiModel,
  "create-fee-instance-output": CreateFeeInstanceOutputJsonApiModel,
  "create-fee-output": CreateFeeOutputJsonApiModel,
  "create-fund-builder-organizational-chart-input":
    CreateFundBuilderOrganizationalChartInputJsonApiModel,
  "create-fund-builder-organizational-chart-output":
    CreateFundBuilderOrganizationalChartOutputJsonApiModel,
  "create-investment-request": CreateInvestmentInputJsonApiModel,
  "create-manager-individual-input": CreateManagerIndividualInputJsonApiModel,
  "create-manager-individual-output": CreateManagerIndividualOutputJsonApiModel,
  "create-manual-billing-event-input":
    CreateManualBillingEventInputJsonApiModel,
  "create-manual-billing-event-output":
    CreateManualBillingEventOutputJsonApiModel,
  "create-opportunity-affiliate-input":
    CreateOpportunityAffiliateInputJsonApiModel,
  "create-opportunity-affiliate-output":
    CreateOpportunityAffiliateOutputJsonApiModel,
  "create-opportunity-timeline-section-input":
    CreateOpportunityTimelineItemInputJsonApiModel,
  "create-opportunity-timeline-section-output":
    CreateOpportunityTimelineItemOutputJsonApiModel,
  "create-opportunity-timeline-section-input":
    CreateOpportunityTimelineSectionInputJsonApiModel,
  "create-opportunity-timeline-section-output":
    CreateOpportunityTimelineSectionOutputJsonApiModel,
  "create-organizational-chart-input":
    CreateOrganizationalChartInputJsonApiModel,
  "create-organizational-chart-output":
    CreateOrganizationalChartOutputJsonApiModel,
  "create-security-input": CreateSecurityInputJsonApiModel,
  "create-security-output": CreateSecurityOutputJsonApiModel,
  "create-transfer-input": CreateTransferInputJsonApiModel,
  "create-transfer-output": CreateTransferOutputJsonApiModel,
  custodian: CustodianJsonApiModel,
  custom_domain: CustomDomainJsonApiModel,
  "dashboard-search": DashboardSearchJsonApiModel,
  "delete-billing-entity-output": DeleteBillingEntityOutputJsonApiModel,
  "delete-billing-product-output": DeleteBillingProductOutputJsonApiModel,
  "delete-billing-schedule-input": DeleteBillingScheduleInputJsonApiModel,
  "delete-billing-schedule-output": DeleteBillingScheduleOutputJsonApiModel,
  "delete-entity-input": DeleteEntityInputJsonApiModel,
  "delete-entity-opportunity-input": DeleteEntityOpportunityInputJsonApiModel,
  "delete-entity-opportunity-output": DeleteEntityOpportunityOutputJsonApiModel,
  "delete-entity-output": DeleteEntityOutputJsonApiModel,
  "delete-fee-input": DeleteFeeInputJsonApiModel,
  "delete-fee-instance-input": DeleteFeeInstanceInputJsonApiModel,
  "delete-fee-instance-output": DeleteFeeInstanceOutputJsonApiModel,
  "delete-fee-output": DeleteFeeOutputJsonApiModel,
  "delete-fund-builder-output": DeleteFundBuilderOutputJsonApiModel,
  distribution: DistributionJsonApiModel,
  "distribution-detail": DistributionDetailJsonApiModel,
  "distribution-holding": DistributionHoldingJsonApiModel,
  "distribution-preference": DistributionPreferenceJsonApiModel,
  "distribution-set": DistributionSetJsonApiModel,
  "document-tag": DocumentsTagJsonApiModel,
  "docusign-setting": DocusignSettingJsonApiModel,
  email: EmailJsonApiModel,
  entity: EntityJsonApiModel,
  "entity-address": EntityAddressJsonApiModel,
  "entity-key-personnel": EntityKeyPersonnelJsonApiModel,
  "entity-opportunity": EntityOpportunityJsonApiModel,
  exchange: ExchangeJsonApiModel,
  fee: FeeJsonApiModel,
  "fee-instance": FeeInstanceJsonApiModel,
  "flattened-investment": FlattenedInvestmentJsonApiModel,
  "flattened-investment-item": FlattenedInvestmentItemJsonApiModel,
  "fund-builder": FundBuilderJsonApiModel,
  "fund-builder-charge": FundBuilderChargesJsonApiModel,
  "fund-builder-form": FundBuilderFormJsonApiModel,
  "gi-contribution": GIContributionJsonApiModel,
  "gi-generic": GIGenericJsonApiModel,
  "gi-ownership": GIOwnershipJsonApiModel,
  "generate-char-input": GenerateChartGraphInputJsonApiModel,
  "generate-char-output": GenerateChartGraphOutputJsonApiModel,
  "get-asset-type-input": GetAssetTypeInputJsonApiModel,
  "get-asset-type-output": GetAssetTypeOutputJsonApiModel,
  "get-asset-types-input": GetAssetTypesInputJsonApiModel,
  "get-asset-types-output": GetAssetTypesOutputJsonApiModel,
  "get-billing-entities-output": GetBillingEntitiesOutputJsonApiModel,
  "get-billing-entity-output": GetBillingEntityOutputJsonApiModel,
  "get-billing-invoice-output": GetBillingInvoiceOutputJsonApiModel,
  "get-billing-invoices-output": GetBillingInvoicesOutputJsonApiModel,
  "get-billing-price-output": GetBillingPriceOutputJsonApiModel,
  "get-billing-prices-output": GetBillingPricesOutputJsonApiModel,
  "get-billing-product-output": GetBillingProductOutputJsonApiModel,
  "get-billing-products-output": GetBillingProductsOutputJsonApiModel,
  "get-billing-schedule-output": GetBillingScheduleOutputJsonApiModel,
  "get-billing-schedule-tiers-output":
    GetBillingScheduleTiersOutputJsonApiModel,
  "get-chart-input": GetChartInputJsonApiModel,
  "get-chart-nodes-input": GetChartNodesInputJsonApiModel,
  "get-chart-nodes-output": GetChartNodesOutputJsonApiModel,
  "get-chart-output": GetChartOutputJsonApiModel,
  "get-entities-input": GetEntitiesInputJsonApiModel,
  "get-entities-output": GetEntitiesOutputJsonApiModel,
  "get-entity-input": GetEntityInputJsonApiModel,
  "get-entity-opportunities-input": GetEntityOpportunitiesInputJsonApiModel,
  "get-entity-opportunities-output": GetEntityOpportunitiesOutputJsonApiModel,
  "get-entity-opportunity-input": GetEntityOpportunityInputJsonApiModel,
  "get-entity-opportunity-output": GetEntityOpportunityOutputJsonApiModel,
  "get-entity-output": GetEntityOutputJsonApiModel,
  "get-fee-input": GetFeeInputJsonApiModel,
  "get-fee-instance-input": GetFeeInstanceInputJsonApiModel,
  "get-fee-instance-output": GetFeeInstanceOutputJsonApiModel,
  "get-fee-instances-input": GetFeeInstancesInputJsonApiModel,
  "get-fee-instances-output": GetFeeInstancesOutputJsonApiModel,
  "get-fee-output": GetFeeOutputJsonApiModel,
  "get-fees-input": GetFeesInputJsonApiModel,
  "get-fees-output": GetFeesOutputJsonApiModel,
  "get-holding-input": GetHoldingInputJsonApiModel,
  "get-holding-output": GetHoldingOutputJsonApiModel,
  "get-metropolitan-statistical-areas-input":
    GetMetropolitanStatisticalAreasInputJsonApiModel,
  "get-metropolitan-statistical-areas-output":
    GetMetropolitanStatisticalAreasOutputJsonApiModel,
  "get-notifications-output": GetNotificationsOutputJsonApiModel,
  "get-offering-exemption-input": GetOfferingExemptionInputJsonApiModel,
  "get-offering-exemption-output": GetOfferingExemptionOutputJsonApiModel,
  "get-offering-exemptions-input": GetOfferingExemptionsInputJsonApiModel,
  "get-offering-exemptions-output": GetOfferingExemptionsOutputJsonApiModel,
  "get-offerings-input": GetOfferingsInputJsonApiModel,
  "get-offerings-output": GetOfferingsOutputJsonApiModel,
  "get-securities-input": GetSecuritiesInputJsonApiModel,
  "get-securities-output": GetSecuritiesOutputJsonApiModel,
  "get-transfer-input": GetTransferInputJsonApiModel,
  "get-transfer-output": GetTransferOutputJsonApiModel,
  "get-transfers-input": GetTransfersInputJsonApiModel,
  "get-transfers-output": GetTransfersOutputJsonApiModel,
  "grouped-investment": GroupedInvestmentJsonApiModel,
  holding: HoldingJsonApiModel,
  "holding-rate-update": HoldingRateUpdateJsonApiModel,
  "holdings-activity": HoldingsActivityJsonApiModel,
  image: ImageJsonApiModel,
  import: ImportJsonApiModel,
  "import-account": ImportAccountJsonApiModel,
  "import-account-match-data": ImportAccountMatchDataJsonApiModel,
  "import-balance": ImportBalanceJsonApiModel,
  "import-distribution-preference": ImportDistributionPreferenceJsonApiModel,
  "import-log": ImportLogJsonApiModel,
  "import-position": ImportPositionJsonApiModel,
  "import-transaction": ImportTransactionJsonApiModel,
  "import-user": ImportUserJsonApiModel,
  importer: ImporterJsonApiModel,
  investment: InvestmentJsonApiModel,
  "investor-assist": InvestorAssistJsonApiModel,
  location: LocationJsonApiModel,
  login: LoginJsonApiModel,
  manager: ManagerJsonApiModel,
  "manager-card": ManagerCardJsonApiModel,
  "manager-contact": ManagerContactJsonApiModel,
  managercontactmessage: ManagerContactMessageJsonApiModel,
  "manager-employee": ManagerEmployeeJsonApiModel,
  "individual-signer": ManagerIndividualJsonApiModel,
  "manager-review": ManagerReviewJsonApiModel,
  "manager-white-label-subscription": ManagerWhiteLabelSubscriptionJsonApiModel,
  mediaitem: MediaItemsJsonApiModel,
  "metropolitan-statistical-area": MetropolitanStatisticalAreaJsonApiModel,
  minimuminvestment: MinimumInvestmentManagerJsonApiModel,
  monitoringreport: MonitoringReportJsonApiModel,
  monitoringreportitem: MonitoringReportItemJsonApiModel,
  "object-snapshot": ObjectSnapshotJsonApiModel,
  offering: OfferingJsonApiModel,
  "offering-exemption": OfferingExemptionJsonApiModel,
  opportunity: OpportunityJsonApiModel,
  "opportunity-affiliate": OpportunityAffiliateJsonApiModel,
  "opportunity-metric": OpportunityMetricJsonApiModel,
  "opportunity-point": OpportunityPointJsonApiModel,
  "opportunity-timeline-item": OpportunityTimelineItemJsonApiModel,
  "opportunity-timeline-section": OpportunityTimelineSectionJsonApiModel,
  portfolio: PortfolioJsonApiModel,
  "portfolio-manager": PortfolioManagerJsonApiModel,
  "preview-agreement-document-input": PreviewAgreementDocumentInputJsonApiModel,
  "preview-agreement-document-output":
    PreviewAgreementDocumentOutputJsonApiModel,
  principal: PrincipalJsonApiModel,
  "principal-history": PrincipalHistoryJsonApiModel,
  redemption: RedemptionJsonApiModel,
  report: ReportJsonApiModel,
  "request-principals-background-check-payment-output":
    RequestPrincipalsBackgroundCheckPaymentOutputJsonApiModel,
  "reverse-transfer-input": ReverseTransferInputJsonApiModel,
  "reverse-transfer-output": ReverseTransferOutputJsonApiModel,
  "review-user": ReviewUserJsonApiModel,
  "rule-input": RuleInputJsonApiModel,
  security: SecurityJsonApiModel,
  "security-rate-update": SecurityRateUpdateJsonApiModel,
  "send-manager-agreement-document-input":
    SendManagerAgreementDocumentInputJsonApiModel,
  "send-manager-agreement-document-output":
    SendManagerAgreementDocumentOutputJsonApiModel,
  "setup-fund-builder-charges-output":
    SetupFundBuilderChargesOutputJsonApiModel,
  statement: StatementJsonApiModel,
  "statement-set": StatementSetJsonApiModel,
  "stripe-customer": StripeCustomerJsonApiModel,
  tag: TagJsonApiModel,
  token: TokenJsonApiModel,
  "track-record": TrackRecordJsonApiModel,
  "track-records-summary": TrackRecordSummaryJsonApiModel,
  transaction: TransactionJsonApiModel,
  "transaction-set": TransactionSetJsonApiModel,
  transfer: TransferJsonApiModel,
  "unit-price-update": UnitPriceUpdateJsonApiModel,
  "update-billing-entity-output": UpdateBillingEntityOutputJsonApiModel,
  "update-billing-invoice-input": UpdateBillingInvoiceInputJsonApiModel,
  "update-billing-invoice-output": UpdateBillingInvoiceOutputJsonApiModel,
  "update-billing-price-output": UpdateBillingPriceOutputJsonApiModel,
  "update-billing-product-output": UpdateBillingProductOutputJsonApiModel,
  "update-billing-schedule-input": UpdateBillingScheduleInputJsonApiModel,
  "update-billing-schedule-output": UpdateBillingScheduleOutputJsonApiModel,
  "update-billing-schedule-tier-input":
    UpdateBillingScheduleTierInputJsonApiModel,
  "update-billing-schedule-tier-output":
    UpdateBillingScheduleTierOutputJsonApiModel,
  "update-entity-input": UpdateEntityInputJsonApiModel,
  "update-entity-opportunity-input": UpdateEntityOpportunityInputJsonApiModel,
  "update-entity-opportunity-output": UpdateEntityOpportunityOutputJsonApiModel,
  "update-entity-output": UpdateEntityOutputJsonApiModel,
  "update-fee-input": UpdateFeeInputJsonApiModel,
  "update-fee-instance-input": UpdateFeeInstanceInputJsonApiModel,
  "update-fee-instance-output": UpdateFeeInstanceOutputJsonApiModel,
  "update-fee-output": UpdateFeeOutputJsonApiModel,
  "update-option": UpdateOptionsJsonApiModel,
  "update-track-record-output": UpdateTrackRecordOutputJsonApiModel,
  "update-track-records-output": UpdateTrackRecordsOutputJsonApiModel,
  "update-verification-output": UpdateVerificationOutputJsonApiModel,
  "upsert-billing-schedule-tier-input":
    UpsertBillingScheduleTierInputJsonApiModel,
  "upsert-billing-schedule-tier-output":
    UpsertBillingScheduleTierOutputJsonApiModel,
  user: UserJsonApiModel,
  "user-activities-by-date": UserActivitiesByDateJsonApiModel,
  userselection: UserSelectionsJsonApiModel,
  "users-account": UsersAccountJsonApiModel,
  "users-activity": UsersActivityJsonApiModel,
  "users-email": UsersEmailJsonApiModel,
  "users-phone": UsersPhoneJsonApiModel,
  usersrole: UsersRoleJsonApiModel,
  "users-tag": UsersTagJsonApiModel,
  verification: VerificationJsonApiModel,
  "void-billing-invoice-input": VoidBillingInvoiceInputJsonApiModel,
  "waterfall-definition": WaterfallDefinitionJsonApiModel,
  "waterfall-definition-rule": WaterfallDefinitionRuleJsonApiModel,
  "waterfall-definition-rule-class": WaterfallDefinitionRuleClassJsonApiModel,
  "watefall-definition-template-param":
    WaterfallDefinitionTemplateParamJsonApiModel,
  "waterfall-instance": WaterfallInstanceJsonApiModel,
  "waterfall-instance-rule": WaterfallInstanceRuleJsonApiModel,
  "waterfall-instance-rules-summary": WaterfallInstanceRulesSummaryJsonApiModel,
  "waterfall-rule-distribution-holding":
    WaterfallRuleDistributionHoldingJsonApiModel,
  "waterfall-side-letter": WaterfallSideLetterJsonApiModel,
  workflow: WorkflowJsonApiModel,
  "workflow-instance": WorkflowInstanceJsonApiModel,
  "workflow-job": WorkflowJobJsonApiModel,
  "workflow-job-execution-summary": WorkflowJobExecutionSummaryJsonApiModel,
  "workflow-job-instance": WorkflowJobInstanceJsonApiModel,
  "workflow-job-queue": WorkflowJobQueueJsonApiModel,
  "workflow-job-worker": WorkflowJobWorkerJsonApiModel,
  "workflow-log": WorkflowLogJsonApiModel,
  "crypto-account": CryptoAccountJsonApiModel,
  document: DocumentJsonApiModel,
  "distribution-holding": DistributionHoldingsJsonApiModel,
  "holding-calc": HoldingCalcJsonApiModel,
  "plaid-item": PlaidItemJsonApiModel,
  "plaid-institution": PlaidInstitutionJsonApiModel,
  "plaid-account": PlaidAccountJsonApiModel,
  "media-item": MediaItemJsonApiModel,
  Error: ImportErrorsJsonApiModel,
};
