import React from "react";

import { Box } from "@chakra-ui/react";
import { CustomHeading } from "../../common";
import { CardProps } from "./Card.types";

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      title,
      action,
      actions,
      actionName,
      isGhost,
      extra,
      actionComponent,
      headingProps,
      ...rest
    },
    ref,
  ) => {
    return (
      <Box
        borderRadius={isGhost ? 0 : "4px"}
        border={isGhost ? "none" : "1px solid"}
        borderColor={isGhost ? "" : "border.grey"}
        boxShadow={!isGhost ? 1 : undefined}
        background={isGhost ? "transparent" : "#fff"}
        ref={ref}
        p={isGhost ? 0 : 6}
        {...rest}
      >
        {title && (
          <CustomHeading
            textStyle={"h5"}
            action={action}
            actionName={actionName}
            actions={actions}
            actionProps={{
              textProps: { textStyle: "bodyRegularBold" },
            }}
            divider
            extra={extra}
            actionComponent={actionComponent}
            {...headingProps}
          >
            {title}
          </CustomHeading>
        )}
        <Box>{children}</Box>
      </Box>
    );
  },
);

Card.displayName = "Card";

export default Card;
