/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const HoldingCalcJsonApiModel = {
  id: "",
  "capital-account-balance": "",
  "market-value": "",
  status: "",
  holding: {
    jsonApi: "hasOne",
    type: "holdings",
  },
  rate: "",
  "net-capital-contributions": "",
  units: "",
  "unit-price": "",
  "paid-to-date": "",
  "as-of-date": "",
  "unit-price-date": "",
};
