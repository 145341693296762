import { useDynamicConfig } from "../dynamic-config";
import DynamicConfigFlags from "../dynamic-config/configs";
import { UseGetFeatureToggleProps } from "./FeatureToggleTypes";

export const useGetFeatureToggles = () => {
  const dynamicConfig = useDynamicConfig();
  const availableFeatures = dynamicConfig.get(DynamicConfigFlags.FEATURE_FLAGS);

  const getIsFeatureAvailable = ({
    featureName,
    featureValue = true,
  }: UseGetFeatureToggleProps) => {
    if (!availableFeatures) {
      return false;
    }

    return availableFeatures[featureName] === featureValue;
  };

  return getIsFeatureAvailable;
};
