import { useCallback, useState } from "react";

export interface BooleanState {
  state: boolean;
  handleSetAsTrue: () => void;
  handleSetAsFalse: () => void;
  true: () => void;
  false: () => void;
  toggle: () => void;
}

const useBooleanState: (initialState?: boolean) => BooleanState = (
  initialState = false,
) => {
  const [state, setState] = useState(initialState);

  const handleSetAsTrue = useCallback(() => {
    setState(true);
  }, []);

  const handleSetAsFalse = useCallback(() => {
    setState(false);
  }, []);

  const toggle = useCallback(() => {
    setState((value) => !value);
  }, []);

  return {
    state,
    handleSetAsTrue,
    handleSetAsFalse,
    true: handleSetAsTrue,
    false: handleSetAsFalse,
    toggle,
  };
};

export default useBooleanState;
