/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ArrowUpIcon = createIcon({
  displayName: "ArrowUpIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12.707 1.293L20.414 9L19 10.414L13 4.414V22C13 22.553 12.552 23 12 23C11.448 23 11 22.553 11 22V4.414L5 10.414L3.586 9L11.293 1.293C11.684 0.901998 12.316 0.901998 12.707 1.293Z" fill="currentColor" /></g>)
});
export default ArrowUpIcon;