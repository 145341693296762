/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AllocationGroupJsonApiModel = {
  id: "",
  name: "",
  "allocation-methods": {
    jsonApi: "hasMany",
    type: "allocation-methods",
  },
  security: {
    jsonApi: "hasOne",
    type: "securities",
  },
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  "effective-date": "",
  "effective-date-str": "",
  "distribution-set": {
    jsonApi: "hasOne",
    type: "distribution-sets",
  },
  "group-total": "",
  status: "",
};
