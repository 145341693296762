import React from "react";
import { AutoCompleteProps } from "./AutoComplete.types";
import { VeriDropDown } from "../VeriDropDown";

const AutoComplete: React.FC<AutoCompleteProps> = ({
  onChange,
  onSelectChange,
  ...rest
}) => {
  return (
    <VeriDropDown
      {...rest}
      onFilterChange={(filter) => {
        onChange && onChange(filter);
      }}
      onChange={onSelectChange}
      retainFilterValue
      isSimpleSelect
      hideCaret
    />
  );
};

export default AutoComplete;
