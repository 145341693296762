import {
  identifyLoggedInUserForLogrocket,
  initializeLogRocket,
  ProfileForLogrocket,
} from "@verivest/logrocket/src";

const isLogrocketInitialized = initializeLogRocket({
  logrocketAppId: process.env.RAZZLE_APP_LOGROCKET_APP_ID,
});

export const identifyUserForLogRocket = (profile: ProfileForLogrocket) => {
  if (isLogrocketInitialized) {
    identifyLoggedInUserForLogrocket(profile);
  }
};
