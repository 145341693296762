import { MultiSelect, TagsInput } from "./filters-type";
import TextFilter from "./filters-type/Text/TextFilter";

export { default as TagsDrawer } from "./TagsDrawer";
export type { TagsDrawerProps } from "./TagsDrawer.types";

export const FilterTypes = {
  MultiSelect,
  TagsInput,
  Text: TextFilter,
};
