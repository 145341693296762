import { Box } from "@chakra-ui/react";
import React from "react";
import { DropDownOptionElementProps } from "./DropDownOptionElement.types";

const DropDownOptionElement: React.FC<DropDownOptionElementProps> = ({
  children,
  value,
  label,
  index,
  selectedElement,
  setSelectedElement,
  onSelect,
  disabled,
  wrapperOptions,
  ...rest
}) => {
  return (
    <Box
      py={4}
      px={4}
      backgroundColor={selectedElement === index ? "bg.grey" : "bg.white"}
      _hover={!disabled ? { cursor: "pointer" } : undefined}
      onMouseEnter={() => setSelectedElement?.(index)}
      onClick={() => {
        if (disabled) return;
        onSelect && onSelect(value, label);
      }}
      {...rest}
      {...wrapperOptions}
    >
      {children}
    </Box>
  );
};

export default React.memo(DropDownOptionElement);
