/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DistributionPreferenceJsonApiModel = {
  id: "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "distribution-method": "",
  "address-type": "",
  "distribution-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "bank-account": {
    jsonApi: "hasOne",
    type: "bank-accounts",
  },
  "bank-account2": {
    jsonApi: "hasOne",
    type: "bank-accounts",
  },
  "method-type": "",
  method1: "",
  "method1-value": "",
  method2: "",
  "method2-value": "",
};
