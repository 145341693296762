/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const PlaidAccountJsonApiModel = {
  id: "",
  "plaid-item": {
    jsonApi: "hasOne",
    type: "plaid-items",
  },
  "plaid-account-id": "",
  name: "",
  mask: "",
  "official-name": "",
  "current-balance": "",
  "available-balance": "",
  "iso-currency-code": "",
  type: "",
  "sub-type": "",
};
