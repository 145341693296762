/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UsersTagJsonApiModel = {
  id: "",
  "user-id": "",
  "tag-id": "",
  tag: {
    jsonApi: "hasOne",
    type: "tags",
  },
  "created-at": "",
  "updated-at": "",
};
