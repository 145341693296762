/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum AssetPurchaseStrategyEnumType {
  Build = "Build",
  Convert = "Convert",
  Hold = "Hold",
  Improve = "Improve",
}
