import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useDropzone } from "react-dropzone";
import { getMimeTypesByType } from "../../utils/utils";
import { UploadIcon } from "./Icons";
import { Button, FileIcon } from "./index";

const FileRow = ({ fileName, onRemove, ...rest }) => {
  return (
    <Flex
      borderRadius={4}
      borderWidth={1}
      borderStyle={"solid"}
      borderColor={"border.input"}
      alignItems={"center"}
      py={3}
      px={4}
      position={"relative"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      {...rest}
    >
      <FileIcon mr={4} fileName={fileName} />
      <Box textAlign={"left"} color={"brand.main"}>
        {fileName}
      </Box>
      {onRemove && (
        <Box position={"absolute"} right={"16px"} top={"12px"}>
          <Button onClick={onRemove} empty>
            <CloseIcon color={"text.black"} />
          </Button>
        </Box>
      )}
    </Flex>
  );
};

const VeriDropZone = ({
  multiple = false,
  accept: _accept,
  onChange,
  isSmall = false,
  description,
  showActionButton,
  actionName = "Choose",
  previewImage = false,
  dropTypeText,
  browseText,
  enableFileList = true,
  previewBlolb = null,
  previewValue,
  acceptPreset,
  hideUploadIconOnPreview = false,
  previewProps = null,
  ...rest
}) => {
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [imagePreview, setImagePreview] = React.useState(previewValue || []);

  const accept = Array.isArray(_accept)
    ? [..._accept, ...getMimeTypesByType(acceptPreset)]
    : getMimeTypesByType(acceptPreset);

  const _setSelectedFiles = (selection) => {
    setSelectedFiles(selection);

    if (!multiple && previewImage) {
      setImagePreview(
        selection.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    }

    onChange && onChange(selection);
  };

  const removeSelectedFile = (index) => {
    _setSelectedFiles(selectedFiles.splice(index, 0));
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    _setSelectedFiles(acceptedFiles);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  const hasPreviewImage =
    !multiple && (previewBlolb || (previewImage && imagePreview.length > 0));

  return (
    <Box>
      <Flex
        cursor="pointer"
        bg="bg.grey"
        w={"100%"}
        justify={!isSmall ? "center" : "flex-start"}
        align={!isSmall ? "center" : "flex-start"}
        borderRadius={4}
        border="1px dashed"
        borderColor="border.input"
        textAlign="center"
        direction={"column"}
        {...rest}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box p={!isSmall ? 8 : 6} w={showActionButton && "100%"}>
          {!isSmall && (!hideUploadIconOnPreview || !hasPreviewImage) && (
            <Icon as={UploadIcon} boxSize="40px" />
          )}
          <Flex
            w={showActionButton && "100%"}
            flexDirection={isSmall ? "row" : "column"}
            alignItems={"center"}
          >
            {hasPreviewImage ? (
              <Box flexGrow={1}>
                <Box w={"100%"} maxW={"300px"} overflow={"hidden"}>
                  <Image
                    src={
                      previewBlolb
                        ? URL.createObjectURL(previewBlolb)
                        : imagePreview[0].preview
                    }
                    {...previewProps}
                  />
                </Box>
              </Box>
            ) : (
              <Text
                mt={!isSmall ? 4 : 0}
                flexGrow={1}
                textAlign={showActionButton ? "left" : "center"}
              >
                {description || (
                  <>
                    Drop your {dropTypeText || "file(s)"} here or{" "}
                    <Text
                      as={"span"}
                      color="brand.main"
                      textStyle="bodyRegular"
                    >
                      {browseText || "browse your computer"}
                    </Text>
                  </>
                )}
              </Text>
            )}

            {showActionButton && (
              <Box>
                <Button
                  backgroundColor={"transparent"}
                  isBold
                  label={actionName}
                  outline
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
      {enableFileList && selectedFiles.length > 0 && (
        <Box mb={8} mt={2} w={"100%"}>
          {selectedFiles.map((file, fileIndex) => (
            <FileRow
              key={fileIndex}
              w={"100%"}
              fileName={file.name}
              fileIndex={fileIndex}
              onRemove={(index) => removeSelectedFile(index)}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default VeriDropZone;
