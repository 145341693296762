import { Box, Flex, FormControl, FormLabel, Stack } from "@chakra-ui/react";
import dasherize from "dasherize";
import React from "react";
import { useTagModel } from "../../../api/hooks/models";
import usePlacementsApi from "../../../api/hooks/usePlacementsApi";
import { stringToPlural } from "../../../utils";
import { Button, Chip, OutlinedColoredTag } from "../../common";
import { AutoComplete, TagsInput } from "../../form-controls";
import { VeriDrawer } from "../VeriDrawer";
import { TagsDrawerProps } from "./TagsDrawer.types";

const TagsDrawer: React.FC<TagsDrawerProps> = ({
  onClose,
  managerId,
  isRemove,
  tagType,
  onSubmitRemove,
  onSubmitAdd,
  title,
  isOpen,
  isSingleTag,
  isFreeInput,
  helperText,
  label,
  tags: tagsProp,
  allowCreate = true,
  drawerVariant = "form",
  appliedTags,
  mutateTags,
  autoGenerateColor,
}) => {
  const placements = usePlacementsApi();

  const tagsModel = useTagModel({
    queryParams: { manager_id: managerId, tag_type: tagType },
    useSwrMany: !!tagType,
  });

  const { records: tagsData } = tagsModel;

  const tags: any[] = tagType
    ? (tagsData || []).map((tag) => ({
        value: tag.id || "",
        label: (tag as { name: string }).name || "", //TODO fix this type
        color: tag.color,
      }))
    : tagsProp || [];

  const [tagsToAdd, setTagsToAdd] = React.useState<string[]>(appliedTags || []);
  const [isLoading, setIsLoading] = React.useState(false);
  const [freeTag, setFreeTag] = React.useState("");

  const onAddTag = (tag: any) => {
    if (isFreeInput) {
      setFreeTag(tag.label);
      return;
    }

    if (isSingleTag) {
      setTagsToAdd([tag.value]);
      return;
    }

    if (!tagsToAdd.includes(String(tag.value))) {
      const _newTags = [...tagsToAdd];
      _newTags.push(String(tag.value));
      setTagsToAdd(_newTags);
    }
  };

  const onRemoveTag = (tagValue: any) => {
    if (isSingleTag) {
      setTagsToAdd([]);
      return;
    }

    setTagsToAdd([...tagsToAdd].filter((tag) => tag !== tagValue));
  };

  const onSave = async () => {
    setIsLoading(true);

    if (isRemove) {
      if (isFreeInput) {
        await onSubmitRemove?.(freeTag);
      } else {
        await onSubmitRemove?.(tagsToAdd.map((tag) => parseInt(tag)));
      }
    } else {
      if (isFreeInput) {
        if (freeTag) {
          await onSubmitAdd?.(freeTag);
        }
      } else {
        if (
          (!allowCreate || (tagsToAdd && tagsToAdd.length > 0)) &&
          onSubmitAdd
        ) {
          await onSubmitAdd(
            tagsToAdd.map((tag) => tags.find((t: any) => t.value === tag)),
          );
          return;
        }
      }
    }

    setIsLoading(false);
  };

  if (!tagType && !tagsProp) {
    return <></>;
  }

  const ChipComponent = autoGenerateColor ? OutlinedColoredTag : Chip;

  return (
    <VeriDrawer
      variant={drawerVariant}
      title={
        title ||
        (isRemove
          ? `Remove ${stringToPlural(tagType || label || "", 1)} Tags`
          : `Add ${stringToPlural(tagType || label || "", 1)} Tags`)
      }
      size={"lg"}
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      drawerFooterProps={{ boxShadow: "0px -1px 0px #DCE1E7" }}
      primaryButton={() => (
        <Flex w={"100%"}>
          <Button
            w="100%"
            onClick={onSave}
            mr={4}
            isLoading={isLoading}
            isLarge
          >
            Save
          </Button>
          <Button w="100%" onClick={onClose} isLarge outline>
            Cancel
          </Button>
        </Flex>
      )}
    >
      <Stack spacing={8} mt={6}>
        {!isFreeInput && (
          <TagsInput
            tags={tags}
            label={"TAG"}
            onTagCreation={
              allowCreate
                ? async (value, tagColor) => {
                    const newTag: any = await placements.apiRequest(
                      "/tags",
                      "POST",
                      undefined,
                      placements.serializeResource(
                        "tag",
                        dasherize({
                          manager: { id: managerId },
                          tagType,
                          name: value,
                          ...(autoGenerateColor && tagColor
                            ? { color: tagColor }
                            : {}),
                        }),
                      ),
                    );

                    await mutateTags?.();

                    const newTagId = newTag?.data?.id;
                    const newTagName = newTag?.data?.name;

                    await tagsModel.swrMany.mutate();

                    if (!newTagId) return;

                    setTagsToAdd((tags) => [...tags, newTagId]);

                    return { value: newTagId, name: newTagName };
                  }
                : undefined
            }
            // onAdd={(value) => {
            //   tagsToAdd.push(parseInt(value));
            // }}
            onChange={(tags) => {
              setTagsToAdd(tags);
            }}
            value={tagsToAdd}
            allowTagCreation={allowCreate && !isRemove}
            tagsInInput
            autoGenerateColor={autoGenerateColor}
          />
        )}

        {allowCreate && isFreeInput && (
          <AutoComplete
            label={(label || "") + " Tag"}
            helperText={helperText}
            options={tags}
            value={freeTag}
            onChange={setFreeTag}
          />
        )}

        {tags?.length > 0 && (
          <FormControl>
            <Flex justifyContent="space-between">
              <FormLabel lineHeight="24px" mb="8px" fontWeight="500">
                EXISTING TAGS
              </FormLabel>
            </Flex>
            <Flex flexWrap={"wrap"}>
              <Box>
                {tags.map((tag, tagIndex) => {
                  const isSelected = isFreeInput
                    ? freeTag === tag.label
                    : tagsToAdd.includes(String(tag.value));

                  return (
                    <ChipComponent
                      key={tagIndex}
                      isFilled
                      isSmall
                      label={tag.label}
                      mr={2}
                      mt={2}
                      tagColor={tag.color}
                      {...(autoGenerateColor
                        ? undefined
                        : {
                            backgroundColor: isSelected ? "#e9edf0" : "#cfd5dc",
                          })}
                      _hover={
                        !isSelected
                          ? {
                              cursor: "pointer",
                            }
                          : undefined
                      }
                      onClickClose={
                        isSelected ? () => onRemoveTag(tag.value) : undefined
                      }
                      onClick={() => onAddTag(tag)}
                    />
                  );
                })}
              </Box>
            </Flex>
          </FormControl>
        )}
      </Stack>
    </VeriDrawer>
  );
};

export default TagsDrawer;
