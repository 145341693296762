/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const SwapHorizontalIcon = createIcon({
  displayName: "SwapHorizontalIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <path
        d="M7 17H22"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M18 21L22 17L18 13"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M18 7H2"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M6 11L2 7L6 3"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </g>
  ),
});
export default SwapHorizontalIcon;
