import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { VeriFormLabel } from "../VeriFormLabel/VeriFormLabel";
import { FormControlWrapperProps } from "./FormControlWrapper.types";

const FormControlWrapper = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<FormControlWrapperProps>
>(
  (
    {
      label,
      labelExtra,
      isOptional,
      error,
      success,
      helperText,
      labelProps,
      name,
      children,
      inputLeft,
      inputRight,
      inputLeftProps,
      inputRightProps,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl isInvalid={!!error} {...rest} ref={ref}>
        {label && (
          <VeriFormLabel
            isOptional={isOptional}
            labelExtra={labelExtra}
            {...labelProps}
          >
            {label}
          </VeriFormLabel>
        )}
        <InputGroup>
          {inputLeft && (
            <InputLeftElement pt={2} {...inputLeftProps}>
              {inputLeft}
            </InputLeftElement>
          )}
          {children}
          {inputRight && (
            <InputRightElement pt={2} {...inputRightProps}>
              {inputRight}
            </InputRightElement>
          )}
        </InputGroup>
        {helperText && !error && !success && (
          <FormHelperText
            id={`${name || ""}-helper-text`}
            data-testid="input-helper-text"
          >
            {helperText}
          </FormHelperText>
        )}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        {success && (
          <Box mt={2} textStyle={"bodySmall"} color={"semantic.success"}>
            {success}
          </Box>
        )}
      </FormControl>
    );
  },
);

FormControlWrapper.displayName = "FormControlWrapper";

export default FormControlWrapper;
