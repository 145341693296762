import { Icon } from "@chakra-ui/icons";
import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { AvatarImage } from "../../../common";
import { ActivityItemProps } from "./ActivityItem.types";

const ActivityItem: React.FC<ActivityItemProps> = ({
  children,
  author,
  time,
  authorPicture,
  showAuthName = true,
  icon,
  color,
  bg,
}) => {
  return (
    <Flex mb={8} alignItems={"flex-start"}>
      <Flex alignItems={"center"} justifyContent={"center"} width={"40px"}>
        {icon ? (
          <Flex
            borderRadius={"100%"}
            width={"40px"}
            height={"40px"}
            background={"bg.white"}
            /*border={"1px solid"}
            borderColor={"border.grey"}*/
            alignItems={"center"}
            justifyContent={"center"}
            color={color || undefined}
            bg={bg || undefined}
          >
            <Icon as={icon} boxSize={"20px"} color={color || undefined} />
          </Flex>
        ) : author ? (
          <AvatarImage
            isUser
            name={author}
            width={"40px"}
            height={"40px"}
            fontSize={"18px"}
            image={authorPicture}
          />
        ) : (
          <Box
            borderRadius={"100%"}
            width={"20px"}
            height={"20px"}
            background={"border.grey"}
          />
        )}
      </Flex>
      <Box data-private w={"calc(100% - 40px)"} pt={"0"} pl={4}>
        {showAuthName && (author || children || time) && (
          <Flex alignItems={"center"} justifyContent={"space-between"} mb={1}>
            <Box
              display="inline-block"
              textStyle={"bodyRegularBold"}
              color="text.black"
              mr={2}
              mt={icon && !authorPicture ? 2.5 : 0}
            >
              {author || children}
            </Box>
            {time && (
              <Box
                display="inline-block"
                textStyle={"bodyRegular"}
                color="text.grey"
                whiteSpace={"nowrap"}
              >
                {time}
              </Box>
            )}
          </Flex>
        )}
        {!showAuthName || author ? children : undefined}
      </Box>
    </Flex>
  );
};

export default ActivityItem;
