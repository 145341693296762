import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios";
import dasherize from "dasherize";
import JsonApi from "devour-client";
import { saveAs } from "file-saver";
import humps from "humps";
import qs from "qs";
import Auth from "../auth/Auth";
import { Dict } from "../defines";
import DynamicConfigFlags from "../dynamic-config/configs";
import DynamicConfig from "../dynamic-config/DynamicConfig";
import { logError } from "../error-handling/logError";
import { isClient } from "../utils";
import { defineJsonApiModels } from "./defineJsonApiModels";
import {
  clearJsonApiClientCache,
  deserializeJsonApiObj,
  serializeJsonApiObj,
  serializeWithIncluded as sharedSerializedWithIncluded,
} from "./jsonApiSerializers";
import { JsonAPiUpdateResponse, RPCRequestResponse } from "./types";

//TODO: Need better type conversion

export const placementsApiExternal = process.env.RAZZLE_APP_PLACEMENTS_API;
export const placementsApiInternal =
  !process.env.RAZZLE_APP_PLACEMENTS_API_INTERNAL ||
  process.env.RAZZLE_APP_PLACEMENTS_API_INTERNAL === ""
    ? placementsApiExternal
    : process.env.RAZZLE_APP_PLACEMENTS_API_INTERNAL;

export const legacyApiExternal = process.env.RAZZLE_APP_LEGACY_API;
export const legacyApiInternal =
  !process.env.RAZZLE_APP_LEGACY_API_INTERNAL ||
  process.env.RAZZLE_APP_LEGACY_API_INTERNAL === ""
    ? legacyApiExternal
    : process.env.RAZZLE_APP_LEGACY_API_INTERNAL;

export const apiExternalUrl = process.env.RAZZLE_APP_API;
export const apiInternalUrl =
  !process.env.RAZZLE_APP_API_INTERNAL ||
  process.env.RAZZLE_APP_API_INTERNAL === ""
    ? apiExternalUrl
    : process.env.RAZZLE_APP_API_INTERNAL;

/*
  External URLs are the ones publicly accessible,
  i.e., what's typed in the browser to access a service.
  This URL should be used for user-accessible services,
  like redirecting to another web page.

  Internal URLs are the ones privately accessible,
  i.e., the address of the application servers in
  the local network of the deployment environment.
  This URL should be used for server-to-server
  communication, like accessing an internal API.
*/

const url: Dict = {
  apiExternal: apiExternalUrl,
  apiInternal: apiInternalUrl,
  placementsExternal: placementsApiExternal,
  placementsInternal: placementsApiInternal,
  legacyExternal: legacyApiExternal,
  legacyInternal: legacyApiInternal,
};

export class ApiClient {
  apiType: string;
  jsonApi: DevourClient;
  axiosApi: AxiosInstance;
  authAxios?: AxiosInstance;
  placementsApi?: string;
  auth: Auth;
  token?: string;
  dynamicConfig: DynamicConfig | undefined;

  constructor(auth: Auth, type = "apiExternal") {
    this.apiType = type;
    if (type === "apiExternal" || type === "apiInternal") {
      auth.setApiAxiosRequest(this.apiAxiosRequest);
    }

    this.authAxios = axios.create({
      baseURL: url[this.apiType],
    });

    this.placementsApi = type.includes("External")
      ? placementsApiExternal
      : placementsApiInternal;

    this.jsonApi = new JsonApi({ apiUrl: url[this.apiType] });
    this.axiosApi = axios.create({
      baseURL: url[this.apiType],
    });

    this.auth = auth;
    this.setToken(auth.getToken() || "");
    this.setupJsonAPi();
  }

  setDynamicConfig(dynamicConfig: DynamicConfig) {
    this.dynamicConfig = dynamicConfig;
  }

  getToken() {
    return this.auth.getToken();
  }
  setToken(token: string) {
    this.token = token;
    if (token) {
      this.jsonApi.headers["Authorization"] = "Bearer " + token;
    } else {
      delete this.jsonApi.headers["Authorization"];
    }
  }

  setAxiosHeader() {
    // set the json-api (axios) token for future authenticated requests
    this.axiosApi.defaults.headers.common["Authorization"] =
      "Bearer " + this.token;
  }

  setupJsonAPi() {
    const errorsMiddleware: DevourClientMiddleware = {
      name: "error",
      error: (payload) => {
        // check for expired api requests
        logError("payload:", payload);
        if (
          payload.response &&
          payload.response.status === 401 &&
          payload.response.statusText === "Unauthorized"
        ) {
          if (
            !this.token ||
            (this.token && !this.auth.isTokenExpired(this.token, true))
          ) {
            this.auth.unauthorized();
          }
        }

        // format errors
        const errors =
          payload.response &&
          payload.response.data &&
          payload.response.data.errors;

        return { errors };
      },
    };

    const paramsSerializerMiddleware: DevourClientMiddleware = {
      name: "params-serializer",
      req: async (payload) => {
        if (payload.req.method === "GET") {
          payload.req.paramsSerializer = function (params: Dict) {
            return qs.stringify(params, {
              arrayFormat: "brackets",
              encode: true,
            });
          };
        }

        return payload;
      },
    };

    /**
     * Transform incoming values to camelCase
     */
    const humperMiddlware: DevourClientMiddleware = {
      name: "humper",
      res: (payload) => {
        return humps.camelizeKeys(payload);
      },
    };

    this.jsonApi.insertMiddlewareBefore(
      "axios-request",
      paramsSerializerMiddleware,
    );
    this.jsonApi.insertMiddlewareAfter("response", humperMiddlware);
    this.jsonApi.replaceMiddleware("errors", errorsMiddleware);

    defineJsonApiModels(this.jsonApi);
  }

  serializeResource(model: string, resource: Dict) {
    return serializeJsonApiObj(this.jsonApi, model, resource);
  }

  serializeWithIncluded(
    modelName: string,
    modelAttributes: Record<string, unknown>,
  ) {
    const dasherizedModel = dasherize(modelAttributes);
    return sharedSerializedWithIncluded(
      this.jsonApi,
      modelName,
      dasherizedModel,
    );
  }

  transformAxios(response: any) {
    const data = response.data;

    const deserializedData = deserializeJsonApiObj<Dict>(this.jsonApi, data);

    return { ...response.data, data: deserializedData };
  }

  async createPlacementsResource(type: string, resource: any) {
    return await new Promise((resolve, reject) => {
      this.jsonApi
        .create(type, resource)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
          if (response.errors) {
            reject(response.errors);
          }
        })
        .catch((response) => {
          reject(response.errors);
        });
    });
  }

  async createResource<T>(
    type: string,
    resource: T,
    params: Dict = {},
  ): Promise<T> {
    return await new Promise((resolve, reject) => {
      this.jsonApi
        .create(type, resource, params)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
          if (response.errors) {
            reject(response.errors);
          }
        })
        .catch((response) => {
          reject(response.errors);
        });
    });
  }

  async updateResource<T>(
    type: string,
    resource: any,
    params: Dict = {},
    meta: any = {},
  ): Promise<JsonAPiUpdateResponse<T>> {
    if (this.token) {
      this.jsonApi.headers["Authorization"] = "Bearer " + this.token;
    }
    return await new Promise((resolve, reject) => {
      this.jsonApi
        .update(type, resource, params, meta)
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          }
          if (response.errors) {
            reject(response.errors);
          }
        })
        .catch((response) => {
          reject(response.errors);
        });
    });
  }

  apiRequest = <T>(
    path: string,
    method = "GET",
    queryParams?: Dict,
    resource?: any,
  ): Promise<T> => {
    if (this.token) {
      this.jsonApi.headers["Authorization"] = "Bearer " + this.token;
    }
    if (!isClient() && this.dynamicConfig) {
      const origin = this.dynamicConfig.get(DynamicConfigFlags.BASE_URL);
      if (origin) {
        this.jsonApi.headers["Origin"] = origin;
      }
    }

    clearJsonApiClientCache(this.jsonApi);

    return new Promise((resolve, reject) => {
      this.jsonApi
        .request(`${url[this.apiType]}${path}`, method, queryParams, resource)
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  };

  async post<T>(path: string, data: any, transform = false): Promise<T> {
    const config: Dict = {
      method: "post",
      url: `${url[this.apiType]}${path}`,
      data,
      validateStatus: function (status: number) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };
    if (this.token)
      config.headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

    const response = await this.authAxios?.(config);

    if (response?.data.error) {
      throw response.data;
    } else if (response?.data.errors) {
      throw response.data;
    } else if (response?.status !== 200 && response?.status !== 201) {
      throw response?.data;
    }

    if (transform) {
      return this.transformAxios(response);
    }

    return response as never as T;
  }

  async rawGet<T>(path: string): Promise<T> {
    const config: Dict = {
      method: "GET",
      url: `${url[this.apiType]}${path}`,
      validateStatus: function (status: number) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };
    if (this.token)
      config.headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

    const response = await this.authAxios?.(config);
    return response?.data as T;
  }

  async patch(path: string, data: any, transform = false) {
    const config: Dict = {
      method: "patch",
      url: `${url[this.apiType]}${path}`,
      data,
      validateStatus: function (status: number) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };
    if (this.token)
      config.headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

    const response = await this.authAxios?.(config);

    if (response?.status !== 200) {
      throw response?.data || "Something went wrong";
    }

    if (transform) {
      return this.transformAxios(response);
    }

    return response;
  }

  async get<T>(path: string, transform = true): Promise<T> {
    const config: Dict = {
      method: "get",
      url: `${url[this.apiType]}${path}`,
      validateStatus: function (status: number) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };
    if (this.token)
      config.headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

    const response = await this.authAxios?.(config);
    return transform ? this.transformAxios(response) : response;
  }

  async delete(path: string, data: any) {
    const config: Dict = {
      method: "delete",
      url: `${url[this.apiType]}${path}`,
      data,
      validateStatus: function (status: number) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };
    if (this.token)
      config.headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };

    const response = await this.authAxios?.(config);
    return this.transformAxios(response);
  }

  async rpcUnsafeRequest(method: string, params: Dict) {
    this.setAxiosHeader();
    return await this.axiosApi.post("/rpc-unsafe", {
      method,
      params,
    });
  }

  async rpcRequestDownload(method: string, params: Dict) {
    this.setAxiosHeader();
    const response = await this.axiosApi.post<RPCRequestResponse>(
      "/rpc",
      {
        method,
        params,
      },
      {
        responseType: "blob",
      },
    );

    return response.data;
  }
  async rpcRequest(method: string, params: Dict) {
    this.setAxiosHeader();
    const response = await this.axiosApi.post<RPCRequestResponse>("/rpc", {
      method,
      params,
    });

    return response.data;
  }

  apiAxiosRequest = async (
    path: string,
    method: string,
    data: any,
    params = {},
    withToken?: boolean | string,
    transform?: boolean,
    blob?: boolean,
  ) => {
    const config: Dict = {
      method,
      params,
      url: `${url[this.apiType]}/${path}`,
      data,
      validateStatus: function (status: number) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };
    const origin = this.dynamicConfig?.get(DynamicConfigFlags.BASE_URL);
    if (blob) config.responseType = "blob";
    if (withToken) {
      if ((withToken && withToken !== true) || this.token)
        config.headers = {
          "Content-Type": "application/json",
          ...(origin && { Origin: origin }),
          Authorization:
            "Bearer " +
            (withToken && withToken !== true ? withToken : this.token),
        };
    } else if (origin) {
      config.headers = {
        ...config.headers,
        Origin: origin,
      };
    }
    if (transform) {
      const response = await axios(config);

      if (response.data.errors) {
        throw response;
      }

      return this.transformAxios(response);
    }

    return await this.authAxios?.(config);
  };

  request = async <T>(
    path: string,
    method = "GET",
    data?: any,
    params: Dict = {},
  ): Promise<AxiosResponse<T> | undefined> => {
    const config: Dict = {
      method,
      params,
      url: `${url[this.apiType]}${path}`,
      data,
      validateStatus: function (status: number) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };
    config.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };
    return await this.authAxios?.(config);
  };

  /** @deprecated
   * Use uploadApiRequest instead
   */
  uploadRequest = async (
    path: string,
    data: any,
    params: Dict = {},
    onProgress?: (progress: number) => void,
    transform = false,
  ) => {
    let progress = 0;

    const config = {
      headers: {
        Authorization: "Bearer " + this.token,
      },
      method: "POST" as Method,
      params,
      onUploadProgress: function (progressEvent: any) {
        progress = Math.floor(
          Number(Number(progressEvent.loaded) / Number(data.size)) * 100,
        );
        if (onProgress) onProgress(progress);
      },
      url: `${url[this.apiType]}/${path}`,
      data,
    };

    if (transform) {
      const response = await axios?.(config);
      return this.transformAxios(response);
    }
    return await axios(config);
  };

  uploadApiRequest = async (path: string, data: FormData) => {
    const config = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "multipart/form-data",
      },
      method: "POST" as Method,
      url: `${url[this.apiType]}/${path}`,
      data,
    };

    return await axios(config);
  };

  getReport = async (path: string, params = {}) => {
    return axios({
      headers: { Authorization: "Bearer " + this.getToken() },
      method: "GET",
      params,
      responseType: "blob",
      url: `${process.env.RAZZLE_APP_REPORTS_URL}/${path}`,
    });
  };

  downloadFile = async (url: string, params: Dict = {}, usePost?: boolean) => {
    const config: AxiosRequestConfig = {
      url,
      responseType: "blob",
    };

    if (usePost) {
      config.method = "POST";
      config.data = params;
    } else {
      config.method = "GET";
      config.params = params;
    }

    if (this.token) {
      config.headers = {
        Authorization: "Bearer " + this.token,
      };
    }

    return this.authAxios?.(config);
  };

  downloadAndSaveFile = async (
    url: string,
    fileName: string,
    params?: Dict,
    usePost?: boolean,
  ) => {
    const response = await this.downloadFile(url, params, usePost);
    return saveAs(response?.data, fileName);
  };

  downloadDocument = async (
    documentId: string,
    documentName: string,
    fromMarketplace = false,
    managerId?: string,
  ) => {
    let url = `${this.placementsApi}/documents/${documentId}?view=file`;
    if (fromMarketplace) {
      url += "&from_marketplace=true";
    }

    if (managerId) {
      url += `&manager_id=${managerId}`;
    }

    return this.downloadAndSaveFile(url, documentName);
  };

  downloadDistributionReport = async (
    distributionReportId: string,
    fileName: string,
  ) => {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${process.env.RAZZLE_APP_REPORTS_URL}/distribution-report/${distributionReportId}`,
      responseType: "blob",
      validateStatus: function (status: number) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };

    if (this.token) {
      config.headers = {
        Authorization: "Bearer " + this.token,
      };
    }

    const response = await axios(config);
    return saveAs(response.data, fileName);
  };
}

export default ApiClient;
