import { Box, Image } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import useDynamicConfig, { MANAGER } from "../../hooks/useDynamicConfig";
import useMetrics from "../../hooks/useMetrics";

function VideoImageViewer({ data, carouselProps }) {
  const { video, image, autoplay = false } = data;

  const _video = video.replace("youtu.be/", "www.youtube.com/watch?v=");

  return (
    <>
      {_video && (
        <Box
          float="none"
          clear="both"
          /* width: 700px; */
          position="relative"
          paddingBottom="56.25%"
          heigh="0"
          margin="0 auto"
        >
          {_video && (_video || "").includes("youtube") && (
            <Box
              position="absolute"
              margin="0"
              top="50%"
              left="0"
              transform="translateY(-50%)"
              width="100%"
              height="100%"
              maxHeight="calc(100vh - 100px)"
              padding={carouselProps && "30px"}
              borderRadius={4}
              as="iframe"
              src={
                _video.replace("/watch?v=", "/embed/") +
                (autoplay ? "?autoplay=1" : "")
              }
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
              // allowFullScreen
            />
          )}

          {_video && (_video || "").includes("vimeo") && (
            // <AspectRatio ratio={4 / 3}>
            <Box
              position="absolute"
              margin="0"
              top="50%"
              left="0"
              transform="translateY(-50%)"
              width="100%"
              height="100%"
              maxHeight="calc(100vh - 100px)"
              padding={carouselProps && "30px"}
              as="iframe"
              src={
                _video.replace("/vimeo.com", "/player.vimeo.com/video") +
                (autoplay ? "?autoplay=1&loop=1" : "")
              }
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; accelerometer;"
              // allowFullScreen
            />
            // </AspectRatio>
          )}
        </Box>
      )}
      {!_video && image && (
        <Image
          objectFit="cover"
          borderRadius={4}
          width="100%"
          fallbackSrc={image}
          src={image}
        />
      )}
    </>
  );
}
function VideoOrImage({
  video,
  image,
  isEditor,
  managerId,
  opportunityId,
  ...rest
}) {
  const [videoOpen, setVideoOpen] = React.useState(false);
  const sendMetric = useMetrics();
  const dynamicConfig = useDynamicConfig();
  const manager = dynamicConfig.get(MANAGER);

  if (!video && !image) return <></>;
  let components = { Footer: null };
  let views = { video: video, image: image };
  if (video) {
    components = { Footer: null, View: VideoImageViewer };
  }
  if (image && !video) {
    views = { source: image };
  }

  const openVideo = () => {
    if (managerId && !isEditor) {
      sendMetric({
        event_type: "click",
        indirect_id: parseInt(managerId),
        indirect_type: "managers",
        object_type: "videos",
        is_marketplace: !(manager && manager.id),
      });
    }

    if (opportunityId) {
      sendMetric({
        event_type: "click",
        indirect_id: parseInt(opportunityId),
        indirect_type: "opportunities",
        object_type: "videos",
        is_marketplace: !(manager && manager.id),
      });
    }

    setVideoOpen(true);
  };

  return (
    <>
      <Box {...rest} position="relative">
        <VideoImageViewer data={{ video: video, image: image }} />
        <Box
          position="absolute"
          top="0px"
          bottom="0px"
          right="0px"
          left="0px"
          cursor="pointer"
          onClick={() => {
            openVideo();
          }}
        ></Box>
      </Box>
      <ModalGateway>
        {videoOpen ? (
          <Modal
            onClose={() => setVideoOpen(false)}
            styles={{
              positioner(base) {
                return { ...base, zIndex: 3001 };
              },
              blanket(base) {
                return { ...base, zIndex: 3000 };
              },
            }}
          >
            <Carousel
              isModal={true}
              currentIndex={0}
              components={components}
              views={[views]}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
}

VideoOrImage.propTypes = {
  /**
   * Url to an image
   */
  image: PropTypes.string,
  /**
   * Url to a video
   */
  video: PropTypes.string,
};

export default VideoOrImage;
