import React from "react";
import { Box, Grid, Text } from "@chakra-ui/react";
import Card from "./Card";
import { VeriHeading } from "../../index";
import Divider from "./Divider";

const FormContainer = ({
  title,
  description,
  hasDivider,
  noCard,
  children,
}) => {
  const ChildrenContainerComponent = noCard ? Box : Card;

  return (
    <Box>
      <Grid templateColumns={{ base: "10fr", md: "3fr 7fr" }}>
        <Box pr={8}>
          <VeriHeading textStyle="h5" mt={6} mb={3}>
            {title}
          </VeriHeading>
          {description && (
            <Text maxW={"306px"} color={"black"} textStyle={"bodyRegular"}>
              {description}
            </Text>
          )}
        </Box>
        <Box>
          <ChildrenContainerComponent>{children}</ChildrenContainerComponent>
        </Box>
      </Grid>
      {hasDivider && <Divider my={8} />}
    </Box>
  );
};

export default FormContainer;
