import { createContext } from "react";
import { AuthContextValues } from "./AuthContext.types";

const AuthContext = createContext<AuthContextValues>({
  auth: null,
  api: null,
  placementsApiClient: null,
  dynamicConfig: null,
  utmParams: null,
  legacyApiClient: null,
} as unknown as AuthContextValues);
const { Provider: AuthProvider } = AuthContext;

export { AuthContext, AuthProvider };
