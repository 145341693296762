import LogRocket from "logrocket";
import { ProfileForLogrocket } from "./logRocketTypes";

export const identifyLoggedInUserForLogrocket = (
  profile: ProfileForLogrocket,
) => {
  if (profile?.id) {
    LogRocket.identify(profile.id.toString(), {
      name: profile.name,
    });
  }
};
