/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const FundBuilderChargesJsonApiModel = {
  id: "",
  "fund-builder-single-asset-fund-syndication": "",
  "fund-builder-fund-administration": "",
  "fund-builder-fund-advisory": "",
};
