import { COOKIE_NAME_SESSION_ID } from "@verivest/shared-components";
import { useAuth } from "@verivest/shared-components/src/auth";
import ReactUseWebsocket from "react-use-websocket";

const useWebsocket = ({ onMessage, share = true }) => {
  const { auth } = useAuth();
  const token = auth.getToken();

  const { sendMessage } = ReactUseWebsocket(
    (token && process.env.RAZZLE_APP_WS + "?token=" + token) ||
      process.env.RAZZLE_APP_WS +
        "/pub?uuid=" +
        auth.cookies.get(COOKIE_NAME_SESSION_ID),
    {
      shouldReconnect: () => true,
      onOpen: () => {
        setInterval(() => {
          sendMessage("ping");
        }, 10000);
      },
      share: share,
      onMessage: onMessage,
    },
  );

  return sendMessage;
};

export default useWebsocket;
