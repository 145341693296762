import { Box, Flex, Spinner, Switch } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import React from "react";
import useApi from "../../../../../../api/hooks/useApi";
import {
  CheckIcon,
  CloseIcon,
  DownloadIcon,
  MoveIcon,
  TrashIcon,
} from "../../../../../../icons";
import { Button } from "../../../../../common";
import { FileRowProps } from "./FileRow.types";

const FileRow: React.FC<FileRowProps> = ({
  file,
  fileName,
  onRemove,
  onView,
  sortable,
  usePublicToggle,
  fileIndex,
  uploading,
  setConfirmData,
  deleteConfirm,
  error,
  success,
  ...rest
}) => {
  const [_isPublic, _setIsPublic] = React.useState(false);
  const [lineRef, setLineRef] = React.useState<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isDragOver, setIsDragOver] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const api = useApi();

  const onDownload = async () => {
    setIsDownloading(true);
    if (file.file) {
      const objectURL = URL.createObjectURL(file.file);
      saveAs(objectURL, fileName);
    } else if (file.id) {
      await api.downloadDocument(file.id, fileName, false, "");
    } else if (file.url) {
      saveAs(file.url);
    } else if (file.name) {
      saveAs(file.name);
    }

    setIsDownloading(false);
  };

  const doDeleteAction = (fileIndex: number) => {
    if (deleteConfirm) {
      setConfirmData?.({
        title: deleteConfirm.title ? deleteConfirm.title : "Delete file",
        message: deleteConfirm.message
          ? deleteConfirm.message.replace("%{filename}", fileName)
          : "Are you sure you want to delete this file?",
        onConfirm: () => onRemove?.(fileIndex),
        onClose: () => setConfirmData?.(null),
      });
    } else {
      onRemove?.(fileIndex);
    }
  };

  return (
    <Box position={"relative"} pb={4}>
      {isDragging && (
        <Box
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg={"bg.grey"}
          border={"1px solid"}
          borderColor={"border.grey"}
          borderRadius={4}
        />
      )}
      <Flex
        borderRadius={4}
        borderWidth={1}
        borderStyle={"solid"}
        borderColor={"border.input"}
        alignItems={"center"}
        py={3}
        px={4}
        position={"relative"}
        boxShadow={2}
        onDragStart={() => {
          /*const crt = e.target.cloneNode(true);
                    crt.style.width = e.target.offsetWidth + "px";
                    document.body.appendChild(crt);
                    e.dataTransfer.setDragImage(crt, 0, 0);
                    e.target.style.opacity = 0;
                    setIsDragging(true);*/
          setIsDragging(true);
          setIsDragOver(true);
        }}
        onDragEnd={(e) => {
          e.currentTarget.style.opacity = "1";
          setIsDragging(false);
          setIsDragOver(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
          setIsDragOver(true);
        }}
        ref={setLineRef}
        {...rest}
      >
        {(sortable || uploading || error || success) &&
          (success ? (
            <Box pr={4}>
              <CheckIcon boxSize={"24px"} color={"semantic.success"} />
            </Box>
          ) : error ? (
            <Box pr={4}>
              <CloseIcon boxSize={"16px"} color={"text.error"} />
            </Box>
          ) : uploading ? (
            <Box pr={4}>
              <Spinner color="brand.main" boxSize={"24px"} />
            </Box>
          ) : (
            <Box>
              <Box
                pr={4}
                onMouseDown={() => {
                  lineRef?.setAttribute("draggable", "true");
                }}
                onMouseUp={() => {
                  lineRef?.setAttribute("draggable", "false");
                }}
              >
                <MoveIcon boxSize={"24px"} color={"icons.grey"} />
              </Box>
            </Box>
          ))}
        {/*<FileIcon mr={4} fileName={fileName} />*/}
        <Box textAlign={"left"} flex="1" color={"text.black"}>
          {fileName}
        </Box>
        {(usePublicToggle || onRemove || onView) && (
          <Flex alignItems={"center"} justifyContent={"center"}>
            {usePublicToggle && (
              <Flex
                mt={0.5}
                alignItems={"center"}
                justifyContent={"center"}
                mr={8}
              >
                <Box>Private</Box>
                <Switch
                  colorScheme="brand"
                  onChange={(e) => _setIsPublic(e.target.checked)}
                  isChecked={_isPublic}
                  size="lg"
                  id="enable-two-way-auth"
                  px={4}
                />
                <Box>Public</Box>
              </Flex>
            )}

            {/*<Button mr={4} onClick={onView} empty>
              <EyeIcon color={"icons.grey"} boxSize={"24px"} />
            </Button>*/}
            <Button mr={4} onClick={onDownload} empty>
              {isDownloading ? (
                <Spinner color="text.grey" boxSize={"22px"} />
              ) : (
                <DownloadIcon color={"icons.grey"} boxSize={"24px"} />
              )}
            </Button>
            {onRemove && (
              <Button
                onClick={() => doDeleteAction(fileIndex)}
                isDisabled={uploading}
                empty
                aria-label="Delete file"
              >
                <TrashIcon color={"icons.grey"} boxSize={"24px"} />
              </Button>
            )}
          </Flex>
        )}
      </Flex>
      {isDragOver && !isDragging && (
        <Box
          h={"58px"}
          bg={"bg.grey"}
          border={"1px solid"}
          borderColor={"border.grey"}
          borderRadius={4}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragOver(true);
          }}
          mt={4}
        />
      )}
      {isDragOver && !isDragging && (
        <Box
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg={"transparent"}
          borderRadius={4}
          onDragLeave={(e) => {
            e.preventDefault();
            setIsDragOver(false);
          }}
          onDrop={(e) => {
            e.preventDefault();
            setIsDragOver(false);
            setIsDragging(false);
          }}
        />
      )}
    </Box>
  );
};

export default FileRow;
