/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const SwimRingIcon = createIcon({
  displayName: "SwimRingIcon",
  viewBox: "0 0 25 25",
  path: (
    <g fill="none">
      <path
        d="M12.5 24.3095C19.0222 24.3095 24.3095 19.0222 24.3095 12.5C24.3095 5.97773 19.0222 0.69043 12.5 0.69043C5.97773 0.69043 0.69043 5.97773 0.69043 12.5C0.69043 19.0222 5.97773 24.3095 12.5 24.3095Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 18.4043C15.7611 18.4043 18.4047 15.7606 18.4047 12.4995C18.4047 9.23838 15.7611 6.59473 12.5 6.59473C9.23887 6.59473 6.59521 9.23838 6.59521 12.4995C6.59521 15.7606 9.23887 18.4043 12.5 18.4043Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9285 8.07134L20.619 4.38086M16.9285 16.9285L20.619 20.619M8.07134 16.9285L4.38086 20.619M8.07134 8.07134L4.38086 4.38086"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});
export default SwimRingIcon;
