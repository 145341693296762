/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const FileIcon = createIcon({
  displayName: "FileIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M15 1V7H21L15 1Z" fill="currentColor" /><path d="M15 1V7H21" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M15 1H3V23H21V7L15 1Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default FileIcon;