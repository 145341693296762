import { Avatar, Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { AvatarImageProps } from "./AvatarImage.types";
import { allAvatarPartIds } from "./AvatarImageTestIds";
import { DefaultAvatarImage } from "./components";

const AvatarImage: React.FC<AvatarImageProps> = ({
  image,
  badge,
  badgeColor = "semantic.success",
  isUser,
  ...rest
}) => {
  let _avatarImage;

  if ((!image || image === "") && !isUser) {
    _avatarImage = (
      <DefaultAvatarImage
        data-testid={allAvatarPartIds.defaultAvatar}
        {...rest}
      />
    );
  } else if ((!image || image === "") && isUser) {
    _avatarImage = (
      <Avatar
        borderRadius="50%"
        size="32px"
        src={""}
        data-testid={allAvatarPartIds.userAvatar}
        {...rest}
      />
    );
  } else {
    _avatarImage = (
      <Image
        objectFit="cover"
        fallbackSrc={image}
        width="72px"
        height="72px"
        borderRadius="50%"
        src={image}
        data-testid={allAvatarPartIds.imageAvatar}
        {...rest}
      />
    );
  }

  if (badge) {
    return (
      <Box position={"relative"} data-testid={allAvatarPartIds.avatarWrapper}>
        {_avatarImage}
        <Flex
          backgroundColor={badgeColor}
          borderRadius={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          position={"absolute"}
          right={0}
          top={0}
          mr={3}
          w={6}
          h={6}
          data-testid={allAvatarPartIds.avatarBadge}
        >
          {badge}
        </Flex>
      </Box>
    );
  }

  return _avatarImage;
};

export default AvatarImage;
