export enum LoggedMenuPage {
  FindManagers = "find-managers",
  Portfolio = "portfolio",
  Investments = "investments",
  Accounts = "accounts",
  Documents = "documents",
}

export const guestTextMenuItems = [
  {
    name: "Home",
    href: "/",
    children: "Home",
  },
  {
    name: "Managers",
    href: "/for-managers",
    children: "Managers",
  },
  {
    name: "Investors",
    href: "/for-investors",
    children: "Investors",
  },
  {
    name: "Pricing",
    href: "/pricing",
    children: "Pricing",
  },
];

export const loggedUserTextMenuItems = [
  {
    name: LoggedMenuPage.Investments,
    to: "/investments",
    children: "Investments",
  },
  {
    name: LoggedMenuPage.Accounts,
    to: "/accounts",
    children: "Accounts",
  },
  {
    name: LoggedMenuPage.Documents,
    to: "/documents",
    children: "Documents",
  },
];
