import React from "react";
import { VeriDatePickerProps } from "./VeriDatePicker.types";
import { format, getMonth, getYear, parse } from "date-fns";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { VeriSelect } from "../../form-controls";
import { Button } from "../../common";
import { Flex } from "@chakra-ui/react";
import { monthFullNames } from "../../../utils/dates";

const dateFormat = "yyyy-MM-dd";

const VeriDatePicker: React.FC<VeriDatePickerProps> = ({
  initialDate,
  ...rest
}) => {
  const parsedDate = rest.value
    ? parse(rest.value, "MM/dd/yyyy", new Date())
    : new Date();
  const isValidValue =
    parsedDate instanceof Date && !isNaN(parsedDate.getTime());

  const onSingleDateSelect = (date: Date) => {
    rest.onDateChange?.(format(date as Date, dateFormat));
    rest.onDayPick?.(date as Date);
  };

  const onRangeDateSelect = (startDate: Date | null, endDate: Date | null) => {
    rest.onRangeChange?.(startDate, endDate);
    rest.onDayPick?.(startDate as Date);
  };

  return (
    <DatePicker
      onChange={(d) => {
        if (rest.isRangePicker && Array.isArray(d)) {
          return onRangeDateSelect?.(d[0], d[1]);
        }
        onSingleDateSelect(d as Date);
      }}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => {
        const years = new Array(30)
          .fill(0)
          .map((_, i) => getYear(date) - 15 + i);
        return (
          <Flex
            p={2.5}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1.5}
          >
            <button
              type="button"
              className="react-datepicker__navigation react-datepicker__navigation--previous"
              aria-label="Previous Month"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                Previous Month
              </span>
            </button>

            <VeriSelect
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(Number(value))}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </VeriSelect>

            <VeriSelect
              value={monthFullNames[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(monthFullNames.indexOf(value))
              }
              selectProps={{ minWidth: "130px" }}
            >
              {monthFullNames.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </VeriSelect>
            {!rest.isRangePicker && (
              <Button
                onClick={onSingleDateSelect.bind(null, new Date())}
                outline
              >
                Today
              </Button>
            )}
            <button
              type="button"
              className="react-datepicker__navigation react-datepicker__navigation--next"
              aria-label="Next Month"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                Next Month
              </span>
            </button>
          </Flex>
        );
      }}
      selectsRange={rest.isRangePicker}
      startDate={
        rest.rangeStartDate
          ? parse(rest.rangeStartDate, dateFormat, new Date())
          : isValidValue
          ? parsedDate
          : new Date()
      }
      endDate={
        rest.rangeEndDate
          ? parse(rest.rangeEndDate, dateFormat, new Date())
          : undefined
      }
      maxDate={
        rest.maxDate ? parse(rest.maxDate, dateFormat, new Date()) : undefined
      }
      minDate={
        rest.minDate ? parse(rest.minDate, dateFormat, new Date()) : undefined
      }
      excludeDates={rest.disabledDates}
      selected={isValidValue ? parsedDate : new Date()}
      fixedHeight
      inline
    />
  );
};

export default VeriDatePicker;
