/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UpdateTrackRecordOutputJsonApiModel = {
  id: "",
  "track-record": {
    jsonApi: "hasOne",
    type: "track-records",
  },
};
