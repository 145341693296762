/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const FlattenedInvestmentItemJsonApiModel = {
  id: "",
  "transaction-type": "",
  "transaction-effective-date": "",
  "transaction-amount": "",
  "transaction-units": "",
  "transaction-unit-price": "",
  "offering-id": "",
  "offering-name": "",
  "offering-status": "",
  description: "",
  "is-imported": "",
};
