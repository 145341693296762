/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const FeeJsonApiModel = {
  id: "",
  slug: "",
  name: "",
  recurring: "",
  frequency: "",
  order: "",
  "basis-options": "",
  "percent-basis-options": "",
  "payment-receiver-options": "",
  "created-at": "",
  "updated-at": "",
};
