/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetFeeInstancesOutputJsonApiModel = {
  id: "",
  "fee-instances": {
    jsonApi: "hasMany",
    type: "fee-instances",
  },
};
