import { GroupedInvestmentModel } from "../../../models/GroupedInvestment/GroupedInvestment";
import { useVeriModel, UseVeriModelOptions } from "../../useVeriModel";
import { apiGroupedInvestmentModel } from "./apiGroupedInvestmentModel";

export function useGroupedInvestmentModel(
  opts?: UseVeriModelOptions<GroupedInvestmentModel>,
) {
  return useVeriModel(apiGroupedInvestmentModel, opts);
}

export default useGroupedInvestmentModel;
