import { Flex, Image } from "@chakra-ui/react";
import React from "react";

const EmptyHeader = ({ ...rest }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="nowrap"
      height="64px"
      bg="white"
      padding="none"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06), inset 0px -1px 0px #E5E5E5"
      {...rest}
    >
      <Flex align="center" ml={5} mr={5} height="24px">
        <a href="/">
          <Image
            height={"24px"}
            alt={"Verivest's logo"}
            fallbackSrc={"/images/verivest-logo.svg"}
            src={"/images/verivest-logo.svg"}
          />
        </a>
      </Flex>
    </Flex>
  );
};

export default EmptyHeader;
