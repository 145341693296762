/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const LinkIcon = createIcon({
  displayName: "LinkIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M14.242 9.75801C13.752 9.26801 13.19 8.88 12.583 8.589C12.208 8.966 12 9.468 12 10C12 10.213 12.04 10.415 12.102 10.608C12.361 10.769 12.607 10.951 12.828 11.172C13.583 11.928 14 12.932 14 14C14 15.068 13.584 16.073 12.829 16.829L9.829 19.828C8.317 21.34 5.683 21.34 4.172 19.828C3.416 19.072 3 18.068 3 17C3 15.932 3.416 14.928 4.171 14.172L6.275 12.068C6.098 11.401 6 10.709 6 10C6 9.838 6.013 9.677 6.023 9.517C5.934 9.596 5.843 9.67301 5.757 9.75801L2.757 12.758C1.624 13.891 1 15.397 1 17C1 18.603 1.624 20.109 2.757 21.242C3.891 22.376 5.397 23 7 23C8.603 23 10.109 22.376 11.243 21.242L14.243 18.243C15.375 17.109 16 15.603 16 14C16 12.397 15.375 10.891 14.242 9.75801Z" fill="currentColor" /><path d="M21.243 2.758C20.109 1.624 18.603 1 17 1C15.397 1 13.891 1.624 12.757 2.758L9.757 5.757C8.625 6.891 8 8.397 8 10C8 11.603 8.624 13.109 9.757 14.242C10.247 14.732 10.809 15.12 11.416 15.411C11.792 15.034 12 14.532 12 14C12 13.782 11.959 13.575 11.894 13.378C11.636 13.223 11.391 13.046 11.173 12.828C10.417 12.072 10 11.068 10 10C10 8.932 10.416 7.927 11.171 7.171L14.171 4.172C14.927 3.416 15.932 3 17 3C18.068 3 19.073 3.416 19.829 4.172C20.584 4.928 21 5.932 21 7C21 8.068 20.584 9.072 19.829 9.828L17.722 11.935C17.9 12.601 18 13.292 18 14C18 14.162 17.988 14.322 17.979 14.482C18.068 14.403 18.159 14.327 18.244 14.242L21.244 11.242C22.376 10.109 23 8.603 23 7C23 5.397 22.376 3.891 21.243 2.758Z" fill="currentColor" /></g>)
});
export default LinkIcon;