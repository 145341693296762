/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WorkflowJobInstanceJsonApiModel = {
  id: "",
  "job-id": "",
  job: {
    jsonApi: "hasOne",
    type: "workflow-job",
  },
  "start-on": "",
  status: "",
  "status-code": "",
  "worker-id": "",
  worker: {
    jsonApi: "hasOne",
    type: "workflow-job-worker",
  },
  errors: "",
  settings: "",
  data: "",
  metadata: "",
  "created-at": "",
  "updated-at": "",
};
