import React from "react";
import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from "@chakra-ui/react";
import { AvatarEditorProps } from "./AvatarEditor.types";
import useApi from "../../../api/hooks/useApi";
import usePlacementsApi from "../../../api/hooks/usePlacementsApi";
import { useAuth } from "../../../auth";
import { Button } from "../Button";
import AvatarEditorCropper from "react-avatar-editor";
import VeriHeading from "../VeriHeading/VeriHeading";
import { AvatarImage } from "../AvatarImage";
import { logError } from "../../../error-handling/logError";
import { VeriModal } from "../../modals/VeriModal";

const AvatarEditor: React.FC<AvatarEditorProps> = ({
  userProfile,
  title = "Profile photo",
  isOpen,
  onClose,
  setPicture,
  description,
  editorEnabled = false,
  borderRadius = 1000,
  onUpload,
  isPlacement,
  transparencyBackground,
  maskColor,
  saveLabel = "Save",
  selectedFile: _selectedFile,
  allowPictureRemove,
}) => {
  const api = useApi();
  const placementsApi = usePlacementsApi();
  const { auth } = useAuth();
  const [userPicture, setUserPicture] = React.useState(
    userProfile?.picture || "",
  );
  const [selectedFile, setSelectedFile] = React.useState(_selectedFile || null);
  const [avatarScale, setAvatarScale] = React.useState(100);
  const [startEdit, setStartEdit] = React.useState(editorEnabled);
  const [isLoading, setLoading] = React.useState(false);
  const editor = React.useRef<any>(null);

  const selectPicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setUserPicture(URL.createObjectURL(e.target.files[0]));
      setStartEdit(true);
    }
  };

  return (
    <VeriModal isOpen={isOpen || false} onClose={onClose}>
      <VeriHeading textStyle="h4" mt="24px" mb="16px">
        {title}
      </VeriHeading>
      {description && <Text>{description}</Text>}
      <Box my="32px" display="flex" justifyContent="center" w={"100%"}>
        {(!userPicture || !startEdit) && (
          <AvatarImage
            style={{ cursor: "pointer" }}
            size="xl"
            h="300px"
            w="300px"
            name={userProfile?.name}
            image={userPicture}
            borderRadius={borderRadius}
            isUser
          />
        )}
        {userPicture && startEdit && (
          <Flex direction="column" w="100%" maxW="460px">
            <Flex
              alignItems="center"
              justifyContent="center"
              h="300px"
              bg={
                transparencyBackground
                  ? "url(/images/transparency-pattern.png)"
                  : undefined
              }
              borderRadius={transparencyBackground ? 4 : undefined}
              pos={transparencyBackground ? "relative" : undefined}
            >
              {transparencyBackground && (
                <Box
                  pos={"absolute"}
                  left={0}
                  top={0}
                  bottom={0}
                  right={0}
                  bg={"rgba(255,255,255,.8)"}
                />
              )}
              <Box pos={"relative"}>
                <AvatarEditorCropper
                  image={userPicture}
                  width={250}
                  height={250}
                  border={15}
                  color={maskColor || [255, 255, 255, 0.8]} // RGBA
                  scale={avatarScale ? avatarScale / 100 : 1}
                  rotate={0}
                  borderRadius={borderRadius}
                  ref={editor}
                />
              </Box>
            </Flex>
            <Flex mt="20px">
              <Box px="20px">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#A1A5AC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
              <Box w={"100%"}>
                <Slider
                  defaultValue={100}
                  min={10}
                  max={200}
                  step={1}
                  onChange={(value) => setAvatarScale(value)}
                  colorScheme="brand"
                >
                  <SliderTrack h="2px">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack h="2px" />
                  </SliderTrack>
                  <SliderThumb
                    boxSize={6}
                    boxShadow="0px 1px 2px rgba(0, 0, 0, 0.06), 0px 4px 9px rgba(0, 0, 0, 0.1)"
                    _focus={{
                      boxShadow:
                        "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 4px 9px rgba(0, 0, 0, 0.1)!important",
                    }}
                  />
                </Slider>
              </Box>
              <Box px="20px">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 5V19"
                    stroke="#A1A5AC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 12H19"
                    stroke="#A1A5AC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
            </Flex>
          </Flex>
        )}
      </Box>
      <Flex>
        <Flex flex="1 1 auto">
          <Button
            isLoading={isLoading}
            isLarge
            isBold
            mr="18px"
            onClick={async () => {
              if (!userPicture) {
                if (onUpload) {
                  await onUpload(
                    isPlacement ? placementsApi : api,
                    null,
                    "",
                    null,
                  );
                  onClose && onClose();
                }
                return;
              }

              if (startEdit) {
                setLoading(true);
                editor.current
                  ?.getImageScaledToCanvas()
                  .toBlob(async (blob: Blob) => {
                    const formData = new FormData();
                    formData.append("file", blob, "filename.png");
                    try {
                      if (onUpload) {
                        await onUpload(
                          isPlacement ? placementsApi : api,
                          formData,
                          blob,
                          selectedFile,
                        );
                      } else {
                        await api.uploadRequest(
                          "user/me/picture",
                          formData,
                          {},
                          () => null,
                        );
                        await auth.refreshProfile();
                        setPicture?.(auth?.getProfile()?.picture || "");
                      }
                    } catch (e) {
                      logError("error uploading image", e);
                    } finally {
                      setLoading(false);
                    }
                    onClose();
                  });

                // setPicture(image);
              } else {
                setPicture?.("");
                onClose();
              }
            }}
          >
            {saveLabel}
          </Button>

          <Box position="relative" overflow="hidden">
            <Button isDisabled={isLoading} isLarge isBold outline>
              Change photo
            </Button>
            <input
              disabled={isLoading}
              value={""}
              onChange={selectPicture}
              accept="image/x-png,image/gif,image/jpeg"
              type="file"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                opacity: 0,
                cursor: "pointer",
              }}
            />
          </Box>
        </Flex>
        {allowPictureRemove && userPicture && (
          <Box>
            <Button
              isLarge
              isBold
              empty
              onClick={() => {
                setUserPicture("");
                setStartEdit(false);
              }}
            >
              Delete photo
            </Button>
          </Box>
        )}
      </Flex>
    </VeriModal>
  );
};

export default AvatarEditor;
