import { OpportunityStatusEnumType } from "../api/enums";
import { OpportunityModel } from "../api/models";

export const opportunityStatusToColor = (
  status: OpportunityStatusEnumType | string,
) => {
  switch (status) {
    case OpportunityStatusEnumType.Draft:
      return "opportunity.draft";
    case OpportunityStatusEnumType.Open:
    case "Active":
      return "opportunity.open";
    case OpportunityStatusEnumType.ComingSoon:
    case "Opening Soon":
      return "opportunity.soon";
    case OpportunityStatusEnumType.Funded:
      return "opportunity.funded";
    case OpportunityStatusEnumType.FullCycle:
    case "Realized":
      return "opportunity.full";
    case "Monitoring":
      return "opportunity.monitoring";
  }

  return "";
};

export const getOpportunityBaseUrl = (
  managerSlugOrId?: string | number | null,
) => {
  if (!managerSlugOrId) {
    return "";
  }

  return `/${managerSlugOrId}/opportunities`;
};

export const getOpportunityUrl = (opportunity: OpportunityModel) => {
  return `/opportunities/${opportunity.slug || opportunity.id}`;
};

export function sortOpportunityBySortOrder(
  a: OpportunityModel,
  b: OpportunityModel,
) {
  return parseInt(String(a.sortOrder)) - parseInt(String(b.sortOrder));
}

export function getOpportunityFeaturedImage(opportunity: OpportunityModel) {
  if (opportunity.bannerLayout) {
    const foundBanner = opportunity.bannerImages?.find(
      (banner) => banner.layout === opportunity.bannerLayout,
    );
    if (foundBanner?.croppedImage?.url) return foundBanner.croppedImage.url;
  }

  const images = opportunity.images;
  if (!images || images.length === 0) return "/images/empty-card-sponsor.svg";

  return (
    images.sort((a, b) => (a?.sortOrder || 0) - (b.sortOrder || 0))[0].url || ""
  );
}
