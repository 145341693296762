/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImportAccountMatchDataJsonApiModel = {
  id: "",
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  tin: "",
  users: {
    jsonApi: "hasMany",
    type: "users",
  },
};
