/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ReviewUserJsonApiModel = {
  id: "",
  name: "",
  picture: "",
  "first-name": "",
  "last-name": "",
  "is-owner": "",
};
