/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ActivityIcon = createIcon({
  displayName: "ActivityIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M22 12H18L15 21L9 3L6 12H2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></g>)
});
export default ActivityIcon;