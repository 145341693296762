import React from "react";
import { PageButtonProps } from "./PageButton.types";
import { Box, Link, Text } from "@chakra-ui/react";

const PageButton: React.FC<PageButtonProps> = ({ selected, page, onClick }) => {
  return (
    <Box as="li" display="inline-block" mr="16px">
      <Box
        as={(!selected && Link) || "span"}
        // to={href || undefined}
        onClick={onClick}
        h="32px"
        w="32px"
        borderRadius={"50%"}
        bg={selected ? "brand.main" : undefined}
        _hover={{ color: selected ? "white" : "brand.main" }}
        color={selected ? "white" : "text.grey"}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text as="span" fontWeight="700">
          {page}
        </Text>
      </Box>
    </Box>
  );
};

export default PageButton;
