import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  DeepPartial,
  useVeriForm,
  UseFormReturn,
} from "../../../react-hook-form";
import { EditIcon } from "../../../icons";
import { Button } from "../../common";

export interface PopoverEditProps<T> {
  submitLabel?: React.ReactNode;
  cancelLabel?: React.ReactNode;
  getInputs?: (form: UseFormReturn<T>) => React.ReactNode;
  defaultValues?: DeepPartial<T>;
  onSubmit?: (values: T) => Promise<void> | void;
}

const PopoverEdit = <T,>({
  children,
  submitLabel = "Update",
  cancelLabel = "Cancel",
  getInputs,
  defaultValues,
  onSubmit,
}: React.PropsWithChildren<PopoverEditProps<T>>) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);

  const form = useVeriForm<T>({
    defaultValues,
  });

  const _onClose = () => {
    form.reset(defaultValues);
    onClose();
  };

  const _onSubmit = async (values: T) => {
    setIsLoading(true);
    onSubmit && (await onSubmit(values));
    setIsLoading(false);
    form.reset(values);
    onClose();
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      closeOnBlur={false}
    >
      <PopoverTrigger
      /*onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}*/
      >
        <EditIcon
          boxSize={"20px"}
          ml={2}
          color={"icons.grey"}
          _hover={{ cursor: "pointer" }}
        />
      </PopoverTrigger>
      <PopoverContent
        boxShadow={
          "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)!important"
        }
        borderRadius={4}
        p={6}
      >
        <Box key={JSON.stringify(defaultValues)}>
          {getInputs && getInputs(form)}
          {children}
        </Box>
        <Stack spacing={2} mt={6} direction={"row"}>
          <Button
            isLoading={isLoading}
            label={submitLabel}
            onClick={form.handleSubmit(_onSubmit)}
          />
          <Button
            isDisabled={isLoading}
            label={cancelLabel}
            onClick={_onClose}
            outline
          />
        </Stack>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverEdit;
