/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UpdateFeeInputJsonApiModel = {
  id: "",
  fee: {
    jsonApi: "hasOne",
    type: "fees",
  },
  options: {
    jsonApi: "hasOne",
    type: "update-options",
  },
};
