import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";
import { DashboardMenuLink } from "./DashboardMenuLink";
import { managerDashboardMenuItems } from "./managerDashboardMenuItems";
import { useGetFeatureToggles } from "@verivest/shared-components/src/feature-toggle";

interface SponsorDashboardMenuProps extends BoxProps {
  onItemSelected: () => void;
}

const SponsorDashboardMenu = ({
  onItemSelected,
  ...boxProps
}: SponsorDashboardMenuProps) => {
  const isFeatureAvailable = useGetFeatureToggles();

  return (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="background"
      borderColor="border.gray"
      borderRightWidth="1px"
      w="240px"
      zIndex={"sponsorDashboardMenu"}
      {...boxProps}
    >
      <Flex direction="column" aria-label="Main Navigation" mt={20}>
        {managerDashboardMenuItems.map((item) => {
          if (
            item.featureFlag &&
            !isFeatureAvailable({ featureName: item.featureFlag })
          ) {
            return <React.Fragment key={item.name}></React.Fragment>;
          }

          return (
            <DashboardMenuLink
              key={item.name}
              to={item.to}
              icon={item.icon}
              onClick={onItemSelected}
            >
              {item.children}
            </DashboardMenuLink>
          );
        })}
      </Flex>
    </Box>
  );
};

export default SponsorDashboardMenu;
