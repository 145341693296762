import { Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../../auth";
import { useLayout } from "../../../hooks/ui/useLayout";
import { ProfileButtonProps } from "./ProfileButton.types";
import { ProfileButtonAvatar } from "./ProfileButtonAvatar";

export const ProfileButton: React.FC<ProfileButtonProps> = ({
  isMarketing,
}) => {
  const { auth } = useAuth();
  const { layout } = useLayout();
  const profile = layout.profile;
  const isImpersonating = auth.isImpersonating();
  const isMallcop = auth?.isMallcop() || false;
  const isManagerOfAny = auth.isManagerOfAny();

  const handleLogout = async () => {
    await auth.logout();
  };

  return (
    <Menu placement={"bottom-end"} closeOnSelect={false}>
      {profile && (
        <MenuButton
          dataTestId="logged-in-user-avatar"
          as={ProfileButtonAvatar}
          isImpersonating={!!isImpersonating}
          profile={profile}
        >
          MenuItem
        </MenuButton>
      )}
      <MenuList>
        {isImpersonating && (
          <MenuItem onClick={() => auth.revokeImpersonation()}>
            Leave impersonation ({isImpersonating.name})
          </MenuItem>
        )}
        <MenuItem
          as={isMarketing ? Link : RouterLink}
          href={isMarketing ? "/user/settings" : undefined}
          to={!isMarketing ? "/user/settings" : undefined}
        >
          Settings
        </MenuItem>
        {isMallcop && (
          <MenuItem
            onClick={() => {
              window.location.href =
                process.env.RAZZLE_APP_ADMIN_URL +
                "/login?token=" +
                auth.getToken();
            }}
          >
            Switch to Admin Portal
          </MenuItem>
        )}
        {(isMallcop || isManagerOfAny) && (
          <MenuItem onClick={() => auth.switchToDashboard()}>
            <span>Switch to Manager Dashboard</span>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );
};
