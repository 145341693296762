/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const TransactionJsonApiModel = {
  id: "",
  "transaction-set": {
    jsonApi: "hasOne",
    type: "transaction-sets",
  },
  security: {
    jsonApi: "hasOne",
    type: "securities",
  },
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  holding: {
    jsonApi: "hasOne",
    type: "holdings",
  },
  amount: "",
  "cr-or-dr": "",
  element: "",
  "is-displayed": "",
  description: "",
  "transaction-type": "",
  units: "",
  "unit-price": "",
};
