import React from "react";
import { BackButtonProps } from "./BackButton.types";
import { Box, Button } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { ChevronLeftIcon } from "../../../icons";

const BackButton: React.FC<BackButtonProps> = ({
  children,
  to,
  title,
  onClick,
  useReplace,
  ...restProps
}) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (onClick) {
      onClick(event);
    } else if (to) {
      let toUrl = "";
      const nUrlParmas = new URLSearchParams(location.search);
      const bTo = nUrlParmas.get("bTo");
      toUrl = bTo || to;
      if (useReplace) {
        history.replace(toUrl);
      } else {
        history.push(toUrl);
      }
    }
  };

  return (
    <Button
      my={6}
      variant="ghost"
      textStyle="bodyRegular"
      fontWeight="400"
      px={0}
      onClick={handleClick}
      {...restProps}
      data-testid="back-button"
    >
      <ChevronLeftIcon boxSize="20px" />
      {title && <Box mr={2}>{title}</Box>}
      {children}
    </Button>
  );
};

export default BackButton;
