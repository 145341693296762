/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ClockIcon = createIcon({
  displayName: "ClockIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 6V12H18" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default ClockIcon;