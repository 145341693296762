/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallInstanceJsonApiModel = {
  id: "",
  "waterfall-definition-id": "",
  "waterfall-definitions": {
    jsonApi: "hasOne",
    type: "waterfall-definitions",
  },
  "amount-in": "",
  "calculated-amount": "",
  "effective-date": "",
  "distribution-date": "",
  status: "",
  "distribution-set-id": "",
  "created-by-user-id": "",
  "approved-by-user-id": "",
  "approved-at": "",
  "waterfall-instance-rules": {
    jsonApi: "hasMany",
    type: "waterfall-instance-rules",
  },
};
