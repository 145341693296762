import React from "react";
import { Box, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const LinkList = ({ value, asRouterLink = true }) => {
  const links = Array.isArray(value) ? value : [];

  return (
    <Box data-testid="link-list-box">
      {links.map((link, linkIndex) => (
        <React.Fragment key={linkIndex}>
          <Link
            as={RouterLink}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              link.onClick?.();
            }}
            onMouseDown={(e) => e.stopPropagation()}
            color={"brand.main"}
            to={link.to}
          >
            {link.label}
          </Link>
          {linkIndex < links.length - 1 && " • "}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default LinkList;
