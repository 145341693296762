import { FlexProps } from "@chakra-ui/react";
import React from "react";
import {
  ManagerModel,
  NotificationContentInvestorDocumentVoidedModel,
  NotificationContentManagerAccreditationFailedModel,
} from "../../api/models";
import { NotificationAccreditationFailed } from "./notifications-by-type/NotificationAccreditationFailed";
import { NotificationDocusignVoided } from "./notifications-by-type/NotificationDocusignVoided";
import {
  NotificationImporterBrownfieldCompletedModel,
  PossibleNotificationTypes,
} from "./notifications.types";
import { NotificationImporterBrownfieldCompleted } from "./notifications-by-type/NotificationImporterBrownfieldCompleted";

interface NotificationItemProps extends FlexProps {
  type: PossibleNotificationTypes;
  content:
    | NotificationContentManagerAccreditationFailedModel
    | NotificationContentInvestorDocumentVoidedModel
    | NotificationImporterBrownfieldCompletedModel;
  createdAt: string;
  viewed: boolean;

  // for notifications, manager has to come from here,
  // so that notifications opened from the websocket, that is outside
  // of the AuthContext, wouldn't trigger an error
  manager: ManagerModel | null | undefined;
}

const NotificationItem = ({
  type,
  content,
  createdAt,
  manager,
  ...rest
}: NotificationItemProps) => {
  switch (type) {
    case PossibleNotificationTypes.ManagerAccreditationFailed:
      return (
        <NotificationAccreditationFailed
          {...{ content, createdAt, manager }}
          {...rest}
        />
      );
    case PossibleNotificationTypes.InvestorDocumentVoided:
      return (
        <NotificationDocusignVoided
          {...{ content, createdAt, manager }}
          {...rest}
        />
      );
    case PossibleNotificationTypes.ImporterBrownfieldCompleted:
      return (
        <NotificationImporterBrownfieldCompleted
          {...{ content, createdAt, manager }}
          {...rest}
        />
      );
    default:
      return <></>;
  }
};

export default NotificationItem;
