import { AccountModel, AddressModel, LocationModel } from "../api/models";
import { isCustodiedAccountType } from "./accounts";

export function buildStreetAddress(location: LocationModel): string {
  let streetAddress = "";
  if (location) {
    if (location.streetNumber) {
      streetAddress = `${location.streetNumber} `;
    }
    if (location.route) {
      streetAddress += location.route;
    }
  }

  return streetAddress;
}

export function buildLocation(
  suggest: google.maps.GeocoderResult | null,
): LocationModel {
  // Create a location object
  const location: LocationModel = {} as LocationModel;

  if (!suggest || !suggest.place_id) return location;

  location.id = 0;
  location.placeId = suggest.place_id;
  location.label = suggest.formatted_address;
  location.latitude = suggest.geometry.location.lat();
  location.longitude = suggest.geometry.location.lng();
  location.formattedAddress = suggest.formatted_address;
  location.locationType =
    suggest.types && suggest.types.length > 0 ? suggest.types.join(", ") : "";

  if (suggest.address_components) {
    for (let i = 0; i < suggest.address_components.length; i++) {
      for (let j = 0; j < suggest.address_components[i].types.length; j++) {
        switch (suggest.address_components[i].types[j]) {
          case "establishment":
            location.establishmentName =
              suggest.address_components[i].short_name;
            break;
          case "point_of_interest":
            location.pointOfInterest = suggest.address_components[i].short_name;
            break;
          case "street_number":
            location.streetNumber = suggest.address_components[i].short_name;
            break;
          case "route":
            location.route = suggest.address_components[i].short_name;
            break;
          case "premise":
            location.premise = suggest.address_components[i].short_name;
            break;
          case "subpremise":
            location.subpremise = suggest.address_components[i].short_name;
            break;
          case "neighborhood":
            location.neighborhood = suggest.address_components[i].short_name;
            break;
          case "colloquial_area":
            location.colloquialArea = suggest.address_components[i].short_name;
            break;
          case "natural_feature":
            location.naturalFeature = suggest.address_components[i].short_name;
            break;
          case "locality":
            location.locality = suggest.address_components[i].long_name;
            break;
          case "sublocality":
            location.sublocality = suggest.address_components[i].short_name;
            break;
          case "sublocality_level_1":
            location.sublocalityLevel1 =
              suggest.address_components[i].short_name;
            break;
          case "sublocality_level_2":
            location.sublocalityLevel2 =
              suggest.address_components[i].short_name;
            break;
          case "sublocality_level_3":
            location.sublocalityLevel3 =
              suggest.address_components[i].short_name;
            break;
          case "administrative_area_level_1":
            location.administrativeAreaLevel1 =
              suggest.address_components[i].short_name;
            break;
          case "administrative_area_level_2":
            location.administrativeAreaLevel2 =
              suggest.address_components[i].short_name;
            break;
          case "administrative_area_level_3":
            location.administrativeAreaLevel3 =
              suggest.address_components[i].short_name;
            break;
          case "postal_code":
            location.postalCode = suggest.address_components[i].short_name;
            break;
          case "postal_code_suffix":
            location.postalCodeSuffix =
              suggest.address_components[i].short_name;
            break;
          case "country":
            location.country = suggest.address_components[i].short_name;
            break;
          default:
        }
      }
    }
  }

  return location;
}

export function isValidPartialAddress(address: AddressModel) {
  return Object.keys(address).some(
    (key) => !!address[key as keyof AddressModel],
  );
}

export function addressToString(address?: AddressModel | null) {
  if (!address) return;
  return `${address.address1}${
    address.address2 ? ", " + address.address2 : ""
  }, ${address.city}, ${address.region} ${address.postalCode}`;
}

export function getEmptyAddressObject(): AddressModel {
  return {
    id: null,
    address1: null,
    address2: null,
    city: null,
    country: null,
    createdAt: null,
    postalCode: null,
    postalCodeSuffix: null,
    location: null,
    updatedAt: null,
    region: null,
  };
}

export enum AddressType {
  Home = "Home Address",
  Mailing = "Mailing Address",
  Primary = "Primary Address",
  Other = "Other Address",
}

export const getAddressSelection = (
  account: AccountModel,
  otherAddress: AddressModel | null | undefined,
) => {
  let addresses: {
    key: AddressType;
    value: AddressModel | null | undefined;
  }[] = [];
  if (isCustodiedAccountType(account.accountType)) {
    return [
      {
        key: "Custodian Address",
        value: account.custodian?.address,
      },
    ];
  }
  if (account && account.accountType) {
    switch (account.accountType) {
      case "Individual":
        addresses = [
          {
            key: AddressType.Home,
            value: account.primaryAddress,
          },
          {
            key: AddressType.Mailing,
            value: account.mailingAddress,
          },
        ];

        break;
      case "Joint":
        addresses = [
          {
            key: AddressType.Home,
            value: account.individual1?.primaryAddress,
          },
          {
            key: AddressType.Mailing,
            value: account.individual1?.mailingAddress,
          },
        ];
        break;
      case "Entity":
      case "Trust":
        addresses = [
          {
            key: AddressType.Primary,
            value: account.primaryAddress,
          },
          { key: AddressType.Mailing, value: account.mailingAddress },
        ];
        break;
      default:
    }
    addresses.push({
      key: AddressType.Other,
      value: otherAddress || getEmptyAddressObject(),
    });
  }

  return addresses;
};
