import React from "react";
import { usePopper } from "react-popper";
import { MenuItemProps } from "./MenuItem.types";
import { Box, Flex } from "@chakra-ui/react";
import { ChevronRightIcon } from "../../../../../icons";

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  subMenu,
  setIsOpen,
  onClick,
  isDisabled,
  px = 2,
  ...rest
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = React.useState(false);

  const [trigger, setTrigger] = React.useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(trigger, popperElement, {
    modifiers: [],
    placement: "left-start",
  });

  return (
    <Flex
      textStyle={"bodyRegular"}
      _hover={
        !isDisabled
          ? {
              cursor: "pointer",
              bg: "bg.lighter",
            }
          : { cursor: "not-allowed" }
      }
      ref={subMenu ? setTrigger : undefined}
      whiteSpace={"nowrap"}
      onMouseEnter={() => {
        if (subMenu) {
          setIsSubMenuOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (subMenu) {
          setIsSubMenuOpen(false);
        }
      }}
      onClick={(e) => {
        if (onClick && !isDisabled) onClick(e);
        setIsOpen?.(false);
      }}
      bg={"transparent"}
      w={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
      {...rest}
    >
      <Box
        flexGrow={1}
        py={2}
        px={px}
        textAlign={"left"}
        {...(isDisabled && { color: "text.greyLight" })}
      >
        {children}
      </Box>
      {subMenu && (
        <Box pr={4}>
          <ChevronRightIcon boxSize={6} color={"icons.grey"} />
        </Box>
      )}

      {subMenu && !isDisabled && isSubMenuOpen && (
        <Box
          {...(styles.popper as unknown)}
          {...attributes.popper}
          ref={setPopperElement}
          zIndex={"dropdown"}
          mr={-3}
        >
          {React.cloneElement(subMenu, { setIsOpen })}
        </Box>
      )}
    </Flex>
  );
};

export default MenuItem;
