/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DistributionSetJsonApiModel = {
  id: "",
  name: "",
  memo: "",
  "amount-total": "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  status: "",
  "distribution-date": "",
  "effective-date": "",
  "reinvest-security": {
    jsonApi: "hasOne",
    type: "securities",
  },
  security: {
    jsonApi: "hasOne",
    type: "securities",
  },
  "method-name": "",
  "method-type": "",
  "pay-to-date": "",
  "lump-sum": "",
  "begin-date": "",
  "end-date": "",
  "amount-per-unit": "",
  "waterfall-instances": {
    jsonApi: "hasOne",
    type: "waterfall-instances",
  },
};
