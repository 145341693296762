import { E164Number } from "libphonenumber-js";
import { isValidPhoneNumber } from "../../../utils";

export const mapPhoneValueToPhoneInput = (
  phoneNumber: string,
  countryCode: string,
) => {
  return "+" + countryCode + phoneNumber;
};

export const mapPhoneInputValueToPhoneValue = (
  phoneInputValue: E164Number | undefined,
  countryCode: string,
) => {
  const noWithoutPlus = String(phoneInputValue || "").replace("+", "");

  if (noWithoutPlus.startsWith(countryCode)) {
    const noWithoutCountryCode = noWithoutPlus.substring(countryCode.length);
    return noWithoutCountryCode;
  }

  return noWithoutPlus;
};

export const validatePhoneNumber = (
  phoneNumber: string,
  phoneCountry: string,
) => {
  if (!phoneNumber) {
    return true;
  }

  const value = mapPhoneValueToPhoneInput(phoneNumber, phoneCountry);
  if (isValidPhoneNumber(value)) {
    return true;
  }

  return "Invalid phone number";
};
