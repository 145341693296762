/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const EntityOpportunityJsonApiModel = {
  id: "",
  entity: {
    jsonApi: "hasOne",
    type: "entities",
  },
  "entity-id": "",
  "manager-individual": {
    jsonApi: "hasOne",
    type: "individual-signers",
  },
  "manager-individual-id": "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  "opportunity-id": "",
  "entity-role": "",
  "created-at": "",
  "updated-at": "",
};
