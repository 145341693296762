import { Select } from "@chakra-ui/react";
import React from "react";
import { ChevronDownIcon } from "../../../icons";
import { FormControlWrapper } from "../FormControlWrapper";
import { VeriSelectProps } from "./VeriSelect.types";

const VeriSelect = React.forwardRef<HTMLSelectElement, VeriSelectProps>(
  (
    {
      children,
      error,
      name,
      selectProps,
      greyedOutOnReadOnly,
      isSmall,
      value,
      onChange,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControlWrapper error={error} {...rest}>
        <Select
          name={name}
          ref={ref}
          bg="white"
          icon={<ChevronDownIcon />}
          iconColor="icons.grey"
          fontFamily="Nunito Sans"
          fontSize="md"
          color="text.black"
          textStyle={isSmall ? "bodySmall" : undefined}
          onChange={onChange}
          {...selectProps}
          variant={isSmall ? "verivestSmall" : "verivest"}
          height="none"
          value={value}
          _readOnly={
            greyedOutOnReadOnly
              ? {
                  backgroundColor: "bg.grey",
                }
              : undefined
          }
          {...(error
            ? { borderColor: "semantic.error", borderWidth: 2 }
            : null)}
        >
          {children}
        </Select>
      </FormControlWrapper>
    );
  },
);

VeriSelect.displayName = "VeriSelect";

export default VeriSelect;
