/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ObjectSnapshotJsonApiModel = {
  id: "",
  "user-id": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "created-at": "",
  label: "",
  "object-id": "",
  data: "",
  metadata: "",
};
