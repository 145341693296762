/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImportLogJsonApiModel = {
  id: "",
  import: {
    jsonApi: "hasOne",
    type: "imports",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  uuid: "",
  status: "",
  description: "",
  metadata: "",
  "created-at": "",
};
