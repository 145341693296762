/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ChevronLeftIcon = createIcon({
  displayName: "ChevronLeftIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M15 18L9 12L15 6" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></g>)
});
export default ChevronLeftIcon;