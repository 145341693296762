import React from "react";
import { UseWorkerJobsState } from "./useWorkerJobs.types";
import { Box, Flex, Stack } from "@chakra-ui/react";
import { WorkerJobItem } from "./WorkerJobItem";

const WorkerJobsContext = React.createContext<UseWorkerJobsState>(
  {} as UseWorkerJobsState,
);

export const MAX_JOB_ITEMS_TO_SHOW = 2;

export const WorkerJobsProvider: React.FC = ({ children }) => {
  const [jobs, setJobs] = React.useState<UseWorkerJobsState["jobs"]>([]);

  const cancelJob = React.useCallback(
    (jobId: string) => {
      setJobs((prevJobs) => prevJobs.filter((job) => job.id !== jobId));
    },
    [jobs],
  );

  const completeJob = React.useCallback(
    (jobId: string) => {
      cancelJob(jobId);
    },
    [cancelJob],
  );

  return (
    <WorkerJobsContext.Provider
      value={{
        jobs,
        appendJob: (job) => {
          setJobs((_jobs) =>
            _jobs.find((_job) => job.id === _job.id) ? jobs : [...jobs, job],
          );
        },
      }}
    >
      {children}

      {jobs.length > 0 && (
        <Flex position={"fixed"} left={10} bottom={10} zIndex={"docked"}>
          <Stack spacing={4}>
            {jobs.map((job, jobIndex) => (
              <WorkerJobItem
                key={job.id}
                onCompleteJob={completeJob}
                onCancelJob={cancelJob}
                job={job}
                isHidden={jobIndex >= MAX_JOB_ITEMS_TO_SHOW}
              />
            ))}
            {jobs.length > MAX_JOB_ITEMS_TO_SHOW && (
              <Box>{jobs.length - MAX_JOB_ITEMS_TO_SHOW} more</Box>
            )}
          </Stack>
        </Flex>
      )}
    </WorkerJobsContext.Provider>
  );
};

export const useWorkerJobs = () => React.useContext(WorkerJobsContext);
