/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const SendManagerAgreementDocumentOutputJsonApiModel = {
  id: "",
  "envelope-summary": "",
  url: "",
};
