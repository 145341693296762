/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const TransferJsonApiModel = {
  id: "",
  "requested-by-user": {
    jsonApi: "hasOne",
    type: "users",
  },
  "requested-by-user-id": "",
  "from-holding": {
    jsonApi: "hasOne",
    type: "holdings",
  },
  "from-holding-id": "",
  "to-holding": {
    jsonApi: "hasOne",
    type: "holdings",
  },
  "to-holding-id": "",
  "to-account": {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "to-account-id": "",
  "effective-date": "",
  basis: "",
  amount: "",
  "transfer-percent": "",
  "created-at": "",
  "updated-at": "",
};
