import React from "react";

import { Box, Flex } from "@chakra-ui/react";
import { Button } from "../../../";
import { ConfirmBannerProps } from "./ConfirmBanner.types";

const ConfirmBanner: React.FC<ConfirmBannerProps> = ({
  message,
  onClose,
  onConfirm,
  confirmProps,
  cancelProps,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    if (onConfirm) {
      setIsLoading(true);
      await onConfirm();
      setIsLoading(false);
    }
  };

  return (
    <Flex
      position={"absolute"}
      left={0}
      right={0}
      top={0}
      bottom={0}
      bg={"rgba(0,0,0,0.8)"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      color={"text.white"}
      px={6}
      textStyle={"bodyRegular"}
    >
      <Box mr={10}>{message}</Box>
      <Button
        mr={2}
        onClick={handleConfirm}
        label={confirmLabel}
        isLoading={isLoading}
        {...confirmProps}
      />
      <Button
        label={cancelLabel}
        onClick={onClose}
        isFlat
        isDisabled={isLoading}
        {...cancelProps}
      />
    </Flex>
  );
};

export default ConfirmBanner;
