/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImportJsonApiModel = {
  id: "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  document: {
    jsonApi: "hasOne",
    type: "documents",
  },
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "import-type": "",
  status: "",
  settings: {
    type: "import-settings",
  },
  errors: "",
  "errors-count": "",
  "created-at": "",
  "updated-at": "",
};
