/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ManagerReviewJsonApiModel = {
  id: "",
  user: {
    jsonApi: "hasOne",
    type: "review-users",
  },
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "parent-review": {
    jsonApi: "hasOne",
    type: "manager-reviews",
  },
  update: {
    jsonApi: "hasOne",
    type: "manager-reviews",
  },
  "is-anonymous": "",
  rating: "",
  review: "",
  "manager-reply": "",
  "manager-reply-status": "",
  "review-type": "",
  status: "",
  "created-at": "",
  "updated-at": "",
  "manager-reply-at": "",
  "manager-reply-updated-at": "",
  "investor-verified": "",
  "has-update": "",
};
