import { asyncComponent } from "@jaredpalmer/after";
import {
  investorPortalRoutes,
  investorPortalWhiteLabelRoutes,
  legacyInvestorPortalRoutes,
  marketingWebsiteRoutes,
  SponsorOnboardingRoutes,
} from "@verivest/shared-components";
import { RouteLoading as Placeholder } from "@verivest/shared-components/src/components/common/loaders/RouteLoading";
import { authRoutes } from "@verivest/shared-components/src/defines/routes/auth";
import asyncComponentAuthenticated from "@verivest/shared-components/src/utils/asyncComponentAuthenticated";
import InvestorPortalPageNotFound from "../pages/investor-portal/InvestorPortalPageNotFound";
import { createAccountRoutes } from "../pages/investor-portal/wizards/account/createAccountRoutes";
import { editAccountInvestorBaseRoute } from "../routes/account/editAccountWizardRoutes";

const investorPortalAuthRoutes = [
  {
    path: authRoutes.AUTH,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "investor-portal-auth" */ "../pages/auth/InvestorPortalAuth"
        ),
      Placeholder,
      chunkName: "investor-portal-auth",
    }),
  },
];

const marketingPlaceholderRoutes = [
  {
    path: marketingWebsiteRoutes.homepage,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-home" */ "../pages/investor-portal-3/HomePageRoute"
        ),
      Placeholder,
      chunkName: "marketing-home",
    }),
  },
  {
    path: marketingWebsiteRoutes.pricing,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-pricing" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-pricing",
    }),
  },
  {
    path: marketingWebsiteRoutes.forManagers,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-for-managers" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-for-managers",
    }),
  },
  {
    path: marketingWebsiteRoutes.forInvestors,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-for-investors" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-for-investors",
    }),
  },
  {
    path: marketingWebsiteRoutes.pitchSlam2023,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-pitch-slam" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-pitch-slam",
    }),
  },
  {
    path: marketingWebsiteRoutes.terms,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-terms" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-terms",
    }),
  },
  {
    path: marketingWebsiteRoutes.privacy,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-privacy" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-privacy",
    }),
  },
  {
    path: marketingWebsiteRoutes.investorPortal,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-investorPortal" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-investorPortal",
    }),
  },
  {
    path: marketingWebsiteRoutes.advisory,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-advisory" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-advisory",
    }),
  },
  {
    path: marketingWebsiteRoutes.managerdash,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-managerdash" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-managerdash",
    }),
  },
  {
    path: marketingWebsiteRoutes.admin,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-admin" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-admin",
    }),
  },
  {
    path: marketingWebsiteRoutes.fundbuilder,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-fundbuilder" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-fundbuilder",
    }),
  },
  {
    path: marketingWebsiteRoutes.fundAdmin,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "marketing-2023-fundAdmin" */ "../pages/investor-portal/EmptyRouteForMarketingWebsite"
        ),
      Placeholder,
      chunkName: "marketing-2023-fundAdmin",
    }),
  },
];

const legacySupportRoutes = [
  {
    path: legacyInvestorPortalRoutes.findSponsors,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "verivest-fallback-redirect" */ "../pages/investor-portal/RedirectToInvestorPortalHomepage"
        ),
      Placeholder,
      chunkName: "verivest-fallback-redirect",
    }),
  },
  {
    path: legacyInvestorPortalRoutes.dashboard,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboardPage" */ "../pages/investor-portal/RedirectToInvestorPortalHomepage"
        ),
      Placeholder,
      chunkName: "dashboardPage",
    }),
  },
];

const privateRoutes = [
  {
    path: investorPortalRoutes.userSettings,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "user-settings" */ "../pages/investor-portal/investor-portal-user-settings/InvestorPortalUserSettings"
        ),
      Placeholder,
      chunkName: "user-settings",
    }),
  },
  {
    path: investorPortalRoutes.accountDetails,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "investor-accountsSinglePage" */ "../pages/investor-portal/accounts/AccountDetails"
        ),
      Placeholder,
      chunkName: "investor-accountsSinglePage",
    }),
  },
  {
    path: "/w/accounts/:id/documents",
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "investor-accountsDocumentsPage" */ "../pages/investor-portal/accounts/AccountDocuments"
        ),
      Placeholder,
      chunkName: "investor-accountsDocumentsPage",
    }),
  },

  {
    path: "/w/onboarding/:type?/:extra?",
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "investor-onboardingWizard" */ "../pages/investor-portal/onboarding"
        ),
      Placeholder,
      chunkName: "investor-onboardingWizard",
    }),
  },
  {
    path: "/w/create/investment/:id",
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "investor-create-investment" */ "../pages/investor-portal/wizards/investment/CreateInvestment"
        ),
      Placeholder,
      chunkName: "investor-create-investment",
    }),
  },
  {
    path: createAccountRoutes.base,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "investor-accountsWizard" */ "../pages/investor-portal/wizards/account/Create"
        ),
      Placeholder,
      chunkName: "investor-accountsWizard",
    }),
  },
  {
    path: editAccountInvestorBaseRoute,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "edit-accountsWizard" */ "../pages/investor-portal/wizards/account/EditAccount"
        ),
      Placeholder,
      chunkName: "edit-accountsWizard",
    }),
  },
  {
    path: "/w/investment/:id?",
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "investor-investmentWizard" */ "../pages/investor-portal/wizards/investment/InvestmentWizard"
        ),
      Placeholder,
      chunkName: "investor-investmentWizard",
      appendBackUrl: true,
    }),
  },
  /* Sponsor onboarding */
  {
    path: SponsorOnboardingRoutes.GET_VERIFIED,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-onboardingWizard-get-verified" */ "../pages/investor-portal/wizards/sponsor/get-verified-wizard/GetVerifiedWizard"
        ),
      Placeholder,
      chunkName: "manager-onboardingWizard-get-verified",
      appendBackUrl: true,
    }),
  },
  {
    path: investorPortalRoutes.fundBuilder,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "fund-builder-wizard" */ "../pages/investor-portal/wizards/fund-builder"
        ),
      Placeholder,
      chunkName: "fund-builder-wizard",
      appendBackUrl: true,
    }),
  },
  {
    path: investorPortalWhiteLabelRoutes.whiteLabel,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "investor-managerPage" */ "../pages/investor-portal-3/white-label/ManagerWhiteLabelRoutes"
        ),
      chunkName: "investor-managerPage",
      Placeholder,
    }),
  },
];

const publicRoutes = [
  {
    path: investorPortalRoutes.login,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "loginPage" */ "../pages/investor-portal/auth/Login"
        ),
      Placeholder,
      chunkName: "loginPage",
    }),
  },
  {
    path: investorPortalRoutes.getStarted,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "getStarted" */ "../pages/investor-portal/get-started-flow/GetStarted"
        ),
      Placeholder,
      chunkName: "getStarted",
    }),
  },
  {
    path: investorPortalRoutes.selectManagerAndCreateFundBuilder,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "empty-page-to-create-fund-builder" */ "../pages/investor-portal/get-started-flow/SelectManagerAndCreateFundBuilder"
        ),
      Placeholder,
      chunkName: "empty-page-to-create-fund-builder",
    }),
  },
  {
    path: investorPortalRoutes.docusign,
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "docusignPage" */ "../pages/investor-portal/Docusign"
        ),
      Placeholder,
      chunkName: "docusignPage",
    }),
  },
];

export const investorPortalPages = [
  ...publicRoutes,
  ...investorPortalAuthRoutes,
  ...marketingPlaceholderRoutes,
  ...legacySupportRoutes,
  ...privateRoutes,
  {
    // there is no need to declare path variable
    // react router will pick this component as fallback
    component: InvestorPortalPageNotFound,
  },
];
