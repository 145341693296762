import { Box } from "@chakra-ui/react";
import React from "react";

const IconButton = ({
  onClick,
  children,
  isCircle,
  isGrey,
  isActive,
  isGhost,
  ...rest
}) => {
  return (
    <Box
      as="button"
      p={isGhost ? 1 : 3}
      justifyContent="center"
      onClick={onClick}
      border={isGhost ? undefined : "1px"}
      focusBorderColor={isGhost ? undefined : "brand.main"}
      height="none"
      borderRadius={isCircle ? "100%" : 4}
      borderColor={
        isGhost
          ? "transparent"
          : isGrey
          ? "border.grey"
          : isActive
          ? "brand.main"
          : "border.input"
      }
      _hover={isGhost ? {} : { borderColor: "brand.main" }}
      cursor="pointer"
      bg={isGhost ? "transparent" : isGrey ? "border.grey" : "bg.white"}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default IconButton;
