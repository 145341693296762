import {
  DrawerProps,
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
  ModalOverlayProps,
} from "@chakra-ui/react";
import React from "react";
import { MessageBoxProps } from "../../common";

export interface VeriDrawerProps extends DrawerProps {
  overlayProps?: ModalOverlayProps;
  drawerContentProps?: ModalContentProps;
  drawerBodyProps?: ModalBodyProps;
  drawerFooterProps?: ModalFooterProps;
  footer?: React.ReactNode;
  title?: React.ReactNode;
  primaryButton?: (size?: string) => React.ReactNode;
  secondaryButton?: (size?: string) => React.ReactNode;
  noHeaderShadow?: boolean;
  modalAsDrawer?: boolean;
  noAnimation?: boolean;
  backTitle?: string;
  backAction?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  message?: React.ReactNode;
  messageType?: MessageBoxProps["type"];
  messageTitle?: React.ReactNode;
  isLoading?: boolean;
  otherOpenDrawersCount?: number;
  hideCloseButton?: boolean;
  isModal?: boolean;
}

export enum FilterValueType {
  ARRAY = "array",
  BOOLEAN = "bool",
  STRING = "string",
}
