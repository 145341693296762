import { useToast, UseToastOptions } from "@chakra-ui/react";

export const useToastError = () => {
  const toast = useToast();
  return (message: string, options?: UseToastOptions) => {
    toast({
      title: message,
      status: "error",
      duration: 3000,
      isClosable: true,
      ...options,
    });
  };
};
