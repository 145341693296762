import dasherize from "dasherize";
import { useContext } from "react";
import useSWR, { SWRConfiguration } from "swr";
import { Dict } from "../../defines";
import { buildRouteUrl } from "../../utils";
import ApiClient from "../ApiClient";
import {
  JsonApiManyResponse,
  JsonApiSingleResponse,
  ModelScope,
} from "../types";
import { UseModelContext } from "./contexts/useModelContext";
import useApi from "./useApi";
import usePlacementsApi from "./usePlacementsApi";

export type ApiModelHandler = ApiClient;

export interface UseModelOptions<Data, TError = unknown> {
  id?: string | number;
  usePlacement?: boolean;
  url?: string;
  urlParams?: Dict;
  queryParams?: Dict;
  urls?: {
    [key in ModelScope]?: {
      getOne?: string;
      getMany?: string;
      create?: string;
      update?: string;
      delete?: string;
    };
  };
  useSwr?: SWRConfiguration<JsonApiSingleResponse<Data>, TError> | boolean;
  useSwrMany?: SWRConfiguration<JsonApiManyResponse<Data>, TError> | boolean;
  scope?: ModelScope;
  modelScope?: ModelScope;
  forcePlacement?: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
    getOne?: boolean;
    getMany?: boolean;
  };
}

export const useModel = <
  Data extends { id?: string | number | null },
  UrlParams = unknown,
  TError = unknown,
>(
  type: string,
  options?: UseModelOptions<Data, TError>,
) => {
  const _defaults = useContext(UseModelContext);

  const opts = { ..._defaults?.defaults, ...options } as UseModelOptions<
    Data,
    TError
  >;

  const {
    usePlacement,
    urls: scopedUrls,
    url,
    useSwr,
    useSwrMany,
    id,
    urlParams,
    scope: _scope,
  } = opts || {};

  const scope = _scope || ModelScope.Public;

  const urls =
    scope && scopedUrls && scopedUrls[scope]
      ? scopedUrls[scope]
      : scopedUrls?.[ModelScope.Public];
  const getOneApiUrl = urls?.getOne || url;
  const getManyApiUrl = urls?.getMany || url;
  const createUrl = urls?.create || url;
  const updateUrl = urls?.update || url;

  const api = useApi();
  const placementsApi = usePlacementsApi();
  const apiHandler = usePlacement ? placementsApi : api;

  const swr = useSWR<JsonApiSingleResponse<Data>, TError>(
    useSwr && getOneApiUrl
      ? buildRouteUrl(
          getOneApiUrl,
          { id: String(id), ...urlParams },
          opts?.queryParams,
        )
      : null,
    useSwr && getOneApiUrl ? apiHandler.apiRequest : null,
    useSwr && typeof useSwr === "object" && getOneApiUrl
      ? { focusThrottleInterval: 0, ...useSwr }
      : undefined,
  );

  const swrMany = useSWR<JsonApiManyResponse<Data>>(
    useSwrMany && getManyApiUrl
      ? buildRouteUrl(
          getManyApiUrl,
          { id: String(id), ...urlParams },
          opts?.queryParams,
        )
      : null,
    useSwrMany && getManyApiUrl ? apiHandler.apiRequest : null,
    useSwrMany && typeof useSwrMany === "object" && getManyApiUrl
      ? { focusThrottleInterval: 0, ...useSwrMany }
      : undefined,
  );

  if (scope && !scopedUrls) {
    throw new Error(`Scope ${scope} for ${type} is not supported`);
  }

  const getOne = async (id: number | string, params?: UrlParams) => {
    if (!getOneApiUrl) {
      throw new Error(`No getOne url provided for ${scope}`);
    }
    return await apiHandler.get<JsonApiSingleResponse<Data>>(
      buildRouteUrl(
        getOneApiUrl,
        { id: String(id), ...urlParams, ...params },
        opts?.queryParams,
      ),
    );
  };

  const getMany = async (params?: UrlParams, queryParams?: Dict) => {
    const apiUrl = urls?.getMany || url;
    if (!apiUrl) {
      throw new Error(`No getMany url provided for ${scope}`);
    }
    return await apiHandler.get<JsonApiManyResponse<Data>>(
      buildRouteUrl(
        apiUrl,
        { ...urlParams, ...params },
        { ...queryParams, ...opts?.queryParams },
      ),
    );
  };

  const create = async (data: Data, params?: UrlParams) => {
    if (!type) {
      throw new Error("No type provided");
    }
    if (!createUrl) {
      throw new Error(`No creation url provided for ${scope}`);
    }
    const _apiHandler = opts?.forcePlacement?.create
      ? placementsApi
      : apiHandler;

    const result = (await _apiHandler.post(
      buildRouteUrl(createUrl, { ...urlParams, ...params }, opts?.queryParams),
      {
        data: api.serializeResource(type, dasherize(data)),
      },
      true,
    )) as any;

    return result.data || {};

    /*
    return await _apiHandler.createResource<Data>(
      type,
      dasherize(data),
      params,
    );*/
  };

  const createWithIncluded = async (
    data: Data,
    included: Dict[],
    params?: UrlParams,
  ) => {
    if (!type) {
      throw new Error("No type provided");
    }
    if (!createUrl) {
      throw new Error(`No creation url provided for ${scope}`);
    }
    const result = (await apiHandler.post(
      buildRouteUrl(createUrl, { ...urlParams, ...params }, opts?.queryParams),
      {
        data: api.serializeResource(type, dasherize(data)),
        included: dasherize(included),
      },
      true,
    )) as any;

    return result.data;
  };

  const updateWithIncluded = async (
    id: string | number,
    data: Data,
    included: Dict[],
    params?: UrlParams,
    preloadExisting = true,
  ) => {
    if (!type) {
      throw new Error("No type provided");
    }
    if (!updateUrl) {
      throw new Error(`No update url provided for ${scope}`);
    }

    const currentData = preloadExisting ? await getOne(String(id)) : undefined;

    return await apiHandler.patch(
      buildRouteUrl(
        updateUrl,
        { id: String(id), ...urlParams, ...params },
        opts?.queryParams,
      ),
      {
        data: api.serializeResource(
          type,
          dasherize({
            id: String(id),
            ...currentData?.data,
            ...data,
          }),
        ),
        included: dasherize(included),
      },
      true,
    );
  };

  const update = async (
    id: string | number,
    data: Data,
    params?: UrlParams,
    preloadExisting = true,
  ) => {
    if (!type) {
      throw new Error("No type provided");
    }
    if (!id) {
      throw new Error("No id provided");
    }

    const currentData = preloadExisting ? await getOne(String(id)) : undefined;

    return await apiHandler.updateResource<Data>(
      type,
      dasherize({
        id: String(id),
        ...currentData?.data,
        ...data,
      }),
      params,
    );
  };

  const deleteRecord = async (id: string | number) => {
    const deleteUrl = urls?.delete || url;

    if (!deleteUrl) {
      throw new Error(`No delete url provided for ${scope}`);
    }
    if (!type) {
      throw new Error("No type provided");
    }
    if (!id) {
      throw new Error("No id provided");
    }
    return await apiHandler.apiRequest<void>(
      buildRouteUrl(deleteUrl, { id: String(id) }, opts?.queryParams),
      "DELETE",
    );
  };

  /*const isManyLoading = () =>
    !!swrMany?.data?.data && swrMany?.data?.data?.length > 0;*/

  const isManyLoading = swrMany.isValidating && !swrMany?.data?.data;

  const isLoading = !swr?.data?.data;

  const isLoaded = !!swr?.data?.data?.id && Number(swr.data?.data?.id) > 0;

  return {
    data: swr.data ? swr.data.data || null : undefined,
    records: swrMany.data?.data || [],
    isLoaded,
    isManyLoading,
    isLoading,
    getOne,
    getMany,
    create,
    createWithIncluded,
    updateWithIncluded,
    update,
    deleteRecord,
    swr,
    swrMany,
    error: swr.error || swrMany.error,
    handler: apiHandler,
    scope: scope,
    api,
    placementsApi,
  };
};
