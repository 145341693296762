/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateManualBillingEventOutputJsonApiModel = {
  id: "",
  "billing-event": {
    jsonApi: "hasOne",
    type: "billing-events",
  },
};
