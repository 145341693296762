import React from "react";
import { Box, Flex } from "@chakra-ui/react";

const SectionContainer = ({
  children,
  noBorder,
  noPadding,
  maxWidth = "1280px",
  innerProps,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Flex justifyContent="center">
        <Flex
          maxWidth={maxWidth}
          width="100%"
          mx={{ base: noPadding ? "0px" : "20px" }}
          borderBottomWidth={noBorder ? "" : "1px"}
          pb={noBorder ? "" : "48px"}
          borderBottomColor={noBorder ? "" : "border.input"}
          {...innerProps}
        >
          <Box width="100%">{children}</Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SectionContainer;
