/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ShareIcon = createIcon({
  displayName: "ShareIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 2V16" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M8 6L12 2L16 6" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M17 10H22V22H2V10H7" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default ShareIcon;