import { Box, Divider, Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { Button } from "../";
import { CustomHeadingProps } from "./CustomHeading.types";

const CustomHeading: React.FC<CustomHeadingProps> = ({
  children,
  subtitle = null,
  divider = false,
  as = "h2",
  dividerProps,
  actionComponent,
  actionName = "",
  actionProps,
  action,
  actions,
  extra,
  ...rest
}) => {
  const _actions = actions ? [...actions] : [];
  if (action && actionName) {
    _actions.push({
      label: actionName,
      callback: action,
    });
  }

  const rightAction =
    actionComponent ||
    (_actions && _actions.length > 0 && (
      <>
        {_actions.map((_action, actionIndex) => {
          const _button = (
            <Button
              key={actionIndex}
              onClick={_action.callback}
              isBold
              empty
              p={0}
              {...actionProps}
            >
              {_action.label}
            </Button>
          );

          if (_action.dropDownMenu) {
            return React.cloneElement(_action.dropDownMenu, {
              trigger: _button,
            });
          }

          return _button;
        })}
      </>
    ));

  return (
    <Box>
      <Box fontFamily="heading" fontWeight="bold" as={as} {...rest}>
        <Flex alignItems={"center"} flexWrap="wrap">
          <Box flexGrow={1}>{children}</Box>
          {rightAction && (
            <Stack
              direction={"row"}
              spacing={6}
              data-testid="heading-right-action"
            >
              {rightAction}
            </Stack>
          )}
        </Flex>
      </Box>
      {subtitle && (
        <Box
          textStyle="bodyRegular"
          color="text.grey"
          data-testid="heading-subtitle"
        >
          {subtitle}
        </Box>
      )}
      {extra && extra}
      {divider && (
        <Divider
          mb="16px"
          mt="8px"
          borderColor="border.divider"
          data-testid="heading-divider"
          {...dividerProps}
        />
      )}
    </Box>
  );
};

export default CustomHeading;
