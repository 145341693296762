import { Box, Flex } from "@chakra-ui/react";
import React from "react";

const VeriTableHeading = ({
  headingLabel,
  showTotalRecordsCount,
  totalRecordsCount,
}) => {
  if (!showTotalRecordsCount && !headingLabel) {
    return null;
  }

  return (
    <Flex p={4} w={"100%"} justifyContent={"space-between"}>
      <Box>{headingLabel}</Box>
      <Box fontWeight={"bold"}>
        {showTotalRecordsCount && `${totalRecordsCount} total`}
      </Box>
    </Flex>
  );
};

VeriTableHeading.defaultProps = {
  totalRecordsCount: 0,
};

export default VeriTableHeading;
