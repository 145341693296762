/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DistributionHoldingsJsonApiModel = {
  id: "",
  distribution: {
    jsonApi: "hasOne",
    type: "distributions",
  },
  holding: {
    jsonApi: "hasOne",
    type: "holdings",
  },
  amount: "",
};
