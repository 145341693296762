import DynamicConfigFlags from "../configs";
import useDynamicConfig from "./useDynamicConfig";

export default function useGetDynamicConfigManagerId() {
  const dynamicConfig = useDynamicConfig();
  const manager = dynamicConfig.get(DynamicConfigFlags.DASHBOARD_MANAGER);
  const managerId = manager?.id;

  return managerId as string;
}
