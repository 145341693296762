/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const PlaidItemJsonApiModel = {
  id: "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "plaid-item-id": "",
  "plaid-institution-id": "",
  "plaid-institution": {
    jsonApi: "hasOne",
    type: "plaid-institutions",
  },
  "plaid-accounts": {
    jsonApi: "hasMany",
    type: "plaid-accounts",
  },
  status: "",
};
