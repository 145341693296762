import React from "react";
import { Avatar as ChakraAvatar, Box, Flex } from "@chakra-ui/react";

const Avatar = ({ value, options }) => {
  return (
    <>
      {value ? (
        <Flex alignItems={"center"}>
          <ChakraAvatar
            width="32px"
            height="32px"
            name={value.name}
            src={value.picture}
            mr="16px"
          />
          {options.showName && <Box>{value.name}</Box>}
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default Avatar;
