/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ArrowLeftShortIcon = createIcon({
  displayName: "ArrowLeftShortIcon",
  viewBox: "0 0 25 25",
  path: (
    <g fill="none">
      <path
        d="M19.1393 13.0532C19.6916 13.0473 20.1344 12.5948 20.1284 12.0425C20.1225 11.4902 19.6699 11.0474 19.1177 11.0534L7.53258 11.1786L12.768 5.82878C13.1543 5.43406 13.1474 4.80093 12.7527 4.41465C12.358 4.02837 11.7248 4.03521 11.3386 4.42993L4.41461 11.5052C4.02833 11.8999 4.03517 12.533 4.42989 12.9193L11.5051 19.8432C11.8998 20.2295 12.533 20.2227 12.9193 19.828C13.3055 19.4332 13.2987 18.8001 12.904 18.4138L7.55419 13.1784L19.1393 13.0532Z"
        fill="currentColor"
      />
    </g>
  ),
});
export default ArrowLeftShortIcon;
