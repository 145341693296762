import { ApiModel, ApiModelOptions } from "../../../ApiModel";
import { WorkflowModel } from "../../../models";
import { WorkflowJobExecutionSummaryModel } from "../../../models/WorkflowJobExecutionSummary";
import { JsonApiSingleResponse } from "../../../types";

export function apiWorkflowModel(opts: ApiModelOptions<WorkflowModel>) {
  const model = ApiModel<WorkflowModel>("workflow", opts);

  const getWorkflowJobExecutionSummary = async (workflowId: string) => {
    const result = await model.handler.get<
      JsonApiSingleResponse<WorkflowJobExecutionSummaryModel>
    >(`/workflows/job/${workflowId}`);

    return result?.data;
  };

  return { ...model, getWorkflowJobExecutionSummary };
}
