import { Box, Text } from "@chakra-ui/react";
import QRCode from "qrcode.react";
import React from "react";
import useApi from "../../../../api/hooks/useApi";
import { useAuth } from "../../../../auth";
import { logError } from "../../../../error-handling/logError";
import { scrollToTop } from "../../../../utils";
import { MfaCodeInput } from "../../../auth";
import { useMfaModal } from "../context/MfaModalContext/MfaModalContext";
import { MfaModalStep } from "../context/MfaModalContext/MfaModalContext.types";
import { MFAModalView } from "./MFAModalView";

const MfaAppTypeConfigureMobileApp: React.FC = () => {
  const state = useMfaModal();

  const { auth } = useAuth();
  const [secretState, setSecretState] = React.useState(false);
  const api = useApi();
  const profile = auth.getProfile();

  const getSecret = async () => {
    // setIsLoading(true);
    try {
      const response = await api.apiAxiosRequest(
        "user/mfa/secret",
        "get",
        null,
        {},
        auth.getMFAToken() || auth.getToken() || "",
      );
      if (response.status === 200) {
        // setIsLoading(false);
        return setSecretState(response.data.secret);
      }
      logError(response);
    } catch (e) {
      logError(e);
    }
  };

  const verifyCode = async () => {
    state.setState({ isLoading: true, error: "" });
    const { verifyCode } = state;
    if (!verifyCode || verifyCode.length !== 6) {
      return state.setState({
        isLoading: false,
        error: "You need to enter the 6 digits code",
      });
    }
    const data = {
      code: verifyCode,
    };
    try {
      const response = await auth.mfaConfirmApp(data);
      if (response.status === 200) {
        return state.setState({
          step: MfaModalStep.success,
          isLoading: false,
        });
      }
      if (
        response.data &&
        response.data.errors &&
        response.data.errors.length > 0
      ) {
        return state.setState({
          error: response.data.errors[0].detail,
          isLoading: false,
        });
      }
    } catch (e) {
      logError(e);
    }
    return state.setState({ error: "Something went wrong", isLoading: false });
  };

  React.useEffect(() => {
    getSecret();
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MFAModalView
      title="Configure mobile app"
      subTitle="Complete the following steps to configure your authenticator app. This will allow you to generate verification codes on your phone."
      continueLabel="Confirm"
      onBack={() => state.setStep(MfaModalStep.start)}
      onContinue={verifyCode}
      continueDisabled={!state.verifyCode || state.verifyCode.length < 6}
    >
      <form>
        <Text as="ol" mb="16px" pl="32px">
          <li>
            On your phone, search the app store for an authenticator app (for
            example Microsoft or Google Authenticator).
          </li>
          <li>Download and install the app</li>
          <li>Add a new account in the app</li>
          <li>
            Select the option to scan a barcode and use your phone’s camera to
            scan the barcode below.
          </li>
        </Text>

        {secretState && (
          <Box data-private bg="white" p="20px" display="inline-block">
            <QRCode
              size={300}
              value={encodeURI(
                `otpauth://totp/Verivest:${
                  profile?.name || ""
                }@verivest.com?secret=${secretState}&issuer=Verivest`,
              )}
            />
          </Box>
        )}

        <Text lineHeight="24px" mt="16px" mb="32px">
          Enter the 6-digit verification code from the authenticator app to
          continue.
        </Text>

        <MfaCodeInput
          onComplete={(verifyCode: string) => state.setState({ verifyCode })}
          fieldWidth={{ base: "40px", md: "80px" }}
        />
      </form>
    </MFAModalView>
  );
};

export default MfaAppTypeConfigureMobileApp;
