import React from "react";
import { TwoWayFactorAuthenticationModalProps } from "./TwoWayFactorAuthenticationModal.types";
import { MfaModalProvider } from "./context/MfaModalContext/MfaModalContext";
import { MfaModalStep } from "./context/MfaModalContext/MfaModalContext.types";
import { VeriModal } from "../VeriModal";
import { MfaStepsContainer } from "./steps/MfaStepsContainer";

const TwoWayFactorAuthenticationModal: React.FC<
  TwoWayFactorAuthenticationModalProps
> = ({ onClose, isOpen, step = MfaModalStep.start }) => {
  return (
    <MfaModalProvider closeModal={onClose.bind(null)} initialStep={step}>
      <VeriModal isOpen={isOpen} onClose={onClose}>
        <MfaStepsContainer mt="38px" />
      </VeriModal>
    </MfaModalProvider>
  );
};

export default TwoWayFactorAuthenticationModal;
