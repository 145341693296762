import dasherize from "dasherize";
import humps from "humps";
import pluralize from "pluralize";
import { SWRConfiguration } from "swr/dist/types";
import { Dict } from "../defines";
import { buildRouteUrl } from "../utils";
import ApiClient from "./ApiClient";
import {
  JsonApiManyResponse,
  JsonApiSingleResponse,
  ModelScope,
  PaginationOptionsType,
} from "./types";

export interface ApiModelOptions<Data, TError = unknown> {
  id?: string | number;
  usePlacement?: boolean;
  url?: string;
  urlParams?: Dict;
  queryParams?: Dict;
  urls?: {
    [key in ModelScope]?: {
      getOne?: string;
      getMany?: string;
      create?: string;
      update?: string;
      delete?: string;
    };
  };
  useSwr?: SWRConfiguration<JsonApiSingleResponse<Data>, TError> | boolean;
  useSwrMany?: SWRConfiguration<JsonApiManyResponse<Data>, TError> | boolean;
  modelScope?: ModelScope;
  forcePlacement?: {
    create?: boolean;
    update?: boolean;
    delete?: boolean;
    getOne?: boolean;
    getMany?: boolean;
  };
  apiClient: ApiClient;
  placementsApiClient: ApiClient;
  replaceType?: string;
}

export const ApiModel = <
  Data extends { id?: string | number | null },
  UrlParams = unknown,
  TError = unknown,
>(
  type: string,
  options: ApiModelOptions<Data, TError>,
) => {
  const opts = { ...options } as ApiModelOptions<Data, TError>;

  const {
    usePlacement,
    urls: scopedUrls,
    url: _userDefinedUrl,
    urlParams,
    modelScope: _scope,
  } = opts || {};

  const scope = _scope || ModelScope.Public;

  const url = _userDefinedUrl || pluralize(type);

  const urls =
    scope && scopedUrls && scopedUrls[scope]
      ? scopedUrls[scope]
      : scopedUrls?.[ModelScope.Public];

  const getOneApiUrl = urls?.getOne || `${url}/:id`;
  const getManyApiUrl = urls?.getMany || url;
  const createUrl = urls?.create || url;
  const updateUrl = urls?.update || `${url}/:id`;

  const { apiClient, placementsApiClient } = opts;
  const apiHandler =
    usePlacement && placementsApiClient ? placementsApiClient : apiClient;

  const swr = {};

  const swrMany = {};

  const getOne = async (id: number | string, params?: UrlParams) => {
    if (!getOneApiUrl) {
      throw new Error(`No getOne url provided for ${scope}`);
    }
    return await apiHandler.get<JsonApiSingleResponse<Data>>(
      buildRouteUrl(
        getOneApiUrl,
        { id: String(id), ...urlParams, ...params },
        opts?.queryParams,
      ),
    );
  };

  const getMany = async (params?: UrlParams, queryParams?: Dict) => {
    const apiUrl = getManyApiUrl || url;

    const _apiHandler =
      opts?.forcePlacement?.getMany && placementsApiClient
        ? placementsApiClient
        : apiHandler;

    if (!apiUrl) {
      throw new Error(`No getMany url provided for ${scope}`);
    }
    return await _apiHandler.get<JsonApiManyResponse<Data>>(
      buildRouteUrl(
        apiUrl,
        { ...urlParams, ...params },
        { ...queryParams, ...opts?.queryParams },
      ),
    );
  };

  const getPagedMany = async (
    paginationOptions: PaginationOptionsType,
    params?: UrlParams,
    queryParams?: Dict,
    isJson?: boolean,
  ) => {
    const apiUrl = getManyApiUrl || url;

    const _apiHandler =
      opts?.forcePlacement?.getMany && placementsApiClient
        ? placementsApiClient
        : apiHandler;

    if (!apiUrl) {
      throw new Error(
        `No getMany ( on getPagedMany ) url provided for ${scope}`,
      );
    }

    const defaultResult = {
      data: [],
      meta: {
        total: 0,
        pageTotal: 0,
        pageNumber: 1,
        rowsTotal: 0,
        pageSize: 0,
      },
    };

    const requestUrl = buildRouteUrl(
      apiUrl,
      { ...urlParams, ...params },
      {
        "page[number]": paginationOptions?.page || 1,
        "page[size]": paginationOptions?.pageSize || 25,
        sort: `${paginationOptions?.sortDirection === "DESC" ? "-" : ""}${
          paginationOptions?.sort || "id"
        }`,
        ...queryParams,
        ...opts?.queryParams,
      },
    );

    if (isJson) {
      const response = await _apiHandler.request(requestUrl, "GET");

      return humps.camelizeKeys(
        (response?.data || defaultResult) as never,
      ) as unknown as JsonApiManyResponse<Data>;
    }

    return (
      (await _apiHandler.apiRequest<JsonApiManyResponse<Data>>(
        requestUrl,
        "GET",
      )) || defaultResult
    );
  };

  const create = async (data: Data, params?: UrlParams, isJson?: boolean) => {
    if (!createUrl) {
      throw new Error(`No creation url provided for ${scope}`);
    }
    const _apiHandler =
      opts?.forcePlacement?.create && placementsApiClient
        ? placementsApiClient
        : apiHandler;

    const jsonData = humps.decamelizeKeys(data);

    const result = (await _apiHandler.post(
      buildRouteUrl(createUrl, { ...urlParams, ...params }, opts?.queryParams),
      !type || isJson
        ? jsonData
        : {
            data: apiClient.serializeResource(
              opts?.replaceType || type,
              dasherize(data),
            ),
          },
      true,
    )) as JsonApiSingleResponse<Data>;

    return result.data || {};
  };

  const createWithIncluded = async (
    data: Data,
    included: Dict[],
    params?: UrlParams,
  ) => {
    if (!type) {
      throw new Error("No type provided");
    }
    if (!createUrl) {
      throw new Error(`No creation url provided for ${scope}`);
    }
    const result = (await apiHandler.post(
      buildRouteUrl(createUrl, { ...urlParams, ...params }, opts?.queryParams),
      {
        data: apiClient.serializeResource(type, dasherize(data)),
        included: dasherize(included),
      },
      true,
    )) as any;

    return result.data;
  };

  const updateWithIncluded = async (
    id: string | number,
    data: Data,
    included: Dict[],
    params?: UrlParams,
    preloadExisting = true,
  ) => {
    if (!type) {
      throw new Error("No type provided");
    }
    if (!updateUrl) {
      throw new Error(`No update url provided for ${scope}`);
    }

    const currentData = preloadExisting ? await getOne(String(id)) : undefined;

    return await apiHandler.patch(
      buildRouteUrl(
        updateUrl,
        { id: String(id), ...urlParams, ...params },
        opts?.queryParams,
      ),
      {
        data: apiClient.serializeResource(
          opts?.replaceType || type,
          dasherize({
            id: String(id),
            ...currentData?.data,
            ...data,
          }),
        ),
        included: dasherize(included),
      },
      true,
    );
  };

  const update = async (
    id: string | number,
    data: Data,
    params?: UrlParams,
    preloadExisting = true,
    isJson?: boolean,
    dasherizeData = true,
  ) => {
    if (!type) {
      throw new Error("No type provided");
    }
    if (!id) {
      throw new Error("No id provided");
    }

    const currentData = preloadExisting ? await getOne(String(id)) : undefined;

    const _apiHandler =
      opts?.forcePlacement?.update && placementsApiClient
        ? placementsApiClient
        : apiHandler;

    /*return await _apiHandler.updateResource<Data>(
      type,
      dasherize({
        id: String(id),
        ...currentData?.data,
        ...data,
      }),
      params,
    );*/
    const jsonData = humps.decamelizeKeys(data);
    return await _apiHandler.patch(
      buildRouteUrl(
        updateUrl,
        { id: String(id), ...urlParams, ...params },
        opts?.queryParams,
      ),
      isJson
        ? jsonData
        : {
            data: apiClient.serializeResource(
              opts?.replaceType || type,
              dasherizeData
                ? dasherize({
                    id: String(id),
                    ...currentData?.data,
                    ...data,
                  })
                : {
                    id: String(id),
                    ...currentData?.data,
                    ...data,
                  },
            ),
          },
      true,
    );
  };

  const updateJson = async (
    id: string | number,
    data: Data,
    params?: UrlParams,
    preloadExisting = true,
  ) => {
    return update(id, data, params, preloadExisting, true);
  };

  const updateWithUrl = async (
    id: string | number,
    data: Data,
    params?: UrlParams,
    preloadExisting = true,
  ) => {
    if (!type) {
      throw new Error("No type provided");
    }
    if (!id) {
      throw new Error("No id provided");
    }

    if (!updateUrl) {
      throw new Error(`No update url provided for ${scope}`);
    }
    const currentData = preloadExisting ? await getOne(String(id)) : undefined;

    return await apiHandler.patch(
      buildRouteUrl(
        updateUrl,
        { id: String(id), ...urlParams, ...params },
        opts?.queryParams,
      ),
      {
        data: apiClient.serializeResource(
          type,
          dasherize({
            id: String(id),
            ...currentData?.data,
            ...data,
          }),
        ),
        // TODO: Why this is needed? Variable not exists, investigate
        //included: serializedWithIncluded.included,
      },
    );
  };

  const deleteRecord = async (id: string | number) => {
    const deleteUrl = urls?.delete || `${url}/${id}`;

    if (!deleteUrl) {
      throw new Error(`No delete url provided for ${scope}`);
    }
    if (!type) {
      throw new Error("No type provided");
    }
    if (!id) {
      throw new Error("No id provided");
    }

    const _apiHandler =
      opts?.forcePlacement?.delete && placementsApiClient
        ? placementsApiClient
        : apiHandler;

    return await _apiHandler.apiRequest<void>(
      buildRouteUrl(deleteUrl, { id: String(id) }, opts?.queryParams),
      "DELETE",
    );
  };

  /*const isManyLoading = () =>
      !!swrMany?.data?.data && swrMany?.data?.data?.length > 0;*/

  const isManyLoading = false;
  const isLoading = false;
  const isLoaded = false;

  return {
    data: undefined as Data | undefined,
    records: [] as Data[],
    isLoaded,
    isManyLoading,
    isLoading,
    getOne,
    getMany,
    getPagedMany,
    create,
    createWithIncluded,
    updateWithIncluded,
    updateWithUrl,
    update,
    updateJson,
    deleteRecord,
    swr,
    swrMany,
    error: undefined,
    handler: apiHandler,
    scope: scope,
    apiClient,
    placementsApiClient,
    type,
    options: opts,
  };
};
