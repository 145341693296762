import React from "react";
import CustomHeading from "../../general/CustomHeading";
import { Box } from "@chakra-ui/react";

const Card = ({
  children,
  title,
  action,
  actions,
  actionName,
  isGhost,
  extra,
  actionComponent,
  headingProps,
  ...rest
}) => {
  return (
    <Box
      borderRadius={isGhost ? 0 : "4px"}
      border={isGhost ? "none" : "1px solid"}
      borderColor={isGhost ? "" : "border.grey"}
      boxShadow={!isGhost ? 1 : null}
      background={isGhost ? "transparent" : "#fff"}
      p={isGhost ? 0 : 6}
      {...rest}
    >
      {title && (
        <CustomHeading
          textStyle={"h5"}
          action={action}
          actionName={actionName}
          actions={actions}
          actionProps={{
            textProps: { textStyle: "bodyRegularBold" },
          }}
          divider
          extra={extra}
          actionComponent={actionComponent}
          {...headingProps}
        >
          {title}
        </CustomHeading>
      )}
      <Box>{children}</Box>
    </Box>
  );
};

export default Card;
