/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const CheckCircleIcon = createIcon({
  displayName: "CheckCircleIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M22 11.08V12C21.9988 14.1564 21.3005 16.2547 20.0093 17.9818C18.7182 19.709 16.9033 20.9725 14.8354 21.5839C12.7674 22.1953 10.5573 22.1219 8.53446 21.3746C6.51168 20.6273 4.78465 19.2461 3.61095 17.4371C2.43726 15.628 1.87979 13.4881 2.02167 11.3363C2.16356 9.18456 2.9972 7.13633 4.39827 5.49707C5.79935 3.85782 7.69278 2.71538 9.79618 2.24015C11.8996 1.76491 14.1003 1.98234 16.07 2.86" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /><path d="M22 4L12 14.01L9 11.01" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></g>)
});
export default CheckCircleIcon;