/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const HeartEmptyIcon = createIcon({
  displayName: "HeartEmptyIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><g clipPath="url(#clip0_142_6)"><path d="M21.243 3.761C20.1178 2.63617 18.592 2.00428 17.001 2.00428C15.41 2.00428 13.8842 2.63617 12.759 3.761C12.4759 4.04449 12.2217 4.35534 12 4.689C11.538 3.98733 10.9339 3.39049 10.2266 2.93712C9.51941 2.48374 8.72484 2.18394 7.89438 2.05711C7.06393 1.93029 6.21609 1.97927 5.40577 2.20089C4.59544 2.4225 3.84069 2.81181 3.19036 3.34362C2.54003 3.87542 2.00863 4.53787 1.63055 5.28806C1.25248 6.03826 1.03615 6.8595 0.995583 7.6986C0.955015 8.5377 1.09111 9.37598 1.39505 10.1591C1.69899 10.9423 2.16401 11.6529 2.76 12.245L12 21.486L21.241 12.245C22.3655 11.1199 22.9972 9.59424 22.9972 8.0035C22.9972 6.41276 22.3655 4.88713 21.241 3.762L21.243 3.761Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g><defs><clipPath id="clip0_142_6"><rect width={24} height={24} fill="currentColor" /></clipPath></defs></g>)
});
export default HeartEmptyIcon;