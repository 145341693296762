import humps from "humps";
import { NotificationsResponse } from "../../../../components/notification/notifications.types";
import { ModelId } from "../../../../defines";
import { ServerError } from "../../../../defines/types";
import { ApiModel, ApiModelOptions } from "../../../ApiModel";
import { JsonApiMeta, JsonApiSingleResponse } from "../../../types";

export default function apiNotificationsModel(
  opts: ApiModelOptions<NotificationsResponse>,
) {
  const model = ApiModel<NotificationsResponse>("notifications", {
    url: "/",
    ...opts,
  });

  const getPagedNotifications = async (
    url: string,
  ): Promise<{
    data: NotificationsResponse;
    meta: JsonApiMeta;
    error?: ServerError;
  }> => {
    try {
      const res = await model.apiClient.get<
        JsonApiSingleResponse<NotificationsResponse>
      >("/" + url);
      const data = humps.camelizeKeys(res.data);
      const meta = humps.camelizeKeys(res.meta) as JsonApiMeta;
      return { data, meta };
    } catch (e: ServerError) {
      return e;
    }
  };

  const markAsViewedEndpoint = "/notifications/mark-viewed";
  const markAllAsViewed = async () => {
    await model.apiClient.post(markAsViewedEndpoint, {
      mark_all_as_viewed: true,
    });
  };

  const markSomeAsViewed = async (ids: ModelId[]) => {
    await model.apiClient.post(markAsViewedEndpoint, {
      notification_ids: ids,
      mark_all_as_viewed: false,
    });
  };

  const markOneAsViewed = async (id: ModelId) => {
    await markSomeAsViewed([id]);
  };

  return {
    ...model,
    getPagedNotifications,
    markOneAsViewed,
    markAllAsViewed,
  };
}
