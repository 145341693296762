import React from "react";

export enum FeatureName {
  WhiteLabelBilling = "WHITELABEL_BILLING",
  NetworkBilling = "NETWORK_BILLING",
  CentralizedInvoicing = "CENTRALIZED_INVOICING",
  WorkflowJobs = "WORKFLOW_JOBS",
  ImportContacts = "IMPORT_CONTACTS",
  Importer = "IMPORTER",
}

export interface FeatureDefinitions {
  [FeatureName.WhiteLabelBilling]: boolean;
  [FeatureName.NetworkBilling]: boolean;
  [FeatureName.CentralizedInvoicing]: boolean;
  [FeatureName.WorkflowJobs]: boolean;
  [FeatureName.ImportContacts]: boolean;
  [FeatureName.Importer]: boolean;
}

interface ToggledFeatureDefinition {
  featureName: keyof FeatureDefinitions;
  featureValue?: FeatureDefinitions[keyof FeatureDefinitions];
}

export type UseGetFeatureToggleProps = ToggledFeatureDefinition;
export interface FeatureToggleProps extends ToggledFeatureDefinition {
  fallback?: React.ReactNode;
}
