/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DocumentJsonApiModel = {
  id: "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  statement: {
    jsonApi: "hasOne",
    type: "statements",
  },
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  tags: {
    jsonApi: "hasMany",
    type: "tags",
  },
  "document-tags": {
    jsonApi: "hasMany",
    type: "document-tag",
  },
  filename: "",
  filepath: "",
  filetype: "",
  "mime-type": "",
  filesize: "",
  "content-date": "",
  "delete-restricted": "",
  "created-at": "",
};
