/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UserSelectionsJsonApiModel = {
  id: "",
  "save-type": "",
  "opportunity-id": "",
  "manager-id": "",
};
