/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const TrackRecordJsonApiModel = {
  id: "",
  "total-cost": "",
  "inception-date": "",
  "realization-date": "",
  "total-proceeds": "",
  "realized-irr": "",
  "realized-equity-multiple": "",
  "verified-total-cost": "",
  "verified-inception-date": "",
  "verified-realization-date": "",
  "verified-total-proceeds": "",
  "verified-realized-irr": "",
  "verified-realized-equity-multiple": "",
  "opportunity-tag": "",
  "manager-id": "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "manager-slug": "",
  "image-url": "",
  "investment-name": "",
  "investment-type": "",
  status: "",
  "created-at": "",
  "updated-at": "",
};
