import {
  Box,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { VeriSpinner } from "@verivest/shared-components/src/components/common/loaders/VeriSpinner";
import { logError } from "@verivest/shared-components/src/error-handling/logError";
import React from "react";
import { scrollToTop } from "../../../utils/utils";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Button,
  MoreVertical,
  PaginationBoxView,
} from "../index";
import ConfirmModal from "../Modals/ConfirmModal";
import PopoverEdit from "../PopoverEdit";
import { Table, Tbody, Td, Th, Thead, Tr } from "../Table";
import MenuItem from "../VeriDropMenu/MenuItem";
import MenuList from "../VeriDropMenu/MenuList";
import VeriDropMenu from "../VeriDropMenu/VeriDropMenu";
import VeriTableHeading from "./VeriTableHeading";

const VeriTableStyles = {
  unstyled: {
    table: {
      my: 8,
    },
    bulkActionsBar: {
      backgroundColor: "bg.white",
      borderTopRadius: "4px",
    },
  },
  unstyledRegular: {
    table: {
      my: 8,
    },
    td: {
      textStyle: "bodyRegular",
      fontSize: "16px!important",
    },
    footerRecord: {
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: "border.grey",
      backgroundColor: "bg.grey",
      px: 2,
      py: 4,
      textStyle: "bodyRegularBold",
      color: "text.black",
      fontWeight: "bold!important",
    },
  },
  contained: {
    wrapper: {
      boxShadow:
        "0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "border.grey",
      backgroundColor: "bg.white",
      borderRadius: "4px",
    },
    table: {
      p: 0,
      m: 0,
    },
    selectedRow: {
      backgroundColor: "bg.lightestPurple",
    },
    bulkActionsBar: {
      backgroundColor: "bg.white",
      borderTopRadius: "4px",
    },
    th: {
      py: 5,
      textStyle: "bodyRegular",
    },
    td: {
      textStyle: "bodyRegular",
    },
  },
};

const sortingRecordsByColumnName = (column, direction, records, renderer) => {
  if (renderer?.prototype?.sort) {
    return renderer?.prototype?.sort(column, direction, records);
  }

  if (column) {
    return records.sort((a, b) =>
      a[column] > b[column]
        ? direction === "ASC"
          ? 1
          : -1
        : a[column] === b[column]
        ? 0
        : direction === "ASC"
        ? -1
        : 1,
    );
  }

  return records;
};

const ConfirmBanner = ({
  message,
  onClose,
  onConfirm,
  confirmProps,
  cancelProps,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    if (onConfirm) {
      setIsLoading(true);
      await onConfirm();
      setIsLoading(false);
    }
  };

  return (
    <Flex
      position={"absolute"}
      left={0}
      right={0}
      top={0}
      bottom={0}
      bg={"rgba(0,0,0,0.8)"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      color={"text.white"}
      px={6}
      textStyle={"bodyRegular"}
    >
      <Box mr={10}>{message}</Box>
      <Button
        mr={2}
        onClick={handleConfirm}
        label={confirmLabel}
        isLoading={isLoading}
        {...confirmProps}
      />
      <Button
        label={cancelLabel}
        onClick={onClose}
        isFlat
        isDisabled={isLoading}
        {...cancelProps}
      />
    </Flex>
  );
};

const VeriTableHead = React.forwardRef(
  (
    {
      tHeadProps,
      tableStyle,
      columnNames,
      allowBulkSelection,
      tableObject,
      hasRowActions,
      sortable,
      columns,
    },
    ref,
  ) => {
    const [hoverColumn, setHoverColumn] = React.useState(null);

    return (
      <Thead {...tHeadProps} ref={ref}>
        <Tr>
          {allowBulkSelection && (
            <Th
              key={"column-bulk-selection"}
              {...(tableStyle.th || {})}
              data-testid="table-bulk-select"
            >
              <Checkbox
                isIndeterminate={tableObject.isIndeterminateSelection}
                isChecked={tableObject.isAllRecordsSelected}
                onChange={tableObject.toggleAllSelection}
                size={"lg"}
                colorScheme={"brand"}
              />
            </Th>
          )}
          {columnNames.map((columnName, columnIndex) => {
            const column = columns[columnName];

            const isSortable =
              (sortable && column.sortable !== false) || !!column.sortable;

            const isSorted =
              isSortable && tableObject.sortingColumn === columnName;
            const isSortedDesc = tableObject.sortingDirection === "DESC";

            return (
              <Th
                key={`${columnName}-${columnIndex}`}
                pl={!columnIndex && allowBulkSelection ? 0 : undefined}
                {...(tableStyle.th || {})}
                {...(isSortable && {
                  _hover: {
                    cursor: "pointer",
                  },
                  onMouseEnter: () => setHoverColumn(columnName),
                  onMouseLeave: () => setHoverColumn(null),
                  onClick: () => {
                    if (isSorted) {
                      tableObject.setSortingDirection(
                        isSortedDesc ? "ASC" : "DESC",
                      );
                    } else {
                      tableObject.setSortingDirection("ASC");
                      tableObject.setSortingColumn(columnName);
                    }
                  },
                })}
                pos={"relative"}
              >
                <Text
                  as="div"
                  display={"flex"}
                  textAlign={column.columnAlign || "left"}
                  justifyContent={
                    (column.columnAlign === "right"
                      ? "flex-end"
                      : column.columnAlign) || "flex-start"
                  }
                  textStyle="bodyRegularBold"
                >
                  {column?.label}
                  {isSortable && (
                    <Box
                      px={2}
                      backgroundColor={"#fff"}
                      display={"inline-flex"}
                      visibility={
                        isSorted || hoverColumn === columnName
                          ? "visible"
                          : "hidden"
                      }
                      alignItems={"center"}
                    >
                      {!isSortedDesc ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Box>
                  )}
                </Text>
              </Th>
            );
          })}
          {hasRowActions && <Th {...(tableStyle.th || {})} />}
        </Tr>
      </Thead>
    );
  },
);
VeriTableHead.displayName = "VeriTableHead";

const InlineActionItem = ({
  action,
  isEnabled,
  actionName,
  recordIndex,
  recordIdentifier,
  tableObject,
  record,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const _doAction = async () =>
    action.callback &&
    (await action.callback([recordIdentifier], [record], tableObject));

  const doAction = async () => {
    if (!action?.confirm?.inlineBanner) setIsLoading(true);

    if (isEnabled) {
      if (action.confirm) {
        tableObject.setConfirmData({
          isOpen: true,
          title: action.confirm.title ? action.confirm.title(record) : "",
          message: action.confirm.message ? action.confirm.message(record) : "",
          onClose: () => {
            tableObject.setConfirmData(null);
            setIsLoading(false);
          },
          onConfirm: async () => {
            await _doAction();
            tableObject.setConfirmData(null);
            setIsLoading(false);
          },
          cancelLabel: action.confirm.cancelLabel
            ? action.confirm.cancelLabel(record)
            : "Cancel",
          confirmLabel: action.confirm.confirmLabel
            ? action.confirm.confirmLabel(record)
            : "Confirm",
          confirmProps: action.confirm.confirmProps
            ? action.confirm.confirmProps(record)
            : {},
          cancelProps: action.confirm.cancelProps
            ? action.confirm.cancelProps(record)
            : {},
          record,
          inlineBanner: action.confirm.inlineBanner || false,
        });

        return;
      }

      try {
        action.callback && (await _doAction());
      } catch (e) {
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  };

  return (
    <IconButton
      isLoading={isLoading}
      background={"transparent"}
      borderRadius={4}
      isDisabled={!isEnabled}
      key={"action-" + actionName + "-" + recordIndex}
      textStyle={"bodyRegular"}
      onClick={doAction}
    >
      <Icon as={action.icon} boxSize={"24px"} color={"icons.grey"} />
    </IconButton>
  );
};

const BulkActionButton = ({
  actions,
  actionName,
  bulkSelection,
  tableObject,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const action = actions[actionName];
  const browseForFile = action.browseForFile;

  const _doAction = async (e) => {
    if (browseForFile && action.fileCallback) {
      await action.fileCallback(
        e.target.files,
        Object.keys(bulkSelection),
        [],
        tableObject,
      );
    } else if (action.callback) {
      await action.callback(Object.keys(bulkSelection), [], tableObject);
    }
  };

  const doAction = async (e) => {
    setIsLoading(true);

    if (action.confirm) {
      tableObject.setConfirmData({
        isOpen: true,
        title: action.confirm.title ? action.confirm.title(null) : "",
        message: action.confirm.message ? action.confirm.message(null) : "",
        onClose: () => {
          tableObject.setConfirmData(null);
          setIsLoading(false);
        },
        onConfirm: async () => {
          await _doAction();
          tableObject.setConfirmData(null);
          setIsLoading(false);
        },
        cancelLabel: action.confirm.cancelLabel
          ? action.confirm.cancelLabel(null)
          : "Cancel",
        confirmLabel: action.confirm.confirmLabel
          ? action.confirm.confirmLabel(null)
          : "Confirm",
        confirmProps: action.confirm.confirmProps
          ? action.confirm.confirmProps(null)
          : {},
        cancelProps: action.confirm.cancelProps
          ? action.confirm.cancelProps(null)
          : {},
        record: null,
        inlineBanner: false,
      });

      return;
    }

    try {
      await _doAction(e);
    } catch (e) {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <Button
      mr={2}
      onClick={!browseForFile ? doAction : undefined}
      isLoading={isLoading}
      position={browseForFile ? "relative" : undefined}
      outline
    >
      {browseForFile && (
        <input
          type={"file"}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            cursor: "pointer",
            opacity: 0,
          }}
          onChange={doAction}
        />
      )}
      <strong>{actions[actionName].label}</strong>
    </Button>
  );
};

const VeriTable = ({
  columns,
  records = [],
  onRowAction,
  allowBulkSelection = false,
  indexIdentifier = "id",
  variant = "unstyled",
  actions = {},
  tableProps = {},
  hideLastDivider,
  onBulkSelectionChange,
  defaultBulkSelection,
  footerRecord,
  recordsPerPage = 0,
  api,
  apiUrl,
  apiPagination,
  apiUrlParams,
  mapApiRecord,
  mapApiResponseToColumns,
  apiFallbackData,
  scrollOnTopOnPageChange,
  onPageChange,
  refTableObject,
  hideHeader,
  minHeight,
  rowAllowMoreActions,
  actionRowExtra,
  noRecordsMessage,
  tHeadProps,
  sortable,
  apiSortable,
  sortRecords,
  defaultSorting,
  defaultSortingDirection,
  noRecordsContent,
  onRecordsChanged,
  selectAllSelectAllPages,
  onSelectionAllToggle,
  footer,
  showTotalRecords,
  tableHeadLabel,
  filterRecords,
}) => {
  const tableStyle = VeriTableStyles[variant] || VeriTableStyles["unstyled"];
  const paginationSize = useBreakpointValue({ base: 2, md: 7 });
  const marginPagesDisplayed = useBreakpointValue({ base: 2, md: 1 });
  const breakpoint = useBreakpointValue({ md: 1, lg: 2, xl: 3, "2xl": 4 });

  const columnNames = Object.keys(columns).filter(
    (columnName) =>
      !breakpoint ||
      !columns[columnName].hideOnBreakpoint ||
      breakpoint > Number(columns[columnName].hideOnBreakpoint),
  );
  const viewMode = useBreakpointValue({ base: "mobile", md: "table" });
  const tableObject = React.useRef({});
  const theadRef = React.useRef({});
  const [count, setCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [apiRecords, setApiRecords] = React.useState([]);
  const [apiMeta, setApiMeta] = React.useState(null);
  const [apiLoading, setApiLoading] = React.useState(false);
  const [apiFallbackLoaded, setApiFallbackLoaded] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [confirmData, setConfirmData] = React.useState(null);
  const [sortingColumn, setSortingColumn] = React.useState(defaultSorting);
  const [allRecordsSelected, setAllRecordsSelected] = React.useState(false);
  const [sortingDirection, setSortingDirection] = React.useState(
    defaultSortingDirection || "ASC",
  );
  const stringifiedApiUrlParams = JSON.stringify(apiUrlParams);

  // Using object instead of array for better performance
  const [bulkSelection, setBulkSelection] = React.useState(
    defaultBulkSelection
      ? defaultBulkSelection.reduce((res, el) => ({ ...res, [el]: true }), {})
      : {},
  );
  const selectedRecordsCount =
    selectAllSelectAllPages && allRecordsSelected
      ? apiMeta?.rowsTotal || 0
      : Object.keys(bulkSelection).length;

  const availableActionNames = Object.keys(actions);
  const availableBulkActionNames = availableActionNames.filter(
    (actionName) => actions[actionName].allowBulk || false,
  );

  const availableMoreActionNames = availableActionNames.filter(
    (actionName) => actions[actionName].isMoreAction || false,
  );

  const availableInlineActionNames = availableActionNames.filter(
    (actionName) => actions[actionName].isInlineAction || false,
  );

  const _setBulkSelection = (selection) => {
    setBulkSelection(selection);
    onBulkSelectionChange &&
      onBulkSelectionChange(Object.keys(selection), apiMeta);
  };

  const hasRowActions =
    availableActionNames.length > 0 || availableInlineActionNames.length > 0;

  const mapApiRecords = (records) => {
    if (mapApiResponseToColumns) return mapApiResponseToColumns(records);
    if (mapApiRecord) return [...records.map(mapApiRecord)];
    return [...records];
  };

  const sortSimpleRecords = (records) => {
    /*if (sortRecords) {
      return sortRecords(
        columns?.[sortingColumn]?.sortName || sortingColumn,
        sortingDirection,
        records,
        tableObject
      );
    }*/

    return sortable
      ? sortingRecordsByColumnName(
          columns?.[sortingColumn]?.sortName || sortingColumn,
          sortingDirection,
          records,
          columns?.[sortingColumn]?.renderer,
        )
      : records;
  };

  const _records =
    (apiRecords && apiRecords.length
      ? mapApiRecords(apiRecords)
      : recordsPerPage
      ? [...sortSimpleRecords(records || [])].slice(
          currentPage * recordsPerPage,
          (currentPage + 1) * recordsPerPage,
        )
      : [...sortSimpleRecords(records || [])]) || [];

  /**
   * Records count
   */
  const totalRecords = _records.length;

  const isAnyRecordSelected = allowBulkSelection && selectedRecordsCount > 0;
  const isIndeterminateSelection =
    selectAllSelectAllPages && allRecordsSelected
      ? false
      : isAnyRecordSelected && selectedRecordsCount < totalRecords;
  const isAllRecordsSelected =
    (selectAllSelectAllPages && allRecordsSelected) ||
    (isAnyRecordSelected && selectedRecordsCount === totalRecords);

  /**
   * Selection methods
   */
  const toggleRecordSelection = (recordId) => {
    if (selectAllSelectAllPages) {
      setAllRecordsSelected(false);
      onSelectionAllToggle &&
        onSelectionAllToggle(false, apiMeta?.rowsTotal || 0);
    }

    const newSelection = { ...bulkSelection };

    if (newSelection[recordId]) delete newSelection[recordId];
    else newSelection[recordId] = true;

    _setBulkSelection(newSelection);
  };

  const clearSelection = () => {
    if (selectAllSelectAllPages) {
      setAllRecordsSelected(false);
      onSelectionAllToggle &&
        onSelectionAllToggle(false, apiMeta?.rowsTotal || 0);
      return;
    }

    _setBulkSelection({});
  };

  const selectAllRecords = () => {
    if (selectAllSelectAllPages) {
      setAllRecordsSelected(true);
      onSelectionAllToggle &&
        onSelectionAllToggle(true, apiMeta?.rowsTotal || 0);
      return;
    }

    const newSelection = {};
    _records.forEach((record) => {
      newSelection[record[indexIdentifier]] = true;
    });

    _setBulkSelection(newSelection);
  };

  const toggleAllSelection = () => {
    if (isAllRecordsSelected) {
      clearSelection();
    } else {
      selectAllRecords();
    }
  };

  const divider = (
    <Box
      borderBottomWidth={1}
      borderBottomStyle={"solid"}
      borderBottomColor={"#DCE1E7"}
      my={4}
    />
  );

  const hoverStyle = { backgroundColor: "bg.lighter", cursor: "pointer" };

  const clickAction = (e, record) => {
    if (!onRowAction) return;

    e.stopPropagation();
    e.preventDefault();

    onRowAction(e, record);
  };

  const refresh = async (customUrlParams) => {
    if (apiUrl) {
      setApiLoading(true);

      let sortField = null;

      if (apiSortable && sortingColumn) {
        sortField = `${sortingDirection === "DESC" ? "-" : ""}${
          columns[sortingColumn] && columns[sortingColumn].sortName
            ? columns[sortingColumn].sortName
            : sortingColumn
        }`;
      }

      await api
        .apiRequest(apiUrl, "GET", {
          ...(customUrlParams || apiUrlParams),
          ...(apiPagination && {
            "page[size]": recordsPerPage || 100,
            "page[number]": currentPage + 1,
          }),
          ...(apiSortable &&
            sortField && {
              sort: sortField,
            }),
        })
        .then((result) => {
          setApiMeta(result.meta || null);

          let resultRecords = mapApiRecords(
            filterRecords
              ? filterRecords(result.data || [])
              : result.data || [],
          );

          if (sortRecords) {
            sortRecords(
              columns?.[sortingColumn]?.sortName || sortingColumn,
              sortingDirection,
              resultRecords,
              tableObject,
            ).then((sortedRecords) => {
              setApiRecords(
                sortedRecords
                  ? sortedRecords || []
                  : sortingRecordsByColumnName(
                      columns?.[sortingColumn]?.sortName || sortingColumn,
                      sortingDirection,
                      resultRecords,
                      columns?.[sortingColumn]?.renderer,
                    ),
              );
            });
          } else {
            setApiRecords(
              sortable && !apiSortable
                ? sortingRecordsByColumnName(
                    columns?.[sortingColumn]?.sortName || sortingColumn,
                    sortingDirection,
                    resultRecords,
                    columns?.[sortingColumn]?.renderer,
                  )
                : resultRecords,
            );
          }
        })
        .catch((err) => {
          logError("Something went wrong retrieving data", err);
        })
        .finally(() => setApiLoading(false));
    } else {
      sortRecords &&
        sortRecords(sortingColumn, sortingDirection, records, tableObject).then(
          (/*sortedRecords*/) => {
            // setApiRecords(sortedRecords || []);
          },
        );
    }

    setIsInitialized(true);
  };

  tableObject.current = {
    setFilter: () => {
      alert(count);
      setCount(count + 1);
    },
    toggleRecordSelection,
    clearSelection,
    selectAllRecords,
    toggleAllSelection,
    refresh,
    setConfirmData,
    setSortingColumn,
    setSortingDirection,
    sortingDirection,
    sortingColumn,
    isIndeterminateSelection,
    isAllRecordsSelected,
    setCurrentPage,
  };

  React.useEffect(() => {
    if (onBulkSelectionChange) {
      setBulkSelection(
        (Object.keys(bulkSelection) || []).reduce(
          (res, el) => ({ ...res, [el]: true }),
          {},
        ),
      );
    }
  }, [onBulkSelectionChange, defaultBulkSelection]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (apiFallbackData && !apiFallbackLoaded) {
      setApiMeta(apiFallbackData.meta || null);

      let _resultRecords = mapApiRecords(apiFallbackData.data || []);

      if (sortRecords) {
        sortRecords(
          sortingColumn,
          sortingDirection,
          _resultRecords,
          tableObject,
        ).then((sortedRecords) => {
          setApiRecords(
            sortedRecords
              ? filterRecords
                ? filterRecords(sortedRecords || [])
                : sortedRecords || []
              : sortingRecordsByColumnName(
                  columns?.[sortingColumn]?.sortName || sortingColumn,
                  sortingDirection,
                  _resultRecords,
                  columns?.[sortingColumn]?.renderer,
                ),
          );

          setApiRecords(sortedRecords || []);
        });
      } else {
        setApiRecords(
          sortable
            ? sortingRecordsByColumnName(
                columns?.[sortingColumn]?.sortName || sortingColumn,
                sortingDirection,
                _resultRecords,
                columns?.[sortingColumn]?.renderer,
              )
            : filterRecords
            ? filterRecords(_resultRecords || [])
            : _resultRecords || [],
        );
      }
      setApiFallbackLoaded(true);
      setIsInitialized(true);

      return;
    }

    // setCurrentPage(0);

    refresh() /*.finally(() => setIsInitialized(true))*/;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiUrl,
    stringifiedApiUrlParams,
    currentPage,
    sortingColumn,
    sortingDirection,
  ]);

  /*React.useEffect(() => {
    if (!isInitialized) return;

    refresh();
  }, [currentPage]);*/

  React.useEffect(() => {
    refTableObject && refTableObject(tableObject.current);
  }, [refTableObject]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (apiUrl && isInitialized && onRecordsChanged) {
      onRecordsChanged(_records, tableObject.current);
    }
  }, [_records.length, apiUrl, isInitialized]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!viewMode) {
    return <></>;
  }

  if (footerRecord) _records.push(footerRecord);

  const totalPages = apiMeta
    ? apiMeta.pageTotal
    : recordsPerPage
    ? Math.ceil(records.length / recordsPerPage)
    : 0;

  const _doAction = async (action, ids, records) =>
    action.callback && (await action.callback(ids, records, tableObject));

  const doAction = async (action, ids, records) => {
    if (action.confirm) {
      setConfirmData({
        isOpen: true,
        title: action.confirm.title(records),
        message: action.confirm.message(records),
        onClose: () => {
          setConfirmData(null);
        },
        onConfirm: async () => {
          await _doAction(action, ids, records);
          setConfirmData(null);
        },
      });
      return;
    }

    try {
      action.callback && (await _doAction(action, ids, records));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const PaginationComponent =
    apiPagination && totalPages > 1 ? (
      <Box mt={0} pt={6} pb={6} data-testid="veri-table-pagination">
        <PaginationBoxView
          pageCount={totalPages}
          pageRangeDisplayed={Number(paginationSize || 7)}
          marginPagesDisplayed={marginPagesDisplayed}
          onClickBuilder={(page) => {
            setCurrentPage(page - 1);
            if (scrollOnTopOnPageChange) {
              scrollToTop(true);
            }
            onPageChange && onPageChange(page);
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          hrefBuilder={() => {}}
          selected={currentPage}
        />
      </Box>
    ) : (
      <></>
    );

  if (viewMode !== "table") {
    return (
      <Box data-testid="veri-table-mobile">
        {(_records || []).map((record, recordIndex) => {
          return (
            <Box key={"record-" + recordIndex}>
              {divider}
              <Box
                onClick={(e) => {
                  clickAction(e, record);
                }}
                _hover={onRowAction ? hoverStyle : {}}
                data-testid={"veri-table-mobile-row-" + recordIndex}
              >
                {columnNames.map((columnName, columnIndex) => {
                  const column = columns[columnName];
                  const value = record[columnName];
                  const Renderer = column.renderer || undefined;
                  return (
                    <Box key={columnName}>
                      <Box mb={2}>
                        {column?.label && (
                          <Text as="span" textStyle="bodyRegularBold" mr={2}>
                            {column?.label}:
                          </Text>
                        )}
                        <Text as="span" textStyle="bodyRegular">
                          {Renderer ? (
                            <Renderer
                              value={value}
                              column={column}
                              tableObject={tableObject.current}
                              record={record}
                              recordIndex={recordIndex}
                              columnIndex={columnIndex}
                              columnName={columnName}
                              options={column.rendererOptions || {}}
                            />
                          ) : column.processRow ? (
                            column.processRow(column, value, record)
                          ) : (
                            value
                          )}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}

        {PaginationComponent}

        {!hideLastDivider && divider}
      </Box>
    );
  }

  if (apiUrl && !isInitialized) return <></>;

  if (noRecordsContent && (!_records || _records.length < 1)) {
    return noRecordsContent;
  }

  const getMoreActionsMenu = (
    record,
    recordIndex,
    recordIdentifier,
    availableMoreActionNames,
    actions,
  ) => {
    return (
      <MenuList>
        {availableMoreActionNames.map((actionName, i) => {
          const isEnabled =
            !actions[actionName].checkActionAllowed ||
            actions[actionName].checkActionAllowed(record, tableObject);

          if (actions[actionName].isDiver) {
            return (
              <Box
                key={"action-" + actionName + "-" + recordIndex + "-" + i}
                px={0}
                py={1}
                cursor={"default"}
              >
                <Box
                  borderBottom={"1px solid"}
                  borderColor="border.grey"
                  w={"100%"}
                />
              </Box>
            );
          }

          return (
            <MenuItem
              isDisabled={!isEnabled}
              key={"action-" + actionName + "-" + recordIndex + "-" + i}
              textStyle={"bodyRegular"}
              onClick={() =>
                isEnabled &&
                !actions[actionName].subs &&
                actions[actionName].callback &&
                doAction(
                  actions[actionName],
                  [recordIdentifier],
                  [record],
                  tableObject,
                )
              }
              subMenu={
                actions[actionName].subs
                  ? getMoreActionsMenu(
                      record,
                      recordIndex,
                      recordIdentifier,
                      Object.keys(actions[actionName].subs),
                      actions[actionName].subs,
                    )
                  : undefined
              }
            >
              {actions[actionName].label || ""}
            </MenuItem>
          );
        })}
      </MenuList>
    );
  };

  return (
    <Box>
      <VeriTableHeading
        headingLabel={tableHeadLabel}
        showTotalRecordsCount={showTotalRecords}
        totalRecordsCount={apiMeta?.rowsTotal}
      />
      <Box
        minHeight={minHeight}
        {...(tableStyle.wrapper || {})}
        position={"relative"}
      >
        {isAnyRecordSelected && (
          <Flex
            position={"absolute"}
            left="35px"
            right={0}
            bottom="1px"
            top={0}
            pl={6}
            pt={String((theadRef?.current?.clientHeight || 65) - 70) + "px"}
            alignItems={"center"}
            {...(tableStyle.bulkActionsBar || {})}
            height={String((theadRef?.current?.clientHeight || 65) - 1) + "px"}
            zIndex={"docked"}
          >
            <Box textStyle={"bodySmall"} fontWeight={"500"} mr={6}>
              {selectedRecordsCount} selected
            </Box>
            <Flex>
              {availableBulkActionNames.map((actionName) => (
                <BulkActionButton
                  key={actionName}
                  actions={actions}
                  actionName={actionName}
                  bulkSelection={bulkSelection}
                  tableObject={tableObject.current}
                />
              ))}
            </Flex>
          </Flex>
        )}
        {apiLoading && (
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            position={"absolute"}
            left={0}
            right={0}
            top={0}
            bottom={0}
            bg={"rgba(255,255,255,.6)"}
            data-testid="table-loading-screen"
          >
            <VeriSpinner />
          </Flex>
        )}
        <Table variant="unstyled" {...(tableStyle.table || {})} {...tableProps}>
          {!hideHeader && (
            <VeriTableHead
              tHeadProps={tHeadProps}
              tableStyle={tableStyle}
              columnNames={columnNames}
              allowBulkSelection={allowBulkSelection}
              tableObject={tableObject.current}
              hasRowActions={hasRowActions}
              columns={columns}
              sortable={sortable}
              ref={theadRef}
              data-testid="table-header"
            />
          )}
          <Tbody data-testid="table-tbody">
            {(_records || []).map((record, recordIndex) => {
              const recordIdentifier = record[indexIdentifier];
              const isRecordSelected =
                (selectAllSelectAllPages && allRecordsSelected) ||
                bulkSelection[recordIdentifier];

              const hasActionBanner =
                confirmData &&
                confirmData.inlineBanner &&
                confirmData.record[indexIdentifier] === record[indexIdentifier];

              return (
                <Tr
                  key={`record-${recordIndex}`}
                  _hover={onRowAction ? hoverStyle : {}}
                  position={hasActionBanner ? "relative" : undefined}
                  {...(tableStyle.tr || {})}
                  {...(isRecordSelected && tableStyle.selectedRow
                    ? tableStyle.selectedRow
                    : {})}
                >
                  {allowBulkSelection && (
                    <Td
                      key={`row-bulk-selection-${recordIndex}-${isRecordSelected}`}
                      data-testid="row-bulk-selection"
                    >
                      <Checkbox
                        isChecked={isRecordSelected}
                        onChange={() => toggleRecordSelection(recordIdentifier)}
                        size={"lg"}
                        data-testid="row-bulk-selection-checkbox"
                      />
                    </Td>
                  )}
                  {columnNames.map((columnName, columnIndex) => {
                    const column = columns[columnName];
                    const value = record[columnName];

                    const content = (
                      <>
                        {column.renderer ? (
                          <column.renderer
                            value={value}
                            column={column}
                            tableObject={tableObject.current}
                            record={record}
                            recordIndex={recordIndex}
                            columnName={columnName}
                            columnIndex={columnIndex}
                            options={column.rendererOptions || {}}
                            key={`renderer-${columnIndex}-${recordIndex}`}
                          />
                        ) : column.processRow ? (
                          column.processRow(column, value, record)
                        ) : (
                          value
                        )}
                      </>
                    );

                    return (
                      <Td
                        textStyle={"bodySmallRegular"}
                        textAlign={column.rowAlign || "left"}
                        pl={!columnIndex && allowBulkSelection ? 0 : undefined}
                        key={`record-column-${columnName}-${columnIndex}`}
                        onClick={(e) => {
                          clickAction(e, record);
                        }}
                        data-testid={"veri-table-table-row-" + recordIndex}
                        _hover={{ pointer: "inherit" }}
                        borderBottomWidth={
                          hideLastDivider && recordIndex + 1 === _records.length
                            ? 0
                            : 1
                        }
                        {...(tableStyle.td || {})}
                        {...(footerRecord &&
                          recordIndex + 1 === _records.length && {
                            ...(tableStyle.footerRecord || {}),
                          })}
                        {...column.props}
                      >
                        {column.inlineEdit &&
                        (!column.inlineEdit.checkActionAllowed ||
                          column.inlineEdit.checkActionAllowed(record)) ? (
                          <Flex alignItems={"center"}>
                            {content}
                            <PopoverEdit
                              getInputs={column.inlineEdit.component}
                              defaultValues={{ [columnName]: value }}
                              onSubmit={async (values) =>
                                await column.inlineEdit.onSubmit(
                                  values,
                                  record,
                                  tableObject.current,
                                )
                              }
                            />
                          </Flex>
                        ) : (
                          content
                        )}
                      </Td>
                    );
                  })}
                  {hasRowActions && (
                    <Td
                      textStyle={"bodySmallRegular"}
                      textAlign={"center"}
                      _hover={{ pointer: "inherit" }}
                      borderBottomWidth={
                        hideLastDivider && recordIndex + 1 === _records.length
                          ? 0
                          : 1
                      }
                      {...(tableStyle.td || {})}
                      {...(footerRecord &&
                        recordIndex + 1 === _records.length && {
                          ...(tableStyle.footerRecord || {}),
                        })}
                    >
                      <Flex alignItems={"center"} justifyContent={"flex-end"}>
                        {actionRowExtra && (
                          <Box>
                            {actionRowExtra(record, tableObject.current)}
                          </Box>
                        )}
                        <Box minHeight={"40px"} whiteSpace={"nowrap"}>
                          {availableInlineActionNames?.length > 0 && (
                            <>
                              {availableInlineActionNames.map((actionName) => {
                                const isEnabled =
                                  !actions[actionName].checkActionAllowed ||
                                  actions[actionName].checkActionAllowed(
                                    record,
                                    tableObject.current,
                                  );
                                return (
                                  <InlineActionItem
                                    record={record}
                                    recordIndex={recordIndex}
                                    isEnabled={isEnabled}
                                    recordIdentifier={recordIdentifier}
                                    actions={actions}
                                    actionName={actionName}
                                    action={actions[actionName]}
                                    tableObject={tableObject.current}
                                    key={
                                      "action-" + actionName + "-" + recordIndex
                                    }
                                  />
                                );
                              })}
                            </>
                          )}
                          {availableMoreActionNames?.length > 0 &&
                            (!rowAllowMoreActions ||
                              rowAllowMoreActions(record, tableObject)) && (
                              <VeriDropMenu
                                trigger={({ isOpen }) => (
                                  <IconButton
                                    isActive={isOpen}
                                    bg={"transparent"}
                                    borderRadius={"100%"}
                                  >
                                    <MoreVertical
                                      boxSize={"24px"}
                                      color={"icons.grey"}
                                    />
                                  </IconButton>
                                )}
                              >
                                {getMoreActionsMenu(
                                  record,
                                  recordIndex,
                                  recordIdentifier,
                                  availableMoreActionNames,
                                  actions,
                                )}
                              </VeriDropMenu>
                            )}
                        </Box>
                      </Flex>
                      {hasActionBanner && <ConfirmBanner {...confirmData} />}
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        {noRecordsMessage && (!_records || !_records.length > 0) && (
          <Box py={6}>{noRecordsMessage}</Box>
        )}
      </Box>
      {PaginationComponent}
      {footer}
      {confirmData && !confirmData.inlineBanner && (
        <ConfirmModal {...confirmData} />
      )}
    </Box>
  );
};

export default VeriTable;
