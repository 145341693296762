import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { VeriSpinner } from "../../components/common/loaders/VeriSpinner";
import {
  getBoxAsComponent,
  getButtonVariationAttributes,
} from "./buttonHelpers";
import { ButtonProps } from "./ButtonProps";

const Button: React.FC<ButtonProps> = ({
  label,
  href,
  to,
  isLoadingJustIcon,
  isLoading,
  loadingText,
  leftIcon,
  leftIconProps,
  isDisabled,
  textProps,
  children,
  isRoundIcon,
  isDanger,
  isWhite,
  isDark,
  isFlat,
  fullWidth,
  isLarge,
  isMedium,
  outline,
  isBold,
  empty,
  link,
  boxLink,
  dataTestId,
  ...restProps
}) => {
  const iconSpacing = 2;
  const [isHovering, setIsHover] = useState(false);

  const stylingProps = {
    isDanger,
    empty,
    fullWidth,
    isBold,
    isDark,
    isFlat,
    isLarge,
    isMedium,
    isWhite,
    link,
    boxLink,
    outline,
    isRoundIcon,
  };

  const stylingVariationAttributes = getButtonVariationAttributes(
    stylingProps,
    isHovering,
    restProps.color as string,
  );

  const disabled = isDisabled || isLoading;

  return (
    <Box
      as={getBoxAsComponent(to, href, disabled)}
      to={to}
      href={href}
      disabled={disabled}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      data-testid={dataTestId}
      {...stylingVariationAttributes.boxStylingAttributes}
      {...restProps}
    >
      {leftIcon && !isLoading && (
        <Box
          as={leftIcon}
          ml={-1}
          mr={iconSpacing}
          data-custom-icon
          focusable="false"
          aria-hidden="true"
          color="currentColor"
          {...leftIconProps}
        />
      )}
      {isLoading && (
        <VeriSpinner
          position={!isLoadingJustIcon ? "relative" : "absolute"}
          mr={!isLoadingJustIcon ? iconSpacing : 0}
          color="currentColor"
          boxSize="1em"
          data-testid="spinner"
        />
      )}
      {!isLoadingJustIcon && (
        <Text
          as={"span"}
          {...stylingVariationAttributes.textStylingAttributes}
          {...textProps}
        >
          {isLoading && loadingText ? loadingText : label || children}
        </Text>
      )}
    </Box>
  );
};

Button.defaultProps = {
  type: "button",
  leftIconProps: {},
  textProps: {},
};

export default Button;
