import {
  Box,
  Flex,
  Tab,
  TabList,
  TabProps,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";
import slugify from "slugify";
import { VeriSelect } from "../../form-controls";
import { VeriTab } from "./components";
import { useTabsOverflow } from "./useTabsOverflow";
import { VeriTabsProps } from "./VeriTabs.types";
import { cn } from "../../../utils/css";

const tabStyle = {
  borderLeftWidth: 4,
  borderLeftStyle: "solid",
  borderLeftColor: "transparent",
  borderTopWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: "border.grey",
  borderRightWidth: 0,
  fontWeight: "500",
  py: 4,
  px: 6,
  mx: 0,
} as TabProps;

const tabSelectedStyle = {
  backgroundColor: "transparent",
  borderLeftColor: "brand.main",
  borderRightColor: "transparent",
  color: "brand.main",
};

const containedTabStyle = {
  backgroundColor: "bg.grey",
  m: 0,
  p: 6,
  borderLeft: "1px solid",
  borderBottom: "1px solid",
  borderBottomColor: "border.grey",
  borderLeftColor: "border.grey",
  _selected: {
    backgroundColor: "transparent",
    borderBottom: "1px solid",
    borderBottomColor: "transparent",
    borderTop: "4px solid",
    borderTopColor: "brand.main",
  },
};

const VeriTabs: React.FC<VeriTabsProps> = ({
  isVertical,
  children,
  useHashNavigation,
  tabListWrapperAs,
  tabListWrapperProps,
  normalTabStyle,
  labelStyle,
  iconsOnly,
  variant,
  onTabChange,
  rightExtra,
  baseUrl,
  useUrlNavigation,
  urlSelected,
  hideTabs,
  searchQuery,
  defaultSelectedTabSlug,
  ignoreSearchQuery,
  tabListProps,
  tabSelectedProps,
  ...rest
}) => {
  const { ref: elementRef, hasOverflow } = useTabsOverflow<HTMLDivElement>();
  const tabsAsSelect = !isVertical && hasOverflow;

  const _children = React.Children.toArray(children).filter(
    (child) => child && typeof child !== "string",
  ) as ReactElement[];
  const tabs = _children.map((child) => child.props?.label || "");
  const tabsSuffix = _children.map((child) => child.props?.labelSuffix || "");
  const tabUrls = _children.map(
    (child) =>
      child.props?.slug || slugify(child.props?.label || "").toLowerCase(),
  );
  const icons = _children.map((child) => child.props?.icon || null);
  const tabDescriptions = _children.map(
    (child) => child.props?.description || "",
  );
  const location = useLocation();
  const history = useHistory();
  const hashSelection = useHashNavigation && decodeURIComponent(location.hash);
  const tabKeys = _children.map(
    (child) => child.props?.name || child.props?.label?.toLowerCase() || "",
  );

  const [selectedTab, setSelectedTab] = React.useState<string | number>(
    defaultSelectedTabSlug
      ? tabUrls.indexOf(slugify(defaultSelectedTabSlug).toLowerCase())
      : useUrlNavigation && urlSelected
      ? tabUrls.indexOf(urlSelected)
      : 0,
  );

  const selectTab = (tabIndex: number) => {
    if (searchQuery && !ignoreSearchQuery) {
      history.replace(`${baseUrl}/${tabUrls[tabIndex]}?${searchQuery}`);
    } else if (useUrlNavigation) {
      history.replace(`${baseUrl}/${tabUrls[tabIndex]}`);
    } else if (useHashNavigation) {
      history.replace(`${history.location.search}#${tabKeys[tabIndex]}`);
    } else {
      setSelectedTab(tabIndex);
    }

    onTabChange && onTabChange(tabIndex);
  };

  const findSelectedTab = () => {
    if (!useHashNavigation) return 0;
    const cleanKey = (hashSelection || "").replace("#", "").toLowerCase();
    const tabIndex = tabKeys.findIndex(
      (tabKey) => tabKey.toLowerCase() === cleanKey,
    );

    return tabIndex > -1 ? tabIndex : 0;
  };

  React.useEffect(() => {
    if (useHashNavigation) setSelectedTab(findSelectedTab());
    if (defaultSelectedTabSlug)
      setSelectedTab(
        tabUrls.indexOf(slugify(defaultSelectedTabSlug).toLowerCase()),
      );
  }, [hashSelection, defaultSelectedTabSlug]);

  React.useEffect(() => {
    if (useUrlNavigation) {
      setSelectedTab(tabUrls.indexOf(urlSelected || ""));
    }
  }, [useUrlNavigation, urlSelected]);

  const selectedChild = _children.find(
    (_child, tabIndex) => tabIndex === selectedTab,
  );

  return (
    <Flex
      ref={elementRef}
      w={"100%"}
      flexDirection={isVertical ? "row" : "column"}
      {...rest}
    >
      {!hideTabs && (
        <>
          {tabsAsSelect ? (
            <VeriSelect
              width={"w-full sm:w-fit"}
              minWidth={"250px"}
              onChange={(event) => selectTab(Number(event.target.value))}
              value={selectedTab}
            >
              {tabs.map((label, tabIndex) => (
                <option key={tabKeys[tabIndex]} value={tabIndex}>
                  {label}
                </option>
              ))}
            </VeriSelect>
          ) : (
            <Box {...tabListWrapperProps}>
              <Box
                as={tabListWrapperAs}
                display={rightExtra ? "flex" : undefined}
                {...tabListWrapperProps}
                borderBottom={!isVertical ? "1px solid" : undefined}
                borderBottomColor={"border.grey"}
                alignItems={rightExtra && !isVertical ? "flex-end" : undefined}
              >
                <Tabs
                  onChange={(index) => selectTab(index)}
                  index={Number(selectedTab) ?? undefined}
                  orientation={isVertical ? "vertical" : "horizontal"}
                  w={rightExtra ? "100%" : undefined}
                >
                  <TabList
                    w={"100%"}
                    borderBottomWidth={!isVertical ? "0" : undefined}
                    borderLeftWidth={0}
                    pb={!isVertical ? "2px" : undefined}
                    {...tabListProps}
                  >
                    {tabs.map((label, tabIndex) =>
                      isVertical ? (
                        <Tab
                          {...tabStyle}
                          {...normalTabStyle}
                          _selected={tabSelectedStyle}
                          justifyContent="left"
                          w={"100%"}
                          borderTopWidth={tabIndex === 0 ? 0 : 1}
                          flexDirection={"column"}
                          alignItems={"flex-start"}
                          key={tabIndex}
                        >
                          <Flex w={"100%"} alignItems={"flex-end"}>
                            {icons[tabIndex] && (
                              <Box mr={5}>{icons[tabIndex]}</Box>
                            )}
                            <Box {...labelStyle}>
                              {label} {tabsSuffix[tabIndex]}
                            </Box>
                          </Flex>
                          {tabDescriptions[tabIndex] && (
                            <Text
                              as={"span"}
                              display={"block"}
                              color={"text.grey"}
                              fontWeight={"normal"}
                              mt={1}
                            >
                              {tabDescriptions[tabIndex]}
                            </Text>
                          )}
                        </Tab>
                      ) : (
                        <VeriTab
                          key={tabKeys[tabIndex]}
                          {...(variant === "contained"
                            ? containedTabStyle
                            : {})}
                          {...tabSelectedProps}
                        >
                          <Flex w={"100%"} alignItems={"flex-end"}>
                            {icons[tabIndex] && (
                              <Box mr={iconsOnly ? 0 : 5}>
                                {icons[tabIndex]}
                              </Box>
                            )}
                            {!iconsOnly && <Box>{label}</Box>}
                          </Flex>
                          {tabDescriptions[tabIndex] && (
                            <Text
                              as={"span"}
                              display={"block"}
                              color={"text.grey"}
                              fontWeight={"normal"}
                              mt={1}
                            >
                              {tabDescriptions[tabIndex]}
                            </Text>
                          )}
                        </VeriTab>
                      ),
                    )}
                  </TabList>
                </Tabs>
                {rightExtra && <Box>{rightExtra}</Box>}
              </Box>
            </Box>
          )}
        </>
      )}
      <Box w={"100%"}>
        <Box
          className={cn(
            "pr-0",
            !isVertical && !hideTabs ? "pt-2 sm:pt-8" : "",
            isVertical ? "pl-8" : "",
          )}
          pl={isVertical ? 8 : 0}
          pr={0}
        >
          {selectedChild}
        </Box>
      </Box>
    </Flex>
  );
};

export default VeriTabs;
