import React from "react";
import { TagsDrawerFilterType } from "../../TagsDrawer.types";
import { TagsInput as TagsInputControl } from "../../../../form-controls";
import { FilterValueType } from "../../../VeriDrawer/VeriDrawer.types";
import { Dict } from "../../../../../defines";

const TagsInput: React.FC<TagsDrawerFilterType> = ({
  filterRef,
  onChange,
  value,
}) => {
  const [tags, setTags] = React.useState<{ label: string; value: string }[]>(
    [],
  );

  const [selectedValues, setSelectedValues] = React.useState<Dict>({});

  React.useEffect(() => {
    const refObject = {
      valueType: FilterValueType.ARRAY,
      getValueLabel: (value: any) => value,
    };

    if (typeof filterRef === "function") {
      filterRef(refObject);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (Array.isArray(value)) {
      const selectedValues: Dict = {};
      setTags((_t) => value.map((v) => ({ value: v, label: v })));
      value.forEach((val) => (selectedValues[val] = true));
      setSelectedValues(
        value.reduce((result, current) => ({ ...result, [current]: true }), {}),
      );
    } else {
      setSelectedValues([]);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _changeSelection = (newValues: any) => {
    setSelectedValues((n) => {
      const valuesObject = { ...n, ...newValues };
      if (onChange) {
        onChange(Object.keys(valuesObject).filter((k) => valuesObject[k]));
      }
      return valuesObject;
    });
  };

  const removeValue = (value: any) => {
    const newValues = { ...selectedValues };
    newValues[value] = false;
    _changeSelection(newValues);
  };

  const addValue = (value: any) => {
    const newValues = { ...selectedValues };
    newValues[value] = true;

    _changeSelection(newValues);
  };

  return (
    <TagsInputControl
      placeholder={" "}
      containerProps={{ w: "100%" }}
      tags={tags}
      value={Object.keys(selectedValues).filter((k) => selectedValues[k])}
      tagsInInput
      onTagCreation={async (value) => {
        setTags((tags) => [...tags, { label: value, value }]);
        return { label: value, value };
      }}
      onAdd={(value) => {
        addValue(value);
      }}
      onRemove={(value) => {
        removeValue(value);
      }}
      createLabel={"Add"}
      showNoTagLeft={false}
      backspaceToRemove
      allowTagCreation
    />
  );
};

export default TagsInput;
