/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum InvestmentsStatusType {
  Completed = "Completed",
  FailedPurchase = "FailedPurchase",
  FailedSend = "FailedSend",
  InProcess = "In Process",
  Oversubscribed = "Oversubscribed",
  Pending = "Pending",
}
