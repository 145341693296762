/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateBillingEntityOutputJsonApiModel = {
  id: "",
  "billing-entity": {
    jsonApi: "hasOne",
    type: "billing-entities",
  },
};
