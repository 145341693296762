// import LogRocket from "logrocket";
// import OwnCookies from "../utils/OwnCookies";
// import { getMe } from "../../redux/modules/users";
import decode from "jwt-decode";
import ApiClient from "../api/ApiClient";
import { ManagerModel, UserModel } from "../api/models";
import {
  COOKIE_NAME_SESSION_ID,
  Dict,
  ModelId,
  managerDashboardRoutes,
} from "../defines";
import { authRoutes } from "../defines/routes/auth";
import { logError } from "../error-handling/logError";
import {
  buildRouteUrl,
  getWhiteLabelBaseUrl,
  isClient,
  openUrlInNewWindow,
} from "../utils";
import OwnCookies from "../utils/own-cookies";
import { AuthData } from "./types/AuthData";
import { AuthProfile } from "./types/AuthProfile";

const mfaTokenKey = "mfatoken";
export const accessTokenKey = "access_token";
export const impersonatedTokenKey = "impersonated_access_token";
export const impersonatedProfileKey = "impersonated_profile";
export const impersonatedByManager = "impersonated_by_manager";

export interface Manager extends ManagerModel {
  color: { main: string; hover: string; dark: string };
}

const TENANT_ID = 1;
class Auth {
  cookies: OwnCookies;
  dispatch: any;
  getState: any;
  history: any;
  profile: any;
  apiAxiosRequest: ApiClient["apiAxiosRequest"] | null;
  manager: Manager | null;
  authData: AuthData | undefined;
  isManagerDashboard = false;

  constructor(cookies: OwnCookies, isManagerDashboard = false) {
    this.manager = null;
    this.apiAxiosRequest = null;
    this.cookies = cookies;
    this.isManagerDashboard = isManagerDashboard;
  }

  getLoginURL(email: string): string {
    const loc = window.location;
    const callbackUrl = loc.protocol + "//" + loc.host + "/";
    return `${buildRouteUrl(
      authRoutes.AUTH,
    )}?email=${email}&callback_url=${callbackUrl}/login`;
  }

  setDispatch(dispatch: any): void {
    this.dispatch = dispatch;
    // this.owb = new OurWebSocket(dispatch);
  }

  setGetState(getState: any): void {
    this.getState = getState;
  }

  init = async (): Promise<void> => {
    // return await this.setReduxAuth();
  };

  setApiAxiosRequest(method: ApiClient["apiAxiosRequest"]): void {
    this.apiAxiosRequest = method;
  }

  setHistory = (h: any): void => {
    this.history = h;
  };

  setSessionStorageItem = (name: string, value: string) => {
    sessionStorage.setItem(name, value);
  };

  setManager = (manager: Manager): void => {
    this.manager = manager;
  };

  setAuthData = (authData: AuthData) => {
    this.authData = authData;
  };

  loadUserByEmail = async (email: string) => {
    const response = await this.apiAxiosRequest?.("auth/check-email", "post", {
      email: email,
    });

    return response.data;
  };

  getManagerByDomain = async (
    domain: string,
  ): Promise<Manager | null | undefined> =>
    this.apiAxiosRequest?.(
      `managers/check-domain/${domain}`,
      "GET",
      false,
      {},
      false,
      true,
    )
      ?.then((response) => response.data as Manager)
      ?.then((manager) => {
        manager.color = { main: "#32a852", hover: "#226e37", dark: "#134521" };
        this.setManager(manager);
        return manager;
      })
      ?.catch(() => null);

  getManagerById = async (id: string): Promise<Manager> => {
    const response = await this.apiAxiosRequest?.(
      `managers/${id}`,
      "GET",
      false,
      {},
      false,
      true,
    );

    const data: Manager = response.data || {};
    data.color = { main: "#32a852", hover: "#226e37", dark: "#134521" };
    this.setManager(data);

    return data;
  };

  getManagerAndHasAccess = async (
    managerIdOrDomain: string,
  ): Promise<Manager | false> => {
    if (!this.isManager(managerIdOrDomain)) {
      return false;
    }
    const response = await this.apiAxiosRequest?.(
      `managers/${managerIdOrDomain}`,
      "GET",
      false,
      {},
      false,
      true,
    );

    const data: Manager = response.data || {};
    data.color = { main: "#32a852", hover: "#226e37", dark: "#134521" };
    this.setManager(data);

    return data;
  };

  async loginThroughToken(token: string): Promise<void> {
    const profile = await this.loadProfile(token);

    if (isClient()) {
      this.deleteMFAToken();
    }

    this.setSession({
      token: token,
      profile: profile.data.profile,
    });
  }

  getFirstManagerId = () => {
    const profile = this.getProfile();
    return (
      ((profile?.roles as { role: string; manager_id: number }[]) || []).find(
        (role) => role.role === "manager" && role.manager_id,
      )?.manager_id || null
    );
  };

  findFirstManager = () => {
    const profile = this.getProfile();
    return (profile?.roles || []).find(
      (r) => r.role === "admin" || r.role === "manager",
    );
  };

  isManagerOfAny = (): boolean => {
    const profile = this.getProfile();
    return (profile?.roles || []).some(
      (r) => r.role === "admin" || r.role === "manager",
    );
  };

  isManager = (manager_id: string): boolean => {
    const profile = this.getProfile();
    return (profile?.roles || []).some(
      (r) =>
        parseInt((r as Dict).manager_id || "") === parseInt(manager_id) &&
        (r.role === "admin" || r.role === "manager"),
    );
  };

  isMallcop = (): boolean => {
    const profile = this.getProfile();

    return (profile?.roles || []).some((r) => r.role === "mallcop");
  };

  loadProfile = async (token: string): Promise<any> => {
    try {
      return await this.apiAxiosRequest?.(
        "auth/check-token",
        "POST",
        { token: token },
        {},
      );
    } catch (error) {
      logError("error", error);
    }
  };

  signUpJustEmail = async (
    signupData: any,
    loginAfterSignup = true,
  ): Promise<any> => {
    const response = await this.apiAxiosRequest?.(
      "auth/signup-no-password",
      "POST",
      { ...signupData, manager_id: TENANT_ID },
    );

    if (response.status === 401) {
      return {
        error:
          (response.data && response.data.errors && response.data.errors[0]) ||
          "Something went wrong",
      };
    }
    const data = response.data;
    if (loginAfterSignup) {
      this.setSession(data);
    }

    return data;
  };

  signup = async (signupData: any): Promise<any> => {
    signupData.manager_id = TENANT_ID;
    const response = await this.apiAxiosRequest?.(
      "auth/signup",
      "POST",
      signupData,
    );
    if (response.status === 401) {
      return {
        error:
          (response.data && response.data.errors && response.data.errors[0]) ||
          "Something went wrong",
      };
    }
    const data = response.data;
    if (data.email_activation_required === true) {
      this.history.push({
        pathname: "/signup/verify",
      });
    } else {
      this.setSession(data);
    }

    return data;
  };

  signupConfirm = async (token: string, inviteCode: string) => {
    const confirmData = {
      token,
      inviteCode,
    };
    const response = await this.apiAxiosRequest?.(
      "auth/confirm",
      "POST",
      confirmData,
    );
    if (response.status === 401) {
      return {
        error:
          (response.data && response.data.errors && response.data.errors[0]) ||
          "Something went wrong",
      };
    }
    const data = response.data;
    this.setSession(data);

    return data;
  };

  apiLogout = async (): Promise<void> => {
    const t = this.getToken();
    return this.apiAxiosRequest?.(
      "user/logout",
      "POST",
      { token: t },
      {},
      t || undefined,
    );
  };

  mfaCheckSms = async (checkData: any) => {
    checkData.manager_id = TENANT_ID;

    return this.apiAxiosRequest?.(
      "user/mfa/check/sms",
      "POST",
      checkData,
      {},
      this.getMFAToken() || this.getToken() || undefined,
    );
  };

  mfaConfirmSms = async (confirmData: any) => {
    return this.apiAxiosRequest?.(
      "user/mfa/sms",
      "POST",
      confirmData,
      {},
      this.getMFAToken() || this.getToken() || undefined,
    );
  };

  mfaConfirmApp = async (confirmData: any) => {
    return this.apiAxiosRequest?.(
      "user/mfa/app",
      "POST",
      confirmData,
      {},
      this.getMFAToken() || this.getToken() || undefined,
    );
  };

  syncUserSession = async (sessionId: string, token: string) => {
    const data = {
      token: token,
      session_id: sessionId,
    };
    return this.apiAxiosRequest?.("auth/sync-session", "POST", data);
  };

  login = async (loginData: {
    email: string;
    password: string;
    invite_code?: string;
    remember: boolean;
  }) => {
    const response = await this.apiAxiosRequest?.("auth/login", "post", {
      ...loginData,
      anon_session_id: this.cookies.get(COOKIE_NAME_SESSION_ID) || "",
    });
    if (response.status === 401) {
      return {
        error:
          (response.data && response.data.errors && response.data.errors[0]) ||
          "Something went wrong",
      };
    }

    if (response.mfa_required === true) {
      // MFA is required
    } else if (
      response.profile &&
      (!response.profile.mfa_type || response.profile.mfa_type.length === 0)
    ) {
      // Must set up MFA
    } else {
      // NO MFA is needed
      //this.addSession(response.data);
      this.cookies.delete(COOKIE_NAME_SESSION_ID);
    }

    return response.data;
  };

  loginMFA = async (token: string, code: string) => {
    try {
      const mfaData = {
        token,
        code,
        anon_session_id: this.cookies.get(COOKIE_NAME_SESSION_ID) || "",
      };

      const response = await this.apiAxiosRequest?.(
        "auth/mfa",
        "post",
        mfaData,
      );
      if (response.status === 401) {
        return {
          error:
            (response.data &&
              response.data.errors &&
              response.data.errors[0]) ||
            "Something went wrong",
        };
      }
      const data = response.data;
      this.cookies.delete(COOKIE_NAME_SESSION_ID);
      //this.addSession(data);
      return data;
    } catch (e) {
      logError("err", e);
      throw e;
    }
  };

  resetPassword = async (email: string) => {
    try {
      const confirmData = {
        email: email,
      };
      const response = await this.apiAxiosRequest?.(
        "auth/reset-password",
        "POST",
        confirmData,
      );
      if (response.status === 401) {
        return {
          error:
            (response.data &&
              response.data.errors &&
              response.data.errors[0]) ||
            "Something went wrong",
        };
      }
      return response.data;
    } catch (e) {
      logError("err", e);
      throw e;
    }
  };

  resetPasswordConfirm = async (resetConfirmData: any) => {
    try {
      const response = await this.apiAxiosRequest?.(
        "auth/reset-password/confirm",
        "POST",
        resetConfirmData,
      );
      if (response.status === 401) {
        return {
          error:
            (response.data &&
              response.data.errors &&
              response.data.errors[0]) ||
            "Something went wrong",
        };
      }
      const data = response.data;
      this.setSession(data);
      return data;
    } catch (e) {
      logError("err", e);
      throw e;
    }
  };

  resendActivationEmail = async (email: string) => {
    const response = await this.apiAxiosRequest?.(
      "auth/resend-activation",
      "POST",
      {
        email: email,
        manager_id: TENANT_ID,
      },
    );
    if (response.status === 401) {
      return {
        error:
          (response.data && response.data.errors && response.data.errors[0]) ||
          "Something went wrong",
      };
    }
    const data = response.data;
    return data;
  };

  completeLogin = (data: any) => {
    // set profile
    this.setProfile(data);
    if (isClient()) {
      // Inactive requires email confirmation
      if (!data.profile.active) {
        window.location.assign("/confirm-email");
      }

      // Active users only
      if (data.profile.active) {
        const code = sessionStorage.getItem("invite_code");
        if (code) {
          sessionStorage.removeItem("invite_code");
          return window.location.assign("/");
        }
        const url = sessionStorage.getItem("redirect_after_login");
        if (url && url !== "undefined") {
          sessionStorage.removeItem("redirect_after_login");
          return window.location.assign(url);
        } else {
          return window.location.assign(window.location.pathname);
        }
      }
    }
  };

  async validateSession() {
    const token = this.getToken();
    if (!token || token === "") {
      return true;
    }
    try {
      const response = await this.apiAxiosRequest?.(
        "auth/check-token",
        "POST",
        {
          token: this.getToken(),
        },
      );

      const data = response.data;
      this.setSession(data);
      if (!data.token || data.token === "") {
        this.removeSession();
        return false;
      }

      return true;
    } catch (err) {
      this.removeSession();
      return false;
    }
  }

  setProfile(profile: any) {
    this.profile = profile;
  }

  getProfile(): (AuthProfile & { id: any; role: any }) | undefined {
    if (this.isAuthenticated()) {
      return this.profile;
    }

    return undefined;
    // Retrieves the profile data from sessionStorage
    // const profile = sessionStorage.getItem("profile");
    // return profile ? JSON.parse(sessionStorage.profile) : {};
  }

  getRole() {
    const profile = this.getProfile();
    return profile?.role || null;
  }

  setSession = (authResult: any) => {
    // Set the time that the access token will expire at
    if (authResult) {
      this.cookies.set(
        this.isManagerDashboard && this.isImpersonating()
          ? impersonatedTokenKey
          : accessTokenKey,
        authResult.token,
      );
      if (authResult.profile) {
        this.profile = authResult.profile;
      }
    }
  };

  switchDashboardManager = async (
    managerId = "",
    redirectUrl: string | boolean = "",
    skipCheck = false,
    openNewTab = false,
  ) => {
    const isManager = this.isManager(managerId);
    if (skipCheck || isManager) {
      this.setDashboardTenant(managerId);
      if (redirectUrl === false) return;

      const manager = await this.getManagerById(managerId);

      const goToUrl =
        getWhiteLabelBaseUrl(manager) +
        "/login?token=" +
        this.getToken() +
        "&back_to=" +
        (String(redirectUrl) || "/dashboard");

      if (openNewTab) {
        return openUrlInNewWindow(goToUrl);
      }

      return (window.location.href = goToUrl);
    }

    return "";
  };

  switchToDashboard = (_managerId = "", _backTo = "") => {
    window.location.href = buildRouteUrl(managerDashboardRoutes.HOME);
  };

  openNewManagerDashboard = async (managerId: ModelId, redirectUrl = "") => {
    return this.switchDashboardManager(
      String(managerId),
      redirectUrl || undefined,
      true,
      true,
    );
  };

  switchToSite = (url = "") => {
    window.location.href = url || "/";
  };
  unsetDashboardTenant = () => {
    this.cookies.delete("managerDashboardTenant");
  };

  getDashboardManager = () => {
    const managerId = this.cookies.get("managerDashboardTenant");
    return managerId || false;
  };

  setDashboardTenant = (managerId: string) => {
    this.cookies.set("managerDashboardTenant", managerId);
  };

  isImpersonating(): false | UserModel {
    const data = this.cookies.get(impersonatedProfileKey);
    return data ? (JSON.parse(data) as UserModel) : false;
  }

  isManagerImpersonating(): false | UserModel {
    const data = this.cookies.get(impersonatedByManager);
    if (JSON.parse(data || "false")) {
      return this.isImpersonating();
    }

    return false;
  }

  async impersonateUserByManager(userId: string) {
    return this.impersonateUser(userId, true);
  }

  async impersonateUser(userId: string, isManagerImpersonating = false) {
    try {
      const response = await this.apiAxiosRequest?.(
        isManagerImpersonating
          ? "manager/impersonate/" + userId
          : "user/impersonate/" + userId,
        "GET",
        null,
        {},
        true,
      );
      if (response.status === 401) {
        return {
          error:
            (response.data &&
              response.data.errors &&
              response.data.errors[0]) ||
            "Something went wrong",
        };
      }
      const data = response.data;
      this.saveImpersonation(isManagerImpersonating);
      const profile = await this.loadProfile(data.token);
      this.setSession({
        token: data.token,
        profile: profile.data.profile,
      });

      return true;
    } catch (e) {
      logError("err", e);
    }

    return false;
  }

  saveImpersonation(isManagerImpersonating = false) {
    this.cookies.set(
      impersonatedTokenKey,
      this.cookies.get(accessTokenKey) || null,
    );
    this.cookies.set(impersonatedProfileKey, JSON.stringify(this.getProfile()));
    this.cookies.set(
      impersonatedByManager,
      JSON.stringify(isManagerImpersonating),
    );
  }

  revokeImpersonation(redirect = true) {
    this.cookies.set(
      accessTokenKey,
      this.cookies.get(impersonatedTokenKey) || null,
    );
    this.cookies.set(
      "profile",
      this.cookies.get(impersonatedProfileKey) || null,
    );
    this.cookies.delete(impersonatedTokenKey);
    this.cookies.delete(impersonatedProfileKey);
    this.cookies.delete(impersonatedByManager);

    if (redirect) window.location.href = "/";
  }

  async refreshProfile() {
    const profile = await this.loadProfile(String(this.getToken()));
    if (profile.status === 200) {
      this.profile = profile.data.profile;
    }
  }

  removeSession = (redirectUrl = "") => {
    // Clear access token and ID token from local storage
    this.cookies.delete(accessTokenKey);
    this.cookies.delete("expires_at");
    this.cookies.delete("profile");
    this.cookies.delete(COOKIE_NAME_SESSION_ID);
    // user is now logged out
    // navigate to the home route
    if (isClient())
      window.location.href =
        typeof redirectUrl === "string" && redirectUrl ? redirectUrl : "/";
  };

  logout = async (redirectUrl = "") => {
    try {
      await this.apiLogout();
    } catch (e) {
      logError("error logout: ", e);
    }
    this.revokeImpersonation(false);
    this.removeSession(redirectUrl);
  };

  forgotPasswordRequest = async (values: any) => {
    return await this.apiAxiosRequest?.("auth/forgot", "POST", {
      email: values.email,
    })
      .then(({ status }: { status: number }) => {
        if (status === 200) {
          return true;
        }

        return false;
      })
      .catch((error) => {
        throw error.response.data.errors;
      });
  };

  resetPasswordRequest = async (values: any, code: string) => {
    return await this.apiAxiosRequest?.("auth/reset", "POST", {
      password: values.password,
      code: code,
    })
      .then(({ status }: { status: number }) => {
        if (status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        logError(error.response);
        throw error.response.data.errors;
      });
  };

  resendConfirmEmailRequest = async (email: string) => {
    return await this.apiAxiosRequest?.("auth/resend-confirm-email", "POST", {
      email,
    })
      .then(({ status }: { status: number }) => {
        if (status === 200) {
          return true;
        }

        return false;
      })
      .catch((error) => {
        logError(error);
        throw error.response.data.errors;
      });
  };

  confirmEmailRequest = async (code: string) => {
    return await this.apiAxiosRequest?.("auth/confirm-email", "POST", {
      code,
    })
      .then(({ data, status }: { data: any; status: number }) => {
        if (status === 200) {
          this.completeLogin(data);
          return true;
        }

        return false;
      })
      .catch((error) => {
        logError(error);
        throw error.response.data.errors;
      });
  };

  isAuthenticated(): boolean {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    return !!token && !this.isTokenExpired(String(token));
  }

  isAdmin = (): boolean => {
    if (!this.isAuthenticated()) {
      return false;
    }
    // check for admin role for tenancy
    const role = this.getRole();
    return role === "admin";
  };

  getTokenExpirationDate(token: string): Date | null {
    const decoded: any = decode(token);
    if (!decoded.exp) {
      return null;
    }

    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token: string, redirect?: boolean) {
    const date = this.getTokenExpirationDate(token);
    const offsetSeconds = 0;
    if (date === null) {
      return false;
    }
    const isExpired = !(
      date.valueOf() >
      new Date().valueOf() + offsetSeconds * 1000
    );
    if (isExpired && redirect) {
      this.history.push({
        pathname: "/",
        query: { message: "expired" },
      });
    }
    return isExpired;
  }

  async disableMfa() {
    this.profile = await this.apiAxiosRequest?.(
      "user/me/disable-mfa",
      "POST",
      {},
      {},
      this.getToken() || false,
    );
  }

  //to check
  unauthorized(): void {
    this.logout().then();
    // window.location = "/unauthorized";
  }
  getToken(): string | false | null | undefined {
    if (this.cookies) {
      if (this.isManagerDashboard && this.isImpersonating()) {
        // If the user is on a dashboard route
        // we need to use the original access token
        return this.cookies.get(impersonatedTokenKey);
      }

      return this.cookies.get(accessTokenKey);
    }
    return undefined;
  }

  setMFAToken(token: string): void {
    window.sessionStorage && window.sessionStorage.setItem(mfaTokenKey, token);
  }

  getMFAToken(): string | null {
    return window.sessionStorage && window.sessionStorage.getItem(mfaTokenKey);
  }

  deleteMFAToken(): void {
    window.sessionStorage && window.sessionStorage.removeItem(mfaTokenKey);
  }

  // From auth
  loginWithSession(authData: any, s: any) {
    window.location.replace(
      authData.callbackUrl +
        "?token=" +
        s.token +
        (this.authData?.backUrl ? `&back_to=${this.authData.backUrl}` : ""),
    );
  }

  activateImportedUser = async (activateData: {
    email: string;
    password: string;
    token: string;
  }) => {
    const response = await this.apiAxiosRequest?.(
      "auth/activation",
      "post",
      activateData,
    );
    if (response.status === 401) {
      return {
        error:
          (response.data && response.data.errors && response.data.errors[0]) ||
          "Something went wrong",
      };
    }
    return response.data;
  };

  getActivationInfo = async (token: string) => {
    const confirmData = {
      token: token,
    };
    const response =
      (await this.apiAxiosRequest?.(
        "auth/activation/info",
        "post",
        confirmData,
      )) || {};
    if (response.status === 401) {
      return {
        error:
          (response.data && response.data.errors && response.data.errors[0]) ||
          "Something went wrong",
      };
    }

    return response.data;
  };

  initGoogleLogin = async (data: { token: string }) => {
    const response = await this.apiAxiosRequest?.("auth/google", "POST", data);
    if (response.status === 200) {
      if (response.data?.token) {
        return response.data;
      }
    }
  };
}

export default Auth;
export { accessTokenKey as accessTokenCookieName };
