/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BankAccountJsonApiModel = {
  id: "",
  nickname: "",
  name: "",
  "account-type": "",
  "routing-number": "",
  "account-number": "",
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  "lob-account-type": "",
  signatory: "",
  description: "",
  "funding-note": "",
  status: "",
  verified: "",
  entity: {
    jsonApi: "hasOne",
    type: "addresses",
  },
  address: {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "created-at": "",
};
