/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GIGenericJsonApiModel = {
  id: "",
  amount: "",
  "effective-date": "",
  description: "",
  "transaction-type": "",
  "offering-name": "",
  "offering-status": "",
  "is-imported": "",
};
