import React from "react";
import { useMfaModal } from "../context/MfaModalContext/MfaModalContext";
import { useAuth } from "../../../../auth";
import { isValidPhoneNumber } from "../../../../utils";
import { MfaModalStep } from "../context/MfaModalContext/MfaModalContext.types";
import { logError } from "../../../../error-handling/logError";
import { MFAModalView } from "./MFAModalView";
import { VeriPhoneInput } from "../../../form-controls";
import {
  formatPhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { CountryCode } from "libphonenumber-js";

const MfaPhoneTypeSendVerificationCodeStep: React.FC = () => {
  const state = useMfaModal();

  const { auth } = useAuth();
  const submitPhone = async () => {
    state.setState({ isLoading: true, error: "" });
    const { phone, country } = state;
    if (!isValidPhoneNumber(phone)) {
      return state.setState({
        error: "The phone number is not valid",
        isLoading: false,
      });
    }
    const data = {
      phone_number: formatPhoneNumber(phone),
      phone_number_code: getCountryCallingCode(country as CountryCode),
    };
    try {
      const response = await auth.mfaCheckSms(data);
      if (response.status === 200) {
        return state.setState({
          codeSent: true,
          isLoading: false,
          step: MfaModalStep.verifyPhoneVerificationCode,
        });
      }
    } catch (e) {
      logError("err", e);
    }
    return state.setState({ error: "Something went wrong", isLoading: false });
  };

  return (
    <MFAModalView
      title="Confirm your phone number"
      subTitle="Enter the mobile phone number you’d like to enroll in two-factor authentication. We’ll then send a verification code that you’ll confirm in the next step."
      continueLabel="Send code"
      onBack={() => state.setStep(MfaModalStep.start)}
      onContinue={submitPhone}
      continueDisabled={!isValidPhoneNumber(state.phone || "")}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitPhone();
          return false;
        }}
      >
        <VeriPhoneInput
          label="Mobile phone number"
          country={state.country || "US"}
          onChangeCountry={(country) => state.setState({ country })}
          onChange={(phone) => state.setState({ phone })}
          value={state.phone || ""}
        />
      </form>
    </MFAModalView>
  );
};

export default MfaPhoneTypeSendVerificationCodeStep;
