import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { FieldValues, OriginalHookFormReturn, Path, RegisterOptions } from ".";
import { errorMessages } from "../defines";

export interface HookFormInputProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  name: Path<T>;
  rules?: RegisterOptions<T, Path<T>>;
}

export interface UseFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> extends OriginalHookFormReturn<TFieldValues, TContext> {
  setObjectValue?: UseFormSetValue<TFieldValues>;
}

export type HookFormUsedPropKeys =
  | "ref"
  | "name"
  | "onChange"
  | "value"
  | "error";

export function buildErrorMessageForName<T extends FieldValues>(
  form: UseFormReturn<T>,
  name: Path<T>,
) {
  const errorNames = name.split(".");

  const error = errorNames.reduce(
    (errorPath, namePath) => errorPath?.[namePath],
    form.formState.errors as any,
  );

  return error?.message;
}

export interface HookFormAmountDefaultRulesProps {
  allowZero?: boolean;
  maxValue?: number;
  minValue?: number;
  errorMessageAsCurrency?: boolean;
}

export function buildAmountDefaultRules({
  maxValue = 0,
  minValue = 0,
  allowZero = false,
  errorMessageAsCurrency,
}: HookFormAmountDefaultRulesProps) {
  return {
    ...(maxValue > 0 && {
      max: {
        value: maxValue,
        message: errorMessageAsCurrency
          ? errorMessages.maxCurrency(maxValue)
          : errorMessages.maxVal(maxValue),
      },
    }),
    ...(minValue > 0 && {
      min: {
        value: minValue,
        message: errorMessageAsCurrency
          ? errorMessages.minCurrency(minValue)
          : errorMessages.minVal(minValue),
      },
    }),
    ...(!allowZero && {
      validate: (value: number) => {
        if (value == null || value > 0) {
          return undefined;
        }

        return errorMessages.requiredField;
      },
    }),
  };
}

/**
 * Use this only to fix react-hook-from's focus for legacy inputs.
 * Ideally, the ref should be passed to the actual input component displayed on the page.
 */
export const HiddenInputToReceiveFocus = React.forwardRef<
  HTMLInputElement,
  unknown
>((_props, ref) => (
  <input ref={ref} tabIndex={-1} style={{ height: "0", width: "0" }} />
));

HiddenInputToReceiveFocus.displayName = "HiddenInputToReceiveFocus";
