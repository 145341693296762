/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum VerificationStatusEnumType {
  Failed = "Failed",
  ManagerPending = "Manager - Pending",
  NeedsReview = "Needs Review",
  PmPending = "PM - Pending",
  PmRejected = "PM - Rejected",
  Pending = "Pending",
  Unverified = "Unverified",
  Verified = "Verified",
  VerivestPending = "Verivest - Pending",
}
