import DynamicConfigFlags from "../configs";
import useDynamicConfig from "./useDynamicConfig";
import { ManagerModel } from "../../api/models";

export default function useGetDynamicConfigManager() {
  const dynamicConfig = useDynamicConfig();
  return dynamicConfig.get(DynamicConfigFlags.MANAGER) as
    | ManagerModel
    | undefined;
}
