/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum FundBuilderStatusEnumType {
  Draft = "Draft",
  Finalized = "Finalized",
  Paid = "Paid",
  Rejected = "Rejected",
  Reviewed = "Reviewed",
}
