/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AgreementJsonApiModel = {
  id: "",
  name: "",
  "agreement-type": "",
  template: "",
  "created-at": "",
  "updated-at": "",
};
