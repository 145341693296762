import { Box, useToast, UseToastOptions } from "@chakra-ui/react";
import React, { ReactNode } from "react";
export const useToastNotification = () => {
  const toast = useToast();
  return (content: ReactNode, options?: UseToastOptions) => {
    toast({
      position: "top-right",
      render: () => (
        <Box
          cursor="pointer"
          bg="white"
          padding={2}
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
        >
          {content}
        </Box>
      ),
      duration: 5000,
      ...options,
    });
  };
};
