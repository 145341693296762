export const replaceImage = (_i: string, size = "350xAUTO") => {
  const i = String(_i || "");
  if (!i.includes("cdn.verivest.com") && !i.includes("cdn.testverivest.com"))
    return i;
  const tokens = i.split("/");
  const url =
    tokens.slice(0, tokens.length - 1).join("/") +
    `/${size}/` +
    encodeURIComponent(tokens[tokens.length - 1]);
  return url;
};
