import {
  Avatar,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import {
  isClient,
  managerDashboardRoutes,
  openUrlInNewWindow,
} from "@verivest/shared-components";
import { useAuth } from "@verivest/shared-components/src/auth";
import { VeriSpinner } from "@verivest/shared-components/src/components/common/loaders/VeriSpinner";
import { getWhiteLabelBaseUrl } from "@verivest/shared-components/src/utils";
import React, { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import useDynamicConfig, {
  DASHBOARD_MANAGER,
} from "../../hooks/useDynamicConfig";
import usePlacementsApi from "../../hooks/usePlacementsApi";
import { NotificationBell } from "../general";
import {
  ChevronDownIcon,
  CloseIcon,
  MenuIcon,
  SupportIcon,
} from "../general/Icons";
import VeriDropDown from "../general/VeriDropDown";
import HeaderSearch from "../sponsor-dashboard/components/HeaderSearch";
import SponsorDashboardMenu from "./manager-dashboard-menu/SponsorDashboardMenu";

const ProfileButtons = forwardRef(({ profile, ...rest }, ref) => {
  return (
    <Box ref={ref} {...rest} cursor="pointer">
      <Avatar
        data-testid="logged-in-user-avatar"
        name={profile.name}
        mr="16px"
        backgroundColor="bg.lightAccent"
        color={"text.accentTeal"}
        fontWeight="500"
        src={profile.picture}
        css={{
          "&>div": {
            fontSize: "13px!important",
          },
        }}
      />
    </Box>
  );
});

ProfileButtons.displayName = "ProfileButtons";

const ProfileButton = ({ auth, profile, logout }) => {
  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={ProfileButtons} profile={profile}>
        MenuItem
      </MenuButton>
      <MenuList>
        <MenuItem as={RouterLink} to={"/user/settings"}>
          Settings
        </MenuItem>
        {auth.isMallcop() && (
          <MenuItem
            onClick={() =>
              (window.location =
                process.env.RAZZLE_APP_ADMIN_URL +
                "/login?token=" +
                auth.getToken())
            }
          >
            Switch to Admin Portal
          </MenuItem>
        )}
        <MenuItem onClick={() => auth.switchToSite("/")}>
          Switch to Investor Portal
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );
};

const SponsorDashboardHeader = ({ profile, ...rest }) => {
  const { auth } = useAuth();
  const placementsApi = usePlacementsApi();
  const dynamicConfig = useDynamicConfig();

  const isManager = false;
  const selectedManager = dynamicConfig.get(DASHBOARD_MANAGER);

  const isMallcop = auth?.isMallcop() || false;

  const show = false;
  const logout = () => auth.logout(getWhiteLabelBaseUrl(selectedManager));

  const sidebar = useDisclosure();

  const profiles =
    profile?.roles
      ?.filter((role) => role.role === "manager" || role.role === "admin")
      .map((role) => ({
        value: role.manager_id,
        label: role.manager_name,
        domain: role.manager_domain,
      })) || [];

  profiles.sort((a, b) => {
    a = a.label.toLowerCase();
    b = b.label.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  });

  const selectManagerProfile = async (id) => {
    await auth.switchDashboardManager(id, managerDashboardRoutes.HOME, true);
  };

  const Logo = (
    <Image
      height={"24px"}
      alt={(selectedManager && selectedManager.name) || "Verivest's logo"}
      fallbackSrc={
        (selectedManager && selectedManager.logoUrl) || "/images/minilogo.svg"
      }
      src={
        (selectedManager && selectedManager.logoUrl) || "/images/minilogo.svg"
      }
    />
  );

  const LogoAndName = (
    <Flex
      borderRadius={4}
      px={2.5}
      py={2.5}
      alignItems={"center"}
      _hover={
        isMallcop || profiles?.length > 1
          ? {
              bg: "bg.lighter",
              cursor: "pointer",
            }
          : null
      }
      w={"100%"}
    >
      {Logo}
      {(profiles?.length > 1 || isMallcop) && (
        <ChevronDownIcon boxSize={"20px"} ml={2} color={"text.grey"} />
      )}
    </Flex>
  );

  return (
    <>
      <Flex
        pos="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="sticky"
        as="nav"
        align="center"
        justify="space-between"
        wrap="nowrap"
        height="64px"
        bg="white"
        padding="none"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.06), inset 0px -1px 0px #DCE1E7"
        {...rest}
      >
        {/* Mobile Menu Trigger */}
        <Box
          display={{
            base: "flex",
            md: "none",
          }}
          onClick={sidebar.onOpen}
          pl={4}
        >
          <Icon
            as={show ? CloseIcon : MenuIcon}
            color="text.grey"
            boxSize="24px"
          />
        </Box>

        {/* Header Logo */}
        <Flex
          align="center"
          ml={5}
          mr={5}
          height="24px"
          display={{
            base: "none",
            md: "flex",
          }}
        >
          {!isManager && (isMallcop || profiles?.length > 1) ? (
            <VeriDropDown
              triggerComponent={() => LogoAndName}
              ml={3}
              value={selectedManager?.id}
              placeholder={"e.g. Skyline Properties"}
              options={profiles}
              onSelect={(managerId) => selectManagerProfile(managerId)}
              isSelect
              autoComplete="off"
              renderOption={(option) => (
                <>
                  <Box>{option.label}</Box>
                  {option.domain && (
                    <Box color={"text.grey"} textStyle={"bodySmall"}>
                      {option.domain}
                    </Box>
                  )}
                </>
              )}
              recordsViewMinWidth={"320px"}
              placement={"bottom-start"}
              searchInRecordsView={isMallcop}
              searchInRecordsViewApiEndpoint={
                isMallcop && "/managers?filter[search]="
              }
              filterDelay={isMallcop ? 500 : 0}
              onFilter={
                isMallcop &&
                (async (filter) => {
                  if (!filter) return profiles;

                  const results = await placementsApi.apiRequest(
                    "/managers?filter[search]=" + encodeURIComponent(filter),
                  );
                  return (results?.data || []).map((manager) => ({
                    value: manager.id,
                    label: manager.name,
                    domain: manager.domain,
                  }));
                })
              }
              loading={
                isMallcop && (
                  <Flex alignItems={"center"} justifyContent={"center"} py={6}>
                    <VeriSpinner mt={0} />
                  </Flex>
                )
              }
              noOptions={
                isMallcop && (
                  <Box p={6} textAlign={"center"}>
                    No results
                  </Box>
                )
              }
            />
          ) : !isManager && profiles.length === 1 ? (
            <Box>{LogoAndName}</Box>
          ) : (
            <RouterLink to="/">{Logo}</RouterLink>
          )}
        </Flex>

        {/* Search bar */}
        <Flex
          flexGrow={1}
          alignItems={"center"}
          justifyContent={"center"}
          mx={4}
          w={"100%"}
          maxWidth={"578px"}
        >
          <HeaderSearch managerId={selectedManager.id} />
        </Flex>

        {/* Header left actions */}
        <Flex justifyContent={"center"} alignItems={"center"}>
          <Box
            mx={2.5}
            display={{
              base: "none",
              md: "block",
            }}
          >
            <IconButton
              aria-label="Help"
              icon={<SupportIcon boxSize={6} color="text.grey" />}
              variant="ghost"
              rounded={"100%"}
              onClick={() => {
                if (isClient()) {
                  openUrlInNewWindow("https://support.verivest.com/en/");
                }
              }}
            />
          </Box>
          <Box
            mx={2.5}
            display={{
              base: "none",
              md: "block",
            }}
            color="text.grey"
          >
            <NotificationBell />
          </Box>
          <Box ml={2} display={show ? "none" : "block"} whiteSpace="nowrap">
            <ProfileButton auth={auth} profile={profile} logout={logout} />
          </Box>
        </Flex>
      </Flex>

      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent backgroundColor={"bg.grey"}>
          <DrawerBody>
            <SponsorDashboardMenu
              bg="bg.grey"
              borderRight="none"
              onItemSelected={() => sidebar.onClose()}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default SponsorDashboardHeader;
