/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ManagerEmployeeJsonApiModel = {
  id: "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  name: "",
  role: "",
  school: "",
  "img-url": "",
  "degree-or-cert": "",
  "start-date": "",
  "end-date": "",
  description: "",
};
