import { Box, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const Divider = ({ spacing = 12, ...rest }) => {
  const viewType = useBreakpointValue({ base: "mobile", md: "large" });
  const isMobile = viewType === "mobile";

  if (isMobile) {
    return (
      <Box h={"16px"} bg={"bg.grey"} my={spacing} mx={"-32px"} {...rest} />
    );
  }

  return (
    <Box
      borderBottom={"1px solid"}
      borderColor={"border.grey"}
      my={spacing}
      {...rest}
    />
  );
};

export default Divider;
