import React from "react";
import { errorMessages } from "../../../defines";
import { buildErrorMessageForName } from "../../../react-hook-form";
import VeriSelect from "./VeriSelect";
import { HookFormVeriSelectProps } from "./VeriSelect.types";

export function HookFormVeriSelect<T>({
  form,
  name,
  rules,
  ...props
}: HookFormVeriSelectProps<T>) {
  return (
    <VeriSelect
      {...form.register(name, {
        required: {
          value: !!props.isRequired,
          message: errorMessages.requiredField,
        },
        ...rules,
      })}
      error={buildErrorMessageForName(form, name)}
      {...props}
    />
  );
}
