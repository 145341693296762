/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const StatementSetJsonApiModel = {
  id: "",
  name: "",
  "start-date": "",
  "end-date": "",
  "show-ytd": "",
  "show-annualized-return": "",
  "annualized-return-basis": "",
  "show-income-return": "",
  "show-total-return": "",
  "footnotes-raw": "",
  "footnotes-pdf": "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  "show-footnotes": "",
  "show-support-link": "",
  "show-returns-summary": "",
  "show-growth-summary": "",
  status: "",
  "show-unrealized-gain": "",
  message: "",
};
