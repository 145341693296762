import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Controller } from "@verivest/shared-components/src/react-hook-form";
import InputMask from "react-input-mask";
import { formatter, formatterWithDecimals } from "../../utils/utils";
import VeriInput from "./Input";

function AmountInput({
  name,
  type = "text",
  label,
  value,
  onChange,
  error,
  helperText,
  optional,
  propsInput = {},
  mask,
  isBig,
  control,
  isRequired = false,
  allowDecimals = true,
  useGroupSeparator = true,
  maxLength = 16,
  placeholder = "",
  withSymbol,
  valueAsInt,
  maxValue,
  maxValueError = null,
  validate,
  decimalsLimit = 2,
  prefix,
  suffix,
  symbol = "$",
  fRef: ref,
  controllerProps,
  ...rest
}) {
  const format = (val) => {
    if (allowDecimals && isBig) {
      if (!val) return "";
      const cleaned = val.replace(/[^\d.]/g, "");
      const parts = cleaned.split(".");

      if (parts.length > 1 && decimalsLimit >= 0) {
        return (
          formatter.format(parts[0]) + `.${parts[1].slice(0, decimalsLimit)}`
        );
      }
      return formatter.format(val);
    }
    return (val && formatter.format(val)) || "";
  };
  //const parse = (val) => val.replace(/^\$/, "").replace(/\D/g, "");
  const parse = (val) => {
    if (allowDecimals && isBig) {
      // Remove all characters that are not digits or decimal points
      const cleaned = val.replace(/[^\d.]/g, "");

      // Split the string by the decimal point, if present
      const parts = cleaned.split(".");

      if (parts.length > 1 && decimalsLimit >= 0) {
        // If there are more than one decimal points, join the first part and the second part
        // limited to maxDecimals, with a single decimal point
        return parts[0] + "." + parts[1].slice(0, decimalsLimit);
      } else {
        // If there's no decimal point or no limitation on decimals, return the cleaned string as is
        return cleaned;
      }
    }

    return val.replace(/^\$/, "").replace(/\D/g, "");
  };
  const [internalType] = useState(type);
  const r = mask ? { inputRef: ref } : { ref: ref };

  const _styles = isBig
    ? {
        borderRadius: "0px",
        focusBorderColor: "brand.main",
        height: "none",
        borderWidth: "0",
        borderBottomWidth: "1px",
        borderColor: "border.input",
        fontSize: "32px",
        fontFamily: "satoshi",
        fontWeight: "700",
        lineHeight: "44px",
        pt: "12px",
        pb: "12px",
      }
    : {
        focusBorderColor: "brand.main",
        height: "none",
        borderRadius: 4,
        borderColor: "border.input",
        fontFamily: "Nunito Sans",
        pt: 3,
        pb: 3,
        pl: 8,
        fontSize: "md",
      };

  if (control) {
    return (
      <Controller
        rules={{
          required: isRequired ? "This field is required" : false,
          ...(maxValue && {
            max: {
              value: maxValue,
              message: maxValueError || "Max value is " + String(maxValue),
            },
          }),
          ...(validate && { validate }),
        }}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => {
          return (
            <CurrencyInput
              name={name}
              decimalsLimit={decimalsLimit}
              onValueChange={(value) => {
                if (value === undefined || value === null) {
                  return onChange("");
                }
                onChange(valueAsInt ? parseInt(value) : value);
              }}
              maxLength={maxLength}
              customInput={VeriInput}
              value={value}
              error={error?.message}
              label={label}
              helperText={helperText}
              allowNegativeValue={false}
              allowDecimals={allowDecimals}
              decimalSeparator="."
              groupSeparator=","
              disableGroupSeparators={!useGroupSeparator}
              propsInput={{ placeholder, ...propsInput }}
              prefix={prefix}
              suffix={suffix}
              {...rest}
              inputLeft={
                withSymbol ? (
                  <Text color={"text.grey"} textStyle={"bodyRegular"}>
                    {symbol}
                  </Text>
                ) : null
              }
              {...rest}
              greyedOutOnReadOnly
            />
          );
        }}
        control={control}
        name={name}
        {...controllerProps}
      />
    );
  }

  return (
    <FormControl isInvalid={!!error} {...rest}>
      {label && (
        <Flex justifyContent="space-between">
          <FormLabel lineHeight="24px" mb="8px" fontWeight="500">
            {label}
          </FormLabel>
          {optional && (
            <Text color="text.grey" fontWeight="400" fontSize="16px">
              Optional
            </Text>
          )}
        </Flex>
      )}
      <NumberInput
        onChange={(v) => {
          onChange && onChange(parse(v));
        }}
        precision={decimalsLimit}
        value={format(value)}
      >
        <NumberInputField
          pattern={allowDecimals ? "[0-9.]*" : "[0-9]*"}
          name={name}
          {...r}
          as={mask && InputMask}
          mask={mask}
          //value={value}
          _focus={{
            borderColor: "brand.main",
            color: "black",
          }}
          {..._styles}
          bg="white"
          onChange={onChange}
          type={internalType}
          color="black"
          {...propsInput}
        />
      </NumberInput>

      {helperText && !error && (
        <FormHelperText id="email-helper-text">{helperText}</FormHelperText>
      )}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}

AmountInput.propTypes = {
  /**
   * label of the field
   */
  label: PropTypes.string,
  /**
   * Value of the field
   */
  value: PropTypes.string,
  /**
   * Type of rht einput field
   */
  type: PropTypes.string,
  /**
   * props passed to the Input(chakra-ui) field
   */
  propsInput: PropTypes.object,
  /**
   * callback for onChange
   */
  onChange: PropTypes.func,
};

const AmountInputWithRef = React.forwardRef((props, ref) => (
  <AmountInput {...props} fRef={ref} />
));

AmountInputWithRef.displayName = "AmountInput";

export default AmountInputWithRef;
