import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { MFAModalView } from "./MFAModalView";
import { MfaModalStep } from "../context/MfaModalContext/MfaModalContext.types";
import { useMfaModal } from "../context/MfaModalContext/MfaModalContext";
import { useAuth } from "../../../../auth";
import {
  formatPhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { logError } from "../../../../error-handling/logError";
import { MfaCodeInput } from "../../../auth";
import { CountryCode } from "libphonenumber-js";
import { LRPrivText } from "@verivest/logrocket/src";

const MfaPhoneTypeVerifyVerificationCodeStep: React.FC = () => {
  const state = useMfaModal();

  const { auth } = useAuth();
  const verifyCode = async () => {
    state.setState({ isLoading: true, error: "" });
    const { phone, country, verifyCode } = state;
    if (!verifyCode || verifyCode.length !== 6) {
      return state.setState({
        isLoading: false,
        error: "You need to enter the 6 digits code",
      });
    }
    const data = {
      phone_number: formatPhoneNumber(phone),
      phone_number_code: getCountryCallingCode(country as CountryCode),
      code: verifyCode,
    };
    try {
      const response = await auth.mfaConfirmSms(data);
      if (response.status === 200) {
        return state.setState({
          step: MfaModalStep.success,
          isLoading: false,
        });
      }
      if (
        response.data &&
        response.data.errors &&
        response.data.errors.length > 0
      ) {
        return state.setState({
          error: response.data.errors[0].detail,
          isLoading: false,
        });
      }
    } catch (e) {
      logError("err", e);
    }
    return state.setState({ error: "Something went wrong", isLoading: false });
  };

  return (
    <MFAModalView
      title="Confirm your verification code"
      subTitle={
        <LRPrivText>
          Enter the code we sent to {state.phone}. The text message with the
          code may take a few minutes to arrive.
        </LRPrivText>
      }
      continueLabel="Confirm"
      onBack={() => state.setStep(MfaModalStep.sendVerificationCodeToPhone)}
      onContinue={verifyCode}
      continueDisabled={!state.verifyCode || state.verifyCode.length < 6}
    >
      <Box maxWidth="256px" textStyle="bodyRegular">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            verifyCode();
            return false;
          }}
        >
          <MfaCodeInput
            onComplete={(verifyCode: string) => state.setState({ verifyCode })}
            fieldWidth={{ base: "40px", md: "80px" }}
          />
          <Text lineHeight="24px" mt="32px">
            Haven’t received it?{" "}
            <Text
              fontWeight="700"
              cursor="pointer"
              as="span"
              onClick={() =>
                state.setStep(MfaModalStep.sendVerificationCodeToPhone)
              }
            >
              <strong>Resend code</strong>
            </Text>
            .
          </Text>
        </form>
      </Box>
    </MFAModalView>
  );
};

export default MfaPhoneTypeVerifyVerificationCodeStep;
