import pluralize from "pluralize";
import { useContext } from "react";
import useSWR from "swr";
import { buildRouteUrl } from "../../utils";
import { ApiModel, ApiModelOptions } from "../ApiModel";
import {
  JsonApiManyResponse,
  JsonApiSingleResponse,
  ModelScope,
} from "../types";
import { UseModelContext } from "./contexts/useModelContext";
import useApi from "./useApi";
import usePlacementsApi from "./usePlacementsApi";

export type UseVeriModelOptions<Data, TError = unknown> = Omit<
  ApiModelOptions<Data, TError>,
  "apiClient" | "placementsApiClient"
> &
  Partial<
    Pick<ApiModelOptions<Data, TError>, "apiClient" | "placementsApiClient">
  >;

export const useVeriModel = <
  Data,
  TError = unknown,
  TReturn extends ReturnType<typeof ApiModel> = ReturnType<typeof ApiModel>,
>(
  apiModelFn: (options: ApiModelOptions<Data, TError>) => TReturn,
  options?: UseVeriModelOptions<Data, TError>,
): TReturn => {
  const apiClient = useApi();
  const placementsApiClient = usePlacementsApi();
  const _defaults = useContext(UseModelContext);

  const opts = { ..._defaults?.defaults, ...options } as UseVeriModelOptions<
    Data,
    TError
  >;

  const apiModel = apiModelFn({
    ...opts,
    apiClient,
    placementsApiClient,
  });

  const {
    usePlacement,
    urls: scopedUrls,
    url: _userDefinedUrl,
    id,
    urlParams,
    modelScope: _scope,
    forcePlacement,
  } = apiModel.options || {};

  const { useSwr, useSwrMany } = opts || {};

  const scope = _scope || ModelScope.Public;

  const url = _userDefinedUrl || pluralize(apiModel.type);

  const urls =
    scope && scopedUrls && scopedUrls[scope]
      ? scopedUrls[scope]
      : scopedUrls?.[ModelScope.Public];
  const getOneApiUrl = urls?.getOne || `${url}/:id`;
  const getManyApiUrl = urls?.getMany || url;

  const apiHandler =
    forcePlacement?.getOne || usePlacement ? placementsApiClient : apiClient;
  const apiHandlerMany =
    forcePlacement?.getMany || usePlacement ? placementsApiClient : apiClient;

  const swr = useSWR<JsonApiSingleResponse<Data>, TError>(
    useSwr && getOneApiUrl
      ? buildRouteUrl(
          getOneApiUrl,
          { id: String(id), ...urlParams },
          opts?.queryParams,
        )
      : undefined,
    useSwr && getOneApiUrl ? apiHandler.apiRequest : null,
    useSwr && typeof useSwr === "object" && getOneApiUrl
      ? { focusThrottleInterval: 0, ...useSwr }
      : undefined,
  );

  const swrMany = useSWR<JsonApiManyResponse<Data>>(
    useSwrMany && getManyApiUrl
      ? buildRouteUrl(
          getManyApiUrl,
          { id: String(id), ...urlParams },
          opts?.queryParams,
        )
      : undefined,
    useSwrMany && getManyApiUrl ? apiHandlerMany.apiRequest : null,
    useSwrMany && typeof useSwrMany === "object" && getManyApiUrl
      ? { focusThrottleInterval: 0, ...useSwrMany }
      : undefined,
  );

  return {
    ...apiModel,
    swr,
    swrMany,
    data: swr.data ? (swr.data.data as Data) || null : undefined,
    records: swrMany.data?.data || [],
  };
};
