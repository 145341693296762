import { Icon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Button, VeriHeading } from "../../common";
import { VeriTooltip } from "../../popover";
import { Card } from "../Card";
import { TableNoRecordsProps } from "./TableNoRecords.types";

const TableNoRecords: React.FC<TableNoRecordsProps> = ({
  icon,
  title,
  description,
  primaryAction,
  primaryActionLabel,
  secondaryAction,
  secondaryActionLabel,
  primaryActionProps,
  iconBoxSize = 16,
  cardProps,
  popoverText,
}) => {
  const [badgeTriggerRef, setBadgeTriggerRef] =
    React.useState<HTMLDivElement | null>(null);

  return (
    <Card p={0} {...cardProps}>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        direction={"column"}
        p={12}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          direction={"column"}
          maxW={"370px"}
        >
          {icon && (
            <Flex
              w={"112px"}
              h={"112px"}
              bg={"bg.lighter"}
              borderRadius={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              mb={6}
            >
              <Icon as={icon} boxSize={iconBoxSize} color={"#212121"} />
            </Flex>
          )}
          {title && (
            <VeriHeading mb={2} textStyle={"h5"} color={"text.black"}>
              {title}
            </VeriHeading>
          )}
          {description && (
            <Text
              textAlign={"center"}
              mb={2}
              textStyle={"bodyRegular"}
              color={"text.black"}
            >
              {description}
            </Text>
          )}
        </Flex>
        <Flex>
          {secondaryActionLabel && secondaryAction && (
            <Button
              mt={6}
              label={secondaryActionLabel}
              onClick={secondaryAction}
              isLarge
              outline
              mr={6}
            />
          )}
          {primaryActionLabel && primaryAction && (
            <Box mt={6}>
              {popoverText ? (
                <Box position={"relative"} ref={setBadgeTriggerRef}>
                  <VeriTooltip
                    py={"32px"}
                    offsetTop={-32}
                    trigger={badgeTriggerRef as HTMLDivElement}
                    placement={"top"}
                  >
                    <Box>{popoverText}</Box>
                  </VeriTooltip>
                  <Button
                    label={primaryActionLabel}
                    onClick={primaryAction}
                    isLarge
                    {...primaryActionProps}
                  />
                </Box>
              ) : (
                <Button
                  label={primaryActionLabel}
                  onClick={primaryAction}
                  isLarge
                  {...primaryActionProps}
                />
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export default TableNoRecords;
