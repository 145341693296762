import { AccountTypeType } from "@verivest/shared-components/src/api/enums";
import { AccountModel } from "@verivest/shared-components/src/api/models";
import { wizardPrefixPure } from "@verivest/shared-components/src/defines/routes/investor-portal";
import { isCustodiedAccountType } from "@verivest/shared-components/src/utils/accounts";
import { generatePath } from "react-router";
import { getAccountPathForType } from "./accountWizardRoutes";

export const accountToEditStateParameter = "accountToEdit";
export const accountIdParameter = "accountId";

export const editAccountWizardRoutes = {
  base: `/edit-account/:${accountIdParameter}`,
};
export const editAccountInvestorBaseRoute =
  wizardPrefixPure + editAccountWizardRoutes.base;

export const getEditAccountsBasePath = (
  accountId: string,
  isAccountWizard?: boolean,
) => {
  const base = isAccountWizard
    ? editAccountInvestorBaseRoute
    : editAccountWizardRoutes.base;
  return generatePath(base, {
    [accountIdParameter]: accountId,
  });
};

// normally it's an investment flow path, but we need it for the account flow
// isAccountWizard should be true
export const getPathToEditAccount = (
  account: AccountModel,
  isAccountWizard?: boolean,
) => {
  const editAccountBasePath = getEditAccountsBasePath(
    account.id as string,
    isAccountWizard,
  );

  const accountTypeEditionPath = getAccountPathForType(
    account.accountType as AccountTypeType,
  );

  if (isCustodiedAccountType(account.accountType)) {
    const custodiedOwnerAccountTypePath = getAccountPathForType(
      account.custodiedOwner?.accountType as AccountTypeType,
    );

    return `${editAccountBasePath}${accountTypeEditionPath}${custodiedOwnerAccountTypePath}`;
  }

  return `${editAccountBasePath}${accountTypeEditionPath}`;
};
