import { useModel, UseModelOptions } from "../useModel";
import { TagModel } from "../../models";
import { ModelScope } from "../../types";
import ManagerTagEndpoints from "../../endpoints/Tag/manager";

export function useTagModel(opts?: UseModelOptions<TagModel>) {
  const model = useModel<TagModel>("tag", {
    urls: {
      [ModelScope.Manager]: ManagerTagEndpoints,
      [ModelScope.Mallcop]: ManagerTagEndpoints,
    },
    forcePlacement: {
      create: true,
    },
    ...opts,
  });

  return {
    ...model,
  };
}

export default useTagModel;
