/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const InvestorAssistJsonApiModel = {
  id: "",
  investment: {
    jsonApi: "hasOne",
    type: "investments",
  },
  date: "",
  "requested-by": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  notes: "",
  "created-at": "",
  "updated-at": "",
};
