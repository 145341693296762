/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const HoldingJsonApiModel = {
  id: "",
  security: {
    jsonApi: "hasOne",
    type: "securities",
  },
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  "is-erisa": "",
  "maturity-date": "",
  "inception-date": "",
  label: "",
  units: "",
  "unit-price": "",
  "market-value": "",
  "net-capital-contributions": "",
  "capital-account-balance": "",
  status: "",
  rate: "",
  "paid-to-date": "",
  "unit-price-date": "",
  "as-of-date": "",
  "created-at": "",
  "updated-at": "",
};
