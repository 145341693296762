/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const PaperclipIcon = createIcon({
  displayName: "PaperclipIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M8.5 23.175C6.6 23.175 4.7 22.475 3.2 20.975C1.8 19.575 1 17.675 1 15.675C1 13.675 1.8 11.775 3.2 10.375L11 2.575C13.1 0.475 16.6 0.475 18.8 2.575C21 4.675 20.9 8.175 18.8 10.375L11.7 17.475C10.3 18.875 8.1 18.875 6.7 17.475C5.3 16.075 5.3 13.875 6.7 12.475L12.7 6.475C13.1 6.075 13.7 6.075 14.1 6.475C14.5 6.875 14.5 7.475 14.1 7.875L8.1 13.875C7.5 14.475 7.5 15.375 8.1 15.975C8.7 16.575 9.6 16.575 10.2 15.975L17.3 8.875C18 8.175 18.3 7.375 18.3 6.375C18.3 5.475 17.9 4.575 17.3 3.875C15.9 2.475 13.7 2.475 12.4 3.875L4.6 11.675C3.6 12.675 3 14.075 3 15.575C3 17.075 3.6 18.375 4.6 19.475C6.7 21.575 10.2 21.575 12.4 19.475L21.2 10.675C21.6 10.275 22.2 10.275 22.6 10.675C23 11.075 23 11.675 22.6 12.075L13.8 20.875C12.4 22.475 10.5 23.175 8.5 23.175Z" fill="currentColor" /></g>)
});
export default PaperclipIcon;