import React from "react";
import { getURLParams, isClient } from "../../utils";
import { useBooleanState } from "../states";
import { History, Location } from "history";
import qs from "qs";
import { ConfirmModal } from "../../components";
import { Box } from "@chakra-ui/react";
import { TwoWayFactorAuthenticationModal } from "../../components/modals/TwoWayFactorAuthenticationModal";
interface LayoutUserProfile {
  country: string;
  email: string;
  email_verified: boolean;
  first_name: string;
  has_account_ready: boolean;
  id: number;
  last_name: string;
  mfa_type: string;
  name: string;
  page_size: number;
  phone_number: string;
  picture: string;
  tos_accepted_at: string;
  roles: {
    role: string;
    manager_domain: string;
    manager_id: number;
    manager_name: string;
  }[];
}

interface UseLayoutState {
  profile: LayoutUserProfile;
  breakpoint: string;
  drawerOpens: Record<string, number>;
  app: "admin" | "dashboard" | "web" | "auth";
}

interface UseLayoutProps {
  layout: UseLayoutState;
  setProfile: (profile: LayoutUserProfile) => void;
  setDrawerOpenDepth: (drawerId: string, depth: number) => void;
  getDrawerOpenDepth: (drawerId: string) => number;
  increaseDrawerOpenDepth: (drawerId: string) => void;
  decreaseDrawerOpenDepth: (drawerId: string) => void;
}

interface LayoutProviderProps {
  initialProfile: LayoutUserProfile;
  history: History;
  location: Location;
  app: "admin" | "dashboard" | "web" | "auth";
  mfaSkipUrls?: string[];
}

const LayoutInitialState = {
  profile: null,
};

const LayoutContext = React.createContext<UseLayoutProps>({} as UseLayoutProps);

const breakpoints = [900, 1440, 1860, 2400, 2880];
const breakpointNames = ["base", "sm", "md", "lg", "xl", "2xl"];
const LayoutProvider: React.FC<LayoutProviderProps> = ({
  initialProfile,
  history,
  location,
  app,
  children,
  mfaSkipUrls,
}) => {
  const [state, setState] = React.useState<UseLayoutState>({
    ...LayoutInitialState,
    profile: initialProfile,
    breakpoint: "",
    drawerOpens: {},
    app,
  });

  const isMFAConfirmOpen = useBooleanState(false);
  const isMFAWizardOpen = useBooleanState(false);

  const queryParams = getURLParams<{ ask_mfa_setup: string }>(
    isClient() ? window.location.search || "" : "",
  );
  const showMFAModal =
    (!mfaSkipUrls ||
      !mfaSkipUrls.includes(
        isClient() ? window.location.pathname || "" : "",
      )) &&
    queryParams?.ask_mfa_setup === "1";

  const _setState = (newValues: Partial<UseLayoutState>) => {
    setState({ ...state, ...newValues });
  };

  React.useEffect(() => {
    const onWindowResize = () => {
      let breakpoint = 0;
      const screenW = window.innerWidth;
      for (let i = 0; i < breakpoints.length; i++) {
        if (screenW > breakpoints[i]) {
          breakpoint = i + 1;
          break;
        }
      }

      _setState({ breakpoint: breakpointNames[breakpoint] });
    };

    window.addEventListener("resize", onWindowResize);

    onWindowResize();

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  React.useEffect(() => {
    if (showMFAModal) {
      if (state.profile) {
        isMFAConfirmOpen.true();
      }
      const pms = qs.stringify({
        ...queryParams,
        ["ask_mfa_setup"]: undefined,
      });
      history.replace(location.pathname + (pms ? `?${pms}` : ""));
    }
  }, [showMFAModal]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LayoutContext.Provider
      value={{
        layout: state,
        setProfile: (profile) =>
          _setState({
            profile,
          }),
        setDrawerOpenDepth: (drawerId, depth) => {
          _setState({
            drawerOpens: {
              ...state.drawerOpens,
              [drawerId]: depth,
            },
          });
        },
        getDrawerOpenDepth: (drawerId) => {
          return state.drawerOpens[drawerId] || 0;
        },
        increaseDrawerOpenDepth: (drawerId) => {
          _setState({
            drawerOpens: {
              ...state.drawerOpens,
              [drawerId]: (state.drawerOpens[drawerId] || 0) + 1,
            },
          });
        },
        decreaseDrawerOpenDepth: (drawerId) => {
          _setState({
            drawerOpens: {
              ...state.drawerOpens,
              [drawerId]: (state.drawerOpens[drawerId] || 0) - 1,
            },
          });
        },
      }}
    >
      {isMFAConfirmOpen.state ? (
        <ConfirmModal
          title={"Do you want to secure your account?"}
          cancelLabel={"Skip for now"}
          message={
            <>
              By setting up MFA, you add an extra layer of security to your
              Verivest account. For example, you first enter your password and,
              when prompted, you also type a dynamically generated verification
              code provided by an authenticator app or sent to your phone.
              <Box mt={4}>
                If you prefer you can skip and configure it at another time.
              </Box>
            </>
          }
          confirmLabel={"Setup two factor authentication"}
          isOpen
          onClose={isMFAConfirmOpen.false.bind(null)}
          onConfirm={() => {
            isMFAConfirmOpen.false();
            isMFAWizardOpen.true();
          }}
        />
      ) : undefined}
      {isMFAWizardOpen.state ? (
        <TwoWayFactorAuthenticationModal
          isOpen
          onClose={isMFAWizardOpen.false.bind(null)}
        />
      ) : undefined}
      {children}
    </LayoutContext.Provider>
  );
};

const useLayout = () => {
  const context = React.useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};

export { LayoutProvider, useLayout };
