import { Box } from "@chakra-ui/react";
import React from "react";
import { dividerTestIds } from "./Divider.TestIds";
import { DividerProps } from "./Divider.types";

const Divider: React.FC<DividerProps> = ({ spacing = 12, ...rest }) => (
  <Box
    h={{ base: "16px", md: "unset" }}
    bg={{ base: "bg.grey", md: "unset" }}
    mx={{ base: "-32px", md: "unset" }}
    my={spacing}
    borderBottomColor={"border.grey"}
    borderBottomStyle={{ base: "none", md: "solid" }}
    borderBottomWidth={"1px"}
    data-testid={{
      base: dividerTestIds.mobileDivider,
      md: dividerTestIds.generalDivider,
    }}
    {...rest}
  />
);

export default Divider;
