/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BillingEventJsonApiModel = {
  id: "",
  "price-id": "",
  "billing-price": {
    jsonApi: "hasOne",
    type: "billing-prices",
  },
  "product-id": "",
  "billing-product": {
    jsonApi: "hasOne",
    type: "billing-products",
  },
  "account-id": "",
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "opportunity-id": "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  "offering-id": "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  "manager-id": "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "billing-entity-id": "",
  "billing-entity": {
    jsonApi: "hasOne",
    type: "billing-entities",
  },
  "refunded-by-event-id": "",
  "refund-of-event-id": "",
  "related-record-id": "",
  "related-record-type": "",
  "billing-invoice-id": "",
  "billing-invoice": {
    jsonApi: "hasOne",
    type: "billing-invoices",
  },
  "created-by-user-id": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "activity-timestamp": "",
  "stripe-invoice-item-id": "",
  description: "",
  amount: "",
  "manually-created": "",
};
