/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const CircleCheckmarkEmptyIcon = createIcon({
  displayName: "CircleCheckmarkEmptyIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 6.13737 18.4136 1.34594 12.6331 1.01792C12.5907 1.00624 12.5461 1 12.5 1C12.4702 1 12.441 1.0026 12.4127 1.0076C12.2757 1.00255 12.1382 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
        fill="currentColor"
      />
    </g>
  ),
});
export default CircleCheckmarkEmptyIcon;
