/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallDefinitionTemplateParamJsonApiModel = {
  id: "",
  "template-id": "",
  "has-preferred-return": "",
  "rule-inputs": {
    jsonApi: "hasMany",
    type: "rule-inputs",
  },
};
