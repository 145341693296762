/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AssetPurchaseDebtJsonApiModel = {
  id: "",
  "asset-purchase": {
    jsonApi: "hasOne",
    type: "asset-purchases",
  },
  "asset-purchase-id": "",
  "loan-type": "",
  "loan-amount": "",
  "created-at": "",
  "updated-at": "",
};
