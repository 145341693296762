import { Box, Flex } from "@chakra-ui/react";
import { Button } from "@verivest/shared-components";
import { useLayout } from "@verivest/shared-components/src/hooks/ui/useLayout";
import React from "react";
import { getLoginURL } from "../../../utils/utils";
import VeriDrawer from "../../general/VeriDrawer";
import HeaderLogo from "./HeaderLogo";
import LoggedMenuItems from "./logged-menu-items/LoggedMenuItems";
import MarketingMenu from "./MarketingMenu";
import ProfileItems from "./ProfileItems";

const MobileMenuDrawer = ({ manager, isOpen, onClose }) => {
  const { layout } = useLayout();
  const profile = layout.profile;

  const [openSubMenu, setOpenSubMenu] = React.useState(-1);

  return (
    <VeriDrawer
      isOpen={isOpen}
      variant={"view-alt"}
      drawerBodyProps={{ p: 0 }}
      onClose={onClose}
    >
      <Box pl={"1.875rem"}>
        <HeaderLogo />
      </Box>

      <Box
        pt={"1.875rem"}
        mb={"1.875rem"}
        borderBottom={"1px solid rgb(196,196,196)"}
      >
        {!manager && !profile && (
          <MarketingMenu
            openSubMenu={openSubMenu}
            setOpenSubMenu={setOpenSubMenu}
            isMobile={true}
          />
        )}
        {!manager && profile && <LoggedMenuItems />}
      </Box>
      <Box mb={"1.875rem"}>
        {!profile && (
          <Box w="full" px={10}>
            <Flex
              alignItems={"center"}
              cursor={"pointer"}
              fontFamily={"satoshi"}
              fontSize={"1em"}
              lineHeight={"28px"}
              fontWeight={400}
              onClick={() => {
                window.location.href = getLoginURL();
              }}
            >
              <span>Log In</span>
            </Flex>
            <Button
              mt={2}
              display="flex"
              as="a"
              href="/get-started"
              textProps={{ whiteSpace: "nowrap" }}
              fontFamily="satoshi"
              fontWeight={400}
              letterSpacing="-0.2px"
              color="#FFFFFF"
              isMedium
              maxW={"100%"}
            >
              Get started
            </Button>
          </Box>
        )}
        {/* profile */}
        {profile && <ProfileItems show={false} manager={manager} />}
      </Box>
    </VeriDrawer>
  );
};

export default MobileMenuDrawer;
