/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const FlattenedInvestmentJsonApiModel = {
  id: "",
  "total-amount": "",
  items: "",
};
