/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const SearchIcon = createIcon({
  displayName: "SearchIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M22.561 20.439L17.328 15.206C18.6406 13.3613 19.2121 11.0907 18.9293 8.84437C18.6465 6.59806 17.5301 4.53999 15.8012 3.07815C14.0724 1.61631 11.8574 0.857409 9.59528 0.951893C7.3332 1.04638 5.1892 1.98734 3.58828 3.58827C1.98735 5.18919 1.04639 7.33319 0.951902 9.59527C0.857418 11.8573 1.61632 14.0723 3.07816 15.8012C4.54 17.53 6.59807 18.6465 8.84438 18.9293C11.0907 19.2121 13.3613 18.6406 15.206 17.328L20.439 22.561C20.7204 22.8424 21.1021 23.0005 21.5 23.0005C21.898 23.0005 22.2796 22.8424 22.561 22.561C22.8424 22.2796 23.0005 21.898 23.0005 21.5C23.0005 21.102 22.8424 20.7204 22.561 20.439ZM3.00001 10C3.00001 8.61553 3.41055 7.26215 4.17972 6.11101C4.94889 4.95986 6.04214 4.06266 7.32123 3.53284C8.60031 3.00303 10.0078 2.86441 11.3656 3.1345C12.7235 3.4046 13.9708 4.07128 14.9498 5.05025C15.9287 6.02922 16.5954 7.2765 16.8655 8.63437C17.1356 9.99223 16.997 11.3997 16.4672 12.6788C15.9374 13.9579 15.0401 15.0511 13.889 15.8203C12.7379 16.5895 11.3845 17 10 17C8.14414 16.9979 6.3649 16.2597 5.0526 14.9474C3.7403 13.6351 3.00213 11.8559 3.00001 10Z" fill="currentColor" /></g>)
});
export default SearchIcon;