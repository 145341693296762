/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetBillingPricesOutputJsonApiModel = {
  id: "",
  "billing-prices": {
    jsonApi: "hasMany",
    type: "billing-prices",
  },
};
