/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const OfferingJsonApiModel = {
  id: "",
  ticker: "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  name: "",
  "short-name": "",
  slug: "",
  "offering-type": "",
  "offering-min-amount": "",
  "offering-max-amount": "",
  "investment-min-amount": "",
  "investment-max-amount": "",
  "ds-entity-template-id": "",
  "ds-individual-template-id": "",
  "ta-offering-id": "",
  status: "",
  "allows-non-accredited": "",
  "allows-reinvest": "",
  "allows-many-contributions": "",
  "allows-split-distributions": "",
  "allows-bank": "",
  "allows-check": "",
  "broker-vetted": "",
  "requires-accreditation": "",
  "requires-qualified-purchaser": "",
  "hide-distributions": "",
  "hide-net-income": "",
  "hide-ownership": "",
  "hide-market-value": "",
  securities: {
    jsonApi: "hasMany",
    type: "securities",
  },
  "total-investor-holdings": "",
  "irr-target": "",
  eqm: "",
  exemption: {
    jsonApi: "hasOne",
    type: "offering-exemptions",
  },
  "exemption-id": "",
  entity: {
    jsonApi: "hasOne",
    type: "entities",
  },
  "entity-id": "",
  "investment-type": "",
  "created-at": "",
  "updated-at": "",
};
