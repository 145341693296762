/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImportUserJsonApiModel = {
  id: "",
  import: {
    jsonApi: "hasOne",
    type: "imports",
  },
  "external-id": "",
  row: "",
  status: "",
  errors: "",
  metadata: "",
  "created-at": "",
  "updated-at": "",
  matched: "",
  "match-id": "",
  match: {
    jsonApi: "hasOne",
    type: "users",
  },
  "manager-id": "",
  name: "",
  "first-name": "",
  "last-name": "",
  email: "",
  "client-account-id": "",
};
