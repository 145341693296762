/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UserJsonApiModel = {
  id: "",
  name: "",
  nickname: "",
  "first-name": "",
  "last-name": "",
  email: "",
  "email-type": "",
  "email-verified": "",
  "mfa-type": "",
  picture: "",
  phone: "",
  "phone-code": "",
  "phone-type": "",
  country: "",
  "auth0-user-id": "",
  "cognito-id": "",
  "wp-user-id": "",
  "jwt-consumed": "",
  tos_accepted_at: "",
  imported_email_at: "",
  page_size: "",
  usersrole: {
    jsonApi: "hasMany",
    type: "usersroles",
  },
  "is-hubspot-synched": "",
  "permission-level": "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "hs-id": "",
  title: "",
  company: "",
  status: "",
  committed: "",
  emails: {
    jsonApi: "hasMany",
    type: "users-emails",
  },
  phones: {
    jsonApi: "hasMany",
    type: "users-phones",
  },
  tags: {
    jsonApi: "hasMany",
    type: "users-tags",
  },
  "invested-offerings": {
    jsonApi: "hasMany",
    type: "offerings",
  },
  "invested-opportunities": {
    jsonApi: "hasMany",
    type: "opportunities",
  },
  "full-phone": "",
  "created-at": "",
  "updated-at": "",
  "deleted-at": "",
};
