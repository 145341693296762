import { Avatar as ChakraAvatar, Box, Flex } from "@chakra-ui/react";
import React from "react";
import { ColoredTag } from "../../ColoredTag";
import { VeriTableRendererProps } from "../VeriTable.types";

const Avatar: React.FC<VeriTableRendererProps> = ({ value, options }) => {
  return (
    <>
      {value ? (
        <Flex alignItems={"center"}>
          <ChakraAvatar
            width="32px"
            height="32px"
            name={value.pictureComponent ? undefined : value.name}
            src={value.pictureComponent ? undefined : value.picture}
            icon={value.pictureComponent || undefined}
            mr="16px"
            bg={value.pictureComponent ? "bg.greyLight" : undefined}
          />
          {options.showName && <Box data-private>{value.name}</Box>}
          {Array.isArray(value.tags) && value.tags.length > 0 && (
            <>
              {value.tags.map(
                (tag: { label: string; type: string }, tagIndex: number) => (
                  <ColoredTag key={tagIndex} ml={2} type={tag.type as never}>
                    {tag.label}
                  </ColoredTag>
                ),
              )}
            </>
          )}
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default Avatar;
