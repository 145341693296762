/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum ActivityTypeEnumType {
  AcceptPortalInvite = "Accept portal invite",
  Comment = "Comment",
  CreatedAContribution = "Created a contribution",
  CreatedAPledge = "Created a pledge",
  CreatedARedemption = "Created a redemption",
  CreatedContact = "Created contact",
  DownloadedADocument = "Downloaded a document",
  Reviewed = "Reviewed",
  Saved = "Saved",
  SentAnEmail = "Sent an email",
  SentPortalInvite = "Sent portal invite",
  SentResetPassword = "Sent reset password",
  Shared = "Shared",
  StageConverted = "Stage converted",
  ViewedPage = "Viewed page",
  WatchedAVideo = "Watched a video",
}
