import { Link } from "@chakra-ui/react";
import { isMarketingSiteRoute } from "@verivest/shared-components";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ColoredTag from "../../general/ColoredTag";

const MenuItem = ({
  to,
  href,
  currentPath,
  closeMenu,
  children,
  justMobile,
  onClick,
  bigger,
  isNew,
  ...rest
}) => {
  let as = "a";

  const isMarketingRoute = isMarketingSiteRoute();
  if (!isMarketingRoute && to) as = RouterLink;

  return (
    <Link
      onClick={onClick || closeMenu}
      display={justMobile ? { base: "flex", md: "none" } : "flex"}
      alignItems="center"
      as={as}
      height="64px"
      cursor="pointer"
      textDecoration="none"
      to={isMarketingRoute ? undefined : to}
      href={isMarketingRoute ? (to && to) || href : href}
      color={{
        base: "#000",
        md: bigger
          ? currentPath === to
            ? "#000"
            : "#667085"
          : currentPath === to
          ? "#000"
          : "#667085",
      }}
      _hover={{
        textDecoration: "none",
        color: "#667085",
      }}
      ml={10}
      fontFamily={"satoshi"}
      fontSize={"1em"}
      lineHeight={"28px"}
      fontWeight={500}
      letterSpacing="-0.2px"
      {...rest}
    >
      {children}
      {isNew && (
        <ColoredTag ml="8px" bg={"#F9DCEB"} color={"brand.main"}>
          New
        </ColoredTag>
      )}
    </Link>
  );
};

export default MenuItem;
