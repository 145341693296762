import React from "react";
import {
  MfaModalContextProps,
  MfaModalContextProviderProps,
  MfaModalState,
  MfaModalStep,
} from "./MfaModalContext.types";

export const MfaModalContext = React.createContext<MfaModalContextProps>(
  {} as MfaModalContextProps,
);

const MfaModalProvider: React.FC<MfaModalContextProviderProps> = ({
  children,
  closeModal,
  initialStep,
}) => {
  const [modalState, setModalState] = React.useState<MfaModalState>({
    authByPhone: true,
    phone: "",
    country: "US",
    step: initialStep || MfaModalStep.start,
    verifyCode: "",
    isLoading: false,
    error: "",
    codeSent: false,
  });

  const setState = (newState: Partial<MfaModalState>) => {
    setModalState((oldState) => ({ ...oldState, ...newState }));
  };

  const setStep = (step: MfaModalStep) => {
    setState({ step });
  };

  return (
    <MfaModalContext.Provider
      value={{
        ...modalState,
        setState,
        setStep,
        closeModal,
      }}
    >
      {children}
    </MfaModalContext.Provider>
  );
};

const useMfaModal = () => {
  const context = React.useContext(MfaModalContext);
  if (context === undefined) {
    throw new Error("useMfaModal must be used within a MfaModalProvider");
  }
  return context;
};

export { useMfaModal, MfaModalProvider };
