import { VeriTableSortDirection } from "./VeriTable.types";
import React from "react";

export const sortingRecordsByColumnName = (
  column: string,
  direction: VeriTableSortDirection,
  records: any[],
  renderer?: React.JSXElementConstructor<any>,
) => {
  if (renderer?.prototype?.sort) {
    return renderer?.prototype?.sort(column, direction, records);
  }

  if (column) {
    return records.sort((a, b) =>
      a[column] > b[column]
        ? direction === "ASC"
          ? 1
          : -1
        : a[column] === b[column]
        ? 0
        : direction === "ASC"
        ? -1
        : 1,
    );
  }

  return records;
};
