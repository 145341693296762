/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum PortalStatusEnumType {
  Active = "Active",
  Invited = "Invited",
  Offline = "Offline",
}
