import { Dict } from "../defines";
import { timeout } from "./ui";

export const isClient = () => {
  return typeof window !== "undefined";
};

export const isServer = () => {
  return typeof process !== "undefined";
};

export function getProperty(object: any, propertyName: string): any {
  const parts = propertyName.split("."),
    length = parts.length;

  let i,
    property = { ...object };

  for (i = 0; i < length; i++) {
    if (!property[parts[i]]) return undefined;
    property = property[parts[i]];
  }

  return property;
}

export function fixObjectArray(obj: Dict): Dict {
  const newO: Dict = {};
  Object.keys(obj).map(
    (k) =>
      (newO[k] =
        (typeof obj[k] === "string" &&
          (obj[k].lastIndexOf(",") !== -1 ? obj[k].split(",") : obj[k])) ||
        obj[k]),
  );
  return newO;
}

export async function runFastRequestWithTimeout(request: () => Promise<any>) {
  await Promise.all([request(), timeout(250)]);
}

export const getEnumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O,
): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

export const getObjectKeys = getEnumKeys;

export const cleanObjectUndefinedValues = <T extends object>(obj: T): T => {
  const newObj = { ...obj };
  getObjectKeys(newObj).forEach((key) => {
    if (newObj[key] === undefined) {
      delete newObj[key];
    }
  });
  return newObj;
};
