import { Box } from "@chakra-ui/react";
import React from "react";
import { usePopper } from "react-popper";
import { useDebounce } from "use-debounce";
import { ChevronDownIcon, CloseIcon, SearchIcon } from "./Icons";
import VeriInput from "./Input";

const OptionElement = React.memo(
  ({
    children,
    value,
    label,
    index,
    selectedElement,
    setSelectedElement,
    onSelect,
    disabled,
    wrapperOptions,
    ...rest
  }) => (
    <Box
      py={4}
      px={4}
      backgroundColor={selectedElement === index ? "bg.grey" : "bg.white"}
      _hover={!disabled && { cursor: "pointer" }}
      onMouseEnter={() => setSelectedElement(index)}
      onClick={() => {
        if (disabled) return;
        onSelect && onSelect(value, label);
      }}
      {...rest}
      {...wrapperOptions}
    >
      {children}
    </Box>
  ),
);
OptionElement.displayName = "OptionElement";

OptionElement.displayName = "OptionElement";

const VeriDropDown = ({
  value,
  options,
  onChange,
  onSelect,
  label,
  placeholder,
  footer,
  noOptions,
  getNoOptions,
  minSearchLength,
  onFilter,
  onFilterChange,
  loading,
  renderOption,
  filterDelay = 0,
  optionProps,
  isSelect,
  isSimpleSelect,
  isFilterable = true,
  triggerComponent,
  persistOnSearch,
  hideCaret,
  clearOnSelect,
  veriInputProps,
  wrapperOptions,
  onSelectOption,
  searchInRecordsView,
  error,
  zIndex,
  recordsViewMinWidth,
  placement = undefined,
  wrapperProps,
  retainFilterValue,
  hideDropDownOnEmptyList = false,
  helperText,
  enterKeyToSelect,
  resultsMaxHeight,
  dropDownRef,
  notClearResultsOnEmptyFilter,
  ...rest
}) => {
  const [rootElementRef, setRootElementRef] = React.useState(null);
  const [availableOptions, setAvailableOptions] = React.useState(options);
  const [listElement, setListItem] = React.useState(null);
  const [searchFilter, _setSearchFilter] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [triggerElRef, setTriggerElRef] = React.useState(null);
  const [selectedElement, setSelectedElement] = React.useState(-1);
  const [asyncFilteredOptions, setAsyncFilteredOptions] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedValueLabel, setSelectedValueLabel] = React.useState("");
  const [simpleSelectSelectedValue, setSimpleSelectSelectedValue] =
    React.useState(null);

  const updateSearchFilter = (value) => {
    _setSearchFilter(value || "");
  };

  const [debouncedSearchFilter] = useDebounce(searchFilter, filterDelay);
  const lowerCaseSearchFilter = (
    (searchInRecordsView ? debouncedSearchFilter : searchFilter) || ""
  ).toLowerCase();

  const { styles, attributes } = usePopper(triggerElRef, listElement, {
    modifiers: [{ name: "offset", enabled: true, options: { offset: [0, 6] } }],
    placement,
  });

  const filteredOptions = Array.isArray(asyncFilteredOptions)
    ? asyncFilteredOptions
    : (availableOptions || []).filter(
        (option) =>
          lowerCaseSearchFilter === "" ||
          option.label.toLowerCase().includes(lowerCaseSearchFilter),
      );

  const setSearchFilter = (filter) => {
    if (!filter && onFilter) {
      //setIsLoading(true);
      if (!notClearResultsOnEmptyFilter) {
        onFilter("")
          .then((results) => setAsyncFilteredOptions(results))
          .finally(() => setIsLoading(false));
      }
    }

    if (onFilterChange) onFilterChange(filter);

    return updateSearchFilter(filter);
  };

  const closeSelect = () => {
    if (clearOnSelect) {
      setIsOpen(false);
      setSearchFilter("");
      setSelectedValueLabel("");
    }

    if (isSelect) {
      setIsOpen(false);
      setSearchFilter(label);
      setSelectedValueLabel(label);
    }

    if (isSimpleSelect) {
      setIsOpen(false);
    }
  };

  const _selectNext = React.useCallback(() => {
    let newSelectedElement = 0;

    if (
      !filteredOptions.length ||
      selectedElement + 1 > filteredOptions.length - 1
    ) {
      newSelectedElement = 0;
    } else {
      newSelectedElement = selectedElement + 1;
    }

    if (
      filteredOptions[newSelectedElement] &&
      filteredOptions[newSelectedElement].disabled
    ) {
      let nextElement = null;

      for (let i = newSelectedElement; i < filteredOptions.length; i++) {
        if (!filteredOptions[i].disabled) {
          nextElement = i;
          break;
        }
      }

      if (nextElement === null) return;
      else newSelectedElement = nextElement;
    }

    setSelectedElement(newSelectedElement);
  }, [selectedElement, filteredOptions]);

  const _selectPrev = React.useCallback(() => {
    let newSelectedElement = 0;

    if (!filteredOptions.length) {
      newSelectedElement = 0;
    } else if (selectedElement - 1 < 0) {
      newSelectedElement = filteredOptions.length - 1;
    } else {
      newSelectedElement = selectedElement - 1;
    }

    if (
      filteredOptions[newSelectedElement] &&
      filteredOptions[newSelectedElement].disabled
    ) {
      let prevElement = null;
      for (let i = newSelectedElement; i >= 0; i--) {
        if (!filteredOptions[i].disabled) {
          prevElement = i;
          break;
        }
      }
      if (prevElement === null) {
        return;
      } else newSelectedElement = prevElement;
    }

    setSelectedElement(newSelectedElement);
  }, [selectedElement, filteredOptions]);

  const handleUserKeyPress = React.useCallback(
    (event) => {
      const { keyCode } = event;

      setIsOpen(true);

      if (keyCode === 38) {
        _selectPrev();
        event.preventDefault();
      } else if (keyCode === 40) {
        _selectNext();
        event.preventDefault();
      } else if (keyCode === 13) {
        setSearchFilter("");
        setIsOpen(false);

        if (enterKeyToSelect && onSelect && asyncFilteredOptions) {
          const __selection = asyncFilteredOptions[selectedElement] || {};
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          _onSelect(__selection.value, __selection.label);
        }

        // _onSelect(filteredOptions[selectedElement]);
        onSelectOption &&
          onSelectOption(filteredOptions[selectedElement], searchFilter);

        closeSelect();

        event.preventDefault();
      }
    },
    [_selectNext], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onOutsideClick = React.useCallback(
    (event) => {
      if (persistOnSearch && searchFilter) return;

      if (rootElementRef && !rootElementRef.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [rootElementRef, searchFilter], // eslint-disable-line react-hooks/exhaustive-deps
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const doFilterAction = React.useCallback(
    (searchFilter, changeLoading) => {
      if (onFilter && searchFilter) {
        if (changeLoading) setIsLoading(true);
        onFilter(searchFilter)
          .then((results) => setAsyncFilteredOptions(results))
          .finally(() => changeLoading && setIsLoading(false));
      }
    },
    [onFilter],
  );

  React.useEffect(() => {
    doFilterAction(debouncedSearchFilter, true);
    // setSelectedElement(0);
  }, [debouncedSearchFilter, doFilterAction]);

  React.useEffect(() => {
    if (!triggerElRef) return;

    triggerElRef.addEventListener("keydown", handleUserKeyPress);

    return () => {
      triggerElRef.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress, triggerElRef]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    window.document.addEventListener("click", onOutsideClick);

    return () => {
      window.document.removeEventListener("click", onOutsideClick);
    };
  }, [rootElementRef, searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setAvailableOptions(options || []);
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!isSelect && !isSimpleSelect) return;

    const sel = (options || []).find(
      (opt) => String(opt.value) === String(value),
    );

    if (retainFilterValue) {
      if (sel) {
        setSearchFilter(sel.label);
      }
    } else {
      if (sel) {
        isSimpleSelect
          ? setSimpleSelectSelectedValue(sel)
          : setSelectedValueLabel(sel.label);
      }
    }
  }, [value, options, isSelect, isSimpleSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (retainFilterValue) {
      updateSearchFilter(value);
    }
  }, [value, retainFilterValue]);

  React.useEffect(() => {
    if (dropDownRef) {
      dropDownRef({
        refreshFilter: doFilterAction,
        setAsyncFilteredOptions: setAsyncFilteredOptions,
      });
    }
  }, [dropDownRef]);

  const _onSelect = (value, label) => {
    if (retainFilterValue) {
      setSearchFilter(label);
      onChange && onChange(options.find((opt) => opt.value === value));
      closeSelect();

      if (onSelect) {
        const selOption = (asyncFilteredOptions || options).find(
          (opt) => opt?.value === value,
        );

        const onSelectResult = onSelect(value, label, searchFilter, selOption);
        if (onSelectResult === false) return;
      }

      return;
    } else if (isSimpleSelect) {
      const selOption = options.find((opt) => opt.value === value);

      onSelect && onSelect(value, label, searchFilter);
      setSimpleSelectSelectedValue(selOption);
      onChange && onChange(selOption);
      setSearchFilter("");
      closeSelect();
      return;
    }

    if (onSelect) {
      const selOption = (asyncFilteredOptions || options).find(
        (opt) => opt?.value === value,
      );

      const onSelectResult = onSelect(value, label, searchFilter, selOption);
      if (onSelectResult === false) return;
    }

    closeSelect();
  };

  const ItemsView = (
    <>
      {isOpen &&
        (searchInRecordsView ||
          !minSearchLength ||
          minSearchLength <= searchFilter?.length) &&
        (!hideDropDownOnEmptyList || filteredOptions?.length > 0) && (
          <>
            <Box
              pos={"fixed"}
              left={0}
              right={0}
              top={0}
              bottom={0}
              bg={"transparent"}
              zIndex={"dropdown"}
              onClick={() => setIsOpen(false)}
            />
            <Box
              ref={setListItem}
              style={styles.popper}
              {...attributes.popper}
              background={"bg.white"}
              left={0}
              right={0}
              w={triggerElRef.offsetWidth}
              boxShadow="0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)"
              borderWidth={1}
              borderStyle={"solid"}
              borderColor={"border.grey"}
              zIndex={"dropdown"}
              minW={recordsViewMinWidth}
            >
              {searchInRecordsView && (
                <Box px={4} pt={5} pb={4}>
                  <VeriInput
                    w={"100%"}
                    inputLeft={
                      <Box>
                        <SearchIcon />
                      </Box>
                    }
                    inputRight={
                      searchFilter && (
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                            setTimeout(() => {
                              setSearchFilter("");
                            }, 50);
                          }}
                          cursor={"pointer"}
                        >
                          <CloseIcon />
                        </Box>
                      )
                    }
                    value={searchFilter}
                    onChange={(e) => setSearchFilter(e.target.value)}
                  />
                </Box>
              )}
              {!isLoading &&
              (isSelect ||
                isSimpleSelect ||
                debouncedSearchFilter?.length > 0) ? (
                <Box
                  {...((isSelect || isSimpleSelect) && {
                    overflow: "auto",
                    maxH: resultsMaxHeight || "260px",
                    zIndex: zIndex || "1500",
                    marginRight: searchInRecordsView ? "6px" : null,
                  })}
                  {...(resultsMaxHeight && {
                    maxH: resultsMaxHeight,
                    overflow: "auto",
                  })}
                  css={
                    (searchInRecordsView || resultsMaxHeight) && {
                      "&::-webkit-scrollbar": {
                        width: "4px",
                      },
                      "&::-webkit-scrollbar-track": {
                        width: "6px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#DCE1E7",
                        borderRadius: "4px",
                        width: "6px",
                      },
                    }
                  }
                >
                  {filteredOptions?.length > 0 ||
                  (searchInRecordsView && !debouncedSearchFilter) ? (
                    filteredOptions.map((option, optionIndex) => {
                      return (
                        <OptionElement
                          value={option.value}
                          label={option.label || ""}
                          index={optionIndex}
                          key={`${option.value}-${optionIndex}`}
                          selectedElement={!option.disabled && selectedElement}
                          setSelectedElement={setSelectedElement}
                          onSelect={_onSelect}
                          disabled={option.disabled || false}
                          wrapperOptions={
                            wrapperOptions && wrapperOptions(option)
                          }
                          {...optionProps}
                        >
                          {renderOption ? renderOption(option) : option.label}
                        </OptionElement>
                      );
                    })
                  ) : noOptions ? (
                    noOptions
                  ) : getNoOptions ? (
                    getNoOptions(searchFilter)
                  ) : (
                    <Box textStyle={"labelSmall"} py={4} px={4}>
                      {" "}
                    </Box>
                  )}
                </Box>
              ) : loading ? (
                loading
              ) : (
                <Box py={4} px={4}>
                  Loading...
                </Box>
              )}
              {footer && footer}
            </Box>
          </>
        )}
    </>
  );

  if (triggerComponent) {
    return (
      <Box ref={setRootElementRef}>
        <Box ref={setTriggerElRef} onClick={() => setIsOpen(true)}>
          {triggerComponent(selectedValueLabel)}
        </Box>
        {ItemsView}
      </Box>
    );
  }

  return (
    <Box ref={setRootElementRef} w={"100%"} {...wrapperProps}>
      {ItemsView}
      <VeriInput
        w={"100%"}
        ref={setTriggerElRef}
        propsInput={{
          placeholder: placeholder,
          w: "100%",
          autoComplete: "off",
          ...(isOpen && { zIndex: "dropdown", position: "relative" }),
          ...rest,
          readOnly: !isFilterable,
          _hover: !isFilterable && { cursor: "pointer" },
        }}
        helperText={helperText}
        onFocus={() => setIsOpen(true)}
        onChange={(e) => setSearchFilter(e.target.value)}
        inputGroupProps={{ w: "100%" }}
        value={
          isOpen || retainFilterValue
            ? searchFilter
            : !isSimpleSelect
            ? selectedValueLabel
            : simpleSelectSelectedValue?.label || ""
        }
        label={label}
        inputRight={
          (isSelect || isSimpleSelect) &&
          !hideCaret && (
            <Box onClick={() => setIsOpen(true)}>
              <ChevronDownIcon />
            </Box>
          )
        }
        error={error}
        {...veriInputProps}
      />
    </Box>
  );
};

export default VeriDropDown;
