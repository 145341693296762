/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CalculateBillingEventDefaultsOutputJsonApiModel = {
  id: "",
  "billing-schedule-id": "",
  basis: "",
  "current-billing-period": "",
  "investor-count": "",
  "previous-aum": "",
  "estimated-aum": "",
  contributions: "",
  redemptions: "",
};
