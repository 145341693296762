/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ControlsVideoPlayIcon = createIcon({
  displayName: "ControlsVideoPlayIcon",
  viewBox: "0 0 48 48",
  path: (
    <g fill="none">
      <g fill="currentColor">
        <path
          d="M40.9705 7.02938C36.4375 2.49638 30.4106 0 24 0C17.5893 0 11.5624 2.49647 7.02938 7.02938C2.49647 11.5624 0 17.5894 0 24C0 30.4107 2.49647 36.4376 7.02938 40.9706C11.5624 45.5035 17.5893 48 24 48C30.4106 48 36.4375 45.5035 40.9705 40.9706C45.5035 36.4375 48 30.4106 48 24C48 17.5894 45.5035 11.5625 40.9705 7.02938ZM37.5832 25.1702L19.5081 37.2201C19.2727 37.377 19.0008 37.4563 18.728 37.4563C18.5004 37.4563 18.2724 37.4012 18.0645 37.2899C17.6073 37.0453 17.3218 36.5687 17.3218 36.0501V11.95C17.3218 11.4314 17.6073 10.9549 18.0645 10.7102C18.5216 10.4655 19.0765 10.4922 19.5081 10.78L37.5832 22.83C37.9744 23.0908 38.2094 23.5299 38.2094 24.0001C38.2093 24.4703 37.9744 24.9093 37.5832 25.1702Z"
          fill="currentColor"
        />
      </g>
    </g>
  ),
});
export default ControlsVideoPlayIcon;
