/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ManagerJsonApiModel = {
  id: "",
  slug: "",
  name: "",
  "img-url": "",
  "card-img-url": "",
  "capital-managed": "",
  "year-founded": "",
  "company-url": "",
  "meta-description": "",
  "description-html": "",
  "description-raw": "",
  "description-video-url": "",
  "description-img-url": "",
  city: "",
  state: "",
  "contact-email": "",
  "minimum-investment": "",
  "years-principal-experience": "",
  "years-real-estate-experience": "",
  "has-coming-soon-opportunities": "",
  "has-open-opportunities": "",
  "verified-capital-managed": "",
  "verified-years-principal": "",
  "verified-years-real-estate": "",
  "show-track-record": "",
  "social-media-links": [],
  "manager-contact": {
    jsonApi: "hasOne",
    type: "manager-contacts",
  },
  opportunities: {
    jsonApi: "hasMany",
    type: "opportunities",
  },
  attributeitems: {
    jsonApi: "hasMany",
    type: "attributeitems",
  },
  principals: {
    jsonApi: "hasMany",
    type: "principals",
  },
  "custom-domains": {
    jsonApi: "hasMany",
    type: "custom_domains",
  },
  "manager-employees": {
    jsonApi: "hasMany",
    type: "manager-employees",
  },
  images: {
    jsonApi: "hasMany",
    type: "images",
  },
  address: {
    jsonApi: "hasOne",
    type: "addresses",
  },
  domain: "",
  "track-record-status": "",
  "verified-track-record": "",
  "last-updated-capital-managed": "",
  "last-updated-status": "",
  "last-updated-track-record-status": "",
  "verification-status": "",
  "review-count": "",
  "review-avg": "",
  "is-logged-user-verified-investor": "",
  "billing-entity": {
    jsonApi: "hasOne",
    type: "billing-entities",
  },
  "is-profile-completed": "",
  "banner-layout": "",
  "banner-images": {
    jsonApi: "hasMany",
    type: "banner-images",
  },
  "parallel-markets-enabled": "",
  "logo-url": "",
  "logo-icon-url": "",
  "support-email": "",
  active: "",
  "print-logo-url": "",
  "hs-enabled": "",
  "hs-events-enabled": "",
  "hs-sync-enabled": "",
  "hs-sync-error": "",
  "hs-portal-id": "",
  "favicon-url": "",
  "is-demo": "",
  "white-label-domain": "",
  "created-at": "",
  "updated-at": "",
};
