/* eslint-disable react/prop-types */
import { Box, Icon } from "@chakra-ui/react";
import React, { useState } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "./Icons";

const BOX_SHADOW = "0 1px 5px 0 rgba(0, 0, 0, 0.1)";

export const Container = ({ children, variant = "default", ...rest }) => {
  return (
    <Box
      display="block"
      w="100%"
      overflowX="auto"
      borderRadius={4}
      border={variant === "list" ? "none" : "1px"}
      boxShadow={variant === "list" ? "none" : BOX_SHADOW}
      borderColor="gray.200"
      {...rest}
    >
      {children}
    </Box>
  );
};

export const Table = ({ children, ...rest }) => {
  return (
    <Box
      as="table"
      w="100%"
      table-layout="auto"
      border-collapse="chevronUp"
      {...rest}
    >
      {children}
    </Box>
  );
};

export const Thead = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <Box as="thead" p={4} textAlign="left" {...rest} ref={ref}>
      {children}
    </Box>
  );
});

Thead.displayName = "Thead";

export const Tbody = ({ children, ...rest }) => {
  return (
    <Box as="tbody" p={4} {...rest}>
      {children}
    </Box>
  );
};

export const Tr = ({ children, ...rest }) => {
  return (
    <Box as="tr" my={1} {...rest}>
      {children}
    </Box>
  );
};

export const Th = ({ children, sortable, sort, onClick, align, ...rest }) => {
  const [isHover, setIsHover] = useState(false);
  const clickable = ((isHover && sortable) || onClick) && true;
  return (
    <Box
      as="th"
      p={"12px"}
      fontSize="14px"
      borderBottom="1px"
      borderBottomColor="gray.200"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      _hover={{
        cursor: clickable ? "pointer" : "",
        color: clickable && "text.grey",
      }}
      onClick={onClick}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      textAlign={align}
      {...rest}
    >
      {sortable && sort === "asc" && (
        <Icon
          as={ArrowUpIcon}
          boxSize="16px"
          mr="4px"
          color="text.grey"
          mt="-4px"
        />
      )}
      {sortable && sort === "desc" && (
        <Icon
          as={ArrowDownIcon}
          boxSize="16px"
          mr="4px"
          color="text.grey"
          mt="-4px"
        />
      )}
      {isHover && sortable && !sort && (
        <Icon
          as={ArrowUpIcon}
          boxSize="16px"
          color="text.grey"
          mr="4px"
          mt="-4px"
        />
      )}
      {children}
    </Box>
  );
};

export const Td = ({ children, onClick, ...rest }) => (
  <Box
    as="td"
    p={"12px"}
    borderBottom="1px"
    fontSize="14px"
    borderBottomColor="gray.200"
    _hover={{ cursor: onClick ? "pointer" : "" }}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Box>
);

export default {
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
};
