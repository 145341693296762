import React from "react";
import { Controller, FieldValues } from "../../../../../react-hook-form";
import { TagsInput } from "..";
import { errorMessages } from "../../../../../defines";
import { HookFormTagsInputProps } from "./HookFormTagsInput.types";

const HookFormTagsInput = <T extends FieldValues>({
  form,
  name,
  rules,
  isRequired,
  ...rest
}: HookFormTagsInputProps<T>) => {
  return (
    <Controller
      control={form.control}
      name={name}
      rules={{
        required: isRequired ? errorMessages.requiredField : undefined,
        ...rules,
      }}
      render={({ field }) => (
        <TagsInput
          ref={field.ref}
          placeholder={"Select as many as you’d like..."}
          label={"Strategies Employed"}
          tagsInInput
          value={
            Array.isArray(field.value)
              ? field.value
              : field.value
              ? [field.value]
              : []
          }
          onChange={(values) => field.onChange(values)}
          isRequired={isRequired}
          {...rest}
        />
      )}
    />
  );
};

export default HookFormTagsInput;
