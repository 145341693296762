//Original https://stackoverflow.com/questions/72780957/get-weeks-in-a-month-as-array-with-day-name-and-date
import { DateOrDateString } from "../../defines";
import { dateToMonYYYY, dateToYYYYMMDDNoIso } from "../../utils/dates";
import { DatePickerWeekDay } from "./types";

export const getWeekDaysOfTheMonthInYear = (
  month: number,
  year: number,
): DatePickerWeekDay[][] => {
  const weeks: { start: number; end: number; sub?: number }[] = [],
    firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month + 1, 0),
    numDays = lastDate.getDate();

  let start = 1;
  let end;
  if (firstDate.getDay() === 1) {
    end = 7;
  } else if (firstDate.getDay() === 0) {
    const preMonthEndDay = new Date(year, month, 0);
    start = preMonthEndDay.getDate() - 6 + 1;
    end = 1;
  } else {
    const preMonthEndDay = new Date(year, month, 0);
    start = preMonthEndDay.getDate() + 1 - firstDate.getDay() + 1;
    end = 7 - firstDate.getDay() + 1;
    weeks.push({
      start: start,
      end: end,
    });
    start = end + 1;
    end = end + 7;
  }
  while (start <= numDays) {
    weeks.push({
      start: start,
      end: end,
    });
    start = end + 1;
    end = end + 7;
    end = start === 1 && end === 8 ? 1 : end;
    if (end > numDays && start <= numDays) {
      end = end - numDays;
      weeks.push({
        start: start,
        end: end,
      });
      break;
    }
  }

  while (weeks.length <= 5) {
    const startDay =
      weeks[weeks.length - 1].end === numDays
        ? 1
        : weeks[weeks.length - 1].end + 1;
    weeks.push({
      start: startDay,
      end: startDay + 7,
      sub: -1,
    });
  }
  const currentDate = new Date();
  return weeks.map(({ start, end, sub: _sub }, index) => {
    const sub = _sub || (start > end && index === 0 ? 1 : 0);
    return Array.from({ length: 7 }, (_, index) => {
      const date = new Date(year, month - sub, start + index);

      date.setHours(
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds(),
      );
      return {
        date: date,
        day: date.getDate(),
        monthName: date.toLocaleString("en", { month: "long" }),
        dayName: date.toLocaleString("en", { weekday: "long" }),
      };
    });
  });
};

export const getRightAndLeftYearsList = (date: Date, qt = 18) => {
  const curYear = date.getFullYear();
  const halfQt = Math.floor(qt / 2);
  const prevYears = [...new Array(halfQt)].map(
    (_, index) => curYear - (halfQt - index),
  );
  const nextYears = [...new Array(halfQt)].map((_, index) => curYear + index);

  return [...prevYears, ...nextYears];
};

export const isDaySelected = (date1: Date, date2: Date) => {
  return (
    new Date(date1.toDateString()).getTime() ==
    new Date(date2.toDateString()).getTime()
  );
};

export const selectedDateConsideringNegativeTimezoneOffset = (
  date: string | null,
) => {
  const selectedDate = new Date(date || new Date());
  if (date) {
    const parsedDate = date.split("-");
    selectedDate.setFullYear(Number(parsedDate[0]));
    selectedDate.setMonth(Number(parsedDate[1]) - 1);
    selectedDate.setDate(Number(parsedDate[2]));
  }

  return selectedDate;
};

export const selectedDateStart = (date: string | null) => {
  const selectedDate = selectedDateConsideringNegativeTimezoneOffset(date);
  selectedDate.setHours(0, 0, 0, 0);
  return selectedDate;
};

export const dateIsSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
export const selectedDateEnd = (date: string | null) => {
  const selectedDate = selectedDateConsideringNegativeTimezoneOffset(date);
  selectedDate.setHours(23, 59, 59, 59);
  return selectedDate;
};

export const getDateValue = (_value: string) => {
  const dateParts = (_value || "").split("/");
  const _n = new Date();

  const nMonth = Number(dateParts[0]) || _n.getMonth() + 1;
  const nDay = Number(dateParts[1]) || _n.getDate();
  const nYear = Number(dateParts[2]) || _n.getFullYear();

  const newDateStr = `${nYear}-${String(nMonth).padStart(2, "0")}-${String(
    nDay,
  ).padStart(2, "0")}`;

  return newDateStr;
};

export interface AllowedDatesOptions {
  minDate?: DateOrDateString;
  maxDate?: DateOrDateString;
  enabledDates?: DateOrDateString[];
  disabledDates?: DateOrDateString[];
}

export function isDateAllowed(
  date: DateOrDateString,
  options: AllowedDatesOptions,
  returnReason: true,
): boolean | string;
export function isDateAllowed(
  date: DateOrDateString,
  options: AllowedDatesOptions,
  returnReason?: false,
): boolean;
export function isDateAllowed(
  date: DateOrDateString,
  options: AllowedDatesOptions,
  returnReason?: boolean,
) {
  // we want to mind a possible negative offset
  const _date = new Date(date);
  const _minDate = options.minDate
    ? selectedDateStart(
        dateToYYYYMMDDNoIso(
          selectedDateConsideringNegativeTimezoneOffset(
            options.minDate as string,
          ),
        ),
      )
    : null;

  const _maxDate = options.maxDate
    ? selectedDateEnd(
        dateToYYYYMMDDNoIso(
          selectedDateConsideringNegativeTimezoneOffset(
            options.maxDate as string,
          ),
        ),
      )
    : null;

  if (options.disabledDates && options.disabledDates.length > 0) {
    const isDisabled = options.disabledDates.some((d) => {
      const _d = new Date(d);
      return dateIsSameDay(_d, _date);
    });

    if (isDisabled) {
      return false;
    }
  }

  if (options.enabledDates && options.enabledDates.length > 0) {
    const isEnabled = options.enabledDates.some((d) => {
      const _d = new Date(d);
      return dateIsSameDay(_d, _date);
    });

    if (!_minDate && !_maxDate) {
      return isEnabled;
    } else if (isEnabled) {
      return true;
    }
  }

  if (_minDate && _maxDate && (_date < _minDate || _date > _maxDate)) {
    if (returnReason) {
      return (
        "You can only choose dates between " +
        dateToMonYYYY(_minDate, true) +
        " and " +
        dateToMonYYYY(_maxDate, true)
      );
    }
    return false;
  }

  if (_minDate && _date < _minDate) {
    if (returnReason) {
      return "You can only choose dates after " + dateToMonYYYY(_minDate, true);
    }
    return false;
  }

  if (_maxDate && _date > _maxDate) {
    if (returnReason) {
      return (
        "You can only choose dates before " + dateToMonYYYY(_maxDate, true)
      );
    }
    return false;
  }

  return true;
}
