/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallSideLetterJsonApiModel = {
  id: "",
  "waterfall-definition-rule-id": "",
  "account-id": "",
  "metric-new-value": "",
  description: "",
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
};
