import { Dict } from "../../../defines";

export const VeriTableStyles: Dict = {
  unstyled: {
    table: {
      my: 8,
    },
    bulkActionsBar: {
      backgroundColor: "bg.white",
      borderTopRadius: "4px",
    },
  },
  unstyledWithStickyRowAction: {
    bulkActionsBar: {
      backgroundColor: "bg.white",
      borderTopRadius: "4px",
      marginTop: "1px",
    },
    stickyTh: {
      position: "sticky",
      left: 0,
      marginTop: "-21px",
    },
    stickyTd: {
      position: "sticky",
      right: 0,
      zIndex: 1,
      marginTop: "-21px",
      bg: "bg.white",
    },
  },
  unstyledRegular: {
    table: {
      my: 8,
    },
    td: {
      textStyle: "bodyRegular",
      fontSize: "16px!important",
    },
    footerRecord: {
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: "border.grey",
      backgroundColor: "bg.grey",
      px: 2,
      py: 4,
      textStyle: "bodyRegularBold",
      color: "text.black",
      fontWeight: "bold!important",
    },
    selectedRow: {
      backgroundColor: "bg.lightestPurple",
    },
    bulkActionsBar: {
      backgroundColor: "bg.white",
      borderTopRadius: "4px",
    },
  },
  blackBorders: {
    table: {
      my: 0,
    },
    td: {
      textStyle: "bodyRegular",
      fontSize: "16px!important",
      borderColor: "black",
      borderWidth: 1,
      p: 3,
    },
    th: {
      textStyle: "bodyRegular",
      fontSize: "16px!important",
      borderColor: "black",
      borderWidth: 1,
    },
  },
  contained: {
    wrapper: {
      boxShadow:
        "0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "border.grey",
      backgroundColor: "bg.white",
      borderRadius: "4px",
    },
    table: {
      p: 0,
      m: 0,
    },
    selectedRow: {
      backgroundColor: "bg.lightestPurple",
    },
    bulkActionsBar: {
      backgroundColor: "bg.white",
      borderTopRadius: "4px",
    },
    th: {
      py: 5,
      textStyle: "bodyRegular",
    },
    td: {
      textStyle: "bodyRegular",
    },
  },
};
