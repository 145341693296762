import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { VeriFormLabel } from "../VeriFormLabel/VeriFormLabel";
import { VeriTextareaProps } from "./VeriTextarea.types";

const VeriTextarea: React.FC<VeriTextareaProps> = React.forwardRef<
  HTMLTextAreaElement,
  VeriTextareaProps
>(
  (
    {
      type = "text",
      label,
      value,
      onChange,
      error,
      helperText,
      height,
      propsTextArea = {},
      isOptional,
      maxCharsLength,
      ref: _,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl isInvalid={!!error} {...rest}>
        {label && (
          <VeriFormLabel isOptional={isOptional}>{label}</VeriFormLabel>
        )}
        <Textarea
          ref={ref}
          value={value}
          _focus={{
            borderColor: "brand.main",
            color: "black",
          }}
          focusBorderColor="brand.main"
          borderRadius={4}
          borderColor="border.input"
          fontSize={14}
          fontFamily="roboto"
          color="black"
          onChange={onChange}
          type={type}
          height={height || "40px"}
          {...propsTextArea}
          maxLength={maxCharsLength}
        />
        <Box
          display={{
            base: "inline-block",
            md: "flex",
          }}
          w={"100%"}
          mt={2}
          justifyContent={"space-between"}
        >
          {helperText && !error && (
            <FormHelperText id="textarea-helper-text" mt={0}>
              {helperText}
            </FormHelperText>
          )}
          {maxCharsLength && (
            <Box
              color={"text.grey"}
              textAlign={"right"}
              textStyle={"text.helperText"}
            >
              <FormHelperText>
                {String(value || "").length}/{maxCharsLength} characters
              </FormHelperText>
            </Box>
          )}
        </Box>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    );
  },
);

VeriTextarea.displayName = "VeriTextarea";

export default VeriTextarea;
