import { Flex, Text } from "@chakra-ui/react";
import { managerDashboardRoutes } from "@verivest/shared-components";
import Button from "@verivest/shared-components/src/buttons/Button";
import {
  ClearErrorOnLocationChange,
  ErrorBoundary,
} from "@verivest/shared-components/src/error-handling/error-boundary";
import { genericErrorMessage } from "@verivest/shared-components/src/error-handling/error-boundary/genericErrorMessage";
import React from "react";
import SponsorDashboardPageContainer from "../../components/main/SponsorDashboardPageContainer";

const DashboardPageContainer =
  SponsorDashboardPageContainer as React.ComponentType;

export const SponsorDashboardErrorBoundary: React.FC = ({ children }) => (
  <ErrorBoundary
    fallback={(onClearError) => (
      <DashboardPageContainer>
        <Flex
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          direction="column"
        >
          <Text m={4} variant="h2" maxW="400px" textAlign="center">
            {genericErrorMessage}
          </Text>
          <Button onClick={onClearError} to={managerDashboardRoutes.HOME}>
            Go to the homepage
          </Button>
          <ClearErrorOnLocationChange onClearError={onClearError} />
        </Flex>
      </DashboardPageContainer>
    )}
  >
    {children}
  </ErrorBoundary>
);
