/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AssetPurchaseJsonApiModel = {
  id: "",
  asset: {
    jsonApi: "hasOne",
    type: "assets",
  },
  "asset-id": "",
  entity: {
    jsonApi: "hasOne",
    type: "entities",
  },
  "entity-id": "",
  "actual-close-date": "",
  "acquisition-date": "",
  "purchase-price": "",
  "member-equity": "",
  "manager-equity": "",
  "expected-sale": "",
  strategy: "",
  "hold-period-months": "",
  "created-at": "",
  "updated-at": "",
};
