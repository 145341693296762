/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const RoundsDollarIcon = createIcon({
  displayName: "RoundsDollarIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 5V19" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M15.375 7.21901C13.754 6.32801 8.648 5.66301 8.648 8.73201C8.648 12.406 15.132 11.109 15.132 14.351C15.132 17.593 10.918 17.349 8 16.296" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default RoundsDollarIcon;