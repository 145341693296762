export const firstNameMaxLength = 35;
export const lastNameMaxLength = 35;
export const emailMaxLength = 254; /*320*/
export const cityMaxLength = 60;
export const stateMaxLength = 100;
export const zipCodeMaxLength = 16;
export const countryMaxLength = 56;
export const signerTitleMaxLength = 100;
export const addressMaxLength = 254;
export const legalNameMaxLength = 100;
export const einMaxLength = 11;
export const accountLegalNameMaxLength = 100;
export const accountNumberMaxLength = 20;
export const custodianNameMaxLength = 100;
export const accountNickNameMaxLength = 60;
export const bankNameMaxLength = 60;
export const routingNumberMaxLength = 10;
export const fundingNoteMaxLength = 200;
