/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const HelpIcon = createIcon({
  displayName: "HelpIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19Z" fill="currentColor" /><path d="M9.853 6.56C11.833 5.685 14.395 5.789 15.27 7.205C16.145 8.621 15.541 10.268 14.041 11.539C12.541 12.81 12 13.5 12 14.5" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default HelpIcon;