import React from "react";
import { Controller, FieldPath } from "../../../react-hook-form";
import { einMaxLength } from "../../../defines";
import { VeriInput } from "../VeriInput";
import { HookFormEinInputProps } from "./EinInput.types";
import { einRegex } from "../../../utils";

export const HookFormEinInput = <T, K extends FieldPath<T>>({
  inputProps,
  form,
  name,
  isRequired = true,
  isDisabled,
  rules,
  ...rest
}: HookFormEinInputProps<T, K>) => {
  return (
    <Controller
      rules={{
        required: isRequired && "You must enter a valid EIN",
        pattern: {
          value: einRegex,
          message: "You must enter a valid EIN",
        },
        ...rules,
      }}
      render={({
        fieldState: { error },
        field: { onChange, value, name, ref },
      }) => (
        <VeriInput
          label="EIN"
          isRequired={isRequired}
          onChange={onChange}
          value={(value || "") as string}
          ref={ref}
          name={name}
          mask="99-9999999"
          maxLength={einMaxLength}
          optional={!isRequired}
          error={error?.message}
          {...{ isDisabled, ...inputProps }}
        />
      )}
      control={form.control}
      name={name}
      {...rest}
    />
  );
};
