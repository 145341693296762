import { AvatarEditor } from "@verivest/shared-components/src/components/common/AvatarEditor";
import React from "react";

const ImageEditor = ({ image, title, description, ...rest }) => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onUpload = rest.onFinish || (async () => {});

  return (
    <AvatarEditor
      title={title || "Image"}
      description={description || "Upload your image"}
      userProfile={{ picture: image }}
      onUpload={onUpload}
      borderRadius={4}
      maskColor={[0, 0, 0, 0.2]}
      editorEnabled
      transparencyBackground
      {...rest}
    />
  );
};

export default ImageEditor;
