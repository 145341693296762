/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum ParallelMarketsAccreditationStatusEnumType {
  Canceled = "canceled",
  Current = "current",
  Expired = "expired",
  NotAccredited = "not_accredited",
  Pending = "pending",
  Rejected = "rejected",
  SubmitterPending = "submitter_pending",
  ThirdPartyPending = "third_party_pending",
}
