/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ReportJsonApiModel = {
  id: "",
  name: "",
  "report-type": "",
  "security-type": "",
  securities: {
    jsonApi: "hasMany",
    type: "securities",
  },
  tags: {
    jsonApi: "hasMany",
    type: "tags",
  },
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "start-date": "",
  "end-date": "",
};
