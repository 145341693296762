/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BackgroundCheckItemJsonApiModel = {
  id: "",
  total: "",
  years: "",
  notes: "",
  "background-check-type": "",
  "background-check": {
    jsonApi: "hasOne",
    type: "background-checks",
  },
};
