/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UsersPhoneJsonApiModel = {
  id: "",
  "user-id": "",
  phone: "",
  "phone-code": "",
  "phone-type": "",
  "created-at": "",
  "updated-at": "",
};
