/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImportAccountJsonApiModel = {
  id: "",
  "import-id": "",
  import: {
    jsonApi: "hasOne",
    type: "imports",
  },
  "external-id": "",
  row: "",
  status: "",
  errors: "",
  metadata: "",
  "created-at": "",
  "updated-at": "",
  matched: "",
  "match-id": "",
  match: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "match-score": "",
  "match-data": {
    jsonApi: "hasOne",
    type: "import-account-match-datas",
  },
  "use-match": "",
  "client-account-id": "",
  "user-email": "",
  "account-name": "",
  "account-type": "",
  "identity-type": "",
  erisa: "",
  "first-name": "",
  "middle-name": "",
  "last-name": "",
  suffix: "",
  ssn: "",
  "home-address1": "",
  "home-address2": "",
  "home-city": "",
  "home-region": "",
  "home-postal-code": "",
  "home-postal-code-suffix": "",
  "home-country": "",
  "mailing-address1": "",
  "mailing-address2": "",
  "mailing-city": "",
  "mailing-region": "",
  "mailing-postal-code": "",
  "mailing-postal-code-suffix": "",
  "mailing-country": "",
  "entity-name": "",
  "entity-type": "",
  "entity-email": "",
  "primary-address1": "",
  "primary-address2": "",
  "primary-city": "",
  "primary-region": "",
  "primary-postal-code": "",
  "primary-postal-code-suffix": "",
  "primary-country": "",
  "mailing-address12": "",
  "mailing-address23": "",
  "mailing-city4": "",
  "mailing-region2": "",
  "mailing-postal-code5": "",
  "mailing-postal-code-suffix6": "",
  "mailing-country7": "",
  ein: "",
  ssn8: "",
  "signer-title": "",
  "custodied-account-number": "",
  "custodied-account-type": "",
  "custodian-name": "",
  "custodian-ein": "",
  "custodian-representative": "",
  "custodian-email": "",
  "custodian-address": "",
  "custodian-address2": "",
  "custodian-city": "",
  "custodian-region": "",
  "custodian-postal-code": "",
  "custodian-postal-code-suffix": "",
  "custodian-country": "",
  "first-name9": "",
  "middle-name10": "",
  "last-name11": "",
  suffix12: "",
  email: "",
  ssn13: "",
  "home-address114": "",
  "home-address215": "",
  "home-city16": "",
  "home-region2": "",
  "home-postal-code17": "",
  "home-postal-code-suffix18": "",
  "home-country19": "",
  "mailing-address120": "",
  "mailing-address221": "",
  "mailing-city22": "",
  "mailing-region3": "",
  "mailing-postal-code23": "",
  "mailing-postal-code-suffix24": "",
  "mailing-country25": "",
  mnumber: "",
};
