/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateManualBillingEventInputJsonApiModel = {
  id: "",
  "billing-schedule-id": "",
  "opportunity-id": "",
  "manual-billing-event-type": "",
  "applied-billing-schedule-tier-factor": "",
  "billing-product-id": "",
  description: "",
  amount: "",
  "investor-count": "",
  "previous-aum": "",
  "estimated-aum": "",
  contributions: "",
  redemptions: "",
  "calculated-amount": "",
  "actual-amount": "",
};
