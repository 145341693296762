/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AdminDashboardSearchJsonApiModel = {
  id: "",
  accounts: {
    jsonApi: "hasMany",
    type: "accounts",
  },
  opportunities: {
    jsonApi: "hasMany",
    type: "opportunities",
  },
  offerings: {
    jsonApi: "hasMany",
    type: "offerings",
  },
  managers: {
    jsonApi: "hasMany",
    type: "managers",
  },
  users: {
    jsonApi: "hasMany",
    type: "users",
  },
  reviews: {
    jsonApi: "hasMany",
    type: "manager-reviews",
  },
  "fund-builders": {
    jsonApi: "hasMany",
    type: "fund-builders",
  },
  custodians: {
    jsonApi: "hasMany",
    type: "custodians",
  },
  "dashboard-search-totals": {
    jsonApi: "hasOne",
    type: "admin-dashboard-search-totals",
  },
};
