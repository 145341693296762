export enum EntityTypeEnum {
  "Corporation" = "Corporation",
  "Inc" = "Inc",
  "Incorporated" = "Incorporated",
  "LC" = "LC",
  "LLC" = "LLC",
  "LLLP" = "LLLP",
  "LLP" = "LLP",
  "LP" = "LP",
  "Ltd" = "Ltd",
  "Co" = "Co",
  "NA" = "NA",
  "NTSA" = "NTSA",
  "Providership" = "Providership",
  "PLLC" = "PLLC",
  "Other" = "Other",
  "Unspecified" = "Unspecified",
}

export enum EntityLocationType {
  "USRegistered" = "U.S. Registered",
  "NonUsRegistered" = "Non-U.S. Registered",
}
