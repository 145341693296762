import { dashboardWizardPrefix } from "./investor-portal";

export const MANAGER_DASHBOARD_ROOT = "/dashboard";

export const managerDashboardRoutes = {
  USER_SETTINGS: "/user/settings",
  HOME: MANAGER_DASHBOARD_ROOT,
  PROFILE: `${MANAGER_DASHBOARD_ROOT}/profile`,
  CONTACTS: `${MANAGER_DASHBOARD_ROOT}/contacts`,
  CONTACT_DETAILS: `${MANAGER_DASHBOARD_ROOT}/contacts/:idContact`,
  CONTACTS_NEW: `${MANAGER_DASHBOARD_ROOT}/contacts/new`,
  IMPORT_CONTACTS: `${dashboardWizardPrefix}import-contacts`,
  IMPORTS: `${MANAGER_DASHBOARD_ROOT}/imports`,
  IMPORTS_BROWNFIELD: `${dashboardWizardPrefix}brownfield/:id`,
  ACCOUNT_CREATE: `${MANAGER_DASHBOARD_ROOT}/accounts/new`,
  ACCOUNT_DETAILS: `${MANAGER_DASHBOARD_ROOT}/accounts/:idAccount`,
  ACCOUNTS: `${MANAGER_DASHBOARD_ROOT}/accounts`,
  INVESTMENTS: `${MANAGER_DASHBOARD_ROOT}/investments`,
  INVESTMENT_CREATE: `${MANAGER_DASHBOARD_ROOT}/investments/new`,
  INVESTMENT_DETAILS: `${MANAGER_DASHBOARD_ROOT}/investments/:idInvestment`,
  OPPORTUNITIES: `${MANAGER_DASHBOARD_ROOT}/opportunities`,
  OPPORTUNITIES_DETAILS: `${MANAGER_DASHBOARD_ROOT}/opportunities/:idOpportunity`,
  OFFERINGS: `${MANAGER_DASHBOARD_ROOT}/offerings`,
  OFFERINGS_DETAILS: `${MANAGER_DASHBOARD_ROOT}/offerings/:idOffering/:tab?`,
  OFFERINGS_ALLOCATION_METHODS: `${MANAGER_DASHBOARD_ROOT}/offerings/allocation-methods/:idAllocation`,
  OFFERING_DISTRIBUTION_LIST: `${MANAGER_DASHBOARD_ROOT}/offerings/:idOffering/distributions#distributions`,
  OFFERINGS_DISTRIBUTIONS: `${MANAGER_DASHBOARD_ROOT}/offerings/distributions/:idDistribution`,
  OFFERINGS_STATEMENT_SETS: `${MANAGER_DASHBOARD_ROOT}/offerings/statement-sets/:idStatementSet`,
  OFFERINGS_GENERATE_STATEMENTS: `${MANAGER_DASHBOARD_ROOT}/offerings/:idOffering/statements/new`,
  OFFERINGS_CHANGE_HOLDING_RATE: `${MANAGER_DASHBOARD_ROOT}/offerings/:idOffering/update-holding-rate/:idAccount/:changeType`,
  OFFERINGS_CHANGE_SECURITY_RATE: `${MANAGER_DASHBOARD_ROOT}/offerings/:idOffering/update-security-rate/:idSecurity/:changeType`,
  EXCHANGE_SECURITIES: `${MANAGER_DASHBOARD_ROOT}/exchange-securities/:idAccount/:idOffering`,
  TRANSFER: `${MANAGER_DASHBOARD_ROOT}/transfer/:idAccount/:idOffering`,
  REPORTS: `${MANAGER_DASHBOARD_ROOT}/reports/:tab?`,
  CUSTOM_REPORTS: `${MANAGER_DASHBOARD_ROOT}/reports/custom-reports`,
  REPORTS_EDIT: `${MANAGER_DASHBOARD_ROOT}/reports/edit/:idReport`,
  REPORTS_NEW: `${MANAGER_DASHBOARD_ROOT}/reports/new`,
  PORTAL_SETTINGS: `${MANAGER_DASHBOARD_ROOT}/portal-settings`,
};
