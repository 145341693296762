/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const FileMoneyIcon = createIcon({
  displayName: "FileMoneyIcon",
  viewBox: "0 0 20 20",
  path: (<g fill="none"><rect width={20} height={20} /><path d="M17.5 0.833313H2.5V19.1666H17.5V0.833313Z" stroke="currentColor" strokeWidth={1.38889} strokeMiterlimit={10} strokeLinecap="square" /><path d="M10 4.16669V15.8334" stroke="currentColor" strokeWidth={1.38889} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12.8121 6.01586C11.4613 5.27336 7.20626 4.71919 7.20626 7.27669C7.20626 10.3384 12.6096 9.25752 12.6096 11.9592C12.6096 14.6609 9.09793 14.4575 6.66626 13.5834" stroke="currentColor" strokeWidth={1.38889} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default FileMoneyIcon;