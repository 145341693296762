/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum CorporationTypeEnumType {
  CCorporation = "C Corporation",
  SCorporation = "S Corporation",
}
