/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuSettingsIcon = createIcon({
  displayName: "MenuSettingsIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <g clipPath="url(#clip0_12051_17030)">
        <path
          d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path
          d="M20.0001 12C19.9989 11.4239 19.9359 10.8496 19.8121 10.287L22.5261 8.232L20.5261 4.768L17.3831 6.094C16.532 5.3137 15.5218 4.72723 14.4221 4.375L14.0001 1H10.0001L9.57812 4.375C8.47847 4.72723 7.46826 5.3137 6.61712 6.094L3.47412 4.768L1.47412 8.232L4.18812 10.287C3.93751 11.4153 3.93751 12.5847 4.18812 13.713L1.47412 15.768L3.47412 19.232L6.61712 17.906C7.46826 18.6863 8.47847 19.2728 9.57812 19.625L10.0001 23H14.0001L14.4221 19.625C15.5218 19.2728 16.532 18.6863 17.3831 17.906L20.5261 19.232L22.5261 15.768L19.8121 13.713C19.9359 13.1504 19.9989 12.5761 20.0001 12V12Z"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_12051_17030">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  ),
});
export default MenuSettingsIcon;
