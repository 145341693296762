import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Route } from "react-router-dom";
import { VeriHeading } from "../common";
import { NotFoundRouteProps } from "./NotFoundRoute.types";

const NotFoundRoute: React.FC<NotFoundRouteProps> = ({ children, ...rest }) => {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;

        return (
          <>
            <Flex justifyContent="center" {...rest}>
              <Flex
                maxWidth="480px"
                width="100%"
                my={"80px"}
                mx={{ base: "20px" }}
                justifyContent="center"
              >
                <Box textAlign="center">
                  <VeriHeading textStyle="h1">Page not found</VeriHeading>
                  <Text mt="12px" mb="24px">
                    Sorry, the page you were looking for could not be found. It
                    might have been removed, renamed, or did not exist in the
                    first place.
                  </Text>
                  {children}
                </Box>
              </Flex>
            </Flex>
          </>
        );
      }}
    />
  );
};

export default NotFoundRoute;
