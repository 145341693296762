import React, { ReactNode } from "react";
import { Dict, ServerError, UniServerError } from "../defines";
import { isClient } from "./common";
import { capitalize } from "./locale";

export function scrollToTop(allowAnimation?: boolean) {
  isClient() && allowAnimation
    ? window.scrollTo({ top: 0, behavior: "smooth" })
    : window.scrollTo(0, 0);
}

export function scrollToId(id: string) {
  const sectionElement = document.getElementById(id);

  if (sectionElement) {
    window.scrollTo({
      behavior: "smooth",
      top: sectionElement.offsetTop - 150,
    });
  }
}

export const getAutocompleteOffFlag = () => {
  if (!isClient()) return "off";
  return window["chrome" as never] ? "chrome-off" : "off";
};

export function getChildWithProps(child: ReactNode, props: Dict) {
  if (React.isValidElement(child)) {
    return React.cloneElement(child, props);
  }
  return child;
}

export async function timeout(ms: number) {
  await new Promise((resolve) => setTimeout(resolve, ms));
}

export const defaultErrorMessage = "Something went wrong";

export function getServerError(
  error: ServerError,
  fallbackError?: string,
): UniServerError {
  if (error) {
    if (typeof error === "string") {
      return { message: String(error) };
    }
    if (error.jsonprc !== undefined && error.error?.message) {
      return {
        message: capitalize(error.error?.message) || defaultErrorMessage,
      };
    }
    if (
      error.response?.data?.jsonprc !== undefined &&
      error.response?.data?.error?.message
    ) {
      return {
        message:
          capitalize(error.response?.data?.error?.message) ||
          defaultErrorMessage,
      };
    }
    if (error?.response?.data.errors?.[1]?.title) {
      return {
        message:
          capitalize(error?.response?.data.errors?.[1]?.title) ||
          defaultErrorMessage,
      };
    }
    if (error?.response?.data.errors?.[0]?.detail) {
      return {
        message:
          capitalize(error?.response?.data.errors?.[0]?.detail) ||
          defaultErrorMessage,
      };
    }
    if (error?.response?.data.errors?.[0]?.title) {
      return {
        message:
          capitalize(error?.response?.data.errors?.[0]?.title) ||
          defaultErrorMessage,
      };
    }
    if (error.errors?.[0]?.detail) {
      return { message: error.errors?.[0]?.detail || defaultErrorMessage };
    }
    if (error.errors?.[0]?.title) {
      return { message: error.errors?.[0]?.title || defaultErrorMessage };
    }
    if (Array.isArray(error) && error?.[0]?.detail) {
      return { message: error?.[0]?.detail || defaultErrorMessage };
    }
    if (Array.isArray(error) && error?.[0]?.title) {
      return { message: error?.[0]?.title || defaultErrorMessage };
    }
    if (error.errors?.[0]?.title) {
      return { message: error.errors?.[0]?.title || defaultErrorMessage };
    }
  }

  return { message: fallbackError || defaultErrorMessage };
}

export function openUrlInNewWindow(url: string) {
  return isClient() && window.open(url, "_blank");
}

export const isTouchEvent = (event: object) => {
  return "touches" in event;
};

export const stopPropagation = (event: React.UIEvent) => {
  event.stopPropagation();
};

export function getVisibleHeight(element: HTMLElement): number {
  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const documentElement = document.documentElement;

  // Calculate the visible height based on the element's position in the viewport
  const visibleHeight =
    Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);

  // If the element is completely outside the viewport, the visible height is 0
  if (visibleHeight < 0) {
    return 0;
  }

  // If the visible height is greater than the document height, use the document height instead
  return Math.min(visibleHeight, documentElement.clientHeight);
}

export function getIntercomPosition() {
  if (isClient() && !!process.env.RAZZLE_APP_INTERCOM_CODE) {
    const intercomLauncher =
      window.document.querySelector(".intercom-launcher");
    if (intercomLauncher) {
      return intercomLauncher.getBoundingClientRect();
    }
  }

  return null;
}
