import { ComponentWithAs, Icon, IconProps, Link, Text } from "@chakra-ui/react";
import { MANAGER_DASHBOARD_ROOT } from "@verivest/shared-components";
import React from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";

interface DashboardMenuLinkProps {
  to: string;
  icon: ComponentWithAs<"svg", IconProps>;
  onClick: () => void;
}

export const DashboardMenuLink: React.FC<DashboardMenuLinkProps> = ({
  to,
  icon,
  children,
  onClick,
}) => {
  const match = useRouteMatch({
    path: to,
    exact: to === MANAGER_DASHBOARD_ROOT ? true : false,
  });

  const isActive = !!match;

  return (
    <Link
      as={RouterLink}
      display={"flex"}
      backgroundColor={"background"}
      borderColor={"transparent"}
      borderRadius={4}
      _hover={{
        backgroundColor: "bg.lighter",
        color: "brand.main",
        borderColor: "transparent",
      }}
      to={to}
      p={2.5}
      mx={4}
      my={0.5}
      transition=".15s background-color color ease"
      color={isActive ? "brand.main" : "text.greyDark"}
      alignItems={"center"}
      onClick={onClick}
    >
      {icon && <Icon mr={4} boxSize={6} as={icon} />}
      <Text as={"span"} textStyle={"bodySmall"} fontWeight={"500"}>
        {children}
      </Text>
    </Link>
  );
};
