import React from "react";
import { ConfirmModal } from "@verivest/shared-components";

const UnsavedChangesModalInitialState = {
  enabled: null,
  message: "Are you sure?",
  callback: null,
};

const UnsavedChangesModalContext = React.createContext(undefined);

const UnsavedChangesModalProvider = ({ children }) => {
  const [state, setState] = React.useState({
    ...UnsavedChangesModalInitialState,
    enabled: false,
  });

  const _setState = (newValues) => {
    setState({ ...state, ...newValues });
  };

  const setCallback = (callback, message) => {
    _setState({ callback, message });
  };

  return (
    <UnsavedChangesModalContext.Provider
      value={{
        unsavedChangesModal: state,
        setCallback,
      }}
    >
      {state.callback && (
        <ConfirmModal
          isOpen={true}
          onClose={() => {
            state.callback(false);
            setCallback(null, "");
          }}
          onConfirm={() => {
            state.callback(true);
            setCallback(null, "");
          }}
          message={
            state.message ||
            "If you leave this page, any unsaved changes will be lost."
          }
          title={"Unsaved changes"}
        />
      )}
      {children(setCallback)}
    </UnsavedChangesModalContext.Provider>
  );
};

export { UnsavedChangesModalProvider };
