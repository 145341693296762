/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const MonitoringReportJsonApiModel = {
  id: "",
  "report-type": "",
  quarter: "",
  year: "",
  "due-date": "",
  "submit-date": "",
  monitoringreportitems: {
    jsonApi: "hasMany",
    type: "monitoringreportitems",
  },
};
