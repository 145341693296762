export const termsOfServiceUrl = "https://www.verivest.com/terms";
export const privacyPolicyUrl = "https://www.verivest.com/privacy";
export const helpCenterUrl = "https://support.verivest.com/en/";
export const supportEmail = "support@verivest.com";
export const onboardingEmail = "onboarding@verivest.com";

export const talkToAnExpertTypeformUrl =
  "https://verivest.typeform.com/talktoanexpert";

export const workWithUsUrl =
  "https://verivest.notion.site/verivest/Working-at-Verivest-2270c9ab083e4875a3ace01978679854";
export const investmentSummitUrl =
  "https://verivest.com/verivest-investment-summit";
