/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const BriefcaseIcon = createIcon({
  displayName: "BriefcaseIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M8 5V1H16V5" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M9 15H1V5H23V15H15" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M22 18V23H2V18" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M15 13H9V17H15V13Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default BriefcaseIcon;