import React from "react";
import { ActivityListProps } from "./ActivityList.types";
import { Box } from "@chakra-ui/react";

const ActivityList: React.FC<ActivityListProps> = ({ children }) => {
  return (
    <Box position="relative">
      <Box
        position={"absolute"}
        top={0}
        bottom={0}
        width={"40px"}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        py={2}
      >
        <Box
          height={"100%"}
          borderLeftWidth={1}
          borderLeftStyle={"solid"}
          borderLeftColor={"border.grey"}
        ></Box>
      </Box>
      <Box w={"100%"} position={"relative"}>
        {children}
      </Box>
    </Box>
  );
};

export default ActivityList;
