/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const SendManagerAgreementDocumentInputJsonApiModel = {
  id: "",
  "fund-builder": "",
  "fund-builder-id": "",
  "waterfall-definition-rules": "",
  "return-url": "",
  "incoming-url": "",
};
