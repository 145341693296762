import React from "react";

import { Box, Flex } from "@chakra-ui/react";
import { BackButton, DashboardPageContainerProps, SectionHeading } from "../";
import { useAuth } from "../../../auth";
import { getLoginURL, isClient, scrollToTop } from "../../../utils";
import { LoadingBox } from "../../common";
import { NotFoundRoute } from "../../NotFoundRoute";

const DashboardPageContainer: React.FC<DashboardPageContainerProps> = ({
  children,
  containerProps,
  noLeftMargin,
  hideSidebar = false,
  backTitle = "Back",
  actions,
  filters,
  onFilterClick,
  backTo,
  title,
  subTitle,
  preTitle,
  titleActionsExtra,
  centerContainer,
  relativeContainer,
  isLoading,
  isNotFound,
}) => {
  const { auth } = useAuth();
  const profile = auth.getProfile();

  React.useEffect(() => {
    if (isClient()) {
      if (!profile) {
        window.location.href = getLoginURL();
      } else if (profile && profile.id && !profile.roles) {
        auth.switchToSite();
      }
    }
  }, [profile]);

  React.useEffect(() => {
    scrollToTop();
  }, []);

  if (!profile || !profile.id || !profile.roles) return <></>;

  let content = (
    <>
      {backTo && backTitle && (
        <Flex w={"100%"} mb={6}>
          <Box w={"100%"}>
            <BackButton my={0} to={backTo}>
              {backTitle}
            </BackButton>
          </Box>
        </Flex>
      )}
      {title && (
        <SectionHeading
          title={title}
          preTitle={preTitle}
          subTitle={subTitle}
          actions={actions}
          filters={filters}
          onFilterClick={onFilterClick}
          actionsExtra={titleActionsExtra}
        />
      )}
      {relativeContainer ? (
        <Box position={"relative"}>{children}</Box>
      ) : isLoading ? (
        <>
          <LoadingBox />
        </>
      ) : isNotFound ? (
        <NotFoundRoute />
      ) : (
        children
      )}
    </>
  );
  if (centerContainer) {
    content = (
      <Flex justifyContent="center">
        {" "}
        <Box maxW="760px">{content}</Box>
      </Flex>
    );
  }
  return (
    <Box
      ml={{
        base: 0,
        md: noLeftMargin ? (hideSidebar ? "0" : "240px") : "260px",
      }}
      mb={"48px"}
      p={6}
      // mt="48px"
      transition=".3s ease"
      {...containerProps}
    >
      {content}
    </Box>
  );
};

export default DashboardPageContainer;
