/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const StarOutlineIcon = createIcon({
  displayName: "StarOutlineIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><g clipPath="url(#clip0_363_73)"><path d="M12 2.49L15.09 8.75L22 9.754L17 14.628L18.18 21.51L12 18.262L5.82 21.51L7 14.628L2 9.754L8.91 8.75L12 2.49Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g><defs><clipPath id="clip0_363_73"><rect width={24} height={24} fill="currentColor" /></clipPath></defs></g>)
});
export default StarOutlineIcon;