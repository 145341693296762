/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DashboardSearchJsonApiModel = {
  id: "",
  accounts: {
    jsonApi: "hasMany",
    type: "accounts",
  },
  opportunities: {
    jsonApi: "hasMany",
    type: "opportunities",
  },
  offerings: {
    jsonApi: "hasMany",
    type: "offerings",
  },
  "dashboard-search-totals": {
    jsonApi: "hasOne",
    type: "dashboard-search-totals",
  },
};
