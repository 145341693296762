/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImportTransactionJsonApiModel = {
  id: "",
  import: {
    jsonApi: "hasOne",
    type: "imports",
  },
  "external-id": "",
  row: "",
  status: "",
  errors: "",
  metadata: "",
  "created-at": "",
  "updated-at": "",
  "account-id": "",
  "security-id": "",
  "client-position-id": "",
  "holding-id": "",
  "account-name": "",
  "effective-date": "",
  amount: "",
  "transaction-type": "",
  description: "",
  "unit-price": "",
  "market-value": "",
  "net-capital-contributions": "",
  "capital-account-balance": "",
};
