import { Box, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { ModelId } from "@verivest/shared-components";
import { useNotificationsModel } from "@verivest/shared-components/src/api/hooks/models/useNotificationsModel/useNotificationsModel";
import NotificationItem from "@verivest/shared-components/src/components/notification/NotificationItem";
import {
  NotificationType,
  PossibleNotificationTypes,
} from "@verivest/shared-components/src/components/notification/notifications.types";
import { useDynamicConfig } from "@verivest/shared-components/src/dynamic-config";
import DynamicConfigFlags from "@verivest/shared-components/src/dynamic-config/configs";
import { useStandardApiHandler } from "@verivest/shared-components/src/error-handling/useStandardApiErrorHandler";
import React from "react";
import {
  AnimationTypes,
  ComponentTransition,
} from "react-component-transition";
import VeriHeading from "../../VeriHeading";
import { BellIcon } from "../Icons";

export const NotificationList = ({
  notifications,
}: {
  notifications: NotificationType[] | null | undefined;
}) => {
  const [listIsLoaded, setListIsLoaded] = React.useState<boolean>(false);
  React.useEffect(() => {
    setListIsLoaded(true);
  }, []);

  if (!notifications || notifications.length === 0) {
    return (
      <Flex
        px={3}
        mt={{ base: "32px", md: "64px" }}
        justifyContent="center"
        textAlign="center"
      >
        <Box>
          <Flex justifyContent="center">
            <Box bg="bg.lighter" borderRadius="50%">
              <Icon
                as={BellIcon}
                m="32px"
                color="icons.grey"
                boxSize={"48px"}
              />
            </Box>
          </Flex>
          <VeriHeading mt="24px" textStyle="h5">
            No notifications yet
          </VeriHeading>
          <Text>Notifications about new activity will show up here.</Text>
        </Box>
      </Flex>
    );
  }

  return (
    <VStack gap={2}>
      {notifications.map((n, i) => {
        const isLast = i === notifications.length - 1;
        return (
          <Notification
            listIsLoaded={listIsLoaded}
            n={n}
            isLast={isLast}
            key={`notification-item-${n.id || i}`}
          />
        );
      })}
    </VStack>
  );
};

const Notification = ({
  n,
  isLast,
  listIsLoaded,
}: {
  n: NotificationType;
  isLast: boolean;
  listIsLoaded: boolean;
}) => {
  const dynamicConfig = useDynamicConfig();
  const dashboardManager = dynamicConfig.get(
    DynamicConfigFlags.DASHBOARD_MANAGER,
  );
  const investorPortalManager = dynamicConfig.get(DynamicConfigFlags.MANAGER);

  const { wrapApiRequest, isLoading } = useStandardApiHandler();

  const notificationModel = useNotificationsModel();

  const [viewed, setViewed] = React.useState<boolean>(n.viewed || false);

  const handleOnOver = wrapApiRequest(async () => {
    await notificationModel.markOneAsViewed(n.id as ModelId);
    setViewed(true);
  });
  return (
    <ComponentTransition
      enterAnimation={AnimationTypes.slideRight.enter}
      animateOnMount={listIsLoaded}
    >
      <Box onMouseOver={n.viewed || isLoading ? undefined : handleOnOver}>
        <NotificationItem
          type={n.type as PossibleNotificationTypes}
          content={n.content}
          createdAt={n.createdAt || ""}
          manager={dashboardManager || investorPortalManager}
          viewed={viewed}
          pb={isLast ? 0 : 3}
          px={5}
          borderBottom={isLast ? "none" : "1px solid"}
          borderColor="lightGrey"
        />
      </Box>
    </ComponentTransition>
  );
};
