/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CalculateTransferOutputJsonApiModel = {
  id: "",
  "from-holding": {
    jsonApi: "hasOne",
    type: "holdings",
  },
  "from-holding-calc-before": {
    jsonApi: "hasOne",
    type: "holding-calcs",
  },
  "from-holding-calc-after": {
    jsonApi: "hasOne",
    type: "holding-calcs",
  },
  "from-holding-calc-after-id": "",
  "to-holding": {
    jsonApi: "hasOne",
    type: "holdings",
  },
  "to-holding-calc-before": {
    jsonApi: "hasOne",
    type: "holding-calcs",
  },
  "to-holding-calc-after": {
    jsonApi: "hasOne",
    type: "holding-calcs",
  },
  "to-holding-calc-after-id": "",
  "transfer-percent": "",
  "trx-units": "",
  "trx-market-value": "",
  "trx-net-capital-contributions": "",
  "trx-capital-account-balance": "",
};
