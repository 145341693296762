import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { Button, isClient } from "@verivest/shared-components";
import { useAuth } from "@verivest/shared-components/src/auth";
import { logError } from "@verivest/shared-components/src/error-handling/logError";
import React, { useEffect } from "react";
import { getLoginURL, toastSuccess } from "../../utils/utils";
import SponsorDashboardMenu from "./manager-dashboard-menu/SponsorDashboardMenu";

export default function SponsorDashboardContainer({
  children,
  hideSidebar = false,
}) {
  const { auth } = useAuth();
  const profile = auth.getProfile();
  const toast = useToast();
  let message;
  let to, onClick;
  if (profile && !profile.email_verified) {
    onClick = async () => {
      try {
        await auth.resendActivationEmail(profile.email);
        toastSuccess(toast, "Verification email sent", {
          description: "Follow the instructions on your email",
        });
      } catch (e) {
        logError("error resending activation", e);
      }
    };
    message = (
      <>
        Your email address needs verification. Please check your inbox and
        confirm. Missing the email? <b>Resend verification</b>
      </>
    );
  } /* else if (profile && !profile.has_account_ready) {
    to = "/accounts";
    message = (
      <>
        <b>Complete your investment account</b> so you don’t miss out on an
        investment opportunity!
      </>
    );
  } */

  useEffect(() => {
    if (isClient()) {
      if (!profile) {
        window.location.href = getLoginURL();
      } else if (profile && profile.id && !profile.roles) {
        auth.switchToSite();
      }
    }
  }, [profile]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!profile || !profile.id || !profile.roles) return <></>;

  return (
    <Box
      as="section"
      minH={(message && "calc(100vh - 64px - 48px)") || "calc(100vh - 64px)"}
    >
      {message && (
        <>
          <Box position="fixed" left="0px" right="0px" zIndex="topBanner">
            <Flex justifyContent={"center"} bg="bg.lightAccent" p="14px">
              <Button empty justifyContent={"center"} to={to} onClick={onClick}>
                <Text
                  textAlign="center"
                  textStyle="bodyRegular"
                  lineHeight="20px"
                  color="text.darkTeal"
                >
                  {message}
                </Text>
              </Button>
            </Flex>
          </Box>
          <Box h="48px" mt="64px"></Box>
        </>
      )}
      {!message && <Box h="0px" mt="64px"></Box>}
      <Box>
        {!hideSidebar && (
          <SponsorDashboardMenu
            mt={message ? "48px" : undefined}
            display={{ base: "none", md: "unset" }}
          />
        )}
        {children}
      </Box>
    </Box>
  );
}
