/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CustodianJsonApiModel = {
  id: "",
  name: "",
  "name-format": "",
  ein: "",
  email: "",
  phone: "",
  "phone-code": "",
  type: "",
  representative: "",
  notes: "",
  address: {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "address-id": "",
  "created-by-user-id": "",
  "created-by-user": {
    jsonApi: "hasOne",
    type: "users",
  },
  "wire-instructions-id": "",
  "wire-instructions": {
    jsonApi: "hasOne",
    type: "documents",
  },
  "forms-id": "",
  forms: {
    jsonApi: "hasOne",
    type: "documents",
  },
  "attachments-id": "",
  attachments: {
    jsonApi: "hasOne",
    type: "documents",
  },
  "created-at": "",
  "updated-at": "",
};
