import { Flex, Spinner } from "@chakra-ui/react";
import React, { useCallback, useRef } from "react";

interface InfiniteScrollProps {
  moreToLoad: boolean;
  isLoading: boolean;
  onLoad: () => void;
}
export const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  children,
  moreToLoad,
  isLoading,
  onLoad,
}) => {
  const intObserver = useRef<IntersectionObserver | null>(null);
  const spinnerRef = useCallback(
    (spinner) => {
      if (isLoading) return;
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((spinners) => {
        if (spinners[0].isIntersecting) {
          onLoad();
        }
      });
      if (spinner) intObserver.current.observe(spinner);
    },
    [isLoading, moreToLoad],
  );

  return (
    <>
      {children}
      {moreToLoad && (
        <Flex ref={spinnerRef} justifyContent="center">
          <Spinner color="brand.main" onClick={onLoad} />
        </Flex>
      )}
    </>
  );
};
