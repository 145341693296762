import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { EyeIcon, EyeOffIcon } from "./Icons";

const VeriInput = React.forwardRef(
  (
    {
      name,
      type = "text",
      label,
      value,
      onChange,
      error,
      helperText,
      optional,
      propsInput = {},
      mask,
      inputLeft,
      inputRight,
      inputLeftProps,
      inputRightProps,
      inputGroupProps,
      defaultValue,
      greyedOutOnReadOnly,
      success,
      maxLength,
      ...rest
    },
    ref,
  ) => {
    const [internalType, setInternalType] = useState(type);
    const r = mask ? { inputRef: ref } : { ref: ref };

    return (
      <FormControl isInvalid={!!error} {...rest}>
        {label && (
          <Flex justifyContent="space-between">
            <FormLabel
              lineHeight="24px"
              mb="8px"
              fontWeight="500"
              data-testid="input-label"
            >
              {label}
            </FormLabel>
            {optional && (
              <Text
                color="text.grey"
                fontWeight="400"
                fontSize="16px"
                data-testid="input-optional"
              >
                Optional
              </Text>
            )}
          </Flex>
        )}
        <InputGroup {...inputGroupProps}>
          {inputLeft && (
            <InputLeftElement pt="8px" {...inputLeftProps}>
              {inputLeft}
            </InputLeftElement>
          )}
          <Input
            name={name}
            {...r}
            as={mask && InputMask}
            mask={mask}
            value={value}
            _focus={{
              borderColor: "brand.main",
              color: "black",
            }}
            _readOnly={
              greyedOutOnReadOnly && {
                backgroundColor: "bg.grey",
              }
            }
            bg="white"
            focusBorderColor="brand.main"
            height="none"
            borderRadius={4}
            borderColor="border.input"
            fontFamily="Nunito Sans"
            pt="12px"
            pb="12px"
            fontSize="md"
            onChange={onChange}
            type={internalType}
            defaultValue={defaultValue}
            color="black"
            pr={type === "password" && "4.5rem"}
            maxLength={mask ? undefined : maxLength}
            {...propsInput}
          />
          {inputRight && (
            <InputRightElement pt="8px" {...inputRightProps}>
              {inputRight}
            </InputRightElement>
          )}
        </InputGroup>
        {type === "password" && (
          <InputRightElement width="4.5rem" mt="44px">
            <Icon
              cursor="pointer"
              color="icons.grey"
              as={internalType === "text" ? EyeOffIcon : EyeIcon}
              onClick={() =>
                setInternalType(internalType === "text" ? "password" : "text")
              }
              boxSize="24px"
              data-testid="input-password-eye"
            />
          </InputRightElement>
        )}
        {helperText && !error && !success && (
          <FormHelperText
            id="email-helper-text"
            data-testid="input-helper-text"
          >
            {helperText}
          </FormHelperText>
        )}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        {success && (
          <Box mt={2} textStyle={"bodySmall"} color={"semantic.success"}>
            {success}
          </Box>
        )}
      </FormControl>
    );
  },
);

VeriInput.displayName = "VeriInput";
VeriInput.propTypes = {
  /**
   * label of the field
   */
  label: PropTypes.string,
  /**
   * Value of the field
   */
  value: PropTypes.string,
  /**
   * Type of rht einput field
   */
  type: PropTypes.string,
  /**
   * props passed to the Input(chakra-ui) field
   */
  propsInput: PropTypes.object,
  /**
   * callback for onChange
   */
  onChange: PropTypes.func,
};

export default VeriInput;
