/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DeleteBillingScheduleInputJsonApiModel = {
  id: "",
  "billing-schedule": {
    jsonApi: "hasOne",
    type: "billing-schedules",
  },
};
