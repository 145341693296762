export const loadGooglePlaces = (callback: () => void): void => {
  const existingScript = document.getElementById("googlePlacesScript");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=" +
      process.env.RAZZLE_APP_GOOGLE_MAPS_API_KEY +
      "&libraries=places";
    script.id = "googlePlacesScript";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export const searchAddress = (
  searchTerm: string,
): Promise<google.maps.places.AutocompletePrediction[] | null> => {
  const service = new window.google.maps.places.AutocompleteService();
  return new Promise((ok) => {
    service.getPlacePredictions(
      {
        input: searchTerm,
      },
      (predictions) => {
        ok(predictions);
      },
    );
  });
};

export const geocodePlace = (
  placeId?: string | null,
): Promise<google.maps.GeocoderResult | null> => {
  const geocoder = new window.google.maps.Geocoder();
  return new Promise((ok) => {
    geocoder.geocode(
      { placeId: placeId },
      (predictions: google.maps.GeocoderResult[] | null) => {
        ok(predictions && predictions.length > 0 ? predictions[0] : null);
      },
    );
  });
};
