import Money from "./Money";
import TagList from "./TagList";
import Number from "./Number";
import Avatar from "./Avatar";
import ImageTitle from "./ImageTitle";
import ColoredTag from "./ColoredTag";
import LinkList from "./LinkList";
import Date from "./Date";
import Actions from "./Actions";
import Percentage from "./Percentage";
import Multiple from "./Multiple";

export const Renderers = {
  Money,
  TagList,
  Number,
  Percentage,
  Multiple,
  Avatar,
  LinkList,
  Date,
  ImageTitle,
  ColoredTag,
  Actions,
};
