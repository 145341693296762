import {
  FieldValues,
  Path,
  PathValue,
  useForm,
  UseFormProps,
  UseFormSetValue,
} from "react-hook-form";
import { UseFormReturn } from "./hook-form-helpers";

export const useVeriForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>(
  props?: UseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues> => {
  const form = useForm<TFieldValues, TContext>(props);

  /**
   * react-hook-form stores the refs in the same order as they are registered.
   * If a form has inputs displayed conditionally, those inputs can be displayed in the dom
   * in a different order than the order in which they are registered.
   * When submitting the form, react-hook-form focuses the first ref, and we want that to also be the first input.
   * Resetting the form resets the order of the refs
   */
  const resetFieldRegisterOrder = () => {
    form.reset(undefined, {
      keepValues: true,
      keepErrors: true,
      keepTouched: true,
      keepDirty: true,
      keepIsSubmitted: true,
      keepSubmitCount: true,
    });
  };

  const handleSubmit: typeof form.handleSubmit = (...params) => {
    return (e) => {
      resetFieldRegisterOrder();
      return form.handleSubmit(...params)(e);
    };
  };

  /**
   *  setValue doesn't work with nested objects well
   *  so before it's fixed, we need to set the values
   *  of the nested objects with this
   **/
  const setObjectValue: UseFormSetValue<TFieldValues> = (
    name,
    value,
    formOptions,
  ) => {
    Object.entries(value || {}).forEach(([attribute, innerValue]) => {
      const innerName = name ? `${name}.${attribute}` : attribute;

      form.setValue(
        innerName as Path<TFieldValues>,
        innerValue as PathValue<TFieldValues, Path<TFieldValues>>,
        formOptions,
      );
    });
  };

  return {
    ...form,
    handleSubmit,
    setObjectValue,
  };
};
