import { useContext } from "react";
import { AuthContext } from "../../auth";

function useDynamicConfig() {
  const { dynamicConfig } = useContext(AuthContext);

  return dynamicConfig;
}

export default useDynamicConfig;
