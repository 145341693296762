import React from "react";
import { Box } from "@chakra-ui/react";
import { VeriTableRendererProps } from "../VeriTable.types";

const Multiple: React.FC<VeriTableRendererProps> = ({ value, column }) => {
  return (
    <Box
      display={{ base: "inline", md: "block" }}
      w={{ base: "auto", md: "100%" }}
      textAlign={"right"}
      data-testid="percentage-box"
    >
      {column.emptyReplace && !value ? column.emptyReplace : `${value}x`}
    </Box>
  );
};

export default Multiple;
