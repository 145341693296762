/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const UploadIcon = createIcon({
  displayName: "UploadIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M2 16V22H22V16" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 17V2" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M18 8L12 2L6 8" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default UploadIcon;