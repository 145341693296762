import React from "react";
import { Box } from "@chakra-ui/react";
import {
  VeriTableRendererProps,
  VeriTableSortDirection,
} from "../VeriTable.types";

const TagList: React.FC<VeriTableRendererProps> = function ({
  value,
  column,
  columnName,
  options,
  record,
  tableObject,
}) {
  if (!Array.isArray(value)) return <>{value}</>;

  const hasClickAction = options.onTagClick;

  const onTagClick = (e: React.MouseEvent<HTMLDivElement>, tag: any) => {
    if (!hasClickAction) return;

    e.stopPropagation();
    e.preventDefault();

    options.onTagClick(tag, record, { column, name: columnName }, tableObject);
  };

  return (
    <>
      {value.map((tag, tagIndex) => {
        return (
          <Box
            display={"inline-block"}
            backgroundColor={"#DCE1E7"}
            px={2}
            py={0.5}
            fontWeight="500"
            textStyle={"bodySmall"}
            color={"text.black"}
            borderRadius={"26px"}
            m={1}
            key={tagIndex}
            _hover={hasClickAction ? { cursor: "pointer" } : {}}
            onClick={
              typeof tag === "object" ? (e) => onTagClick(e, tag.id) : undefined
            }
          >
            {typeof tag === "object" ? tag.name : tag}
          </Box>
        );
      })}
    </>
  );
};

TagList.prototype.sort = (
  column: string,
  direction: VeriTableSortDirection,
  records: any[],
) => {
  return records.sort((a, b) => {
    const aValue = Array.isArray(a[column])
      ? a[column].reduce((value: any, tag: any) => `${value}${tag.name}`, "")
      : "";
    const bValue = Array.isArray(b[column])
      ? b[column].reduce((value: any, tag: any) => `${value}${tag.name}`, "")
      : "";

    return direction === "ASC"
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });

  /*return records.sort((a, b) => {
    console.log(a, b);

    const aValue = a[column.name];
    const bValue = b[column.name];

    if (aValue === bValue) return 0;

    if (aValue === null || aValue === undefined) return -1;
    if (bValue === null || bValue === undefined) return 1;

    if (typeof aValue === "object") {
      if (aValue.name === bValue.name) return 0;
      return aValue.name < bValue.name ? -1 : 1;
    }

    return aValue < bValue ? -1 : 1;
  });*/
};

export default TagList;
