import { Box } from "@chakra-ui/react";
import React from "react";
import { usePopper } from "react-popper";
import { useDebounce } from "use-debounce";
import { Dict } from "../../../../defines";
import { ChevronDownIcon, CloseIcon, SearchIcon } from "../../../../icons";
import { getAutocompleteOffFlag } from "../../../../utils";
import { VeriSpinner } from "../../../common/loaders/VeriSpinner";
import { VeriInput } from "../../VeriInput";
import { DropDownOptionElement } from "./components";
import { VeriDropDownProps } from "./VeriDropDown.types";

const VeriDropDown: React.FC<VeriDropDownProps> = ({
  value,
  options,
  onChange,
  onSelect,
  label,
  placeholder,
  footer,
  noOptions,
  getNoOptions,
  minSearchLength,
  onFilter,
  onFilterChange,
  loading,
  renderOption,
  filterDelay = 0,
  optionProps,
  isSelect,
  isSimpleSelect,
  isFilterable = true,
  triggerComponent,
  persistOnSearch,
  isDashboardSearch,
  hideCaret,
  clearOnSelect,
  veriInputProps,
  wrapperOptions,
  onSelectOption,
  searchInRecordsView,
  error,
  zIndex,
  recordsViewMinWidth,
  placement = undefined,
  wrapperProps,
  retainFilterValue,
  hideDropDownOnEmptyList = false,
  helperText,
  enterKeyToSelect,
  resultsMaxHeight,
  dropDownRef,
  startFilter,
  ...rest
}) => {
  const [rootElementRef, setRootElementRef] =
    React.useState<HTMLDivElement | null>(null);
  const [availableOptions, setAvailableOptions] = React.useState(options);
  const [listElement, setListItem] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [searchFilter, _setSearchFilter] = React.useState(startFilter || "");
  const [isOpen, setIsOpen] = React.useState(false);
  const [triggerElRef, setTriggerElRef] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [selectedElement, setSelectedElement] = React.useState(-1);
  const [asyncFilteredOptions, setAsyncFilteredOptions] =
    React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedValueLabel, setSelectedValueLabel] = React.useState<
    string | undefined
  >("");
  const [simpleSelectSelectedValue, setSimpleSelectSelectedValue] =
    React.useState<any>(null);

  const [debouncedSearchFilter] = useDebounce(searchFilter, filterDelay);
  const lowerCaseSearchFilter = String(
    (searchInRecordsView ? debouncedSearchFilter : searchFilter) || "",
  ).toLowerCase();

  const { styles, attributes } = usePopper(triggerElRef, listElement, {
    modifiers: [],
    placement,
  });

  const filteredOptions: Dict[] = Array.isArray(asyncFilteredOptions)
    ? asyncFilteredOptions
    : (availableOptions || []).filter(
        (option) =>
          lowerCaseSearchFilter === "" ||
          option.label.toLowerCase().includes(lowerCaseSearchFilter),
      );

  const setSearchFilter = (filter: any) => {
    if (!filter && onFilter) {
      setIsLoading(true);
      onFilter("")
        .then((results) => setAsyncFilteredOptions(results))
        .finally(() => setIsLoading(false));
    }

    if (onFilterChange) onFilterChange(filter);

    return _setSearchFilter(filter);
  };

  const closeSelect = () => {
    if (clearOnSelect) {
      setIsOpen(false);
      setSearchFilter("");
      setSelectedValueLabel("");
    }

    if (isSelect) {
      setIsOpen(false);
      setSearchFilter(label);
      setSelectedValueLabel(label);
    }

    if (isSimpleSelect) {
      setIsOpen(false);
    }
  };

  const _selectNext = React.useCallback(() => {
    let newSelectedElement = 0;

    if (
      !filteredOptions.length ||
      selectedElement + 1 > filteredOptions.length - 1
    ) {
      newSelectedElement = 0;
    } else {
      newSelectedElement = selectedElement + 1;
    }

    if (
      filteredOptions[newSelectedElement] &&
      filteredOptions[newSelectedElement].disabled
    ) {
      let nextElement = null;

      for (let i = newSelectedElement; i < filteredOptions.length; i++) {
        if (!filteredOptions[i].disabled) {
          nextElement = i;
          break;
        }
      }

      if (nextElement === null) return;
      else newSelectedElement = nextElement;
    }

    setSelectedElement(newSelectedElement);
  }, [selectedElement, filteredOptions]);

  const _selectPrev = React.useCallback(() => {
    let newSelectedElement = 0;

    if (!filteredOptions.length) {
      newSelectedElement = 0;
    } else if (selectedElement - 1 < 0) {
      newSelectedElement = filteredOptions.length - 1;
    } else {
      newSelectedElement = selectedElement - 1;
    }

    if (
      filteredOptions[newSelectedElement] &&
      filteredOptions[newSelectedElement].disabled
    ) {
      let prevElement = null;
      for (let i = newSelectedElement; i >= 0; i--) {
        if (!filteredOptions[i].disabled) {
          prevElement = i;
          break;
        }
      }
      if (prevElement === null) {
        return;
      } else newSelectedElement = prevElement;
    }

    setSelectedElement(newSelectedElement);
  }, [selectedElement, filteredOptions]);

  const _onSelect = (value: any, label: any) => {
    if (retainFilterValue) {
      setSearchFilter(label);
      onChange &&
        onChange(
          asyncFilteredOptions.find((opt: any) => opt.value === value) ||
            options.find((opt) => opt.value === value),
        );
      closeSelect();
      return;
    } else if (isSimpleSelect) {
      const selOption = options.find((opt) => opt.value === value);

      onSelect && onSelect(value, label, searchFilter);
      setSimpleSelectSelectedValue(selOption);
      onChange && onChange(selOption);
      setSearchFilter("");
      closeSelect();
      return;
    }

    if (onSelect) {
      const selOption = (asyncFilteredOptions || options).find(
        (opt: any) => opt?.value === value,
      );

      const onSelectResult = onSelect(value, label, searchFilter, selOption);
      if (onSelectResult === false) return;
    }

    closeSelect();
  };

  const handleUserKeyPress = React.useCallback(
    (event) => {
      const { keyCode } = event;

      setIsOpen(true);

      if (keyCode === 38) {
        _selectPrev();
        event.preventDefault();
      } else if (keyCode === 40) {
        _selectNext();
        event.preventDefault();
      } else if (keyCode === 13) {
        setSearchFilter("");
        setIsOpen(false);

        if (enterKeyToSelect && onSelect && asyncFilteredOptions) {
          const __selection = asyncFilteredOptions[selectedElement] || {};
          _onSelect(__selection.value, __selection.label);
        }

        // _onSelect(filteredOptions[selectedElement]);
        onSelectOption &&
          onSelectOption(filteredOptions[selectedElement], searchFilter);

        closeSelect();

        event.preventDefault();
      }
    },
    [_selectNext],
  );

  const onOutsideClick = React.useCallback(
    (event) => {
      if (persistOnSearch && searchFilter) return;

      if (rootElementRef && !rootElementRef.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [rootElementRef, searchFilter],
  );

  const doFilterAction = React.useCallback(
    (searchFilter, changeLoading) => {
      if (onFilter && searchFilter) {
        if (changeLoading) setIsLoading(true);
        onFilter(searchFilter)
          .then((results) => setAsyncFilteredOptions(results))
          .finally(() => changeLoading && setIsLoading(false));
      }
    },
    [onFilter],
  );

  React.useEffect(() => {
    doFilterAction(debouncedSearchFilter, true);
    // setSelectedElement(0);
  }, [debouncedSearchFilter, doFilterAction]);

  React.useEffect(() => {
    if (!triggerElRef) return;

    triggerElRef.addEventListener("keydown", handleUserKeyPress);

    return () => {
      triggerElRef.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress, triggerElRef]);

  React.useEffect(() => {
    window.document.addEventListener("click", onOutsideClick);

    return () => {
      window.document.removeEventListener("click", onOutsideClick);
    };
  }, [rootElementRef, searchFilter]);

  React.useEffect(() => {
    setAvailableOptions(options || []);
  }, [options]);

  React.useEffect(() => {
    if (!isSelect && !isSimpleSelect) return;

    const sel = (options || []).find(
      (opt) => String(opt.value) === String(value?.value || value),
    );

    if (retainFilterValue) {
      if (sel) {
        setSearchFilter(sel.label);
      }
    } else {
      if (sel) {
        isSimpleSelect
          ? setSimpleSelectSelectedValue(sel)
          : setSelectedValueLabel(sel.label);
      }
    }
  }, [value, options, isSelect, isSimpleSelect]);

  React.useEffect(() => {
    if (retainFilterValue) {
      _setSearchFilter(value);
    }
  }, [value, retainFilterValue]);

  React.useEffect(() => {
    if (dropDownRef) {
      dropDownRef({
        refreshFilter: doFilterAction,
        setAsyncFilteredOptions: setAsyncFilteredOptions,
      });
    }
  }, [dropDownRef]);

  const ItemsView = (
    <>
      {isOpen &&
        (searchInRecordsView ||
          !minSearchLength ||
          minSearchLength <= searchFilter.length) &&
        (!hideDropDownOnEmptyList || filteredOptions.length > 0) && (
          <>
            <Box
              pos={"fixed"}
              left={0}
              right={0}
              top={0}
              bottom={0}
              bg={"transparent"}
              zIndex={"dropdown"}
              onClick={() => setIsOpen(false)}
            />
            <Box
              ref={setListItem}
              style={styles.popper}
              {...attributes.popper}
              background={"bg.white"}
              left={0}
              right={0}
              w={triggerElRef?.offsetWidth}
              boxShadow="0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)"
              borderWidth={1}
              borderStyle={"solid"}
              borderColor={"border.grey"}
              mt={1.5}
              mb={1.5}
              zIndex={"dropdown"}
              minW={recordsViewMinWidth}
            >
              {searchInRecordsView && (
                <Box px={4} pt={5} pb={4}>
                  <VeriInput
                    w={"100%"}
                    inputLeft={
                      <Box>
                        <SearchIcon />
                      </Box>
                    }
                    inputRight={
                      searchFilter && (
                        <Box
                          onClick={(e) => {
                            e.stopPropagation();
                            setTimeout(() => {
                              setSearchFilter("");
                            }, 50);
                          }}
                          cursor={"pointer"}
                        >
                          <CloseIcon />
                        </Box>
                      )
                    }
                    value={searchFilter}
                    onChange={(e) => setSearchFilter(e.target.value)}
                  />
                </Box>
              )}
              {!isLoading &&
              (isSelect ||
                isSimpleSelect ||
                debouncedSearchFilter.length > 0) ? (
                <Box
                  {...(isSelect || isSimpleSelect
                    ? {
                        overflow: "auto",
                        maxH: resultsMaxHeight || "260px",
                        zIndex: zIndex || "1500",
                        marginRight: searchInRecordsView ? "6px" : undefined,
                      }
                    : undefined)}
                  {...(resultsMaxHeight
                    ? {
                        maxH: resultsMaxHeight,
                        overflow: "auto",
                      }
                    : undefined)}
                  css={
                    (searchInRecordsView || resultsMaxHeight) && {
                      "&::-webkit-scrollbar": {
                        width: "4px",
                      },
                      "&::-webkit-scrollbar-track": {
                        width: "6px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#DCE1E7",
                        borderRadius: "4px",
                        width: "6px",
                      },
                    }
                  }
                >
                  {filteredOptions.length > 0 ||
                  (searchInRecordsView && !debouncedSearchFilter) ? (
                    filteredOptions.map((option, optionIndex) => {
                      return (
                        <DropDownOptionElement
                          value={option.value}
                          label={option.label || ""}
                          index={optionIndex}
                          key={`${option.value}-${optionIndex}`}
                          selectedElement={!option.disabled && selectedElement}
                          setSelectedElement={setSelectedElement}
                          onSelect={_onSelect}
                          disabled={option.disabled || false}
                          wrapperOptions={
                            wrapperOptions && wrapperOptions(option)
                          }
                          {...optionProps}
                        >
                          {renderOption ? renderOption(option) : option.label}
                        </DropDownOptionElement>
                      );
                    })
                  ) : noOptions ? (
                    noOptions
                  ) : getNoOptions ? (
                    getNoOptions(searchFilter)
                  ) : (
                    <Box textStyle={"labelSmall"} py={4} px={4}>
                      {" "}
                    </Box>
                  )}
                </Box>
              ) : loading ? (
                loading
              ) : (
                <Box py={4} px={4}>
                  Loading...
                </Box>
              )}
              {footer && footer}
            </Box>
          </>
        )}
    </>
  );

  if (triggerComponent) {
    return (
      <Box ref={setRootElementRef}>
        <Box ref={setTriggerElRef} onClick={() => setIsOpen(true)}>
          {triggerComponent(selectedValueLabel)}
        </Box>
        {ItemsView}
      </Box>
    );
  }

  return (
    <Box
      ref={setRootElementRef}
      w={"100%"}
      position={"relative"}
      {...wrapperProps}
    >
      {ItemsView}
      <VeriInput
        w={"100%"}
        ref={setTriggerElRef}
        propsInput={{
          placeholder: placeholder,
          w: "100%",
          autoComplete: getAutocompleteOffFlag(),
          ...(isOpen && { zIndex: "dropdown", position: "relative" }),
          ...rest,
          isReadOnly: !isFilterable,
          _hover: !isFilterable ? { cursor: "pointer" } : undefined,
        }}
        helperText={helperText}
        onFocus={() => setIsOpen(true)}
        onChange={(e) => setSearchFilter(e.target.value)}
        inputGroupProps={{ w: "100%" }}
        value={
          isOpen || retainFilterValue
            ? searchFilter
            : !isSimpleSelect
            ? selectedValueLabel
            : simpleSelectSelectedValue?.label || ""
        }
        label={label}
        inputRight={
          (isSelect || isSimpleSelect) &&
          !hideCaret &&
          !veriInputProps?.isDisabled && (
            <Box onClick={() => setIsOpen(true)}>
              <ChevronDownIcon />
            </Box>
          )
        }
        error={error}
        {...veriInputProps}
      />
      {isLoading && (
        <Box position={"absolute"} bottom={0} right={0}>
          <VeriSpinner boxSize={6} mt={0} mb={3} mr={3} />
        </Box>
      )}
    </Box>
  );
};

export default VeriDropDown;
