import React from "react";
import { MFAModalView } from "./MFAModalView";
import { useMfaModal } from "../context/MfaModalContext/MfaModalContext";
import { useAuth } from "../../../../auth";

const MfaSuccessStep: React.FC = () => {
  const state = useMfaModal();
  const { auth } = useAuth();

  React.useEffect(() => {
    state.setState({ isLoading: true });
    auth.refreshProfile().finally(() => {
      state.setState({ isLoading: false });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MFAModalView
      title="Two-factor authentication is now enabled!"
      subTitle="Each time you log in, you will be sent a verification code and prompted to enter that code in order to log in. You can change your enrollment from your Settings."
      continueLabel="Done"
      hideGoBack={true}
      onContinue={() => {
        state.closeModal();
      }}
    />
  );
};

export default MfaSuccessStep;
