import { Link } from "@chakra-ui/react";
import React from "react";
import { ExternalLinkProps } from "./ExternalLink.types";

export const ExternalLink = ({
  stopPropagation = true,
  label,
  ...rest
}: ExternalLinkProps) => {
  const handleStopPropagation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (stopPropagation) e.stopPropagation();
  };

  return (
    <Link
      target="_blank"
      color={"brand.main"}
      onClick={handleStopPropagation}
      {...rest}
    >
      {label}
    </Link>
  );
};
