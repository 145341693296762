/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum UsersAccountsPermissionLevelType {
  Admin = "Admin",
  Owner = "Owner",
  Read = "Read",
}
