import { formatMoneyWithSymbol } from "../../utils";

export const errorMessages = {
  enterPhoneNumber: "You must enter a phone number",
  chooseOneOption: "You must choose an option",
  postOfficeCannotBeAPoBox: "This address can not be a P.O. Box",
  requiredField: "This field is required",
  enterAValidDate: "Enter a valid date",
  somethingWentWrong: "Something went wrong",
  minVal: (value: number) => `Min value is ${value}`,
  maxVal: (value: number) => `Max value is ${value}`,
  minCurrency: (value: number) =>
    `Min amount is ${formatMoneyWithSymbol(value)}`,
  maxCurrency: (value: number) =>
    `Max amount is ${formatMoneyWithSymbol(value)}`,
};
