/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallInstanceRulesSummaryJsonApiModel = {
  id: "",
  "rule-number": "",
  "rule-name": "",
  "rule-description": "",
  "template-name": "",
  "total-amount-in": "",
  "total-amount-paid": "",
  "total-amount-remaining": "",
  "total-amount-to-target": "",
};
