const AUTH_URL_PREFIX = "/auth";
export const authRoutes = {
  AUTH: `${AUTH_URL_PREFIX}/:page?/:sub?`,
  GET_STARTED: `${AUTH_URL_PREFIX}/get-started`,
  LOGIN: `${AUTH_URL_PREFIX}/Login`,
  LOGIN_MFA: `${AUTH_URL_PREFIX}/Login/ValidateMFA`,
  SIGNUP: `${AUTH_URL_PREFIX}/signup`,
  SIGNUP_CONFIRM: `${AUTH_URL_PREFIX}/signup/confirm`,
  SIGNUP_ACTIVATION: `${AUTH_URL_PREFIX}/signup/activation`,
  RESET_PASSWORD: `${AUTH_URL_PREFIX}/reset-password`,
  RESET_PASSWORD_CONFIRM: `${AUTH_URL_PREFIX}/signup/reset-password/confirm`,
  VERIFY: `${AUTH_URL_PREFIX}/verify`,
};
