/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImportPositionJsonApiModel = {
  id: "",
  import: {
    jsonApi: "hasOne",
    type: "imports",
  },
  "external-id": "",
  row: "",
  status: "",
  errors: "",
  metadata: "",
  "created-at": "",
  "updated-at": "",
  "account-id": "",
  "holding-id": "",
  "client-account-id": "",
  "account-mnumber": "",
  "client-position-id": "",
  "account-name": "",
  "security-id": "",
  "inception-date": "",
  "maturity-date": "",
  class: "",
};
