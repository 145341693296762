export const SponsorOnboardingRoot = "/w/sponsor-onboarding";

export const SponsorOnboardingRoutes = {
  GET_VERIFIED: SponsorOnboardingRoot + "/get-verified/:id/:page?",
  ADD_PRINCIPALS: SponsorOnboardingRoot + "/add-principals/:id/:page?",
  VERIFY_PRINCIPALS: SponsorOnboardingRoot + "/verify-principals/:id/:page?",
  VERIFY_PRINCIPALS_CHECKOUT:
    SponsorOnboardingRoot + "/verify-principals/:id/checkout",
  SWITCH_SPONSOR: SponsorOnboardingRoot + "/switch-sponsor/:id",
};
