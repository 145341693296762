import {
  Flex,
  FlexProps,
  FormLabel,
  FormLabelProps,
  Text,
} from "@chakra-ui/react";
import React from "react";

export interface VeriFormLabelProps extends FlexProps {
  isOptional?: boolean;
  labelExtra?: React.ReactNode;
  formLabelProps?: FormLabelProps;
}
export const VeriFormLabel: React.FC<VeriFormLabelProps> = ({
  children,
  labelExtra,
  isOptional,
  formLabelProps,
  ...rest
}) => {
  return (
    <Flex justifyContent="space-between" {...rest}>
      <FormLabel
        lineHeight="24px"
        mb="8px"
        fontWeight="700"
        data-testid="input-label"
        {...formLabelProps}
      >
        {children}
      </FormLabel>
      {isOptional && (
        <Text
          color="text.grey"
          fontWeight="400"
          fontSize="16px"
          data-testid="input-optional"
        >
          Optional
        </Text>
      )}
      {labelExtra}
    </Flex>
  );
};
