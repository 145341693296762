/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ChartNodeJsonApiModel = {
  id: "",
  chart: {
    jsonApi: "hasOne",
    type: "charts",
  },
  "chart-id": "",
  label: "",
  model: "",
  "model-id": "",
  "depends-on": "",
  tags: "",
  metadata: "",
  level: "",
  "created-at": "",
  "updated-at": "",
};
