import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";

// Attributes *containing* these keys will be sanitized
// Ex: "email", "company-name", "birth-date", etc will also be sanitized
const privateFieldNames = [
  "name",
  "address",
  "bank",
  "account",
  "phone",
  "tin",
  "ssn",
  "ein",
  "mail",
  "birth",
  "document",
  "password",
];

export const getLogrocketFuzzySanitizer = () => {
  return LogrocketFuzzySanitizer.setup(privateFieldNames);
};
