import {
  Avatar,
  Box,
  Circle,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Button } from "../../..";
import { dateToDate, dateToHumanDate } from "../../utils/dates";
import { ButtonProps } from "../common";

interface NotificationItemGeneralProps {
  image?: string;
  title: string;
  description: string | React.ReactNode;
  actionTitle?: string;
  actionOnClick?: () => void;
  accountName?: string;
  createdAt?: string;
  viewed?: boolean;
  actionProps?: ButtonProps;
}
export const NotificationItemGeneral = ({
  image,
  title,
  description,
  actionTitle,
  actionOnClick,
  accountName,
  createdAt,
  viewed,
  actionProps,
  ...rest
}: NotificationItemGeneralProps) => {
  return (
    <Flex boxShadow="none" mb={2} gap={4} {...rest}>
      <Box position="relative" minWidth="fit-content">
        <Box ps={1} pt={1}>
          <Avatar
            width="32px"
            height="32px"
            name={accountName}
            src={image || undefined}
            mr="16px"
            bg={"bg.greyLight"}
          />
        </Box>
        {!viewed && (
          <Circle
            d="block"
            size="8px"
            borderWidth="1px"
            borderColor="semantic.success"
            bg="bg.green"
            position="absolute"
            top={0}
            left={0}
          />
        )}
      </Box>

      <Stack>
        <Box>
          <Heading size="sm" mb={2}>
            {title}
          </Heading>

          <Text>{description}</Text>
        </Box>

        {actionTitle && (
          <Box>
            <Button p={1} py={0} onClick={actionOnClick} {...actionProps}>
              {actionTitle}
            </Button>
          </Box>
        )}
        {createdAt && (
          <Box>
            <Text mt={1.5} color="text.grey" fontSize="sm">
              {dateToHumanDate(dateToDate(createdAt))}
            </Text>
          </Box>
        )}
      </Stack>
    </Flex>
  );
};
