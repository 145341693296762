import dasherize from "dasherize";
import { ModelId } from "../../../../defines";
import { buildRouteUrl } from "../../../../utils";
import { dateToYYYYMMDD } from "../../../../utils/dates";
import { ApiModel, ApiModelOptions } from "../../../ApiModel";
import { ActivityTypeEnumType } from "../../../enums";
import {
  GroupedInvestmentModel,
  UserActivitiesByDateModel,
  UserModel,
  UsersActivityModel,
} from "../../../models";
import { JsonApiManyResponse, ModelScope } from "../../../types";
import { UsersLoginHistoryItem } from "../../../user-defined-models/UsersLoginHistoryItem";
import { UsersStatisticTotals } from "../../../user-defined-models/UsersStatisticTotals";

export default function apiUserModel(opts: ApiModelOptions<UserModel>) {
  const model = ApiModel<UserModel>("user", {
    urls: {
      [ModelScope.Manager]: {
        getOne: "/manager/users/:id",
        getMany: "/manager/users",
      },
    },
    ...opts,
  });

  const handler = model.handler;

  const addActivityComment = async (
    userId: string,
    managerId: string,
    message: string,
  ) => {
    return handler.post("/manager/users/activities?manager_id=" + managerId, {
      data: handler.serializeResource(
        "user-activity",
        dasherize({
          activityType: ActivityTypeEnumType.Comment,
          activityNotes: message,
          userId: Number(userId),
          managerId: Number(managerId),
        } as UsersActivityModel),
      ),
    });
  };

  const getAccountsByUserId = async (userId: string, managerId: string) => {
    const activities = await handler.get<
      JsonApiManyResponse<GroupedInvestmentModel>
    >(`/manager/users/${userId}/accounts?manager_id=${managerId}`);

    return activities.data || [];
  };

  const getActivitiesByUserId = async (userId: string, managerId: string) => {
    const activities = await handler.get<
      JsonApiManyResponse<UserActivitiesByDateModel>
    >(`/manager/users/${userId}/activities?manager_id=${managerId}`);

    return activities.data || [];
  };

  const getInvestmentsByUserId = async (userId: string, managerId: string) => {
    const activities = await handler.get<
      JsonApiManyResponse<GroupedInvestmentModel>
    >(`/manager/users/${userId}/investments?manager_id=${managerId}`);

    return activities.data || [];
  };

  const getUsersLoginHistory = async (startDate: Date, endDate: Date) => {
    const history = await model.placementsApiClient.request<
      UsersLoginHistoryItem[]
    >(
      buildRouteUrl("/users/logins", undefined, {
        start_date: dateToYYYYMMDD(startDate),
        end_date: dateToYYYYMMDD(endDate),
      }),
      "GET",
    );

    return history?.data || [];
  };

  const getUsersTotalStatistics = async () => {
    const result =
      await model.placementsApiClient.request<UsersStatisticTotals>(
        "/users/totals",
        "GET",
      );
    return result?.data || undefined;
  };

  const addUserRole = async (
    manager_id: number,
    user_id: number,
    role: "admin" | "manager",
  ) => {
    return model.placementsApiClient.rpcRequest("add-user-role", {
      role: role,
      manager_id,
      user_id,
    });
  };

  const setPassword = async (userId: ModelId, password: string) => {
    return await model.apiClient.request("/mallcop/user/set-password", "POST", {
      user_id: Number(userId),
      password,
    });
  };

  const managerSetPassword = async (userId: ModelId, password: string) => {
    return await model.apiClient.post(
      `/manager/users/${userId}/set-password`,
      {
        password,
      },
      false,
    );
  };

  const sendWelcomeEmail = async (userId: ModelId, managerId: ModelId) => {
    await model.placementsApiClient.rpcRequest("add-user-manager", {
      manager_id: Number(managerId),
      user_id: Number(userId),
    });
    await model.handler.post(
      "/mallcop/welcome-email/user",
      {
        user_id: Number(userId),
        manager_id: Number(managerId),
      },
      false,
    );
  };

  const bulkSendInvite = async (
    userIds: ModelId[],
    isSelectionByExclude: boolean,
  ) => {
    const keyName = isSelectionByExclude ? "exclude_user_ids" : "user_ids";
    const data = userIds.length ? { [keyName]: userIds } : {};
    await model.handler.post("/manager/users/bulk-send-invite", data);
  };

  const removeUserRole = async (userRoleId: string) => {
    return model.placementsApiClient.rpcRequest("remove-user-role", {
      role_id: Number(userRoleId),
    });
  };

  const sendInvite = async (userId: ModelId) => {
    return model.apiClient.post(`/manager/users/${userId}/send-invite`, {});
  };

  const sendPasswordReset = async (userId: ModelId) => {
    return model.apiClient.post(`/manager/users/${userId}/reset-password`, {});
  };

  return {
    ...model,
    addActivityComment,
    getAccountsByUserId,
    getActivitiesByUserId,
    getInvestmentsByUserId,
    getUsersLoginHistory,
    getUsersTotalStatistics,
    addUserRole,
    setPassword,
    sendWelcomeEmail,
    removeUserRole,
    sendInvite,
    bulkSendInvite,
    sendPasswordReset,
    managerSetPassword,
  };
}
