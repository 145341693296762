import {
  Box,
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { CountryCallingCode, CountryCode, E164Number } from "libphonenumber-js";
import React from "react";
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import { isClient } from "../../../utils";
import { VeriFormLabel } from "../VeriFormLabel/VeriFormLabel";
import { VeriStyledInput } from "../VeriInput";

const compareCountry = (a: CountryCode, b: CountryCode) => {
  if (en[a] > en[b]) {
    return 1;
  }
  if (en[a] < en[b]) {
    return -1;
  }
  return 0;
};

const getCountryFromCode = (code: CountryCode) => {
  const countries = getCountries();
  const country = countries.find((c) => {
    return c === code;
  });
  return country;
};

export interface VeriPhoneInputProps
  extends Omit<FormControlProps, "label" | "onChange"> {
  onChange?: (value?: E164Number | undefined) => void;
  onChangeCountry?: (value?: string | null) => void;
  onChangeCountryNumeric?: (value?: CountryCallingCode) => void;
  country?: string;
  countryCode?: CountryCode;
  value?: E164Number;
  label?: React.ReactNode;
  error?: React.ReactNode;
  helperText?: React.ReactNode;
  name?: string;
}

export const VeriPhoneInput = React.forwardRef<
  HTMLDivElement,
  VeriPhoneInputProps
>(
  (
    {
      onChange,
      onChangeCountry,
      onChangeCountryNumeric,
      country,
      countryCode,
      value,
      label,
      error,
      helperText,
      name,
      isRequired,
      ...rest
    },
    ref,
  ) => {
    const flagRef = React.useRef<HTMLDivElement>(null);
    const [flagWidth, setFlagWidth] = React.useState<string>("100px");

    React.useEffect(() => {
      if (isClient()) {
        document.onreadystatechange = () => {
          setFlagWidth(String(flagRef?.current?.clientWidth || 0) + "px");
        };
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (isClient()) {
        setFlagWidth(String(flagRef?.current?.clientWidth || 0) + "px");
      }
    }, [country, countryCode]); // eslint-disable-line react-hooks/exhaustive-deps

    const textCountry = country
      ? country
      : (countryCode && getCountryFromCode(countryCode)) || "US";

    return (
      <FormControl isRequired={isRequired} isInvalid={!!error} {...rest}>
        {label && <VeriFormLabel>{label}</VeriFormLabel>}
        <InputGroup>
          <InputLeftElement width="fit-content" height="100%">
            <Flex ref={flagRef}>
              <select
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                  border: 0,
                  opacity: 0,
                  cursor: "pointer",
                }}
                value={textCountry}
                onChange={(event) => {
                  onChangeCountry &&
                    onChangeCountry(event.target.value || undefined);
                  onChangeCountryNumeric &&
                    onChangeCountryNumeric(
                      getCountryCallingCode(event.target.value as CountryCode),
                    );
                }}
              >
                <option value="">{en["ZZ"]}</option>
                {getCountries()
                  .sort(compareCountry)
                  .map((country) => (
                    <option key={country} value={country}>
                      {en[country]} +{getCountryCallingCode(country)}
                    </option>
                  ))}
              </select>
              <Box ml="16px">
                <img
                  style={{ width: "34.6px", height: "26px" }}
                  alt={textCountry}
                  role="presentation"
                  src={"https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/1x1/{xx}.svg"
                    .replace("{XX}", textCountry)
                    .replace("{xx}", textCountry.toLowerCase())}
                />
              </Box>
              {/* <Icon mr="18px" as={ChevronUpIcon} color="brand.main" /> */}
              <Text
                alignSelf="center"
                ml="8px"
                mr="4px"
                textStyle="bodySmall"
              >{`+${getCountryCallingCode(textCountry as CountryCode)}`}</Text>
            </Flex>
          </InputLeftElement>
          <PhoneInput
            ref={ref}
            name={name}
            pl={flagWidth}
            country={textCountry as CountryCode}
            placeholder="Enter phone number"
            value={value}
            onChange={
              onChange ??
              function () {
                /* empty */
              }
            }
            inputComponent={VeriStyledInput as never}
          />
        </InputGroup>
        {helperText && !error && (
          <FormHelperText id="email-helper-text">{helperText}</FormHelperText>
        )}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    );
  },
);

VeriPhoneInput.displayName = "VeriPhoneInput";
