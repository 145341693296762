import React from "react";
import { Divider, LoadingBox, VeriHeading } from "../../common";
import { Box, Grid, Text } from "@chakra-ui/react";
import { Card } from "../Card";
import { FormContainerProps } from "./FormContainer.types";

const FormContainer: React.FC<FormContainerProps> = ({
  title,
  description,
  hasDivider,
  noCard,
  isLoading,
  children,
  showDescriptionSide = true,
}) => {
  const ChildrenContainerComponent = noCard ? Box : Card;

  return (
    <Box>
      <Grid
        templateColumns={
          showDescriptionSide ? { base: "10fr", md: "3fr 7fr" } : "10fr"
        }
      >
        {showDescriptionSide && (
          <Box pr={8}>
            <VeriHeading textStyle="h5" mt={6} mb={3}>
              {title}
            </VeriHeading>
            {description && (
              <Text maxW={"306px"} color={"black"} textStyle={"bodyRegular"}>
                {description}
              </Text>
            )}
          </Box>
        )}
        <Box>
          {!showDescriptionSide ? (
            <VeriHeading textStyle="h5" mb={3}>
              {title}
            </VeriHeading>
          ) : undefined}
          <ChildrenContainerComponent>
            {isLoading ? <LoadingBox /> : children}
          </ChildrenContainerComponent>
        </Box>
      </Grid>
      {hasDivider && <Divider my={8} />}
    </Box>
  );
};

export default FormContainer;
