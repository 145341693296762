/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MoveIcon = createIcon({
  displayName: "MoveIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 2V22" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M22 12H2" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M19 15L22 12L19 9" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M9 19L12 22L15 19" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M5 9L2 12L5 15" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M15 5L12 2L9 5" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default MoveIcon;