import { ChakraTheme } from "@chakra-ui/react";
import theme from "@chakra-ui/theme";
import { Dict } from "../defines";
// TODO: THIS IS ILLEGAL
// eslint-disable-next-line workspaces/require-dependency
import * as icons from "../icons";

const ReactSelect = {
  baseStyle: () => ({
    container: {
      bg: "black",
      p: 8,
    },
    menu: {
      color: "blue.100",
      bg: "blue.500",
      padding: 8,
    },
  }),
};

const MainTheme = ({ colors }: { colors: ChakraTheme["colors"] }): Dict => ({
  investorPortal: {
    fixed: {
      maxWidth: 1180,
    },
    headerHeights: {
      mainHeader: 79,
      impersonatingHeader: 60,
    },
  },
  radii: {
    4: "4px",
  },
  zIndices: {
    absoluteOverlay: 1,
    inputRightAndLeftElements: 2,
    veriTooltip: 3,
    bottomButtons: 800,
    sponsorDashboardMenu: 850,
    fixedFormButtons: 851,
    topBanner: 900,
    aboveChakraOverlay: 1301,
    aboveChakraOverlayContent: 1302,
  },
  sizes: {
    maxContentWidth: "1080px",
  },
  space: {
    mobileSideP: "24px",
    desktopSideP: "80px",
  },
  textStyles: {
    /* All the rest */

    display1: {
      fontSize: "64px!important",
      lineHeight: "77px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    display2: {
      fontSize: "56px!important",
      lineHeight: "68px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h1: {
      fontSize: "40px!important",
      lineHeight: "54px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h2: {
      fontSize: "32px!important",
      lineHeight: "44px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h2normal: {
      fontSize: "32px!important",
      lineHeight: "44px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    h3: {
      fontSize: "28px!important",
      lineHeight: "42px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h4: {
      fontSize: "24px!important",
      lineHeight: "36px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h4normal: {
      fontSize: "24px!important",
      lineHeight: "36px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    h5: {
      fontSize: "20px!important",
      lineHeight: "28px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    h5normal: {
      fontSize: "20px!important",
      lineHeight: "28px",
      fontStyle: "normal",
    },
    h6: {
      fontSize: "16px!important",
      lineHeight: "22px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    bodyRegular: {
      fontSize: "16px!important",
      lineHeight: "24px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    bodyRegularBold: {
      fontSize: "16px!important",
      lineHeight: "24px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    bodyLarge: {
      fontSize: "20px!important",
      lineHeight: "26px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    bodyLargeBold: {
      fontSize: "20px!important",
      lineHeight: "26px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    bodySmall: {
      fontSize: "14px!important",
      lineHeight: "22px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    bodySmallBold: {
      fontSize: "14px!important",
      lineHeight: "22px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    bodyXS: {
      fontSize: "12px!important",
      lineHeight: "20px",
      fontWeight: "normal",
      fontStyle: "normal",
    },
    bodyXSBold: {
      fontSize: "12px!important",
      lineHeight: "20px",
      fontWeight: "bold",
      fontStyle: "normal",
    },
    labelLarge: {
      fontSize: "16px!important",
      lineHeight: "24px",
      fontWeight: 600,
      fontStyle: "normal",
      textTransform: "uppercase",
      letterSpacing: "0.02em",
    },
    labelSmall: {
      fontSize: "14px!important",
      lineHeight: "22px",
      fontWeight: 700,
      fontStyle: "normal",
      textTransform: "uppercase",
      letterSpacing: "0.02em",
    },
    labelXS: {
      fontSize: "12px!important",
      lineHeight: "20px",
      fontWeight: 600,
      fontStyle: "normal",
      textTransform: "uppercase",
      letterSpacing: "0.02em",
    },

    /* Marketing pages */
    canon: {
      fontSize: { base: "48px!important", md: "64px!important" },
      lineHeight: { base: "54px!important", md: "72px!important" },
      fontWeight: 400,
      fontFamily: "Nunito Sans",
    },
    canonSmall: {
      fontSize: "44px!important",
      fontWeight: 400,
      lineHeight: "56px",
      fontFamily: "Nunito Sans",
    },
    canonSmallStrong: {
      fontSize: "44px!important",
      fontWeight: 600,
      lineHeight: "56px",
      fontFamily: "Nunito Sans",
    },
    canonStrong: {
      fontSize: { base: "48px!important", md: "64px!important" },
      lineHeight: { base: "54px!important", md: "72px!important" },
      fontWeight: 600,
      fontFamily: "Nunito Sans",
    },
    greatPrimer: {
      fontSize: "24px!important",
      fontWeight: 400,
      lineHeight: "40px",
      fontFamily: "Nunito Sans",
    },
    bannerXL: {
      fontSize: { base: "64px!important", md: "103px!important" },
      lineHeight: { base: "70px!important", md: "114px!important" },
      fontStyle: "normal",
      fontWeight: 400,
      fontFamily: "Nunito Sans",
    },

    paragraph: {
      fontSize: "16px!important",
      fontWeight: 400,
      lineHeight: "28px",
      fontFamily: "Nunito Sans",
    },
    paragraphStrong: {
      fontSize: "16px!important",
      fontWeight: 700,
      lineHeight: "28px",
      fontFamily: "Nunito Sans",
    },
    minion: {
      fontSize: "12px!important",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "20px",
      fontFamily: "Nunito Sans",
    },
  },
  components: {
    ReactSelect,
    Select: {
      variants: {
        verivest: {
          field: {
            paddingBottom: "12px",
            paddingTop: "12px",
            height: "50px",
            color: "text.black",
            borderRadius: "4px",
            borderColor: "border.input",
            borderWidth: "1px",
            lineHeight: "24px",
            _focus: {
              borderColor: "brand.main",
            },
          },
        },
        verivestSmall: {
          field: {
            paddingBottom: "7px",
            paddingTop: "7px",
            height: "40px",
            color: "text.black",
            borderRadius: "4px",
            borderColor: "border.input",
            borderWidth: "1px",
            lineHeight: "24px",
            _focus: {
              borderColor: "brand.main",
            },
          },
        },
      },
    },
    Popover: {
      variants: {
        criteria: {
          popper: {
            maxWidth: "560px",
            width: "unset",
          },
        },
      },
    },
    Box: {
      variants: {
        wrapper: {
          maxWidth: "500px",
        },
      },
    },
    Table: {
      sizes: {
        md: {
          th: {
            fontSize: "md",
            letterSpacing: "inherit",
          },
        },
      },
      baseStyle: {
        td: {
          paddingLeft: "0px",
        },
        th: {
          paddingLeft: "0px",
          color: "ink",
          borderBottom: "100px",
          textTransform: "inherit",
          fontFamily: "Nunito Sans",
          fontSize: "md",
        },
        tr: {
          borderBottomColor: "#DCE1E7",
          borderBottomWidth: "1px",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: "brand.main",
          _checked: {
            bg: "brand.main",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "brand.main",
            _hover: {
              bg: "brand.main",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: "brand.main",
            },
          },
        },
      },
      variants: {
        grayPurple: {
          control: {
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: "border.checkbox",
            borderRadius: "3.3px",
            _hover: {
              borderColor: "brand.main",
            },
            _checked: {
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: "brand.main",
              backgroundColor: "brand.main",
              _hover: {
                borderColor: "brand.main",
                backgroundColor: "brand.main",
              },
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: "text.accent",
      },
      variants: {
        marked: {
          display: "inline-block",
          borderBottom: "1px solid #000",
          paddingBottom: "0",
          fontSize: "24px",
          lineHeight: "40px",
          _hover: {
            textDecoration: "none",
          },
        },
      },
    },
    Radio: {
      variants: {
        outline: {
          parts: ["container", "label", "control"],
          container: {
            padding: 4,
            borderRadius: "4px",
            border: "1px solid",
            borderColor: "border.grey7",
            _checked: {
              borderColor: "brand.main",
            },
          },
          label: {
            width: "100%",
          },
          control: {
            alignSelf: "center",
          },
        },
        highlightedOutline: {
          parts: ["container", "label", "control"],
          container: {
            padding: 4,
            borderRadius: "4px",
            border: "1px solid",
            borderColor: "border.grey7",
            _checked: {
              borderColor: "brand.main",
              backgroundColor: "#F5F4FF",
            },
          },
          label: {
            width: "100%",
          },
          control: {
            display: "none",
          },
        },
      },
    },
  },
  fonts: {
    nunitoSans: "Nunito Sans, sans-serif",
    body: "Nunito Sans, sans-serif",
    roboto: "Nunito Sans, sans-serif",
    heading: "satoshi, sans-serif",
    viga: "viga, sans-serif",
    mono: "Menlo, monospace",
    satoshi: "satoshi, sans-serif",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "20px",
    xl: "24px",
    "2xl": "28px",
    "3xl": "32px",
    "4xl": "40px",
    "5xl": "56px",
    "6xl": "64px",
    title: "38px",
    subTitle: "30px",
  },
  icons: {
    ...icons,
  },
  shadows: {
    1: "0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16)",
    2: "0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)",
    3: "0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16)",
    4: "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)",
    5: "0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16)",
    6: "0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)",

    xs: "0 0 0 1px rgba(0, 0, 0, 0.05)",
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    base: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.6)",
    inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
    mobileHeader:
      "0px 2px 4px rgba(0, 0, 0, 0.06), inset 0px -1px 0px #E5E5E5;",
    buttonRaise: "0 8px 24px 0 rgba(0,6,17,0.1)",
    none: "none",
    "dark-lg":
      "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px",
  },
  colors: {
    ink: "#262b3e",
    primary: {
      regular: colors.main,
      lightest: "#F9DCEB",
    },
    accent: {
      teal: {
        regular: "#007D74",
        lightest: "#D3EEEE",
      },
      purple: {
        lightest: "#E0D6FF",
        regular: "#6D6ACA",
      },
      blue: {
        regular: "#3D70D5",
        lightest: "#C8D7F3",
      },
    },
    semantic: {
      error: "#D90042",
      danger: "#D90042",
      lightestError: "#FFE6ED",
      success: "#169742",
      warning: "#FF9C2C",
      lightestWarning: "#FBEDE0",
      lightestSuccess: "#DAECE0",
    },
    icons: {
      grey: "#A1A5AC",
      green: "#1F8E45",
    },
    alert: {
      danger: "#DB5151",
    },
    black: "#262b3e",
    main: { 500: "#4840BB" },
    background: "#fff",
    bg: {
      grey: "#F7F8F9",
      grey2: "#F9F9F9",
      grey4: "#AFB4C1",
      grey8: "#F4F5F9",
      greyLight: "#DCE1E7",
      greyLighter: "#F0F2F5",
      green: "#9CE39C",
      lightestGreen: "#DAECE0",
      lighter: "#F0F2F5",
      darkest: "#262B3E",
      purple: colors.main,
      lightPurple: colors.mainLightest,
      lightestPurple: "#EDECF8",
      lightPurple2: "#DAD9F1",
      accent: "#007D74",
      lightAccent: "#dad9f1",
      white: "#ffffff",
      gold: "#FF9C2C",
      lightGold: "#FBEDE0",
      error: "#ef0011",
      primaryRegular: "#4840BB",
      goldLightest: "#FFEFCF",
      paleYellow: "#FCF7CC",
    },
    text: {
      footerText: "#acaeb2",
      copyright: "#262B3E",
      black: "#171E34",
      lessBlack: "#293848",
      grey: "#6A6E74",
      grey2: "#818696",
      grey3: "#9197A9",
      greyDark: "#6A6E74",
      greyLight: "#DCE1E7",
      greyDarker: "#3D3F43",
      darkestGrey: "#252628",
      greyLighter: "#F0F2F5",
      darkGold: "#663600",
      lightGold: "#fc8a22",
      darkPurple: "#660043",
      accent: "#007D74",
      white: "#ffffff",
      gold: "#FF9C2C",
      greenishYellow: "#9F7F0C",
      purple: colors.main,
      darkTeal: "#00332F",
      accentTeal: "#007D74",
      error: "#ef0011",
    },
    border: {
      input: "#DCE1E7",
      grey: "#DCE1E7",
      grey7: "#E6E8F0",
      divider: "#DCE1E7",
      checkbox: "#A1A5AC",
      lighter: "#F0F2F5",
    },
    divider: {
      light: "#E5E5E5",
    },
    brand: {
      main: colors.main,
      mainLightest: colors.mainLightest,
      dark: colors.mainDark,
      500: colors.main,
      800: "#153e75",
      700: colors.mainDark,
    },
    opportunity: {
      draft: "#3D3F43",
      draftbg: "#DCE1E7",
      open: "white",
      openbg: colors.main,
      soon: "#007D74",
      soonbg: "#D3EEEE",
      funded: "#6D6ACA",
      fundedbg: "#E0D6FF",
      full: "#3D70D5",
      fullbg: "#C8D7F3",
    },
    badges: {
      unverified: "#90949C",
      verified: colors.dark,
      gold: "#FD8A23",
    },
    light: {
      main: "#4840bb",
      "100": "#3e38ac",
      "200": "#5a53c2",
      "300": "#6d66c9",
      "400": "#7f79cf",
      "500": "#918cd6",
      "600": "#a4a0dd",
      "700": "#b6b3e4",
      "800": "#c8c6eb",
      "900": "#dad9f1",
      "1000": "#edecf8",
    },
    dark: {
      main: "#230b59",
      "100": "#1D0F4C",
      "200": "#39236a",
      "300": "#4f3c7a",
      "400": "#65548b",
      "500": "#7b6d9b",
      "600": "#9185ac",
      "700": "#a79dbd",
      "800": "#bdb6cd",
      "900": "#d3cede",
      "1000": "#e9e7ee",
    },
    grey: {
      main: "#262b3e",
      "100": "#232635",
      "200": "#3c4051",
      "300": "#515565",
      "400": "#676b78",
      "500": "#7d808b",
      "600": "#93959f",
      "700": "#a8aab2",
      "800": "#bebfc5",
      "900": "#d4d5d8",
      "1000": "#e9eaec",
    },
  },
  useSystemColorMode: false,
  styles: {
    ...theme.styles,
    global: (props: Dict) => ({
      ...theme.styles.global,
      "html, body": {
        color: props.colorMode === "light" ? "black" : "black",
        bg: props.colorMode === "light" ? "background" : "background",
        borderColor:
          props.colorMode === "light" ? "border.input" : "border.input",
        placeholderColor:
          props.colorMode === "light" ? "text.grey" : "text.grey",
      },
    }),
  },
});

export default MainTheme;
