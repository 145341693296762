/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AttributeItemJsonApiModel = {
  id: "",
  "attribute-type": "",
  "attribute-key": "",
  name: "",
  description: "",
  "is-primary-strategy": "",
  "is-primary-property-type": "",
  attribute: {
    jsonApi: "hasOne",
    type: "attributes",
  },
};
