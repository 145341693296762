/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuIcon = createIcon({
  displayName: "MenuIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M23 13H1C0.4 13 0 12.6 0 12C0 11.4 0.4 11 1 11H23C23.6 11 24 11.4 24 12C24 12.6 23.6 13 23 13Z" fill="currentColor" /><path d="M23 6H1C0.4 6 0 5.6 0 5C0 4.4 0.4 4 1 4H23C23.6 4 24 4.4 24 5C24 5.6 23.6 6 23 6Z" fill="currentColor" /><path d="M23 20H1C0.4 20 0 19.6 0 19C0 18.4 0.4 18 1 18H23C23.6 18 24 18.4 24 19C24 19.6 23.6 20 23 20Z" fill="currentColor" /></g>)
});
export default MenuIcon;