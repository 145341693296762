export const MessageBoxStyles = {
  success: {
    leftBox: {
      backgroundColor: "semantic.success",
    },
    rightBox: {
      backgroundColor: "semantic.lightestSuccess",
    },
  },
  warning: {
    leftBox: {
      backgroundColor: "semantic.warning",
    },
    rightBox: {
      backgroundColor: "semantic.lightestWarning",
    },
  },
  info: {
    leftBox: {
      backgroundColor: "semantic.info",
    },
    rightBox: {
      backgroundColor: "semantic.lightestInfo",
    },
  },
  error: {
    leftBox: {
      backgroundColor: "semantic.error",
    },
    rightBox: {
      backgroundColor: "semantic.lightestError",
    },
  },
  lightestWarning: {
    leftBox: {
      backgroundColor: "semantic.lightestWarning",
      p: 3,
      pr: 0,
      pt: 4,
      w: undefined,
    },
    rightBox: {
      backgroundColor: "semantic.lightestWarning",
      p: 3,
    },
    icon: {
      color: "#CDAE91",
    },
  },
};
