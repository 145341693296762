/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuOpportunitiesIcon = createIcon({
  displayName: "MenuOpportunitiesIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <g clipPath="url(#clip0_12051_17002)">
        <path
          d="M5 23H1V13H5"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path
          d="M9 16H11"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path
          d="M9 19H11"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path
          d="M9 13H11"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path
          d="M13 6V2L23 5V23H5V9H15V23"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path
          d="M19 19V9"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_12051_17002">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  ),
});
export default MenuOpportunitiesIcon;
