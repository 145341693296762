/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const EyeOffIcon = createIcon({
  displayName: "EyeOffIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M1.373 13.183C1.13043 12.8362 1.00034 12.4232 1.00034 12C1.00034 11.5768 1.13043 11.1638 1.373 10.817C2.946 8.59 6.819 4 12 4C17.181 4 21.054 8.59 22.627 10.817C22.8696 11.1638 22.9997 11.5768 22.9997 12C22.9997 12.4232 22.8696 12.8362 22.627 13.183C21.054 15.41 17.181 20 12 20C6.819 20 2.946 15.41 1.373 13.183Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M2 22L22 2" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default EyeOffIcon;