/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const AlertErrorIcon = createIcon({
  displayName: "AlertErrorIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><g fill="currentColor"><path d="M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2zM12 8v4M12 16h.01" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></g></g>)
});
export default AlertErrorIcon;