import { CountryCallingCode, E164Number } from "libphonenumber-js";
import React from "react";
import { VeriPhoneInput } from "..";
import { errorMessages } from "../../../defines";
import { Controller, FieldValues, Path } from "../../../react-hook-form";
import { countryDialToIso } from "../../../utils/phone-numbers";
import {
  mapPhoneInputValueToPhoneValue,
  mapPhoneValueToPhoneInput,
  validatePhoneNumber,
} from "./HookFormVeriPhoneInput.helpers";
import { HookFormVeriPhoneInputProps } from "./HookFormVeriPhoneInput.types";

export const HookFormVeriPhoneInput = <T extends FieldValues>({
  name,
  form,
  rules,
  ...inputProps
}: HookFormVeriPhoneInputProps<T>) => {
  const countryCodeInputName = `${name}Code` as Path<T>;

  return (
    <>
      <Controller
        name={countryCodeInputName}
        control={form.control}
        render={({ field: countryCodeField }) => (
          <Controller
            name={name}
            control={form.control}
            defaultValue={"" as never}
            rules={{
              required: inputProps.isRequired && errorMessages.enterPhoneNumber,
              validate: (phoneValue) => {
                if (
                  !inputProps.isRequired &&
                  !phoneValue &&
                  !countryCodeField.value
                ) {
                  return undefined;
                }
                return validatePhoneNumber(
                  phoneValue as string,
                  countryCodeField.value as string,
                );
              },
              ...rules,
            }}
            render={({ field: phoneNumberField, fieldState: { error } }) => {
              const handleChangeCountryInput = (
                countryValue?: CountryCallingCode,
              ) => {
                countryCodeField.onChange({
                  target: { name: countryCodeInputName, value: countryValue },
                });
                phoneNumberField.onChange({ target: { name, value: "" } });
              };

              const handleChangePhoneInput = (phoneInputValue?: E164Number) => {
                let countryCode: string = countryCodeField.value;
                if (!countryCode) {
                  countryCodeField.onChange({
                    target: { name: countryCodeInputName, value: "1" },
                  });
                  countryCode = "1";
                }

                const phoneNumberVal = mapPhoneInputValueToPhoneValue(
                  phoneInputValue,
                  countryCode,
                );
                phoneNumberField.onChange({
                  target: { name, value: phoneNumberVal },
                });
              };

              return (
                <VeriPhoneInput
                  ref={phoneNumberField.ref}
                  name={name}
                  onBlur={phoneNumberField.onBlur}
                  label={inputProps.label || "Phone number"}
                  country={countryDialToIso(
                    countryCodeField.value as never,
                    "US",
                  )}
                  onChangeCountryNumeric={handleChangeCountryInput}
                  onChange={handleChangePhoneInput}
                  value={mapPhoneValueToPhoneInput(
                    phoneNumberField.value as string,
                    countryCodeField.value as string,
                  )}
                  error={error && error.message}
                  {...inputProps}
                />
              );
            }}
          />
        )}
      />
    </>
  );
};
