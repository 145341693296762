/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WorkflowJobJsonApiModel = {
  id: "",
  "workflow-id": "",
  workflow: {
    jsonApi: "hasOne",
    type: "workflow",
  },
  "queue-id": "",
  queue: {
    jsonApi: "hasOne",
    type: "workflow-job-queue",
  },
  "user-id": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  name: "",
  "start-on": "",
  tags: "",
  settings: "",
  data: "",
  status: "",
  "status-code": "",
  "created-at": "",
  "updated-at": "",
};
