/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const UserGroupIcon = createIcon({
  displayName: "UserGroupIcon",
  viewBox: "0 0 20 20",
  path: (
    <g fill="none">
      <path
        d="M3.33333 10.8333C4.25 10.8333 5 10.0833 5 9.16667C5 8.25 4.25 7.5 3.33333 7.5C2.41667 7.5 1.66667 8.25 1.66667 9.16667C1.66667 10.0833 2.41667 10.8333 3.33333 10.8333ZM4.275 11.75C3.96667 11.7 3.65833 11.6667 3.33333 11.6667C2.50833 11.6667 1.725 11.8417 1.01667 12.15C0.4 12.4167 0 13.0167 0 13.6917V15H3.75V13.6583C3.75 12.9667 3.94167 12.3167 4.275 11.75ZM16.6667 10.8333C17.5833 10.8333 18.3333 10.0833 18.3333 9.16667C18.3333 8.25 17.5833 7.5 16.6667 7.5C15.75 7.5 15 8.25 15 9.16667C15 10.0833 15.75 10.8333 16.6667 10.8333ZM20 13.6917C20 13.0167 19.6 12.4167 18.9833 12.15C18.275 11.8417 17.4917 11.6667 16.6667 11.6667C16.3417 11.6667 16.0333 11.7 15.725 11.75C16.0583 12.3167 16.25 12.9667 16.25 13.6583V15H20V13.6917ZM13.5333 11.375C12.5583 10.9417 11.3583 10.625 10 10.625C8.64167 10.625 7.44167 10.95 6.46667 11.375C5.56667 11.775 5 12.675 5 13.6583V15H15V13.6583C15 12.675 14.4333 11.775 13.5333 11.375ZM6.725 13.3333C6.8 13.1417 6.83333 13.0083 7.48333 12.7583C8.29167 12.4417 9.14167 12.2917 10 12.2917C10.8583 12.2917 11.7083 12.4417 12.5167 12.7583C13.1583 13.0083 13.1917 13.1417 13.275 13.3333H6.725ZM10 6.66667C10.4583 6.66667 10.8333 7.04167 10.8333 7.5C10.8333 7.95833 10.4583 8.33333 10 8.33333C9.54167 8.33333 9.16667 7.95833 9.16667 7.5C9.16667 7.04167 9.54167 6.66667 10 6.66667ZM10 5C8.61667 5 7.5 6.11667 7.5 7.5C7.5 8.88333 8.61667 10 10 10C11.3833 10 12.5 8.88333 12.5 7.5C12.5 6.11667 11.3833 5 10 5Z"
        fill="currentColor"
      />
    </g>
  ),
});
export default UserGroupIcon;
