/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuAccountsIcon = createIcon({
  displayName: "MenuAccountsIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <path
        d="M5.99707 12H7.19707"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M5.99707 7H7.19707"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M10.8018 7H18.0018"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M10.8018 12.001H18.0018"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M10.8018 16.8008H18.0018"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M5.99707 17H7.19707"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M21.9985 2H1.99854V22H21.9985V2Z"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </g>
  ),
});
export default MenuAccountsIcon;
