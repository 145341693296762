/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MapPinIcon = createIcon({
  displayName: "MapPinIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><g clipPath="url(#clip0_175_67)"><path d="M20 10C20 14.9 12 23 12 23C12 23 4 14.9 4 10C4 4.9 8.1 2 12 2C15.9 2 20 4.9 20 10Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g><defs><clipPath id="clip0_175_67"><rect width={24} height={24} fill="currentColor" /></clipPath></defs></g>)
});
export default MapPinIcon;