/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const AlertOctagonIcon = createIcon({
  displayName: "AlertOctagonIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M16.556 1H7.444L1 7.444V16.556L7.444 23H16.556L23 16.556V7.444L16.556 1Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 7V13" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z" fill="currentColor" /></g>)
});
export default AlertOctagonIcon;