/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuContactsIcon = createIcon({
  displayName: "MenuContactsIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <path
        d="M11.992 11.7373L14.2 13.4003C14.4484 13.5866 14.65 13.8282 14.7889 14.1059C14.9277 14.3836 15 14.6898 15 15.0003V16.0003H7V15.0003C7 14.6898 7.07229 14.3836 7.21115 14.1059C7.35 13.8282 7.55161 13.5866 7.8 13.4003L10.008 11.7373"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M13 9C13 7.89543 12.1046 7 11 7C9.89543 7 9 7.89543 9 9V10C9 11.1046 9.89543 12 11 12C12.1046 12 13 11.1046 13 10V9Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M2 1H18C18.5304 1 19.0391 1.21071 19.4142 1.58579C19.7893 1.96086 20 2.46957 20 3V21C20 21.5304 19.7893 22.0391 19.4142 22.4142C19.0391 22.7893 18.5304 23 18 23H2V1Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M23 5V9"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </g>
  ),
});
export default MenuContactsIcon;
