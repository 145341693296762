import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { ColoredTagProps } from "./ColoredTag.types";

const colorMap = {
  success: {
    bg: "bg.lightestGreen",
    color: "semantic.success",
  },
  error: {
    bg: "semantic.lightestError",
    color: "semantic.error",
  },
  warning: {
    bg: "semantic.lightestWarning",
    color: "semantic.warning",
  },
  info: {
    bg: "bg.greyLight",
    color: "text.greyDarker",
  },
  purple: {
    bg: "bg.lightPurple2",
    color: "bg.primaryRegular",
  },
  outline: {
    bg: "bg.white",
    color: "text.greyDarker",
  },
};

const ColoredTag: React.FC<ColoredTagProps> = ({
  type,
  children,
  isMedium,
  height: userHeight,
  ...rest
}) => {
  const { bg, color } = (type && colorMap[type]) || {
    bg: rest.bg || "bg.greyLight",
    color: rest.color || "text.greyDarker",
  };

  const height = userHeight || (isMedium ? null : "20px");
  const padding = isMedium ? null : "0px 8px";
  const px = isMedium ? 2 : null;
  const py = isMedium ? 0.5 : null;
  const textStyle = isMedium ? "bodySmall" : "bodyXSBold";
  const fontWeight = isMedium ? "500" : null;
  const isOutline = type === "outline";

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="fit-content"
      height={height || undefined}
      as="span"
      borderRadius="40px"
      padding={padding || undefined}
      px={px || undefined}
      py={py || undefined}
      border={isOutline ? "1px solid" : undefined}
      borderColor={isOutline ? "border.grey" : undefined}
      {...{ bg, color }}
      {...rest}
    >
      <Text textStyle={textStyle} fontWeight={fontWeight || undefined}>
        {children}
      </Text>
    </Box>
  );
};

export default ColoredTag;
