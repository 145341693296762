import { Box } from "@chakra-ui/react";
import React from "react";
import { usePopper } from "react-popper";
import { errorMessages } from "../../../defines";
import { useBooleanState } from "../../../hooks";
import { CalendarIcon } from "../../../icons";
import { useDidUpdate } from "../../../lifecycle/useDidUpdate";
import { Controller, FieldPath } from "../../../react-hook-form";
import {
  dateToFixedIsoString,
  dateToISOString,
  dateToYYYYMMDDNoIso,
  formatDateDDMMYYYY,
  formatDateMMDDYYYY,
  isValidInputDateDob,
  isValidIsoDate,
  isValidYYYYMMDDDate,
} from "../../../utils/dates";
import { isDateAllowed } from "../../datepicker/utils";
import { VeriDatePicker } from "../../datepicker/VeriDatePicker";
import { VeriInput } from "../VeriInput";
import { VeriDateInputProps } from "./VeriDateInput.types";

const VeriDateInput = <T, K extends FieldPath<T>>({
  form,
  name,
  isRequired = true,
  inputProps,
  label,
  helperText,
  usePicker,
  useCalendarIcon,
  requiredError = "You must enter a valid date",
  valueAsIsoString,
  valueAsYYYMMDDDate,
  notOptional,
  maxDate,
  minDate,
  dateNotAllowedErrorMessage,
  enabledDates,
  disabledDates,
  isDisabled,
  ...rest
}: VeriDateInputProps<T, K>) => {
  const [rootBoxRef, setRootBoxRef] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [pickerBoxRef, setPickerBoxRef] = React.useState<HTMLDivElement | null>(
    null,
  );

  const [arrowBoxRef, setArrowBoxRef] = React.useState<HTMLDivElement | null>(
    null,
  );

  const isPickerOpen = useBooleanState(false);

  const {
    styles,
    attributes,
    state: popperState,
  } = usePopper(
    usePicker ? rootBoxRef : undefined,
    usePicker ? pickerBoxRef : undefined,
    usePicker
      ? {
          modifiers: [
            {
              name: "offset",
              enabled: true,
              options: {
                offset: [0, 6],
              },
            },
            { name: "arrow", options: { element: arrowBoxRef } },
          ],
        }
      : undefined,
  );

  const onOutsideClick = React.useCallback(
    (event) => {
      if (rootBoxRef && !rootBoxRef.contains(event.target)) {
        isPickerOpen.false();
      }
    },
    [rootBoxRef],
  );

  const onInputFocus = () => {
    isPickerOpen.true();
  };

  React.useEffect(() => {
    if (!usePicker) return;
    window.document.addEventListener("click", onOutsideClick, true);

    return () => {
      window.document.removeEventListener("click", onOutsideClick, true);
    };
  }, [usePicker, rootBoxRef]);

  useDidUpdate(() => {
    if (form.formState.isSubmitted) {
      form.trigger(name);
    }
  }, [minDate, maxDate, enabledDates, disabledDates]);

  return (
    <Box w={"100%"} {...rest}>
      <Controller
        rules={{
          required: isRequired ? requiredError : undefined,
          validate: (v) => {
            if (!isRequired && !v) return undefined;

            if (valueAsYYYMMDDDate) {
              if (!isValidYYYYMMDDDate(String(v)))
                return errorMessages.enterAValidDate;
            } else {
              const isValidDate = valueAsIsoString
                ? isValidIsoDate(String(v))
                : isValidInputDateDob(String(v));

              if (!isValidDate) return errorMessages.enterAValidDate;
            }

            if (isNaN(Date.parse(v as string)))
              return errorMessages.enterAValidDate;

            if (maxDate || minDate || enabledDates || disabledDates) {
              const _isDateAllowed = isDateAllowed(
                dateToYYYYMMDDNoIso(v as string),
                {
                  maxDate,
                  minDate,
                  enabledDates,
                  disabledDates,
                },
                true,
              );
              if (typeof _isDateAllowed === "string" || !_isDateAllowed) {
                return (
                  dateNotAllowedErrorMessage ||
                  _isDateAllowed ||
                  "This date is not allowed"
                );
              }
            }

            return undefined;
          },
          // setValueAs: (value) => convertToSqlDate(value),
        }}
        render={({
          field: { onChange, value, name, ref },
          fieldState: { error },
        }) => {
          let inputValue = String(value);
          let pickerValue = String(value);
          if (valueAsYYYMMDDDate && isValidYYYYMMDDDate(inputValue)) {
            const inputValueSplit = inputValue.split("-");
            inputValue =
              pickerValue = `${inputValueSplit[1]}/${inputValueSplit[2]}/${inputValueSplit[0]}`;
          } else if (inputValue.match("T")) {
            inputValue = pickerValue = formatDateDDMMYYYY(new Date(inputValue));
          }

          const Input = (
            <VeriInput
              optional={!isRequired && !notOptional}
              label={label}
              isRequired={isRequired}
              helperText={helperText}
              onChange={(e) => {
                if (valueAsIsoString && isValidInputDateDob(e.target.value)) {
                  return onChange(dateToISOString(e.target.value));
                } else if (
                  valueAsYYYMMDDDate &&
                  isValidInputDateDob(e.target.value) &&
                  !isValidYYYYMMDDDate(inputValue)
                ) {
                  const inputValueSplit = String(e.target.value || "").split(
                    "/",
                  );
                  return onChange(
                    `${inputValueSplit[2]}-${inputValueSplit[0]}-${inputValueSplit[1]}` ||
                      null,
                  );
                }
                onChange(e.target.value || null);
              }}
              onFocus={onInputFocus}
              value={inputValue}
              ref={ref}
              name={name}
              inputLeft={
                useCalendarIcon ? <CalendarIcon boxSize={4} /> : undefined
              }
              propsInput={{ placeholder: "MM/DD/YYYY", isDisabled }}
              error={error?.message}
              mask="99/99/9999"
              {...inputProps}
            />
          );

          if (usePicker) {
            const pickerArrow = (
              <Box
                ref={setArrowBoxRef}
                style={{
                  ...styles.arrow,
                  transform:
                    (styles.arrow?.transform || "") +
                    ` rotate(${
                      popperState?.placement === "top" ? "45deg" : "225deg"
                    })`,
                }}
                boxShadow="1px 1px 2px 0px rgb(96 97 112 / 16%)"
                backgroundColor="bg.white"
                width={"12px"}
                height={"12px"}
                borderColor="transparent"
                borderWidth={1}
                borderStyle={"solid"}
                zIndex={3}
                marginTop={"-6px"}
                className="react-datepicker__triangle"
              />
            );

            return (
              <Box ref={setRootBoxRef}>
                {Input}
                {isPickerOpen.state && (
                  <Box
                    ref={setPickerBoxRef}
                    style={{ ...styles.popper, zIndex: 1500 }}
                    {...attributes.popper}
                    className="react-datepicker-popper"
                  >
                    {popperState?.placement === "bottom" && pickerArrow}
                    <Box
                      boxShadow={2}
                      background={"white"}
                      border={"1px solid"}
                      borderColor={"grey.1000"}
                      borderRadius={4}
                      px={3}
                      py={2}
                      position={"relative"}
                    >
                      <VeriDatePicker
                        maxDate={maxDate}
                        minDate={minDate}
                        enabledDates={enabledDates}
                        disabledDates={disabledDates}
                        value={pickerValue}
                        onDayPick={isPickerOpen.false.bind(null)}
                        onDateChange={(d) => {
                          if (valueAsYYYMMDDDate) {
                            return onChange(d);
                          }
                          if (valueAsIsoString) {
                            return onChange(
                              dateToFixedIsoString(d || new Date()),
                            );
                          }

                          return onChange(formatDateMMDDYYYY(d || new Date()));
                        }}
                      />
                    </Box>
                    {popperState?.placement === "top" && pickerArrow}
                  </Box>
                )}
              </Box>
            );
          }

          return Input;
        }}
        control={form.control}
        name={name}
      />
    </Box>
  );
};

export default VeriDateInput;
