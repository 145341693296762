import useGetDynamicConfig from "./useGetDynamicConfig";
import DynamicConfigFlags from "../configs";

export default function useGetDynamicConfigIsMobile() {
  const data = useGetDynamicConfig(DynamicConfigFlags.IS_MOBILE);
  if (data && typeof data === "boolean") {
    return data;
  }

  return false;
}
