/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum AssetsToAcquireNumberEnumType {
  MultipleAssets = "Multiple Assets",
  SingleAsset = "Single Asset",
}
