import { Box } from "@chakra-ui/react";
import { useAuth } from "@verivest/shared-components/src/auth";
import React from "react";
import { useHistory } from "react-router-dom";
import MenuItem from "../MenuItem";
import {
  buildHeaderLoggedInItemDataCy,
  guestTextMenuItems,
  loggedUserTextMenuItems,
} from "./logged-menu-items";
import { useLayout } from "@verivest/shared-components/src/hooks/ui/useLayout";

const LoggedMenuItems = () => {
  const { auth } = useAuth();
  const history = useHistory();
  const currentPath = history.location.pathname;
  const { layout } = useLayout();

  const isMobile = layout.breakpoint === "base";

  return (
    <Box
      id="header-menu"
      display="flex"
      width={"100%"}
      alignItems={{ base: "unset", md: "center" }}
      flexGrow={1}
      flexDirection={{ base: "column", md: "row" }}
      justifyContent={"flex-start"}
    >
      {loggedUserTextMenuItems.map((item) => (
        <MenuItem key={item.name} to={item.to} currentPath={currentPath}>
          <span>{item.children}</span>
        </MenuItem>
      ))}

      <Box display={"none"} borderTopWidth="1px" borderTopColor="border.input">
        <MenuItem to="/user/sessings" currentPath={currentPath}>
          Settings
        </MenuItem>
        <MenuItem onClick={auth.logout} currentPath={currentPath}>
          Log out
        </MenuItem>
      </Box>
    </Box>
  );
};

export default LoggedMenuItems;
