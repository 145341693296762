/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const EntityJsonApiModel = {
  id: "",
  name: "",
  "entity-type": "",
  "entity-type-other": "",
  "primary-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "primary-address-id": "",
  "mailing-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "mailing-address-id": "",
  "formation-date": "",
  "registered-date": "",
  "registered-country": "",
  "registered-state": "",
  tin: "",
  "tin-type": "",
  "investor-account": {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "investor-account-id": "",
  "contact-phone": "",
  "contact-email": "",
  "investor-relations-phone": "",
  "investor-relations-phone-code": "",
  "investor-relations-email": "",
  "company-id": "",
  "created-at": "",
  "updated-at": "",
};
