/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BillingScheduleTierJsonApiModel = {
  id: "",
  "billing-schedule-id": "",
  "billing-schedule": {
    jsonApi: "hasOne",
    type: "billing-schedules",
  },
  "tier-index": "",
  "tier-base": "",
  "tier-limit": "",
  factor: "",
  "minimum-charge": "",
  "fixed-charge": "",
  "created-at": "",
  "updated-at": "",
};
