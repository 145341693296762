enum DynamicConfigFlags {
  BASE_SPONSOR_URL = "baseSponsorUrl",
  IS_TENANT = "isTenant",
  TENANT = "manager",
  IS_SPONSOR_DASHBOARD = "isSponsorDashboard",
  IS_MALLCOP_DASHBOARD = "isMallcopDashboard",
  IS_MOBILE = "isMobile",
  BASE_URL = "baseURL",
  MANAGER = "manager",
  DASHBOARD_MANAGER = "managerDashboardTenant",
  IS_MANAGER = "isManager",
  FEATURE_FLAGS = "featureFlags",
}

export default DynamicConfigFlags;
