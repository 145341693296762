/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BillingScheduleJsonApiModel = {
  id: "",
  "opportunity-id": "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  name: "",
  "is-active": "",
  "deactivated-at": "",
  "active-start-date": "",
  "active-end-date": "",
  basis: "",
  "factor-type": "",
  "billing-period-frequency": "",
  tiers: {
    jsonApi: "hasMany",
    type: "billing-schedule-tiers",
  },
  "created-at": "",
  "updated-at": "",
};
