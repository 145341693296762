/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MailIcon = createIcon({
  displayName: "MailIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><g clipPath="url(#clip0_142_20)"><path d="M1.614 4.558L12 14L22.385 4.559" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M21 4H3C1.89543 4 1 4.89543 1 6V20C1 21.1046 1.89543 22 3 22H21C22.1046 22 23 21.1046 23 20V6C23 4.89543 22.1046 4 21 4Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g><defs><clipPath id="clip0_142_20"><rect width={24} height={24} fill="currentColor" /></clipPath></defs></g>)
});
export default MailIcon;