import React, { ErrorInfo } from "react";
import { logError } from "../logError";

interface State {
  hasError: boolean;
}

interface Props {
  fallback: React.ReactNode | ((onClearError: () => void) => React.ReactNode);
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };

    this.handleClearError = this.handleClearError.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, errorInfo);
  }

  handleClearError() {
    this.setState({ hasError: false });
  }

  override render() {
    if (this.state.hasError) {
      if (typeof this.props.fallback === "function") {
        return this.props.fallback(this.handleClearError);
      }
      return this.props.fallback;
    }

    return this.props.children;
  }
}
