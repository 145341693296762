/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum InvestmentPaymentMethodType {
  Wire = "Wire",
  Check = "Check",
}
