import { Icon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import React from "react";
import { VeriTableAction, VeriTableObject } from "../../VeriTable.types";
import { InlineActionItemWrapper } from "./InlineActionItemWrapper";

export interface InlineActionItemProps<T> {
  action: VeriTableAction;
  tableObject: VeriTableObject<T>;
  actionName: string;
  isEnabled: boolean;
  recordIndex: number;
  recordIdentifier: string;
  tooltipText?: string;
  record: T;
}

const InlineActionItem = <T,>({
  action,
  isEnabled,
  actionName,
  recordIndex,
  recordIdentifier,
  tableObject,
  record,
  tooltipText,
}: InlineActionItemProps<T>) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const _doAction = async () =>
    action.callback &&
    (await action.callback([recordIdentifier], [record], tableObject));

  const doAction = async () => {
    if (!action?.confirm?.inlineBanner) setIsLoading(true);

    if (isEnabled) {
      if (action.confirm) {
        tableObject.setConfirmData({
          isOpen: true,
          title: action.confirm.title ? action.confirm.title(record) : "",
          message: action.confirm.message ? action.confirm.message(record) : "",
          onClose: () => {
            tableObject.setConfirmData(null);
            setIsLoading(false);
          },
          onConfirm: async () => {
            await _doAction();
            tableObject.setConfirmData(null);
            setIsLoading(false);
          },
          cancelLabel: action.confirm.cancelLabel
            ? action.confirm.cancelLabel(record)
            : "Cancel",
          confirmLabel: action.confirm.confirmLabel
            ? action.confirm.confirmLabel(record)
            : "Confirm",
          confirmProps: action.confirm.confirmProps
            ? action.confirm.confirmProps(record)
            : {},
          cancelProps: action.confirm.cancelProps
            ? action.confirm.cancelProps(record)
            : {},
          record,
          inlineBanner: action.confirm.inlineBanner || false,
        });

        return;
      }

      try {
        action.callback && (await _doAction());
      } catch (e) {
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  };

  return (
    <InlineActionItemWrapper tooltipText={tooltipText}>
      <IconButton
        isLoading={isLoading}
        background={"transparent"}
        borderRadius={4}
        isDisabled={!isEnabled}
        key={"action-" + actionName + "-" + recordIndex}
        textStyle={"bodyRegular"}
        onClick={doAction}
        aria-label={"action-" + actionName + "-" + recordIndex}
      >
        <Icon as={action.icon} boxSize={"24px"} color={"icons.grey"} />
      </IconButton>
    </InlineActionItemWrapper>
  );
};

export default InlineActionItem;
