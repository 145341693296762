/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuFundBuilderIcon = createIcon({
  displayName: "MenuFundBuilderIcon",
  viewBox: "0 0 256 256",
  path: (
    <g fill="none">
      <g fill="currentColor" id="icons_without_caption">
        <path d="M210.06 89.58a12.72 12.72 0 0 0-12.69 14.33L169.65 112a52.81 52.81 0 0 0-14.79-18.77l8.11-12a19.32 19.32 0 1 0-3.23-2.36l-8.1 11.94a52.46 52.46 0 0 0-76.93 20.72L58.33 102a13.26 13.26 0 1 0-2.33 3.22l17.15 10a52.33 52.33 0 0 0 7.46 51.06l-10.61 9a21.19 21.19 0 1 0 2.38 3.22l10.78-9.18a52.42 52.42 0 0 0 74.14 3.75l12 11.2a12.74 12.74 0 1 0 2.94-2.73l-12.11-11.27a52.38 52.38 0 0 0 11.08-54.62l27.25-7.94a12.78 12.78 0 1 0 11.58-18.18Zm-52.67-24.92a15.2 15.2 0 1 1 15.2 15.2 15.22 15.22 0 0 1-15.2-15.2Zm-110.84 40.5a9.26 9.26 0 1 1 9.26-9.26 9.27 9.27 0 0 1-9.26 9.26Zm7.73 101.39a17.12 17.12 0 1 1 17.12-17.13 17.14 17.14 0 0 1-17.12 17.12Zm133.86-14.33a8.79 8.79 0 1 1-8.79-8.79 8.8 8.8 0 0 1 8.79 8.79Zm-66.05-9.51a48.51 48.51 0 1 1 48.51-48.51 48.57 48.57 0 0 1-48.51 48.51Zm88-71.55a8.79 8.79 0 1 1 8.79-8.79 8.8 8.8 0 0 1-8.82 8.79Z" />
        <path d="M123.17 129.34c-5.62-1.84-9.7-3.39-9.7-7.59 0-3.95 3.29-6.15 8-6.15a20.9 20.9 0 0 1 11.34 4l3.38-4.17a27.54 27.54 0 0 0-11.47-4.9v-8.83h-4.5v8.51c-8.22.4-12.79 5.36-12.79 11.9 0 8 6.71 10.32 14.07 12.69 6.21 1.94 10.09 3.55 10.09 8.38 0 4.24-3.68 6.71-8.64 6.71a19.12 19.12 0 0 1-13-5.59l-3.52 4.21a23.47 23.47 0 0 0 13.77 6.61v8.22h4.5v-8.15c8.18-.66 13-5.49 13-12.36.03-8.65-6.25-10.83-14.53-13.49Z" />
      </g>
    </g>
  ),
});
export default MenuFundBuilderIcon;
