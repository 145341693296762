import React from "react";
import { DefaultAvatarImageProps } from "./DefaultAvatarImage.types";
import { Box, Flex } from "@chakra-ui/react";
import { Icon } from "@chakra-ui/icons";
import { UserIcon } from "../../../../../icons";

const DefaultAvatarImage: React.FC<DefaultAvatarImageProps> = (props) => {
  return (
    <Box
      boxShadow="inset 0px 0px 8px rgba(0, 0, 0, 0.06)"
      h="72px"
      w="72px"
      borderRadius="50%"
      bg="bg.lighter"
      {...props}
    >
      <Flex justifyContent="center" height="100%" alignItems="center">
        <Icon as={UserIcon} boxSize="24px" color="icons.grey"></Icon>
      </Flex>
    </Box>
  );
};

export default DefaultAvatarImage;
