import {
  Box,
  IconButton,
  ResponsiveValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useNotificationsModel } from "@verivest/shared-components/src/api/hooks/models/useNotificationsModel/useNotificationsModel";
import useAuth from "@verivest/shared-components/src/auth/useAuth";
import { NotificationsResponse } from "@verivest/shared-components/src/components/notification/notifications.types";
import {
  NotificationActionTypes,
  NotificationStorageType,
} from "@verivest/shared-components/src/configure-store/reducers/notifications";
import { useStickySWR } from "@verivest/shared-components/src/hooks/data/useStickySWR";
import { default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BellIcon } from "./Icons";
import NotificationsDrawer from "./NotificationsDrawer/NotificationsDrawer";

export const newNotificationsCircleStyle = {
  display: "block",
  pos: "absolute" as ResponsiveValue<"absolute">,
  minW: "12px",
  h: "12px",
  top: "4px",
  left: "24px",
  backgroundColor: "semantic.success",
  borderWidth: "0",
  borderRadius: "8px",
  fontSize: "8px",
  color: "white",
  pt: "1px",
};

const NotificationBell = () => {
  const size = useBreakpointValue({ base: "full", md: "sm" });
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const { auth } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const profile = auth.getProfile();
  const dispatch = useDispatch();

  const notificationsModel = useNotificationsModel({
    urlParams: {
      user_id: profile?.id,
    },
  });

  const lastUpdate = useSelector(
    (state: { notification: NotificationStorageType }) =>
      state.notification.lastUpdate,
  );

  const [pageSize, setPageSize] = useState(10);

  const { data, mutate } = useStickySWR<{
    data?: NotificationsResponse;
    meta?: {
      pageTotal: number;
    };
  }>(
    `notifications?user_id=${profile?.id}&sort=viewed,-id&page[size]=${pageSize}`,
    notificationsModel.getPagedNotifications,
    {
      focusThrottleInterval: 0,
    },
  );

  React.useEffect(() => {
    // this mutation will be triggered by the notification socket
    // but on BE new notification will be added to the list with a delay
    // so we need to refetch the data after some time
    setTimeout(() => {
      mutate();
    }, 3000);
  }, [lastUpdate]);

  const handleClose = () => {
    onClose();
    dispatch({
      type: NotificationActionTypes.SET_DRAWER_IS_OPEN,
      drawerIsOpen: false,
    });
    mutate();
  };

  const pageTotal = data?.meta?.pageTotal ?? 0;
  const moreToLoad = pageTotal > 1;
  const { notifications, unviewedCount } = data?.data ?? {};

  const onLoad = () => {
    setPageSize((prevPageSize) => {
      const newSize = prevPageSize + 10;
      // Placing mutate() here guarantees that it will run **immediately**
      // once the new pageSize value is set
      mutate();
      return newSize;
    });
  };

  const openAndShout = () => {
    mutate();
    dispatch({
      type: NotificationActionTypes.SET_DRAWER_IS_OPEN,
      drawerIsOpen: true,
    });
    onOpen();
  };

  return (
    <>
      <IconButton
        variant="ghost"
        borderWidth={0}
        rounded={"100%"}
        ref={btnRef}
        onClick={openAndShout}
        position="relative"
        aria-label="Notifications Bell"
        icon={
          <Box>
            <Box
              data-test-id={"unread-notifications-indicator"}
              {...newNotificationsCircleStyle}
              d={unviewedCount ? "block" : "none"}
            >
              {unviewedCount}
            </Box>
            <BellIcon
              color={unviewedCount ? "brand.main" : "text.gray"}
              boxSize={"24px"}
            />
          </Box>
        }
      />
      <NotificationsDrawer
        isOpen={isOpen}
        onClose={handleClose}
        finalFocusRef={btnRef}
        size={size}
        notifications={notifications}
        onLoad={onLoad}
        moreToLoad={moreToLoad}
        mutate={mutate}
        hasUnviewed={Boolean(unviewedCount)}
      />
    </>
  );
};

export default NotificationBell;
