/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AttributeJsonApiModel = {
  id: "",
  "attribute-type": "",
  "attribute-key": "",
  name: "",
  description: "",
};
