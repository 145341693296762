import { accountWizardRoutes } from "../../../../routes/account/accountWizardRoutes";

const createAccountBasePath = "/w/create";

type AccountWizardRoutes = Record<keyof typeof accountWizardRoutes, string>;

const accountWizardRoutesForCreateAccount: AccountWizardRoutes = Object.keys(
  accountWizardRoutes,
).reduce((acc, route) => {
  return {
    ...acc,
    [route]: `${createAccountBasePath}${
      accountWizardRoutes[route as keyof typeof accountWizardRoutes]
    }`,
  };
}, {} as AccountWizardRoutes);

export const createAccountRoutes = {
  ...accountWizardRoutesForCreateAccount,
  base: createAccountBasePath,
};
