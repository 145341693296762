/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MinusCircleFilledIcon = createIcon({
  displayName: "MinusCircleFilledIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /><path d="M8 12H16" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></g>)
});
export default MinusCircleFilledIcon;