import React from "react";
import { Box, BoxProps, useTheme } from "@chakra-ui/react";

const VeriHeading: React.FC<BoxProps> = ({ children, as = "h2", ...rest }) => {
  const theme = useTheme();
  const textStyleString =
    typeof rest.textStyle === "string" ? rest.textStyle : null;

  return (
    <Box
      fontFamily={
        (textStyleString &&
          theme.textStyles[textStyleString] &&
          theme.textStyles[textStyleString].fontFamily) ||
        "heading"
      }
      fontWeight={
        (textStyleString &&
          theme.textStyles[textStyleString] &&
          theme.textStyles[textStyleString].fontWeight) ||
        "bold"
      }
      as={as}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default VeriHeading;
