/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AllocationMethodJsonApiModel = {
  id: "",
  "allocation-group": {
    jsonApi: "hasOne",
    type: "allocation-groups",
  },
  document: {
    jsonApi: "hasOne",
    type: "documents",
  },
  "document-id": "",
  memo: "",
  "method-name": "",
  "method-type": "",
  "pay-to-date": "",
  "lump-sum": "",
  "begin-date": "",
  "end-date": "",
  "amount-per-unit": "",
  "method-total": "",
};
