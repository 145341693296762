import { Avatar, Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { UserModel } from "../../../api/models";

export interface ProfileButtonAvatarProps extends BoxProps {
  profile: UserModel;
  isImpersonating?: boolean;
  dataTestId?: string;
}

export const ProfileButtonAvatar = React.forwardRef<
  HTMLDivElement,
  ProfileButtonAvatarProps
>(({ profile, isImpersonating, dataTestId, ...rest }, ref) => {
  return (
    <Box ref={ref} {...rest} cursor="pointer" position="relative" mr="16px">
      <Avatar
        data-testid={dataTestId}
        width="38px"
        height="38px"
        name={profile.name || ""}
        src={profile.picture || ""}
        fontFamily="satoshi"
        css={{ WebkitFontSmoothing: "auto" }}
        pt={profile.picture ? undefined : 0.5}
      />
      {isImpersonating && (
        <Box
          top="-2px"
          right="-6px"
          bg="red"
          w="8px"
          h="8px"
          borderRadius="4px"
          position="absolute"
        >
          &nbsp;
        </Box>
      )}
    </Box>
  );
});

ProfileButtonAvatar.displayName = "ProfileButtonAvatar";
