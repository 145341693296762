/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BillingEntityJsonApiModel = {
  id: "",
  name: "",
  "parent-entity-id": "",
  "parent-entity": {
    jsonApi: "hasOne",
    type: "billing-entities",
  },
  "bill-with-parent-entity": "",
  email: "",
  "phone-number": "",
  "contact-person": "",
  "billing-address-id": "",
  "billing-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "physical-address-id": "",
  "physical-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "stripe-customer-id": "",
  "hubspot-company-id": "",
  notes: "",
  tin: "",
  "quickbooks-client-id": "",
  "quickbooks-client-number": "",
  "created-by-manager": {
    jsonApi: "hasOne",
    type: "managers",
  },
  "created-by-manager-id": "",
  "has-children-entity": "",
  "has-opportunity": "",
  "has-manager": "",
};
