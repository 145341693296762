import React from "react";
import { TableWithFilterNoRecordsProps } from "./TableWithFilterNoRecords.types";
import { TableNoRecords } from "../TableNoRecords";
import { SearchIcon } from "../../../icons";

const TableWithFilterNoRecords: React.FC<TableWithFilterNoRecordsProps> = ({
  clearFilters,
}) => {
  return (
    <TableNoRecords
      title={"No results found"}
      description={
        "No results match the filter criteria. Remove filters or clear all filters to show results."
      }
      icon={SearchIcon}
      primaryActionLabel={"Clear all filters"}
      primaryAction={clearFilters}
      primaryActionProps={{ empty: true }}
    />
  );
};

export default TableWithFilterNoRecords;
