import { createIcon, Icon } from "@chakra-ui/icons";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useDropzone } from "react-dropzone";
import { getMimeTypesByType } from "../../../../utils";
import { Button, VeriHeading } from "../../../common";
import LoadingBox from "../../../common/loaders/LoadingBox/LoadingBox";
import { ConfirmModal } from "../../../modals";
import { FileRow } from "./components";
import {
  VeriDropZoneProps,
  VeriUploadFile,
  VeriUploadFileList,
} from "./VeriDropZone.types";

export const UploadIcon = createIcon({
  displayName: "UploadIcon",
  viewBox: "0 0 40 40",
  path: (
    <g fill="none">
      <path
        d="M26.667 26.667L20.001 20l-6.667 6.667M20 20v15"
        stroke="#A1A5AC"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.984 30.65A8.333 8.333 0 0030 15h-2.1A13.332 13.332 0 008.736 6.565 13.334 13.334 0 005 27.168"
        stroke="#A1A5AC"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.667 26.667L20.001 20l-6.667 6.667"
        stroke="#A1A5AC"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

const VeriDropZone: React.FC<VeriDropZoneProps> = ({
  multiple = false,
  accept: _accept,
  onChange,
  isSmall = false,
  description,
  subDescription,
  showActionButton,
  actionName = "Choose",
  previewImage = false,
  dropTypeText,
  browseText,
  enableFileList = true,
  previewBlob = null,
  previewValue,
  acceptPreset = "",
  hideUploadIconOnPreview = false,
  previewProps = null,
  hideDropZoneOnSelect,
  files,
  deleteConfirm,
  onDelete,
  onDeletePinned,
  error,
  fileRowComponent,
  isDisabled,
  icon,
  isLoading = false,
  pinnedFiles,
  pinnedFilesTitle = "Already uploaded",
  label,
  ...rest
}) => {
  const [selectedFiles, setSelectedFiles] = React.useState<VeriUploadFileList>(
    files || [],
  );
  const [imagePreview, setImagePreview] = React.useState<VeriUploadFileList>(
    previewValue || [],
  );
  const [confirmData, setConfirmData] = React.useState(null);

  const accept = Array.isArray(_accept)
    ? [..._accept, ...getMimeTypesByType(acceptPreset)]
    : getMimeTypesByType(acceptPreset);

  const _setSelectedFiles = (selection: VeriUploadFileList) => {
    setSelectedFiles(selection);

    if (!multiple && previewImage) {
      setImagePreview(
        selection.map((file) =>
          Object.assign(file, {
            preview: file.file ? URL.createObjectURL(file.file) : "",
          }),
        ),
      );
    }

    onChange && onChange(selection);

    if (!enableFileList) {
      setSelectedFiles([]);
    }
  };

  const removeSelectedFile = (index: number) => {
    const deleteResult = onDelete?.({ ...selectedFiles[index] });
    if (deleteResult === false) return;
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    _setSelectedFiles(newFiles);
  };

  const deletePinnedFile = (index: number) => {
    onDelete?.({ ...pinnedFiles?.[index] });
  };

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      if (multiple) {
        _setSelectedFiles(
          [
            ...selectedFiles,
            ...acceptedFiles.map((file: VeriUploadFile) => ({
              file,
              name: file.name,
              isPublic: false,
            })),
          ].map((file, fileIndex) => ({ ...file, sortOrder: fileIndex })),
        );
        return;
      }

      _setSelectedFiles(
        acceptedFiles && acceptedFiles.length
          ? [
              {
                file: acceptedFiles[0],
                name: acceptedFiles[0].name,
                isPublic: false,
                sortOrder: 0,
              },
            ]
          : [],
      );
    },
    [selectedFiles], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  React.useEffect(() => {
    setSelectedFiles(files || []);
  }, [files]);

  React.useEffect(() => {
    setImagePreview(previewValue || []);
  }, [previewValue]);

  return (
    <Box pos={"relative"} w={"100%"}>
      {pinnedFiles && pinnedFiles.length > 0 && (
        <>
          {pinnedFilesTitle && (
            <VeriHeading mb={4}>{pinnedFilesTitle}</VeriHeading>
          )}
          {pinnedFiles.map((file, fileIndex) => {
            const FileRowComponentView =
              fileRowComponent instanceof Function ? fileRowComponent : FileRow;

            return (
              <FileRowComponentView
                w={"100%"}
                file={file}
                fileName={file.name}
                fileIndex={fileIndex}
                key={fileIndex}
                onRemove={(index: number) => deletePinnedFile(index)}
                sortable={false}
                uploading={file.uploading || false}
                error={file.error || false}
                success={file.success || undefined}
                usePublicToggle={false}
                setConfirmData={setConfirmData}
                deleteConfirm={deleteConfirm}
              />
            );
          })}
        </>
      )}
      {label && <VeriHeading mb={4}>{label}</VeriHeading>}
      {enableFileList && selectedFiles.length > 0 && (
        <Box
          // mb={
          //   (hideDropZoneOnSelect && selectedFiles.length === 0) ||
          //   !hideDropZoneOnSelect
          //     ? 8
          //     : 0
          // }
          mb={2}
          w={"100%"}
        >
          {selectedFiles.map((file, fileIndex) => {
            const FileRowComponentView =
              fileRowComponent instanceof Function ? fileRowComponent : FileRow;

            return (
              <FileRowComponentView
                w={"100%"}
                file={file}
                fileName={file.name}
                fileIndex={fileIndex}
                key={fileIndex}
                onRemove={(index: number) => removeSelectedFile(index)}
                sortable={false}
                uploading={file.uploading || false}
                error={file.error || false}
                success={file.success || undefined}
                usePublicToggle={false}
                setConfirmData={setConfirmData}
                deleteConfirm={deleteConfirm}
              />
            );
          })}
        </Box>
      )}
      {((hideDropZoneOnSelect && selectedFiles.length === 0) ||
        !hideDropZoneOnSelect) && (
        <Flex
          cursor="pointer"
          bg="bg.grey"
          w={"100%"}
          justify={!isSmall ? "center" : "flex-start"}
          align={!isSmall ? "center" : "flex-start"}
          borderRadius={4}
          border="1px dashed"
          borderColor="border.input"
          textAlign="center"
          direction={"column"}
          {...rest}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Box p={!isSmall ? 8 : 6} w={showActionButton ? "100%" : undefined}>
            {!isSmall &&
              !hideUploadIconOnPreview &&
              (icon || <Icon as={UploadIcon} boxSize="40px" />)}
            <Flex
              w={showActionButton ? "100%" : undefined}
              flexDirection={isSmall ? "row" : "column"}
              alignItems={"center"}
              justifyContent={isSmall ? "center" : undefined}
            >
              {isLoading && <LoadingBox />}
              {!multiple &&
              !isLoading &&
              (previewBlob || (previewImage && imagePreview.length > 0)) ? (
                <Box flexGrow={1}>
                  <Box w={"100%"} maxW={"300px"} overflow={"hidden"}>
                    <Image
                      src={
                        previewBlob
                          ? URL.createObjectURL(previewBlob)
                          : imagePreview[0].preview
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <>
                  <Text
                    mt={!isSmall ? 4 : 0}
                    flexGrow={1}
                    textAlign={showActionButton ? "left" : "center"}
                    textColor={isDisabled ? "text.grey" : undefined}
                  >
                    {description || (
                      <>
                        Drop your{" "}
                        {dropTypeText || `file${multiple ? "(s)" : ""}`} here or{" "}
                        <Text
                          as={"span"}
                          color={isDisabled ? "text.grey" : "brand.main"}
                          textStyle="bodyRegular"
                        >
                          {browseText || "browse your computer"}
                        </Text>
                      </>
                    )}
                  </Text>
                  {subDescription}
                </>
              )}

              {showActionButton && (
                <Box>
                  <Button
                    backgroundColor={"transparent"}
                    isBold
                    label={actionName}
                    outline
                  />
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
      )}
      {isDisabled && (
        <Box
          pos={"absolute"}
          left={0}
          right={0}
          bottom={0}
          top={0}
          bg={"bg.black"}
        />
      )}
      {confirmData && <ConfirmModal isOpen={true} {...confirmData} />}
      {error && (
        <Box color={"semantic.error"} pt={2}>
          {error}
        </Box>
      )}
    </Box>
  );
};

export default VeriDropZone;
