import {
  Box,
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Property } from "csstype";
import React from "react";
import { useLayout } from "../../../hooks/ui/useLayout";
import { MessageBox, VeriHeading } from "../../common";
import { BackButton } from "../../dashboard";
import { VeriDrawerProps } from "./VeriDrawer.types";
const VeriDrawer: React.FC<VeriDrawerProps> = ({
  children,
  overlayProps,
  drawerContentProps,
  drawerBodyProps,
  footer,
  drawerFooterProps,
  primaryButton: _userDefinedPrimaryButtons,
  secondaryButton: _userDefinedSecondaryButtons,
  title,
  noHeaderShadow,
  variant,
  modalAsDrawer,
  noAnimation,
  backTitle = "Back",
  backAction,
  message,
  messageType = "error",
  isLoading,
  messageTitle,
  otherOpenDrawersCount,
  hideCloseButton,
  isModal,
  ...rest
}) => {
  const { layout } = useLayout();
  const size = useBreakpointValue({ base: "full", md: "half" });

  const primaryButton =
    layout.app === "admin"
      ? _userDefinedSecondaryButtons
      : _userDefinedPrimaryButtons;
  const secondaryButton =
    layout.app === "admin"
      ? _userDefinedPrimaryButtons
      : _userDefinedSecondaryButtons;

  const BaseComponent = modalAsDrawer ? Modal : Drawer;
  const OverlayComponent = modalAsDrawer ? ModalOverlay : DrawerOverlay;
  const ContentComponent = modalAsDrawer ? ModalContent : DrawerContent;
  const HeaderComponent = modalAsDrawer ? DrawerHeader : DrawerHeader;
  const BodyComponent = modalAsDrawer ? DrawerBody : DrawerBody;
  const FooterComponent = modalAsDrawer ? DrawerFooter : DrawerFooter;

  const extraContentProps = modalAsDrawer
    ? {
        position: "fixed" as Property.Position,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 0,
        borderRadius: 0,
      }
    : otherOpenDrawersCount
    ? {
        marginRight: `${otherOpenDrawersCount * 20}px`,
        filter: "sepia(100%)",
      }
    : undefined;

  if (variant === "form" || variant === "form-alt" || variant === "view-alt") {
    return (
      <BaseComponent
        motionPreset={noAnimation ? "none" : "slideInRight"}
        scrollBehavior={"inside"}
        {...rest}
      >
        <OverlayComponent
          // animation={() => ({ opacity: 200 })}
          {...overlayProps}
        />
        <ContentComponent
          {...(modalAsDrawer && { maxH: "auto" })}
          {...((!isModal && extraContentProps) || {})}
          {...drawerContentProps}
          animation={"all 0.3s ease-in-out"}
        >
          <Box p={8} pb={0}>
            <DrawerCloseButton
              position={variant === "form" ? "static" : "absolute"}
              mt={variant === "form-alt" || variant === "view-alt" ? 8 : 0}
              mr={variant === "form-alt" || variant === "view-alt" ? 8 : 0}
            />
            {backAction && (
              <Box mb={variant === "form" ? 0 : 8}>
                <BackButton
                  onClick={backAction}
                  title={backTitle}
                  my={0}
                  variant={"link"}
                  color={"brand.main"}
                />
              </Box>
            )}
            {message && (
              <MessageBox
                type={messageType}
                title={messageTitle}
                text={message}
                mb={6}
                filled
              />
            )}
            {title && (
              <VeriHeading mt={variant === "form" ? 8 : 0} textStyle={"h3"}>
                {title}
              </VeriHeading>
            )}
          </Box>
          <BodyComponent
            mt={variant === "form" ? 6 : 0}
            pb={variant === "form" ? 12 : 0}
            px={8}
            {...drawerBodyProps}
          >
            {isLoading ? (
              <Flex justifyContent={"center"}>
                <CircularProgress
                  isIndeterminate
                  color={"brand.main"}
                  thickness={2}
                  mt={0}
                />
              </Flex>
            ) : (
              children
            )}
          </BodyComponent>
          {variant !== "view-alt" && (
            <FooterComponent
              boxShadow={
                "0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)"
              }
            >
              {!isLoading && (primaryButton || secondaryButton) && (
                <Flex w="100%" justifyContent={"space-between"}>
                  <Box mr={4}>{primaryButton && primaryButton(size)}</Box>
                  <Box>{secondaryButton && secondaryButton(size)}</Box>
                </Flex>
              )}
              {isLoading && (primaryButton || secondaryButton) && (
                <Flex w="100%" justifyContent={"space-between"}>
                  <Box mr={4}></Box>
                  <Box>{/* Cancel button */}</Box>
                </Flex>
              )}
              {footer}
            </FooterComponent>
          )}
        </ContentComponent>
      </BaseComponent>
    );
  }

  return (
    <BaseComponent motionPreset={noAnimation ? "none" : undefined} {...rest}>
      <OverlayComponent {...overlayProps} />
      <ContentComponent {...drawerContentProps}>
        {size === "full" && (
          <Flex
            justifyContent="space-between"
            boxShadow={!noHeaderShadow ? "mobileHeader" : undefined}
          >
            {!hideCloseButton && (
              <Box p={2}>
                <DrawerCloseButton position="static" />
              </Box>
            )}
            <FooterComponent p={2} flexGrow={1} textAlign="center">
              <Text textStyle="h5">{title}</Text>
            </FooterComponent>
            {!isLoading && secondaryButton && (
              <Box p={2}>{secondaryButton(size)}</Box>
            )}
          </Flex>
        )}
        {size === "half" && (
          <>
            {!hideCloseButton && <DrawerCloseButton />}
            <HeaderComponent
              boxShadow={!noHeaderShadow ? "mobileHeader" : undefined}
            >
              <Text textStyle="h5">{title}</Text>
            </HeaderComponent>
          </>
        )}
        <BodyComponent {...drawerBodyProps}>
          {isLoading ? (
            <Flex justifyContent={"center"}>
              <CircularProgress
                isIndeterminate
                color={"brand.main"}
                thickness={2}
                mt={0}
              />
            </Flex>
          ) : (
            children
          )}
        </BodyComponent>
        <FooterComponent boxShadow="mobileHeader" {...drawerFooterProps}>
          {footer}
          {size === "half" && (
            <Flex justifyContent="space-between" w="100%">
              {!isLoading && secondaryButton && (
                <Box>{secondaryButton && secondaryButton(size)}</Box>
              )}
              {!isLoading && primaryButton && (
                <Box>{primaryButton && primaryButton(size)}</Box>
              )}
              {isLoading && (primaryButton || secondaryButton) && (
                <Box>{/* Cancel button */}</Box>
              )}
            </Flex>
          )}
          {!isLoading && size === "full" && (
            <Box w="100%">{primaryButton && primaryButton(size)}</Box>
          )}

          {isLoading && size === "full" && (
            <Box w="100%">{/* Cancel button */}</Box>
          )}
        </FooterComponent>
      </ContentComponent>
    </BaseComponent>
  );
};

export default VeriDrawer;
