/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuOfferingsIcon = createIcon({
  displayName: "MenuOfferingsIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <path
        d="M13.243 22.414L18.414 17.243C18.5998 17.0573 18.7472 16.8368 18.8478 16.5941C18.9483 16.3513 19.0001 16.0912 19.0001 15.8285C19.0001 15.5658 18.9483 15.3057 18.8478 15.0629C18.7472 14.8202 18.5998 14.5997 18.414 14.414L9 5H1V13L10.414 22.414C10.5997 22.5998 10.8202 22.7472 11.0629 22.8478C11.3057 22.9483 11.5658 23.0001 11.8285 23.0001C12.0912 23.0001 12.3514 22.9483 12.5941 22.8478C12.8368 22.7472 13.0573 22.5998 13.243 22.414Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M21 11L11 1H3"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z"
        fill="currentColor"
      />
    </g>
  ),
});
export default MenuOfferingsIcon;
