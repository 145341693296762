import { useToastError } from "./useToastError";
import { useToastSuccess } from "./useToastSuccess";
import { ServerError } from "../../../defines";
import { getServerError } from "../../../utils";

export const useVeriToast = () => {
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const toastServerError = (error: ServerError, defaultMessage?: string) =>
    toastError(getServerError(error, defaultMessage).message);

  return {
    toastError,
    toastSuccess,
    toastServerError,
  };
};
