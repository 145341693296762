import React from "react";
import { Chip } from "../Chip";
import { darken, hexToRgba, lighten } from "../../../utils";
import { OutlinedColoredTagProps } from "./ColoredTag.types";

export const OutlinedColoredTag: React.FC<OutlinedColoredTagProps> = ({
  tagColor,
  ...rest
}) => {
  return (
    <Chip
      isFilled
      isSmall
      backgroundColor={
        tagColor ? hexToRgba(lighten(tagColor, 10), 0.1) : undefined
      }
      border={"1px solid"}
      borderColor={tagColor ? darken(tagColor, 30) : undefined}
      color={tagColor ? darken(tagColor, 30) : undefined}
      {...rest}
    />
  );
};
