/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const OpportunityJsonApiModel = {
  id: "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "manager-id": "",
  issuer: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  "issuer-id": "",
  "description-raw": "",
  "description-html": "",
  "description-raw2": "",
  "description-html2": "",
  name: "",
  slug: "",
  "check-instructions": "",
  "check-instructions-url": "",
  "meta-description": "",
  "visible-on-manager-profile": "",
  "wire-instructions": "",
  "wire-instructions-url": "",
  status: "",
  "allows-check": "",
  "allows-wire": "",
  "inception-date": "",
  "marketplace-video-url": "",
  "invest-url": "",
  "signer-name": "",
  "signer-email": "",
  "views-count": "",
  "windup-date": "",
  opportunitypoints: {
    jsonApi: "hasMany",
    type: "opportunity-points",
  },
  mediaitems: {
    jsonApi: "hasMany",
    type: "mediaitems",
  },
  monitoringreports: {
    jsonApi: "hasMany",
    type: "monitoringreports",
  },
  documents: {
    jsonApi: "hasMany",
    type: "documents",
  },
  attributeitems: {
    jsonApi: "hasMany",
    type: "attributeitems",
  },
  images: {
    jsonApi: "hasMany",
    type: "images",
  },
  offerings: {
    jsonApi: "hasMany",
    type: "offerings",
  },
  "sort-order": "",
  "billing-entity": {
    jsonApi: "hasOne",
    type: "billing-entities",
  },
  "billing-entity-id": "",
  "created-by-fund-builder": "",
  "executive-summary-html": "",
  "executive-summary-raw": "",
  "business-strategy-html": "",
  "business-strategy-raw": "",
  "investment-thesis": "",
  "bill-for-advisory": "",
  "fund-builder": {
    jsonApi: "hasOne",
    type: "fund-builders",
  },
  "asset-purchases": {
    jsonApi: "hasMany",
    type: "asset-purchases",
  },
  "banner-layout": "",
  "banner-images": {
    jsonApi: "hasMany",
    type: "banner-images",
  },
  "created-at": "",
  "updated-at": "",
};
