import parsePhoneNumber from "libphonenumber-js";
import { PhoneNumberProps } from "../components/common/PhoneNumber";
import { countries } from "../defines";

export function countryIsoToName(iso: string, defaultValue = ""): string {
  return (
    countries.find((country) => country.iso2 === iso)?.name || defaultValue
  );
}

export function countryDialToIso(
  dial: string | number,
  defaultValue = "",
): string {
  return (
    countries.find((country) => String(country.dialCode) === String(dial))
      ?.iso2 || defaultValue
  );
}

export function formatPhoneNumber(
  phone?: PhoneNumberProps["phone"],
  countryCode?: PhoneNumberProps["phoneCode"],
) {
  if (!phone) return;
  if (!countryCode) return phone;

  const internationalPhone = `${parsePhoneNumber(
    `+${countryCode}${phone}`,
  )?.formatInternational()}`;

  let formattedPhone = internationalPhone;

  // formatInternational not always works as we want,
  // so extra formatting is needed
  // so +1 213 373 4253 would become +1 (213) 373 4253
  if ((formattedPhone.match(/ /g) || []).length >= 2) {
    formattedPhone = formattedPhone.replace(
      /^(.*?) (.*?) (.*?)$/,
      "$1 ($2) $3",
    );
  }

  return formattedPhone;
}
