import { Dict } from "../defines";
import { isClient } from "./";

type serverCookieCallback = (
  name: string,
  value: string | null,
  opts: any,
) => void;

export function writeCookie(
  name: string,
  value: string | null | undefined,
  days: number,
): void {
  let date, expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function deleteCookie(name: string): void {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
}

function readCookie(name: string): string | false | null {
  let i, c;
  const ca = document.cookie.split(";");
  const nameEQ = name + "=";
  for (i = 0; i < ca.length; i++) {
    c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  return false;
}

class OwnCookies {
  serverCookiesSet: serverCookieCallback | null = null;
  serverCookies: Dict = {};

  setServerCookies(cookies: Dict, setCookies: serverCookieCallback) {
    this.serverCookies = cookies;
    this.serverCookiesSet = setCookies;
  }

  set(name: string, value: string | null, days = 200): void {
    if (isClient()) {
      return writeCookie(name, value ? encodeURIComponent(value) : null, days);
    }
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    this.serverCookiesSet?.(name, value, { expires: date });
  }

  get(name: string): string | false | null {
    if (isClient()) {
      return readCookie(name);
    }

    return this.serverCookies && this.serverCookies[name];
  }
  delete(name: string) {
    if (isClient()) {
      return deleteCookie(name);
    }

    return (
      this.serverCookiesSet && this.serverCookiesSet(name, null, { maxAge: 0 })
    );
  }
}

export default OwnCookies;
