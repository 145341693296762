/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallInstanceRuleJsonApiModel = {
  id: "",
  "waterfall-instance-id": "",
  "waterfall-definition-rule-class-id": "",
  "amount-in": "",
  "amount-to-target": "",
  "amount-paid": "",
  "amount-remaining": "",
  "distribution-holdings": {
    jsonApi: "hasMany",
    type: "waterfall-rule-distribution-holdings",
  },
};
