import { wizardPrefix } from "./investor-portal";

const addTrackRecord = `${wizardPrefix}add-track-record`;

export const addTrackRecordRoutes = {
  START: addTrackRecord,
  UPLOAD_TRACK_RECORD: addTrackRecord + "/upload",
  ADD_INVESTMENT: addTrackRecord + "/add-investment",
  ADD_INVESTMENT_NO_BACK: addTrackRecord + "/new-investment",
  REVIEW_RECORDS: addTrackRecord + "/review",
};
