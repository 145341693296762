/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImageJsonApiModel = {
  id: "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  asset: {
    jsonApi: "hasOne",
    type: "assets",
  },
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "fund-builder": {
    jsonApi: "hasOne",
    type: "fund-builders",
  },
  description: "",
  filename: "",
  filepath: "",
  filesize: "",
  "mime-type": "",
  url: "",
  "sort-order": "",
};
