import { Icon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import InputMask from "react-input-mask";
import { EyeIcon, EyeOffIcon } from "../../../icons";
import { VeriFormLabel } from "../VeriFormLabel/VeriFormLabel";
import { VeriInputProps } from "./VeriInput.types";
import { buildVeriInputStyles } from "./buildVeriInputStyles";

const VeriInput = React.forwardRef<HTMLInputElement, VeriInputProps>(
  (
    {
      name,
      type = "text",
      label,
      value,
      onChange,
      error,
      helperText,
      optional,
      propsInput = {},
      mask,
      inputLeft,
      inputRight,
      inputLeftProps,
      inputRightProps,
      inputGroupProps,
      defaultValue,
      greyedOutOnReadOnly,
      success,
      maxLength,
      placeholder,
      autoComplete,
      labelExtra,
      isRequired,
      dataTestId,
      ...rest
    },
    ref,
  ) => {
    const [internalType, setInternalType] = React.useState(type);
    const r = mask ? { inputRef: ref } : { ref: ref };

    return (
      <FormControl isInvalid={!!error} {...rest}>
        {label && (
          <VeriFormLabel isOptional={optional} labelExtra={labelExtra}>
            {label}
            {isRequired && <span style={{ color: "red" }}> * </span>}
          </VeriFormLabel>
        )}
        <InputGroup {...inputGroupProps}>
          {inputLeft && (
            <InputLeftElement
              zIndex={"inputRightAndLeftElements"}
              pt="8px"
              {...inputLeftProps}
            >
              {inputLeft}
            </InputLeftElement>
          )}
          <Input
            data-testid={dataTestId}
            {...buildVeriInputStyles({ greyedOutOnReadOnly })}
            name={name}
            {...r}
            as={mask ? InputMask : undefined}
            mask={mask}
            value={value}
            onChange={onChange}
            type={internalType}
            defaultValue={defaultValue}
            placeholder={placeholder}
            pr={type === "password" ? "4.5rem" : undefined}
            maxLength={mask ? undefined : maxLength}
            autoComplete={autoComplete}
            {...propsInput}
          />
          {inputRight && (
            <InputRightElement
              zIndex={"inputRightAndLeftElements"}
              pt="8px"
              {...inputRightProps}
            >
              {inputRight}
            </InputRightElement>
          )}
        </InputGroup>
        {type === "password" && (
          <InputRightElement
            zIndex={"inputRightAndLeftElements"}
            width="4.5rem"
            mt="44px"
          >
            <Icon
              cursor="pointer"
              color="icons.grey"
              as={internalType === "text" ? EyeOffIcon : EyeIcon}
              onClick={() =>
                setInternalType(internalType === "text" ? "password" : "text")
              }
              boxSize="24px"
              data-testid="input-password-eye"
            />
          </InputRightElement>
        )}
        {helperText && !error && !success && (
          <FormHelperText
            id="email-helper-text"
            data-testid="input-helper-text"
          >
            {helperText}
          </FormHelperText>
        )}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        {success && (
          <Box mt={2} textStyle={"bodySmall"} color={"semantic.success"}>
            {success}
          </Box>
        )}
      </FormControl>
    );
  },
);

VeriInput.displayName = "VeriInput";

export default VeriInput;
