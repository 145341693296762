import { Tooltip } from "@chakra-ui/react";
import React from "react";
import inlineActionTestIds from "./inlineAction.testIds";

interface InlineActionItemWrapperProps {
  tooltipText?: string;
}
export const InlineActionItemWrapper: React.FC<
  InlineActionItemWrapperProps
> = ({ children, tooltipText }) => {
  if (!tooltipText) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      hasArrow
      label={tooltipText}
      shouldWrapChildren
      mt="3"
      bg={"bg.white"}
      color="text.black"
      placement="top-end"
      data-testid={inlineActionTestIds.InlineActionItemWrapper}
    >
      {children}
    </Tooltip>
  );
};
