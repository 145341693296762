import Actions from "./Actions";
import Avatar from "./Avatar";
import ColoredTag from "./ColoredTag";
import Date from "./Date";
import DateUTC from "./DateUTC";
import ImageTitle from "./ImageTitle";
import LinkList from "./LinkList";
import Money from "./Money";
import Number from "./Number";
import Percentage from "./Percentage";
import TagList from "./TagList";

export const Renderers = {
  Money,
  TagList,
  Number,
  Percentage,
  Avatar,
  LinkList,
  Date,
  DateUTC,
  ImageTitle,
  ColoredTag,
  Actions,
};
