/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const DollarSignIcon = createIcon({
  displayName: "DollarSignIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 1V23" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M16.5 4C15.0847 3.36075 13.5528 3.02033 12 3C9.47 3 6.5 4 6.5 7C6.5 13 17.5 10 17.5 16C17.5 19 14.984 19.977 12 20C9.95782 20.0087 7.92899 19.6705 6 19" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default DollarSignIcon;