import React from "react";
import { UseModelOptions } from "../useModel";

interface UseModelProps {
  defaults: UseModelOptions<any>;
}

const UseModelContext = React.createContext<UseModelProps>({} as UseModelProps);

const UseModelProvider: React.FC<UseModelProps> = ({ defaults, children }) => {
  return (
    <UseModelContext.Provider value={{ defaults }}>
      {children}
    </UseModelContext.Provider>
  );
};

export { UseModelProvider, UseModelContext };
