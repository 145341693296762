/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const SecurityJsonApiModel = {
  id: "",
  "security-type": "",
  name: "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  "rate-days-per-year": "",
  rate: "",
  "is-unitized": "",
  "is-primary": "",
  "is-tokenized": "",
  "show-on-card": "",
  "unit-price": "",
  "total-units-issued": "",
  "total-market-value": "",
  "total-net-cap-cont": "",
  "total-capital": "",
  "minimum-amount": "",
  "hold-period": "",
  "target-equity-multiple": "",
  "target-irr": "",
  "year1-cash": "",
  "stabilized-cash-on-cash": "",
  "management-fee": "",
  "preferred-return": "",
  "irr-net-of-fees": "",
  "equity-multiple-net": "",
  "minimum-subordinated-equity": "",
  "target-income-return": "",
  "target-total-return": "",
  "current-preferred-return": "",
  "total-preferred-return": "",
  "profit-split": "",
  "profit-share": "",
  "max-leverage": "",
  "co-investment-requirement": "",
  "distribution-frequency": "",
  "short-name": "",
  "unit-price-date": "",
  "inception-date": "",
  "is-temporary-security": "",
};
