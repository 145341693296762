export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 255;

export const PASSWORD_ALLOWED_SPECIAL_CHARACTERS = [
  "#",
  "$",
  "^",
  "+",
  "=",
  "_",
  ".",
  "\\-",
  "!",
  "*",
  "(",
  ")",
  "@",
  "%",
  "&",
  " ",
];
