/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateEntityKeyPersonnelOutputJsonApiModel = {
  id: "",
  "entity-key-personnel": {
    jsonApi: "hasOne",
    type: "entity-key-personnels",
  },
};
