import React from "react";
import { Controller, FieldPath } from "../../../react-hook-form";
import { VeriInput } from "../VeriInput";
import { SsnTinInputProps } from "./SsnTinInput.types";
import { tinRegex } from "../../../utils";

export const HookFormSsnTinInput = <T, K extends FieldPath<T>>({
  inputProps,
  form,
  name,
  isRequired = true,
  isHidden = true,
  isDisabled,
  rules,
  ...rest
}: SsnTinInputProps<T, K>) => {
  return (
    <Controller
      rules={{
        required: isRequired && "You must enter a valid SSN",
        pattern: {
          value: tinRegex,
          message: "You must enter a valid SSN",
        },
        ...rules,
      }}
      render={({
        fieldState: { error },
        field: { onChange, value, name, ref },
      }) => (
        <VeriInput
          optional={!isRequired}
          isRequired={isRequired}
          onChange={onChange}
          value={(value || "") as string}
          label="SSN/ITIN"
          ref={ref}
          mask="999-99-9999"
          name={name}
          error={error?.message}
          {...(isHidden && { type: "password" })}
          {...{ isDisabled, ...inputProps }}
        />
      )}
      control={form.control}
      name={name}
      {...rest}
    />
  );
};
