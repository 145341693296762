/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ReverseTransferInputJsonApiModel = {
  id: "",
  transfer: {
    jsonApi: "hasOne",
    type: "transfers",
  },
  "transfer-id": "",
  "do-not-check-activity": "",
  "do-not-update-sums": "",
  "do-not-check-remaining-balance": "",
  "remove-holding-from-allocations": "",
  "remove-holding-from-distributions": "",
  "remove-holding-redemptions": "",
};
