import { Flex, Image } from "@chakra-ui/react";
import {
  buildRouteUrl,
  Button,
  investorPortalWhiteLabelRoutes,
  managerDashboardRoutes,
} from "@verivest/shared-components";
import React from "react";
import { useHistory } from "react-router";
import useGetDynamicConfigManager from "@verivest/shared-components/src/dynamic-config/hooks/useGetDynamicConfigManager";
import useGetDynamicConfigDashboardManager from "@verivest/shared-components/src/dynamic-config/hooks/useGetDynamicConfigDashboardManager";

export const ManagerIcon = ({
  size,
  onClick,
}: {
  size?: string | number;
  onClick?: () => void;
}) => {
  const history = useHistory();

  const wlManager = useGetDynamicConfigManager();
  const dashboardManager = useGetDynamicConfigDashboardManager();

  const manager = wlManager || dashboardManager;

  return (
    <Button
      onClick={
        onClick ||
        (() => {
          history.push(
            buildRouteUrl(
              dashboardManager
                ? managerDashboardRoutes.HOME
                : investorPortalWhiteLabelRoutes.overview,
            ),
          );
        })
      }
      empty
    >
      <Flex maxHeight={size || "55px"} borderRadius={4}>
        <Image
          h={size || "55px"}
          src={manager?.printLogoUrl || manager?.logoUrl || ""}
          alt={`${manager?.name}'s logo`}
        />
      </Flex>
    </Button>
  );
};
