import {
  NotificationContentInvestorDocumentVoidedModel,
  NotificationContentManagerAccreditationFailedModel,
  NotificationModel,
} from "../../api/models";
import { ModelId } from "../../defines";

export enum PossibleNotificationTypes {
  ManagerAccreditationFailed = "manager-accreditation-failed",
  InvestorDocumentVoided = "investor-document-voided",
  ImporterBrownfieldCompleted = "importer-brownfield-completed",
}
export interface NotificationImporterBrownfieldCompletedModel {
  importerId?: number | null;
  opportunityName?: string | null;
  offeringName?: string | null;
}

export type PossibleNotificationContents =
  | NotificationContentManagerAccreditationFailedModel
  | NotificationContentInvestorDocumentVoidedModel
  | NotificationImporterBrownfieldCompletedModel;
export interface NotificationType extends Omit<NotificationModel, "content"> {
  content: PossibleNotificationContents & {
    id: ModelId | null | undefined;
  };
}

// we use this instead of GetNotificationsOutputModel to avoid type conflict when using useSWR
export interface NotificationsResponse {
  id?: string | number | null;
  notifications?: NotificationType[] | null | undefined;
  unviewedCount?: number | null;
}
