/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const FundBuilderFormJsonApiModel = {
  id: "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  asset: {
    jsonApi: "hasOne",
    type: "assets",
  },
  "asset-purchase": {
    jsonApi: "hasOne",
    type: "asset-purchases",
  },
  "asset-key-tenants": {
    jsonApi: "hasMany",
    type: "asset-key-tenants",
  },
  "asset-purchase-debts": {
    jsonApi: "hasMany",
    type: "asset-purchase-debts",
  },
  "asset-purchase-costs": {
    jsonApi: "hasMany",
    type: "asset-purchase-costs",
  },
  "owner-entity": {
    jsonApi: "hasOne",
    type: "entities",
  },
  "investor-entity": {
    jsonApi: "hasOne",
    type: "entities",
  },
  "manager-entity": {
    jsonApi: "hasOne",
    type: "entities",
  },
  "manager-signer-entity-name": "",
  "new-manager-entity-managed-by": "",
  "manager-individual": {
    jsonApi: "hasOne",
    type: "individual-signers",
  },
  fees: {
    jsonApi: "hasMany",
    type: "fee-instances",
  },
  "owner-entity-optional-addresses": {
    jsonApi: "hasMany",
    type: "entity-addresses",
  },
  "investor-entity-optional-addresses": {
    jsonApi: "hasMany",
    type: "entity-addresses",
  },
  "manager-entity-optional-addresses": {
    jsonApi: "hasMany",
    type: "entity-addresses",
  },
  "entity-key-personnels": {
    jsonApi: "hasMany",
    type: "entity-key-personnels",
  },
  "opportunity-affiliates": {
    jsonApi: "hasMany",
    type: "opportunity-affiliates",
  },
  "blue-sky-filings": {
    jsonApi: "hasMany",
    type: "blue-sky-filings",
  },
  "opportunity-timeline-items": {
    jsonApi: "hasMany",
    type: "opportunity-timeline-items",
  },
  "bank-accounts": {
    jsonApi: "hasMany",
    type: "bank-accounts",
  },
  "waterfall-params": {
    jsonApi: "hasOne",
    type: "watefall-definition-template-params",
  },
};
