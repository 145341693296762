/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ExchangeJsonApiModel = {
  id: "",
  status: "",
  from_holding: {
    jsonApi: "hasOne",
    type: "holdings",
  },
  to_holding: {
    jsonApi: "hasOne",
    type: "holdings",
  },
  to_security: {
    jsonApi: "hasOne",
    type: "securities",
  },
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  amount: "",
  "interest-rate": "",
  "effective-date": "",
  "maturity-date": "",
  "exchange-percent": "",
};
