/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateManagerIndividualInputJsonApiModel = {
  id: "",
  "manager-individual": {
    jsonApi: "hasOne",
    type: "individual-signers",
  },
};
