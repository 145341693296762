import React from "react";
import { BulkActionButtonProps } from "./BulkActionButton.types";
import { Button } from "../../../Button";
import { BulkActionWithSubs } from "../BulkActionWithSubs";

const BulkActionButton: React.FC<BulkActionButtonProps> = ({
  actions,
  actionName,
  bulkSelection,
  tableObject,
  records,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const action = actions[actionName];
  const browseForFile = action.browseForFile;

  if (action.subs) {
    return (
      <BulkActionWithSubs
        actions={actions}
        actionName={actionName}
        bulkSelection={bulkSelection}
        tableObject={tableObject}
      />
    );
  }

  const _doAction = async (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (browseForFile && action.fileCallback && e?.target?.files) {
      await action.fileCallback(
        e.target.files,
        Object.keys(bulkSelection),
        tableObject,
      );
    } else if (action.callback) {
      await action.callback(Object.keys(bulkSelection), records, tableObject);
    }
  };

  const doAction = async () => {
    setIsLoading(true);

    if (action.confirm) {
      tableObject.setConfirmData({
        isOpen: true,
        title: action.confirm.title ? action.confirm.title(null) : "",
        message: action.confirm.message ? action.confirm.message(null) : "",
        onClose: () => {
          tableObject.setConfirmData(null);
          setIsLoading(false);
        },
        onConfirm: async () => {
          await _doAction();
          tableObject.setConfirmData(null);
          setIsLoading(false);
        },
        cancelLabel: action.confirm.cancelLabel
          ? action.confirm.cancelLabel(null)
          : "Cancel",
        confirmLabel: action.confirm.confirmLabel
          ? action.confirm.confirmLabel(null)
          : "Confirm",
        confirmProps: action.confirm.confirmProps
          ? action.confirm.confirmProps(null)
          : {},
        cancelProps: action.confirm.cancelProps
          ? action.confirm.cancelProps(null)
          : {},
        record: null,
        inlineBanner: false,
      });

      return;
    }

    try {
      await _doAction();
    } catch (e) {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <Button
      mr={2}
      onClick={!browseForFile ? doAction : undefined}
      isLoading={isLoading}
      position={browseForFile ? "relative" : undefined}
      outline
    >
      {browseForFile && (
        <input
          type={"file"}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            cursor: "pointer",
            opacity: 0,
          }}
          onChange={doAction}
        />
      )}
      <strong>{actions[actionName].label}</strong>
    </Button>
  );
};

export default BulkActionButton;
