/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MoreVerticalIcon = createIcon({
  displayName: "MoreVerticalIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12Z" fill="currentColor" /><path d="M10 3C10 4.10457 10.8954 5 12 5C13.1046 5 14 4.10457 14 3C14 1.89543 13.1046 1 12 1C10.8954 1 10 1.89543 10 3Z" fill="currentColor" /><path d="M10 21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21C14 19.8954 13.1046 19 12 19C10.8954 19 10 19.8954 10 21Z" fill="currentColor" /></g>)
});
export default MoreVerticalIcon;