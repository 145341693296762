/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const LocationJsonApiModel = {
  id: "",
  "place-id": "",
  label: "",
  latitude: "",
  longitude: "",
  "formatted-address": "",
  "location-type": "",
  "establishment-name": "",
  "point-of-interest": "",
  "street-number": "",
  route: "",
  premise: "",
  subpremise: "",
  neighborhood: "",
  "colloquial-area": "",
  "natural-feature": "",
  locality: "",
  sublocality: "",
  "sublocality-level1": "",
  "sublocality-level2": "",
  "sublocality-level3": "",
  "administrative-area-level1": "",
  "administrative-area-level2": "",
  "administrative-area-level3": "",
  "postal-code": "",
  "postal-code-suffix": "",
  country: "",
  "created-at": "",
  "updated-at": "",
};
