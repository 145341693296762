import { Avatar, Box } from "@chakra-ui/react";
import React, { forwardRef } from "react";

const ProfileButtons = forwardRef(
  ({ profile, isImpersonating, ...rest }, ref) => {
    return (
      <Box ref={ref} {...rest} cursor="pointer" position="relative" mr="16px">
        <Avatar
          width="32px"
          height="32px"
          name={profile.name}
          src={profile.picture}
          fontFamily="satoshi"
          css={{ WebkitFontSmoothing: "auto" }}
          pt={profile.picture ? undefined : "2px"}
        />
        {isImpersonating && (
          <Box
            top="-2px"
            right="-6px"
            bg="red"
            w="8px"
            h="8px"
            borderRadius="4px"
            position="absolute"
          >
            &nbsp;
          </Box>
        )}
      </Box>
    );
  },
);

ProfileButtons.displayName = "ProfileButtons";

export default ProfileButtons;
