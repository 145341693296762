/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum WaterfallRuleTemplateType {
  Catchup = "Catchup",
  EquityMultiple = "Equity Multiple",
  Fee = "Fee",
  Irr = "IRR",
  PreferredReturn = "Preferred Return",
  ProRata = "Pro Rata",
  ReturnOfCapital = "Return of Capital",
  Split = "Split",
  SplitWithHurdles = "Split With Hurdles",
}
