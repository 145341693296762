/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateFeeInstanceInputJsonApiModel = {
  id: "",
  "fee-instance": {
    jsonApi: "hasOne",
    type: "fee-instances",
  },
};
