/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const BookIcon = createIcon({
  displayName: "BookIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 4V21" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /><path d="M17.5 2C14.5 2 12 3.3 12 5C12 3.3 9.5 2 6.5 2C3.5 2 1 3.3 1 5V22C1 20.3 3.5 19 6.5 19C9.5 19 12 20.3 12 22C12 20.3 14.5 19 17.5 19C20.5 19 23 20.3 23 22V5C23 3.3 20.5 2 17.5 2Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default BookIcon;