import { Box } from "@chakra-ui/react";
import React from "react";
import { LabelWithTooltip } from "../../form-controls/LabelWithTooltip";
import { LabelAndValueProps } from "./LabelAndValue.types";

const LabelAndValue: React.FC<LabelAndValueProps> = ({
  label,
  value,
  emptyValue = "-",
  labelProps,
  valueProps,
  children,
  labelAlt,
  helpTooltip,
  ...rest
}) => {
  return (
    <Box mb={6} {...rest}>
      <Box
        textStyle="labelSmall"
        color={labelAlt ? "text.grey" : undefined}
        mb={2}
        {...labelProps}
      >
        {!helpTooltip && label}
        {!!helpTooltip && (
          <LabelWithTooltip
            tooltip={helpTooltip}
            label={label}
          ></LabelWithTooltip>
        )}
      </Box>
      <Box textStyle="bodyRegular" {...valueProps}>
        {children ? (
          children
        ) : (
          <>
            {value !== undefined && value !== null && value !== ""
              ? value
              : emptyValue}
          </>
        )}
      </Box>
    </Box>
  );
};

export default LabelAndValue;
