import { useCallback, useRef } from "react";
import { useBooleanState } from "../../../hooks/states";
import { useResizeObserver } from "./useResizeObserver";

export const useTabsOverflow = <T extends HTMLElement>() => {
  const elementHasOverflow = useBooleanState(false);
  const lastOverflowThreshold = useRef(0);

  const checkElementDimentions = useCallback((element: T) => {
    if (element.scrollWidth > element.clientWidth) {
      lastOverflowThreshold.current = element.clientWidth;
      return elementHasOverflow.true();
    }

    if (element.clientWidth > lastOverflowThreshold.current) {
      elementHasOverflow.false();
    }
  }, []);

  const ref = useResizeObserver(checkElementDimentions);

  return {
    ref,
    hasOverflow: elementHasOverflow.state,
  };
};
