/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateFundBuilderOrganizationalChartOutputJsonApiModel = {
  id: "",
  "organizational-chart": {
    jsonApi: "hasOne",
    type: "charts",
  },
  graph: "",
};
