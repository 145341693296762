import React from "react";
import { Flex } from "@chakra-ui/react";
import { DownloadIcon, EditIcon } from "../../../../icons";
import { Button } from "../../Button";
import { VeriTableRendererProps } from "../VeriTable.types";

const Actions: React.FC<VeriTableRendererProps> = ({ options, record }) => {
  const onEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!options.onEditClick) return;

    e.stopPropagation();
    e.preventDefault();
    options.onEditClick(record);
  };
  const onDownloadClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!options.onDownloadClick) return;

    e.stopPropagation();
    e.preventDefault();
    options.onDownloadClick(record);
  };
  return (
    <Flex justifyContent="flex-end">
      {options.onEditClick && (
        <Button onClick={onEditClick} mr={4} empty>
          <EditIcon color={"icons.grey"} boxSize={"20px"} />
        </Button>
      )}
      {options.onDownloadClick && (
        <Button onClick={onDownloadClick} mr={4} empty>
          <DownloadIcon color={"icons.grey"} boxSize={"20px"} />
        </Button>
      )}
    </Flex>
  );
};

export default Actions;
