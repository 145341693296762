import {
  Box,
  Icon,
  Modal as Mo,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { CloseIcon } from "./Icons";
import VeriHeading from "../VeriHeading";

const Modal = ({
  children,
  isOpen,
  onClose,
  size = "xl",
  contentProps,
  innerContentProps,
  closeButtonProps,
  title,
  scrollBehavior,
  modalProps,
}) => {
  return (
    <Mo
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      scrollBehavior={scrollBehavior}
      {...modalProps}
    >
      <ModalOverlay />
      <ModalContent
        position={{ base: "absolute", md: "unset" }}
        top={{ base: "0px", md: "unset" }}
        bottom={{ base: "0px", md: "unset" }}
        right={{ base: "0px", md: "unset" }}
        left={{ base: "0px", md: "unset" }}
        my={{ base: "0px", md: "3.75rem" }}
        mx={{ base: "0px", md: "auto" }}
        overflowY={{ base: "auto", md: "unset" }}
        {...contentProps}
      >
        {title && (
          <ModalHeader
            position="relative"
            textAlign="center"
            py={7}
            borderBottomWidth={1}
            borderBottomStyle={"solid"}
            borderBottomColor={"divider.light"}
          >
            <Icon
              cursor="pointer"
              onClick={onClose}
              as={CloseIcon}
              color="black"
              boxSize="24px"
              position="absolute"
              m="32px"
              left="0"
              top="0"
              {...closeButtonProps}
            />
            <VeriHeading textStyle="h5">{title}</VeriHeading>
          </ModalHeader>
        )}

        {scrollBehavior !== "inside" && (
          <Box p="32px" position="relative" {...innerContentProps}>
            {!title && (
              <Icon
                cursor="pointer"
                onClick={onClose}
                as={CloseIcon}
                color="black"
                boxSize="24px"
                {...closeButtonProps}
              />
            )}

            {children}
          </Box>
        )}

        {scrollBehavior === "inside" && children}
      </ModalContent>
    </Mo>
  );
};

export default Modal;
