import React from "react";
import { Box, ScaleFade, Text } from "@chakra-ui/react";
import { usePopper } from "react-popper";

const VeriTooltip = ({
  trigger,
  label,
  placement,
  children,
  alwaysOpen,
  my,
  offsetTop,
  openByDefault,
  withHoverOverlay = true,
  ...rest
}) => {
  const [popperElement, setPopperElement] = React.useState(null);
  const [arrowElement, setArrowElement] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(
    alwaysOpen || openByDefault || false,
  );

  const onTriggerEnter = React.useCallback(() => {
    setIsOpen(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onTriggerLeave = React.useCallback(() => {
    if (alwaysOpen) return;
    setIsOpen(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (trigger) {
      trigger.addEventListener("mouseenter", onTriggerEnter);
      // trigger.addEventListener("mouseleave", onTriggerLeave);
    }

    return () => {
      if (trigger) {
        trigger.removeEventListener("mouseenter", onTriggerEnter);
        // trigger.removeEventListener("mouseleave", onTriggerLeave);
      }
    };
  }, [trigger, onTriggerEnter, onTriggerLeave]); // eslint-disable-line react-hooks/exhaustive-deps

  const popperModifiers = [
    { name: "arrow", options: { element: arrowElement } },
  ];

  if (alwaysOpen) {
    popperModifiers.push({
      name: "flip",
      enabled: false,
    });
  }

  if (offsetTop || my) {
    popperModifiers.push({
      name: "offset",
      enabled: true,
      options: {
        offset: [0, offsetTop || parseFloat(String(my).replace("px", ""))],
      },
    });
  }

  const { styles, attributes } = usePopper(trigger, popperElement, {
    placement: placement || "bottom",
    modifiers: popperModifiers,
  });

  const isTop =
    attributes.popper &&
    attributes.popper["data-popper-placement"] &&
    attributes.popper["data-popper-placement"] === "top";

  const arrow = (
    <Box
      ref={setArrowElement}
      style={{
        ...styles.arrow,
        transform: (styles.arrow?.transform || "") + " rotate(45deg)",
      }}
      boxShadow="0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)!important"
      backgroundColor="bg.white"
      width={"12px"}
      height={"12px"}
      borderColor="bg.greyLighter"
      borderWidth={1}
      borderStyle={"solid"}
      zIndex={3}
      marginTop={"-6px"}
    />
  );

  const hoverOverlay = withHoverOverlay && (
    <Box
      style={styles.arrow}
      backgroundColor="transparent"
      width={"40px"}
      height={"40px"}
      zIndex={4}
      marginTop={isTop ? "0" : "-40px"}
      marginLeft={"-16px"}
      cursor={"pointer"}
    />
  );

  return (
    <Box
      style={styles.popper}
      {...attributes.popper}
      ref={setPopperElement}
      zIndex={2}
      onMouseLeave={onTriggerLeave}
      {...rest}
    >
      <ScaleFade initialScale={0.9} in={isOpen} unmountOnExit>
        <Box>
          <Box
            marginTop={"16px"}
            marginBottom={"16px"}
            borderColor="bg.greyLighter"
            borderWidth={1}
            borderStyle={"solid"}
            borderRadius={4}
            boxShadow="0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)!important"
            width="unset"
            backgroundColor="white"
            zIndex="3"
            position="relative"
          >
            {!isTop && arrow}
            {!isTop && hoverOverlay}
            <Box
              px="12px"
              py="8px"
              background="bg.white"
              position="relative"
              zIndex={4}
              borderRadius={4}
            >
              {label && (
                <Text
                  p="8px"
                  textStyle="bodySmall"
                  fontFamily="Nunito Sans"
                  whiteSpace={"nowrap"}
                >
                  {label}
                </Text>
              )}
              {children}
            </Box>
            {isTop && arrow}
            {isTop && hoverOverlay}
          </Box>
        </Box>
      </ScaleFade>
    </Box>
  );
};

export default VeriTooltip;
