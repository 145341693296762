import { Avatar, Box, Flex, Icon, Image } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { UserIcon } from "./Icons";

const DefaultAvatarImage = (props) => {
  return (
    <Box
      boxShadow="inset 0px 0px 8px rgba(0, 0, 0, 0.06)"
      h="72px"
      w="72px"
      borderRadius="50%"
      bg="bg.lighter"
      {...props}
    >
      <Flex justifyContent="center" height="100%" alignItems="center">
        <Icon as={UserIcon} boxSize="24px" color="icons.grey"></Icon>
      </Flex>
    </Box>
  );
};

const AvatarImage = ({
  image,
  badge,
  badgeColor = "semantic.success",
  isUser,
  ...rest
}) => {
  let _avatarImage;

  if ((!image || image === "") && !isUser) {
    _avatarImage = (
      <DefaultAvatarImage data-testid="default-avatar" {...rest} />
    );
  } else if ((!image || image === "") && isUser) {
    _avatarImage = (
      <Avatar
        data-testid="user-avatar"
        borderRadius="50%"
        size="32px"
        src={false}
        {...rest}
      />
    );
  } else {
    _avatarImage = (
      <Image
        objectFit="cover"
        fallbackSrc={image}
        width="72px"
        height="72px"
        borderRadius="50%"
        src={image}
        data-testid="image-avatar"
        {...rest}
      />
    );
  }

  if (badge) {
    return (
      <Box position={"relative"} data-testid="avatar-wrapper">
        {_avatarImage}
        <Flex
          backgroundColor={badgeColor}
          borderRadius={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          position={"absolute"}
          right={0}
          top={0}
          mr={3}
          w={6}
          h={6}
          data-testid="avatar-badge"
        >
          {badge}
        </Flex>
      </Box>
    );
  }

  return _avatarImage;
};

AvatarImage.propTypes = {
  /**
   * Image url of the avatar
   */
  image: PropTypes.string,
};

export default AvatarImage;
