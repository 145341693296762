import useUtmParams from "./useUtmParams";
import useWebsocket from "./useWebsocket";

const useMetrics = () => {
  const utmParams = useUtmParams();
  const sendMessage = useWebsocket({});

  return (params) => {
    const p = utmParams ? utmParams.get() : {};
    sendMessage(
      JSON.stringify({ method: "event", params: { ...p, ...params } }),
    );
  };
};

export default useMetrics;
