/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum TransferBasisEnumType {
  CapitalAccountBalance = "Capital Account Balance",
  MarketValue = "Market Value",
  NetCapitalContributions = "Net Capital Contributions",
  Units = "Units",
}
