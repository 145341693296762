/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UsersAccountJsonApiModel = {
  id: "",
  "account-id": "",
  "user-id": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "permission-level": "",
};
