/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const OpportunityMetricJsonApiModel = {
  id: "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  "opportunity-id": "",
  "period-end": "",
  "period-frequency": "",
  "total-market-value": "",
  "total-capital-account-balance": "",
  "total-investor-count": "",
  "period-contributions": "",
  "period-redemptions": "",
  "previous-aum": "",
  "estimated-aum": "",
  "billing-schedule-factor": "",
  "calculated-billable-amount": "",
  "actual-billable-amount": "",
  "created-at": "",
  "updated-at": "",
};
