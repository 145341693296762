/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const AtSignIcon = createIcon({
  displayName: "AtSignIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M16 21.168C14.0265 22.0293 11.8277 22.2306 9.73059 21.7419C7.6335 21.2533 5.75016 20.1008 4.36066 18.4558C2.97117 16.8108 2.14975 14.7613 2.01858 12.612C1.88741 10.4628 2.45349 8.32855 3.63263 6.52682C4.81177 4.72509 6.54098 3.3521 8.56308 2.61203C10.5852 1.87197 12.7922 1.80437 14.8558 2.41929C16.9194 3.03421 18.7294 4.2988 20.0166 6.02499C21.3038 7.75117 21.9994 9.84673 22 12V13C22 13.7957 21.6839 14.5587 21.1213 15.1213C20.5587 15.6839 19.7957 16 19 16C18.2044 16 17.4413 15.6839 16.8787 15.1213C16.3161 14.5587 16 13.7957 16 13V8.00001" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default AtSignIcon;