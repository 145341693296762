import React from "react";
import { Box } from "@chakra-ui/react";
import { usePopper } from "react-popper";

const VeriDropMenu = ({ children, trigger, placement = "bottom-end" }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [popperTrigger, setTrigger] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);

  const { styles, attributes } = usePopper(popperTrigger, popperElement, {
    modifiers: [],
    placement,
  });

  const _children = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setIsOpen });
    }
    return child;
  });

  return (
    <>
      <Box ref={setTrigger} onClick={() => setIsOpen(true)}>
        {typeof trigger === "function" ? trigger({ isOpen }) : trigger}
      </Box>
      {isOpen && (
        <>
          <Box
            zIndex={"dropdown"}
            pos={"fixed"}
            left={0}
            right={0}
            top={0}
            bottom={0}
            onClick={() => setIsOpen(false)}
          />
          <Box
            {...styles.popper}
            {...attributes.popper}
            ref={setPopperElement}
            zIndex={"dropdown"}
            borderRadius={4}
          >
            {_children}
          </Box>
        </>
      )}
    </>
  );
};

export default VeriDropMenu;
