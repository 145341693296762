import { Box, Flex } from "@chakra-ui/react";
import { useLayout } from "@verivest/shared-components/src/hooks/ui/useLayout";
import React from "react";
import useAuth from "../../../hooks/useAuth";
import NotificationBell from "../../general/NotificationBell";
import ProfileButton from "./ProfileButton";

const ProfileItems = ({ manager, show, isMarketing }) => {
  const { layout } = useLayout();
  const { auth } = useAuth();

  const logout = () => auth.logout();
  const profile = layout.profile;

  const isManager = manager && manager.id && auth.isManager(manager.id);
  const isMallcop = auth?.isMallcop() || false;

  return (
    <Flex alignItems={"center"} justifyContent={"center"} ml={"2.5rem"}>
      <Box
        mr={5}
        display={show ? "none" : "block"}
        alignSelf="center"
        whiteSpace="nowrap"
      >
        <NotificationBell />
      </Box>
      <Box display={show ? "none" : "block"} whiteSpace="nowrap">
        <ProfileButton
          manager={manager}
          auth={auth}
          profile={profile}
          logout={logout}
          isManager={isManager}
          isMallcop={isMallcop}
          isMarketing={isMarketing}
        />
      </Box>
    </Flex>
  );
};

export default ProfileItems;
