/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BillingProductJsonApiModel = {
  id: "",
  name: "",
  description: "",
  category: "",
  "active-begin-date": "",
  "active-end-date": "",
  "is-active": "",
  "stripe-product-id": "",
  "billing-target-type": "",
  "active-price": {
    jsonApi: "hasOne",
    type: "billing-prices",
  },
  "product-family": "",
  "product-type": "",
  "revenue-type": "",
  "created-at": "",
  "updated-at": "",
};
