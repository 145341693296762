import { useLocation } from "react-router-dom";
import { useDidUpdate } from "../../lifecycle/useDidUpdate";

interface FallbackErrorBoundaryProps {
  onClearError: () => void;
}

export const ClearErrorOnLocationChange = ({
  onClearError,
}: FallbackErrorBoundaryProps) => {
  const location = useLocation();

  useDidUpdate(() => {
    onClearError();
  }, [location]);

  return null;
};
