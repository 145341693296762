import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Img,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { VeriSpinner } from "@verivest/shared-components/src/components/common/loaders/VeriSpinner";
import React from "react";
import ImageEditor from "./ImageEditor";

const ImageField = ({
  label,
  error,
  optional,
  inputLeft,
  inputRight,
  inputLeftProps,
  inputGroupProps,
  helperText,
  success,
  inputRightProps,
  emptyIcon,
  editorTitle,
  editorDescription,
  onChange,
  value,
  w = 72,
  h = 72,
  isUploading = false,
  ...rest
}) => {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [currentPreview, setCurrentPreview] = React.useState("");
  const [showFallback, setShowFallback] = React.useState(false);

  React.useEffect(() => {
    setCurrentPreview(value);
  }, [value, setCurrentPreview]);

  React.useEffect(() => {
    setShowFallback(false);
  }, [currentPreview, setShowFallback]);

  return (
    <FormControl isInvalid={!!error} {...rest}>
      {label && (
        <Flex justifyContent="space-between">
          <FormLabel lineHeight="24px" mb="8px" fontWeight="500">
            {label}
          </FormLabel>
          {optional && (
            <Text color="text.grey" fontWeight="400" fontSize="16px">
              Optional
            </Text>
          )}
        </Flex>
      )}
      <InputGroup {...inputGroupProps}>
        {inputLeft && (
          <InputLeftElement pt="8px" {...inputLeftProps}>
            {inputLeft}
          </InputLeftElement>
        )}

        <Box
          minW={w + "px"}
          maxW={w + "px"}
          h={h + "px"}
          w={"100%"}
          backgroundColor={"bg.lighter"}
          borderRadius={4}
          overflow={"hidden"}
          p={0}
          cursor={"pointer"}
          {...rest}
        >
          <Flex
            justifyContent="center"
            w={"100%"}
            h={"100%"}
            alignItems={"center"}
            position={"relative"}
          >
            {isUploading ? (
              <VeriSpinner mt={0} />
            ) : !showFallback && currentPreview ? (
              <Img
                src={currentPreview}
                h={"100%"}
                objectFit="cover"
                onError={() => setShowFallback(true)}
              />
            ) : (
              <Icon as={emptyIcon} boxSize={8} />
            )}
            <input
              type={"file"}
              accept="image/x-png,image/gif,image/jpeg"
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                opacity: 0,
                cursor: "pointer",
              }}
              onChange={(e) => {
                if (e.target.files[0]) {
                  setSelectedFile(e.target.files[0]);
                }
              }}
            />
          </Flex>
        </Box>

        {selectedFile && (
          <ImageEditor
            title={editorTitle}
            description={editorDescription}
            onClose={() => setSelectedFile(null)}
            image={URL.createObjectURL(selectedFile)}
            selectedFile={selectedFile}
            onFinish={async (api, formData, blob, selectedFile) => {
              setCurrentPreview(URL.createObjectURL(blob));
              onChange && onChange(blob, selectedFile);
            }}
            isPlacement
            isOpen
          />
        )}
        {inputRight && (
          <InputRightElement pt="8px" {...inputRightProps}>
            {inputRight}
          </InputRightElement>
        )}
      </InputGroup>
      {helperText && !error && !success && (
        <FormHelperText id="email-helper-text">{helperText}</FormHelperText>
      )}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {success && (
        <Box mt={2} textStyle={"bodySmall"} color={"semantic.success"}>
          {success}
        </Box>
      )}
    </FormControl>
  );
};

export default ImageField;
