/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const DocusignSettingJsonApiModel = {
  id: "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  email: "",
  "user-id": "",
  "created-at": "",
  "updated-at": "",
};
