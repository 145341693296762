/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetOfferingExemptionsInputJsonApiModel = {
  id: "",
  filters: {
    jsonApi: "hasOne",
    type: "offering-exemption-filters",
  },
};
