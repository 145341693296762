import { isValidPhoneNumber } from "react-phone-number-input";
import {
  PASSWORD_ALLOWED_SPECIAL_CHARACTERS,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from "../defines/password";
export { isValidPhoneNumber };

export function isValidEmail(value: string): boolean {
  const re = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
  return re.test(value);
}

// Check a password between 8 to 250 characters which contains at least one numeric digit or special character
export function isValidPassword(value: string) {
  const re = new RegExp(
    `^(?=.*[${PASSWORD_ALLOWED_SPECIAL_CHARACTERS.join(
      "",
    )}0-9])[a-zA-Z0-9${PASSWORD_ALLOWED_SPECIAL_CHARACTERS.join("")}]{` +
      PASSWORD_MIN_LENGTH +
      "," +
      PASSWORD_MAX_LENGTH +
      "}$",
  );
  return re.test(value);
}

/*
  (?:              # Group but don't capture:
    Post(?:al)?\   #   Match "Post " or "Postal ".
    (?:Office\ )?  #   Optionally match "Office ".
  |                #  Or:
    P[.\s?]?        #   Match "P" and an optional period or space character.
    O\.?\          #   Match "O", an optional period, and a space character.
  )?               # Make the group optional.
  Box              # Match "Box".
  \b               # Assert position at a word boundary.
*/
export function isPOBox(value: string): boolean {
  const re = /(?:Post(?:al)? (?:Office )?|P[. ]?O\.? )?Box\b/i;
  return re.test(value);
}

export const isStringBetween = (a: number, b: number, text: string) => {
  return text.length >= a && text.length <= b;
};

export const stringContainsSpecialOrNumber = (text: string) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]/;

  return regex.test(text);
};

export const tinRegex = /^(\d{3}-?\d{2}-?\d{4})/gm;
export const einRegex = /^(\d{2}-?\d{7})/gm;

export function assertUnreachable(x: never, errorMessage?: string): never {
  const defaultMessage = `Reached unpredicted code for value: "${x}"`;

  throw new Error(errorMessage || defaultMessage);
}
