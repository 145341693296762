/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const FundBuilderJsonApiModel = {
  id: "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "user-id": "",
  reviewer: {
    jsonApi: "hasOne",
    type: "users",
  },
  "reviewer-id": "",
  "reviewed-at": "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "manager-id": "",
  status: "",
  step: "",
  images: {
    jsonApi: "hasMany",
    type: "images",
  },
  "bill-for-advisory": "",
  "payment-submitted-at": "",
  "acting-manager-also-equity-investor": "",
  "acting-manager-percentage-invested": "",
  "has-manager-entity": "",
  "wants-to-create-manager-entity": "",
  "has-prior-experience-raising": "",
  "raised-money": "",
  "raised-capital-with": "",
  "how-did-you-market": "",
  "how-did-you-market-details": "",
  "has-used-fund-administrator": "",
  "fund-administrator-used": "",
  "money-to-raise": "",
  "is-acting-manager-and-authorized-signer": "",
  "assets-types-to-be-acquired": "",
  "single-or-multiple-assets": "",
  "has-property-under-contract": "",
  "close-equity-raise-round-date": "",
  "continue-process-without-raise-round-date": "",
  "applied-promo-code": "",
  "agreement-ds-envelope-id": "",
  "investor-agreement-document-url": "",
  "owner-agreement-document-url": "",
  "manager-agreement-document-url": "",
  "ppm-agreement-document-url": "",
  "opportunity-id": "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  "onboarding-entity-formation-completed": "",
  "onboarding-ein-registration-completed": "",
  "onboarding-state-business-registrations-completed": "",
  "onboarding-reg-d-filing-completed": "",
  "onboarding-blue-sky-completed": "",
  "onboarding-oa-signed-completed": "",
  "onboarding-bank-accounts-completed": "",
  form: {
    jsonApi: "hasOne",
    type: "fund-builder-forms",
  },
  "form-object": "",
  "created-at": "",
  "updated-at": "",
};
