import React from "react";
import { TagsDrawerFilterType } from "../../TagsDrawer.types";
import { VeriInput } from "../../../../form-controls";
import { FilterValueType } from "../../../VeriDrawer/VeriDrawer.types";

const TextFilter: React.FC<TagsDrawerFilterType> = ({ filterRef, ...rest }) => {
  React.useEffect(() => {
    const refObject = {
      valueType: FilterValueType.STRING,
    };

    if (typeof filterRef === "function") {
      filterRef(refObject);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <VeriInput
      onChange={(e) => rest.onChange(e.target.value)}
      value={rest.value || ""}
    />
  );
};

export default TextFilter;
