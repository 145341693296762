/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UnitPriceUpdateJsonApiModel = {
  id: "",
  "created-at": "",
  security: {
    jsonApi: "hasOne",
    type: "securities",
  },
  "unit-price": "",
  "unit-price-date": "",
};
