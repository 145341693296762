import { Box } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { guestTextMenuItems } from "./logged-menu-items/logged-menu-items";
import MenuItem from "./MenuItem";

const MarketingMenu = ({ setOpenSubMenu, openSubMenu, isMobile }) => {
  const history = useHistory();
  const currentPath = history.location.pathname;

  return (
    <Box
      id="header-menu"
      display="flex"
      width={"100%"}
      alignItems={{ base: "unset", md: "center" }}
      flexGrow={1}
      flexDirection={{ base: "column", md: "row" }}
      justifyContent={"flex-start"}
    >
      {guestTextMenuItems.map((item) => (
        <MenuItem
          key={item.name}
          to={item.to}
          href={item.href}
          currentPath={currentPath}
        >
          <span>{item.children}</span>
        </MenuItem>
      ))}
    </Box>
  );
};

export default MarketingMenu;
