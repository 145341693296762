/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const EntityKeyPersonnelJsonApiModel = {
  id: "",
  entity: {
    jsonApi: "hasOne",
    type: "entities",
  },
  "entity-id": "",
  "manager-individual": {
    jsonApi: "hasOne",
    type: "individual-signers",
  },
  "manager-individual-id": "",
  "user-id": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  "first-name": "",
  "last-name": "",
  title: "",
  biography: "",
  "is-signer": "",
  email: "",
  phone: "",
  "phone-code": "",
  "created-at": "",
  "updated-at": "",
};
