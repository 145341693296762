import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { usePopper } from "react-popper";
import Chip from "./Chip";
import VeriInput from "./Input";

const TagsInput = ({
  dataTestId,
  value,
  tags,
  allowTagCreation,
  onTagCreation,
  placeholder,
  onChange,
  label,
  helperText,
  error,
  success,
  tagsInInput = false,
  optional,
  onAdd,
  onRemove,
  maxTags,
  containerProps,
  noTagsLeftMessage = "No tags left",
  ...rest
}) => {
  const [rootElementRef, setRootElementRef] = React.useState(null);
  const [availableTags, setAvailableTags] = React.useState(tags);
  const [listElement, setListItem] = React.useState(null);
  const [searchFilter, setSearchFilter] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [triggerElRef, setTriggerElRef] = React.useState(null);
  const [selectedElement, setSelectedElement] = React.useState(-1);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const lowerCaseSearchFilter = searchFilter.toLowerCase();

  const { styles, attributes, update } = usePopper(
    rootElementRef,
    listElement,
    {
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 6],
          },
        },
      ],
    },
  );

  const filteredTags = (availableTags || []).filter(
    (tag) =>
      (lowerCaseSearchFilter === "" ||
        tag.label.toLowerCase().includes(lowerCaseSearchFilter)) &&
      !selectedItems.find((sel) => sel === tag.value),
  );

  const isTagCreation =
    allowTagCreation &&
    filteredTags.length === 0 &&
    availableTags.find((tag) => tag.label === searchFilter) === undefined;

  const _applyChange = (newSelection) => {
    setSelectedItems(newSelection);
    onChange && onChange(newSelection);
  };

  const _selectNext = React.useCallback(() => {
    if (!filteredTags.length || selectedElement + 1 > filteredTags.length - 1)
      setSelectedElement(0);
    else setSelectedElement(selectedElement + 1);
  }, [selectedElement, filteredTags]);

  const _selectPrev = React.useCallback(() => {
    if (!filteredTags.length) return setSelectedElement(0);

    if (selectedElement - 1 < 0) setSelectedElement(filteredTags.length - 1);
    else setSelectedElement(selectedElement - 1);
  }, [selectedElement, filteredTags]);

  const _selectItem = React.useCallback(
    (value) => {
      const newItems = selectedItems.filter((v) => v !== value);

      if (maxTags && selectedItems.length >= maxTags) {
        _applyChange(newItems);
        setSearchFilter("");
        return;
      }

      newItems.push(value);
      _applyChange(newItems);

      onAdd && onAdd(value);
      setSearchFilter("");
    },
    [selectedItems], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _removeItem = React.useCallback(
    (value) => {
      const newItems = selectedItems.filter((v) => v !== value);
      _applyChange(newItems);
      onRemove && onRemove(value);
    },
    [selectedItems], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const _createAndSelectNewTag = React.useCallback(
    async (value) => {
      if (value === "") return;
      if (onTagCreation) {
        const result = await onTagCreation(value);
        if (typeof result === "object" && result.label && result.value) {
          const newTags = [...availableTags];
          newTags.push(result);
          setAvailableTags(newTags);
          _selectItem(result.value);
        }
      }
    },
    [onTagCreation, availableTags, _selectItem], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleUserKeyPress = React.useCallback(
    (event) => {
      const { keyCode } = event;

      if (keyCode === 38) {
        _selectPrev();
        event.preventDefault();
      } else if (keyCode === 40) {
        _selectNext();
        event.preventDefault();
      } else if (keyCode === 13) {
        if (isTagCreation) {
          _createAndSelectNewTag(searchFilter || "");
        } else {
          filteredTags[selectedElement] &&
            _selectItem(filteredTags[selectedElement].value);
        }

        setSearchFilter("");
        if (!tagsInInput) {
          setIsOpen(false);
        } else {
          update && update();
        }
        //if (tagsInInput && triggerElRef) triggerElRef.blur();

        event.preventDefault();
      } else if (keyCode === 27) {
        setIsOpen(false);
        setSearchFilter("");
        if (tagsInInput && triggerElRef) triggerElRef.blur();
        event.stopPropagation();
        event.preventDefault();
      }
    },
    [_selectNext, update], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onOutsideClick = React.useCallback(
    (event) => {
      if (rootElementRef && !rootElementRef.contains(event.target)) {
        setIsOpen(false);
        if (tagsInInput && triggerElRef) triggerElRef.blur();
      }
    },
    [rootElementRef], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onRootElClick = () => {
    if (tagsInInput && triggerElRef) triggerElRef.focus();
  };

  React.useEffect(() => {
    setSelectedElement(0);
  }, [searchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!triggerElRef) return;

    triggerElRef.addEventListener("keydown", handleUserKeyPress);

    return () => {
      triggerElRef.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress, triggerElRef]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    window.document.addEventListener("click", onOutsideClick);

    return () => {
      window.document.removeEventListener("click", onOutsideClick);
    };
  }, [rootElementRef]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setSelectedItems(value && Array.isArray(value) ? value : []);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setAvailableTags(tags || []);
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  const OptionElement = ({ children, value, index }) => (
    <Box
      py={4}
      px={4}
      backgroundColor={selectedElement === index ? "bg.grey" : "bg.white"}
      _hover={{ cursor: "pointer" }}
      onMouseEnter={() => setSelectedElement(index)}
      onClick={() => {
        if (isTagCreation) {
          setSearchFilter("");
          _createAndSelectNewTag(searchFilter || "");
        } else {
          _selectItem(value);
        }
      }}
    >
      {children}
    </Box>
  );

  const chips = (
    <>
      {selectedItems.map((val, selectedIndex) => (
        <Chip
          key={selectedIndex}
          isFilled
          isSmall
          label={
            availableTags.find((tag) => String(tag.value) === String(val))
              ?.label || ""
          }
          mr={2}
          mt={tagsInInput ? 3 : 2}
          onClickClose={(e) => {
            if (tagsInInput) {
              e.stopPropagation();
              e.preventDefault();
            }
            _removeItem(val);
          }}
        />
      ))}
    </>
  );

  const onInputFocus = () => {
    if (maxTags && selectedItems.length >= maxTags) {
      return;
    }
    setIsOpen(true);
  };

  const onInputChange = (e) => setSearchFilter(e.target.value);

  return (
    <FormControl isInvalid={!!error} {...rest}>
      <Box ref={setRootElementRef} {...containerProps}>
        {isOpen && (
          <>
            <Box
              pos={"fixed"}
              left={0}
              right={0}
              top={0}
              bottom={0}
              bg={"transparent"}
              zIndex={"dropdown"}
              onClick={() => setIsOpen(false)}
            />
            <Box
              ref={setListItem}
              style={styles.popper}
              {...attributes.popper}
              background={"bg.white"}
              left={0}
              right={0}
              w={rootElementRef.offsetWidth}
              boxShadow="0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)"
              borderWidth={1}
              borderStyle={"solid"}
              borderColor={"border.grey"}
              zIndex={"dropdown"}
              maxH={"224px"}
              overflow={"auto"}
            >
              {filteredTags.length > 0 ? (
                filteredTags.map((tag, tagIndex) => (
                  <OptionElement
                    value={tag.value}
                    index={tagIndex}
                    key={`${tag.value}-${tagIndex}`}
                  >
                    {tag.label}
                  </OptionElement>
                ))
              ) : isTagCreation && searchFilter ? (
                <OptionElement index={0}>
                  {`Create "${searchFilter}"`}
                </OptionElement>
              ) : (
                <Box textStyle={"labelSmall"} py={4} px={4}>
                  {noTagsLeftMessage}
                </Box>
              )}
            </Box>
          </>
        )}

        {label && (
          <Flex justifyContent="space-between">
            <FormLabel lineHeight="24px" mb="8px" fontWeight="500">
              {label}
            </FormLabel>
            {optional && (
              <Text color="text.grey" fontWeight="400" fontSize="16px">
                Optional
              </Text>
            )}
          </Flex>
        )}
        <Flex
          data-testid={dataTestId}
          {...(tagsInInput && {
            border: "1px solid",
            borderColor: "border.input",
            borderRadius: 4,
          })}
          {...(tagsInInput && {
            px: 4,
            cursor: "text",
          })}
          flexWrap={"wrap"}
          onClick={onRootElClick}
        >
          {/*{tagsInInput && selectedItems.length > 0 && (
            <Box px={4} py={3}>
              {chips}
            </Box>
          )}*/}
          {tagsInInput && selectedItems.length > 0 && <>{chips}</>}
          {!tagsInInput && (
            <VeriInput
              ref={setTriggerElRef}
              propsInput={{
                placeholder: placeholder || "Search or create new tags...",
                autoComplete: "off",
                disabled: maxTags && selectedItems.length >= maxTags,
                ...(tagsInInput && { border: 0 }),
                ...(isOpen && { zIndex: "dropdown", position: "relative" }),
              }}
              onFocus={onInputFocus}
              onChange={onInputChange}
              value={searchFilter}
            />
          )}
          {tagsInInput && (
            <input
              autoComplete={"off"}
              ref={setTriggerElRef}
              placeholder={placeholder || "Search or create new tags..."}
              disabled={maxTags && selectedItems.length >= maxTags}
              style={{
                ...(tagsInInput && { border: 0 }),
                ...(isOpen && { zIndex: "dropdown", position: "relative" }),
                padding: "12px 0",
                margin: 0,
                width: "auto",
                fontSize: 16,
                fontFamily: "Nunito Sans, sans-serif",
                outline: "none",
                display: "inline-block",
                maxWidth: "100%",
              }}
              onChange={onInputChange}
              onFocus={onInputFocus}
              value={searchFilter}
              size={Math.max(
                (searchFilter || "").length || 1,
                placeholder?.length || 1,
              )}
            />
          )}
        </Flex>

        {helperText && !error && !success && (
          <FormHelperText id="email-helper-text">{helperText}</FormHelperText>
        )}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        {success && (
          <Box mt={2} textStyle={"bodySmall"} color={"semantic.success"}>
            {success}
          </Box>
        )}

        <Box>{!tagsInInput && chips}</Box>
      </Box>
    </FormControl>
  );
};

export default TagsInput;
