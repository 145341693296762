/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BackgroundCheckJsonApiModel = {
  id: "",
  "checked-on": "",
  principal: {
    jsonApi: "hasOne",
    type: "principals",
  },
  backgroundcheckitems: {
    jsonApi: "hasMany",
    type: "background-check-items",
  },
};
