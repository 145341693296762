import { Icon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import {
  AlertOctagonIcon,
  AlertTriangleIcon,
  CloseIcon,
  InfoIcon,
} from "../../../icons";
import OwnCookies from "../../../utils/own-cookies";
import { IconButton } from "../IconButton";
import { MessageBannerProps } from "./MessageBanner.types";

const typeStyles = {
  info: {
    icon: InfoIcon,
    iconSide: {
      backgroundColor: "bg.greyLight",
    },
    messageSide: {
      backgroundColor: "bg.greyLighter",
    },
  },
  warning: {
    icon: AlertTriangleIcon,
    iconSide: {
      backgroundColor: "semantic.warning",
      color: "text.white",
    },
    messageSide: {
      backgroundColor: "bg.goldLightest",
    },
  },
  error: {
    icon: AlertTriangleIcon,
    iconSide: {
      backgroundColor: "semantic.error",
      color: "text.white",
    },
    messageSide: {
      backgroundColor: "semantic.lightestError",
    },
  },
  octagonCenteredError: {
    icon: AlertOctagonIcon,
    iconSide: {
      backgroundColor: "semantic.error",
      color: "text.white",
      alignItems: "top",
      pt: 6,
    },
    messageSide: {
      backgroundColor: "semantic.lightestError",
      alignContent: "center",
    },
  },
};

const MessageBanner: React.FC<MessageBannerProps> = ({
  type = "info",
  title,
  message,
  children,
  dismissible,
  id,
  ...rest
}) => {
  let dismiss = null;

  const [isDismissed, setIsDismissed] = React.useState(false);
  let cookieDismissed = false;

  if (dismissible && id) {
    const cookies = new OwnCookies();
    const dismissedBanners = JSON.parse(
      cookies.get("dismissed_banners") || "[]",
    );

    dismiss = () => {
      if (!dismissedBanners.includes(id)) {
        dismissedBanners.push(id);
      }

      cookies.set("dismissed_banners", JSON.stringify(dismissedBanners));

      setIsDismissed(true);
    };

    cookieDismissed = dismissedBanners.includes(id);
  }

  const styles = typeStyles[type] || {};

  if (isDismissed || cookieDismissed) return <></>;

  return (
    <Flex
      w={"100%"}
      borderRadius={4}
      overflow={"hidden"}
      position={"relative"}
      mb={8}
      {...rest}
    >
      {dismissible && id && (
        <Box position={"absolute"} right={0} top={0}>
          <IconButton onClick={dismiss || undefined} isGhost>
            <CloseIcon boxSize={"24px"} />
          </IconButton>
        </Box>
      )}
      <Flex
        w={"80px"}
        alignItems={"center"}
        justifyContent={"center"}
        {...styles.iconSide}
      >
        <Icon as={styles.icon} boxSize={"24px"} />
      </Flex>
      <Box py={6} px={5} w={"100%"} {...styles.messageSide}>
        <Text textStyle={"bodyRegularBold"}>{title}</Text>
        <Text textStyle={"bodyRegular"}>
          {message}
          {children}
        </Text>
      </Box>
    </Flex>
  );
};

export default MessageBanner;
