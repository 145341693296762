/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum NewEntityTypeType {
  SingleMemberLlc = "Single Member LLC",
  "LimitedLiabilityCompany (llc)" = "Limited Liability Company (LLC)",
  Partnership = "Partnership",
  Corporation = "Corporation",
  Other = "Other",
}
