import { AccountTypeType } from "@verivest/shared-components/src/api/enums";
import { isCustodiedAccountType } from "@verivest/shared-components/src/utils/accounts";
import { generatePath } from "react-router";

export const accountIdParameter = "identityId";
const accountTypeParameter = "accountType";
export const custodiedTypeParameter = "custodiedType";

const accountWizardBasePath = "/account";

const individualPath = "individual";
const jointPath = "joint";
const entityPath = "entity";
const trustPath = "trust";
const custodiedPath = "custodied";

const accountPathByType = `${accountWizardBasePath}/:${accountTypeParameter}`;
const individualBasePath = `${accountWizardBasePath}/${individualPath}/:id?`;
const jointBasePath = `${accountWizardBasePath}/${jointPath}`;
const entityBasePath = `${accountWizardBasePath}/${entityPath}`;
const trustBasePath = `${accountWizardBasePath}/${trustPath}`;
const custodiedBasePath = `${accountWizardBasePath}/${custodiedPath}/:${custodiedTypeParameter}`;

export const accountWizardRoutes = {
  selectAccount: accountWizardBasePath,
  individual: individualBasePath,
  joint: {
    base: jointBasePath,
    primary: `${jointBasePath}/primary`,
    secondary: `${jointBasePath}/secondary`,
  },
  entity: {
    base: entityBasePath,
    signer: `${entityBasePath}/signer`,
  },
  trust: {
    base: trustBasePath,
    signer: `${trustBasePath}/signer`,
  },
  custodied: {
    base: custodiedBasePath,
    selectAccount: custodiedBasePath,
    individual: `${custodiedBasePath}${individualBasePath}`,
    joint: `${custodiedBasePath}${jointBasePath}`,
    entity: `${custodiedBasePath}${entityBasePath}`,
    trust: `${custodiedBasePath}${trustBasePath}`,
    finish: `${custodiedBasePath}/finish/:${accountIdParameter}`,
  },
};

const accountTypeToPath = (accountType: AccountTypeType) => {
  switch (accountType) {
    case AccountTypeType.Individual:
      return individualPath;
    case AccountTypeType.Joint:
      return jointPath;
    case AccountTypeType.Entity:
      return entityPath;
    case AccountTypeType.Trust:
      return trustPath;
    case AccountTypeType.Retirement:
    case AccountTypeType.Brokerage:
    case AccountTypeType.CustodiedAccount:
      return custodiedPath;
    default:
      return;
  }
};

export const getAccountPathForType = (accountType: AccountTypeType) => {
  const path = generatePath(accountPathByType, {
    [accountTypeParameter]: accountTypeToPath(accountType),
  });

  if (isCustodiedAccountType(accountType)) {
    return `${path}/${accountType.toLowerCase()}`;
  }

  return path;
};

export const getCustodiedBasePath = (custodiedType: AccountTypeType) => {
  return generatePath(accountWizardRoutes.custodied.base, {
    [custodiedTypeParameter]: custodiedType,
  });
};

export const getCustodiedFinishPath = (
  accountID: string,
  custodiedType: AccountTypeType,
) => {
  return generatePath(accountWizardRoutes.custodied.finish, {
    [accountIdParameter]: accountID,
    [custodiedTypeParameter]: custodiedType,
  });
};

export const getCustodiedCreateAccountPath = (
  accountType: AccountTypeType,
  custodiedType: "retirement" | "brokerage",
) => {
  return generatePath(`${custodiedBasePath}${accountPathByType}`, {
    [accountTypeParameter]: accountType,
    [custodiedTypeParameter]: custodiedType,
  });
};
