import { extendTheme } from "@chakra-ui/react";
import MainTheme from "./MainTheme";

const VerivestTheme = extendTheme({
  ...MainTheme({
    colors: {
      main: "#4840BB",
      mainLightest: "#F9DCEB",
      mainDark: "#230B59",
    },
  }),
  initialColorMode: "light",
  useSystemColorMode: false,
});

export const AdminTheme = extendTheme({
  ...MainTheme({
    colors: {
      main: "#4840BB",
      mainLightest: "#F9DCEB",
      mainDark: "#230B59",
    },
  }),
  initialColorMode: "light",
  useSystemColorMode: false,
});

export default VerivestTheme;
