/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ManagerIndividualJsonApiModel = {
  id: "",
  "primary-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "primary-address-id": "",
  "mailing-address": {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "mailing-address-id": "",
  "investor-relations-phone": "",
  "investor-relations-phone-code": "",
  "investor-relations-email": "",
};
