import { InputProps } from "@chakra-ui/react";

interface BuildVeriInputStylesParams {
  greyedOutOnReadOnly?: boolean;
}

export const buildVeriInputStyles = ({
  greyedOutOnReadOnly,
}: BuildVeriInputStylesParams = {}): InputProps => ({
  _focus: {
    borderColor: "brand.main",
    color: "black",
  },
  _readOnly: greyedOutOnReadOnly
    ? {
        backgroundColor: "bg.grey",
      }
    : undefined,

  bg: "white",
  focusBorderColor: "brand.main",
  height: "none",
  borderRadius: 4,
  borderColor: "border.input",
  fontFamily: "Nunito Sans",
  pt: "12px",
  pb: "12px",
  fontSize: "md",
  color: "black",
});
