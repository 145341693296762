import { Box, Flex } from "@chakra-ui/react";
import { Button } from "@verivest/shared-components";
import { useLayout } from "@verivest/shared-components/src/hooks/ui/useLayout";
import React from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { getLoginURL } from "../../utils/utils";
import {
  EmptyHeader,
  HeaderLogo,
  LoggedMenuItems,
  MarketingMenu,
  MobileHeaderActions,
  ProfileItems,
} from "./header-components";
import MobileMenuDrawer from "./header-components/MobileMenuDrawer";
import { InvestorPortalHeader } from "../investor-portal/headers/InvestorPortalHeader";

const Header = ({ empty, isMarketing, ...rest }) => {
  const { layout } = useLayout();
  const isMobile = layout.breakpoint === "base";
  const show = false;
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const headerRef = React.useRef();

  const profile = layout.profile;
  const [openSubMenu, setOpenSubMenu] = React.useState(-1);

  useOnClickOutside(headerRef.current, setOpenSubMenu.bind(null, -1));

  if (empty) {
    return <EmptyHeader {...rest} />;
  }

  if (profile) {
    return <InvestorPortalHeader isMarketing />;
  }

  return (
    <>
      <Flex
        position={"relative"}
        w={"100%"}
        fontFamily={"Nunito Sans"}
        ref={headerRef}
        justifyContent={"center"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          bg={"transparent"}
          p={"1.875rem"}
          w={"100%"}
          h={"87px"}
          maxW={"1440px"}
          data-veri-header="1"
        >
          <Flex
            alignItems="center"
            justifyContent={isMobile ? "space-between" : undefined}
            height="26px"
            w={"100%"}
          >
            <HeaderLogo />
            {isMobile && (
              <Box>
                <MobileHeaderActions show={show} setShow={setShowMobileMenu} />
              </Box>
            )}
            <Flex
              lineHeight={"1.25rem"}
              fontSize={"1rem"}
              alignItems={"center"}
              h={"100%"}
              w={"100%"}
              pt={"2px"}
              display={isMobile ? "none" : "flex"}
            >
              {!profile && (
                <MarketingMenu
                  openSubMenu={openSubMenu}
                  setOpenSubMenu={setOpenSubMenu}
                />
              )}
              {profile && <LoggedMenuItems />}
              <Box
                ml={"2.5rem"}
                mr={0}
                w={".0625rem"}
                h={"1.25rem"}
                d={"inline-block"}
              />
              {!profile && (
                <Flex>
                  <Flex
                    ml={10}
                    alignItems={"center"}
                    cursor={"pointer"}
                    fontFamily={"Nunito Sans"}
                    fontSize={"1em"}
                    lineHeight={"28px"}
                    fontWeight={400}
                    onClick={() => {
                      window.location.href = getLoginURL();
                    }}
                    data-cy="logInLink"
                    whiteSpace={"nowrap"}
                  >
                    <span>Log In</span>
                  </Flex>
                  <Button
                    href="/get-started"
                    textProps={{ whiteSpace: "nowrap" }}
                    ml={"2rem"}
                    fontFamily="satoshi"
                    fontWeight={400}
                    letterSpacing="-0.2px"
                    color="#FFFFFF"
                    isMedium
                  >
                    Get started
                  </Button>
                </Flex>
              )}
              {/* profile */}
              {profile && (
                <ProfileItems isMarketing={isMarketing} show={show} />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {isMobile && (
        <MobileMenuDrawer
          isOpen={showMobileMenu}
          onClose={setShowMobileMenu.bind(null, false)}
        />
      )}
    </>
  );
};

export default Header;
