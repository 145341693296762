import { Text } from "@chakra-ui/react";
import React from "react";
import {
  ManagerModel,
  NotificationContentManagerAccreditationFailedModel,
} from "../../../api/models";
import { managerDashboardRoutes } from "../../../defines";
import { buildRouteUrl, getWhiteLabelBaseUrl } from "../../../utils";
import { NotificationItemGeneral } from "../NotificationItemGeneral";

interface NotificationAccreditationFailedProps {
  content: NotificationContentManagerAccreditationFailedModel;
  manager: ManagerModel | null | undefined;
  createdAt: string;
}
export const NotificationAccreditationFailed = ({
  content,
  manager,
  createdAt,
  ...rest
}: NotificationAccreditationFailedProps) => {
  const isCompleted = content.completedById ? true : false;
  return (
    <NotificationItemGeneral
      image={content.imgUrl || ""}
      title={"Account verification incomplete"}
      description={
        <>
          There were issues flagged with{" "}
          <Text d="inline" textStyle="bodyRegularBold">
            {content.accountName}’s ({content.accountType})
          </Text>{" "}
          accreditation through Parallel Markets.
        </>
      }
      actionTitle={
        isCompleted ? `Resolved by ${content.completedBy}` : "Resolve"
      }
      actionOnClick={async () => {
        const accountToNavigateTo = content.completedById || content.accountId;
        const accountDetailsUrl = manager
          ? getWhiteLabelBaseUrl(manager) +
            buildRouteUrl(managerDashboardRoutes.ACCOUNT_DETAILS, {
              idAccount: String(accountToNavigateTo),
            })
          : window.location.href;
        window.location.href = accountDetailsUrl;
      }}
      accountName={content.accountName || ""}
      createdAt={createdAt}
      actionProps={{
        link: isCompleted,
        p: isCompleted ? 0 : undefined,
      }}
      {...rest}
    />
  );
};
