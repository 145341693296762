import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ActivityItemDateProps } from "./ActivityItemDate.types";
import { dateToMonYYYYUTC, dateToYYYYMMDD } from "../../../../utils/dates";

const ActivityItemDate: React.FC<ActivityItemDateProps> = ({
  children,
  date,
  ...rest
}) => {
  const stringDate = dateToMonYYYYUTC(date, true, true);
  const isToday = dateToYYYYMMDD(new Date()) === stringDate;
  return (
    <Flex mb={6} mt={5} alignItems={"flex-start"} {...rest}>
      <Flex alignItems={"center"} justifyContent={"center"} width={"40px"}>
        {" "}
      </Flex>
      <Box w={"calc(100% - 40px)"} pt={"0"} pl={4}>
        <Text textStyle={"labelSmall"} color={"text.grey"}>
          {isToday ? "Today" : stringDate}
        </Text>
        {children}
      </Box>
    </Flex>
  );
};

export default ActivityItemDate;
