/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ManagerCardJsonApiModel = {
  id: "",
  slug: "",
  name: "",
  "img-url": "",
  "card-img-url": "",
  "capital-managed": "",
  "minimum-investment": "",
  "years-principal-experience": "",
  "years-real-estate-experience": "",
  "has-coming-soon-opportunities": "",
  "has-open-opportunities": "",
  "percent-monitored": "",
  "primary-strategy": "",
  "primary-property-type": "",
  status: "",
  "verified-capital-managed": "",
  "review-count": "",
  "review-avg": "",
};
