/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const LoginJsonApiModel = {
  id: "",
  "login-type": "",
  message: "",
  "user-agent": "",
  "ip-address": "",
  anon_session_id: "",
  "created-at": "",
};
