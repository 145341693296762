/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuInvestmentsIcon = createIcon({
  displayName: "MenuInvestmentsIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <g clipPath="url(#clip0_12051_16995)">
        <path
          d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path
          d="M12 5V19"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
        <path
          d="M15.375 7.21876C13.754 6.32776 8.648 5.66276 8.648 8.73176C8.648 12.4058 15.132 11.1088 15.132 14.3508C15.132 17.5928 10.918 17.3488 8 16.2958"
          stroke="currentColor"
          strokeWidth={2}
          strokeMiterlimit={10}
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_12051_16995">
          <rect width={24} height={24} fill="currentColor" />
        </clipPath>
      </defs>
    </g>
  ),
});
export default MenuInvestmentsIcon;
