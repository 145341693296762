import { Flex } from "@chakra-ui/react";
import {
  buildRouteUrl,
  investorPortalRoutes,
} from "@verivest/shared-components";
import Button from "@verivest/shared-components/src/buttons/Button";
import { NotFoundRoute } from "@verivest/shared-components/src/components/NotFoundRoute";
import React from "react";

function InvestorPortalPageNotFound() {
  return (
    <NotFoundRoute>
      <Flex justifyContent="center">
        <Button
          label={"Go to the homepage"}
          to={buildRouteUrl(investorPortalRoutes.home)}
        />
      </Flex>
    </NotFoundRoute>
  );
}

export default InvestorPortalPageNotFound;
