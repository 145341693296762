/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const ImporterJsonApiModel = {
  id: "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  status: "",
  type: "",
  step: "",
  data: "",
  "created-at": "",
  "updated-at": "",
  "updated-by": {
    jsonApi: "hasOne",
    type: "users",
  },
};
