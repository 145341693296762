/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BannerImageJsonApiModel = {
  id: "",
  "manager-id": "",
  "opportunity-id": "",
  "full-size-image": {
    jsonApi: "hasOne",
    type: "images",
  },
  "full-size-image-id": "",
  "cropped-image": {
    jsonApi: "hasOne",
    type: "images",
  },
  "cropped-image-id": "",
  layout: "",
  "layout-position": "",
  x: "",
  y: "",
  "zoom-level-percent": "",
};
