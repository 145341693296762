import React from "react";
import { errorMessages } from "../../../../defines";
import {
  Controller,
  FieldValues,
  HookFormInputProps,
} from "../../../../react-hook-form";
import { FormControlWrapper } from "../../FormControlWrapper";
import VeriDropZone from "./VeriDropZone";
import { VeriDropZoneProps, VeriUploadFileList } from "./VeriDropZone.types";

interface HookFormVeriDropzoneProps<T extends FieldValues>
  extends HookFormInputProps<T>,
    Pick<VeriDropZoneProps, "multiple" | "accept" | "acceptPreset"> {
  label?: React.ReactNode;
  isRequired?: boolean;
  isOptional?: boolean;
}

export function HookFormVeriDropzone<T extends FieldValues>({
  form,
  name,
  rules,
  isOptional,
  isRequired,
  label,
  multiple,
  accept,
  acceptPreset,
}: HookFormVeriDropzoneProps<T>) {
  return (
    <Controller
      name={name}
      rules={{
        required: isRequired && errorMessages.requiredField,
        ...rules,
      }}
      control={form.control}
      render={({ field, fieldState }) => {
        const files = (field.value || []) as VeriUploadFileList;

        return (
          <FormControlWrapper
            isRequired={isRequired}
            isOptional={isOptional}
            label={label}
            error={fieldState.error?.message}
          >
            <VeriDropZone
              onChange={(files) => {
                field.onChange({ target: { value: files || [] } });
              }}
              onDelete={async (fileToDelete) => {
                const filesToKeep = files.filter((file) => {
                  if (fileToDelete.id) {
                    return fileToDelete.id !== file.id;
                  }

                  if (file.id) {
                    return true;
                  }

                  return fileToDelete.name === file.name;
                });

                field.onChange({ target: { value: filesToKeep } });

                return true;
              }}
              files={files}
              hideDropZoneOnSelect={!multiple}
              multiple={multiple}
              accept={accept}
              acceptPreset={acceptPreset}
            />
          </FormControlWrapper>
        );
      }}
    />
  );
}
