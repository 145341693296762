import React from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { GlobalErrorScreen, GlobalErrorScreenProps } from "./GlobalErrorScreen";

type GlobalErrorBoundaryProps = GlobalErrorScreenProps;

export const GlobalErrorBoundary: React.FC<GlobalErrorBoundaryProps> = ({
  children,
  backToHref,
}) => (
  <ErrorBoundary fallback={<GlobalErrorScreen backToHref={backToHref} />}>
    {children}
  </ErrorBoundary>
);
