import React from "react";
import { Radio, Stack, Text } from "@chakra-ui/react";
import { MFAModalView } from "./MFAModalView";
import { MfaModalStep } from "../context/MfaModalContext/MfaModalContext.types";
import { useMfaModal } from "../context/MfaModalContext/MfaModalContext";

const MfaAuthenticationTypeChooser: React.FC = () => {
  const state = useMfaModal();

  const setAuthByPhone = (authByPhone: boolean) => {
    state.setState({ authByPhone });
  };

  return (
    <MFAModalView
      title="Enable two-factor authentication"
      subTitle="Please choose how you’d like to receive your verification code for each login:"
      backLabel="Cancel"
      onBack={state.closeModal}
      onContinue={() =>
        state.setStep(
          state.authByPhone
            ? MfaModalStep.sendVerificationCodeToPhone
            : MfaModalStep.configureMobileApp,
        )
      }
    >
      <>
        <Stack>
          <Radio
            size="lg"
            colorScheme="brand"
            alignItems="flex-start"
            isChecked={state.authByPhone}
            onChange={() => setAuthByPhone(true)}
          >
            <Text textStyle="bodyRegularBold">Via text message</Text>
            <Text textStyle="bodyRegular" color="text.grey">
              You’ll receive the verification code via text message on your
              mobile device.
            </Text>
          </Radio>
          <Radio
            size="lg"
            colorScheme="brand"
            alignItems="flex-start"
            isChecked={!state.authByPhone}
            onChange={() => setAuthByPhone(false)}
          >
            <Text textStyle="bodyRegularBold">Via authenticator app</Text>
            <Text textStyle="bodyRegular" color="text.grey">
              You’ll use an authenticator app (like Microsoft or Google
              Authenticator) to obtain a verification code.
            </Text>
          </Radio>
        </Stack>
      </>
    </MFAModalView>
  );
};

export default MfaAuthenticationTypeChooser;
