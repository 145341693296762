import { Box, Text } from "@chakra-ui/react";
import React from "react";

function ColoredTag({ type, children, isMedium, height: userHeight, ...rest }) {
  switch (type) {
    case "success":
      rest.bg = "bg.lightestGreen";
      rest.color = "semantic.success";
      break;
    case "error":
      rest.bg = "semantic.lightestError";
      rest.color = "semantic.error";
      break;
    case "warning":
      rest.bg = "semantic.lightestWarning";
      rest.color = "semantic.warning";
      break;
    case "info":
      rest.bg = "bg.greyLight";
      rest.color = "text.greyDarker";
      break;
    case "purple":
      rest.bg = "bg.lightPurple2";
      rest.color = "bg.primaryRegular";
      break;
    default:
      rest.bg = rest.bg || "bg.greyLighter";
      rest.color = rest.color || "text.greyDarker";
      break;
  }

  const height = userHeight || (isMedium ? null : "20px");
  const padding = isMedium ? null : "0px 8px";
  const px = isMedium ? 2 : null;
  const py = isMedium ? 0.5 : null;
  const textStyle = isMedium ? "bodySmall" : "bodyXSBold";
  const fontWeight = isMedium ? "500" : null;

  return (
    <Box
      display="flex"
      alignItems="center"
      as="span"
      borderRadius="40px"
      padding={padding}
      px={px}
      py={py}
      height={height}
      {...rest}
    >
      <Text textStyle={textStyle} fontWeight={fontWeight}>
        {children}
      </Text>
    </Box>
  );
}
export default ColoredTag;
