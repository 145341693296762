/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const AddUserIcon = createIcon({
  displayName: "AddUserIcon",
  viewBox: "0 0 20 20",
  path: (
    <g fill="none">
      <path
        d="M10 10.8333C7.69918 10.8333 5.83334 8.96751 5.83334 6.66668V5.00001C5.83334 2.69918 7.69918 0.833344 10 0.833344C12.3008 0.833344 14.1667 2.69918 14.1667 5.00001V6.66668C14.1667 8.96751 12.3008 10.8333 10 10.8333Z"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M10 14.1667H5.83334C3.07168 14.1667 0.833344 16.405 0.833344 19.1667C0.833344 19.1667 19.1317 19.1667 19.1667 19.1667"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M16.6667 10.8333V15.8333"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M14.1667 13.3333H19.1667"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </g>
  ),
});
export default AddUserIcon;
