import React from "react";
import { VeriTabProps } from "./VeriTab.types";
import { Tab } from "@chakra-ui/react";

const VeriTab: React.FC<VeriTabProps> = ({ children, ...rest }) => {
  return (
    <Tab
      pt="0px"
      _last={{ mr: "0px" }}
      mr="40px"
      px="0px"
      pb="16px"
      color="text.grey"
      fontWeight={"700"}
      _selected={{
        color: "black",
        borderBottomWidth: "2px",
        borderBottomColor: "black",
      }}
      {...rest}
    >
      {children}
    </Tab>
  );
};

export default VeriTab;
