import { Box } from "@chakra-ui/react";
import { countries } from "@verivest/shared-components";
import React from "react";
import Select from "./Select";

const CountryInput = ({ value, onChange, ...rest }, ref) => {
  return (
    <Box width="100%" position="relative">
      <Select
        ref={ref}
        {...rest}
        value={value}
        onChange={onChange}
        selectProps={{
          style: { paddingLeft: value ? "60px" : "" },
          "data-testid": "inner-select",
        }}
      >
        <option value="">Select a country</option>
        {countries.map((country) => (
          <option key={country.iso2} value={country.iso2}>
            {country.name}
          </option>
        ))}
      </Select>
      {value && (
        <Box ml="16px" position="absolute" top="44px">
          <img
            style={{ width: "34.6px", height: "26px" }}
            alt={value}
            role="presentation"
            src={"https://flag.pk/flags/4x3/{xx}.svg"
              .replace("{XX}", value)
              .replace("{xx}", value.toLowerCase())}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.forwardRef(CountryInput);
