/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const UpsertBillingScheduleTierInputJsonApiModel = {
  id: "",
  "billing-schedule": {
    jsonApi: "hasOne",
    type: "billing-schedules",
  },
  "billing-schedule-tier": {
    jsonApi: "hasOne",
    type: "billing-schedule-tiers",
  },
};
