/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const FeeInstanceJsonApiModel = {
  id: "",
  "fee-id": "",
  fee: {
    jsonApi: "hasOne",
    type: "fees",
  },
  "entity-opportunity-id": "",
  "entity-opportunity": {
    jsonApi: "hasOne",
    type: "entity-opportunities",
  },
  value: "",
  "affiliate-name": "",
  basis: "",
  "percent-basis": "",
  "payment-receiver": "",
  slug: "",
  "entity-name": "",
  "created-at": "",
  "updated-at": "",
};
