import React from "react";
import { ExternalLink, ExternalLinkProps } from "./ExternalLink";
import { termsOfServiceUrl } from "./urls";

export const TermsOfServiceLink = (props: ExternalLinkProps) => (
  <ExternalLink
    href={termsOfServiceUrl}
    label={"Terms of Service"}
    {...props}
  />
);
