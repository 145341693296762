/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetNotificationsOutputJsonApiModel = {
  id: "",
  notifications: {
    jsonApi: "hasMany",
    type: "notifications",
  },
  "unviewed-count": "",
};
