import { Image } from "@chakra-ui/react";
import React from "react";

const HeaderLogo = ({ height }) => {
  return (
    <a href="/" style={{ minWidth: "143px" }}>
      <Image
        marginBottom="10px"
        height={height || "30px"}
        alt={"Verivest's logo"}
        fallbackSrc={"/images/verivest-logo.svg"}
        src={"/images/verivest-logo.svg"}
      />
    </a>
  );
};

export default HeaderLogo;
