/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum TaxIdTypeEnumType {
  Ein = "EIN",
  Ssn = "SSN",
  Tin = "TIN",
}
