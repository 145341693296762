/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum DomiciletypeType {
  NonResident = "Non-Resident",
  USCitizen = "U.S. Citizen",
  USResident = "U.S. Resident",
}
