/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ListIcon = createIcon({
  displayName: "ListIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M2 6C3.10457 6 4 5.10457 4 4C4 2.89543 3.10457 2 2 2C0.89543 2 0 2.89543 0 4C0 5.10457 0.89543 6 2 6Z" fill="currentColor" /><path d="M2 14C3.10457 14 4 13.1046 4 12C4 10.8954 3.10457 10 2 10C0.89543 10 0 10.8954 0 12C0 13.1046 0.89543 14 2 14Z" fill="currentColor" /><path d="M2 22C3.10457 22 4 21.1046 4 20C4 18.8954 3.10457 18 2 18C0.89543 18 0 18.8954 0 20C0 21.1046 0.89543 22 2 22Z" fill="currentColor" /><path d="M23 5H8C7.44775 5 7 4.55225 7 4C7 3.44775 7.44775 3 8 3H23C23.5522 3 24 3.44775 24 4C24 4.55225 23.5522 5 23 5Z" fill="currentColor" /><path d="M23 13H8C7.44775 13 7 12.5523 7 12C7 11.4477 7.44775 11 8 11H23C23.5522 11 24 11.4477 24 12C24 12.5523 23.5522 13 23 13Z" fill="currentColor" /><path d="M23 21H8C7.44775 21 7 20.5527 7 20C7 19.4473 7.44775 19 8 19H23C23.5522 19 24 19.4473 24 20C24 20.5527 23.5522 21 23 21Z" fill="currentColor" /></g>)
});
export default ListIcon;