/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateChartInputJsonApiModel = {
  id: "",
  chart: {
    jsonApi: "hasOne",
    type: "charts",
  },
};
