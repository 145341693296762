import { AccountTypeType } from "../../api/enums";
import { buildRouteUrl } from "../../utils/routes";

export const wizardPrefixPure = "/w";
export const wizardPrefix = `${wizardPrefixPure}/`;
export const dashboardWizardPrefix = "/dashboard/w/";

export enum managerWhiteLabelSectionParams {
  section = "section",
  subSection = "subSection",
  subSectionId = "subSectionId",
  extraType = "extraType",
  opportunityId = "opportunityId",
  opportunityName = "opportunityName",
  accountId = "accountId",
}

export enum ManagerWhiteLabelSectionQueries {
  OpportunityId = "opportunity_id",
  OpportunityName = "opportunity_name",
  AccountId = "account_id",
  AccountName = "account_name",
  AccountMNumber = "mnumber",
  AccountType = "account_type",
}

export const managerWhiteLabelSections = {
  overview: "overview",
  opportunities: "opportunities",
  accounts: "accounts",
  investments: "investments",
  documents: "documents",
  transactions: "transactions",
  settings: "settings",
  investmentDetails: "investment-details",
} as const;

export const managerWhiteLabelSubSections = {
  principal: "principal",
  keyPeople: "key-people",
} as const;

export const marketingWebsiteRoutes = {
  homepage: "/",
  pricing: "/pricing",
  forManagers: "/for-managers",
  forInvestors: "/for-investors",
  pitchSlam2023: "/2023-pitch-slam",
  terms: "/terms",
  privacy: "/privacy",
  investorPortal: "/investorportal",
  advisory: "/advisory",
  managerdash: "/managerdash",
  admin: "/admin",
  fundbuilder: "/fundbuilder",
  fundAdmin: "/fund-admin",
  capital: "/capital",
  contact: "/contact",
  productInquiries: "/product-inquiries",
  getStartedNow: "/get-started-now",
  advisoryContact: "/advisory/contact",
  adminContact: "/admin/contact",
  capitalContanct: "/capital/contact",
  about: "/about",
  resources: "/resources",
  product: "/product",
  products: "/products",
  checkout: "/checkout",
  orderConfirmation: "/order-confirmation",
};

export const legacyInvestorPortalRoutes = {
  dashboard: "/dashboard",
  findSponsors: "/sponsor",
  managersOpportunity: "/:managerSlug/o/:id",
  documents: "/documents",
  investments: "/investments",
  investmentDetails: "/investments/:accountId/:offeringId",
};

export const investorPortalRoutes = {
  login: "/login",
  getStarted: "/get-started",
  selectManagerAndCreateFundBuilder: "/select-manager-and-create-fund-builder",
  docusign: "/docusign",
  accounts: "/accounts",

  accountDetails: "/accounts/:id",

  userSettings: "/user/settings",
  findManagers: "/managers",
  home: "/overview",
  opportunities: "/opportunities",
  investmentDetails: "/investment-details",
  documents: "/documents",
  transactions: "/transactions",
  investments: "/investments",
  fundBuilder: `${wizardPrefix}fund-builder/:id`,
};

export const investorPortalWhiteLabelRoutes = {
  whiteLabel: `/:${managerWhiteLabelSectionParams.section}?/:subSection?/:subSectionId?`,
  overview: `/${managerWhiteLabelSections.overview}/:subSection?/:subSectionId?`,
  accounts: `/${managerWhiteLabelSections.accounts}/:subSection?/:subSectionId?`,
  opportunities: `/${managerWhiteLabelSections.opportunities}`,
  opportunityDetails: `/${managerWhiteLabelSections.opportunities}/:${managerWhiteLabelSectionParams.subSectionId}`,
  investments: `/${managerWhiteLabelSections.investments}`,
  documents: `/${managerWhiteLabelSections.documents}`,
  transactions: `/${managerWhiteLabelSections.transactions}`,
  settings: `/${managerWhiteLabelSections.settings}`,
  investmentDetails: `/${managerWhiteLabelSections.investmentDetails}/:accountId?/:offeringId?`,
};

export const getInvestmentWizardRoute = (investmentId: string) =>
  `/w/investment/${investmentId}/progress`;

export const getManagerOverviewRoute = () =>
  buildRouteUrl(investorPortalWhiteLabelRoutes.overview);

export const getManagerOpportunitiesRoute = () =>
  buildRouteUrl(investorPortalWhiteLabelRoutes.opportunities);

export const getOpportunityDetailsRoute = (opportunityId: string | number) =>
  buildRouteUrl(investorPortalWhiteLabelRoutes.opportunityDetails, {
    [managerWhiteLabelSectionParams.subSectionId]: String(opportunityId),
  });

export const getDocumentsRoute = (
  opportunityId?: string | number,
  opportunityName?: string,
  accountId?: string | number,
  accountName?: string,
  mnumber?: string,
  accountType?: AccountTypeType | string,
) =>
  buildRouteUrl(investorPortalWhiteLabelRoutes.documents, {
    [ManagerWhiteLabelSectionQueries.OpportunityId]: String(opportunityId),
    [ManagerWhiteLabelSectionQueries.OpportunityName]: String(opportunityName),
    [ManagerWhiteLabelSectionQueries.AccountId]: String(accountId),
    [ManagerWhiteLabelSectionQueries.AccountName]: String(accountName),
    [ManagerWhiteLabelSectionQueries.AccountMNumber]: String(mnumber),
    [ManagerWhiteLabelSectionQueries.AccountType]: String(accountType),
  });

export const getTransactionsRoute = (
  opportunityId?: string | number,
  opportunityName?: string,
  accountId?: string | number,
  accountName?: string,
  mnumber?: string,
  accountType?: AccountTypeType | string,
) =>
  buildRouteUrl(
    investorPortalWhiteLabelRoutes.transactions,
    {},
    {
      [ManagerWhiteLabelSectionQueries.OpportunityId]: String(opportunityId),
      [ManagerWhiteLabelSectionQueries.OpportunityName]:
        String(opportunityName),
      [ManagerWhiteLabelSectionQueries.AccountId]: String(accountId),
      [ManagerWhiteLabelSectionQueries.AccountName]: String(accountName),
      [ManagerWhiteLabelSectionQueries.AccountMNumber]: String(mnumber),
      [ManagerWhiteLabelSectionQueries.AccountType]: String(accountType),
    },
  );
export const getInvestmentDetailsRoute = (
  accountId: string | number,
  offeringId?: string | number,
) =>
  buildRouteUrl(investorPortalWhiteLabelRoutes.investmentDetails, {
    accountId: String(accountId),
    offeringId: String(offeringId),
  });
