/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum AssetsToAcquireTypeEnumType {
  Both = "Both",
  RealEstateDebt = "Real Estate Debt",
  RealEstateSecurities = "Real Estate Securities",
}
