import { Flex, Icon, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { AlertError, AlertTriangle, CloseIcon } from "./Icons";

const typeStyles = {
  success: {
    leftBox: {
      backgroundColor: "semantic.success",
    },
    rightBox: {
      backgroundColor: "semantic.lightestSuccess",
    },
  },
  warning: {
    leftBox: {
      backgroundColor: "semantic.warning",
    },
    rightBox: {
      backgroundColor: "semantic.lightestWarning",
    },
  },
  info: {
    leftBox: {
      backgroundColor: "semantic.info",
    },
    rightBox: {
      backgroundColor: "semantic.lightestInfo",
    },
  },
  error: {
    leftBox: {
      backgroundColor: "semantic.error",
    },
    rightBox: {
      backgroundColor: "semantic.lightestError",
    },
  },
};

const MessageBox = ({
  title,
  text,
  icon = AlertError,
  onClose,
  filled,
  type,
  ...rest
}) => {
  if (filled) {
    const style = typeStyles[type] || {};

    return (
      <Flex borderRadius={4} width="100%" {...rest}>
        <Flex
          borderRadius="4px 0px 0px 4px"
          w="74px"
          p={6}
          alignItems="flex-start"
          {...style.leftBox}
        >
          <Icon boxSize="24px" as={AlertTriangle} color="text.white" />
        </Flex>
        <Flex
          flex="1"
          borderRadius="0px 4px 4px 0px"
          p={6}
          alignItems="flex-start"
          justifyContent="space-between"
          direction={"column"}
          {...style.rightBox}
        >
          {title && <Text textStyle={"bodyRegularBold"}>{title}</Text>}
          {text && <Text textStyle={"bodyRegular"}>{text}</Text>}
          {onClose && (
            <Icon
              boxSize="24px"
              as={CloseIcon}
              color="icons.grey"
              onClick={onClose}
              cursor="pointer"
            />
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex borderRadius={4} width="100%" {...rest}>
      <Flex
        borderRadius="4px 0px 0px 4px"
        bg="semantic.error"
        w="74px"
        px="25px"
        alignItems="center"
      >
        <Icon boxSize="24px" as={icon} color="alert.danger" />
      </Flex>
      <Flex
        flex="1"
        py="25px"
        borderColor="border.input"
        borderWidth="1px"
        borderRadius="0px 4px 4px 0px"
        px="24px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text>{text}</Text>
        {onClose && (
          <Icon
            boxSize="24px"
            as={CloseIcon}
            color="icons.grey"
            onClick={onClose}
            cursor="pointer"
          />
        )}
      </Flex>
    </Flex>
  );
};

MessageBox.propTypes = {
  /**
   * type of the message
   */
  type: PropTypes.string,
  /**
   * Text of the message
   */
  text: PropTypes.string,
};

export default MessageBox;
