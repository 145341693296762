/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const ChevronUpIcon = createIcon({
  displayName: "ChevronUpIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M18 15L12 9L6 15" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></g>)
});
export default ChevronUpIcon;