/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetEntitiesOutputJsonApiModel = {
  id: "",
  entities: {
    jsonApi: "hasMany",
    type: "entities",
  },
};
