/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum OpportunityStatusEnumType {
  ComingSoon = "Coming Soon",
  Draft = "Draft",
  FullCycle = "Full Cycle",
  Funded = "Funded",
  Open = "Open",
}
