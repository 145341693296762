/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WorkflowJsonApiModel = {
  id: "",
  name: "",
  description: "",
  net: "",
  "depends-on": "",
  tags: "",
  settings: "",
  disabled: "",
  "created-at": "",
  "updated-at": "",
};
