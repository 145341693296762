/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateEntityAddressOutputJsonApiModel = {
  id: "",
  "entity-address": {
    jsonApi: "hasOne",
    type: "entity-addresses",
  },
};
