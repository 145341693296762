import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { ManagerModel } from "../../../api/models";
import { NotificationContentInvestorDocumentVoidedModel } from "../../../api/models/NotificationContentInvestorDocumentVoided/NotificationContentInvestorDocumentVoided";
import { NotificationItemGeneral } from "../NotificationItemGeneral";

interface NotificationDocusignVoidedProps {
  content: NotificationContentInvestorDocumentVoidedModel;
  manager: ManagerModel | null | undefined;
  createdAt: string;
}
export const NotificationDocusignVoided = ({
  content,
  createdAt,
  ...rest
}: NotificationDocusignVoidedProps) => {
  return (
    <NotificationItemGeneral
      image={content.imgUrl || ""}
      title={`Manager voided the docusign for the investment ${content.offeringName}.`}
      description={
        <>
          The docusign envelope has been voided and the link no longer provides
          access to the documents.
          <Box
            sx={{
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                left: 0,
                top: 0,
                bottom: 0,
                width: "4px",
                backgroundColor: "gray.200",
                borderRadius: "lg",
                marginRight: "8px",
                transform: "translateX(-100%)",
              },
            }}
          >
            {!!content.message && (
              <Box>
                <Text ml={2}>Message from manager:</Text>
                <Text ml={2}>{content.message}</Text>
              </Box>
            )}
          </Box>
        </>
      }
      actionTitle={"View investment detail"}
      actionOnClick={async () => {
        const investment = content.investmentId
          ? `/w/investment/${content.investmentId}/progress`
          : window.location.href;
        window.location.href = investment;
      }}
      actionProps={{
        link: true,
        p: 0,
      }}
      createdAt={createdAt}
      {...rest}
    />
  );
};
