import React from "react";
import { Box, Flex, useTheme } from "@chakra-ui/react";
import { ImpersonatingHeaderProps } from "./ImpersonatingHeader.types";
import { Button, useAuth, UserIcon } from "@verivest/shared-components";

export const DEFAULT_IMPERSONATING_HEADER_HEIGHT = 60;

export const ImpersonatingHeader: React.FC<ImpersonatingHeaderProps> = ({
  wrapperProps,
}) => {
  const theme = useTheme();
  const height =
    theme.investorPortal.headerHeights.impersonatingHeader ||
    DEFAULT_IMPERSONATING_HEADER_HEIGHT;
  const { auth } = useAuth();
  const profile = auth.getProfile();
  const leaveImpersonating = () => auth.revokeImpersonation.bind(auth)();
  return (
    <Flex
      bg={"white"}
      justifyContent={"center"}
      w={"100%"}
      borderBottom={"1px solid"}
      borderBottomColor={"border.grey"}
    >
      <Flex
        w={"100%"}
        {...theme?.investorPortal.fixed}
        justifyContent={"space-between"}
        alignItems={"center"}
        {...wrapperProps}
        h={`${height}px`}
      >
        <Flex gap={3} alignItems={"center"} color={"#818696"}>
          <UserIcon />
          You are currently impersonating {profile?.name} on the investor
          portal.
        </Flex>
        <Box>
          <Button onClick={leaveImpersonating} outline>
            Leave impersonation
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};
