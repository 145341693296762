/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const SetupFundBuilderChargesOutputJsonApiModel = {
  id: "",
  "stripe-client-secret": "",
  "recurring-amount": "",
  "subtotal-amount": "",
  "discounted-amount": "",
  "total-amount": "",
  charges: {
    jsonApi: "hasOne",
    type: "fund-builder-charges",
  },
};
