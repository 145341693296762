import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { replaceImage } from "../../../../utils/utils";

const ImageTitle = ({ value }) => {
  return (
    <>
      {value ? (
        <Flex alignItems={"center"}>
          <Image
            objectFit="cover"
            fallbackSrc={replaceImage(
              value.picture || "/images/empty-card-sponsor.svg",
              "48xAUTO",
            )}
            width="48px"
            height="48px"
            borderRadius="4px"
            name={value.name}
            src={replaceImage(
              value.picture || "/images/empty-card-sponsor.svg",
              "48xAUTO",
            )}
            mr="16px"
          />
          <Box>{value.name}</Box>
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};

export default ImageTitle;
