// This has to be imported first
import { identifyUserForLogRocket } from "./initializeWebLogrocket";

import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import {
  After,
  ensureReady,
  getSerializedData,
  SerializeData,
} from "@jaredpalmer/after";
import { isMarketingSiteRoute } from "@verivest/shared-components";
import { ApiClient } from "@verivest/shared-components/src/api";
import { GlobalErrorBoundary } from "@verivest/shared-components/src/error-handling/error-boundary";
import "focus-visible/dist/focus-visible";
import React from "react";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import Auth from "./auth/Auth";
import { AuthProvider } from "./auth/AuthContext";
import "./client.css";
import configureStore from "./common/store/configureStore";
import MainApp from "./components/App";
import ThemeBack from "./components/Theme";
import useDynamicConfig from "./hooks/useDynamicConfig";
import { UnsavedChangesModalProvider } from "./hooks/useUnsavedChangesModal";
import routes from "./routes";
import DynamicConfig, { IS_MANAGER_DASHBOARD } from "./utils/DynamicConfig";
import OwnCookies from "./utils/OwnCookies";
import apiFetcher from "./utils/swr";
import UTMParams from "./utils/utm";

const key = "custom";
const cache = createCache({ key });

let cookies = new OwnCookies();
const dynamicConfig = new DynamicConfig(
  getSerializedData("dynamic_config_data"),
);
const isManagerDashboard = dynamicConfig.get(IS_MANAGER_DASHBOARD);
const auth = new Auth(cookies, isManagerDashboard);
const apiClient = new ApiClient(auth);
const placementsApiClient = new ApiClient(auth, "placementsExternal");
const legacyApiClient = new ApiClient(auth, "legacyExternal");
const preloadedState = getSerializedData("preloaded_state");
const store = configureStore(preloadedState, apiClient);
auth.setDispatch(store.dispatch);
auth.setGetState(store.getState);
auth.init();

const profile = dynamicConfig.get("profile");

auth.setProfile(profile);

identifyUserForLogRocket(profile);

const utmParams = new UTMParams();
utmParams.save(utmParams.parse());
function renderApp() {
  ensureReady(routes(dynamicConfig.get(IS_MANAGER_DASHBOARD))).then((data) =>
    hydrate(
      <CacheProvider value={cache}>
        <ThemeBack isDashboard={dynamicConfig.get(IS_MANAGER_DASHBOARD)}>
          <UnsavedChangesModalProvider>
            {(setCallback) => (
              <BrowserRouter
                getUserConfirmation={(message, callback) =>
                  setCallback(callback, message)
                }
              >
                <Provider store={store}>
                  <AuthProvider
                    value={{
                      utmParams: utmParams,
                      auth: auth,
                      api: apiClient,
                      placementsApiClient: placementsApiClient,
                      legacyApiClient: legacyApiClient,
                      dynamicConfig: dynamicConfig,
                    }}
                  >
                    <>
                      <SWRConfig
                        value={{
                          refreshInterval: 0,
                          revalidateOnFocus: false,
                          revalidateOnReconnect: false,
                          fetcher: (...args) => apiFetcher(apiClient, ...args),
                        }}
                      >
                        <GlobalErrorBoundary
                          backToHref={process.env.RAZZLE_APP_BASE_URL}
                        >
                          <>
                            <MainApp
                              auth={auth}
                              isMarketing={isMarketingSiteRoute()}
                            >
                              <After
                                data={data}
                                routes={routes(
                                  dynamicConfig.get(IS_MANAGER_DASHBOARD),
                                )}
                                store={store}
                                apiClient={apiClient}
                                auth={auth}
                                dynamicConfig={dynamicConfig}
                                isMarketing={isMarketingSiteRoute()}
                              />
                            </MainApp>
                          </>
                        </GlobalErrorBoundary>
                      </SWRConfig>

                      <DynamicConfigData />
                    </>
                  </AuthProvider>
                </Provider>
              </BrowserRouter>
            )}
          </UnsavedChangesModalProvider>
        </ThemeBack>
      </CacheProvider>,

      document.getElementById("root"),
    ),
  );
}

renderApp();

if (module.hot) {
  module.hot.accept("./routes", renderApp);
}

function DynamicConfigData() {
  const dynamicConfig = useDynamicConfig();
  return (
    <SerializeData name="dynamic_config_data" data={dynamicConfig.getState()} />
  );
}
