/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CreateBillingScheduleTierInputJsonApiModel = {
  id: "",
  "billing-schedule-tier": {
    jsonApi: "hasOne",
    type: "billing-schedule-tiers",
  },
};
