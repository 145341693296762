/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const BuildingLineIcon = createIcon({
  displayName: "BuildingLineIcon",
  viewBox: "0 0 39 39",
  path: (
    <g fill="none">
      <path
        d="M34.125 30.875H37.375V34.125H1.625V30.875H4.875V6.5C4.875 6.06902 5.0462 5.6557 5.35095 5.35095C5.6557 5.0462 6.06902 4.875 6.5 4.875H22.75C23.181 4.875 23.5943 5.0462 23.899 5.35095C24.2038 5.6557 24.375 6.06902 24.375 6.5V30.875H30.875V17.875H27.625V14.625H32.5C32.931 14.625 33.3443 14.7962 33.649 15.101C33.9538 15.4057 34.125 15.819 34.125 16.25V30.875ZM8.125 8.125V30.875H21.125V8.125H8.125ZM11.375 17.875H17.875V21.125H11.375V17.875ZM11.375 11.375H17.875V14.625H11.375V11.375Z"
        fill="currentColor"
      />
    </g>
  ),
});
export default BuildingLineIcon;
