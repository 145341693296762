/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum OfferingInvestmentTypeEnumType {
  Growth = "Growth",
  GrowthAndIncome = "Growth and Income",
  Income = "Income",
}
