/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const CustomDomainJsonApiModel = {
  id: "",
  "domain-name": "",
  "certificate-arn": "",
  "certificate-status-valid": "",
  "certificate-record-name": "",
  "certificate-record-value": "",
  "distribution-domain-name": "",
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "manager-id": "",
  "created-at": "",
  "updated-at": "",
  "certificate-verified-at": "",
  "domain-verified-at": "",
  "is-active": "",
};
