/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallDefinitionRuleJsonApiModel = {
  id: "",
  "waterfall-definition-id": "",
  "rule-index": "",
  template: "",
  "target-type": "",
  "display-name": "",
  description: "",
  classes: {
    jsonApi: "hasMany",
    type: "waterfall-definition-rule-classes",
  },
  "side-letters": {
    jsonApi: "hasMany",
    type: "waterfall-side-letters",
  },
};
