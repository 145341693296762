import React from "react";
import { ManagerModel } from "../../../api/models";
import { NotificationItemGeneral } from "../NotificationItemGeneral";
import { NotificationImporterBrownfieldCompletedModel } from "../notifications.types";

interface NotificationImporterBrownfieldCompletedProps {
  content: NotificationImporterBrownfieldCompletedModel;
  manager: ManagerModel | null | undefined;
  createdAt: string;
}
export const NotificationImporterBrownfieldCompleted = ({
  content,
  createdAt,
  ...rest
}: NotificationImporterBrownfieldCompletedProps) => {
  return (
    <NotificationItemGeneral
      title={"Import completed"}
      description={
        <>
          The import for the offering {content.offeringName} in the opportunity{" "}
          {content.opportunityName} has been completed.
        </>
      }
      actionTitle={"View import detail"}
      actionOnClick={async () => {
        const importer = content.importerId
          ? `/dashboard/w/brownfield/${content.importerId}`
          : window.location.href;
        window.location.href = importer;
      }}
      actionProps={{
        link: true,
        p: 0,
      }}
      createdAt={createdAt}
      {...rest}
    />
  );
};
