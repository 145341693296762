/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetOfferingExemptionsOutputJsonApiModel = {
  id: "",
  "offering-exemptions": {
    jsonApi: "hasMany",
    type: "offering-exemptions",
  },
};
