import React, { PropsWithChildren } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

export const LRPrivText: React.FC<PropsWithChildren<BoxProps>> =
  React.forwardRef(
    ({ children, ...rest }, ref: React.ForwardedRef<HTMLDivElement>) => (
      <Box
        ref={ref}
        as={"span"}
        style={{ display: "inline-block" }}
        {...rest}
        data-private
      >
        {children}
      </Box>
    ),
  );
LRPrivText.displayName = "LRPrivText";
