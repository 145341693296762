/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WorkflowInstanceJsonApiModel = {
  id: "",
  "workflow-id": "",
  workflow: {
    jsonApi: "hasOne",
    type: "workflow",
  },
  "parent-id": "",
  parent: {
    jsonApi: "hasOne",
    type: "workflow-instance",
  },
  "user-id": "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  status: "",
  errors: "",
  settings: "",
  metadata: "",
  "created-at": "",
  "updated-at": "",
};
