/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const CheckIcon = createIcon({
  displayName: "CheckIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M20 6L9 17L4 12" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></g>)
});
export default CheckIcon;