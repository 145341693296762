import {
  Box,
  Checkbox,
  Flex,
  IconButton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { ApiModel } from "../../../api/ApiModel";
import { JsonApiManyResponse, JsonApiMeta } from "../../../api/types";
import { Dict } from "../../../defines";
import { logError } from "../../../error-handling/logError";
import { MoreVerticalIcon } from "../../../icons";
import { scrollToTop } from "../../../utils";
import { ConfirmModal } from "../../modals";
import { PopoverEdit } from "../../popover";
import { VeriSpinner } from "../loaders/VeriSpinner";
import { Pagination } from "../Pagination";
import { Table, Tbody, Td, Tr } from "../Table";
import { MenuItem, MenuList, VeriDropMenu } from "../VeriDropMenu";
import {
  BulkActionButton,
  ConfirmBanner,
  InlineActionItem,
  VeriTableHead,
  VeriTableHeading,
} from "./components";
import { sortingRecordsByColumnName } from "./VeriTable.helpers";
import { VeriTableStyles } from "./VeriTable.styles";
import {
  VeriTableAction,
  VeriTableConfirmData,
  VeriTableObject,
  VeriTableProps,
  VeriTableSortDirection,
} from "./VeriTable.types";

export const veriTableTestId = "veri-table";
export const veriTableRowTestId = (index: number) =>
  `${veriTableTestId}-row-${index}`;

const VeriTable: React.FC<VeriTableProps<any>> = <T,>({
  columns,
  records = [],
  onRowAction,
  getActionForRow,
  allowBulkSelection = false,
  indexIdentifier = "id" as keyof T,
  variant = "unstyled",
  actions = {},
  tableProps = {},
  hideLastDivider,
  onBulkSelectionChange,
  defaultBulkSelection,
  footerRecord,
  recordsPerPage = 0,
  api,
  apiUrl,
  apiPagination,
  apiUrlParams,
  mapApiRecord,
  apiFallbackData,
  scrollOnTopOnPageChange,
  onPageChange,
  refTableObject,
  hideHeader,
  minHeight,
  rowAllowMoreActions,
  actionRowExtra,
  noRecordsMessage,
  tHeadProps,
  sortable,
  apiSortable,
  sortRecords,
  defaultSorting,
  defaultSortingDirection,
  noRecordsContent,
  onRecordsChanged,
  selectAllSelectAllPages,
  onSelectionAllToggle,
  footer,
  showTotalRecords,
  tableHeadLabel,
  filterRecords,
  model,
  checkboxProps,
  isJsonApi = true,
  actionRecordWrapperProps,
}: VeriTableProps<T>) => {
  const tableStyle = VeriTableStyles[variant] || VeriTableStyles["unstyled"];
  const paginationSize = useBreakpointValue({ base: 2, md: 7 });
  const marginPagesDisplayed = useBreakpointValue({ base: 2, md: 1 });
  const breakpoint = useBreakpointValue({ md: 1, lg: 2, xl: 3, "2xl": 4 });

  const columnNames = Object.keys(columns).filter(
    (columnName) =>
      !breakpoint ||
      !columns[columnName].hideOnBreakpoint ||
      breakpoint > Number(columns[columnName].hideOnBreakpoint),
  );

  const viewMode = useBreakpointValue({ base: "mobile", md: "table" });
  const tableObject = React.useRef<VeriTableObject<T>>(
    {} as VeriTableObject<T>,
  );
  const theadRef = React.useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [apiRecords, setApiRecords] = React.useState<T[] | undefined>(
    undefined,
  );
  const [apiMeta, setApiMeta] = React.useState<JsonApiMeta | null>(null);
  const [apiLoading, setApiLoading] = React.useState(false);
  const [apiFallbackLoaded, setApiFallbackLoaded] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [confirmData, setConfirmData] =
    React.useState<VeriTableConfirmData<T> | null>(null);
  const [sortingColumn, setSortingColumn] = React.useState(defaultSorting);
  const [allRecordsSelected, setAllRecordsSelected] = React.useState(false);
  const [sortingDirection, setSortingDirection] =
    React.useState<VeriTableSortDirection>(
      defaultSortingDirection || VeriTableSortDirection.ASC,
    );
  const stringifiedApiUrlParams = JSON.stringify(apiUrlParams);

  // Using object instead of array for better performance
  const [bulkSelection, setBulkSelection] = React.useState<Dict<boolean>>(
    defaultBulkSelection
      ? defaultBulkSelection.reduce((res, el) => ({ ...res, [el]: true }), {})
      : {},
  );
  const selectedRecordsCount =
    selectAllSelectAllPages && allRecordsSelected
      ? apiMeta?.rowsTotal || 0
      : Object.keys(bulkSelection).length;

  const availableActionNames = Object.keys(actions);
  const availableBulkActionNames = availableActionNames.filter(
    (actionName) => actions[actionName].allowBulk || false,
  );

  const availableMoreActionNames = availableActionNames.filter(
    (actionName) => actions[actionName].isMoreAction || false,
  );

  const availableInlineActionNames = availableActionNames.filter(
    (actionName) => actions[actionName].isInlineAction || false,
  );

  const _setBulkSelection = (selection: Dict<boolean>) => {
    setBulkSelection(selection);
    onBulkSelectionChange &&
      onBulkSelectionChange(Object.keys(selection), apiMeta);
  };

  const hasRowActions =
    availableActionNames.length > 0 || availableInlineActionNames.length > 0;

  const mapApiRecords = (records: T[]) => {
    if (mapApiRecord) return [...records.map(mapApiRecord)];
    return [...records];
  };

  const sortSimpleRecords = (records: T[]) => {
    return sortable
      ? sortingRecordsByColumnName(
          columns?.[sortingColumn || ""]?.sortName || sortingColumn || "",
          sortingDirection,
          records,
          columns?.[sortingColumn || ""]?.renderer,
        )
      : records;
  };

  const _records =
    (apiRecords && apiRecords.length
      ? mapApiRecords(apiRecords)
      : recordsPerPage
      ? [...sortSimpleRecords(records || [])].slice(
          currentPage * recordsPerPage,
          (currentPage + 1) * recordsPerPage,
        )
      : [...sortSimpleRecords(records || [])]) || [];

  /**
   * Records count
   */
  const totalVisibleRecords = _records.length;

  const isAnyRecordSelected = allowBulkSelection && selectedRecordsCount > 0;
  const isIndeterminateSelection =
    selectAllSelectAllPages && allRecordsSelected
      ? false
      : isAnyRecordSelected && selectedRecordsCount < totalVisibleRecords;
  const isAllRecordsSelected =
    (selectAllSelectAllPages && allRecordsSelected) ||
    (isAnyRecordSelected && selectedRecordsCount === totalVisibleRecords);

  /**
   * Selection methods
   */
  const toggleRecordSelection = (recordId: string) => {
    if (selectAllSelectAllPages) {
      setAllRecordsSelected(false);
      onSelectionAllToggle &&
        onSelectionAllToggle(false, apiMeta?.rowsTotal || 0);
    }

    const newSelection = { ...bulkSelection };

    if (newSelection[recordId]) delete newSelection[recordId];
    else newSelection[recordId] = true;

    _setBulkSelection(newSelection);
  };

  const clearSelection = () => {
    if (selectAllSelectAllPages) {
      setAllRecordsSelected(false);
      onSelectionAllToggle &&
        onSelectionAllToggle(false, apiMeta?.rowsTotal || 0);
      return;
    }

    _setBulkSelection({});
  };

  const selectAllRecords = () => {
    if (selectAllSelectAllPages) {
      setAllRecordsSelected(true);
      onSelectionAllToggle &&
        onSelectionAllToggle(true, apiMeta?.rowsTotal || 0);
      return;
    }

    const newSelection: Dict<boolean> = {};
    _records.forEach((record) => {
      newSelection[record[indexIdentifier]] = true;
    });

    _setBulkSelection(newSelection);
  };

  const toggleAllSelection = () => {
    if (isAllRecordsSelected) {
      clearSelection();
    } else {
      selectAllRecords();
    }
  };

  const divider = (
    <Box
      borderBottomWidth={1}
      borderBottomStyle={"solid"}
      borderBottomColor={"#DCE1E7"}
      my={4}
    />
  );

  const hoverStyle = { backgroundColor: "bg.lighter", cursor: "pointer" };

  const clickAction = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    record: T,
  ) => {
    if (!onRowAction && !getActionForRow) return;

    e.stopPropagation();
    e.preventDefault();

    onRowAction?.(e, record);
    getActionForRow?.(record)?.(e, record);
  };

  const refresh = async (customUrlParams?: Dict) => {
    if (apiUrl || model) {
      setApiLoading(true);

      let sortField = null;
      let sortFieldName: string | undefined = "";

      if (apiSortable && sortingColumn) {
        sortField = `${sortingDirection === "DESC" ? "-" : ""}${
          columns[sortingColumn] && columns[sortingColumn].sortName
            ? columns[sortingColumn].sortName
            : sortingColumn
        }`;
        sortFieldName =
          columns[sortingColumn] && columns[sortingColumn].sortName
            ? columns[sortingColumn].sortName
            : sortingColumn;
      }

      const processResults = async (result: JsonApiManyResponse<T>) => {
        setApiMeta(result.meta || null);

        const resultRecords = mapApiRecords(
          filterRecords
            ? await filterRecords(result.data || [], result.meta || null)
            : result.data || [],
        );

        if (sortRecords) {
          sortRecords(
            columns?.[sortingColumn || ""]?.sortName || sortingColumn || "",
            sortingDirection,
            resultRecords,
            tableObject.current,
          ).then((sortedRecords) => {
            setApiRecords(
              sortedRecords
                ? sortedRecords || []
                : sortingRecordsByColumnName(
                    columns?.[sortingColumn || ""]?.sortName ||
                      sortingColumn ||
                      "",
                    sortingDirection,
                    resultRecords,
                    columns?.[sortingColumn || ""]?.renderer,
                  ),
            );
          });
        } else {
          setApiRecords(
            sortable && !apiSortable
              ? sortingRecordsByColumnName(
                  columns?.[sortingColumn || ""]?.sortName ||
                    sortingColumn ||
                    "",
                  sortingDirection,
                  resultRecords,
                  columns?.[sortingColumn || ""]?.renderer,
                )
              : resultRecords,
          );
        }
      };

      const catchError = (error: any) => {
        logError("Something went wrong retrieving data", error);
      };

      const processFinally = () => {
        setApiLoading(false);
      };

      if (model) {
        const _model: ReturnType<typeof ApiModel> = model as never;
        await _model
          .getPagedMany(
            {
              page: currentPage + 1,
              pageSize: recordsPerPage,
              sort: sortFieldName,
              sortDirection: sortingDirection,
            },
            undefined,
            { ...(customUrlParams || apiUrlParams) },
            !isJsonApi,
          )
          .then(processResults as never)
          .catch(catchError)
          .finally(processFinally);
      } else if (apiUrl) {
        await api
          ?.apiRequest<JsonApiManyResponse<T>>(apiUrl, "GET", {
            ...(customUrlParams || apiUrlParams),
            ...(apiPagination && {
              "page[size]": recordsPerPage || 100,
              "page[number]": currentPage + 1,
            }),
            ...(apiSortable &&
              sortField && {
                sort: sortField,
              }),
          })
          .then(processResults)
          .catch(catchError)
          .finally(processFinally);
      }
    } else {
      sortRecords &&
        sortRecords(
          sortingColumn || "",
          sortingDirection,
          records,
          tableObject.current,
        ).then((/*sortedRecords*/) => {
          // setApiRecords(sortedRecords || []);
        });
    }

    setIsInitialized(true);
  };

  const recordsRef = useRef<T[]>(_records);
  recordsRef.current = _records;

  tableObject.current = {
    toggleRecordSelection,
    clearSelection,
    selectAllRecords,
    toggleAllSelection,
    refresh,
    setConfirmData,
    setSortingColumn,
    setSortingDirection,
    sortingDirection,
    sortingColumn,
    isIndeterminateSelection,
    isAllRecordsSelected,
    setCurrentPage,
    totalRecordsCount: apiMeta?.rowsTotal || 0,
    recordsRef: recordsRef,
  };

  React.useEffect(() => {
    refTableObject && refTableObject(tableObject.current);
  }, [
    refTableObject,
    apiMeta?.rowsTotal,
    isAllRecordsSelected,
    isIndeterminateSelection,
    sortingColumn,
    sortingDirection,
  ]);

  React.useEffect(() => {
    if (onBulkSelectionChange) {
      setBulkSelection(
        (Object.keys(bulkSelection) || []).reduce(
          (res, el) => ({ ...res, [el]: true }),
          {},
        ),
      );
    }
  }, [onBulkSelectionChange, defaultBulkSelection]);

  React.useEffect(() => {
    if (apiFallbackData && !apiFallbackLoaded) {
      setApiMeta(apiFallbackData.meta || null);

      const _resultRecords = mapApiRecords(apiFallbackData.data || []);

      if (sortRecords) {
        sortRecords(
          sortingColumn || "",
          sortingDirection,
          _resultRecords,
          tableObject.current,
        ).then((sortedRecords) => {
          setApiRecords(
            sortedRecords
              ? filterRecords
                ? filterRecords(
                    sortedRecords || [],
                    apiFallbackData.meta || null,
                  )
                : sortedRecords || []
              : sortingRecordsByColumnName(
                  columns?.[sortingColumn || ""]?.sortName ||
                    sortingColumn ||
                    "",
                  sortingDirection,
                  _resultRecords,
                  columns?.[sortingColumn || ""]?.renderer,
                ),
          );

          setApiRecords(sortedRecords || []);
        });
      } else {
        setApiRecords(
          sortable
            ? sortingRecordsByColumnName(
                columns?.[sortingColumn || ""]?.sortName || sortingColumn || "",
                sortingDirection,
                _resultRecords,
                columns?.[sortingColumn || ""]?.renderer,
              )
            : filterRecords
            ? filterRecords(_resultRecords || [], apiFallbackData.meta || null)
            : _resultRecords || [],
        );
      }
      setApiFallbackLoaded(true);
      setIsInitialized(true);

      return;
    }

    // setCurrentPage(0);

    refresh().then() /*.finally(() => setIsInitialized(true))*/;
  }, [
    apiUrl,
    stringifiedApiUrlParams,
    currentPage,
    sortingColumn,
    sortingDirection,
  ]);

  /*React.useEffect(() => {
      if (!isInitialized) return;

      refresh();
    }, [currentPage]);*/

  React.useEffect(() => {
    if (apiUrl && isInitialized && onRecordsChanged) {
      onRecordsChanged(_records, tableObject.current);
    }
  }, [_records.length, apiUrl, isInitialized]);

  /*React.useEffect(() => {
    const onWindowResize = () => {
      console.log("resized");
    };

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);*/

  if (!viewMode) {
    return <></>;
  }

  if (footerRecord) _records.push(footerRecord);

  const totalPages = apiMeta
    ? apiMeta.pageTotal
    : recordsPerPage
    ? Math.ceil(records.length / recordsPerPage)
    : 0;

  const _doAction = async (
    action: VeriTableAction,
    ids: string[],
    records: T[],
  ) => {
    if (action.callback) {
      await action.callback(ids, records, tableObject.current);
    }
  };

  const doAction = async (
    action: VeriTableAction,
    ids: string[],
    records: T[],
  ) => {
    if (action.confirm) {
      setConfirmData({
        isOpen: true,
        title: action.confirm.title?.(records),
        message: action.confirm.message?.(records),
        confirmLabel: action.confirm.confirmLabel?.(records),
        cancelLabel: action.confirm.cancelLabel?.(records),
        onClose: () => {
          setConfirmData(null);
        },
        onConfirm: async () => {
          await _doAction(action, ids, records);
          setConfirmData(null);
        },
        confirmProps: action.confirm.confirmProps?.(records),
      });
      return;
    }

    try {
      action.callback && (await _doAction(action, ids, records));
    } catch (e) {
      logError("VeriTable Error", e);
    }
  };

  const PaginationComponent =
    apiPagination && totalPages > 1 ? (
      <Box mt={0} pt={6} pb={6}>
        <Pagination
          pageCount={totalPages}
          pageRangeDisplayed={paginationSize}
          marginPagesDisplayed={marginPagesDisplayed}
          onClickBuilder={(page) => {
            setCurrentPage(page - 1);
            if (scrollOnTopOnPageChange) {
              scrollToTop(true);
            }
            onPageChange && onPageChange(page);
          }}
          hrefBuilder={() => ""}
          selected={currentPage}
        />
      </Box>
    ) : (
      <></>
    );

  const getMoreActionsMenu = (
    record: T,
    recordIndex: number,
    recordIdentifier: string,
    availableMoreActionNames: string[],
    actions: { [key: string]: VeriTableAction },
  ) => {
    return (
      <MenuList>
        {availableMoreActionNames.map((actionName, i) => {
          let isEnabled = true;
          const actionCheck = actions[actionName].checkActionAllowed;

          if (actionCheck === undefined) {
            // something?
          } else if (typeof actionCheck === "boolean") {
            isEnabled = actionCheck;
          } else if (typeof actionCheck === "function") {
            isEnabled = actionCheck(record, tableObject.current);
          }

          if (actions[actionName].isDiver) {
            return (
              <Box
                key={"action-" + actionName + "-" + recordIndex + "-" + i}
                px={0}
                py={1}
                cursor={"default"}
              >
                <Box
                  borderBottom={"1px solid"}
                  borderColor="border.grey"
                  w={"100%"}
                />
              </Box>
            );
          }

          return (
            <MenuItem
              isDisabled={!isEnabled}
              key={"action-" + actionName + "-" + recordIndex + "-" + i}
              textStyle={"bodyRegular"}
              onClick={() =>
                isEnabled &&
                !actions[actionName].subs &&
                actions[actionName].callback &&
                doAction(actions[actionName], [recordIdentifier], [record])
              }
              subMenu={
                actions[actionName].subs
                  ? getMoreActionsMenu(
                      record,
                      recordIndex,
                      recordIdentifier,
                      Object.keys(actions[actionName].subs || {}),
                      actions[actionName].subs || {},
                    )
                  : undefined
              }
            >
              {actions[actionName].label || ""}
            </MenuItem>
          );
        })}
      </MenuList>
    );
  };

  if (viewMode !== "table") {
    return (
      <Box data-testid={veriTableTestId}>
        {(_records || []).map((record, recordIndex) => {
          const isActionableRow = !!onRowAction || !!getActionForRow?.(record);
          const recordIdentifier = record[indexIdentifier];

          return (
            <Box
              data-testid={veriTableRowTestId(recordIndex)}
              key={"record-" + recordIndex}
              className={"relative sm:static"}
            >
              {divider}
              <Box
                onClick={(e) => {
                  clickAction(e, record);
                }}
                _hover={isActionableRow ? hoverStyle : {}}
              >
                {columnNames.map((columnName, columnIndex) => {
                  const column = columns[columnName];
                  const value = record[columnName];
                  const Renderer = column.renderer || undefined;
                  return (
                    <Box key={columnName}>
                      <Box mb={2}>
                        {column?.label && (
                          <Text as="span" textStyle="bodyRegularBold" mr={2}>
                            {column?.label}:
                          </Text>
                        )}
                        <Text as="span" textStyle="bodyRegular">
                          {Renderer ? (
                            <Renderer
                              value={value}
                              column={column}
                              tableObject={tableObject.current}
                              record={record}
                              recordIndex={recordIndex}
                              columnIndex={columnIndex}
                              columnName={columnName}
                              options={column.rendererOptions || {}}
                            />
                          ) : column.processRow ? (
                            column.processRow(
                              column,
                              value,
                              record,
                              tableObject.current,
                            )
                          ) : (
                            value
                          )}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              {hasRowActions && (
                <Box {...actionRecordWrapperProps}>
                  <Flex alignItems={"center"} justifyContent={"flex-end"}>
                    {actionRowExtra && (
                      <Box>{actionRowExtra(record, tableObject.current)}</Box>
                    )}
                    <Flex minHeight={"40px"} whiteSpace={"nowrap"}>
                      {availableInlineActionNames?.length > 0 && (
                        <>
                          {availableInlineActionNames.map((actionName) => {
                            let isEnabled = true;
                            const thisAction = actions[actionName];
                            const actionCheck = thisAction.checkActionAllowed;

                            if (actionCheck === undefined) {
                              // Something?
                            } else if (typeof actionCheck === "boolean") {
                              isEnabled = actionCheck;
                            } else if (typeof actionCheck === "function") {
                              isEnabled = actionCheck(
                                record,
                                tableObject.current,
                              );
                            }

                            let tooltipText = "";
                            if (thisAction.getTooltipText) {
                              tooltipText = thisAction.getTooltipText(record);
                            }

                            return (
                              <InlineActionItem<T>
                                record={record}
                                recordIndex={recordIndex}
                                isEnabled={isEnabled}
                                recordIdentifier={recordIdentifier}
                                // actions={actions}
                                actionName={actionName}
                                action={actions[actionName]}
                                tableObject={tableObject.current}
                                key={"action-" + actionName + "-" + recordIndex}
                                tooltipText={tooltipText}
                              />
                            );
                          })}
                        </>
                      )}
                      {availableMoreActionNames?.length > 0 &&
                        (typeof rowAllowMoreActions === "boolean"
                          ? rowAllowMoreActions
                          : typeof rowAllowMoreActions === "function"
                          ? rowAllowMoreActions(record, tableObject.current)
                          : false) && (
                          <VeriDropMenu
                            trigger={({ isOpen }: { isOpen: boolean }) => (
                              <IconButton
                                isActive={isOpen}
                                bg={"transparent"}
                                borderRadius={"100%"}
                                aria-label={"More actions"}
                              >
                                <MoreVerticalIcon
                                  boxSize={"24px"}
                                  color={"icons.grey"}
                                />
                              </IconButton>
                            )}
                          >
                            {getMoreActionsMenu(
                              record,
                              recordIndex,
                              recordIdentifier,
                              availableMoreActionNames,
                              actions,
                            )}
                          </VeriDropMenu>
                        )}
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Box>
          );
        })}

        {PaginationComponent}

        {!hideLastDivider && divider}
      </Box>
    );
  }

  if (apiUrl && !isInitialized) return <></>;

  if (noRecordsContent && model && apiRecords === undefined) {
    return (
      <Flex
        w={"100%"}
        p={10}
        alignItems={"center"}
        justifyContent={"center"}
        pos={"relative"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          position={"absolute"}
          left={0}
          right={0}
          top={0}
          bottom={0}
          bg={"rgba(255,255,255,.6)"}
        >
          <VeriSpinner />
        </Flex>
      </Flex>
    );
  }

  if (noRecordsContent && (!_records || _records.length < 1)) {
    return <>{noRecordsContent}</>;
  }

  return (
    <Box data-testid={veriTableTestId}>
      <VeriTableHeading
        headingLabel={tableHeadLabel}
        showTotalRecordsCount={showTotalRecords}
        totalRecordsCount={apiMeta?.rowsTotal}
        checkboxProps={checkboxProps}
      />
      <Box
        minHeight={minHeight}
        {...(tableStyle.wrapper || {})}
        position={"relative"}
        maxWidth={"100%"}
        overflow={"auto"}
      >
        {isAnyRecordSelected && (
          <Flex
            position={"absolute"}
            left="35px"
            right={0}
            bottom="1px"
            top={0}
            pl={6}
            pt={String((theadRef?.current?.clientHeight || 65) - 70) + "px"}
            alignItems={"center"}
            {...(tableStyle.bulkActionsBar || {})}
            height={String((theadRef?.current?.clientHeight || 65) - 1) + "px"}
            zIndex={"docked"}
          >
            <Box textStyle={"bodySmall"} fontWeight={"500"} mr={6}>
              {selectedRecordsCount} selected
            </Box>
            <Flex>
              {availableBulkActionNames.map((actionName) => (
                <BulkActionButton
                  key={actionName}
                  actions={actions}
                  actionName={actionName}
                  bulkSelection={bulkSelection}
                  tableObject={tableObject.current}
                  records={_records.filter(
                    (r) => bulkSelection[r[indexIdentifier]] !== undefined,
                  )}
                />
              ))}
            </Flex>
          </Flex>
        )}
        {apiLoading && (
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            position={"absolute"}
            left={0}
            right={0}
            top={0}
            bottom={0}
            bg={"rgba(255,255,255,.6)"}
          >
            <VeriSpinner />
          </Flex>
        )}
        <Table variant="unstyled" {...(tableStyle.table || {})} {...tableProps}>
          {!hideHeader && (
            <VeriTableHead
              tHeadProps={tHeadProps}
              tableStyle={tableStyle}
              columnNames={columnNames}
              allowBulkSelection={allowBulkSelection}
              tableObject={tableObject.current}
              hasRowActions={hasRowActions}
              columns={columns}
              sortable={sortable}
              ref={theadRef}
              checkboxProps={checkboxProps}
            />
          )}
          <Tbody>
            {(_records || []).map((record, recordIndex) => {
              const recordIdentifier = record[indexIdentifier];
              const isRecordSelected =
                (selectAllSelectAllPages && allRecordsSelected) ||
                bulkSelection[recordIdentifier];

              const hasActionBanner =
                confirmData &&
                confirmData.inlineBanner &&
                confirmData?.record?.[indexIdentifier] ===
                  record[indexIdentifier];

              const isActionableRow =
                !!onRowAction || !!getActionForRow?.(record);

              return (
                <Tr
                  data-testid={veriTableRowTestId(recordIndex)}
                  key={`record-${recordIndex}`}
                  _hover={isActionableRow ? hoverStyle : {}}
                  position={hasActionBanner ? "relative" : undefined}
                  {...(tableStyle.tr || {})}
                  {...(isRecordSelected && tableStyle.selectedRow
                    ? tableStyle.selectedRow
                    : {})}
                >
                  {allowBulkSelection && (
                    <Td
                      key={`row-bulk-selection-${recordIndex}-${isRecordSelected}`}
                      borderBottomWidth={
                        hideLastDivider && recordIndex + 1 === _records.length
                          ? 0
                          : 1
                      }
                    >
                      <Checkbox
                        isChecked={isRecordSelected}
                        onChange={() => toggleRecordSelection(recordIdentifier)}
                        size={"lg"}
                        {...checkboxProps}
                      />
                    </Td>
                  )}
                  {columnNames.map((columnName, columnIndex) => {
                    const column = columns[columnName];
                    const value = record[columnName];

                    const content = (
                      <>
                        {column.renderer ? (
                          <column.renderer
                            value={value}
                            column={column}
                            tableObject={tableObject.current}
                            record={record}
                            recordIndex={recordIndex}
                            columnName={columnName}
                            columnIndex={columnIndex}
                            options={column.rendererOptions || {}}
                            key={`renderer-${columnIndex}-${recordIndex}`}
                          />
                        ) : column.processRow ? (
                          column.processRow(
                            column,
                            value,
                            record,
                            tableObject.current,
                          )
                        ) : (
                          value
                        )}
                      </>
                    );

                    return (
                      <Td
                        textStyle={"bodySmallRegular"}
                        textAlign={column.rowAlign || "left"}
                        pl={!columnIndex && allowBulkSelection ? 0 : undefined}
                        key={`record-column-${columnName}-${columnIndex}`}
                        onClick={(e) => {
                          clickAction(e, record);
                        }}
                        _hover={{ pointer: "inherit" }}
                        borderBottomWidth={
                          hideLastDivider && recordIndex + 1 === _records.length
                            ? 0
                            : 1
                        }
                        {...(tableStyle.td || {})}
                        {...(footerRecord &&
                          recordIndex + 1 === _records.length && {
                            ...(tableStyle.footerRecord || {}),
                          })}
                        {...column.props}
                      >
                        {column.inlineEdit &&
                        (!column.inlineEdit.checkActionAllowed ||
                          column.inlineEdit.checkActionAllowed(record)) ? (
                          <Flex alignItems={"center"}>
                            {content}
                            <PopoverEdit
                              getInputs={column.inlineEdit.component}
                              defaultValues={{ [columnName]: value }}
                              onSubmit={async (values) =>
                                await column.inlineEdit?.onSubmit?.(
                                  values,
                                  record,
                                  tableObject.current,
                                )
                              }
                            />
                          </Flex>
                        ) : (
                          content
                        )}
                      </Td>
                    );
                  })}
                  {hasRowActions && (
                    <Td
                      textStyle={"bodySmallRegular"}
                      textAlign={"center"}
                      _hover={{ pointer: "inherit" }}
                      borderBottomWidth={
                        hideLastDivider && recordIndex + 1 === _records.length
                          ? 0
                          : 1
                      }
                      {...(tableStyle.td || {})}
                      {...(tableStyle.stickyTd || {})}
                      {...(footerRecord &&
                        recordIndex + 1 === _records.length && {
                          ...(tableStyle.footerRecord || {}),
                        })}
                    >
                      <Flex alignItems={"center"} justifyContent={"flex-end"}>
                        {actionRowExtra && (
                          <Box>
                            {actionRowExtra(record, tableObject.current)}
                          </Box>
                        )}
                        <Flex minHeight={"40px"} whiteSpace={"nowrap"}>
                          {availableInlineActionNames?.length > 0 && (
                            <>
                              {availableInlineActionNames.map((actionName) => {
                                let isEnabled = true;
                                const thisAction = actions[actionName];
                                const actionCheck =
                                  thisAction.checkActionAllowed;

                                if (actionCheck === undefined) {
                                  // Something?
                                } else if (typeof actionCheck === "boolean") {
                                  isEnabled = actionCheck;
                                } else if (typeof actionCheck === "function") {
                                  isEnabled = actionCheck(
                                    record,
                                    tableObject.current,
                                  );
                                }

                                let tooltipText = "";
                                if (thisAction.getTooltipText) {
                                  tooltipText =
                                    thisAction.getTooltipText(record);
                                }

                                return (
                                  <InlineActionItem<T>
                                    record={record}
                                    recordIndex={recordIndex}
                                    isEnabled={isEnabled}
                                    recordIdentifier={recordIdentifier}
                                    // actions={actions}
                                    actionName={actionName}
                                    action={actions[actionName]}
                                    tableObject={tableObject.current}
                                    key={
                                      "action-" + actionName + "-" + recordIndex
                                    }
                                    tooltipText={tooltipText}
                                  />
                                );
                              })}
                            </>
                          )}
                          {availableMoreActionNames?.length > 0 &&
                            (typeof rowAllowMoreActions === "boolean"
                              ? rowAllowMoreActions
                              : typeof rowAllowMoreActions === "function"
                              ? rowAllowMoreActions(record, tableObject.current)
                              : false) && (
                              <VeriDropMenu
                                trigger={({ isOpen }: { isOpen: boolean }) => (
                                  <IconButton
                                    isActive={isOpen}
                                    bg={"transparent"}
                                    borderRadius={"100%"}
                                    aria-label={"More actions"}
                                  >
                                    <MoreVerticalIcon
                                      boxSize={"24px"}
                                      color={"icons.grey"}
                                    />
                                  </IconButton>
                                )}
                              >
                                {getMoreActionsMenu(
                                  record,
                                  recordIndex,
                                  recordIdentifier,
                                  availableMoreActionNames,
                                  actions,
                                )}
                              </VeriDropMenu>
                            )}
                        </Flex>
                      </Flex>
                      {hasActionBanner && <ConfirmBanner {...confirmData} />}
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        {noRecordsMessage && (!_records || !(_records.length > 0)) && (
          <Box py={6}>{noRecordsMessage}</Box>
        )}
      </Box>
      {PaginationComponent}
      {footer}
      {confirmData && !confirmData.inlineBanner && (
        <ConfirmModal {...confirmData} />
      )}
    </Box>
  );
};

export default VeriTable;
