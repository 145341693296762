import { Input, InputProps } from "@chakra-ui/react";
import React from "react";
import { buildVeriInputStyles } from "./buildVeriInputStyles";

interface VeriStyledInputProps extends InputProps {
  greyedOutOnReadOnly?: boolean;
  mask?: string | Array<string | RegExp>;
}

export const VeriStyledInput = React.forwardRef<
  HTMLInputElement,
  VeriStyledInputProps
>(({ greyedOutOnReadOnly, ...restProps }, ref) => (
  <Input
    ref={ref}
    {...buildVeriInputStyles({ greyedOutOnReadOnly })}
    {...restProps}
  />
));

VeriStyledInput.displayName = "VeriStyledNativeInput";
