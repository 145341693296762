/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuResportsIcon = createIcon({
  displayName: "MenuResportsIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <path
        d="M14 3H10V21H14V3Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M5 14H1V21H5V14Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <path
        d="M23 9H19V21H23V9Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </g>
  ),
});
export default MenuResportsIcon;
