/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetChartInputJsonApiModel = {
  id: "",
  filters: {
    jsonApi: "hasOne",
    type: "chart-filters",
  },
  "graph-output-format": "",
  base64: "",
};
