import { Dict } from "../defines/index";
export interface JsonApiMeta {
  pageNumber: number;
  pageSize: number;
  pageTotal: number;
  rowsTotal: number;
}

export interface JsonAPiCreateResponse<Data> {
  data: Data;
}

export interface JsonAPiUpdateResponse<Data> {
  data: Data;
}

export interface JsonApiManyResponse<Data> {
  data: Data[];
  meta: JsonApiMeta;
}

export interface JsonApiSingleResponse<Data> {
  data: Data;
  meta: JsonApiMeta;
}

export interface RPCRequestResponse {
  error: {
    code: string;
    data: string;
    message: string;
  };
  jsonprc: string;
  result: string;
}

export enum ModelScope {
  Manager = "manager",
  Investor = "investor",
  Mallcop = "mallcop",
  Public = "public",
}

export type GenericIdType = string | number | null;

export interface PaginationOptionsType {
  page?: number;
  pageSize?: number;
  sort?: string;
  sortDirection?: "ASC" | "DESC";
  filter?: Dict;
}

export type UnknownModelPropertyTypes = string | number | boolean | null | Dict;
export type UnknownModelProperty =
  | UnknownModelPropertyTypes
  | Dict<UnknownModelPropertyTypes>
  | Array<UnknownModelPropertyTypes>;
