/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const VerifiedBadgeIcon = createIcon({
  displayName: "VerifiedBadgeIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6.74228 4.73792L8.59987 1.5L11.9999 3.02464V3.02893L12.0001 3.02464L15.4 1.5L17.2577 4.73792L20.8994 5.51145L20.5095 9.22565L22.9999 12L20.5095 14.7743L20.8994 18.4886L17.2577 19.2621L15.4001 22.5L12.0001 20.9754L11.9999 20.9711V20.9754L8.59991 22.5L6.74228 19.2621L3.10053 18.4886L3.49047 14.7743L1 12L3.49047 9.22565L3.10053 5.51149L6.74228 4.73792Z" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M15.649 8.72095L16.6118 9.58327L12.0004 14.767L12 14.7661V14.7668L11.0899 15.7898L7.25305 11.9401L8.16561 11.0244L11.0366 13.905L12 12.8221V12.8234L12.0004 12.8224L15.649 8.72095Z" fill="currentColor" /></g>)
});
export default VerifiedBadgeIcon;