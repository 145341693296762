import {
  AccountTypeType,
  ParallelMarketsAccreditationStatusEnumType,
  VerificationStatusEnumType,
  VerificationTypeEnumType,
} from "../api/enums";
import { AccountModel, VerificationModel } from "../api/models";
import { InvestmentModel } from "../api/models/Investment/Investment";
import { Nullable } from "../defines/index";

export enum VerificationManualStatuses {
  ManuallyVerified = "Manually verified",
  Expired = "Expired",
  Completed = "Completed",
  Imported = "Imported",
}

export enum IdentityVerificationStatuses {
  Success = "Success",
}

export type AllVerificationStatuses =
  | VerificationStatusEnumType
  | VerificationManualStatuses
  | ParallelMarketsAccreditationStatusEnumType
  | IdentityVerificationStatuses;

export const AllVerificationStatus = {
  ...VerificationStatusEnumType,
  ...VerificationManualStatuses,
  ...ParallelMarketsAccreditationStatusEnumType,
};

export function isCurrentVerifiedAt(expiresOn: string | null | undefined) {
  if (!expiresOn) {
    return false;
  }

  const expirationDate = new Date(expiresOn);
  const todayDate = new Date();
  todayDate.setHours(23, 59, 59, 998);
  return todayDate < expirationDate;
}

const VerificationFields = {
  [VerificationTypeEnumType.Accreditation]: "accreditationStatus",
  [VerificationTypeEnumType.Identity]: "identityStatus",
};

export function getAccountNameLabel(account: AccountModel) {
  if (account.accountType === AccountTypeType.Trust) {
    return "Legal Name of Trust";
  }

  if (account.accountType === AccountTypeType.Entity) {
    return "Legal Name of Entity";
  }

  return "Account Name";
}

export function getVerificationStatusByType(
  verificationType: VerificationTypeEnumType,
  account?: NullableAccount,
  pure = false,
) {
  const status =
    account?.[VerificationFields[verificationType] as keyof AccountModel];
  if (pure) {
    return status as VerificationStatusEnumType;
  }
  return (
    (status as VerificationStatusEnumType) ||
    VerificationStatusEnumType.Unverified
  );
}

type NullableAccount = Nullable<AccountModel>;

export function getIdentityVerificationStatus(
  account?: NullableAccount,
  pure = false,
) {
  return getVerificationStatusByType(
    VerificationTypeEnumType.Identity,
    account,
    pure,
  );
}

export function getAccreditationVerificationStatus(
  account?: NullableAccount,
  pure = false,
) {
  return getVerificationStatusByType(
    VerificationTypeEnumType.Accreditation,
    account,
    pure,
  );
}

// This one is up to be deleted once all investment level accreditations are expired
export function getAccreditationVerificationStatusForInvestmentWithLegacyChecks(
  investment: InvestmentModel,
) {
  // account-level verification
  const accountLevelAccreditation = getAccreditationVerificationStatus(
    investment?.account,
    true,
  );
  if (accountLevelAccreditation == VerificationStatusEnumType.Verified) {
    return VerificationStatusEnumType.Verified;
  }

  if (accountLevelAccreditation == VerificationStatusEnumType.NeedsReview) {
    return VerificationStatusEnumType.NeedsReview;
  }

  // investment-level verification (legacy)
  if (investment.overridedAccreditationAt) {
    if (investment.dsEnvelopeId) {
      return VerificationManualStatuses.ManuallyVerified;
    }
  } else {
    const accreditedAt = investment.account?.accreditationVerifiedAt;
    if (accreditedAt) {
      const isCurrentAccreditation = isCurrentVerifiedAt(
        investment?.account?.accreditationExpiresOn,
      );

      if (isCurrentAccreditation) {
        return VerificationStatusEnumType.Verified;
      }

      return VerificationManualStatuses.Expired;
    }

    if (investment.dsEnvelopeId) {
      return VerificationStatusEnumType.Pending;
    }
  }
  return accountLevelAccreditation || VerificationStatusEnumType.Unverified;
}

export function verificationIsUnverified(status?: VerificationStatusEnumType) {
  return status && status === VerificationStatusEnumType.Unverified;
}

export function isAccreditationManagedByParallelMarkets(
  verification: VerificationModel | null,
) {
  return verification?.verificationMethod === "Parallel Markets";
}

export function buildNameOfRecord(
  account: AccountModel,
  accountType: AccountTypeType,
) {
  switch (accountType) {
    case AccountTypeType.Individual:
      return `${account.firstName} ${account.lastName}`;
    case AccountTypeType.Joint:
      return `${account.individual1?.firstName || ""} ${
        account.individual1?.lastName || ""
      } and ${account.individual2?.firstName || ""} ${
        account.individual2?.lastName || ""
      }`;
    case AccountTypeType.Entity:
    case AccountTypeType.Trust:
      return `${account.name}`;
  }

  return "";
}

export const accountTypesThatAreCustodied = [
  AccountTypeType.CustodiedAccount,
  AccountTypeType.Brokerage,
  AccountTypeType.Retirement,
];

export const accountTypes = [
  ...accountTypesThatAreCustodied,
  "Entity",
  "Joint",
  "Individual",
  "Trust",
] as const;

export const isCustodiedAccountType = (
  accountType: Nullable<AccountTypeType | undefined>,
) => {
  if (!accountType) {
    return false;
  }
  return accountTypesThatAreCustodied.includes(accountType);
};
