/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const MediaItemsJsonApiModel = {
  id: "",
  author: "",
  title: "",
  description: "",
  url: "",
  "cover-img-url": "",
  "sort-order": "",
};
