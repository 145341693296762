import React from "react";
import { Icon, Tag, TagLabel } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { CloseIcon } from "./Icons";

const Chip = ({
  label,
  onClickClose,
  onClick,
  hasCancel,
  isFilled,
  isSmall,
  isBold,
  ...rest
}) => {
  return (
    <Tag
      py={isSmall ? 0.5 : null}
      px={isSmall ? 2 : 3}
      height="32px"
      border="1px solid"
      borderColor="border.input"
      rounded="full"
      variant="outline"
      boxShadow="none"
      onClick={onClick}
      backgroundColor={isFilled ? "border.grey" : "bg.white"}
      fontSize={14}
      fontFamily="Nunito Sans"
      fontWeight={isBold ? "bold" : 400}
      color="ink"
      h={isSmall && "24px"}
      data-testid="chip-tag"
      {...rest}
    >
      <TagLabel>{label}</TagLabel>
      {(onClickClose || hasCancel) && (
        <Icon
          cursor="pointer"
          as={CloseIcon}
          pl="8px"
          boxSize="24px"
          color="icons.grey"
          onClick={onClickClose}
          data-testid="close-icon"
        />
      )}
    </Tag>
  );
};

Chip.propTypes = {
  /**
   * Label of the chip
   */
  label: PropTypes.string.isRequired,
};

export default Chip;
