import { UseGetFeatureToggleProps } from "./FeatureToggleTypes";
import { useGetFeatureToggles } from "./useGetFeatureToggles";

export const useFeatureToggle = (props: UseGetFeatureToggleProps) => {
  const getIsFeatureAvailable = useGetFeatureToggles();

  const isFeatureAvailable = getIsFeatureAvailable(props);

  return isFeatureAvailable;
};
