import { Box, Checkbox, Flex, Stack } from "@chakra-ui/react";
import React from "react";
import { usePopper } from "react-popper";
import { Dict } from "../../../../../defines";
import { ChevronDownIcon } from "../../../../../icons";
import { Chip } from "../../../../common";
import { FilterValueType } from "../../../VeriDrawer/VeriDrawer.types";
import { TagsDrawerFilterType } from "../../TagsDrawer.types";

const MultiSelect: React.FC<TagsDrawerFilterType> = ({
  options,
  onChange,
  value,
  filterRef,
}) => {
  const [rootElementRef, setRootElementRef] =
    React.useState<HTMLDivElement | null>(null);
  const [listElement, setListItem] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [triggerElRef, setTriggerElRef] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [selectedValues, setSelectedValues] = React.useState<Dict>({});
  const selectedValueNames = Object.keys(selectedValues);
  const selectedCount = selectedValueNames.length || 0;

  const _changeSelection = (newValues: any) => {
    setSelectedValues(newValues);
    onChange && onChange(Object.keys(newValues));
  };

  const { styles, attributes, update } = usePopper(triggerElRef, listElement, {
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 6],
        },
      },
    ],
  });

  const filters = options.options || [];

  const removeValue = (value: any) => {
    const newValues = { ...selectedValues };
    if (newValues[value]) delete newValues[value];
    _changeSelection(newValues);
  };

  const toggleSelectedValue = (value: any) => {
    const newValues = { ...selectedValues };

    if (newValues[value]) delete newValues[value];
    else newValues[value] = true;

    _changeSelection(newValues);
  };

  React.useEffect(() => {
    update && update();
  }, [selectedCount]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const onOutsideClick = (event: any) => {
      if (rootElementRef && !rootElementRef.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      window.document.addEventListener("mousedown", onOutsideClick);
    }

    return () => {
      window.document.removeEventListener("mousedown", onOutsideClick);
    };
  }, [rootElementRef, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (Array.isArray(value)) {
      const selectedValues: Dict = {};
      value.forEach((val) => (selectedValues[val] = true));
      setSelectedValues(selectedValues);
    } else {
      setSelectedValues([]);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const refObject = {
      valueType: FilterValueType.ARRAY,
      getValueLabel: (value: any) => {
        return (filters.find((opt: any) => opt.value === value) || {}).label;
      },
    };

    if (typeof filterRef === "function") {
      filterRef(refObject);
    }
  }, [filters]);

  return (
    <Box position={"relative"} ref={setRootElementRef}>
      <Box
        px={4}
        py={3}
        borderWidth={1}
        borderStyle={"solid"}
        borderColor={isOpen ? "brand.main" : "border.grey"}
        borderRadius={4}
        cursor={"pointer"}
        ref={setTriggerElRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Flex>
          <Box flexGrow={1} textStyle={"bodyRegular"} color={"text.grey"}>
            {!(selectedCount > 0) && "Select"}
            {selectedCount > 0 &&
              filters.map(
                (opt: any, index: number) =>
                  selectedValues[opt.value] && (
                    <Chip
                      key={`${opt.value}-${index}`}
                      label={opt.label}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        removeValue(opt.value);
                      }}
                      backgroundColor={"border.grey"}
                      py={0.5}
                      px={2}
                      mr={1}
                      mb={1}
                      textStyle={"bodyRegular"}
                      color={"text.black"}
                      hasCancel
                    />
                  ),
              )}
          </Box>
          <Box>
            <ChevronDownIcon color={"text.grey"} />
          </Box>
        </Flex>
      </Box>
      {isOpen && (
        <Box
          ref={setListItem}
          style={styles.popper}
          {...attributes.popper}
          background={"bg.white"}
          left={0}
          right={0}
          w={triggerElRef?.offsetWidth}
          boxShadow="0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)"
          borderWidth={1}
          borderStyle={"solid"}
          borderColor={"border.grey"}
          p={4}
          px={0}
          zIndex={"dropdown"}
          maxH={"300px"}
          overflow={"auto"}
        >
          <Stack spacing={5}>
            {filters.map((opt: any, index: number) =>
              opt.isDivider ? (
                <Box borderTop={"1px solid"} borderTopColor={"border.grey"} />
              ) : (
                <Checkbox
                  px={4}
                  onChange={() =>
                    /* Timeout is to prevent outside click to be triggered due to React re-rendering */
                    setTimeout(() => toggleSelectedValue(opt.value), 1)
                  }
                  key={`${opt.value}-${index}-${selectedValues[opt.value]}`}
                  value={opt.value}
                  isChecked={selectedValues[opt.value] === true}
                >
                  {opt.label}
                </Checkbox>
              ),
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default MultiSelect;
