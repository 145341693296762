/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const HoldingsActivityJsonApiModel = {
  id: "",
  "effective-date": "",
  "allocation-groups": {
    jsonApi: "hasMany",
    type: "allocation-groups",
  },
  contributions: {
    jsonApi: "hasMany",
    type: "contributions",
  },
  "distribution-sets": {
    jsonApi: "hasMany",
    type: "distribution-sets",
  },
  exchanges: {
    jsonApi: "hasMany",
    type: "exchanges",
  },
  redemptions: {
    jsonApi: "hasMany",
    type: "redemptions",
  },
  transfers: {
    jsonApi: "hasMany",
    type: "transfers",
  },
  "holding-rate-updates": {
    jsonApi: "hasMany",
    type: "holding-rate-updates",
  },
  "security-rate-updates": {
    jsonApi: "hasMany",
    type: "security-rate-updates",
  },
  "unit-price-updates": {
    jsonApi: "hasMany",
    type: "unit-price-updates",
  },
};
