import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import VeriHeading from "../VeriHeading";
import { BackButton } from "./index";
import MessageBox from "./MessageBox";

const VeriDrawer = ({
  children,
  overlayProps,
  drawerContentProps,
  drawerBodyProps,
  footer,
  drawerFooterProps,
  primaryButton,
  secondaryButton,
  title,
  noHeaderShadow,
  variant,
  modalAsDrawer,
  noAnimation,
  backTitle = "Back",
  backAction,
  message,
  messageType = "error",
  messageTitle,
  ...rest
}) => {
  const size = useBreakpointValue({ base: "full", md: "half" });

  const BaseComponent = modalAsDrawer ? Modal : Drawer;
  const OverlayComponent = modalAsDrawer ? ModalOverlay : DrawerOverlay;
  const ContentComponent = modalAsDrawer ? ModalContent : DrawerContent;
  const HeaderComponent = modalAsDrawer ? DrawerHeader : DrawerHeader;
  const BodyComponent = modalAsDrawer ? DrawerBody : DrawerBody;
  const FooterComponent = modalAsDrawer ? DrawerFooter : DrawerFooter;

  const extraContentProps = modalAsDrawer
    ? {
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        margin: 0,
        borderRadius: 0,
      }
    : {};

  if (variant === "form" || variant === "form-alt" || variant === "view-alt") {
    return (
      <BaseComponent
        motionPreset={noAnimation ? "none" : "slideInRight"}
        scrollBehavior={"inside"}
        {...rest}
      >
        <OverlayComponent
          animation={() => ({ opacity: 200 })}
          {...overlayProps}
        />
        <ContentComponent
          {...(modalAsDrawer && { maxH: "auto" })}
          {...extraContentProps}
          {...drawerContentProps}
        >
          <Box p={8} pb={0}>
            <DrawerCloseButton
              position={variant === "form" ? "static" : "absolute"}
              mt={variant === "form-alt" || variant === "view-alt" ? 8 : 0}
              mr={variant === "form-alt" || variant === "view-alt" ? 8 : 0}
            />
            {backAction && (
              <Box mb={variant === "form" ? 0 : 8}>
                <BackButton onClick={backAction} title={backTitle} my={0} />
              </Box>
            )}
            {message && (
              <MessageBox
                type={messageType}
                title={messageTitle}
                text={message}
                mb={6}
                filled
              />
            )}
            {title && (
              <VeriHeading mt={variant === "form" ? 8 : 0} textStyle={"h3"}>
                {title}
              </VeriHeading>
            )}
          </Box>
          <BodyComponent
            mt={variant === "form" ? 6 : 0}
            px={8}
            {...drawerBodyProps}
          >
            {children}
          </BodyComponent>
          {variant !== "view-alt" && (
            <FooterComponent
              boxShadow={
                "0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24)"
              }
            >
              {(primaryButton || secondaryButton) && (
                <Flex w="100%">
                  <Box mr={4}>{primaryButton && primaryButton(size)}</Box>
                  <Box>{secondaryButton && secondaryButton(size)}</Box>
                </Flex>
              )}
              {footer}
            </FooterComponent>
          )}
        </ContentComponent>
      </BaseComponent>
    );
  }

  return (
    <BaseComponent motionPreset={noAnimation && "none"} {...rest}>
      <OverlayComponent {...overlayProps} />
      <ContentComponent {...drawerContentProps}>
        {size === "full" && (
          <Flex justifyContent="space-between" boxShadow="mobileHeader">
            <Box p={2}>
              <DrawerCloseButton position="static" />
            </Box>
            <FooterComponent p={2} flexGrow="1" textAlign="center">
              <Text textStyle="h5">{title}</Text>
            </FooterComponent>
            {secondaryButton && <Box p={2}>{secondaryButton(size)}</Box>}
          </Flex>
        )}
        {size === "half" && (
          <>
            <DrawerCloseButton />
            <HeaderComponent boxShadow={!noHeaderShadow && "mobileHeader"}>
              <Text textStyle="h5">{title}</Text>
            </HeaderComponent>
          </>
        )}
        <BodyComponent {...drawerBodyProps}>{children}</BodyComponent>
        <FooterComponent boxShadow="mobileHeader" {...drawerFooterProps}>
          {footer}
          {size === "half" && (
            <Flex justifyContent="space-between" w="100%">
              {secondaryButton && (
                <Box>{secondaryButton && secondaryButton(size)}</Box>
              )}
              {primaryButton && (
                <Box>{primaryButton && primaryButton(size)}</Box>
              )}
            </Flex>
          )}
          {size === "full" && (
            <Box w="100%">{primaryButton && primaryButton(size)}</Box>
          )}
        </FooterComponent>
      </ContentComponent>
    </BaseComponent>
  );
};

VeriDrawer.propTypes = {
  primaryButton: PropTypes.func,
  secondaryButton: PropTypes.func,
};

export default VeriDrawer;
