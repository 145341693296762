import { Box } from "@chakra-ui/react";
import React from "react";
import { customTheme } from "./Theme";

const VeriHeading = ({ children, as = "h2", ...rest }) => (
  <Box
    fontFamily={
      (rest.textStyle &&
        customTheme.textStyles[rest.textStyle] &&
        customTheme.textStyles[rest.textStyle].fontFamily) ||
      "heading"
    }
    fontWeight={
      (rest.textStyle &&
        customTheme.textStyles[rest.textStyle] &&
        customTheme.textStyles[rest.textStyle].fontWeight) ||
      "bold"
    }
    as={as}
    {...rest}
  >
    {children}
  </Box>
);

export default VeriHeading;
