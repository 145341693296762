import { useContext } from "react";
import { AuthContext } from "../auth/AuthContext";

function useDynamicConfig() {
  const { dynamicConfig } = useContext(AuthContext);

  return dynamicConfig;
}

export * from "../utils/DynamicConfig";
export default useDynamicConfig;
