/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const OpportunityTimelineItemJsonApiModel = {
  id: "",
  opportunity: {
    jsonApi: "hasOne",
    type: "opportunities",
  },
  "opportunity-id": "",
  section: {
    jsonApi: "hasOne",
    type: "opportunity-timeline-sections",
  },
  "section-id": "",
  item: "",
  "created-at": "",
  "updated-at": "",
};
