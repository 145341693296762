/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WorkflowJobExecutionSummaryJsonApiModel = {
  id: "",
  status: "",
  outputs: "",
};
