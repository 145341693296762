import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { VerivestTheme } from "../../../themes";
import { AdminTheme } from "../../../themes/VerivestTheme";

const Theme: React.FC<{
  isDashboard?: boolean;
  isAuth?: boolean;
  isAdmin?: boolean;
}> = (props) => {
  return (
    <ChakraProvider
      theme={{
        ...VerivestTheme,
        colors: {
          ...(props.isAdmin ? AdminTheme.colors : VerivestTheme.colors),
          ...(props.isDashboard || props.isAuth
            ? { background: "#f7f8f9" }
            : undefined),
        },
      }}
    >
      {props.children}
    </ChakraProvider>
  );
};

export default Theme;
