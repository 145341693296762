import { ApiModel, ApiModelOptions } from "../../../ApiModel";
import { OfferingModel } from "../../../models";
import { ModelScope } from "../../../types";

export default function apiOfferingModel(opts: ApiModelOptions<OfferingModel>) {
  const model = ApiModel<OfferingModel>("offering", {
    usePlacement: true,
    urls: {
      [ModelScope.Manager]: {
        getMany: "/manager/offerings",
      },
    },
    ...opts,
  });

  const retitleOffering = async (
    offeringId: string | number,
    fromAccountId: string | number,
    toAccountId: string | number,
  ) => {
    return await model.placementsApiClient.rpcRequest("retitle", {
      offering_id: Number(offeringId),
      from_account_id: Number(fromAccountId),
      to_account_id: Number(toAccountId),
    });
  };

  return { ...model, retitleOffering };
}
