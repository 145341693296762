import LogRocket from "logrocket";
import { getLogrocketFuzzySanitizer } from "./getLogrocketFuzzySanitizer";
import { getRootHostname } from "./getRootHostname";
import { LogRocketInitializationProps } from "./logRocketTypes";

export const initializeLogRocket = ({
  extendedSanitizers = {},
  logrocketAppId,
}: LogRocketInitializationProps) => {
  let isLogrocketInitialized = false;

  if (!logrocketAppId) {
    return isLogrocketInitialized;
  }

  const networkSanitizers = getLogrocketFuzzySanitizer();
  const { network, ...restSanitizers } = extendedSanitizers;

  LogRocket.init(logrocketAppId, {
    rootHostname: getRootHostname(),
    network: {
      requestSanitizer: (request) => {
        const sanitizedAttributesRequest = networkSanitizers.requestSanitizer(
          request as never,
        );

        if (network?.requestSanitizer) {
          return network?.requestSanitizer(sanitizedAttributesRequest);
        }

        return sanitizedAttributesRequest;
      },
      responseSanitizer: (response) => {
        const sanitizedAttributesResponse = networkSanitizers.responseSanitizer(
          response as never,
        );

        if (network?.responseSanitizer) {
          return network?.responseSanitizer(sanitizedAttributesResponse);
        }

        return sanitizedAttributesResponse;
      },
    },
    ...restSanitizers,
  });

  isLogrocketInitialized = true;
  return isLogrocketInitialized;
};
