/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const LockIcon = createIcon({
  displayName: "LockIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M19 11H5C3.89543 11 3 11.8954 3 13V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V13C21 11.8954 20.1046 11 19 11Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 19C13.1046 19 14 18.1046 14 17C14 15.8954 13.1046 15 12 15C10.8954 15 10 15.8954 10 17C10 18.1046 10.8954 19 12 19Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M17 7V6C17.0131 4.68724 16.5042 3.42303 15.5853 2.48539C14.6664 1.54776 13.4128 1.01346 12.1 1H12C10.6872 0.986939 9.42303 1.4958 8.48539 2.41469C7.54776 3.33357 7.01346 4.58724 7 5.9V7" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default LockIcon;