/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/

export enum CustodianTypeEnumType {
  System = "System",
  User = "User",
}
