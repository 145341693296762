/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const UnverifiedBadgeIcon = createIcon({
  displayName: "UnverifiedBadgeIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6.74228 4.73792L8.59987 1.5L11.9999 3.02464V3.02893L12.0001 3.02464L15.4 1.5L17.2577 4.73792L20.8994 5.51145L20.5095 9.22565L22.9999 12L20.5095 14.7743L20.8994 18.4886L17.2577 19.2621L15.4001 22.5L12.0001 20.9754L11.9999 20.9711V20.9754L8.59991 22.5L6.74228 19.2621L3.10053 18.4886L3.49047 14.7743L1 12L3.49047 9.22565L3.10053 5.51149L6.74228 4.73792Z" fill="currentColor" /><path d="M11.6877 17.1538C11.6877 17.3875 11.4982 17.5769 11.2646 17.5769C11.0309 17.5769 10.8415 17.3875 10.8415 17.1538C10.8415 16.9202 11.0309 16.7307 11.2646 16.7307C11.4982 16.7307 11.6877 16.9202 11.6877 17.1538Z" fill="currentColor" stroke="currentColor" strokeWidth={0.84617} /><path d="M9.44785 7.47366C11.1233 6.73326 13.2911 6.82126 14.0315 8.01944C14.7719 9.21761 14.2609 10.6113 12.9916 11.6867C11.7224 12.7622 11.2646 13.3461 11.2646 14.1922" stroke="currentColor" strokeWidth={1.69234} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default UnverifiedBadgeIcon;