/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const TrashIcon = createIcon({
  displayName: "TrashIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M20 9L19.133 21.142C19.0971 21.6466 18.8713 22.1188 18.5011 22.4636C18.1309 22.8083 17.6439 23 17.138 23H6.862C6.35614 23 5.86907 22.8083 5.49889 22.4636C5.1287 22.1188 4.90292 21.6466 4.867 21.142L4 9" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M1 5H23" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M8 5V1H16V5" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} /></g>)
});
export default TrashIcon;