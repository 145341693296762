import { Flex, Text } from "@chakra-ui/react";
import { investorPortalRoutes } from "@verivest/shared-components";
import Button from "@verivest/shared-components/src/buttons/Button";
import {
  ClearErrorOnLocationChange,
  ErrorBoundary,
} from "@verivest/shared-components/src/error-handling/error-boundary";
import { genericErrorMessage } from "@verivest/shared-components/src/error-handling/error-boundary/genericErrorMessage";
import React from "react";

export const InvestorPortalErrorBoundary: React.FC = ({ children }) => (
  <ErrorBoundary
    fallback={(onClearError) => (
      <Flex
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        direction="column"
        margin={8}
      >
        <Text m={4} variant="h2" maxW="400px" textAlign="center">
          {genericErrorMessage}
        </Text>
        <Button onClick={onClearError} to={investorPortalRoutes.home}>
          Go to the homepage
        </Button>
        <ClearErrorOnLocationChange onClearError={onClearError} />
      </Flex>
    )}
  >
    {children}
  </ErrorBoundary>
);
