/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetHoldingOutputJsonApiModel = {
  id: "",
  holding: {
    jsonApi: "hasOne",
    type: "holdings",
  },
};
