/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const MetropolitanStatisticalAreaJsonApiModel = {
  id: "",
  name: "",
  "created-at": "",
  "updated-at": "",
};
