import { isClient } from "@verivest/shared-components";
import {
  AccountTypeType,
  VerificationStatusEnumType,
} from "@verivest/shared-components/src/api/enums";
import { authRoutes } from "@verivest/shared-components/src/defines/routes/auth";
import { logError } from "@verivest/shared-components/src/error-handling/logError";
import { AllHtmlEntities } from "html-entities";
import numbro from "numbro";
import qs from "qs";
import React from "react";

const allHtmlEntities = new AllHtmlEntities();

export function getProperty(object, propertyName) {
  let parts = propertyName.split(/\.|\[|\]/).filter(Boolean), // split path by '.' or '[' or ']' and remove empty elements
    length = parts.length,
    i,
    property = Array.isArray(object) ? [...object] : { ...object };

  for (i = 0; i < length; i++) {
    if (!property[parts[i]]) return undefined;
    property = property[parts[i]];
  }

  return property;
}

export function startEndDateEmployee(startDate, endDate) {
  if (!startDate) return "";
  let finalString = dateToString(new Date(startDate * 1000)) + " - ";
  if (!endDate) return finalString + "Present";

  return finalString + dateToString(new Date(endDate * 1000));
}

export function stringDateToIsoString(date) {
  return new Date(convertToSqlDate(date, "/")).toISOString();
}

export function sqlDateToIsoDate(date) {
  return new Date(date).toISOString();
}

export function sqlDateToFullYear(date) {
  return new Date(date).getUTCFullYear();
}

export function formatDateDDMMYYYY(date) {
  let d = date,
    month = "" + (d.getUTCMonth() + 1),
    day = "" + d.getUTCDate(),
    year = d.getUTCFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
}

export function isoDateToMMDDYYYY(date) {
  return formatDateMMDDYYYY(new Date(date));
}

export function formatDateMMDDYYYY(date) {
  if (!date) return;
  return formatDateDDMMYYYY(typeof date === "string" ? dateToDate(date) : date);
}

export function dateToYYYYMMDD(date) {
  if (!date) return "";

  if (typeof date !== "string" && !isNaN(date) && date.toISOString) {
    return date.toISOString().split("T")[0];
  }

  return new Date(date).toISOString().split("T")[0];
}

export function isInTheFuture(date) {
  const today = new Date();
  today.setHours(23, 59, 59, 998);
  return date > today;
}

export function dateToTime(date) {
  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export function dateToString(date, days) {
  if (days) {
    date.setDate(date.getDate() + days);
  }

  // return formatDateDDMMYYYY(date);
  const splitted = date
    .toISOString()
    .replace("T", " ")
    .replace(/-/g, ".")
    .substr(0, 10)
    .split(".");

  return [splitted[1], splitted[2], splitted[0]].join("/");
  // return date.toISOString().replace("T", " ").replace(/-/g, ".").substr(0, 10);
  // return (
  //   (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
  //   "." +
  //   (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
  //   "." +
  //   date.getFullYear()
  // );
}

// This function converts a date string from the format "MM/DD/YYYY" to "Mon DD, YYYY".
// For example, it will convert "10/01/2023" to "Oct 01, 2023".
export function formatDate(dateString) {
  // Array of month names to convert month number to month name.
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Split the input date string into parts: month, day, and year.
  const dateParts = dateString.split("/");

  // Extract the day and year, and convert the month part to an index.
  const day = dateParts[1];
  const monthIndex = parseInt(dateParts[0], 10) - 1;
  const year = dateParts[2];

  // Format and return the new date string.
  return `${months[monthIndex]} ${day.padStart(2, "0")}, ${year}`;
}

export function dateToTimeMonYYYYUTC(_date, useDay) {
  const date = typeof _date === "object" ? _date : dateToDate(_date);

  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return (
    hours + ":" + minutes + " " + ampm + " on " + dateToMonYYYYUTC(date, useDay)
  );
}

export function dateToMonYYYYUTC(_date, useDay) {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = typeof _date === "object" ? _date : dateToDate(_date);

  let monthIndex = date.getUTCMonth();
  let monthName = monthNames[monthIndex];
  const day = useDay ? ` ${date.getUTCDate()},` : "";

  let year = date.getUTCFullYear();

  if (!monthName) return null;

  return `${monthName}${day} ${year}`;
}

export function dateToMonYYYY(date, useDay) {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthIndex = date.getUTCMonth();
  let monthName = monthNames[monthIndex];
  const day = useDay ? ` ${date.getUTCDate()},` : "";

  let year = date.getUTCFullYear();

  return `${monthName}${day} ${year}`;
}

export function dateToYYYY(date) {
  return date.getUTCFullYear();
}

export function getURLParams(url) {
  return qs.parse((url || "").replace(/([\s\S]*?\?)/, ""));
}

export function getURLParamsQuery(url) {
  const params = getURLParams(url);
  return qs.stringify(fixObjectArray(params || {}), {
    arrayFormat: "comma",
  });
}

function getURLParamsFilter(url) {
  const queryParams = (url || "").replace(/([\s\S]*?\?)/, "");
  const params = qs.parse(queryParams);

  return params.filter || {};
}

export function getURLParamsFilterToQuery(url) {
  const params = getURLParamsFilter(url);
  return qs.stringify(fixObjectArray(params), { arrayFormat: "comma" });
}

export function getUrlParamsFilterToQueryObject(url) {
  const filterList = fixObjectArray(getURLParamsFilter(url));
  return Object.keys(filterList || {}).reduce(
    (obj, cur) => ({
      ...obj,
      [cur]: Array.isArray(filterList[cur])
        ? filterList[cur].join(",")
        : filterList[cur],
    }),
    {},
  );
}

export function getURLParamsFilterServer(params) {
  return qs.stringify(fixObjectArray(params.filter || {}), {
    arrayFormat: "comma",
  });
}

function fixObjectArray(obj) {
  let newO = {};
  Object.keys(obj).map(
    (k) =>
      (newO[k] =
        (typeof obj[k] === "string" &&
          (obj[k].lastIndexOf(",") !== -1 ? obj[k].split(",") : obj[k])) ||
        obj[k]),
  );
  return newO;
}
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const formatterWithDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const baseUrl = process.env.RAZZLE_APP_BASE_URL;

export function getManagerLoginURL(id, url, email = "") {
  return `${buildRouteUrl(
    authRoutes.GET_STARTED,
  )}?manager_id=${id}&email=${encodeURIComponent(
    email,
  )}&callback_url=${url}/login`;
}

export function getLoginURL(email = "", callbackUrl, backTo) {
  return `${buildRouteUrl(authRoutes.GET_STARTED)}?email=${email}${
    backTo ? `&back_to=${encodeURIComponent(backTo)}` : ""
  }&callback_url=${callbackUrl || baseUrl}/login`;
}

export const formatToMoney = (money, useDecimals = false) => {
  if (useDecimals) return formatterWithDecimals.format(money);
  return formatter.format(money);
};

export function formatMoneyWithSymbol(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ",",
) {
  if (amount < 0) {
    return `-$${formatMoney(
      Math.abs(amount),
      decimalCount,
      decimal,
      thousands,
    )}`;
  }

  return "$" + formatMoney(amount, decimalCount, decimal, thousands);
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ",",
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    logError(e);
  }
}

export const statusToWeight = (status) => {
  switch (status) {
    case "Open":
      return 0;
    case "Opening Soon":
      return 1;
    case "Funded":
      return 2;
    case "Full Cycle":
      return 3;
    default:
      return undefined;
  }
};

function writeCookie(name, value, days) {
  var date, expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

export const prepareAuthUrl = () => {
  writeCookie(
    "redirect_login",
    window.location.pathname + window.location.search,
    1,
  );
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const statusToColor = (status) => {
  switch (status) {
    case "Draft":
      return "opportunity.draft";
    case "Open":
    case "Active":
      return "opportunity.open";
    case "Coming Soon":
    case "Opening Soon":
      return "opportunity.soon";
    case "Funded":
      return "opportunity.funded";
    case "Full Cycle":
    case "Realized":
      return "opportunity.full";
    case "Monitoring":
      return "opportunity.monitoring";
    default:
      return undefined;
  }
};

export const htmlToText = (html) => {
  const strippedText = html.replace(/(<([^>]+)>)/gi, "");
  return allHtmlEntities.decode(strippedText);
};

export function convertToSqlDate(string, separator) {
  if (!string || !string.includes(separator || "-")) {
    return null;
  }

  // Split timestamp into [ Y, M, D]
  const t = string.split(separator || "-");

  return t[2] + "-" + t[0] + "-" + t[1];
}

export function isValidInputDateDob(dateString) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;
  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export function isValidInputDate(dateString) {
  // First check for the pattern
  if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) return false;
  // Parse the date parts to integers
  var parts = dateString.split("-");
  var day = parseInt(parts[2], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export function getFormError(errors, fieldName) {
  const e = getProperty(errors || {}, fieldName);
  return e && e.message;
}

export function addressToString(address = {}) {
  if (!address) return;
  return `${address.address1}${
    address.address2 ? ", " + address.address2 : ""
  }, ${address.city}, ${address.region} ${address.postalCode}`;
}

export function dateToDate(date) {
  return new Date(date);
}

export function timestampToISODate(ts) {
  if (!ts) return null;
  return new Date(ts * 1000).toISOString().split("T")[0];
}
export function timestampToDate(ts) {
  return new Date(ts * 1000);
}

// One year in ms
export function isCurrentAccreditedAt(accreditedAt) {
  if (!accreditedAt) {
    return false;
  }

  const oldDate = new Date(accreditedAt);
  const todayDate = new Date();
  return (todayDate - oldDate) / (1000 * 3600 * 24 * 365) < 1;
}

export function accreditationStatus(accreditedAt) {
  let status = "Unverified";
  if (accreditedAt) {
    status = isCurrentAccreditedAt(accreditedAt) ? "Verified" : "Expired";
  }
  return status;
}

export function getSignerEmailFromAccount(account) {
  let a = account;
  if (
    account.accountType === AccountTypeType.CustodiedAccount ||
    account.accountType === AccountTypeType.Retirement ||
    account.accountType === AccountTypeType.Brokerage
  ) {
    a = account.custodiedOwner;
  }

  //to do after adding back email
  switch (a.accountType) {
    case "Individual":
      return a.email;
    case "Entity":
    case "Trust":
      return a.signers[0].email;
    case "Joint":
      return a.individual1.email;
    default:
      return false;
  }
}
export function formatNumber(x, decimals = 3) {
  return new Intl.NumberFormat("en", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(x);
}

export const buildRouteUrl = (routeUrl, params) => {
  const paramKeys = Object.keys(params || {});
  return (
    "/" +
    routeUrl
      .replace("?", "")
      .split("/")
      .map((part) =>
        part.substr(0, 1) === ":"
          ? paramKeys.includes(part.substr(1, part.length - 1))
            ? params[part.substr(1, part.length - 1)]
            : ""
          : part,
      )
      .filter((part) => part !== "")
      .join("/")
  );
};

export const getHostNameFromURL = (url) => {
  if (!url) return "";
  return new URL(
    `${
      url.indexOf("http://") !== 0 && url.indexOf("https://") !== 0
        ? "https://"
        : ""
    }${url}`,
  ).hostname;
};

export const replaceImage = (_i, size = "350xAUTO") => {
  const i = String(_i || "");
  if (!i.includes("cdn.verivest.com") && !i.includes("cdn.testverivest.com"))
    return i;
  const tokens = i.split("/");
  return (
    tokens.slice(0, tokens.length - 1).join("/") +
    `/${size}/` +
    encodeURIComponent(tokens[tokens.length - 1])
  );
};

export const isValidSocialMediaLink = (str) => {
  const re = /^(?:https?:\/\/)?(www\.)?[\w]+\.[\d|\w|-]+(\/[\d|\w\-.]+)+\/?/g;

  return str.match(re);
};

export const stripHtml = (html, brToNl) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = brToNl ? html.replace(/<br( \/)?>/, "") : html;
  return tmp.textContent || tmp.innerText || "";
};

export const getAttributes = (attributes, type, primary, onlyValue) =>
  attributes
    .filter(
      (attr) =>
        attr.attributeType === type &&
        (primary
          ? attr[primary] === true
          : !attr.isPrimaryStrategy && !attr.isPrimaryPropertyType),
    )
    .map((attr) =>
      onlyValue
        ? attr.attributeKey
        : {
            value: attr.attributeKey,
            label: attr.name,
            isPrimaryStrategy: attr.isPrimaryStrategy,
            isPrimaryPropertyType: attr.isPrimaryPropertyType,
          },
    ) || [];

export const toastSuccess = (toast, message, opts) => {
  toast({
    title: message,
    status: "success",
    duration: 3000,
    isClosable: true,
    ...opts,
  });
};

export const toastError = (toast, message, opts) => {
  toast({
    title: message,
    status: "error",
    duration: 3000,
    isClosable: true,
    ...opts,
  });
};

export function getStatementFilename(offering, statement) {
  let date =
    (statement &&
      statement.statementSet &&
      formatDateMMDDYYYY(dateToDate(statement.statementSet.endDate))) ||
    "";
  let mnumber =
    (statement && statement.account && statement.account.mnumber) || "";
  let ticker = (offering && offering.ticker) || "";
  return `Statement ${ticker} ${mnumber} ${date}.pdf`;
}

function getInvestmentIdentityAccreditationStatus(account) {
  if (!account) {
    return null;
  }

  let status;
  if (account.accreditedAt) {
    // check for expired accreditation
    const isCurrentAccreditation = isCurrentAccreditedAt(account.accreditedAt);
    if (isCurrentAccreditation) {
      status = "Verified";
    } else {
      status = "Expired";
    }
  } else {
    const verification = account.verification;
    if (verification) {
      status = verification.status;
    }
  }

  return status;
}

export function getInvestmentAccreditationStatus(investment) {
  if (!investment) {
    return null;
  }

  // check for manager accreditation override
  if (investment.overridedAccreditationAt) {
    return "Verified";
  }

  // check for account level accreditation
  if (
    investment.account?.accreditationStatus ===
    VerificationStatusEnumType.Verified
  ) {
    return "Verified";
  }

  return getInvestmentIdentityAccreditationStatus(investment.account);
}

export function isAccredited(account) {
  if (!account) {
    return false;
  }
  if (account.accreditedAt) {
    return isCurrentAccreditedAt(account.accreditedAt);
  }

  return false;
}

export function calcOfferingAmountRemaining(investment, _investmentTotal) {
  let offeringAmountRemaining = 0;
  if (investment !== null && investment.offering?.offeringMaxAmount !== null) {
    const maxAmount = parseFloat(investment.offering.offeringMaxAmount || 0);
    const investmentTotal = parseFloat(
      _investmentTotal || investment.offering.investmentTotal || 0,
    );
    const amountRemaining = maxAmount - investmentTotal;
    offeringAmountRemaining = amountRemaining > 0 ? amountRemaining : 0;
  }
  return offeringAmountRemaining;
}

export function sortSortOrder(a, b) {
  return parseInt(a.sortOrder) - parseInt(b.sortOrder);
}

export function getTINEINSSNValue(account) {
  switch (account.accountType) {
    case "Joint":
      return account?.individual1?.taxIdNumber;
    case "Individual":
    case "Trust":
    case "Entity":
      return account?.taxIdNumber;
    case "Retirement":
    case "Brokerage":
    case "Custodied Account":
      return account?.custodian?.ein;
    default:
      return "";
  }
}

export function getTINEINSSNName(account) {
  switch (account.accountType) {
    case "Individual":
    case "Joint":
      return "TIN";
    case "Trust":
    case "Entity":
      return account?.taxIdType;
    case "Retirement":
    case "Brokerage":
    case "Custodied Account":
      return "Custodian EIN";
    default:
      return "";
  }
}

export function getMimeTypesByType(type) {
  switch (type) {
    case "image":
    case "images":
      return ["image/png", "image/jpeg", "image/jpg", "image/gif"];
    case "excel":
      return [
        "application/vnd.ms-excel",
        "application/vnd.ms-excel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
        "application/vnd.ms-excel.template.macroEnabled.12",
        "application/vnd.ms-excel.addin.macroEnabled.12",
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
        "text/csv",
      ];
    default:
      return [
        // Word
        "application/msword",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
        "application/vnd.ms-word.document.macroEnabled.12",
        "application/vnd.ms-word.template.macroEnabled.12",

        // Excel
        "application/vnd.ms-excel",
        "application/vnd.ms-excel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
        "application/vnd.ms-excel.sheet.macroEnabled.12",
        "application/vnd.ms-excel.template.macroEnabled.12",
        "application/vnd.ms-excel.addin.macroEnabled.12",
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12",

        // Powerpoint
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.presentationml.template",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
        "application/vnd.ms-powerpoint.addin.macroEnabled.12",
        "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
        "application/vnd.ms-powerpoint.template.macroEnabled.12",
        "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",

        // CSV
        "text/csv",

        // PDF
        "application/pdf",

        // IMages
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
      ];
  }
}

export function stringToPlural(word, total, suffix = "s") {
  if (total > 1) return word + suffix;

  return word;
}

export function isSponsorVerified(manager) {
  return !!(manager && manager.verificationStatus !== "Unverified");
}

export function isTrackRecordPending(manager, records) {
  if (records && records.length > 0) {
    return records.some((record) => record.status === "Pending");
  }

  return false;

  /*return !!(
    manager &&
    ( ||
      manager.trackRecordStatus === "Ready for Review" ||
      manager.trackRecordStatus === "Review in Progress")
  );*/
}

export function hasTrackRecordUnreviewedRecord(records) {
  if (records && records.length > 0) {
    return records.some((record) => record.status === "Unreviewed");
  }

  return false;
}

export function managerHasTrackRecord(trackRecords) {
  return Array.isArray(trackRecords) && trackRecords.length > 0;
}

export function getPrincipalBackgroundCheckStatus(principal) {
  if (!principal) return {};

  const paymentRequired = !principal.stripeInvoicePaidAt;
  const hasVerifiedBackgroundChecks = principal.backgroundchecks?.length > 0;
  const pendingBackgroundChecks =
    !hasVerifiedBackgroundChecks && !paymentRequired;

  const isVerifiedPrincipal = !paymentRequired && hasVerifiedBackgroundChecks;

  return {
    paymentRequired,
    pendingBackgroundChecks,
    isVerifiedPrincipal,
  };
}

export function getNumericValue(value) {
  if (isNaN(numbro(value).value())) {
    return null;
  }
  return numbro(value).value();
}

export function copyToClipboard(text, toast, successMessage = "Link copied") {
  if (isClient()) {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          if (toast) toastSuccess(toast, successMessage);
        })
        .catch(() => {
          if (toast) toastError(toast, "Something went wrong");
        });
    }
  }
}
function isExpired(date) {
  return date <= new Date() || date === new Date(0);
}
export function isExpiredString(date) {
  const d = new Date(date);
  return isExpired(d);
}

export function getFeaturedImage(images) {
  if (!images || images.length === 0) return "/images/empty-card-sponsor.svg";

  return images.sort((a, b) => a.sortOrder - b.sortOrder)[0].url;
}

export function getChildWithProps(child, props) {
  if (React.isValidElement(child)) {
    return React.cloneElement(child, props);
  }
  return child;
}

export function scrollToTop(allowAnimation) {
  isClient() &&
    (allowAnimation
      ? window.scrollTo({ top: 0, behavior: "smooth" })
      : window.scrollTo(0, 0));
}

export function scrollToId(id) {
  const sectionElement = document.getElementById(id);

  if (sectionElement) {
    window.scrollTo({
      behavior: "smooth",
      top: sectionElement.offsetTop - 150,
    });
  }
}

export function splitArrayIntoChunksOfLen(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}
