/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const EmailJsonApiModel = {
  id: "",
  user: {
    jsonApi: "hasOne",
    type: "users",
  },
  email: "",
  "template-id": "",
  "template-data": "",
  "template-name": "",
  "template-type": "",
  delivered: "",
  clicked: "",
  opened: "",
  response: "",
  status: "",
  "created-at": "",
  "updated-at": "",
};
