import { Flex } from "@chakra-ui/react";
import React from "react";
import { VeriSpinner } from "../VeriSpinner";
import { LoadingBoxProps } from "./LoadingBox.types";

const LoadingBox: React.FC<LoadingBoxProps> = ({
  isLoading = true,
  text,
  spinnerProps: circularProgressProps,
  ...rest
}) => {
  if (!isLoading) return null;

  return (
    <Flex
      w={"100%"}
      p={4}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      {...rest}
    >
      <VeriSpinner {...circularProgressProps} />
      {text}
    </Flex>
  );
};

export default LoadingBox;
