import React from "react";
import { Box } from "@chakra-ui/react";
import { MfaStepsContainerProps } from "./MfaStepsContainer.types";
import {
  MfaAppTypeConfigureMobileApp,
  MfaAuthenticationTypeChooser,
  MfaDisableStep,
  MfaPhoneTypeSendVerificationCodeStep,
  MfaPhoneTypeVerifyVerificationCodeStep,
  MfaSuccessStep,
} from "../";
import { MfaModalStep } from "../../context/MfaModalContext/MfaModalContext.types";
import { useMfaModal } from "../../context/MfaModalContext/MfaModalContext";

const steps: Record<MfaModalStep, React.FC> = {
  [MfaModalStep.start]: MfaAuthenticationTypeChooser,
  [MfaModalStep.sendVerificationCodeToPhone]:
    MfaPhoneTypeSendVerificationCodeStep,
  [MfaModalStep.verifyPhoneVerificationCode]:
    MfaPhoneTypeVerifyVerificationCodeStep,
  [MfaModalStep.configureMobileApp]: MfaAppTypeConfigureMobileApp,
  [MfaModalStep.success]: MfaSuccessStep,
  [MfaModalStep.disable]: MfaDisableStep,
};

const MfaStepsContainer: React.FC<MfaStepsContainerProps> = ({ ...rest }) => {
  const { step } = useMfaModal();

  const StepComponent = steps[step];

  return (
    <Box {...rest}>
      <StepComponent />
    </Box>
  );
};

export default MfaStepsContainer;
