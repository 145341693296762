/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const WaterfallDefinitionRuleClassJsonApiModel = {
  id: "",
  "waterfall-definition-rule-id": "",
  "security-id": "",
  security: {
    jsonApi: "hasOne",
    type: "securities",
  },
  "catchup-from-security-id": "",
  "catchup-income-type": "",
  target: "",
  "interclass-split-basis": "",
  "include-self": "",
  "fee-basis": "",
};
