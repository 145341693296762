import React from "react";
import { Box } from "@chakra-ui/react";

const LabelAndValue = ({
  label,
  value,
  emptyValue = "-",
  labelProps = {},
  valueProps = {},
  children,
  labelAlt,
  ...rest
}) => {
  return (
    <Box mb="24px" {...rest}>
      <Box
        textStyle="labelSmall"
        color={labelAlt && "text.grey"}
        {...labelProps}
        mb="8px"
      >
        {label}
      </Box>
      <Box textStyle="bodyRegular" {...valueProps}>
        {children ? (
          children
        ) : (
          <>
            {value !== undefined && value !== null && value !== ""
              ? value
              : emptyValue}
          </>
        )}
      </Box>
    </Box>
  );
};

export default LabelAndValue;
