import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { AnimatedMobileMenuButtonProps } from "./AnimatedMobileMenuButton.types";
import { Button } from "../Button";

export const AnimatedMobileMenuButton: React.FC<
  AnimatedMobileMenuButtonProps
> = ({ isActive, width = 100, ...rest }) => {
  return (
    <Button empty {...rest}>
      <Box
        display={"block"}
        as={"span"}
        width={`${width}px`}
        height={`${width - 2}px`}
        overflow={"hidden"}
      >
        <Flex
          as={"span"}
          w={"100px"}
          flexDirection={"column"}
          css={{
            "& > span": {
              backgroundColor: "currentColor",
              width: "100%",
              height: "10px",
              margin: "10px 0",
              transition: ".4s  cubic-bezier(0.7, -0.5, 0.30, 1.5)",
              display: "block",
            },
            "&.active": {
              "& > span": {
                borderRadius: "10px",
              },
              "& > span:nth-of-type(1)": {
                transformOrigin: "bottom",
                transform: "rotatez(45deg) translate(11px,0px)",
                width: "50%",
              },
              "& > span:nth-of-type(2)": {
                transformOrigin: "top",
                transform: "rotatez(-45deg)",
              },
              "& > span:nth-of-type(3)": {
                transformOrigin: "bottom",
                transform: "translate(42px,-15px) rotatez(45deg)",
                width: "50%",
              },
            },
          }}
          transform={width ? `scale(${width / 100})` : undefined}
          transformOrigin={"top left"}
          className={isActive ? "active" : undefined}
        >
          <span></span>
          <span></span>
          <span></span>
        </Flex>
      </Box>
    </Button>
  );
};
