/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const TrackRecordSummaryJsonApiModel = {
  id: "",
  "amount-active-investments": "",
  "amount-realized-investments": "",
  "average-holding-period": "",
};
