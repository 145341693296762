/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const HeartFilledIcon = createIcon({
  displayName: "HeartFilledIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M21.95 3.051C20.627 1.729 18.87 1 17 1C15.13 1 13.373 1.729 12.051 3.05C12.034 3.067 12.017 3.084 12 3.102C11.983 3.084 11.967 3.068 11.95 3.051C10.627 1.729 8.87 1 7 1C5.13 1 3.373 1.729 2.05 3.051C0.727 4.373 0 6.13 0 8C0 9.87 0.728 11.627 2.05 12.949L12 22.899L21.95 12.949C23.272 11.627 24 9.87 24 8C24 6.131 23.272 4.373 21.95 3.051Z" fill="currentColor" /></g>)
});
export default HeartFilledIcon;