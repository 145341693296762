import { Flex, FlexProps, Icon, Text } from "@chakra-ui/react";
import React from "react";

interface AlertBoxProps extends FlexProps {
  text: string;
  title?: string;
  big?: boolean;
  noIcon?: boolean;
  icon?: string;
}

const AlertBox: React.FC<AlertBoxProps> = ({
  noIcon,
  title,
  text,
  icon,
  big,
  ...restProps
}) => {
  const fontSize = big ? "16px" : "12px";

  return (
    <Flex
      bg="brand.dark"
      borderRadius="0 0 16px 16px"
      py="10px"
      px="26px"
      {...restProps}
      data-testid="alert-box"
    >
      {!noIcon && (
        <Icon
          data-testid="icon"
          boxSize="20px"
          name={icon}
          color="alert.danger"
          mr="8px"
        />
      )}
      <Flex>
        {title && (
          <Text
            fontSize={fontSize}
            color="white"
            fontWeight="bold"
            mr="4px"
            data-testid="alert-box-title"
          >
            {title}
          </Text>
        )}
        <Text fontSize={fontSize} color="white" data-testid="alert-box-text">
          {text}
        </Text>
      </Flex>
    </Flex>
  );
};

AlertBox.defaultProps = {
  icon: "warning",
};

export default AlertBox;
