/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetSecuritiesOutputJsonApiModel = {
  id: "",
  securities: {
    jsonApi: "hasMany",
    type: "securities",
  },
};
