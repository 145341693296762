/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetEntityOpportunitiesOutputJsonApiModel = {
  id: "",
  "entity-opportunities": {
    jsonApi: "hasMany",
    type: "entity-opportunities",
  },
};
