/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const FilterListIcon = createIcon({
  displayName: "FilterListIcon",
  viewBox: "0 0 19 19",
  path: (
    <g fill="none">
      <path
        d="M7.91667 14.25V12.6667H11.0833V14.25H7.91667ZM4.75 10.2917V8.70833H14.25V10.2917H4.75ZM2.375 6.33333V4.75H16.625V6.33333H2.375Z"
        fill="currentColor"
      />
    </g>
  ),
});
export default FilterListIcon;
