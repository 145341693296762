/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const CalendarIcon = createIcon({
  displayName: "CalendarIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M1 9H23" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M21 3H3C1.89543 3 1 3.89543 1 5V21C1 22.1046 1.89543 23 3 23H21C22.1046 23 23 22.1046 23 21V5C23 3.89543 22.1046 3 21 3Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M6 1L5.999 5" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M18 1L17.999 5" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /></g>)
});
export default CalendarIcon;