import { Box, Checkbox, Text } from "@chakra-ui/react";
import React from "react";
import { ArrowDownIcon, ArrowUpIcon } from "../../../../../icons";
import { Th, Thead, Tr } from "../../../Table";
import { VeriTableSortDirection } from "../../VeriTable.types";
import { VeriTableHeadProps } from "./VeriTableHead.types";

const VeriTableHead = React.forwardRef<HTMLDivElement, VeriTableHeadProps>(
  (
    {
      tHeadProps,
      tableStyle,
      columnNames,
      allowBulkSelection,
      tableObject,
      hasRowActions,
      sortable,
      columns,
      checkboxProps,
    },
    ref,
  ) => {
    const [hoverColumn, setHoverColumn] = React.useState<string | null>(null);

    return (
      <Thead {...tHeadProps} ref={ref}>
        <Tr>
          {allowBulkSelection && (
            <Th key={"column-bulk-selection"} {...(tableStyle?.th || {})}>
              <Checkbox
                isIndeterminate={tableObject.isIndeterminateSelection}
                isChecked={tableObject.isAllRecordsSelected}
                onChange={tableObject.toggleAllSelection}
                size={"lg"}
                colorScheme={"brand"}
                {...checkboxProps}
              />
            </Th>
          )}
          {columnNames.map((columnName, columnIndex) => {
            const column = columns[columnName];

            const isSortable =
              (sortable && column.sortable !== false) || !!column.sortable;

            const isSorted =
              isSortable && tableObject.sortingColumn === columnName;
            const isSortedDesc = tableObject.sortingDirection === "DESC";

            return (
              <Th
                key={`${columnName}-${columnIndex}`}
                pl={!columnIndex && allowBulkSelection ? 0 : undefined}
                {...(tableStyle?.th || {})}
                {...(isSortable && {
                  _hover: {
                    cursor: "pointer",
                  },
                  onMouseEnter: () => setHoverColumn(columnName),
                  onMouseLeave: () => setHoverColumn(null),
                  onClick: () => {
                    if (isSorted) {
                      tableObject.setSortingDirection(
                        isSortedDesc
                          ? VeriTableSortDirection.ASC
                          : VeriTableSortDirection.DESC,
                      );
                    } else {
                      tableObject.setSortingDirection(
                        VeriTableSortDirection.DESC,
                      );
                      tableObject.setSortingColumn(columnName);
                    }
                  },
                })}
                pos={"relative"}
                {...column.props}
              >
                <Text
                  as="div"
                  display={"flex"}
                  textAlign={column.columnAlign || "left"}
                  justifyContent={
                    (column.columnAlign === "right"
                      ? "flex-end"
                      : column.columnAlign) || "flex-start"
                  }
                  textStyle="bodyRegularBold"
                >
                  {column?.label}
                  {isSortable && (
                    <Box
                      px={2}
                      backgroundColor={"#fff"}
                      display={"inline-flex"}
                      visibility={
                        isSorted || hoverColumn === columnName
                          ? "visible"
                          : "hidden"
                      }
                      alignItems={"center"}
                    >
                      {isSortedDesc ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Box>
                  )}
                </Text>
              </Th>
            );
          })}
          {hasRowActions && (
            <Th {...(tableStyle?.th || {})} {...(tableStyle?.stickyTh || {})} />
          )}
        </Tr>
      </Thead>
    );
  },
);

VeriTableHead.displayName = "VeriTableHead";

export default VeriTableHead;
