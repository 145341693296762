import React from "react";
import { VeriTableHeadingProps } from "./VeriTableHeading.types";
import { Box, Flex } from "@chakra-ui/react";

const VeriTableHeading: React.FC<VeriTableHeadingProps> = ({
  headingLabel,
  showTotalRecordsCount,
  totalRecordsCount,
}) => {
  if (!showTotalRecordsCount && !headingLabel) {
    return null;
  }

  return (
    <Flex p={4} w={"100%"} justifyContent={"space-between"}>
      <Box>{headingLabel}</Box>
      <Box fontWeight={"bold"}>
        {showTotalRecordsCount && `${totalRecordsCount} total`}
      </Box>
    </Flex>
  );
};

export default VeriTableHeading;
