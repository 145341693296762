import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ChevronDownIcon } from "./Icons";

function VeriSelect(
  {
    children,
    label,
    error,
    helperText,
    optional,
    name,
    selectProps,
    greyedOutOnReadOnly,
    isSmall,
    ...rest
  },
  ref,
) {
  // if (light) {
  //   return (
  //     <Select
  //       width="none"
  //       icon={<ChevronDownIcon />}
  //       iconColor="icons.grey"
  //       borderWidth="0px"
  //       fontSize={14}
  //       fontFamily="roboto"
  //       fontWeight="bold"
  //       color="text.black"
  //       backgroundColor="transparent"
  //       pr="9px"
  //       pl="0px"
  //       height="none"
  //       {...rest}
  //     >
  //       {children}
  //     </Select>
  //   );
  // }
  return (
    <FormControl isInvalid={!!error} {...rest}>
      {label && (
        <Flex justifyContent="space-between">
          <FormLabel lineHeight="24px" mb="8px" fontWeight="500">
            {label}
          </FormLabel>
          {optional && (
            <Text color="text.grey" fontWeight="400" fontSize="16px">
              Optional
            </Text>
          )}
        </Flex>
      )}
      <Select
        name={name}
        ref={ref}
        bg="white"
        icon={<ChevronDownIcon />}
        iconColor="icons.grey"
        fontFamily="Nunito Sans"
        fontSize="md"
        color="text.black"
        textStyle={isSmall ? "bodySmall" : undefined}
        {...rest}
        {...selectProps}
        variant={isSmall ? "verivestSmall" : "verivest"}
        height="none"
        _readOnly={
          greyedOutOnReadOnly && {
            backgroundColor: "bg.grey",
          }
        }
        {...(error ? { borderColor: "semantic.error", borderWidth: 2 } : null)}
      >
        {children}
      </Select>
      {helperText && !error && (
        <FormHelperText id="email-helper-text">{helperText}</FormHelperText>
      )}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
}
export default React.forwardRef(VeriSelect);
