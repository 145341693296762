/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AssetJsonApiModel = {
  id: "",
  address: {
    jsonApi: "hasOne",
    type: "addresses",
  },
  "address-id": "",
  name: "",
  "asset-type": {
    jsonApi: "hasOne",
    type: "asset-types",
  },
  "asset-type-id": "",
  apn: "",
  "year-built": "",
  "year-renovated": "",
  "rentable-area": "",
  acres: "",
  units: "",
  "parking-spaces": "",
  buildings: "",
  msa: {
    jsonApi: "hasOne",
    type: "metropolitan-statistical-areas",
  },
  "msa-id": "",
  "occupancy-rate": "",
  "entity-opportunity-id": "",
  "entity-opportunity": {
    jsonApi: "hasOne",
    type: "entity-opportunities",
  },
  "created-at": "",
  "updated-at": "",
};
