import { Box } from "@chakra-ui/react";
import React from "react";
import { dateToHumanDate, dateToMonYYYYUTC } from "../../../../utils/dates";
import {
  VeriTableRendererProps,
  VeriTableSortDirection,
} from "../VeriTable.types";

const DateRenderer: React.FC<Partial<VeriTableRendererProps>> = function ({
  value,
  column,
}) {
  if (!value || !column) return <></>;

  const date = !isNaN(value)
    ? new Date(value * 1000)
    : typeof value === "string"
    ? new Date(value)
    : value;

  let convertedDate = "";

  const useFullMonth = column.rendererOptions?.useFullMonth === true;
  const useDay = column.rendererOptions?.useDay !== false;

  if (column.rendererOptions?.humanDate) {
    convertedDate = (date ? dateToHumanDate(date) : "") || "";
  } else {
    convertedDate =
      (date ? dateToMonYYYYUTC(date, useDay, useFullMonth) : "") || "";
  }

  return (
    <Box className={"inline-block sm:block"}>
      {!date
        ? column.emptyReplace || "-"
        : convertedDate
        ? convertedDate
        : value}
    </Box>
  );
};

DateRenderer.prototype.sort = (
  column: string,
  direction: VeriTableSortDirection,
  records: any[],
) => {
  return records.sort((a, b) => {
    const aDate = !isNaN(a[column])
      ? new Date(a[column] * 1000)
      : typeof a[column] === "string"
      ? new Date(a[column])
      : a[column];
    const bDate = !isNaN(b[column])
      ? new Date(b[column] * 1000)
      : typeof b[column] === "string"
      ? new Date(b[column])
      : b[column];

    if (aDate && bDate) {
      if (direction === "ASC") {
        return aDate - bDate;
      } else {
        return bDate - aDate;
      }
    } else {
      return 0;
    }
  });
};

export default DateRenderer;
