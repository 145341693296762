import React from "react";
import { MaskedDateInputProps } from "./MaskedDateInput.types";
import { Controller } from "../../../react-hook-form";
import { VeriInput } from "../VeriInput";
import { dateToDate, isInTheFuture } from "../../../utils/dates";

const dateMatch = /^(\d{4}-?(0\d|1[012])-?\d{2})/gm;

const MaskedDateInput: React.FC<MaskedDateInputProps> = ({
  label,
  control,
  errors,
  name,
  toIsoString,
  isRequired,
  helperText,
  value,
  onChange,
  allowFuture,
  w = "50%",
}) => {
  const [_value, setValue] = React.useState(value);

  if (control) {
    return (
      <Controller
        rules={{
          required: isRequired ? "You must enter valid date" : false,
          pattern: {
            value: dateMatch,
            message: "You must enter valid date",
          },
          validate: (value) => {
            if (allowFuture === false && isInTheFuture(dateToDate(value))) {
              return "Date cannot be in the future";
            }
            return true;
          },
        }}
        render={({ field: { onChange, value, name, ref } }) => {
          let fillValue = value;

          if (fillValue) {
            const stringValue = String(fillValue);
            const splitDate = (
              stringValue.includes("T")
                ? stringValue.split("T")[0]
                : stringValue
            ).split("-");
            fillValue = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
          }

          return (
            <VeriInput
              w={w}
              onChange={(e) => {
                if (e.target.value) {
                  const splitDate = String(e.target.value).split("/");
                  e.target.value! = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;

                  if (toIsoString && e.target.value.match(dateMatch)) {
                    e.target.value! = new Date(e.target.value).toISOString();
                  }

                  onChange(e);
                  return;
                }

                e.target.value! = "";

                onChange(e);
              }}
              propsInput={{ placeholder: "MM/DD/YYYY" }}
              value={fillValue || ""}
              label={label}
              ref={ref}
              mask="99/99/9999"
              name={name}
              error={errors && errors[name] && (errors[name] as any).message}
              helperText={helperText}
            />
          );
        }}
        control={control}
        name={name}
        // defaultValue={""}
      />
    );
  }

  return (
    <VeriInput
      w={w}
      onChange={(e) => {
        if (e.target.value) {
          const originalValue = e.target.value;
          const splitDate = String(e.target.value).split("/");
          e.target.value! = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;

          if (toIsoString && e.target.value.match(dateMatch)) {
            e.target.value! = new Date(e.target.value).toISOString();
          }

          setValue(originalValue);
          onChange && onChange(e);
          return;
        }

        e.target.value! = "";

        setValue("");
        onChange && onChange(e);
      }}
      propsInput={{ placeholder: "MM/DD/YYYY" }}
      value={_value || ""}
      label={label}
      mask="99/99/9999"
      name={name}
      error={errors && errors[name] && (errors[name] as any).message}
      helperText={helperText}
    />
  );
};

export default MaskedDateInput;
