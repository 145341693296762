/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 *
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const MenuProfileIcon = createIcon({
  displayName: "MenuProfileIcon",
  viewBox: "0 0 24 24",
  path: (
    <g fill="none">
      <path
        d="M2 8H22"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M8 8V22"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M20 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4V20C22 20.5304 21.7893 21.0391 21.4142 21.4142C21.0391 21.7893 20.5304 22 20 22Z"
        stroke="currentColor"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </g>
  ),
});
export default MenuProfileIcon;
