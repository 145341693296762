import {
  addTrackRecordRoutes,
  managerDashboardRoutes,
  SponsorOnboardingRoutes,
} from "@verivest/shared-components";
import { RouteLoading as Placeholder } from "@verivest/shared-components/src/components/common/loaders/RouteLoading";
import { NotFoundRoute } from "@verivest/shared-components/src/components/NotFoundRoute";
import asyncComponentAuthenticated from "@verivest/shared-components/src/utils/asyncComponentAuthenticated";

export const sponsorDashboardPages = [
  {
    path: managerDashboardRoutes.USER_SETTINGS,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "user-settings-manager" */ "../pages/manager-dashboard/sponsor-dashboard-user-settings/SponsorDashboardUserSettings"
        ),
      Placeholder,
      chunkName: "user-settings-manager",
    }),
  },
  {
    path: managerDashboardRoutes.HOME,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-test" */ "../pages/manager-dashboard/home/DashboardHome"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-test",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.PROFILE,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-profile" */ "../pages/manager-dashboard/profile/ManagerProfileEditor"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-profile",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.CONTACTS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "contacts-profile" */ "../pages/manager-dashboard/contacts"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-contacts",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.CONTACTS_NEW,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-new-contact" */ "../pages/manager-dashboard/contacts/ContactFormPage/ContactFormPage"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-contacts",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.CONTACT_DETAILS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "contacts-profile" */ "../pages/manager-dashboard/contacts/ContactDetails/ContactDetails"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-contacts",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.ACCOUNTS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-dashboard-accounts" */ "../pages/manager-dashboard/accounts"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-dashboard-accounts",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.ACCOUNT_CREATE,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-dashboard-accounts-create" */ "../pages/manager-dashboard/accounts/AccountCreate"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-dashboard-accounts-create",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.ACCOUNT_DETAILS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-accounts-details" */ "../pages/manager-dashboard/accounts/details"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-accounts-details",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.INVESTMENTS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-investments" */ "../pages/manager-dashboard/investments"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-investments",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.INVESTMENT_CREATE,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-investments" */ "../pages/manager-dashboard/investments/create/NewInvestmentPage"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-investment-create",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OPPORTUNITIES,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-opportunities" */ "../pages/manager-dashboard/opportunities"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-opportunities",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OPPORTUNITIES_DETAILS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-opportunities" */ "../pages/manager-dashboard/opportunities/details"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-opportunities",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OFFERINGS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings" */ "../pages/manager-dashboard/offerings"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-offerings",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OFFERINGS_ALLOCATION_METHODS,
    exact: false,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-allocation-methods" */ "../pages/manager-dashboard/offerings/view-allocation-methods/ViewAllocationMethods"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-offerings-allocation-methods",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OFFERINGS_STATEMENT_SETS,
    exact: false,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-statement-sets" */ "../pages/manager-dashboard/offerings/ViewStatementSets"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-offerings-statement-sets",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OFFERINGS_GENERATE_STATEMENTS,
    exact: false,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-generate-statements" */ "../pages/manager-dashboard/offerings/generate-statements/GenerateStatements"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-offerings-generate-statements",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OFFERINGS_DISTRIBUTIONS,
    exact: false,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-distributions" */ "../pages/manager-dashboard/offerings/ViewDistributions"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-offerings-distributions",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OFFERINGS_DETAILS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-details" */ "../pages/manager-dashboard/offerings/details"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-offerings-details",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OFFERINGS_CHANGE_HOLDING_RATE,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-change-rate" */ "../pages/manager-dashboard/offerings/interest-rate-updates/ChangeHoldingRate"
        ),
      Placeholder,
      chunkName: "manager-offerings-change-rate",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.OFFERINGS_CHANGE_SECURITY_RATE,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-change-rate" */ "../pages/manager-dashboard/offerings/interest-rate-updates/ChangeSecurityRate"
        ),
      Placeholder,
      chunkName: "manager-offerings-change-security-rate",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.EXCHANGE_SECURITIES,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-exchange-securities" */ "../pages/manager-dashboard/offerings/exchange-securities/ExchangeSecurities"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-offerings-exchange-securities",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.TRANSFER,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-offerings-transfer" */ "../pages/manager-dashboard/offerings/transfer/Transfer"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-offerings-transfer",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.INVESTMENT_DETAILS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-investment-details" */ "../pages/manager-dashboard/investments/details"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-investment-details",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.REPORTS_EDIT,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-edit-reports" */ "../pages/manager-dashboard/reports/CreateReport"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-edit-reports",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.REPORTS_NEW,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-new-reports" */ "../pages/manager-dashboard/reports/CreateReport"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-new-reports",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.REPORTS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-reports" */ "../pages/manager-dashboard/reports/Reports"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-reports",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.PORTAL_SETTINGS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-portal-settings" */ "../pages/manager-dashboard/portal-settings"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-portal-settings",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },

  /* Sponsor onboarding */
  {
    path: SponsorOnboardingRoutes.SWITCH_SPONSOR,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboard-manager-onboardingWizard-switch-manager" */ "../pages/investor-portal/wizards/sponsor/SwitchSponsor"
        ),
      Placeholder,
      chunkName: "dashboard-manager-onboardingWizard-switch-manager",
      appendBackUrl: true,
    }),
  },
  {
    path: addTrackRecordRoutes.REVIEW_RECORDS,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboard-manager-trackRecord-review-records" */ "../pages/investor-portal/wizards/track-record/ReviewRecords"
        ),
      Placeholder,
      chunkName: "dashboard-manager-trackRecord-review-records",
      appendBackUrl: true,
    }),
  },
  {
    path: addTrackRecordRoutes.ADD_INVESTMENT_NO_BACK,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboard-manager-trackRecord-add-investment-no-back" */ "../pages/investor-portal/wizards/track-record/AddTrackRecordInvestmentNoBack"
        ),
      Placeholder,
      chunkName: "dashboard-manager-trackRecord-add-investment-no-back",
      appendBackUrl: true,
    }),
  },
  {
    path: addTrackRecordRoutes.ADD_INVESTMENT,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboard-manager-trackRecord-add-investment" */ "../pages/investor-portal/wizards/track-record/AddTrackRecordInvestment"
        ),
      Placeholder,
      chunkName: "dashboard-manager-trackRecord-add-investment",
      appendBackUrl: true,
    }),
  },
  {
    path: addTrackRecordRoutes.UPLOAD_TRACK_RECORD,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboard-manager-trackRecord-upload" */ "../pages/investor-portal/wizards/track-record/UploadTrackRecord"
        ),
      Placeholder,
      chunkName: "dashboard-manager-trackRecord-upload",
      appendBackUrl: true,
    }),
  },
  {
    path: addTrackRecordRoutes.START,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboard-manager-trackRecord-start" */ "../pages/investor-portal/wizards/track-record/AddTrackRecordStartPage"
        ),
      Placeholder,
      chunkName: "dashboard-manager-trackRecord-start",
      appendBackUrl: true,
    }),
  },
  {
    path: managerDashboardRoutes.IMPORTS,
    exact: true,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "manager-imports" */ "../pages/manager-dashboard/imports/Imports"
        ), // required
      Placeholder, // this is optional, just returns null by default
      chunkName: "manager-imports",
      callbackUrl: managerDashboardRoutes.HOME,
    }),
  },
  {
    path: managerDashboardRoutes.IMPORTS_BROWNFIELD,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboard-manager-brownfieldImport" */ "../pages/manager-dashboard/wizards/import-brownfield/ImportBrownfield"
        ),
      Placeholder,
      chunkName: "dashboard-manager-brownfieldImport",
      appendBackUrl: true,
    }),
  },
  {
    path: managerDashboardRoutes.IMPORT_CONTACTS,
    component: asyncComponentAuthenticated({
      loader: () =>
        import(
          /* webpackChunkName: "dashboard-manager-contactImport" */ "../pages/manager-dashboard/wizards/import-contacts/ImportContacts"
        ),
      Placeholder,
      chunkName: "dashboard-manager-contactImport",
      appendBackUrl: true,
    }),
  },
  {
    // there is no need to declare path variable
    // react router will pick this component as fallback
    component: NotFoundRoute,
  },
];
