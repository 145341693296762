import { Box, Portal } from "@chakra-ui/react";
import React from "react";
import { usePopper } from "react-popper";
import { VeriDropMenuProps } from "./VeriDropMenu.types";

const VeriDropMenu: React.FC<VeriDropMenuProps> = ({
  children,
  trigger,
  placement = "bottom-end",
  zIndex = "dropdown",
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [popperTrigger, setTrigger] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(popperTrigger, popperElement, {
    modifiers: [],
    placement,
  });

  const _children = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setIsOpen });
    }
    return child;
  });

  return (
    <>
      <Box ref={setTrigger} onClick={() => setIsOpen(true)}>
        {typeof trigger === "function" ? trigger({ isOpen }) : trigger}
      </Box>
      {isOpen && (
        <Portal>
          <Box
            zIndex={zIndex}
            pos={"fixed"}
            left={0}
            right={0}
            top={0}
            bottom={0}
            onClick={() => setIsOpen(false)}
          />
          <Box
            {...(styles.popper as unknown)}
            {...attributes.popper}
            ref={setPopperElement}
            zIndex={zIndex}
            borderRadius={4}
          >
            {_children}
          </Box>
        </Portal>
      )}
    </>
  );
};

export default VeriDropMenu;
