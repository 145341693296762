/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const GetFeeInstanceInputJsonApiModel = {
  id: "",
  filters: {
    jsonApi: "hasOne",
    type: "fee-instance-filters",
  },
};
