import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useVeriForm } from "@verivest/shared-components/src/react-hook-form";
import { EditIcon } from "./Icons";
import { Button } from "./index";

const PopoverEdit = ({
  children,
  submitLabel = "Update",
  cancelLabel = "Cancel",
  getInputs,
  defaultValues,
  onSubmit,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState(false);

  const form = useVeriForm({
    defaultValues,
  });

  const _onClose = (e) => {
    e.stopPropagation();
    form.reset(defaultValues);
    onClose();
  };

  const _onSubmit = async (values) => {
    setIsLoading(true);
    onSubmit && (await onSubmit(values));
    setIsLoading(false);
    form.reset(values);
    onClose();
  };

  const preventEventPropagation = {
    onClick: (e) => {
      e.stopPropagation();
    },
    onKeyDown: (e) => {
      e.stopPropagation();
    },
    onKeyUp: (e) => {
      e.stopPropagation();
      e.preventDefault();
    },
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Box as={"button"} {...preventEventPropagation}>
          <EditIcon boxSize={"20px"} ml={2} color={"icons.grey"} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        boxShadow={
          "0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16)!important"
        }
        borderRadius={4}
        p={6}
        {...preventEventPropagation}
      >
        <form onSubmit={form.handleSubmit(_onSubmit)}>
          <Box key={JSON.stringify(defaultValues)}>
            {getInputs && getInputs(form)}
            {children}
          </Box>
          <Stack spacing={2} mt={6} direction={"row"}>
            <Button isLoading={isLoading} label={submitLabel} type="submit" />
            <Button
              isDisabled={isLoading}
              label={cancelLabel}
              onClick={_onClose}
              outline
            />
          </Stack>
        </form>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverEdit;
