import React from "react";
import { Box, FlexProps, Flex, keyframes } from "@chakra-ui/react";
import { VeriSpinner } from "../../../components/common/loaders/VeriSpinner";
import { Button } from "../../../components";
import { WorkJob } from "./useWorkerJobs.types";
import { useWorkflowModel } from "../../../api/hooks/models/useWorkflowModel";
import { useVeriToast } from "../toast/useVeriToast";
import { logError } from "../../../error-handling/logError";

export const MIN_JOB_TIME_INTERVAL = 1000;

export interface WorkerJobItemProps extends FlexProps {
  onCancelJob: (jobId: string) => void;
  onCompleteJob: (jobId: string) => void;
  job: WorkJob;
  isHidden?: boolean;
}

const newJobAnimationKeyFrames = keyframes`
  from {box-shadow: none; opacity: 0; width: 50px; height: 0; }
  30% { opacity: 1; height: 66px; }
  100% {width: 100%; }
`;

const newJobAnimation = `${newJobAnimationKeyFrames} .7s ease-in-out`;

export const WorkerJobItem: React.FC<WorkerJobItemProps> = ({
  job,
  onCancelJob,
  onCompleteJob,
  isHidden,
  ...rest
}) => {
  const workflowModel = useWorkflowModel();
  const toast = useVeriToast();

  const checkInterval = job.checkInterval || MIN_JOB_TIME_INTERVAL;

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    (function appendTimeout() {
      timeout = setTimeout(
        async () => {
          try {
            const jobStatus =
              await workflowModel.getWorkflowJobExecutionSummary(job.id);

            if (jobStatus.status !== "Completed") {
              appendTimeout();
            } else {
              onCompleteJob(job.id);
              job.onCompleted?.(jobStatus);
            }
          } catch (e) {
            toast.toastServerError(e);
            onCancelJob(job.id);
            logError(e);
          }
        },
        checkInterval < MIN_JOB_TIME_INTERVAL
          ? MIN_JOB_TIME_INTERVAL
          : checkInterval,
      );
    })();

    return () => timeout && clearTimeout(timeout);
  }, []);

  if (isHidden) return <></>;

  return (
    <Flex
      py={2}
      px={3}
      bg={"brand.main"}
      alignItems={"center"}
      borderRadius={"20px"}
      boxShadow={4}
      border={"1px solid"}
      borderColor={"border.grey"}
      animation={newJobAnimation}
      height={"66px"}
      overflow={"hidden"}
      {...rest}
    >
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        w={"40px"}
        minW={"40px"}
        h={"40px"}
        bg={"bg.white"}
        borderRadius={"100%"}
        mr={2}
        p={1}
      >
        <VeriSpinner />
      </Flex>
      <Box maxW={"250px"} w={"100%"} overflow={"hidden"}>
        <Box
          textStyle={"bodyRegularBold"}
          whiteSpace={"nowrap"}
          textOverflow={"ellipsis"}
          color={"text.white"}
          overflow={"hidden"}
        >
          {job.title}
        </Box>
        <Box
          textStyle={"bodyRegular"}
          color={"gray.100"}
          whiteSpace={"nowrap"}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
        >
          {job.description}
        </Box>
      </Box>
      <Button px={3} onClick={onCancelJob.bind(null, job.id)} empty>
        <Box as={"span"} textStyle={"bodyRegular"} color={"text.white"}>
          Cancel
        </Box>
      </Button>
    </Flex>
  );
};
