/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const BlueSkyFilingJsonApiModel = {
  id: "",
  "offering-id": "",
  offering: {
    jsonApi: "hasOne",
    type: "offerings",
  },
  state: "",
  status: "",
  "filing-date": "",
  "created-at": "",
  "updated-at": "",
};
