import React from "react";
import { Box, Flex, Link } from "@chakra-ui/react";
import { ExternalLinkIcon, InfoIcon } from "../../general";
import { openUrlInNewWindow } from "@verivest/shared-components";

const LearnMore = ({ name, url, ...rest }) => {
  return (
    <Flex mt={12} alignItems={"center"} justifyContent={"center"} {...rest}>
      <InfoIcon boxSize={6} color={"text.greyDark"} />
      <Box ml={2.5}>
        Learn more about{" "}
        <Link
          color="brand.main"
          textDecoration={"underline"}
          onClick={() => openUrlInNewWindow(url)}
          isExternal
        >
          {name}
          <ExternalLinkIcon ml={1} boxSize={4} color={"brand.main"} />
        </Link>
      </Box>
    </Flex>
  );
};

export default LearnMore;
