/**
 *
 * This file was automatically generated using build-icons npm script and should not be edited
 * 
 **/
import * as React from "react";
import { createIcon } from "@chakra-ui/icons";
const BellIcon = createIcon({
  displayName: "BellIcon",
  viewBox: "0 0 24 24",
  path: (<g fill="none"><path d="M19 11V8C19 6.14348 18.2625 4.36301 16.9497 3.05025C15.637 1.7375 13.8565 1 12 1C10.1435 1 8.36301 1.7375 7.05025 3.05025C5.7375 4.36301 5 6.14348 5 8V11C5 14.3 2 15.1 2 17C2 18.7 5.9 20 12 20C18.1 20 22 18.7 22 17C22 15.1 19 14.3 19 11Z" stroke="currentColor" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="square" /><path d="M12 22C10.989 22 10.039 21.966 9.145 21.9C9.33559 22.508 9.7153 23.0392 10.2288 23.4164C10.7423 23.7936 11.3628 23.997 12 23.997C12.6372 23.997 13.2577 23.7936 13.7712 23.4164C14.2847 23.0392 14.6644 22.508 14.855 21.9C13.961 21.966 13.011 22 12 22Z" fill="currentColor" /></g>)
});
export default BellIcon;