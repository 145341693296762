/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const PrincipalJsonApiModel = {
  id: "",
  name: "",
  slug: "",
  email: "",
  title: "",
  "img-url": "",
  "description-html": "",
  "description-raw": "",
  "years-principal": "",
  "years-real-estate": "",
  "start-date": "",
  backgroundchecks: {
    jsonApi: "hasMany",
    type: "background-checks",
  },
  "principal-histories": {
    jsonApi: "hasMany",
    type: "principal-histories",
  },
  manager: {
    jsonApi: "hasOne",
    type: "managers",
  },
  "invoice-item-id": "",
  "stripe-invoice-item-id": "",
  "stripe-invoice-status": "",
  "stripe-invoice-paid-at": "",
};
