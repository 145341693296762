/**
 *
 * This file was automatically generated using build-models npm script and should not be edited
 *
 **/
export const AllocationJsonApiModel = {
  id: "",
  holding: {
    jsonApi: "hasOne",
    type: "holdings",
  },
  account: {
    jsonApi: "hasOne",
    type: "accounts",
  },
  amount: "",
  "pay-to-date": "",
};
