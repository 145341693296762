import {
  buildRouteUrl,
  investorPortalWhiteLabelRoutes,
} from "@verivest/shared-components";
import React from "react";
import { InvestorPortalHeaderBase } from "../InvestorPortalHeaderBase";
import { InvestorPortalHeaderProps } from "./InvestorPortalHeader.types";

export const InvestorPortalHeader: React.FC<InvestorPortalHeaderProps> = ({
  isWhiteLabel,
  isMarketing,
  ...rest
}) => {
  return (
    <InvestorPortalHeaderBase
      hasLogo={isWhiteLabel}
      hasMobileDrawer={isWhiteLabel}
      hasShadow={isWhiteLabel && !isMarketing}
      isMarketing={isMarketing}
      logoTo={buildRouteUrl(investorPortalWhiteLabelRoutes.overview)}
      {...rest}
    ></InvestorPortalHeaderBase>
  );
};
